import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parcelActions } from "../actions/rootActions";
import { playSound } from "../utils/playSound";
import { message } from "antd";

export const useDisposalScan = (params) => {
  const dispatch = useDispatch();
  const { disposalScanSuccess, scannedNumbersList, disposalScanError } = useSelector((state) => ({
    disposalScanSuccess: state.parcel.disposalScanSuccess,
    disposalScanError: state.parcel.disposalScanError,
    scannedNumbersList: state.common.scannedNumbersList,
  }));

  useEffect(() => {
    if (disposalScanSuccess) {
      playSound("success");
      message.success(`${scannedNumbersList.length} package(s) has been disposed.`);
      dispatch(parcelActions.clearDisposalScanData());
    }
  }, [disposalScanSuccess]);

  useEffect(() => {
    if (disposalScanError) {
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      playSound("error");
      dispatch(parcelActions.clearDisposalScanData());
    }
  }, [disposalScanError]);
};

export const useDisposalOrder = (params) => {
  const dispatch = useDispatch();
  const [isPackageDisposed, setIsPackageDisposed] = useState(false);
  const { fetchDisposalOrderSuccess, fetchDisposalOrderError } = useSelector((state) => ({
    fetchDisposalOrderSuccess: state.parcel.fetchDisposalOrderSuccess,
    fetchDisposalOrderError: state.parcel.fetchDisposalOrderError,
  }));
  useEffect(() => {
    if (fetchDisposalOrderSuccess) {
      playSound("success");
      if (isPackageDisposed) {
        setIsPackageDisposed(false);
      }
      dispatch(parcelActions.clearDisposalOrderData());
    }
  }, [fetchDisposalOrderSuccess]);

  useEffect(() => {
    if (fetchDisposalOrderError) {
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      playSound("error");
      dispatch(parcelActions.clearDisposalOrderError());
    }
  }, [fetchDisposalOrderError]);

  return [isPackageDisposed];
};
