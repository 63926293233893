import React, { useState } from "react";
import { connect } from "react-redux";
import { Divider } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import BreadCrumb from "./BreadCrumb";

function ScannedNumbersList(props) {
  const [copyInProgress, setCopyInProgress] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const { scannedNumbersList } = props;

  const locationScanType = props.location.state.scanType;
  const locationPageName = props.location.state.pageName;
  const locationRoute = props.location.state.route;

  const numbersList = showAll
    ? scannedNumbersList
    : scannedNumbersList.slice(0, 10);

  const copyClipboardTextComponent = () => {
    const columnFormattedText = scannedNumbersList.join("\n");
    const commaFormattedText = scannedNumbersList.join(",");
    if (copyInProgress) {
      return <span className="text-span-link margin-top-30">"Copied!"</span>;
    }
    return (
      <span className="margin-top-30 fnt-16">
        Copy all {scannedNumbersList.length} tracking numbers to Clipboard:{" "}
        <CopyToClipboard text={columnFormattedText} onCopy={onCopyGroupId}>
          <span className="text-span-link d-inline-block">in one column</span>
        </CopyToClipboard>{" "}
        or{" "}
        <CopyToClipboard text={commaFormattedText} onCopy={onCopyGroupId}>
          <span className="text-span-link">separated by commas</span>
        </CopyToClipboard>
      </span>
    );
  };

  return (
    <div className="parcel-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0 w-100">
          <BreadCrumb
            pageName={locationPageName}
            scanType={locationScanType}
            route={locationRoute}
          />
          <div className="bg-container-body w-100">
            <div className="d-flex flex-column">
              <h2 className="text-heading-black">
                {scannedNumbersList.length} Scanned in This Session
              </h2>
              {copyClipboardTextComponent()}
              <span className="text-span-black-bold margin-top-30">
                Last {numbersList.length} Tracking Number Scanned{" "}
                {scannedNumbersList && scannedNumbersList.length > 10 && (
                  <span>
                    {" "}
                    (
                    <span className="text-span-link" onClick={onToggleShowAll}>
                      {showAll ? "Show Less" : "Show All"}
                    </span>
                    )
                  </span>
                )}
              </span>
              <div className="row">
                {numbersList &&
                  numbersList.length > 0 &&
                  numbersList.map((scanNumber, index) => {
                    return (
                      <div
                        key={index}
                        className="col-sm-4 col-12 text-span-black mt-3"
                      >
                        {scanNumber}
                      </div>
                    );
                  })
                }
              </div>
              <Divider className="gray-divider" />
            </div>
            <button
              className="secondary-button-small full-button-width"
              onClick={() => props.history.goBack()}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  function onToggleShowAll() {
    setShowAll(!showAll);
  }

  function onCopyGroupId() {
    setCopyInProgress(true);
    setTimeout(() => setCopyInProgress(false), 2000);
  }
}

const mapStateToProps = ({ common }) => ({
  scannedNumbersList: common.scannedNumbersList
});

export default connect(mapStateToProps, null)(ScannedNumbersList);