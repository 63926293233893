// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Spin, Divider, Alert, message, Checkbox } from "antd";
import heic2any from "heic2any";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import {
  PARCEL_TRACKING_NUMBER,
  EXCEPTION_DATA,
  ADD_EXCEPTION,
  SUCCESS,
  AUTO_ADD_EXCEPTION,
} from "../../../constants/mixpanelConstants";
import { connect } from "react-redux";
import { parcelActions } from "../../../actions/rootActions";
import { LoadingOutlined } from "@ant-design/icons";
import BreadCrumb from "../commonComponents/BreadCrumb";
import Select from "../../../common/select/select";
import Input from "../../../common/input/input";
import Compress from "browser-image-compression";
import * as _ from "lodash";

function AddException(props) {
  const {
    latestScannedNumber,
    exceptionTypes,
    scannerInformation,
    scanSettings,
    exceptionNumber,
    orderData,
    addExceptionLoading,
    addExceptionSuccess,
    addExceptionError,
    sessionId,
    scannedNumbersList,
  } = props;

  const exceptions = orderData.exceptions;

  const scanType = props.location.state.scanType;
  const locationPageName = props.location.state.pageName;
  const locationRoute = props.location.state.route;

  const [exceptionData, setExceptionData] = useState({
    exceptionType: undefined,
    exceptionDescription: "",
    exceptionImage: null,
    exceptionImageName: null,
    imageType: null,
    imageInProcessing: false,
    exceptionImageTypeError: false,
    savingException: false,
    saveExceptionSuccess: false,
    saveExceptionError: false,
    exceptionErrMessage: null,
    autoAddException: false,
  });

  useEffect(() => {
    if (addExceptionSuccess) {
      const params = getParams(latestScannedNumber);
      const autoAdd = true;
      props.dispatch(parcelActions.onFetchParcelInfo(params, exceptionTypes, autoAdd, scannedNumbersList));
      message.success(`Exception ${exceptionNumber} Added`);
      props.dispatch(parcelActions.clearAddExceptionData());
      const mixPanelData = getMixpanelData();
      mixPanelTrack(ADD_EXCEPTION, {
        [SUCCESS]: true,
        ...mixPanelData,
        [PARCEL_TRACKING_NUMBER]: latestScannedNumber,
        [EXCEPTION_DATA]: exceptionData,
      });
      goBackToPreviousRoute();
    }
  }, [addExceptionSuccess]);

  useEffect(() => {
    if (addExceptionError) {
      setExceptionData({
        ...exceptionData,
        exceptionDescription: "",
        saveExceptionError: true,
        saveExceptionSuccess: false,
        savingException: false,
        exceptionErrMessage: addExceptionError,
      });

      setTimeout(() => {
        setExceptionData({
          ...exceptionData,
          saveExceptionError: false,
          saveExceptionSuccess: false,
        });
      }, 2500);
    }
  }, [addExceptionError]);

  const exceptionTypeErrorMessage =
    exceptionData.exceptionErrMessage &&
    exceptionData.exceptionErrMessage.exception_type &&
    exceptionData.exceptionErrMessage.exception_type[0];

  const exceptionTypeClass =
    exceptionData.exceptionErrMessage &&
    exceptionData.exceptionErrMessage.exception_type &&
    exceptionData.exceptionErrMessage.exception_type[0]
      ? "selection-error"
      : "";

  const exceptionNoteErrorMessage =
    exceptionData.exceptionErrMessage &&
    exceptionData.exceptionErrMessage.exception_note &&
    exceptionData.exceptionErrMessage.exception_note[0];

  const textAreaClass =
    exceptionData.exceptionErrMessage &&
    exceptionData.exceptionErrMessage.exception_note &&
    exceptionData.exceptionErrMessage.exception_note[0]
      ? "error-text-area"
      : "";

  const descriptionClass =
    exceptionData.exceptionErrMessage &&
    exceptionData.exceptionErrMessage.exception_note &&
    exceptionData.exceptionErrMessage.exception_note[0]
      ? "is-invalid"
      : "";

  return (
    <div className="parcel-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container exception-container mt-0">
          <BreadCrumb pageName={locationPageName} scanType={scanType} route={locationRoute} />
          <div className="bg-container-body">
            <div className="row">
              <div className="col-12">
                <h2 className="text-heading-black">Add Exception(s) for {latestScannedNumber}</h2>
              </div>
              <div className="col-12 margin-top-30">
                <span className="text-span-black-bold">Exception</span>
              </div>
              <div className="col-sm-5 col-12 margin-top-30">
                <Select
                  className={exceptionTypeClass}
                  label="Type"
                  placeholder="Please select exception type"
                  options={exceptionTypes}
                  value={exceptionData.exceptionType}
                  name="exceptionType"
                  onChange={(value) => onExceptionDataChange("exceptionType", value)}
                />
                {exceptionTypeErrorMessage && <div className="ant-form-item-explain">{exceptionTypeErrorMessage}</div>}
              </div>

              <div className="col-sm-7 d-sm-block d-none margin-top-30">
                <Input
                  className={descriptionClass}
                  label="Description"
                  placeholder="Tell us more about this parcel issue"
                  value={exceptionData.exceptionDescription}
                  onChange={(e) => onExceptionDataChange("exceptionDescription", e.target.value)}
                />
                {exceptionNoteErrorMessage && <div className="ant-form-item-explain">{exceptionNoteErrorMessage}</div>}
              </div>

              <div className="col-12 d-sm-none d-block margin-top-30">
                <label htmlFor="exception-description" className="exception-description-label">
                  Description
                </label>
                <textarea
                  id="exception-description"
                  className={"form-control exception-description-text-area " + textAreaClass}
                  rows="5"
                  placeholder="Tell us more about this parcel issue"
                  value={exceptionData.exceptionDescription}
                  onChange={(e) => onExceptionDataChange("exceptionDescription", e.target.value)}
                ></textarea>
                {exceptionNoteErrorMessage && <div className="ant-form-item-explain">{exceptionNoteErrorMessage}</div>}
              </div>
              <div className="col-12">
                <div className="d-flex flex-sm-row flex-column margin-top-30">
                  <label htmlFor="exception-image" className="upload-photo-button p-2">
                    Upload Photo
                  </label>
                  <input type="file" className="d-none" id="exception-image" onChange={handleExceptionImageChange} />
                  {!exceptionData.exceptionImageName && !exceptionData.imageInProcessing && (
                    <span className="text-span-black ml-sm-2 ml-1 pt-sm-3 pt-1">No file chosen</span>
                  )}
                  {exceptionData.exceptionImageName && (
                    <span className="text-span-black ml-sm-2 ml-1 pt-sm-3 pt-1">
                      {exceptionData.exceptionImageName}
                    </span>
                  )}
                  {exceptionData.imageInProcessing && <Spin className="ml-2" tip="Processing..." />}
                  {exceptionData.exceptionImageTypeError && (
                    <Alert className="mt-3 mb-3" type="error" message={exceptionData.exceptionImageTypeError} />
                  )}
                </div>
              </div>
              <div className="col-12">
                <Checkbox
                  className="mt-3"
                  checked={exceptionData.autoAddException}
                  onChange={(e) => onExceptionDataChange("autoAddException", e.target.checked)}
                >
                  <span className="text-span-black">Add this exception automatically to newly scanned packages</span>
                </Checkbox>
              </div>
              <div className="col-12">
                <Divider className="gray-divider" />
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                  <button className="secondary-button-small" onClick={goBackToPreviousRoute}>
                    Back
                  </button>
                  <button
                    disabled={addExceptionLoading}
                    className="primary-button mb-sm-0 mb-3"
                    onClick={onAddException}
                  >
                    {addExceptionLoading ? (
                      <LoadingOutlined className="loading-spinner loading-spinner-white" />
                    ) : (
                      "Add Exception"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {scanType === "warehouse inbound" &&
        exceptions &&
        exceptions.length > 0 &&
        exceptions.map((exception, index) => {
          return (
            <div className="d-flex flex-column align-items-center">
              <div key={index} className="bg-container exception-container mt-0">
                <div className="bg-container-body">
                  <div className="margin-top-20 bg-white">
                    <div className="d-flex flex-nowrap align-items-center">
                      <span className="toggle-heading mr-auto">Exception {exception["exception_number"]}</span>
                    </div>
                    <div className="remedy-exception-content">
                      <div className="d-flex flex-column">
                        <div className="row">
                          <div className="col-sm-3 col-6 pl-0-xs">
                            <label className="remedy-exception-label--text-label">Tracking Number</label>
                            <p className="mb-0 remedy-exception-text">{latestScannedNumber}</p>
                          </div>
                          <div className="col-sm-2 col-6">
                            <label className="remedy-exception-label--text-label">Reporter</label>
                            <p className="mb-0 remedy-exception-text">{exception["created_by"]}</p>
                          </div>
                          <div className="col-sm-3 col-6 remedy-content-spacing-xs pl-0-xs">
                            <label className="remedy-exception-label--text-label">Exception Creation Date</label>
                            <p className="mb-0 remedy-exception-text">{exception["created_on"]}</p>
                          </div>
                          <div className="col-sm-2 col-6 remedy-content-spacing-xs">
                            <label className="remedy-exception-label--text-label">Location</label>
                            <p className="mb-0 remedy-exception-text">{exception["module_name"]}</p>
                          </div>
                          <div className="col-sm-2 col-6 remedy-content-spacing-xs pl-0-xs">
                            <label className="remedy-exception-label--text-label">Type</label>
                            <p className="mb-0 remedy-exception-text">{exception["type"]}</p>
                          </div>
                          <div className="col-sm-3 col-6 remedy-content-spacing">
                            <label className="remedy-exception-label--text-label">Description</label>
                            <p className="mb-0 remedy-exception-text">{exception["note"]}</p>
                          </div>
                          <div className="col-sm-2 col-6 remedy-content-spacing pl-0-xs">
                            <label className="remedy-exception-label--text-label">Photo</label>
                            <p
                              className="mb-0 remedy-exception-text remedy-link-color"
                              onClick={() => downloadExceptionImage(exception["photo_url"])}
                            >
                              {imageFileName(exception["photo_url"])}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );

  function downloadExceptionImage(photoUrl) {
    let fileName = "image." + photoUrl.substr(photoUrl.length - 4);
    fetch(photoUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  }

  function getGroupId() {
    if (!_.isEmpty(scanSettings.groupId) || scanSettings.recipientId === undefined) {
      return scanSettings.groupId;
    } else {
      return scanSettings.recipientId;
    }
  }

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  function imageFileName(photoUrl) {
    if (photoUrl) {
      photoUrl = photoUrl.split("/");
      return photoUrl[photoUrl.length - 1];
    }
    return "";
  }

  function onExceptionDataChange(name, value) {
    setExceptionData({
      ...exceptionData,
      [name]: value,
    });
    if (name === "autoAddException") {
      const mixPanelData = getMixpanelData();
      mixPanelTrack(AUTO_ADD_EXCEPTION, {
        ...mixPanelData,
        [EXCEPTION_DATA]: value,
      });
    }
  }

  // exception image upload
  async function handleExceptionImageChange(e) {
    const acceptedTypes = ["image/jpeg", "image/png"];
    let file = e.target.files[0];
    let imageName = file.name;
    let imageType = file.type;
    let isHeicFile = false;
    if (!file) {
      setExceptionData({
        ...exceptionData,
        exceptionImage: null,
        exceptionImageName: null,
        imageType: null,
      });
      return;
    }
    setExceptionData({
      ...exceptionData,
      imageInProcessing: true,
    });
    isHeicFile = file.name.substr(file.name.length - 4) === "heic" || file.name.substr(file.name.length - 4) === "heif";

    if (!acceptedTypes.includes(file.type) && !isHeicFile) {
      let errorMessage = "Only PNG, JPEG and HEIF images are allowed";
      setExceptionImageError(errorMessage);
      return;
    } else if (isHeicFile) {
      let fileToCompress = await convertHeicToJpeg(file);
      fileToCompress = blobToFile(fileToCompress);
      imageName = imageName.substr(0, imageName.length - 4) + "jpeg";
      compressedImage(fileToCompress, imageName, fileToCompress.type);
    } else {
      compressedImage(file, imageName, imageType);
    }
  }

  function blobToFile(blob, fileName) {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }

  async function convertHeicToJpeg(file) {
    let result = await heic2any({ blob: file, toType: "image/jpeg" });
    return result;
  }

  function setExceptionImageError(errorMessage) {
    setExceptionData({
      ...exceptionData,
      exceptionImageTypeError: errorMessage,
      exceptionImage: null,
      imageType: null,
      exceptionImageName: null,
      imageInProcessing: false,
    });
    setTimeout(() => {
      setExceptionData({
        ...exceptionData,
        exceptionImageTypeError: null,
      });
    }, 2500);
  }

  function compressedImage(file, fileName, imageType) {
    let imageName = fileName;
    const fileSize = file.size / Math.pow(1024, 2);
    if (fileSize > 2) {
      const options = {
        maxSizeMB: 2,
        useWebWorker: true,
      };
      Compress(file, options)
        .then((compressedBlob) => {
          let reader = new FileReader();
          reader.readAsDataURL(compressedBlob);
          reader.onload = () => {
            let result = reader.result.replace(/^data:image.+;base64,/, "");
            setExceptionData({
              ...exceptionData,
              exceptionImage: result,
              exceptionImageName: imageName,
              imageType: imageType,
              imageInProcessing: false,
            });
          };
          reader.onerror = (error) => {
            console.log("Error: ", error);
          };
        })
        .catch((e) => {
          let errorMessage = "Something went wrong. Please upload again";
          setExceptionImageError(errorMessage);
        });
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result.replace(/^data:image.+;base64,/, "");
        setExceptionData({
          ...exceptionData,
          exceptionImage: result,
          exceptionImageName: imageName,
          imageType: imageType,
          imageInProcessing: false,
        });
        console.log("Reader result ", result);
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
  }

  function hasErrors() {
    let hasErrors = false;
    let exceptionDataClone = { ...exceptionData };
    exceptionDataClone.exceptionErrMessage = {};
    let errMsgs = {};
    if (exceptionData.exceptionType === undefined) {
      hasErrors = true;
      errMsgs.exception_type = ["Please select an exception type"];
    }
    if (_.isEmpty(exceptionData.exceptionDescription)) {
      hasErrors = true;
      errMsgs.exception_note = ["This field may not be blank"];
    }
    setExceptionData({
      ...exceptionData,
      exceptionErrMessage: errMsgs,
    });
    return hasErrors;
  }

  function onAddException() {
    if (hasErrors()) {
      return;
    }
    const data = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      group_id: getGroupId(),
      scan_type: "cs mark exceptions",
      session_id: sessionId,
      tracking_no: latestScannedNumber.trim(),
      exception_type: exceptionData.exceptionType,
      exception_note: exceptionData.exceptionDescription,
      image_name: exceptionData.exceptionImageName,
      image_data: exceptionData.exceptionImage,
      content_type: exceptionData.imageType,
      autoAddException: exceptionData.autoAddException,
    };
    props.dispatch(parcelActions.onAddException(data, exceptionNumber));
  }

  function getParams(trackingNumber) {
    let params = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      tracking_no: trackingNumber.trim(),
      scan_type: scanType,
    };
    return params;
  }
}

const mapStateToProps = ({ parcel, common, auth }) => ({
  addExceptionLoading: parcel.addExceptionLoading,
  addExceptionError: parcel.addExceptionError,
  addExceptionSuccess: parcel.addExceptionSuccess,
  exceptionNumber: parcel.exceptionNumber,
  latestScannedNumber: parcel.latestScannedNumber,
  orderData: parcel.parcelOrderData,
  exceptionTypes: common.exceptionTypes,
  scannerInformation: common.scannerInformation,
  scanSettings: parcel.scanSettingsData,
  sessionId: auth.sessionId,
  scannedNumbersList: common.scannedNumbersList,
});

export default connect(mapStateToProps, null)(AddException);
