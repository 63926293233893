/* eslint-disable react-hooks/exhaustive-deps*/
import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { Divider, Alert } from "antd";
import Input from "../../../common/input/input";
import Select from "../../../common/select/select";
import DateRangePicker from "../../../common/dateRangePicker/dateRangePicker";
import moment from "moment";
import axios from "axios";
import * as _ from "lodash";
import {
  SUCCESS,
  VISIT_REPORTS_PAGE,
  DOWNLOAD_REPORT,
  SCAN_TYPE,
  DATE_FROM,
  DATE_TO,
  ERROR,
} from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { commonApi, reportsApi } from "../../../api";
import { LoadingOutlined } from "@ant-design/icons";

export default function Reports() {
  const [recipientsList, setRecipientsList] = useState([]);
  const [scanTypes, setScanTypes] = useState([]);
  const [userData, setUserData] = useState({
    hubId: "",
    userId: "",
    userName: "",
    isLoggedIn: true,
  });
  const [state, setState] = useState({
    doFrom: getFromDateFormat(),
    doTo: getToDateFormat(),
    doGroupID: "",
    reportType: undefined,
    doErr: false,
    selectedRecipient: undefined,
    shipmentNumber: "",
    currScanType: undefined,
    showShipmentNumberEmptyError: false,
  });

  const [requestInProgress, setRequestInProgress] = useState(false);

  const reportTypes = [
    { value: "freight forwader inbound", label: "FREIGHT FORWARDER INBOUND" },
    { value: "freight forwader outbound", label: "FREIGHT FORWARDER OUTBOUND" },
    { value: "handover inbound", label: "HANDOVER INBOUND" },
    { value: "handover outbound", label: "HANDOVER OUTBOUND" },
    { value: "warehouse inbound", label: "WAREHOUSE PROCESSING" },
    { value: "ftz inbound", label: "FTZ INBOUND" },
    { value: "ftz outbound", label: "FTZ OUTBOUND" },
    { value: "warehouse outbound", label: "WAREHOUSE PROCESSED" },
    { value: "no_data", label: "NO DATA" },
    { value: "shipment_manifest", label: "SHIPMENT MANIFEST" },
    { value: "returns outbound", label: "RETURNS OUTBOUND" },
  ];

  useEffect(() => {
    getRecipientsList();
    setScanTypesForSessionStorage();
    getUserData();
    if (userData.isLoggedIn) {
      // mixpanel tracking page visit
      const mixPanelData = getMixpanelData();
      mixPanelTrack(VISIT_REPORTS_PAGE, {
        [SUCCESS]: true,
        ...mixPanelData,
      });
    }
  }, []);

  const hasZeroRecipients = state.reportType === "warehouse outbound" && recipientsList.length === 0;

  if (!userData.isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/login" />;
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="bg-container">
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="title mb-0">Reports</p>
        </div>
        <div className="bg-container-body">
          <div className="d-flex flex-column">
            <span className="text-span-black-bold">Generate a report</span>
            <span className="margin-top-20 text-span-black">You may generate a report here.</span>
            <div className="margin-top-20">
              <Select
                label="Report Type"
                placeholder="Select report type"
                value={state.reportType}
                options={reportTypes}
                onChange={(value) => onReportTypeChange("reportType", value)}
              />
            </div>
            <React.Fragment>
              {state.reportType && state.reportType !== "shipment_manifest" && (
                <React.Fragment>
                  {state.reportType === "no_data" && (
                    <div className="margin-top-20">
                      <Select
                        label="Scan Type"
                        placeholder="Please select type"
                        value={state.currScanType}
                        options={scanTypes}
                        onChange={(value) => onSelectionChange("currScanType", value)}
                      />
                    </div>
                  )}

                  {state.reportType === "warehouse outbound" && (
                    <div className="margin-top-20">
                      <Select
                        label="Send to Network Partner"
                        placeholder="Please select Network Partner"
                        value={state.selectedRecipient}
                        options={recipientsList}
                        onChange={(value) => onRecipientSelectionChange("selectedRecipient", value)}
                        disabled={state.selectedRecipient === undefined && !_.isEmpty(state.doGroupID)}
                        addEmptyOptionLabel="Please select Network Partner"
                        addEmptyOption
                      />
                    </div>
                  )}

                  {state.reportType !== "no_data" && (
                    <div className="margin-top-20">
                      <Input
                        label="Tag"
                        placeholder="Type or select tag"
                        name="doGroupID"
                        value={state.doGroupID}
                        disabled={state.selectedRecipient !== undefined && _.isEmpty(state.doGroupID)}
                        onChange={onTagInputChange}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
              {state.reportType === "shipment_manifest" && (
                <div className="margin-top-20">
                  <Input
                    label="Shipment Number"
                    placeholder="Please input the Shipment Number"
                    name="shipmentNumber"
                    value={state.shipmentNumber}
                    onChange={onInputChange}
                  />
                  {state.showShipmentNumberEmptyError && (
                    <Alert className="mt-3" type="error" message="This field should not be empty" />
                  )}
                </div>
              )}
            </React.Fragment>
            {state.reportType !== "shipment_manifest" && (
              <div className="margin-top-20">
                <DateRangePicker
                  label="Scan Date(s)"
                  format={"DD/MM/YY H:mm:ss"}
                  value={[state.doFrom, state.doTo]}
                  onChange={onDateChange}
                />
              </div>
            )}
            <Divider className="gray-divider" />
          </div>
          <button
            className="primary-button full-button-width"
            disabled={hasZeroRecipients || requestInProgress}
            onClick={downloadDO}
          >
            {requestInProgress ? (
              <LoadingOutlined className="loading-spinner loading-spinner-white" />
            ) : state.reportType === "shipment_manifest" ? (
              "Generate Download Manifest"
            ) : (
              "Generate DO/MAWB"
            )}
          </button>

          {state.reportType && (
            <div className="mt-3">
              {state.doErr && (
                <div className="alert alert-danger">
                  No data exists for your selection. Please check if the Group ID is correct and scan dates are valid.
                </div>
              )}
            </div>
          )}
          {hasZeroRecipients && (
            <Alert
              className="mt-3"
              type="error"
              message="Your hub has not configured network partners to send to. Call support immediately."
            />
          )}
        </div>
      </div>
    </div>
  );

  function onTagInputChange(e) {
    const inputVal = e.target.value.toUpperCase();
    setState({
      ...state,
      doGroupID: inputVal,
      selectedRecipient: undefined,
    });
  }

  function onInputChange(e) {
    const inputVal = e.target.value;
    setState({
      ...state,
      shipmentNumber: inputVal,
    });
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function onRecipientSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
      doGroupID: "",
    });
  }

  function onReportTypeChange(name, value) {
    setState({
      ...state,
      [name]: value,
      doGroupID: "",
      selectedRecipient: undefined,
    });
  }

  function onDateChange(dates) {
    let doFrom = dates[0];
    let doTo = dates[1];
    setState({
      ...state,
      doFrom: doFrom,
      doTo: doTo,
    });
  }

  function getAllHubs() {
    const url = reportsApi.GET_ALL_HUBS;
    return axios.get(url, {
      headers: {
        Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
      },
    });
  }

  async function getHubIdByName(hubName) {
    let hubs = await getAllHubs();
    let hubId = null;
    hubs.data.forEach((hub) => {
      if (hub.hub_id === hubName) {
        hubId = hub.id;
      }
    });
    return hubId;
  }

  async function getParams() {
    const DATETIME_FORMAT = "DD/MM/YY H:mm:ss";
    let fromDate = state.doFrom;
    const hubIDID = await getHubIdByName(userData.hubId);

    if (state.reportType === "shipment_manifest") {
      let params = {
        hub_id: userData.hubId,
        hubscanner_key: userData.userId,
        shipment_no: state.shipmentNumber,
      };
      return params;
    } else if (state.reportType === "no_data") {
      let params = {
        hub_id: userData.hubId,
        hubscanner_key: userData.userId,
        scan_from: moment(fromDate, DATETIME_FORMAT).format(),
        scan_to: moment(state.doTo, DATETIME_FORMAT).format(),
        scan_type: state.currScanType,
        // hms_hub_group_id: hubIDID + '#' + this.state.doGroupID,
      };
      return params;
    } else {
      let params = {
        hms_scan_from: moment(fromDate, DATETIME_FORMAT).format(),
        hms_scan_to: moment(state.doTo, DATETIME_FORMAT).format(),
        scan_type: state.reportType,
      };
      if (!_.isEmpty(state.doGroupID)) {
        params.hms_hub_group_id = hubIDID + "#" + state.doGroupID;
      } else {
        let selectedRecipient = state.selectedRecipient;
        if (!selectedRecipient) {
          params.hms_hub_group_id = hubIDID + "#";
        } else {
          params.hms_hub_group_id = hubIDID + "#" + selectedRecipient;
        }
      }
      return params;
    }
  }

  function getUrl() {
    if (state.reportType === "shipment_manifest") {
      return commonApi.DOWNLOAD_SHIPMENT_MANIFEST;
    } else if (state.reportType === "no_data") {
      return reportsApi.DOWNLOAD_NO_DATA_SCAN;
    }
    return reportsApi.DOWNLOAD_DELIVERY_MANIFEST;
  }

  function getFileName() {
    if (state.reportType === "shipment_manifest") {
      return "shipment-manifest.xlsx";
    } else if (state.reportType === "no_data") {
      return "no-data.xlsx";
    }
    return "manifest.xlsx";
  }

  async function downloadDO() {
    if (state.reportType === "shipment_manifest" && state.shipmentNumber === "") {
      setState({
        ...state,
        showShipmentNumberEmptyError: true,
      });
      setTimeout(() => {
        setState({
          ...state,
          showShipmentNumberEmptyError: false,
        });
      }, 5000);
      return;
    }
    setRequestInProgress(true);

    const params = await getParams();
    const url = getUrl();
    const fileName = getFileName();
    axios
      .get(url, {
        params: params,
        responseType: "blob",
        headers: {
          Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setRequestInProgress(false);
        const mixPanelData = getMixpanelData();
        mixPanelTrack(DOWNLOAD_REPORT, {
          [SUCCESS]: true,
          ...mixPanelData,
          [DATE_FROM]: moment(state.doFrom, "DD/MM/YY H:mm:ss").format(),
          [DATE_TO]: moment(state.doTo, "DD/MM/YY H:mm:ss").format(),
          [SCAN_TYPE]: state.currScanType,
        });
      })
      .catch((e) => {
        setState({
          ...state,
          doErr: true,
        });
        setRequestInProgress(false);
        const mixPanelData = getMixpanelData();
        mixPanelTrack(DOWNLOAD_REPORT, {
          [SUCCESS]: false,
          [ERROR]: e,
          ...mixPanelData,
          [DATE_FROM]: moment(state.doFrom, "DD/MM/YY H:mm:ss").format(),
          [DATE_TO]: moment(state.doTo, "DD/MM/YY H:mm:ss").format(),
          [SCAN_TYPE]: state.currScanType,
        });

        setTimeout(() => {
          setState({
            ...state,
            doErr: false,
          });
        }, 5000);
      });
  }

  function getFromDateFormat() {
    let fromDate = moment();
    fromDate.set({ hour: "00", minute: "00", second: "00" });
    return fromDate;
  }

  function getToDateFormat() {
    let toDate = moment();
    toDate.set({ hour: "23", minute: "59", second: "00" });
    return toDate;
  }

  async function getRecipientsList() {
    let recipientsList = await axios.get(commonApi.GET_RECIPIENTS);
    recipientsList = recipientsList.data;
    recipientsList = recipientsList.map((recipient) => {
      recipient.label = recipient.name;
      recipient.value = recipient.name;
      return recipient;
    });
    setRecipientsList(recipientsList);
  }

  function setScanTypesForSessionStorage() {
    let scanTypes = localStorage.getItem("scanTypes");
    scanTypes = JSON.parse(scanTypes);
    const dropdownScanTypes = scanTypes.map((scannedType) => {
      scannedType.label = scannedType.name.toUpperCase();
      scannedType.value = scannedType.name;
      return scannedType;
    });
    setScanTypes(dropdownScanTypes);
  }

  function getUserData() {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      setUserData({
        ...userData,
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
      });
    } else {
      setUserData({
        ...userData,
        isLoggedIn: false,
      });
    }
  }
}
