import {
  SCAN_SUCCESS,
  SCAN_FAIL,
  SCAN_RESET,
  FETCH_PICKUP_DELIVER_ADDRESSES,
  GET_LABEL_LINK,
  MANIFEST_CHECK_SUCCESS,
  MANIFEST_CHECK_FAIL,
  MANIFEST_DELETE,
  MANIFEST_DELETE_ALL,
  SAVE_PARCEL_SCAN_EXCEPTION,
  CLEAR_PARCEL_SCAN_EXCEPTION,
} from '../actions/types';

import store from 'store'

export default function (state = {}, action) {
  let manifestChecks = {};

  switch (action.type) {
    case SCAN_SUCCESS:
      return {
        ...state,
        response: action.payload,
        error: false
      };
    case SCAN_FAIL:
      return {
        ...state,
        response: null,
        error: true
      };
    case SCAN_RESET:
      return {
        ...state,
        response: null,
        error: null,
        status: null,
        manifestChecks: store.get('manifestChecks', { ...manifestChecks })
      };
    case FETCH_PICKUP_DELIVER_ADDRESSES:
      return {
        ...state,
        pickupDeliverAddresses: action.payload
      };
    case GET_LABEL_LINK:
      return {
        ...state,
        response: action.payload,
        status: action.status,
        error: action.error
      };
    case MANIFEST_CHECK_SUCCESS:
      if (state.manifestChecks) {
        manifestChecks = { ...state.manifestChecks }
      };
      manifestChecks[action.trackingNo] = true;
      store.set('manifestChecks', { ...manifestChecks })

      return {
        ...state,
        manifestChecks: manifestChecks,
      };
    case MANIFEST_CHECK_FAIL:
      if (state.manifestChecks) {
        manifestChecks = { ...state.manifestChecks }
      };
      manifestChecks[action.trackingNo] = false;
      store.set('manifestChecks', { ...manifestChecks })

      return {
        ...state,
        manifestChecks: manifestChecks,
      };
    case MANIFEST_DELETE:
      let trackingNo = action.trackingNo
      let removedMainfest = { ...state.manifestChecks }
      delete removedMainfest[trackingNo]
      store.set('manifestChecks', { ...removedMainfest })
      return { ...state, manifestChecks: removedMainfest }
    case MANIFEST_DELETE_ALL:
      store.set('manifestChecks', {})
      return { ...state, manifestChecks: {} }
    case SAVE_PARCEL_SCAN_EXCEPTION:
      return {
        ...state,
        exceptions: action.payload,
      }
    case CLEAR_PARCEL_SCAN_EXCEPTION:
      return {
        ...state,
        exceptions: [],
      }
    default:
      return state;
  }
}
