/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Drawer, Divider, Select } from "antd";
import { Redirect, withRouter } from "react-router";
import { cargoActions, commonActions, trolleyActions } from "../../actions/rootActions";
import { connect } from "react-redux";
import logo from "../../images/old-icons/logo_blue.png";
import i18n from "../../deprecated/i18n";
import "./style.scss";

const { Option } = Select;

const Sidebar = ({ toggleSidebar, showSidebar, ...props }) => {
  const [language, setLanguage] = useState("en");
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    if (i18n.language.includes("en") && i18n.language !== "en") {
      i18n.changeLanguage("en");
      setLanguage("en");
    } else if (i18n.language.includes("zh") && i18n.language !== "zh") {
      i18n.changeLanguage("zh");
      setLanguage("zh");
    } else if (i18n.language === "en" || i18n.language === "zh" || i18n.language === "id") {
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("hubId");
    localStorage.removeItem("scanTypes");
    //localStorage.removeItem("sessionId");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("printersList");
    localStorage.removeItem("isQzConnected");
    localStorage.removeItem("defaultScanner");
    localStorage.removeItem("defaultAudioType");
    localStorage.removeItem("requireErrorAcknowledgment");
    localStorage.removeItem("showPrintLabel");
    localStorage.removeItem("autoPrintLabel");
    localStorage.removeItem("downloadLabel");
    localStorage.removeItem("isOpen");
    setIsLoggedIn(false);
    onClickSideBarItem();
  };

  const onClickSideBarItem = () => {
    props.dispatch(commonActions.clearScannedNumbersList());
    props.dispatch(trolleyActions.resetTrolleyScanStateData());
    props.dispatch(cargoActions.clearCargoExceptions());
    toggleSidebar();
  };

  const user = localStorage.getItem("userName");
  const hubId = localStorage.getItem("hubId");

  if (!isLoggedIn) {
    return <Redirect push to="/" />;
  }

  return (
    <Drawer
      title=""
      drawerStyle={{ background: "#FFFFFF" }}
      placement="left"
      closable={false}
      onClose={toggleSidebar}
      visible={showSidebar}
    >
      <div className="sidebar">
        <div className="top-bar">
          <img style={{ padding: "5px", marginTop: "-5px", width: "90px" }} src={logo} alt="janio-logo-blue" />
        </div>
        <div className="top-bar-username">
          <p>
            Hi {user}, you are logged into <span>{hubId}</span>
          </p>
        </div>

        <ul className="nav">
          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Scans</span>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/parcel-scan')}>
                Parcel Scan
            </a>
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/cargo-scan')}>
              Cargo Scan
            </a>
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/trolley-scan')}>
              Trolley Scan
            </a>
          </li>

          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Packages</span>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/shipments')}>
              Shipments
            </a>
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/pick-list')}>
              Pick List
            </a>
          </li>
          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Scan Management</span>
          </li>
          <li className="nav-item">
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/reports')}>
              Reports
            </a>
            <a className="nav-link nav-subheading" onClick={() => handleOnLinkClick('/scan/hubmanage/settings')}>
              App Settings
            </a>     
          </li>
          <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading">Account</span>
          </li>
          <li className="nav-item">
            <span style={{ cursor: "pointer" }} className="nav-link nav-subheading" onClick={logout}>
              Logout
            </span>
          </li>
          {/* <li className="nav-item">
            <Divider className="gray-divider" />
          </li> */}
          {/* <li className="nav-item">
            <span className="text-upper-case nav-link nav-heading mt-0">Translate</span>
          </li> */}
          {/* <li className="nav-item">
            <span className="nav-link language-dropdown-label">Language</span>
            <Select onChange={onLanguageChange} value={language} className="single-select">
              <Option value="en">English</Option>
              <Option value="id">Bahasa Indonesia</Option>
              <Option value="zh">中文</Option>
            </Select>
          </li> */}
        </ul>
      </div>
    </Drawer>
  );

  // function onLanguageChange(value) {
  //   setLanguage(value);
  // }

  function handleOnLinkClick(href) {
    if (props.cargoPackageExceptions && props.cargoPackageExceptions.length > 0) {
      if (window.confirm("CSV exceptions list will be deleted once you move from this page")) {
        onClickSideBarItem();
        props.history.push(href);
      }
    } else {
      onClickSideBarItem();
      props.history.push(href);
    }
  };


};

const mapStateToProps = ({ cargo }) => ({
  cargoPackageExceptions: cargo.exceptions,
});

export default connect(mapStateToProps, null)(withRouter(Sidebar));