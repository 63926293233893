/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { Form, Divider, message } from "antd";
import { LinkContainer } from "react-router-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";
import { shipmentActions } from "../../../actions/shipmentActions";
import { connect } from "react-redux";
import Input from "../../../common/input/input";
import * as _ from "lodash";

function EditShipment(props) {
  const { partners, editShipmentLoading, editShipmentSuccess } = props;

  const shipment = props.location.state.shipment;

  const [shipmentData, setShipmentData] = useState({
    mawb_no: shipment.mawb_no,
    recipient_id: undefined,
  });

  const customizedPartners = partners.map((partner) => {
    partner.label = partner.name;
    partner.value = partner.id;
    return partner;
  });

  const [form] = Form.useForm();

  useEffect(() => {
    const shipmentRecipientId = _.find(partners, (x) => x.name.toLowerCase() === shipment.recipient_name.toLowerCase());
    setShipmentData({
      ...shipmentData,
      recipient_id: shipmentRecipientId.id,
    });
    form.setFieldsValue({
      mawb_no: shipment.mawb_no,
      recipient_id: shipmentRecipientId.id,
    });
  }, [shipment]);

  useEffect(() => {
    if (editShipmentSuccess) {
      message.success("Shipment has been edited");
      goBackToPreviousScreen();
      props.dispatch(shipmentActions.clearEditShipmentData());
      getShipmentNumbersListData();
    }
  }, [editShipmentSuccess]);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <LinkContainer to="/scan/hubmanage/shipments">
              <p className="link-title mb-0">Shipments</p>
            </LinkContainer>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">{shipment.tracking_no}</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black">Edit Shipment Details</h2>
            <Form form={form} onFinish={handleEditShipmentClick} initialValues={shipmentData}>
              <Form.Item
                name="mawb_no"
                className="margin-top-30 mb-0"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please type MAWB no first",
                  },
                ]}
              >
                <Input
                  label="Link to MAWB"
                  placeholder="Type here"
                  value={shipmentData.mawb_no}
                  onChange={(e) => onChangeShipmentData("mawb_no", e.target.value)}
                />
              </Form.Item>
              <Divider className="gray-divider" />
              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button className="secondary-button-small" onClick={goBackToPreviousScreen}>
                  Back
                </button>
                <button type="submit" className="primary-button-small mb-sm-0 mb-3" disabled={editShipmentLoading}>
                  {editShipmentLoading ? <LoadingOutlined className="loading-spinner loading-spinner-white" /> : "Edit"}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  function onChangeShipmentData(name, value) {
    setShipmentData({
      ...shipmentData,
      [name]: value,
    });
  }

  function handleEditShipmentClick() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipment.tracking_no.trim(),
      mawb_no: shipmentData.mawb_no,
      recipient_id: shipmentData.recipient_id,
    };
    onEditShipment(data);
  }

  async function onEditShipment(data) {
    props.dispatch(shipmentActions.editShipment(data));
  }

  function getShipmentNumbersListData() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: 1,
    };
    props.dispatch(shipmentActions.getShipmentNumbersList(data));
  }
}

const mapStateToProps = (state) => ({
  partners: state.shipment.partners,
  editShipmentLoading: state.shipment.editShipmentLoading,
  editShipmentSuccess: state.shipment.editShipmentSuccess,
});

export default connect(mapStateToProps)(EditShipment);
