/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { cargoActions } from "../../../actions/rootActions";
import NotificationArea from "./notificationArea";
import ActionItem from "../commonComponents/actionItem";
import Sorting from "../../../images/parcel-icons/sorting.png";

const removeCargoFromCargoPage = (props) => {
  const cargo = props.location.state.cargo;
  const { scannedNumbersList } = props;
  const numberOfScanItems = scannedNumbersList && scannedNumbersList.length > 0 ? scannedNumbersList.length : "0";

  const trackingNoInputRef = useRef(null);
  const [trackingNo, setTrackingNo] = useState("");

  const [notificationBoxType, setNotificationBoxType] = useState({
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
  });

  const [successNotificationData, setSuccessNotificationData] = useState({});
  const [errorNotificationData, setErrorNotificationData] = useState({
    errorMessage: "",
  });

  useEffect(() => {
    if (props.removeCargoFromParentSuccessData) {
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false,
      });
      setSuccessNotificationData({
        tracking_no: props.removeCargoFromParentSuccessData.scanned_tracking_no,
        successMessage: "Successfully Removed!",
      });
      setTrackingNo("");
      playSound("success");
    }
  }, [props.removeCargoFromParentSuccessData]);

  useEffect(() => {
    if (props.removeCargoFromParentErrorData) {
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
      });
      setErrorNotificationData({
        errorMessage: props.removeCargoFromParentErrorData.errorData.scanned_tracking_no[0],
        jsonBody: props.removeCargoFromParentErrorData.jsonBody,
      });
      setTrackingNo("");
      playSound("error");
    }
  }, [props.removeCargoFromParentErrorData]);

  useEffect(() => {
    return () => {
      props.dispatch(cargoActions.removeCargoResetState());
    };
  }, []);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
        <p className="link-title mb-0" onClick={onCargoClick}>
          Cargo
        </p>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="link-title text-capitalize mb-0" onClick={onCargoClick}>
          {cargo.tracking_no}
        </p>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="title text-capitalize mb-0">Remove Parcel/Cargo</p>
      </div>
      {mainContent()}
    </div>
  );

  function onCargoClick() {
    goBackToPreviousScreen();
  }

  function mainContent() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">{notificationAndScanInputField()}</div>
        <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">
          <ActionItem
            to={getScannedNumberListHref()}
            imageSrc={Sorting}
            itemText={`${numberOfScanItems} Scanned in This Session`}
          />
        </div>
      </div>
    );
  }

  function notificationAndScanInputField() {
    const noDataBoxClass = notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
    const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
    const validDataBoxClass = notificationBoxType.showValidDataBox ? "d-flex flex-column" : "d-none";
    return (
      <NotificationArea
        trackingNoInputRef={trackingNoInputRef}
        trackingNo={trackingNo}
        setTrackingNo={setTrackingNo}
        notificationBoxType={notificationBoxType}
        noDataBoxClass={noDataBoxClass}
        errorDataBoxClass={errorDataBoxClass}
        validDataBoxClass={validDataBoxClass}
        successNotificationData={successNotificationData}
        errorNotificationData={errorNotificationData}
        onPressEnter={onRemoveCargoFromCargo}
        onChangeScanNumberCameraChange={onChangeScanNumberCameraChange}
      />
    );
  }

  function onRemoveCargoFromCargo(scannedTrackingNo) {
    const trackingCodeToSend = scannedTrackingNo ? scannedTrackingNo : trackingNo;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargo.tracking_no.trim(),
      scanned_tracking_no: trackingCodeToSend.trim(),
    };
    props.dispatch(cargoActions.removeCargoFromParent(data, scannedNumbersList));
  }

  function onChangeScanNumberCameraChange(scanResult) {
    const scannedNumber = scanResult.text;
    setTrackingNo(scannedNumber);
    onRemoveCargoFromCargo(scannedNumber);
  }

  function getScannedNumberListHref() {
    return {
      pathname: "/scan/hubmanage/scanned-numbers-list",
      state: { scanType: "sweep", pageName: "Cargo Scan", route: "/scan/hubmanage/cargo-scan" },
    };
  }

  function playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }
};

const mapStateToProps = (state) => ({
  removeCargoFromParentSuccessData: state.cargo.removeCargoFromParentSuccessData,
  removeCargoFromParentErrorData: state.cargo.removeCargoFromParentErrorData,
  scannedNumbersList: state.common.scannedNumbersList,
});

export default connect(mapStateToProps)(removeCargoFromCargoPage);
