export const formatCargoData = (cargoData) => {
  return cargoData.map((data) => ({
    trackingNo: data.tracking_no,
    cargoType: data.cargo_type,
    isExceptionResolved: data.exceptions.length > 0 ? "Yes" : "No",
    exceptionType: data.exceptions.length > 0 ? data.exceptions.map((exception) => exception.name).join(";") : "-",
    description: data.exceptions.length > 0 ? data.exceptions.map((exception) => exception.description).join(";") : "-",
    numberOfExceptions: data.exceptions.length > 0 ? data.exceptions.length : "-",
  }));
};
