import React from 'react';
import DigitalInfrastructure from '../../../images/parcel-icons/digital-infrastructure.png';
import Sorting from '../../../images/parcel-icons/sorting.png';
import LargeWarehouse from '../../../images/parcel-icons/large-warehouse.png';
import { Link } from 'react-router-dom';

export default function TrolleyActionItemsList(props) {
  const {
    showActionItems,
    onClickActionItem,
    scannedNumbersList,
    scanSettings,
    errorOnScanning,
    scanType,
    pageName
  } = props;
  
  const numberOfScanItems = scannedNumbersList &&
    scannedNumbersList.length > 0 ? scannedNumbersList.length : '0';
  const showActionItem = !errorOnScanning && showActionItems;

  return (
    <div className="d-flex flex-column">
      <div className="action-item-box-shadow">
        {showActionItem &&
        <Link to={getScannedNumberListHref()}>
          <div className="d-flex align-items-center action-item margin-bottom-20">
            <img className="action-item-image" src={Sorting} alt="" />
            <h2
              className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">
              {numberOfScanItems} Scanned in This Session
            </h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>}
      </div>
      <div className="action-item-box-shadow">
        {showActionItem &&
        <Link to={getAddLocationHref()}>
          <div className="d-flex align-items-center action-item margin-bottom-20">
            <img className="action-item-image" src={LargeWarehouse} alt="" />
            <h2
              className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">
              Add Location to Packages
            </h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>}
      </div>
      <div className="action-item-box-shadow">
        <Link to={getSettingsHref()}>
          <div className="d-flex align-items-center action-item">
            <img className="action-item-image" src={DigitalInfrastructure} alt="" />
            <div className="d-flex flex-grow-1 align-items-center">
              <h2 className="text-heading-black text-heading-black-font-sm">Settings</h2>
            </div>
            {scanSettings.linkToShipment === undefined
              && scanSettings.recipientId === null
              && scanSettings.groupId === '' &&
              <button className="action-settings-buttons">None</button>}
            {scanSettings.groupId !== '' &&
              <button className="action-settings-buttons-color action-settings-buttons-color-margin-left">
                Tagged
            </button>}
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
      </div>
    </div>
  );

  function getScannedNumberListHref() {
    return {
      pathname: '/scan/hubmanage/scanned-numbers-list',
      state: { scanType: scanType, pageName: pageName }
    }
  }

  function getSettingsHref() {
    return {
      pathname: '/scan/hubmanage/trolley-scanner/settings',
      state: { scanType: scanType, pageName: pageName }
    }
  }

  function getAddLocationHref() {
    return {
      pathname: '/scan/hubmanage/trolley-scanner/add-location',
      state: { scanType: scanType, pageName: pageName }
    }
  }
}