import { shipmentTypes } from "../types";

export default function (
  state = {
    shipmentNumbersList: {
      shipments: []
    },
    shipmentNumbersListError: false,
    partners: [],
    createShipmentProgress: false,
    shipment_no: null,
    createShipmentErr: null,
    resetState: false,
    shipmentCancelLoading: false,
    shipmentCancelSuccessData: null,
    shipmentCancelErrorData: null,
    partnersForHub: [],
    partnersForHubError: null,
    pendingShipmentsData: null,
    inProgressShipmentsData: null,
    shipmentCargosLoading: false,
    shipmentCargosData: {},
    shipmentCargosNumbersList: [],
    shipmentCargosWeight: null,
    shipmentCargosError: false,

    editShipmentLoading: false,
    editShipmentSuccess: false,
    editShipmentError: false,

    removeCargoFromShipmentLoading: false,
    removeCargoFromShipmentSuccess: false,
    removeCargoFromShipmentError: false
  },
  action
) {
  switch (action.type) {
    case shipmentTypes.GET_SHIPMENT_NUMBERS_LIST_SUCCESS:
      return { ...state, shipmentNumbersList: action.payload };
    case shipmentTypes.GET_SHIPMENT_NUMBERS_LIST_ERROR:
      return { ...state, shipmentNumbersListError: action.payload };
    
    case shipmentTypes.GET_SHIPMENT_PARTNERS:
      return { ...state, partners: action.payload };
    case shipmentTypes.GET_SHIPMENT_PARTNERS_ERROR:
      return { ...state, partners: [], error: "An error occured" };
    
    case shipmentTypes.GET_SHIPMENT_HUB_PARTNERS:
      return { ...state, partnersForHub: action.payload };
    case shipmentTypes.GET_SHIPMENT_HUB_PARTNERS_ERROR:
      return { ...state, partnersForHub: [], partnersForHubError: "An error occured" };
    
    case shipmentTypes.CREATE_SHIPMENT_LOADING:
      return { ...state, createShipmentProgress: true };
    case shipmentTypes.CREATE_SHIPMENT:
      return { ...state, shipment_no: action.payload, createShipmentProgress: false };
    case shipmentTypes.CREATE_SHIPMENT_ERROR:
      return { ...state, createShipmentErr: action.payload, createShipmentProgress: false };
    
    case shipmentTypes.CLEAR_SHIPMENT_ERROR:
      return { ...state, createShipmentErr: null, createShipmentProgress: false };
    case shipmentTypes.CLEAR_SHIPMENT_RESPONSE:
      return { ...state, createShipmentProgress: false, shipment_no: null, createShipmentErr: null, resetState: true };
    case shipmentTypes.SHIPMENT_RESET_STATE:
      return { ...state, resetState: false, shipmentCancelSuccess: null, shipmentCancelError: null };
    
    case shipmentTypes.CANCEL_SHIPMENT_LOADING:
      return { ...state, shipmentCancelLoading: true };
    case shipmentTypes.CANCEL_SHIPMENT_SUCCESS:
      return { ...state, shipmentCancelLoading: false, shipmentCancelSuccessData: action.payload };
    case shipmentTypes.CANCEL_SHIPMENT_ERROR:
      return { ...state, shipmentCancelLoading: false, shipmentCancelErrorData: action.payload };
    case shipmentTypes.CANCEL_SHIPMENT_RESET:
      return { ...state, shipmentCancelLoading: false, shipmentCancelSuccessData: null, shipmentCancelErrorData: null };
    
    case shipmentTypes.GET_PENDING_SHIPMENT_DATA_SUCCESS:
      return { ...state, pendingShipmentsData: action.payload };
    case shipmentTypes.GET_PENDING_SHIPMENT_DATA_ERROR:
      return { ...state, pendingShipmentsData: null };
    
    case shipmentTypes.GET_IN_PROGRESS_SHIPMENT_DATA_SUCCESS:
      return { ...state, inProgressShipmentsData: action.payload };
    case shipmentTypes.GET_IN_PROGRESS_SHIPMENT_DATA_ERROR:
      return { ...state, inProgressShipmentsData: null };
    
    case shipmentTypes.GET_SHIPMENT_CARGOS_LOADING:
      return { ...state, shipmentCargosLoading: true, shipmentCargosError: false };
    case shipmentTypes.GET_SHIPMENT_CARGOS_SUCCESS:
      return { ...state, shipmentCargosLoading: false, shipmentCargosData: action.payload.cargoData, shipmentCargosNumbersList: action.payload.cargoNumbersList, shipmentCargosWeight: action.payload.shipmentWeight };
    case shipmentTypes.GET_SHIPMENT_CARGOS_ERROR:
      return { ...state, inProgressShipmentsData: null };
    
    case shipmentTypes.EDIT_SHIPMENT_LOADING:
      return { ...state, editShipmentLoading: true };
    case shipmentTypes.EDIT_SHIPMENT_SUCCESS:
      return { ...state, editShipmentLoading: false, editShipmentSuccess: true };
    case shipmentTypes.EDIT_SHIPMENT_ERROR:
      return { ...state, editShipmentLoading: false, editShipmentError: action.payload };
    case shipmentTypes.CLEAR_EDIT_SHIPMENT_DATA:
      return { ...state, editShipmentLoading: false, editShipmentSuccess: false, editShipmentError: false };

    case shipmentTypes.REMOVE_CARGO_FROM_SHIPMENT_LOADING:
      return { ...state, removeCargoFromShipmentLoading: true };
    case shipmentTypes.REMOVE_CARGO_FROM_SHIPMENT_SUCCESS:
      return { ...state, removeCargoFromShipmentLoading: false, removeCargoFromShipmentSuccess: true };
    case shipmentTypes.REMOVE_CARGO_FROM_SHIPMENT_ERROR:
      return { ...state, removeCargoFromShipmentLoading: false, removeCargoFromShipmentError: action.payload };
    case shipmentTypes.CLEAR_REMOVE_CARGO_FROM_SHIPMENT_DATA:
      return { ...state, removeCargoFromShipmentLoading: false, removeCargoFromShipmentSuccess: false, removeCargoFromShipmentError: false };
    
    default:
      return state;
  }
}