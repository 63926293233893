import React from "react";
import { Link } from "react-router-dom";
import InsideThisShipment from "../../../images/shipment-icons/inside-this-shipment.png";
import CreateCargo from "../../../images/shipment-icons/create-cargo-in-this-shipment.png";
import EditShipment from "../../../images/shipment-icons/edit-shipment.png";
import CancelShipment from "../../../images/shipment-icons/cancel-shipment.png";
import AddCargo from "../../../images/shipment-icons/add-parcel-cargo-to-shipment.png";
import RemoveCargo from "../../../images/shipment-icons/remove-parcel-cargo-from-shipment.png";


export default function ActionItemsList(props) {
  const { shipment } = props;

  return (
    <div className="d-flex flex-column">
      <div className="action-item-box-shadow">
        <Link to={getInsideThisShipmentHref()}>
          <div className="d-flex align-items-center action-item">
            <img className="action-item-image" alt="" src={InsideThisShipment} />
            <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">Inside This Shipment</h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
      </div>
      <div className="action-item-box-shadow">
        <Link to={getCreateCargoHref()}>
          <div className="d-flex align-items-center action-item action-item-margin">
            <img className="action-item-image" alt="" src={CreateCargo} />
            <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">
              Create Cargo in This Shipment
            </h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
        <Link to={getEditShipmentHref()}>
          <div className="d-flex align-items-center action-item action-item-top-border">
            <img className="action-item-image" alt="" src={EditShipment} />
            <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1">Edit Shipment</h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
        <Link to={getCancelShipmentHref()}>
          <div className="d-flex align-items-center action-item action-item-top-border">
            <img className="action-item-image" alt="" src={CancelShipment} />
            <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">Cancel Shipment</h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
      </div>
      <div className="action-item-box-shadow">
        <Link to={getAddCargoToShipmentHref()}>
          <div className="d-flex align-items-center action-item action-item-margin">
            <img className="action-item-image" alt="" src={AddCargo} />
            <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">
              Add Parcel/Cargo To Shipment
            </h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
        <Link to={getRemoveCargoFromShipmentHref()}>
          <div className="d-flex align-items-center action-item action-item-top-border">
            <img className="action-item-image" alt="" src={RemoveCargo} />
            <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">
              Remove Parcel/Cargo From Shipment
            </h2>
            <span className="action-item-arrow">{'>'}</span>
          </div>
        </Link>
      </div>
    </div>
  );

  function getInsideThisShipmentHref() {
    return {
      pathname: '/scan/hubmanage/inside-shipment',
      state: { shipment: shipment }
    }
  }

  function getCancelShipmentHref() {
    return {
      pathname: '/scan/hubmanage/cancel-shipment',
      state: { shipment: shipment }
    }
  }

  function getCreateCargoHref() {
    return {
      pathname: '/scan/hubmanage/create-cargo-in-shipment',
      state: { shipment: shipment }
    }
  }

  function getEditShipmentHref() {
    return {
      pathname: '/scan/hubmanage/edit-shipment',
      state: { shipment: shipment }
    }
  }

  function getAddCargoToShipmentHref() {
    return {
      pathname: '/scan/hubmanage/add-cargo-to-shipment',
      state: { shipment: shipment }
    }
  }

  function getRemoveCargoFromShipmentHref() {
    return {
      pathname: '/scan/hubmanage/remove-cargo-from-shipment',
      state: { shipment: shipment }
    }
  }
}