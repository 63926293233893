import React, { useState } from "react";
import { Collapse, Pagination, notification, Modal } from "antd";
import { ROOT_URL } from "../../../actions/index";
import axios from "axios";

const { Panel } = Collapse;

export default function ShipmentToPartner(props) {
  const {
    newShipmentNo,
    selectedRecipientName,
    cargoNumbersList,
    cargoNumbersData,
    manifestDownloadError,
    shipmentWeightNumber,
    shipmentMawbLinkedNumbers,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedTrackingNo, setSelectedTrackingNo] = useState(null);
  const [cancelParcelError, setCancelParcelError] = useState(false);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const cargoNumbersClass = cancelParcelError ? "" : "mt-5";

  return (
    <div className="content-container card mt-2 mb-1 p-2 cnt-2 d-flex flex-column">
      <div className="d-flex flex-column align-items-center mt-2">
        <h4 className="shipment-to-partner">Shipment to {selectedRecipientName}</h4>
        <h5 className="shipment-to-partner">{newShipmentNo}</h5>
      </div>
      <div className="row p-2">
        <div className="col-6">
          <span className="partner-shipments-text pointer" onClick={props.handleLinkToMawbClick}>
            {shipmentMawbLinkedNumbers && shipmentMawbLinkedNumbers.length > 0
              ? `MAWB ${shipmentMawbLinkedNumbers[0].mawb_no}`
              : "Link to MAWB"}
          </span>
        </div>
        <div className="col-6 text-right">
          <span className="partner-shipments-text pointer" onClick={props.handleUpdateWeightClick}>
            {shipmentWeightNumber ? `${shipmentWeightNumber} KG` : "Update Shipment Weight"}
          </span>
        </div>
      </div>
      {shipmentMawbLinkedNumbers && shipmentMawbLinkedNumbers.length > 1 && (
        <div className="mt-2 mb-2">
          <Collapse>
            <Panel header="MAWB History">
              {shipmentMawbLinkedNumbers.map((number, index) => {
                let date = new Date(number.updated_on);
                return (
                  <div key={index} className="mr-2">
                    MAWB {number.mawb_no} -
                    {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()} at ${getFormatTime(date)}`}
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        </div>
      )}
      <div className="d-flex justify-content-between p-2">
        <button className="btn cargo-button" onClick={props.showCreateCargo}>
          Create Cargo
        </button>
        <button className="btn cargo-button" onClick={props.downloadManifest}>
          Download Manifest
        </button>
        <button className="btn cargo-button" onClick={props.showAddCargoToShipment}>
          Add Cargo
        </button>
      </div>
      {manifestDownloadError && (
        <div className="d-flex justify-content-between p-2 mt-3">
          <div className="alert alert-danger w-100 text-center">No data exists.</div>
        </div>
      )}
      {cancelParcelError && (
        <div className="d-flex justify-content-between p-2 mt-3">
          <div className="alert alert-danger w-100 text-center">An error occurred. Please try again.</div>
        </div>
      )}
      {cargoNumbersList && cargoNumbersList.length > 0 && (
        <React.Fragment>
          <div className={"w-100 cargo-bg text-dark text-center pt-2 " + cargoNumbersClass}>
            <h5 className="added-to-cargo-text">{cargoNumbersList.length} Added To This Shipment</h5>
          </div>
          <div className="mb-1 p-2">
            <div className="d-flex flex-column mb-2 p-3">
              {cargoNumbersList.map((number, index) => {
                let className = getClassName(number.scanned_tracking_no);
                return (
                  <div className="d-flex align-items-center justify-content-between mb-3" key={index}>
                    <h6 className={`cargo-tracking-number ${className}`} onClick={(e) => handleCargoClick(e, number)}>
                      {number.scanned_tracking_no}
                    </h6>
                    <button
                      className={"btn delete-parcel-button " + getDeleteButtonClass(number.scanned_tracking_no)}
                      onClick={() => showDeleteConfirmationModal(number.scanned_tracking_no)}
                    >
                      Delete
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </React.Fragment>
      )}
      {cargoNumbersData && cargoNumbersList && cargoNumbersList.length > 0 && (
        <div className="ml-4 mb-3 pt-0">
          <Pagination
            current={currentPage}
            defaultPageSize={50}
            total={cargoNumbersData.total_records}
            onChange={onPageChange}
            className="shipment-pagination"
            responsive
          />
        </div>
      )}
      <div style={{ textAlign: "end", marginTop: "auto" }}>
        <div className="d-flex justify-content-between">
          <button className="btn shipment-back-button ml-2" onClick={props.showShipmentPage}>
            Back
          </button>
          <button className="btn cancel-cargo" onClick={props.cancelShipment}>
            Cancel Shipment
          </button>
        </div>
      </div>
      <Modal
        className="confirmation-modal"
        visible={showConfirmationModal}
        okText="Yes"
        okType="success"
        okButtonProps={{ className: "okButton" }}
        cancelText="No, I don’t want to delete"
        cancelButtonProps={{ className: "cancelButton" }}
        onOk={cancelParcel}
        onCancel={onCancelModal}
      >
        <p>Are you sure you want to delete this parcel?</p>
      </Modal>
    </div>
  );

  function showDeleteConfirmationModal(scanned_tracking_no) {
    setSelectedTrackingNo(scanned_tracking_no);
    setShowConfirmationModal(true);
  }

  function cancelParcel() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: newShipmentNo,
      scanned_tracking_no: selectedTrackingNo,
    };
    axios
      .put(`${ROOT_URL}/hms/remove_from_shipment/`, data)
      .then((res) => {
        setSelectedTrackingNo(null);
        setShowConfirmationModal(false);
        notification.success({
          message: res.data.message,
        });
        props.getShipmentTrackingNumber(selectedRecipientName, newShipmentNo, currentPage);
      })
      .catch((err) => {
        setSelectedTrackingNo(null);
        setShowConfirmationModal(false);
        setCancelParcelError(true);
        setTimeout(() => {
          setCancelParcelError(false);
        }, 2500);
      });
  }

  function onCancelModal() {
    setShowConfirmationModal(false);
    setSelectedTrackingNo(null);
  }

  function onPageChange(page) {
    setCurrentPage(page);
    props.getShipmentTrackingNumber(selectedRecipientName, newShipmentNo, page);
  }

  // hack to check cargo number prefix
  function getClassName(cargoNumber) {
    if (cargoNumber.substr(0, 3) == "JNC") {
      return "pointer shipment-link-color";
    }
    return "";
  }

  function getDeleteButtonClass(cargoNumber) {
    if (cargoNumber.substr(0, 3) == "JNC") {
      return "d-none";
    }
    return "";
  }

  function handleCargoClick(e, cargoNumber) {
    if (cargoNumber.scanned_tracking_no.substr(0, 3) == "JNC") {
      let pageNumber = 1;
      props.showCargoChildrenPage(e, cargoNumber, pageNumber);
    }
  }

  // get mawb date history
  function getFormatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
}

