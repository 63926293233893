import React, { Component } from "react";

import '../../node_modules/normalize.css/normalize.css';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../style/style.css';
import '../style/common.scss';
import '../style/common-scanner.scss';

class App extends Component {
  render() {
    return <div className="root-child-main-div">{this.props.children}</div>;
  }
}
export default App;
