import React, { useState } from 'react';
import { Form, Input, Switch, Pagination } from 'antd';
import ZebraScanner from '../../../common/zebraScanner/ZebraScanner';


export default function AddCargo(props) {
  let {
    addCargoError,
    newShipmentNo,
    selectedRecipientName,
    cargoNumbersList,
    cargoNumbersData,
    addCargoRef,
    cargoNumber,
    addCargoToShipmentData
  } = props;

  const [showCameraToScan, setShowCameraToScan] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className="mb-2">
      <div className="content-container card mt-2 p-2">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column align-items-center mt-2">
            <h4 className="add-cargo-label">Add Cargo/Package</h4>
            <h5 className="add-cargo-label">
              Shipment to {selectedRecipientName}
            </h5>
            <h5 className="add-cargo-label add-cargo-tracking-number pointer" onClick={props.handleShipmentNumberClick}>
              {newShipmentNo}
            </h5>
          </div>
        </div>
      </div>
      {
        addCargoError ? <div id="error-data-box" className="content-container card mb-1 error-data-container">
          <div className="d-block justify-content-between px-3.5 scan-info-screen scan-error-info">
            <div id="err-image" className="err-img err-no-data">
              &nbsp;
              </div>
            <label className="err-title pl-5 pr-5">
              {addCargoError.scanned_tracking_no && addCargoError.scanned_tracking_no[0]}
              {addCargoError.shipment_no && addCargoError.shipment_no[0]}
            </label>
          </div>
        </div> : addCargoToShipmentData ? (
          <div
            id="valid-data-box"
            className="content-container card mb-1 valid-sort-container w-100"
          >
            <div className="d-flex flex-column justify-content-center align-items-center px-3.5 scan-info-screen">
              <h4 className="font-weight-bold text-white">
                {addCargoToShipmentData.scanned_tracking_no}
              </h4>
              <h4 className="font-weight-bold text-white">
                Successfully Added!
              </h4>
            </div>
          </div>
        ) : (
              <div id="no-data-box" className="content-container card mb-1">
                <div className="d-block justify-content-between px-3.5 scan-info-screen scan-no-info">
                  <label style={{ marginTop: "95px" }}>No data shown here</label>
                </div>
              </div>
            )
      }
      <div className="content-container card mb-1 p-2">
        {showCameraToScan ?
          <ZebraScanner onScanComplete={onScanComplete} />
        :
          <Form ref={addCargoRef} name="control-ref" onFinish={props.addCargo}>
            <div className="d-flex flex-nowrap px-3.5 m-2 add-cargo-container">
              <Form.Item
                className="w"
                name="cargoNumber"
                rules={[{ required: true, message: 'This field may not be blank' }]}
              >
                <Input
                  name="cargoNumber"
                  placeholder="Tracking No(s)"
                  onChange={props.handleChangeCargoNumber}
                  value={cargoNumber}
                  autoFocus
                />
              </Form.Item>
              <div>
                <button
                  type="submit"
                  className="ml-1 btn btn-janio text-white"
                >
                  Add
                </button>
              </div>
            </div>
          </Form>
        }

        <div className="form-group d-block justify-content-between px-3.5 m-2">
          <b>Laser</b> <Switch className="scanner-switch" onChange={onChangeSwitch} /> <b>Camera</b>
        </div> 

      </div>
      {
        cargoNumbersList.length > 0 && (
          <React.Fragment>
            <div className="content-container card w-100 cargo-bg text-dark text-center pt-2">
              <h5 className="added-to-cargo-text">{cargoNumbersList.length} Added To This Shipment</h5>
            </div>
            <div className="content-container card mb-1 p-2">
              <div className="d-flex flex-column align-items-center mb-2 p-3">
                {cargoNumbersList.map((number, index) => {
                  return (
                    <h6 key={index} className="cargo-tracking-number mb-3">
                      {number.scanned_tracking_no}
                    </h6>
                  );
                })}
              </div>
              {
                cargoNumbersData && cargoNumbersList && cargoNumbersList.length > 0 &&
                <div className="ml-4 mb-3 pt-0">
                  <Pagination
                    current={currentPage}
                    defaultPageSize={50}
                    total={cargoNumbersData.total_records}
                    onChange={onPageChange}
                    className="shipment-pagination"
                    responsive
                  />
                </div>
              }
            </div>
          </React.Fragment>
        )
      }
    </div>
  );

  function onPageChange(page) {
    setCurrentPage(page);
    props.getShipmentTrackingNumber(selectedRecipientName, newShipmentNo, page)
  }

  function onChangeSwitch(checked) {
    setShowCameraToScan(checked)
  }

  function onScanComplete(scanResult) {
    let trackingNumber = scanResult.text;
    props.setAddCargoToShipmentNumber(trackingNumber);
  }
}