import { trolleyTypes } from "../types";

const initialState = {
  fetchTrolleyPackagesLoading: false,
  fetchTrolleyPackagesSuccess: false,

  latestScannedNumber: null,
  orderData: {},

  errorNotificationData: {},
  notificationBoxType: {
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
  },

  addCargoToTrolleyLoadng: false,
  addCargoToTrolleySuccess: false,
  addCargoToTrolleyError: false,

  addLocationLoading: false,
  addLocationError: null,
  addLocationSuccess: false,

  scanSettingsData: {
    groupId: "",
    scanNumber: ""
  }
}

const trolleyReducer = (state = initialState, action) => {
  switch (action.type) {
    case trolleyTypes.FETCH_TROLLEY_PACKAGES_LOADING:
      return { ...state, fetchTrolleyPackagesLoading: true };
    case trolleyTypes.FETCH_TROLLEY_PACKAGES_SUCCESS:
      return { ...state, fetchTrolleyPackagesLoading: false, fetchTrolleyPackagesSuccess: true, errorNotificationData: {}, latestScannedNumber: action.payload.latestScannedNumber, orderData: action.payload.orderData, notificationBoxType: action.payload.notificationBoxType };
    case trolleyTypes.FETCH_TROLLEY_PACKAGES_FAILURE:
      return { ...state, fetchTrolleyPackagesLoading: false, fetchTrolleyPackagesSuccess: false, errorNotificationData: action.payload.errorNotificationData, orderData: {}, notificationBoxType: action.payload.notificationBoxType };
    case trolleyTypes.CLEAR_FETCH_TROLLEY_PACKAGES_DATA:
      return { ...state, fetchTrolleyPackagesLoading: false, fetchTrolleyPackagesSuccess: false };

    case trolleyTypes.ADD_CARGO_TO_TROLLEY_LOADING:
      return { ...state, addCargoToTrolleyLoadng: true };
    case trolleyTypes.ADD_CARGO_TO_TROLLEY_SUCCESS:
      return { ...state, addCargoToTrolleyLoadng: false, addCargoToTrolleySuccess: true, errorNotificationData: {}, notificationBoxType: action.payload.notificationBoxType };
    
    case trolleyTypes.ADD_CARGO_TO_TROLLEY_FAILURE:
      return {  ...state, addCargoToTrolleyLoadng: false, addCargoToTrolleySuccess: false, addCargoToTrolleyError: action.payload };
    
    case trolleyTypes.CLEAR_ADD_CARGO_TO_TROLLEY_DATA:
      return { ...state, addCargoToTrolleyLoadng: false, addCargoToTrolleySuccess: false, addCargoToTrolleyError: false };

    case trolleyTypes.ADD_LOCATION_LOADING:
      return { ...state, addLocationLoading: true, addLocationSuccess: false }
    case trolleyTypes.ADD_LOCATION_SUCCESS:
      return { ...state, addLocationLoading: false, addLocationSuccess: true, addLocationError: null };
    case trolleyTypes.ADD_LOCATION_FAILURE:
      return { ...state, addLocationLoading: false, addLocationSuccess: false, addLocationError: action.payload };
    case trolleyTypes.CLEAR_ADD_LOCATION_DATA:
      return { ...state, addLocationLoading: false, addLocationSuccess: false, addLocationError: null };
    
    case trolleyTypes.CLEAR_TROLLEY_SCAN_DATA:
      return { ...initialState };
    
    default:
      return state;
  }
}

export { trolleyReducer }