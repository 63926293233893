/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { shipmentActions } from "../../../actions/rootActions";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { SHIPMENT_DETAILS_PAGE, SHIPMENT_TRACKING_NUMBER } from "../../../constants/mixpanelConstants";
import ActionItemsList from "./actionItemsList";
import ShipmentDetails from "./shipmentDetails";
import * as _ from "lodash";
import "./style.scss";

function ShipmentDetailsPage(props) {
  const { shipmentWeight } = props;

  const [pageNumber] = useState(1);

  const shipment = getShipment();

  useEffect(() => {
    // mixpanel tracking page visit
    const mixPanelData = getMixpanelData();
    mixPanelTrack(SHIPMENT_DETAILS_PAGE, {
      ...mixPanelData,
      [SHIPMENT_TRACKING_NUMBER]: props.location.state.shipment.tracking_no,
    });
    props.dispatch(shipmentActions.fetchRecipients());
  }, []);

  useEffect(() => {
    if (props.shipmentNumbersList && props.shipmentNumbersList.shipments.length === 0) {
      getShipmentNumbersListData();
    }
  }, []);

  useEffect(() => {
    fetchShipmentTrackingNumber(props.location.state.shipment.tracking_no, pageNumber);
  }, []);

  return <div className="scanner-bg-container">{mainContent()}</div>;

  function mainContent() {
    return (
      <React.Fragment>
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <LinkContainer to="/scan/hubmanage/shipments">
            <p className="link-title mb-0">Shipments</p>
          </LinkContainer>
          <p className="bread-crumb mt-n1 mb-0">{">"}</p>
          <p className="title text-capitalize mb-0">{shipment.tracking_no}</p>
        </div>

        <div className="row">
          <div className="col-md-6 col-sm-12">{shipmentDetailsContent()}</div>
          <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">{actionItemsList()}</div>
        </div>
      </React.Fragment>
    );
  }

  function shipmentDetailsContent() {
    return <ShipmentDetails shipment={shipment} shipmentWeight={shipmentWeight} />;
  }

  function actionItemsList() {
    return <ActionItemsList shipment={shipment} />;
  }

  async function fetchShipmentTrackingNumber(shipmentNumber, page) {
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipmentNumber.trim(),
      page: page,
    };
    props.dispatch(shipmentActions.getShipmentCargosList(data));
  }

  function getShipmentNumbersListData() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: 1,
    };
    props.dispatch(shipmentActions.getShipmentNumbersList(data));
  }

  function getShipment() {
    const dummyObj = { tracking_no: "" };
    const selectedShipment = _.find(
      props.shipmentNumbersList.shipments,
      (x) => x.tracking_no === props.location.state.shipment.tracking_no
    );
    return selectedShipment ? selectedShipment : dummyObj;
  }
}

const mapStateToProps = (state) => ({
  shipmentNumbersList: state.shipment.shipmentNumbersList,
  addCargoTrackingNumber: state.cargo.addCargoTrackingNumber,
  addCargoError: state.cargo.addCargoError,
  cargoResetState: state.cargo.resetState,
  shipmentWeight: state.shipment.shipmentCargosWeight,
});

export default connect(mapStateToProps)(ShipmentDetailsPage);
