/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, DatePicker, Dropdown, Menu, Pagination, Select, Divider } from "antd";
import { isEqual } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { downloadPicklist, getActionRequestCount, getPicklistData } from "../../../actions/picklistActions";
import MultiSelect from "../../../common/multiSelect/multiSelect";
import TextArea from "../../../common/textArea/index";
import "../../../style/common.scss";
import { mapStringToArray } from "../../../utils/mapStringToArray";
import { paginateArrayElements } from "../../../utils/pagination";
import "./style.scss";
import { SUCCESS, VISIT_PICKLIST_PAGE } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";

const { RangePicker } = DatePicker;
const { Option } = Select;

const PickList = (props) => {
  const { picklistData, picklistDataDownloadError, picklistActionRequestCount } = props;

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [picklist, setPicklist] = useState([]);
  const [downloadErr, setDownloadErr] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [actionRequestCount, setActionRequestCount] = useState(null);
  const [filteredTrackingNumbers, setFilteredTrackingNumbers] = useState("");
  const [filteredLocations, setFilteredLocations] = useState("");
  const [filteredActionRequest, setFilteredActionRequest] = useState([]);
  const [filteredDateRange, setFilteredDateRange] = useState([]);
  const [showCSVModal, setShowCsvModal] = useState(false);
  const [downloadFilteredData, setDownloadFilteredData] = useState(true);

  const [dayFilter, setDayFilter] = useState("today");

  const [menuVisible, setMenuVisible] = useState({
    trackingCodeVisible: false,
    locationFilterVisible: false,
    requestFilterVisible: false,
    dateRangerFilterVisible: false,
  });

  useEffect(() => {
    if (!localStorage.getItem("hubId") && !localStorage.getItem("userId")) {
      setIsLoggedIn(false);
    }else{
      const mixpanelData = getMixpanelData();
      mixPanelTrack(VISIT_PICKLIST_PAGE, {
        [SUCCESS]: true,
        ...mixpanelData,
      });
    }
  }, []);

  useEffect(() => {
    getPickList();
    fetchActionRequestCount(new Date(), new Date());
  }, []);

  useEffect(() => {
    if (picklistData && !isEqual(picklistData.results, picklist)) {
      setPicklist(paginateArrayElements(picklistData.results, 20, 1));
    }
  }, [picklistData]);

  useEffect(() => {
    setDownloadErr(picklistDataDownloadError);
  }, [picklistDataDownloadError]);

  useEffect(() => {
    if (picklistActionRequestCount) {
      setActionRequestCount(picklistActionRequestCount);
      console.log(picklistActionRequestCount);
    }
  }, [picklistActionRequestCount]);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/login" />;
  }

  const onDownloadPickListHandler = () => {
    setRequestInProgress(true);
    console.log("Download Pick List");
    let requestPayload = {
      hub_id: localStorage.getItem("hubId"),
    };
    if (downloadFilteredData) {
      if (filteredTrackingNumbers.length > 0) {
        requestPayload = {
          ...requestPayload,
          tracking_nos: filteredTrackingNumbers.includes(",")
            ? mapStringToArray(filteredTrackingNumbers, ",")
            : mapStringToArray(filteredTrackingNumbers, "\n"),
        };
      }
      if (filteredLocations.length > 0) {
        requestPayload = {
          ...requestPayload,
          locations: filteredLocations.includes(",")
            ? mapStringToArray(filteredLocations, ",")
            : mapStringToArray(filteredLocations, "\n"),
        };
      }
      if (filteredActionRequest.length > 0) {
        requestPayload = {
          ...requestPayload,
          action_requests: filteredActionRequest,
        };
      }
      if (filteredDateRange.length > 0) {
        requestPayload = {
          ...requestPayload,
          requested_on_from: new Date(filteredDateRange[0]),
          requested_on_to: new Date(filteredDateRange[1]),
        };
      }
    }
    props.dispatch(downloadPicklist(requestPayload));
    setTimeout(() => {
      setShowCsvModal(false);
      setRequestInProgress(false);
    }, 3000);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
    setPicklist(paginateArrayElements(picklistData.results, 20, page));
  };

  const getPickList = () => {
    let requestPayload = { hub_id: localStorage.getItem("hubId"), is_fulfilled: false };
    if (filteredTrackingNumbers.length > 0) {
      requestPayload = {
        ...requestPayload,
        tracking_nos: filteredTrackingNumbers.includes(",")
          ? mapStringToArray(filteredTrackingNumbers, ",")
          : mapStringToArray(filteredTrackingNumbers, "\n"),
      };
    }
    if (filteredLocations.length > 0) {
      requestPayload = {
        ...requestPayload,
        locations: filteredLocations.includes(",")
          ? mapStringToArray(filteredLocations, ",")
          : mapStringToArray(filteredLocations, "\n"),
      };
    }
    if (filteredActionRequest.length > 0) {
      requestPayload = {
        ...requestPayload,
        action_requests: filteredActionRequest,
      };
    }
    if (filteredDateRange.length > 0) {
      requestPayload = {
        ...requestPayload,
        requested_on_from: new Date(filteredDateRange[0]),
        requested_on_to: new Date(filteredDateRange[1]),
      };
    }
    props.dispatch(getPicklistData(requestPayload));
  };

  const fetchActionRequestCount = (timestamp_from, timestamp_to, value) => {
    let requestPayload = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      timestamp_from,
      timestamp_to,
    };
    props.dispatch(getActionRequestCount(requestPayload, value));
  };

  const onDayChange = (value) => {
    if (value === "today") {
      fetchActionRequestCount(new Date(), new Date(), value);
    } else if (value === "last 7 days") {
      const fromDate = new Date(new Date().setDate(new Date().getDate() - 7));
      fetchActionRequestCount(fromDate, new Date(), value);
    } else if (value === "last 14 days") {
      const fromDate = new Date(new Date().setDate(new Date().getDate() - 14));
      fetchActionRequestCount(fromDate, new Date(), value);
    } else if (value === "last 30 days") {
      const fromDate = new Date(new Date().setDate(new Date().getDate() - 30));
      fetchActionRequestCount(fromDate, new Date(), value);
    }
    setDayFilter(value);
  };

  const filteredTrackingNumbersInputHandler = (e) => {
    setFilteredTrackingNumbers(e.target.value);
  };

  const filteredLocationInputHandler = (e) => {
    setFilteredLocations(e.target.value);
  };

  const resetTrackingNumberFilter = () => {
    setFilteredTrackingNumbers("");
  };

  const resetLocationFilter = () => {
    setFilteredLocations("");
  };

  const resetActionRequestFilter = () => {
    setFilteredActionRequest([]);
  };

  const filteredActionRequestInputHandler = (value) => {
    setFilteredActionRequest(value);
  };

  const filteredDateRangeInputHandler = (value) => {
    setFilteredDateRange(value);
  };

  const resetFilteredDateRange = () => {
    setFilteredDateRange([]);
  };

  function handleVisibleChange(name) {
    setMenuVisible({
      ...menuVisible,
      [name]: !menuVisible[name],
    });
  }

  function showModal() {
    setShowCsvModal(true);
  }

  function hideModal() {
    setShowCsvModal(false);
  }

  function trackingNumberFilterMenu(label, filter) {
    return (
      <Menu className="hms-menu-item">
        <Menu.Item key="0">
          <div className="p-10">
            <TextArea
              rows={3}
              className="w-100"
              label={label}
              placeholder="Type here"
              value={filter === "trackingCodeVisible" ? filteredTrackingNumbers : filteredLocations}
              onChange={
                filter === "trackingCodeVisible" ? filteredTrackingNumbersInputHandler : filteredLocationInputHandler
              }
            />
            <div className="standard-font mt-10 mb-10" style={{ whiteSpace: "break-spaces" }}>
              You may separate the tracking numbers by commas or by directly copying them from a spreadsheet column.
            </div>
            <div className="d-flex align-items-center justify-content-between mt-20">
              <div
                onClick={filter === "trackingCodeVisible" ? resetTrackingNumberFilter : resetLocationFilter}
                className="popover-reset"
              >
                Reset
              </div>
              <button
                className="secondary-button"
                onClick={() => {
                  getPickList();
                  if (filter === "trackingCodeVisible") {
                    handleVisibleChange("trackingCodeVisible");
                  } else {
                    handleVisibleChange("locationFilterVisible");
                  }
                }}
              >
                Filter
              </button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  }

  function requestFilterMenu(label) {
    const options = [
      { value: "DISPOSAL", label: "DISPOSAL" },
      { value: "REDELIVERY", label: "REDELIVERY" },
      { value: "RETURN_TO_MERCHANT", label: "RETURN_TO_MERCHANT" },
      { value: "RETURN_TO_SELLER", label: "RETURN_TO_SELLER" },
      { value: "RELABEL", label: "RELABEL" },
      { value: "RETURN_TO_VENDOR", label: "RETURN_TO_VENDOR" },
    ];
    return (
      <Menu className="hms-menu-item">
        <Menu.Item key="0">
          <div className="p-10">
            <MultiSelect
              placeholder="Select"
              onChange={filteredActionRequestInputHandler}
              value={filteredActionRequest}
              options={options}
              label={label}
            />
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div onClick={resetActionRequestFilter} className="popover-reset">
                Reset
              </div>
              <button
                className="secondary-button"
                onClick={() => {
                  getPickList();
                  handleVisibleChange("requestFilterVisible");
                }}
              >
                Filter
              </button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  }

  function dateRangeFilter() {
    return (
      <Menu className="hms-menu-item">
        <Menu.Item key="0">
          <div className="p-10">
            <h6 className="form-label">Filter by range of dates requested</h6>
            <RangePicker value={filteredDateRange} onChange={filteredDateRangeInputHandler} style={{ width: "100%" }} />
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div onClick={resetFilteredDateRange} className="popover-reset">
                Reset
              </div>
              <button
                className="secondary-button"
                onClick={() => {
                  getPickList();
                  handleVisibleChange("dateRangerFilterVisible");
                }}
              >
                Filter
              </button>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
  }

  function renderCsvConfirmationModal() {
    return (
      <Modal
        className="generate-csv-modal"
        visible={showCSVModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "genereate-csv-modal-primary-button" }}
        cancelButtonProps={{ className: "generate-csv-modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="generate-csv-heading mb-0">Download CSV</h2>
          <div className="mt-3 mb-3">
            <label htmlFor="" className="">
              Include only filtered data in table
            </label>
          </div>
          <Select
            defaultValue={downloadFilteredData}
            style={{ width: "100%" }}
            onChange={(value) => setDownloadFilteredData(value)}
          >
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
          <Divider className="gray-divider" />
          {downloadErr && <div className="alert alert-danger">An error occurred. Please try again.</div>}
        </div>
        <div className="text-center d-flex justify-content-between">
          <button className="generate-csv-modal-secondary-button" onClick={hideModal}>
            Cancel
          </button>
          <button className="generate-csv-modal-primary-button" onClick={onDownloadPickListHandler}>
            Confirm
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <>
      {renderCsvConfirmationModal()}
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container pick-list-container">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <p className="title mb-0">Pick List</p>
          </div>
          <div className="bg-container-body">
            <div className="d-flex justify-content-between align-items-center">
              <div className="picklist-header">New Packages Added to Picklist</div>
              <div className="d-flex align-items-center">
                <div>
                  <span className="fnt-16">Show</span>
                  <Select defaultValue={dayFilter} onChange={onDayChange} style={{ width: 120, marginLeft: 10 }}>
                    <Select.Option value="today">Today</Select.Option>
                    <Select.Option value="last 7 days">Last 7 days</Select.Option>
                    <Select.Option value="last 14 days">Last 14 days</Select.Option>
                    <Select.Option value="last 30 days">Last 30 days</Select.Option>
                  </Select>
                </div>
              </div>
            </div>
            {actionRequestCount && (
              <div className="d-flex justify-content-between align-items-center mt-4">
                <div>
                  <div className="picklist-update-heading">Disposal</div>
                  <div>{actionRequestCount.DISPOSAL}</div>
                </div>
                <div>
                  <div className="picklist-update-heading">Redelivery</div>
                  <div>{actionRequestCount.REDELIVERY}</div>
                </div>
                <div>
                  <div className="picklist-update-heading">Return to Seller</div>
                  <div>{actionRequestCount.RETURN_TO_SELLER}</div>
                </div>
                <div>
                  <div className="picklist-update-heading">Return to Merchant</div>
                  <div>{actionRequestCount.RETURN_TO_MERCHANT}</div>
                </div>
              </div>
            )}
          </div>
          <div className="bg-container-body mt-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="picklist-header">All Packages</div>
              <div className="">
                <button
                  className="secondary-button margin-bottom-20 margin-top-20 full-button-width"
                  onClick={showModal}
                >
                  {requestInProgress ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-blue" />
                  ) : (
                    "Download Pick List"
                  )}
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead className="table-header--bottom-border">
                  <tr>
                    <td>
                      <Dropdown
                        overlay={trackingNumberFilterMenu(
                          "Filter tracking code(s) or by prefixes",
                          "trackingCodeVisible"
                        )}
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() => handleVisibleChange("trackingCodeVisible")}
                        visible={menuVisible.trackingCodeVisible}
                      >
                        <div className="d-flex flex-nowrap ml">
                          <div className="cursor-pointer">Tracking Number</div>
                          <div>
                            <FontAwesomeIcon className="pointer ml-2" size="md" icon={faFilter} />
                          </div>
                        </div>
                      </Dropdown>
                    </td>
                    <td>
                      <Dropdown
                        overlay={trackingNumberFilterMenu("Filter by location(s)")}
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() => handleVisibleChange("locationFilterVisible")}
                        visible={menuVisible.locationFilterVisible}
                      >
                        <div className="d-flex flex-nowrap">
                          <div className="cursor-pointer">Location</div>
                          <div>
                            <FontAwesomeIcon className="pointer ml-2" size="md" icon={faFilter} />
                          </div>
                        </div>
                      </Dropdown>
                    </td>
                    <td>
                      <Dropdown
                        overlay={requestFilterMenu("Filter by request(s)")}
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() => handleVisibleChange("requestFilterVisible")}
                        visible={menuVisible.requestFilterVisible}
                      >
                        <div className="d-flex flex-nowrap">
                          <div className="cursor-pointer">Request</div>
                          <div>
                            <FontAwesomeIcon className="pointer ml-2" size="md" icon={faFilter} />
                          </div>
                        </div>
                      </Dropdown>
                    </td>
                    <td>
                      <Dropdown
                        overlay={dateRangeFilter()}
                        trigger={["click"]}
                        placement="bottomRight"
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() => handleVisibleChange("dateRangerFilterVisible")}
                        visible={menuVisible.dateRangerFilterVisible}
                      >
                        <div className="d-flex flex-nowrap">
                          <div className="cursor-pointer">Requested on</div>
                          <div>
                            <FontAwesomeIcon className="pointer ml-2" size="md" icon={faFilter} />
                          </div>
                        </div>
                      </Dropdown>
                    </td>
                    <td>
                      <span className="cursor-pointer">Consolidation Date</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {picklist &&
                    picklist.length > 0 &&
                    picklist.map((pickListItem, index) => {
                      return (
                        <tr key={`${pickListItem.tracking_no}_${index}`}>
                          <td className="text-span-black">{pickListItem.tracking_no}</td>
                          <td className="text-span-black">{pickListItem.location ? pickListItem.location : "-"}</td>
                          <td className="text-span-black">{pickListItem.action_request}</td>
                          <td className="text-span-black">{moment(pickListItem.requested_on).format("DD MMM YY")}</td>
                          <td className="text-span-black">
                            {pickListItem.next_resolution_date
                              ? moment(pickListItem.next_resolution_date).format("DD MMM YY")
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {picklist && picklist.length > 0 && (
              <div className="mt-3 text-center">
                <Pagination
                  current={currentPage}
                  defaultPageSize={20}
                  total={picklistData.results.length}
                  onChange={onPageChange}
                  className="mini picklist-antd-pagination"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ picklist }) => ({
  picklistData: picklist.picklistData,
  picklistDataDownloadError: picklist.picklistDataDownloadError,
  picklistActionRequestCount: picklist.picklistActionRequestCount,
  picklistActionRequestCountError: picklist.picklistActionRequestCountError,
});

export default connect(mapStateToProps)(PickList);
