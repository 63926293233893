import { Divider, Form } from "antd";
import axios from "axios";
import React, { useState } from "react";
import ReactGA from "react-ga";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { authApi } from "../../../api";
import Input from "../../../common/input/input";
import { mixPanelTrack, saveMixpanelData } from "../../../utils/mixpanelUtils";
import {
  ERROR,
  ERROR_TYPE,
  EVENT_LOGIN_FAILED,
  EVENT_LOGIN_SUCCESS,
  HUB_ID,
  HUB_SCANNER_NAME,
  PERFORMANCE,
  SCAN_TYPES,
  SUCCESS,
  USER_ID,
} from "../../../constants/mixpanelConstants";
import background from "../../../images/login/login_background.png";
import logo from "../../../images/login/logo_homepage.png";
import "./style.scss";

export default function LoginPage() {
  const [state, setState] = useState({
    hub_id: "",
    hubscanner_key: "",
  });

  const [loginSuccessful, setLoginSuccessful] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const errorClass = loginErrorMessage ? "login-input-error" : "";

  if (loginSuccessful) {
    return <Redirect push to="/scan/hubmanage/parcel-scan" />;
  }

  return (
    <div className="login_screen">
      <img className="login-background" alt="background" src={background} />
      <div className="login-overlay" />
      <div className="login-form">
        <LinkContainer to="/">
          <img alt="logo" src={logo} />
        </LinkContainer>
        <div className="form">
          <h2 className="login-heading">HMS SCANNER</h2>
          <Divider className="gray-divider" />
          <Form onFinish={onSubmit}>
            <Form.Item
              name="hub_id"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please enter your hub id",
                },
              ]}
            >
              <Input
                label="Hub ID"
                name="hub_id"
                onChange={handleChange}
                placeholder="Type here"
                className={"login-input " + errorClass}
              />
            </Form.Item>

            <Form.Item name="hubscanner_key" rules={[{ required: true, message: "Please enter user key" }]}>
              <Input
                label="User Key"
                type="password"
                name="hubscanner_key"
                onChange={handleChange}
                placeholder="Type here"
                className={"login-input " + errorClass}
              />
            </Form.Item>
            <Divider className="blue-divider" />
            <Form.Item label="" colon={false} className="mb-2">
              <button className="btn-block login-btn-primary mb-0" type="submit">
                Login
              </button>
            </Form.Item>
          </Form>
          {loginErrorMessage && <div className="error-message mt-3">{loginErrorMessage}</div>}
        </div>
      </div>
    </div>
  );

  function validateUserLogin(hubid, userid) {
    return axios.get(authApi.GET_VALID_SCAN_TYPES, {
      params: {
        hub_id: hubid,
        hubscanner_key: userid,
      },
    });
  }

  async function onSubmit() {
    const t0 = performance.now();
    try {
      const defaultAudioType = localStorage.getItem("defaultAudioType");
      const res = await validateUserLogin(state.hub_id, state.hubscanner_key);
      let scanTypes = JSON.stringify(res.data.valid_scan_types);
      localStorage.setItem("hubId", state.hub_id);
      localStorage.setItem("userId", state.hubscanner_key);
      localStorage.setItem("userName", res.data.hub_scanner_name);
      localStorage.setItem("scanTypes", scanTypes);
      localStorage.setItem("downloadLabel", "Manual");
      localStorage.setItem("defaultScanner", "Laser");

      if (defaultAudioType === null) {
        localStorage.setItem("defaultAudioType", "consigneeCountry");
      }

      ReactGA.event({
        category: "HMS Login",
        action: "Login Validation Success",
      });
      const t1 = performance.now();
      setLoginSuccessful(true);
      saveMixpanelData({
        [HUB_SCANNER_NAME]: res.data.hub_scanner_name,
        [HUB_ID]: state.hub_id,
        [USER_ID]: res.data.hub_scanner_name,
      });
      // mixpane tracking login success
      mixPanelTrack(EVENT_LOGIN_SUCCESS, {
        [SUCCESS]: true,
        [PERFORMANCE]: t1 - t0,
        [HUB_ID]: state.hub_id,
        [HUB_SCANNER_NAME]: res.data.hub_scanner_name,
        [SCAN_TYPES]: scanTypes,
        [USER_ID]: state.hubscanner_key,
      });
    } catch (e) {
      ReactGA.event({
        category: "HMS Login",
        action: "Login Validation Failed",
      });
      const t1 = performance.now();
      // mixpane tracking login failed
      mixPanelTrack(EVENT_LOGIN_SUCCESS, {
        [SUCCESS]: false,
        [PERFORMANCE]: t1 - t0,
        [ERROR]: e.data,
        [ERROR_TYPE]: EVENT_LOGIN_FAILED,
      });
      setLoginErrorMessage("Invalid Credentials. Please try again.");
      setTimeout(() => {
        setLoginErrorMessage("");
      }, 5000);
    }
  }
}
