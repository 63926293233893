import axios from "axios";
import { parcelScanApi } from "../api/parcelScanApi";

const fetchParcelInfo = (params) => {
  return axios({
    method: "GET",
    url: parcelScanApi.GET_ORDER_DETAILS,
    params: params,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const processOrder = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.PROCESS_SCANNED_ORDERS,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const redeliverScan = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.REDELIVERY_SCAN,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getDisposalOrderDetails = (params) => {
  return axios({
    method: "GET",
    url: parcelScanApi.GET_DISPOSAL_ORDER_DETAILS,
    params: params,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const disposalScan = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.DISPOSE_PARCEL,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const updateWeight = (data) => {
  return axios({
    method: "PUT",
    url: parcelScanApi.UPDATE_DIMWEIGHT,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const addException = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.PROCESS_SCANNED_ORDERS,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const addLocation = (data) => {
  return axios({
    method: "PUT",
    url: parcelScanApi.ADD_PARCEL_LOCATION,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const reassignPartner = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.REASSIGN_NETWORK_PARTNER,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const resolveException = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.REMEDY_EXCEPTION,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getResourceDetailsService = (params) => {
  return axios({
    method: "GET",
    url: parcelScanApi.GET_RESOURCE_DEATILS_API,
    params: params,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const recordeResourceUsage = (data) => {
  return axios({
    method: "POST",
    url: parcelScanApi.RECORD_RESOURCE_USAGE,
    data: data,
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  }).then((res) => res.data);
};

const getLabelForLabellessPackage = (params) => {
  return axios({
    method: "GET",
    url: parcelScanApi.GET_LABEL_FROM_PRINT_TN,
    params,
  }).then((res) => res.data);
};

export const parcelServices = {
  fetchParcelInfo,
  processOrder,
  redeliverScan,
  getDisposalOrderDetails,
  disposalScan,
  updateWeight,
  addException,
  addLocation,
  reassignPartner,
  resolveException,
  getResourceDetailsService,
  recordeResourceUsage,
  getLabelForLabellessPackage,
};
