import React from "react";
import { Redirect, Route } from "react-router-dom";

function RoutePrivate({ component: Component, to = "/", ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem("hubId") && localStorage.getItem("userId") ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: to,
              state: { redirect: props.location.pathname },
            }}
          />
        )
      }
    />
  );
}

export default RoutePrivate;
