import React from 'react';
import { DatePicker } from 'antd';
import './style.scss';

const { RangePicker } = DatePicker;

export default function DateRangePicker({ label, className, ...otherProps }) {
  return (
    <div className={`custom-input ${className}`}>
      {label && <h6 className="form-label">{label}</h6>}
      <RangePicker className="w-100 date-range-picker" {...otherProps} />
    </div>
  )
}