import axios from "axios";
import { shipmentTypes } from "../types";
import { commonApi, shipmentApi } from "../api";
import { getMixpanelData, mixPanelTrack } from "../utils/mixpanelUtils";
import { CREATE_SHIPMENT, ERROR, PAYLOAD, SUCCESS, EDIT_SHIPMENT_DETAILS, CANCEL_SHIPMENT } from "../constants/mixpanelConstants";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const defaultToken = localStorage.getItem("token");

if (defaultToken) {
  axios.defaults.headers.common["Authorization"] = "Token " + defaultToken;
}

// list shipments
const getShipmentNumbersList = (data) => {
  return function (dispatch) {
    axios
      .get(commonApi.GET_SHIPMENT_NUMBERS_LIST, { params: data })
      .then((response) => {
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_NUMBERS_LIST_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_NUMBERS_LIST_ERROR,
          payload: err,
        });
      });
  };
};

// fetch partners
const fetchRecipients = () => {
  return function (dispatch) {
    axios
      .get(commonApi.GET_RECIPIENTS)
      .then((response) => {
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_PARTNERS,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_PARTNERS_ERROR,
        });
      });
  };
};

// fetch last mile network partners for hub
const fetchRecipientsForHub = (countryCode) => {
  return function (dispatch) {
    axios
      .get(`${commonApi.GET_RECIPIENTS}?country_code=${countryCode}`)
      .then((response) => {
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_HUB_PARTNERS,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_HUB_PARTNERS_ERROR,
        });
      });
  };
};

// create normal shipment
const createShipment = (data) => {
  return function (dispatch) {
    dispatch({ type: shipmentTypes.CREATE_SHIPMENT_LOADING });
    axios
      .post(shipmentApi.CREATE_SHIPMENT, data)
      .then((response) => {
        dispatch(setShipmentNo(response.data.shipment_no));
      })
      .catch((err) => {
        dispatch({
          type: shipmentTypes.CREATE_SHIPMENT_ERROR,
          payload: err.data,
        });
      });
  };
};

// create RTV shipment
const createRTVShipment = (data) => {
  return function (dispatch) {
    dispatch({ type: shipmentTypes.CREATE_SHIPMENT_LOADING });
    axios
      .post(`${shipmentApi.CREATE_RETURN_SHIPMENT}?hub_id=${data.hub_id}&hubscanner_key=${data.hubscanner_key}`, data)
      .then((response) => {
        dispatch(setShipmentNo(response.data.shipment_no));
        localStorage.setItem("RTV", true);
        localStorage.setItem("recipientCompanyFromRTV", data.recipient_company);
        const mixPanelData = getMixpanelData();
        mixPanelTrack(CREATE_SHIPMENT, {
          [SUCCESS]: true,
          [PAYLOAD]: data,
          ...mixPanelData
        })
      })
      .catch((err) => {
        localStorage.removeItem("RTV");
        localStorage.removeItem("recipientCompanyFromRTV");
        const mixPanelData = getMixpanelData();
        mixPanelTrack(CREATE_SHIPMENT, {
          [SUCCESS]: false,
          [ERROR]: err,
          [PAYLOAD]: data,
          ...mixPanelData
        })
        dispatch({
          type: shipmentTypes.CREATE_SHIPMENT_ERROR,
          payload: err.data,
        });
      });
  };
};

const getType = (tracking_no) => {
  return tracking_no.substr(0, 3) == "JNC" ? "Cargo" : "Parcel";
};

const getShipmentCargosList = (data) => {
  return function (dispatch) {
    dispatch({ type: shipmentTypes.GET_SHIPMENT_CARGOS_LOADING });
    axios
      .get(shipmentApi.CARGOS, {
        params: data,
      })
      .then((response) => {
        const cargoNumbersList = response.data.cargos.map((cargoNumber) => {
          const objToReturn = {
            tracking_no: cargoNumber.tracking_no,
            cargo_type: cargoNumber.cargo_type,
            dest_country_code: cargoNumber.dest_country_code,
            shipment_no: cargoNumber.shipment_no,
            shipment_weight: cargoNumber.shipment_weight,
            dest_poscode_prefix: cargoNumber.dest_poscode_prefix,
            type: getType(cargoNumber.tracking_no),
          };
          return objToReturn;
        });
        const shipmentWeight = response.data.shipment_weight;
        let cargoData = response.data;
        delete cargoData.cargos;

        const payload = {
          cargoNumbersList: cargoNumbersList,
          shipmentWeight: shipmentWeight,
          cargoData: cargoData,
        };
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_CARGOS_SUCCESS,
          payload: payload,
        });
      })
      .catch((err) => {
        dispatch({
          type: shipmentTypes.GET_SHIPMENT_CARGOS_ERROR,
          payload: err.data,
        });
      });
  };
};

// cancel shipment
const cancelShipment = (data) => {
  return function (dispatch) {
    dispatch({ type: shipmentTypes.CANCEL_SHIPMENT_LOADING });
    axios
      .put(shipmentApi.CANCEL_SHIPMENT, data)
      .then((response) => {
        dispatch({
          type: shipmentTypes.CANCEL_SHIPMENT_SUCCESS,
          payload: response.data.shipment_no,
        });
        const mixpanelData = getMixpanelData();
        mixPanelTrack(CANCEL_SHIPMENT, {
          [SUCCESS]: true,
          ...mixpanelData
        })
      })
      .catch((err) => {
        dispatch({
          type: shipmentTypes.CANCEL_SHIPMENT_ERROR,
          payload: err.data,
        });
        const mixpanelData = getMixpanelData();
        mixPanelTrack(CANCEL_SHIPMENT, {
          [SUCCESS]: false,
          [ERROR]: err,
          ...mixpanelData
        })
      });
  };
};

const clearCancelShipmentState = () => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.CANCEL_SHIPMENT_RESET,
    });
  };
};

// edit shipment
const editShipment = (data) => {
  return function (dispatch) {
    dispatch({ type: shipmentTypes.EDIT_SHIPMENT_LOADING });
    axios
      .put(shipmentApi.UPDATE_SHIPMENT, data)
      .then((response) => {
        dispatch({
          type: shipmentTypes.EDIT_SHIPMENT_SUCCESS,
        });
        const mixpanelData = getMixpanelData();
        mixPanelTrack(EDIT_SHIPMENT_DETAILS, {
          [SUCCESS]: true,
          [PAYLOAD]: data,
          ...mixpanelData
        })
      })
      .catch((err) => {
        dispatch({
          type: shipmentTypes.EDIT_SHIPMENT_ERROR,
          payload: err.data,
        });
        const mixpanelData = getMixpanelData();
        mixPanelTrack(EDIT_SHIPMENT_DETAILS, {
          [SUCCESS]: false,
          [ERROR]: err,
          [PAYLOAD]: data,
          ...mixpanelData
        })
      });
  };
};

const clearEditShipmentData = () => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.CLEAR_EDIT_SHIPMENT_DATA,
    });
  };
};

// set shipment number
const setShipmentNo = (shipment_no) => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.CREATE_SHIPMENT,
      payload: shipment_no,
    });
  };
};

// remove cargo from shipment
const removeCargoFromShipment = (data) => {
  return function (dispatch) {
    dispatch({ type: shipmentTypes.REMOVE_CARGO_FROM_SHIPMENT_LOADING });
    axios
      .put(shipmentApi.REMOVE_FROM_SHIPMENT, data)
      .then((response) => {
        dispatch({
          type: shipmentTypes.REMOVE_CARGO_FROM_SHIPMENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: shipmentTypes.REMOVE_CARGO_FROM_SHIPMENT_ERROR,
          payload: {
            errorData: err.data && err.data.shipment_no ? err.data.shipment_no[0] : err.data.scanned_tracking_no[0],
            jsonBody: err.status === 500 ? JSON.stringify(data) : null
          }
        });
      });
  };
};

const clearRemoveCargoFromShipmentData = () => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.CLEAR_REMOVE_CARGO_FROM_SHIPMENT_DATA,
    });
  };
};

const clearShipmentError = () => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.CLEAR_SHIPMENT_ERROR,
    });
  };
};

const clearShipmentResponse = () => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.CLEAR_SHIPMENT_RESPONSE,
    });
  };
};

const shipmentResetState = () => {
  return function (dispatch) {
    dispatch({
      type: shipmentTypes.SHIPMENT_RESET_STATE,
    });
  };
};

const getPendingShipmentData = (requestPayload) => {
  return async function (dispatch) {
    try {
      const res = await axios.get(commonApi.GET_SHIPMENT_NUMBERS_LIST, requestPayload);
      dispatch({
        type: shipmentTypes.GET_PENDING_SHIPMENT_DATA_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: shipmentTypes.GET_PENDING_SHIPMENT_DATA_ERROR,
        payload: err,
      });
    }
  };
};

const getInProgressShipmentData = (requestPayload) => {
  return async function (dispatch) {
    try {
      const res = await axios.get(commonApi.GET_SHIPMENT_NUMBERS_LIST, requestPayload);
      dispatch({
        type: shipmentTypes.GET_IN_PROGRESS_SHIPMENT_DATA_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: shipmentTypes.GET_IN_PROGRESS_SHIPMENT_DATA_ERROR,
        payload: err,
      });
    }
  };
};

export const shipmentActions = {
  getShipmentNumbersList,
  fetchRecipients,
  fetchRecipientsForHub,
  createShipment,
  createRTVShipment,
  cancelShipment,
  clearCancelShipmentState,
  setShipmentNo,
  clearShipmentError,
  clearShipmentResponse,
  shipmentResetState,
  getPendingShipmentData,
  getInProgressShipmentData,
  getShipmentCargosList,
  editShipment,
  clearEditShipmentData,
  removeCargoFromShipment,
  clearRemoveCargoFromShipmentData,
};
