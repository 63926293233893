import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { Pagination, Divider } from "antd";
import { LinkContainer } from "react-router-bootstrap";
import { shipmentActions } from "../../../actions/rootActions";
import * as _ from "lodash";

function InsideThisShipment(props) {
  const { cargoData, cargos } = props;

  const shipment = props.location.state.shipment;

  const [pageNumber, setPageNumber] = useState(1);

  const [shipmentCargos, setShipmentCargos] = useState(cargos);
  const [shipmentCargosCopy] = useState(cargos);

  const [menuVisible, setMenuVisible] = useState({
    trackingCodeVisible: false,
    typeVisible: false,
  });

  const [filters, setFilters] = useState({
    trackingCode: "",
    type: "",
  });

  const [selectedCargo, setSelectedCargo] = useState(null);
  //const [redirectToCargoDetailsPage, setRedirectToCargoDetailsPage] = useState(false);
  //if (redirectToCargoDetailsPage) {
  //return (
  //<Redirect
  //to={{
  //pathname: `/scan/hubmanage/cargo-scan/sweep`,
  //state: {
  //cargo: selectedCargo,
  //pageNumber: pageNumber,
  //shipment: shipment,
  //},
  //}}
  ///>
  //);
  //}

  useEffect(() => {
    if (!_.isEqual(shipmentCargos, cargos)) {
      setShipmentCargos(cargos);
    }
  }, [cargos]);

  useEffect(() => {
    if (cargos && cargos.length === 0) {
      fetchShipmentTrackingNumber(props.location.state.shipment.tracking_no, pageNumber);
    }
  }, []);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <LinkContainer to="/scan/hubmanage/shipments">
              <p className="link-title mb-0">Shipments</p>
            </LinkContainer>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">{shipment.tracking_no}</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black table-heading-margin">Inside This Shipment</h2>
            <div className="table-responsive margin-top-20">
              <table className="table table-striped table-borderless">
                <thead className="table-header--bottom-border">
                  <tr>
                    <td>
                      <span className="cursor-pointer">Tracking Number</span>
                      {/* <Dropdown
                        overlay={trackingNumberFilterMenu}
                        trigger={["click"]}
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() =>
                          handleVisibleChange("trackingCodeVisible")
                        }
                        visible={menuVisible.trackingCodeVisible}
                      >
                        <span className="cursor-pointer">
                          Tracking Code{" "}
                          <i
                            className="fa fa-filter filter-icon cursor-pointer"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Dropdown> */}
                    </td>
                    <td className="table-header-extended">
                      <span className="cursor-pointer">Type</span>
                      {/* <Dropdown
                        overlay={typeFilterMenu}
                        trigger={["click"]}
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() => handleVisibleChange("typeVisible")}
                        visible={menuVisible.typeVisible}
                      >
                        <span className="cursor-pointer">
                          Type{" "}
                          <i
                            className="fa fa-filter filter-icon cursor-pointer"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Dropdown> */}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {shipmentCargos &&
                    shipmentCargos.length > 0 &&
                    shipmentCargos.map((cargo, index) => {
                      return (
                        <tr key={index}>
                          <td
                            className={getTrackingNumberClass(cargo.tracking_no)}
                            onClick={() => redirectToCargoDetails(cargo)}
                          >
                            {cargo.tracking_no}
                          </td>
                          <td className="text-span-black table-header-extended">{cargo.type}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {shipmentCargos && shipmentCargos.length > 0 && (
              <div className="mt-3 text-center">
                <Pagination
                  current={pageNumber}
                  defaultPageSize={50}
                  total={cargoData.total_records}
                  onChange={onPageChange}
                  className="hms-antd-pagination"
                  responsive
                />
              </div>
            )}
            <Divider className="gray-divider" />
            <button className="secondary-button-small full-button-width" onClick={goBackToPreviousScreen}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  function onPageChange(page) {
    setPageNumber(page);
    fetchShipmentTrackingNumber(props.location.state.shipment.tracking_no, page);
  }

  async function fetchShipmentTrackingNumber(shipmentNumber, page) {
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipmentNumber.trim(),
      page: page,
    };
    props.dispatch(shipmentActions.getShipmentCargosList(data));
  }

  //! DISABLED TILL BACKEND FILTER IS DONE
  // function trackingNumberFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Tracking Code"
  //           placeholder="Type here"
  //           name="trackingCode"
  //           value={filters.trackingCode}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("trackingCode")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  // function typeFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Type"
  //           placeholder="Type here"
  //           name="type"
  //           value={filters.type}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("type")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  function redirectToCargoDetails(cargo) {
    if (cargo.tracking_no.substr(0, 3) === "JNC") {
      props.history.push({
        pathname: `/scan/hubmanage/cargo-scan/sweep`,
        state: {
          scanType: "sweep",
          tracking_no: cargo.tracking_no,
          pageNumber: pageNumber,
          shipment: shipment,
        },
      });
    }
  }

  //function onInputChange(e) {
  //const { name, value } = e.target;
  //setFilters({
  //...filters,
  //[name]: value,
  //});
  //}

  //function onClickReset(name) {
  //let newFilters = { ...filters };
  //newFilters[name] = "";
  //setFilters(newFilters);
  //onClickFilter(null, newFilters);
  //}

  function onClickFilter(e, resetFilters) {
    const listFilters = resetFilters ? resetFilters : filters;
    let list = shipmentCargosCopy;
    if (!_.isEmpty(listFilters.trackingCode)) {
      list = _.filter(list, (x) => x.tracking_no === listFilters.trackingCode);
    } else if (!_.isEmpty(listFilters.type)) {
      list = _.filter(list, (x) => x.type === listFilters.type);
    }
    setShipmentCargos(list);
  }

  //function handleVisibleChange(name) {
  //setMenuVisible({
  //...menuVisible,
  //[name]: !menuVisible[name],
  //});
  //}

  function getTrackingNumberClass(tracking_no) {
    return tracking_no.substr(0, 3) === "JNC" ? "text-span-link" : "text-span-black";
  }
}

const mapStateToProps = (state) => ({
  shipmentCargosLoading: state.shipment.shipmentCargosLoading,
  cargoData: state.shipment.shipmentCargosData,
  cargos: state.shipment.shipmentCargosNumbersList,
});

export default connect(mapStateToProps)(InsideThisShipment);
