import React from 'react';
import { Alert, Form, Select } from 'antd';

const { Option } = Select;

export default function CreateShipment(props) {
  const { partners, createShipmentErr, createShipmentsRef } = props;
  return (
    <div className="content-container card mt-2 mb-1 p-2 cnt-2">
      <div className="d-flex flex-column align-items-center mt-2">
        <h4 className="create-shipment-title">Create Shipment</h4>
      </div>
      <Form ref={createShipmentsRef} name="control-ref" onFinish={props.showShipmentCreationProgressMessage}>
        <div className="d-flex flex-column mt-2 py-2 px-4">
          <Form.Item
            name="selectedRecipientId"
            label=""
            rules={[{ required: true, message: 'Please select partner' }]}
            className="recipientDropdown"
          >
            <label className="partner-to-send-label">Partner to Send</label>
            <Select 
              onChange={props.handlePartnerToSendChange}
              name="selectedRecipientId"
            >
              {partners.length !== 0 &&
                partners.map((partner) => (
                  <Option key={partner.id} value={partner.id}>
                    {partner.name}
                  </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="row p-3">
          <div className="col-6">
            <button 
              className="btn shipment-back-button ml-1" 
              onClick={props.showShipmentPage}
            >
              Back
            </button>
          </div>
          <div className="col-6 text-right">
            <Form.Item>
              <button 
                className="btn create-shipment-button mr-1" 
                type="submit"
              >
              Confirm
              </button>
            </Form.Item>
          </div>
        </div>
      </Form>
      {createShipmentErr && createShipmentErr.hub_id &&
        <Alert message={createShipmentErr.hub_id[0]} type="error" closable onClose={this.closeShipmentError} className="mb-3 ml-3 mr-3" />
      }
      {createShipmentErr && createShipmentErr.hubscanner_key &&
        <Alert message={createShipmentErr.hubscanner_key[0]} type="error" closable onClose={this.closeShipmentError} className="mb-3 ml-3 mr-3" />
      }
      {createShipmentErr && createShipmentErr.next_recipient_id &&
        <Alert message={createShipmentErr.next_recipient_id[0]} type="error" closable onClose={this.closeShipmentError} className="mb-3 ml-3 mr-3" />
      }
    </div>
  )
}
