import { cargoTypes } from "../types";

export default function (
  state = {
    addCargoTrackingNumber: null,
    addCargoLoading: false,
    createCargoLoading: false,
    createCargoErr: null,
    newCargoTrackingData: null,
    showSuccessMessage: false,
    countriesList: [],
    countriesListErr: null,
    resetState: false,
    addCargoToShipmentLoading: false,
    addCargoToShipmentData: null,
    addCargoToShipmentDataError: null,
    removeCargoFromParentSuccessData: null,
    removeCargoFromParentErrorData: null,

    latestScannedNumber: null,
    cargoDetailsSuccess: false,
    cargoDetails: null,
    cargoDetailsError: null,
    successNotificationData: {},
    warningNotificationData: {},
    errorNotificationData: {},

    notificationBoxType: {
      showNoDataBox: true,
      showValidDataBox: false,
      showErrorDataBox: false,
    },

    scanSettingsData: {
      linkToShipment: undefined,
      linkToCargo: undefined,
      groupId: "",
      recipientId: null,
    },

    processedScannedCargo: null,
    processedScannedCargoError: null,
    uploadCargoPhotoError: null,
    uploadCargoPhotoSuccess: null,

    getTrackingNumbersInsideCargoLoading: false,
    trackingNumbersInsideCargosData: {},
    trackingNumbersInsideCargo: [],
    getTrackingNumbersInsideCargoError: false,

    photosData: [],
    photosDataError: null,

    bulkRemoveFromCargoLoading: null,
    bulkRemoveFromCargoSuccess: null,
    bulkRemoveFromCargoError: null,
    exceptions: [],
  },
  action
) {
  switch (action.type) {
    case cargoTypes.ADD_CARGO_LOADING:
      return { ...state, addCargoLoading: true };
    case cargoTypes.ADD_CARGO:
      return {
        ...state,
        addCargoTrackingNumber: action.payload,
        addCargoError: null,
      };
    case cargoTypes.ADD_CARGO_ERROR:
      return { ...state, addCargoError: action.payload };
    case cargoTypes.CLEAR_CARGO_RESPONSE:
      return {
        ...state,
        addCargoError: null,
        addCargoTrackingNumber: null,
        addCargoLoading: false,
      };
    case cargoTypes.CREATE_CARGO_LOADING:
      return { ...state, createCargoLoading: true };
    case cargoTypes.CREATE_CARGO:
      return {
        ...state,
        newCargoTrackingData: action.payload,
        showSuccessMessage: true,
        createCargoLoading: false,
      };
    case cargoTypes.SET_CARGO:
      return {
        ...state,
        newCargoTrackingData: action.payload,
        showSuccessMessage: false,
      };
    case cargoTypes.CREATE_CARGO_ERROR:
      return { ...state, createCargoErr: action.payload, createCargoLoading: false };
    case cargoTypes.CLEAR_CREATE_CARGO_ERROR:
      return { ...state, createCargoErr: null };
    case cargoTypes.GET_COUNTRIES_LIST:
      return { ...state, countriesList: action.payload };
    case cargoTypes.GET_COUNTRIES_ERROR:
      return { ...state, countriesListErr: action.payload };
    case cargoTypes.CLEAR_CREATE_CARGO_RESPONSE:
      return {
        ...state,
        createCargoLoading: false,
        createCargoErr: null,
        newCargoTrackingData: null,
        resetState: true,
      };
    case cargoTypes.CLEAR_CARGO_STATE:
      return {
        ...state,
        addCargoTrackingNumber: null,
        addCargoLoading: false,
        resetState: true,
      };
    case cargoTypes.ADD_CARGO_TO_SHIPMENT_LOADING:
      return { ...state, addCargoToShipmentLoading: true };
    case cargoTypes.ADD_CARGO_TO_SHIPMENT:
      return {
        ...state,
        addCargoToShipmentData: action.payload,
        addCargoToShipmentLoading: false,
        addCargoToShipmentDataError: null,
      };
    case cargoTypes.ADD_CARGO_TO_SHIPMENT_ERROR:
      return { ...state, addCargoToShipmentDataError: action.payload };
    case cargoTypes.CLEAR_ADD_CARGO_TO_SHIPMENT_RESPONSE:
      return {
        ...state,
        addCargoToShipmentLoading: false,
        addCargoToShipmentData: null,
        addCargoToShipmentDataError: null,
        resetState: true,
      };
    case cargoTypes.CARGO_RESET_STATE:
      return {
        ...state,
        resetState: false,
        removeCargoFromParentSuccessData: null,
        removeCargoFromParentErrorData: null,
      };
    case cargoTypes.REMOVE_CARGO_FROM_PARENT_SUCCESS:
      return {
        ...state,
        removeCargoFromParentSuccessData: action.payload,
        removeCargoFromParentErrorData: null,
      };
    case cargoTypes.REMOVE_CARGO_FROM_PARENT_ERROR:
      return { ...state, removeCargoFromParentErrorData: action.payload };
    case cargoTypes.CLEAR_REMOVE_CARGO_STATE:
      return {
        ...state,
        removeCargoFromParentSuccessData: null,
        removeCargoFromParentErrorData: null,
      };
    // cargo scanner reducers
    case cargoTypes.GET_CARGO_DETAILS_SUCCESS:
      return {
        ...state,
        cargoDetails: action.payload.cargoDetails,
        cargoDetailsSuccess: true,
        latestScannedNumber: action.payload.latestScannedNumber,
        cargoDetailsError: null,
        errorNotificationData: {},
        notificationBoxType: action.payload.notificationBoxType,
      };
    case cargoTypes.GET_CARGO_DETAILS_ERROR:
      return {
        ...state,
        cargoDetails: null,
        cargoDetailsSuccess: false,
        notificationBoxType: action.payload.notificationBoxType,
        errorNotificationData: action.payload.errorNotificationData,
        cargoDetailsError: action.payload.cargoDetailsError,
      };
    case cargoTypes.CLEAR_GET_CARGO_DETAILS_ERROR:
      return {
        ...state,
        cargoDetailsError: null,
      };
    case cargoTypes.PROCESS_SCANNED_ARRIVAL_BREAK_CARGOS_SUCCESS:
      return {
        ...state,
        processedScannedCargo: action.payload,
        processedScannedCargoError: null,
      };
    case cargoTypes.PROCESS_SCANNED_ARRIVAL_BREAK_CARGOS_ERROR:
      return {
        ...state,
        processedScannedCargo: null,
        processedScannedCargoError: action.payload,
      };
    case cargoTypes.PROCESS_SCANNED_CARGOS_SUCESS:
      return {
        ...state,
        processedScannedCargo: action.payload.processedScannedCargo,
        successNotificationData: action.payload.successNotificationData,
        warningNotificationData: action.payload.warningNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
        processedScannedCargoError: null,
      };
    case cargoTypes.PROCESS_SCANNED_CARGOS_ERROR:
      return {
        ...state,
        processedScannedCargo: null,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
        processedScannedCargoError: action.payload.processedScannedCargoError,
      };
    case cargoTypes.CLEAR_PROCESS_SCAN_ORDER:
      return {
        ...state,
        processedScannedCargo: null,
        processedScannedCargoError: null,
        cargoDetailsSuccess: false,
      };
    case cargoTypes.UPLOAD_CARGO_PHOTOS_SUCCESS:
      return {
        ...state,
        uploadCargoPhotoSuccess: action.payload,
        uploadCargoPhotoError: null,
      };
    case cargoTypes.UPLOAD_CARGO_PHOTOS_ERROR:
      return {
        ...state,
        uploadCargoPhotoSuccess: null,
        uploadCargoPhotoError: action.payload,
      };

    case cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_LOADING:
      return {
        ...state,
        getTrackingNumbersInsideCargoLoading: true,
      };
    case cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_SUCCESS:
      return {
        ...state,
        getTrackingNumbersInsideCargoLoading: false,
        trackingNumbersInsideCargosData: action.payload.cargoTrackingNumbersData,
        trackingNumbersInsideCargo: action.payload.cargoTrackingNumbers,
        getTrackingNumbersInsideCargoError: false,
      };
    case cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_ERROR:
      return {
        ...state,
        getTrackingNumbersInsideCargoLoading: false,
        trackingNumbersInsideCargosData: {},
        trackingNumbersInsideCargo: [],
        getTrackingNumbersInsideCargoError: action.payload,
      };

    case cargoTypes.CLEAR_TRACKING_NUMBERS_INSIDE_CARGO_DATA:
      return {
        ...state,
        getTrackingNumbersInsideCargoLoading: false,
        trackingNumbersInsideCargoData: {},
        trackingNumbersInsideCargo: [],
        getTrackingNumbersInsideCargoError: false,
      };

    case cargoTypes.CLEAR_CARGO_INFO:
      return {
        ...state,
        cargoDetails: null,
        cargoDetailsError: null,
        processedScannedCargo: null,
        processedScannedCargoError: null,
        uploadCargoPhotoSuccess: null,
        uploadCargoPhotoError: null,
        latestScannedNumber: null,
        successNotificationData: {},
        warningNotificationData: {},
        errorNotificationData: {},

        notificationBoxType: {
          showNoDataBox: true,
          showValidDataBox: false,
          showErrorDataBox: false,
        },

        scanSettingsData: {
          linkToShipment: undefined,
          linkToCargo: undefined,
          groupId: "",
          recipientId: null,
        },

        processedScannedCargo: null,
        processedScannedCargoError: null,
      };
    case cargoTypes.SEE_PHOTOS_SUCCESS:
      return { ...state, photosData: action.payload, photosDataError: null };
    case cargoTypes.SEE_PHOTOS_ERROR:
      return { ...state, photosData: null, photosDataError: action.payload };
    case cargoTypes.BULK_REMOVE_CARGO_LOADING:
      return {
        ...state,
        bulkRemoveFromCargoLoading: true,
      };
    case cargoTypes.BULK_REMOVE_CARGO_SUCCESS:
      return {
        ...state,
        bulkRemoveFromCargoSuccess: action.payload,
        bulkRemoveFromCargoError: null,
        bulkRemoveFromCargoLoading: null,
      };
    case cargoTypes.BULK_REMOVE_CARGO_ERROR:
      return {
        ...state,
        bulkRemoveFromCargoSuccess: null,
        bulkRemoveFromCargoError: action.payload,
        bulkRemoveFromCargoLoading: null,
      };
    case cargoTypes.CLEAR_BULK_REMOVE_CARGO_DATA:
      return {
        ...state,
        bulkRemoveFromCargoLoading: null,
        bulkRemoveFromCargoSuccess: null,
        bulkRemoveFromCargoError: null,
      };
    case cargoTypes.SAVE_SCAN_SETTINGS_DATA:
      return {
        ...state,
        scanSettingsData: action.payload,
      };
    case cargoTypes.ADD_CARGO_EXCEPTIONS:
      return {
        ...state,
        exceptions: state.exceptions.concat(action.payload),
      };
    case cargoTypes.REMOVE_CARGO_EXCEPTIONS:
      return {
        ...state,
        exceptions: [],
      };
    case cargoTypes.ADD_SCANNER_DATA:
      return {
        ...state,
        warningNotificationData: action.payload.warningNotificationData,
        successNotificationData: action.payload.successNotificationData,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
      };
    case cargoTypes.RESET_SCAN_STATE:
      console.log("action. ", action.payload);
      return {
        ...state,
        warningNotificationData: action.payload.warningNotificationData,
        successNotificationData: action.payload.successNotificationData,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
      };
    default:
      return state;
  }
}
