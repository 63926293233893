import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import { commonApi, trolleyScanApi, cargoApi } from "../../../api";
import { getSessionID } from "../../../actions/authActions";
import { connect } from "react-redux";
import { message } from "antd";
import { trolleyActions, commonActions } from "../../../actions/rootActions";
import Settings from "../commonComponents/Settings";
import TrolleyActionItemsList from "./TrolleyActionItemsList";
import TrolleyNotificationArea from "./TrolleyNotificationArea";
import AddLocation from "./AddLocation";
import axios from "axios";
import * as _ from "lodash";
import { SUCCESS, VISIT_TROLLEY_SCAN_PAGE } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";

function HmsTrolleyScanner(props) {
  const scanNumberInputRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const {
    latestScannedNumber,
    orderData,
    errorNotificationData,
    notificationBoxType,
    scanSettings,
    scannerInformation,
    scannedNumbersList,
    fetchTrolleyPackagesSuccess,
  } = props;

  const [toggleContent, setToggleContent] = useState({
    showMainContent: true,
    showSettingsContent: false,
    showScannedNumbersListContent: false,
    showLocationContent: false,
  });

  const [setScanSettings] = useState({});

  const [state, setState] = useState({
    groupId: "",
    tag: "",
    scanNumber: "",
  });

  useEffect(() => {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      const data = {
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
      };
      props.dispatch(commonActions.setScannerInformation(data));
      const mixpanelData = getMixpanelData();
      mixPanelTrack(VISIT_TROLLEY_SCAN_PAGE,{
        [SUCCESS]: true,
        ...mixpanelData
      })
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    createSession("sweep");
  }, []);

  useEffect(() => {
    if (fetchTrolleyPackagesSuccess) {
      playSound("success");
      setState({
        ...state,
        scanNumber: "",
      });
      props.dispatch(trolleyActions.clearFetchTrolleyPackagesData());
    }
  }, [fetchTrolleyPackagesSuccess]);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/login" />;
  }

  const locationScanType = "Check";

  return (
    <div className="scanner-bg-container">
      {toggleContent.showMainContent && mainContent()}
      {toggleContent.showLocationContent && locationContent()}
    </div>
  );

  function mainContent() {
    return (
      <React.Fragment>
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="title mb-0">Trolley Scan</p>
          <p className="bread-crumb mt-n1 mb-0">{">"}</p>
          <p className="title text-capitalize mb-0">{locationScanType}</p>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">{notificationAndScanInputField()}</div>
          <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">{actionItemsList()}</div>
        </div>
      </React.Fragment>
    );
  }

  function notificationAndScanInputField() {
    const noDataBoxClass = notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
    const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
    const validDataBoxClass = getValidDataBoxClass();

    return (
      <React.Fragment>
        <TrolleyNotificationArea
          noDataBoxClass={noDataBoxClass}
          errorDataBoxClass={errorDataBoxClass}
          errorNotificationData={errorNotificationData}
          validDataBoxClass={validDataBoxClass}
          scannedNumbersList={scannedNumbersList}
          latestScannedNumber={latestScannedNumber}
          scanNumberInputRef={scanNumberInputRef}
          scanNumber={state.scanNumber}
          orderData={orderData}
          onChangeScanNumberChange={onChangeScanNumberChange}
          onChangeScanNumberCameraChange={onChangeScanNumberCameraChange}
        />
      </React.Fragment>
    );
  }

  function actionItemsList() {
    const showActionItems = latestScannedNumber !== null;
    return (
      <TrolleyActionItemsList
        showActionItems={showActionItems}
        onClickActionItem={onClickActionItem}
        scannedNumbersList={scannedNumbersList}
        scanSettings={scanSettings}
        errorOnScanning={notificationBoxType.showErrorDataBox}
        scanType="check"
        pageName="Trolley Scan"
      />
    );
  }

  function locationContent() {
    return (
      <AddLocation
        latestScannedNumber={latestScannedNumber}
        onClickBackButton={onClickBackButton}
        orderData={orderData}
      />
    );
  }

  function onClickActionItem(name) {
    setToggleContent({
      ...toggleContent,
      showMainContent: false,
      [name]: true,
    });
  }

  function onScanSettingsChange(name, value) {
    setScanSettings({
      ...scanSettings,
      [name]: value,
    });
  }

  function onClickBackButton() {
    setToggleContent({
      showMainContent: true,
      showSettingsContent: false,
    });
  }

  function onChangeScanNumberCameraChange(scanResult) {
    const scannedNumber = scanResult.text;
    setState({
      ...state,
      scanNumber: scannedNumber,
    });
    onScan(scannedNumber);
  }

  function onChangeScanNumberChange(e) {
    const { value } = e.target;
    const { keyCode, which } = e;
    if (keyCode === 13 || which === 13) {
      onScan();
      return;
    }
    setState({
      ...state,
      scanNumber: value,
    });
  }

  function playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  async function onScan(scanNumber) {
    const scannedNumber = scanNumber ? scanNumber : state.scanNumber;
    if (scannedNumber === "") return;
    const params = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      temporary_group_id: scannedNumber.trim(),
    };
    props.dispatch(trolleyActions.getTrolleyPackageDetails(params, scannedNumbersList));
  }

  function getValidDataBoxClass() {
    if (notificationBoxType.showValidDataBox) {
      return "d-flex";
    }
    return "d-none";
  }

  function createSession(scanType) {
    props.dispatch(
      getSessionID({
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
        scan_type: scanType,
      })
    );
  }
}

const mapStateToProps = ({ trolley, common }) => ({
  latestScannedNumber: trolley.latestScannedNumber,
  orderData: trolley.orderData,
  errorNotificationData: trolley.errorNotificationData,
  notificationBoxType: trolley.notificationBoxType,
  fetchTrolleyPackagesSuccess: trolley.fetchTrolleyPackagesSuccess,
  scanSettings: trolley.scanSettingsData,
  scannerInformation: common.scannerInformation,
  scannedNumbersList: common.scannedNumbersList,
});

export default connect(mapStateToProps, null)(HmsTrolleyScanner);
