import { ROOT_URL, API_PRINT_TN } from "../actions";

export const parcelScanApi = {
  GET_ORDER_DETAILS: `${ROOT_URL}/hms/get_order_details/`,
  PROCESS_SCANNED_ORDERS: `${ROOT_URL}/hms/process_scanned_orders/`,
  GET_EXCEPTION_TYPES: `${ROOT_URL}/hms/exception_types/`,
  ADD_PARCEL_LOCATION: `${ROOT_URL}/hms/parcel_location/`,
  REMEDY_EXCEPTION: `${ROOT_URL}/hms/resolve_problems/`,
  UPDATE_DIMWEIGHT: `${ROOT_URL}/hms/update_order_dimweight/`,
  GET_DISPOSAL_ORDER_DETAILS: `${ROOT_URL}/hms/get_order_for_disposal/`,
  DISPOSE_PARCEL: `${ROOT_URL}/hms/confirm_orders_disposal/`,
  REDELIVERY_SCAN: `${ROOT_URL}/hms/redelivery_scan/`,
  REASSIGN_NETWORK_PARTNER: `${ROOT_URL}/order/reassign-partner/`,
  FULFILL_ACTION_REQUEST: `${ROOT_URL}/hms/fulfill_action_request/`,
  GET_RESOURCE_DEATILS_API: `${ROOT_URL}/hms/get_resource_details/`,
  RECORD_RESOURCE_USAGE: `${ROOT_URL}/hms/record_resource_usage/`,
  GET_LABEL_FROM_PRINT_TN: `${API_PRINT_TN}/hms/retrieve_label_from_print_tn/`,
};
