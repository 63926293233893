import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RedirectPrintLabel from './RedirectPrintLabel';
import Header from '../Header';

export default function HMSlite() {
  return (
    <Header>
      <Switch>
        <Route path="/scan/print-label" component={RedirectPrintLabel} />
      </Switch>
    </Header>
  )
}