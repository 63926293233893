/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Dropdown, Menu, Alert } from "antd";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { ROOT_URL } from "../../../actions/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import * as qz from "qz-tray";
import pdf2base64 from "pdf-to-base64";
import "./style.scss";
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Redeliver() {
  const scanNumberInput = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [latestScannedNumber, setLatestScannedNumber] = useState(null);
  const [scannedNumbersList, setScannedNumbersList] = useState([]);

  const [scannerInformation, setScannerInformation] = useState({});
  const [showScanInput, setShowScanInput] = useState(true);
  const [showScannedNumbers, setShowScannedNumbers] = useState(true);

  const [printer, setPrinter] = useState(null);
  const [qzTrayDownloadError, setQzTrayDownloadError] = useState(false);

  const [notificationBoxType, setNotificationBoxType] = useState({
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
  });

  const [state, setState] = useState({
    scanNumber: "",
  });

  const [successNotificationData, setSuccessNotificationData] = useState({});
  const [errorNotificationData, setErrorNotificationData] = useState({});

  const [copyInProgress, setCopyInProgress] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      setScannerInformation({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    createSession();
    setDefaultPrinter();
  }, []);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/old/login" />;
  }

  const scanInputClass = showScanInput ? "" : "d-none";

  const noDataBoxClass = notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
  const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";

  const scannedNumberContentClass = showScannedNumbers ? "" : "d-none";

  const validDataBoxClass = notificationBoxType.showValidDataBox ? "d-flex" : "d-none";

  const errorMessageClass = qzTrayDownloadError ? "" : "d-none";

  return (
    <div className="redeliver-bg-container d-flex flex-column align-items-left">
      <div className="d-flex align-items-center">
        <h1 className="font-weight-bolder pl-0 float-left mb-0">Redeliver</h1>
        <div className="ml-auto">
          <Dropdown overlay={getMenu()} placement="bottomLeft" trigger={["click"]}>
            <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
              Menu
            </button>
          </Dropdown>
          <button type="button" className="btn btn-outline-danger" onClick={clearSession}>
            End Session
          </button>
        </div>
      </div>
      <div className="d-flex redeliver-breadcrumb">
        <nav aria-label="breadcrumb mr-auto">
          <ol className="breadcrumb p-0 m-0">
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan">
                <a className="standard-font-size">Home</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan/hubmanage/parcel-scan">
                <a className="standard-font-size">HMS Scanner</a>
              </LinkContainer>
            </li>
            <li
              className="breadcrumb-item active breadcrumb-divider standard-font-size standard-font-color"
              aria-current="page"
            >
              Redeliver
            </li>
          </ol>
        </nav>
      </div>
      <div className="redeliver-scanner-information-container">
        <div className="scanner-information-content">
          <div className="d-flex flex-column">
            <span className="hub-id-heading">Hub ID</span>
            <span className="hub-id">{scannerInformation.hubId}</span>

            <span className="user-id-heading">User ID</span>
            <span className="user-id">{scannerInformation.userName}</span>
          </div>
        </div>
      </div>

      <div className="scan-input-container">
        <div className="d-flex flex-nowrap align-items-center">
          <span className="toggle-heading mr-auto">Scan Tracking No.</span>
          {!showScanInput && (
            <FontAwesomeIcon icon={faAngleDown} size="lg" className="toggle-icon" onClick={toggleScanInput} />
            // <i className="fa fa-angle-down fa-lg toggle-icon" aria-hidden="true" onClick={toggleScanInput}></i>
          )}
          {showScanInput && (
            <FontAwesomeIcon icon={faAngleUp} size="lg" className="toggle-icon" onClick={toggleScanInput} />
            // <i className="fa fa-angle-up fa-lg toggle-icon" aria-hidden="true" onClick={toggleScanInput}></i>
          )}
        </div>
        <div className={"scan-input-content " + scanInputClass}>
          <div className="d-flex flex-nowrap">
            <input
              ref={scanNumberInput}
              className="form-control scan-input"
              placeholder="Scan tracking no."
              value={state.scanNumber}
              onChange={(e) => onChangeScanNumberChange(e)}
              onKeyUp={(e) => onChangeScanNumberChange(e)}
            />
            <button className="scan-button" onClick={onScan}>
              Scan
            </button>
          </div>
        </div>
      </div>

      <div className={"qz-tray-error-message " + errorMessageClass}>
        <Alert type="error" message="QZ Tray is not connected." />
      </div>

      <div className={`empty-notification-container justify-content-center align-items-center ${noDataBoxClass}`}>
        <span className="empty-notification-text mb-0">
          Once you scan the barcode, you will see the scan result here.
        </span>
      </div>
      <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
        <span className="error-notification-text text-center text-capitalize">Error!</span>
        <span className={`error-notification-scan-text text-uppercase`}>{latestScannedNumber}</span>
        <span className="error-notification-text text-center text-capitalize">
          {errorNotificationData.errorMessage}
        </span>
      </div>
      <div
        className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
      >
        <span className={`notification-scan-text text-uppercase`}>{successNotificationData.tracking_no_message}</span>
        <span className={`notification-scan-number`}>{successNotificationData.tracking_no}</span>
        <span className={`notification-scan-text text-uppercase`}>
          {successNotificationData.new_tracking_no_message}
        </span>
        <span className={`notification-scan-number`}>{successNotificationData.new_tracking_no}</span>
        <span className={`notification-scan-text`}>{successNotificationData.label_printer_message}</span>
      </div>
      <div className="scanned-numbers-container">
        <div className="d-flex flex-nowrap align-items-center">
          <span className="toggle-heading mr-auto">{scannedNumbersList.length} Parcel Scanned</span>
          <CopyToClipboard text={scannedNumbersList} onCopy={onCopyScannedNumbersList}>
            <button className="copy-button" disabled={scannedNumbersList.length === 0}>
              {copyInProgress ? "Copied" : "Copy to Clipboard"}
            </button>
          </CopyToClipboard>
          {!showScannedNumbers && (
            <FontAwesomeIcon icon={faAngleDown} size="lg" className="toggle-icon" onClick={toggleScannedNumbers} />
            // <i className="fa fa-angle-down fa-lg toggle-icon" aria-hidden="true" onClick={toggleScannedNumbers}></i>
          )}
          {showScannedNumbers && (
            <FontAwesomeIcon icon={faAngleUp} size="lg" className="toggle-icon" onClick={toggleScannedNumbers} />
            // <i className="fa fa-angle-up fa-lg toggle-icon" aria-hidden="true" onClick={toggleScannedNumbers}></i>
          )}
        </div>
        <div className={"scanned-numbers-content " + scannedNumberContentClass}>
          <div className="d-flex flex-column">
            {scannedNumbersList &&
              scannedNumbersList.map((number) => {
                return (
                  <span key={number} className="scanned-number-text">
                    {number}
                  </span>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );

  function onCopyScannedNumbersList() {
    setCopyInProgress(true);
    setTimeout(() => setCopyInProgress(false), 2000);
  }

  function clearSession() {
    setIsLoggedIn(false);
  }

  function getMenu() {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  }

  function onChangeScanNumberChange(e) {
    const { value } = e.target;
    const { keyCode, which } = e;
    if (keyCode === 13 || which === 13) {
      onScan();
      return;
    }
    setState({
      ...state,
      scanNumber: value,
    });
  }

  async function createSession() {
    try {
      // old
      const sessionId = await getSessionID();
      localStorage.setItem("sessionId", sessionId.data.session_id);
    } catch (e) {
      console.log("error creating session id");
    }
  }

  function getSessionID() {
    return axios.post(`${ROOT_URL}/hms/session/`, {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      scan_type: "sweep",
    });
  }

  function toggleScanInput() {
    setShowScanInput(!showScanInput);
  }

  function toggleScannedNumbers() {
    setShowScannedNumbers(!showScannedNumbers);
  }

  function setStateToErrorState(errorMessage) {
    setErrorNotificationData({
      errorMessage: errorMessage,
      showTrackingNumber: state.scanNumber,
      showDuplicateTrackingNumberError: false,
    });
    setNotificationBoxType({
      showNoDataBox: false,
      showValidDataBox: false,
      showErrorDataBox: true,
    });
  }

  function printLabel(labelPath) {
    if (!labelPath) {
      return;
    } else {
      pdf2base64(labelPath)
        .then((response) => {
          var config = qz.configs.create(printer);
          var data = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          if (!qz.websocket.isActive()) {
            setQzTrayError(true);
          } else {
            qz.print(config, data)
              .then(function () { })
              .catch((err) => setQzTrayError(err));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function setQzTrayError(err) {
    setQzTrayDownloadError(err);
    setTimeout(() => {
      setQzTrayDownloadError(err);
    }, 3000);
  }

  function setDefaultPrinter() {
    const defaultPrinter = localStorage.getItem("defaultPrinter");
    setPrinter(defaultPrinter);
  }

  function playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  async function redeliverScannedNumber(trackingNumber) {
    // old
    return axios
      .post(`${ROOT_URL}/hms/redelivery_scan/`, {
        hub_id: scannerInformation.hubId,
        hubscanner_key: scannerInformation.userId,
        session_id: localStorage.getItem("sessionId"),
        tracking_no: trackingNumber,
      })
      .then((res) => {
        setLatestScannedNumber(res.data.tracking_no);
        setSuccessNotificationData({
          tracking_no: res.data.tracking_no,
          tracking_no_message: "Taken over for redelivery",
          new_tracking_no: res.data.new_tracking_no,
          new_tracking_no_message: "New Tracking Number",
          label_printer_message: "Check Label Printer for New Label",
          action_request: res.data.action_request,
        });
        setNotificationBoxType({
          showNoDataBox: false,
          showValidDataBox: true,
          showErrorDataBox: false,
        });
        printLabel(res.data.redelivery_label);
        playSound("success");
        return true;
      })
      .catch((e) => {
        scanNumberInput && scanNumberInput.current && scanNumberInput.current.focus();
        setState({
          ...state,
          scanNumber: "",
        });
        setLatestScannedNumber(trackingNumber);
        playSound("error");
        setStateToErrorState(
          e.data.error ? e.data.error : "Unknown Error, please screenshot and send to Tech Support."
        );
        return false;
      });
  }

  async function onScan() {
    if (state.scanNumber === "") return;
    if (await redeliverScannedNumber(state.scanNumber)) {
      let trackingNumbersList = [...scannedNumbersList];
      trackingNumbersList.unshift(state.scanNumber);
      setScannedNumbersList(trackingNumbersList);
      setState({
        ...state,
        scanNumber: "",
      });
    }
  }
}
