import axios from "axios";
import { picklistTypes } from "../types";
import { picklistApi } from "../api/";
import { getMixpanelData, mixPanelTrack } from "../utils/mixpanelUtils";
import { DOWNLOAD_PICK_LIST_CSV, ERROR, PICK_LIST_SHOW_DROPDOWN, SHOW_DROPDOWN_VALUE, SUCCESS } from "../constants/mixpanelConstants";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const defaultToken = localStorage.getItem("token");

if (defaultToken) {
  axios.defaults.headers.common["Authorization"] = "Token " + defaultToken;
}

// get the pick list data
export const getPicklistData = (payload) => {
  return async function (dispatch) {
    try {
      const res = await axios.post(picklistApi.GET_PICK_LIST_DATA, payload);
      dispatch({
        type: picklistTypes.GET_PICK_LIST_DATA_SUCCESS,
        payload: { ...res.data, pageSize: payload.pageSize },
      });
    } catch (err) {
      dispatch({
        type: picklistTypes.GET_PICK_LIST_DATA_ERROR,
      });
    }
  };
};

export const downloadPicklist = (payload) => {
  return async function (dispatch) {
    try {
      const res = await axios.post(picklistApi.DOWNLOAD_PICK_LIST_DATA, payload, {
        responseType: "blob",
        headers: {
          Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "pick-list.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      const mixpanelData = getMixpanelData();
      mixPanelTrack(DOWNLOAD_PICK_LIST_CSV, {
        [SUCCESS]: true,
        ...mixpanelData
      })
    } catch (err) {
      const mixpanelData = getMixpanelData();
      mixPanelTrack(DOWNLOAD_PICK_LIST_CSV, {
        [SUCCESS]: false,
        [ERROR]: err,
        ...mixpanelData
      })
      dispatch({
        type: picklistTypes.ADD_DOWNLOAD_PICK_LIST_DATA_ERROR,
      });
      setTimeout(() => {
        dispatch({
          type: picklistTypes.REMOVE_DOWNLOAD_PICK_LIST_DATA_ERROR,
        });
      }, 5000);
    }
  };
};

export const getActionRequestCount = (payload, value) => {
  return async function (dispatch) {
    try {
      const res = await axios.post(picklistApi.ACTION_REQUEST_COUNT, payload);
      const data = {};
      res.data &&
        res.data.forEach((item) => {
          data[item.name] = item.count;
        });
      const mixPanelData = getMixpanelData();
      mixPanelTrack(PICK_LIST_SHOW_DROPDOWN, {
        [SUCCESS]: true,
        [SHOW_DROPDOWN_VALUE]: value,
        ...mixPanelData
      })
      dispatch({
        type: picklistTypes.GET_ACTION_REQUEST_COUNT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      console.log("action request count fetch error", err);
      const mixPanelData = getMixpanelData();
      mixPanelTrack(PICK_LIST_SHOW_DROPDOWN, {
        [SUCCESS]: false,
        [ERROR]: err,
        [SHOW_DROPDOWN_VALUE]: value,
        ...mixPanelData
      })
      dispatch({
        type: picklistTypes.GET_ACTION_REQUEST_COUNT_ERROR,
        payload: "Could not fetch data",
      });
    }
  };
};
