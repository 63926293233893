/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { shipmentActions, cargoActions } from "../../../actions/rootActions";
import * as _ from "lodash";
import "./style.scss";
//import {
//SHIPMENTS_FILTERS_CHANGE,
//FILTER_NAME,
//FILTER_VALUE,
//} from "../../../constants/mixpanelConstants";
//import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";

function ShipmentData(props) {
  const { shipmentNumbersData, shipmentNumbersList } = props;

  const [currentPage, setCurrentPage] = useState(1);

  //const [menuVisible, setMenuVisible] = useState({
  //trackingCodeVisible: false,
  //recipientVisible: false,
  //statusVisible: false,
  //});

  //const [filters, setFilters] = useState({
  //trackingCode: "",
  //recipient: "",
  //status: "",
  //});

  const [shipments, setShipments] = useState(shipmentNumbersList);

  useEffect(() => {
    if (!_.isEqual(shipments, shipmentNumbersList)) {
      setShipments(shipmentNumbersList);
    }
  }, [shipmentNumbersList]);

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="bg-container w-100 padding-20 pt-0">
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="title mb-0">Shipments</p>
        </div>
        <div className="bg-container-body">
          <button
            className="secondary-button margin-bottom-20 margin-top-20 full-button-width"
            onClick={goToCreateShipmentPage}
          >
            Create Shipment
          </button>
          <div className="table-responsive">
            <table className="table table-striped table-borderless">
              <thead className="table-header--bottom-border">
                <tr>
                  <td>
                    <span className="cursor-pointer">Tracking Number</span>
                    {/* <Dropdown
                      overlay={trackingNumberFilterMenu}
                      trigger={["click"]}
                      overlayClassName="hms-dropdown-menu"
                      onVisibleChange={() =>
                        handleVisibleChange("trackingCodeVisible")
                      }
                      visible={menuVisible.trackingCodeVisible}
                    >
                      <div className="d-flex flex-nowrap">
                        <span className="cursor-pointer">
                          Tracking Code{" "}
                          <i
                            className="fa fa-filter filter-icon cursor-pointer"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </Dropdown> */}
                  </td>
                  <td className="table-header-extended">
                    <span className="cursor-pointer">Recipient</span>
                    {/* <Dropdown
                      overlay={recipientFilterMenu}
                      trigger={["click"]}
                      overlayClassName="hms-dropdown-menu"
                      onVisibleChange={() =>
                        handleVisibleChange("recipientVisible")
                      }
                      visible={menuVisible.recipientVisible}
                    >
                      <span className="cursor-pointer">
                        Recipient{" "}
                        <i
                          className="fa fa-filter filter-icon cursor-pointer"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </Dropdown> */}
                  </td>
                  <td className="">
                    <span className="cursor-pointer">Status in Hub</span>
                    {/* <Dropdown
                      overlay={statusFilterMenu}
                      trigger={["click"]}
                      overlayClassName="hms-dropdown-menu"
                      onVisibleChange={() =>
                        handleVisibleChange("statusVisible")
                      }
                      visible={menuVisible.statusVisible}
                    >
                      <span className="cursor-pointer">
                        Status in Hub{" "}
                        <i
                          className="fa fa-filter filter-icon cursor-pointer"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </Dropdown> */}
                  </td>
                </tr>
              </thead>
              <tbody>
                {shipments &&
                  shipments.length > 0 &&
                  shipments.map((shipment, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-span-link" onClick={() => goToShipmentNumberData(shipment)}>
                          {shipment.tracking_no}
                        </td>
                        <td className="text-span-black table-header-extended">{shipment.recipient_name}</td>
                        <td className="text-span-black">{shipment.status}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {shipments && shipments.length > 0 && (
            <div className="mt-3 text-center">
              <Pagination
                current={currentPage}
                defaultPageSize={50}
                total={shipmentNumbersData.total_records}
                onChange={onPageChange}
                className="hms-antd-pagination"
                responsive
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  //! DISABLED TILL BACKEND FILTER IS DONE
  // function trackingNumberFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Tracking Code"
  //           placeholder="Type here"
  //           name="trackingCode"
  //           value={filters.trackingCode}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("trackingCode")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  // function recipientFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Recipient"
  //           placeholder="Type here"
  //           name="recipient"
  //           value={filters.recipient}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("recipient")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  // function statusFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Status"
  //           placeholder="Type here"
  //           name="status"
  //           value={filters.status}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("status")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  //function onInputChange(e) {
  //const { name, value } = e.target;
  //setFilters({
  //...filters,
  //[name]: value,
  //});
  //// mixpanel shipment filter change
  //const mixPanelData = getMixpanelData();
  //mixPanelTrack(SHIPMENTS_FILTERS_CHANGE, {
  //...mixPanelData,
  //[FILTER_NAME]: name,
  //[FILTER_VALUE]: value,
  //})
  //}

  //function onClickReset(name) {
  //let newFilters = { ...filters };
  //newFilters[name] = "";
  //setFilters(newFilters);
  //onClickFilter(null, newFilters);
  //}

  //function onClickFilter(e, resetFilters) {
  //const listFilters = resetFilters ? resetFilters : filters;
  //let list = shipmentNumbersList;
  //if (!_.isEmpty(listFilters.trackingCode)) {
  //list = _.filter(list, (x) => x.tracking_no === listFilters.trackingCode);
  //} else if (!_.isEmpty(listFilters.recipient)) {
  //list = _.filter(list, (x) => x.recipient_name === listFilters.recipient);
  //} else if (!_.isEmpty(listFilters.status)) {
  //list = _.filter(
  //list,
  //(x) => x.status.toUpperCase() === listFilters.status.toUpperCase()
  //);
  //}
  //setShipments(list);
  //}

  //function handleVisibleChange(name) {
  //setMenuVisible({
  //...menuVisible,
  //[name]: !menuVisible[name],
  //});
  //}

  function goToShipmentNumberData(shipment) {
    props.history.push({
      pathname: `/scan/hubmanage/shipments/${shipment.tracking_no}`,
      state: { shipment: shipment },
    });
  }

  function goToCreateShipmentPage() {
    showCreateShipment();
    props.history.push(`/scan/hubmanage/create-shipment`);
  }

  function showCreateShipment() {
    props.dispatch(shipmentActions.shipmentResetState());
    props.dispatch(cargoActions.cargoResetState());
  }

  function onPageChange(page) {
    setCurrentPage(page);
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: page,
    };
    props.dispatch(shipmentActions.getShipmentNumbersList(data));
  }
}

export default connect(null, null)(withRouter(ShipmentData));
