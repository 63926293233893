import axios from "axios";
import { parcelScanApi, commonApi } from "../api";

const getExceptionsTypes = (params) => {
  return axios({
    method: 'GET',
    url: parcelScanApi.GET_EXCEPTION_TYPES,
    params: params,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  }).then(res => res.data)
};

const getRecipientsList = () => {
  return axios({
    method: 'GET',
    url: commonApi.GET_RECIPIENTS,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  }).then(res => res.data)
};

const getShipmentNumbersList = (params) => {
  return axios({
    method: 'GET',
    url: commonApi.GET_SHIPMENT_NUMBERS_LIST,
    params: params,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  }).then(res => res.data)
};

export const commonServices = {
  getExceptionsTypes,
  getRecipientsList,
  getShipmentNumbersList
}