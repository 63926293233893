import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../../deprecated/Header';
import HMSAuth from './HMSAuth';
import HMSCargoScanner from './HMSCargoScanner';
import HMSReports from './HMSReports';
import HMSScanner from './HMSScanner';
import HMSSettings from './HMSSettings';
import HMSShipments from './HMSShipments/HMSContainer';
import MixPanelRoute from '../MixPanelRoute';
import PickList from './pickList/PickList';
import Disposal from './disposal/Disposal';
import RTV from './rtv/Rtv';
import Redeliver from './redeliver/Redeliver';


export default function HMSlite() {
  return (
    <Header>
      <Switch>
        <Route path="/scan/hubmanage/old/login" component={HMSAuth} />
        <MixPanelRoute path="/scan/hubmanage/old/parcel-scan" component={HMSScanner} />
        <MixPanelRoute path="/scan/hubmanage/old/cargo-scan" component={HMSCargoScanner} />
        <Route path="/scan/hubmanage/old/shipments" component={HMSShipments} />
        <Route path="/scan/hubmanage/old/settings" component={HMSSettings} />
        <Route path="/scan/hubmanage/old/reports" component={HMSReports} />
        <Route path="/scan/hubmanage/old/pick-list" component={PickList} />
        <Route path="/scan/hubmanage/old/disposal" component={Disposal} />
        <Route path="/scan/hubmanage/old/rtv" component={RTV} />
        <Route path="/scan/hubmanage/old/redeliver" component={Redeliver} />
      </Switch>
    </Header>
  )
}