import React from "react";
import { Route, Switch } from "react-router-dom";
import Header from "../../common/header/header";
import ParcelScanner from "./parcelScanner/ParcelScanner";
import ParcelScannerPage from "./parcelScanner";
import CargoScannerPage from "./cargoScanner";
import CargoScanner from "./cargoScanner/CargoScanner";
import TrolleyScanner from "./trolleyScanner";
import Shipments from "./shipments";
import CreateShipment from "./shipments/createShipment";
import Cargos from "./cargos";
import ShipmentDetails from "./shipments/shipmentDetailsPage";
import Reports from "./reports/Reports";
import Settings from "./settings/Settings";
import PickList from "./pickList";
import ScannedNumbersList from "./commonComponents/ScannedNumbersList";
import UpdateWeight from "./parcelScanner/UpdateWeight";
import Timeline from "./parcelScanner/Timeline";
import ParcelSettings from "./parcelScanner/Settings";
import TrolleyScannerSettings from "./trolleyScanner/TrolleySettings";
import TrolleyScannerAddLocation from "./trolleyScanner/AddLocation";
import AddExceptions from "./parcelScanner/AddExceptions";
import AddLocation from "./parcelScanner/AddLocation";
import InsideThisShipment from "./shipments/insideThisShipment";
import CancelShipment from "./shipments/cancelShipment";
import CreateCargoInShipment from "./shipments/createCargo";
import EditShipment from "./shipments/editShipment";
import AddCargoToShipment from "./shipments/addCargoToShipment";
import RemoveCargoFromShipment from "./shipments/removeCargoFromShipment";
import InsideThisCargo from "./cargos/insideThisCargo";
import CargoPartOf from "./cargos/cargoPartOf";
import EditCargo from "./cargos/editCargo";
import AddCargoToCargo from "./cargos/addCargoToCargo";
import RemoveCargoFromCargo from "./cargos/removeCargoFromCargo";
import CargoScannerSettings from "./cargoScanner/Settings";
import CancelCargo from "./cargos/cancelCargo";
import BulkRemoveFromCargo from "./cargos/bulkRemoveFromCargo";
import SeePhotos from "./cargos/SeePhotos";
import TakePhotos from "./cargoScanner/TakePhotos";
import PrivateRoute from "../PrivateRoute";
import AddResource from "./parcelScanner/AddResource";

export default function HMSlite() {
  return (
    <Header>
      <Switch>
        <PrivateRoute path="/scan/hubmanage/parcel-scan/:scanType" component={ParcelScanner} />
        <PrivateRoute path="/scan/hubmanage/parcel-scan" component={ParcelScannerPage} />
        <PrivateRoute path="/scan/hubmanage/remove-cargo-from-cargo" component={RemoveCargoFromCargo} />
        <PrivateRoute path="/scan/hubmanage/add-cargo-to-cargo" component={AddCargoToCargo} />
        <PrivateRoute path="/scan/hubmanage/edit-cargo" component={EditCargo} />
        <PrivateRoute path="/scan/hubmanage/cargo-part-of" component={CargoPartOf} />
        <PrivateRoute path="/scan/hubmanage/inside-cargo" component={InsideThisCargo} />
        <PrivateRoute path="/scan/hubmanage/cargo/:cargoNumber" component={Cargos} />
        <PrivateRoute path="/scan/hubmanage/take-cargo-photos" component={TakePhotos} />
        <PrivateRoute path="/scan/hubmanage/cargo-photos" component={SeePhotos} />
        <PrivateRoute path="/scan/hubmanage/bulk-remove-from-cargo" component={BulkRemoveFromCargo} />
        <PrivateRoute path="/scan/hubmanage/cancel-cargo" component={CancelCargo} />
        <PrivateRoute path="/scan/hubmanage/cargo-scanner/settings" component={CargoScannerSettings} />
        <PrivateRoute path="/scan/hubmanage/cargo-scan/:scanType" component={CargoScanner} />
        <PrivateRoute path="/scan/hubmanage/cargo-scan" component={CargoScannerPage} />
        <PrivateRoute path="/scan/hubmanage/trolley-scan" component={TrolleyScanner} />
        <PrivateRoute path="/scan/hubmanage/shipments/:shipmentNo" component={ShipmentDetails} />
        <PrivateRoute path="/scan/hubmanage/remove-cargo-from-shipment" component={RemoveCargoFromShipment} />
        <PrivateRoute path="/scan/hubmanage/add-cargo-to-shipment" component={AddCargoToShipment} />
        <PrivateRoute path="/scan/hubmanage/edit-shipment" component={EditShipment} />
        <PrivateRoute path="/scan/hubmanage/create-cargo-in-shipment" component={CreateCargoInShipment} />
        <PrivateRoute path="/scan/hubmanage/cancel-shipment" component={CancelShipment} />
        <PrivateRoute path="/scan/hubmanage/inside-shipment" component={InsideThisShipment} />
        <PrivateRoute path="/scan/hubmanage/create-shipment" component={CreateShipment} />
        <PrivateRoute path="/scan/hubmanage/shipments" component={Shipments} />
        <PrivateRoute path="/scan/hubmanage/scanner/settings" component={ParcelSettings} />
        <PrivateRoute path="/scan/hubmanage/trolley-scanner/add-location" component={TrolleyScannerAddLocation} />
        <PrivateRoute path="/scan/hubmanage/trolley-scanner/settings" component={TrolleyScannerSettings} />
        <PrivateRoute path="/scan/hubmanage/add-location" component={AddLocation} />
        <PrivateRoute path="/scan/hubmanage/add-exception" component={AddExceptions} />
        <PrivateRoute path="/scan/hubmanage/timeline" component={Timeline} />
        <PrivateRoute path="/scan/hubmanage/update-weight" component={UpdateWeight} />
        <PrivateRoute path="/scan/hubmanage/scanned-numbers-list" component={ScannedNumbersList} />
        <PrivateRoute path="/scan/hubmanage/reports" component={Reports} />
        <PrivateRoute path="/scan/hubmanage/settings" component={Settings} />
        <PrivateRoute path="/scan/hubmanage/pick-list" component={PickList} />
        <PrivateRoute path="/scan/hubmanage/scanner/add-resource" component={AddResource} />
      </Switch>
    </Header>
  );
}
