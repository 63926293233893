export const cargoTypes = {
  ADD_CARGO_LOADING: "ADD_CARGO_LOADING",
  ADD_CARGO: "ADD_CARGO",
  ADD_CARGO_ERROR: "ADD_CARGO_ERROR",

  CREATE_CARGO_LOADING: "CREATE_CARGO_LOADING",
  CREATE_CARGO: "CREATE_CARGO",
  CREATE_CARGO_ERROR: "CREATE_CARGO_ERROR",

  CLEAR_CARGO_RESPONSE: "CLEAR_CARGO_RESPONSE",
  CLEAR_CREATE_CARGO_ERROR: "CLEAR_CREATE_CARGO_ERROR",
  CLEAR_CREATE_CARGO_RESPONSE: "CLEAR_CREATE_CARGO_RESPONSE",
  CLEAR_CARGO_STATE: "CLEAR_CARGO_STATE",
  CARGO_RESET_STATE: "CARGO_RESET_STATE",

  REMOVE_CARGO_FROM_PARENT_SUCCESS: "REMOVE_CARGO_FROM_PARENT_SUCCESS",
  REMOVE_CARGO_FROM_PARENT_ERROR: "REMOVE_CARGO_FROM_PARENT_ERROR",
  CLEAR_REMOVE_CARGO_STATE: "CLEAR_REMOVE_CARGO_STATE",
  SET_CARGO: "SET_CARGO",

  ADD_CARGO_TO_SHIPMENT_LOADING: "ADD_CARGO_TO_SHIPMENT_LOADING",
  ADD_CARGO_TO_SHIPMENT: "ADD_CARGO_TO_SHIPMENT",
  ADD_CARGO_TO_SHIPMENT_ERROR: "ADD_CARGO_TO_SHIPMENT_ERROR",
  CLEAR_ADD_CARGO_TO_SHIPMENT_RESPONSE: "CLEAR_ADD_CARGO_TO_SHIPMENT_RESPONSE",

  GET_COUNTRIES_LIST: "GET_COUNTRIES_LIST",
  GET_COUNTRIES_ERROR: "GET_COUNTRIES_ERROR",

  GET_CARGO_DETAILS_SUCCESS: "GET_CARGO_DETAILS_SUCCESS",
  GET_CARGO_DETAILS_ERROR: "GET_CARGO_DETAILS_ERROR",

  PROCESS_SCANNED_ARRIVAL_BREAK_CARGOS_SUCCESS: "PROCESS_SCANNED_ARRIVAL_BREAK_CARGOS_SUCCESS",
  PROCESS_SCANNED_ARRIVAL_BREAK_CARGOS_ERROR: "PROCESS_SCANNED_ARRIVAL_BREAK_CARGOS_ERROR",

  PROCESS_SCANNED_CARGOS_SUCESS: "PROCESS_SCANNED_CARGOS_SUCESS",
  PROCESS_SCANNED_CARGOS_ERROR: "PROCESS_SCANNED_CARGOS_ERROR",

  UPLOAD_CARGO_PHOTOS_SUCCESS: "UPLOAD_CARGO_PHOTOS_SUCCESS",
  UPLOAD_CARGO_PHOTOS_ERROR: "UPLOAD_CARGO_PHOTOS_ERROR",

  CLEAR_CARGO_INFO: "CLEAR_CARGO_INFO",

  SEE_PHOTOS_SUCCESS: "SEE_PHOTOS_SUCCESS",
  SEE_PHOTOS_ERROR: "SEE_PHOTOS_ERROR",

  GET_TRACKING_NUMBERS_INSIDE_CARGO_LOADING: "GET_TRACKING_NUMBERS_INSIDE_CARGO_LOADING",
  GET_TRACKING_NUMBERS_INSIDE_CARGO_SUCCESS: "GET_TRACKING_NUMBERS_INSIDE_CARGO_SUCCESS",
  GET_TRACKING_NUMBERS_INSIDE_CARGO_ERROR: "GET_TRACKING_NUMBERS_INSIDE_CARGO_ERROR",

  CANCEL_CARGO_SUCCESS: "CANCEL_CARGO_SUCCESS",
  CANCEL_CARGO_ERROR: "CANCEL_CARGO_ERROR",

  BULK_REMOVE_CARGO_LOADING: "BULK_REMOVE_CARGO_LOADING",
  BULK_REMOVE_CARGO_SUCCESS: "BULK_REMOVE_CARGO_SUCCESS",
  BULK_REMOVE_CARGO_ERROR: "BULK_REMOVE_CARGO_ERROR",
  CLEAR_BULK_REMOVE_CARGO_DATA: "CLEAR_BULK_REMOVE_CARGO_DATA",

  SAVE_SCAN_SETTINGS_DATA: "SAVE_SCAN_SETTINGS_DATA",

  ADD_SCANNER_DATA: "ADD_SCANNER_DATA",

  CLEAR_TRACKING_NUMBERS_INSIDE_CARGO_DATA: "CLEAR_TRACKING_NUMBERS_INSIDE_CARGO_DATA",

  CLEAR_PROCESS_SCAN_ORDER: "CLEAR_PROCESS_SCAN_ORDER",

  CLEAR_GET_CARGO_DETAILS_ERROR: "CLEAR_GET_CARGO_DETAILS_ERROR",
  ADD_CARGO_EXCEPTIONS: "ADD_CARGO_EXCEPTIONS",
  REMOVE_CARGO_EXCEPTIONS: "REMOVE_CARGO_EXCEPTIONS",
  RESET_SCAN_STATE: "RESET_SCAN_STATE",
};
