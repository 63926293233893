/* eslint-disable react-hooks/exhaustive-deps, jsx-a11y/anchor-is-valid */
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu, Modal } from "antd";
import axios from "axios";
import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { ROOT_URL } from "../../../actions/index";
import "./style.scss";

export default function Disposal() {
  const scanNumberInput = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  const [latestScannedNumber, setLatestScannedNumber] = useState(null);
  const [scannedNumbersList, setScannedNumbersList] = useState([]);

  const [scannerInformation, setScannerInformation] = useState({});
  const [showScanInput, setShowScanInput] = useState(true);
  const [showScannedNumbers, setShowScannedNumbers] = useState(true);

  const [notificationBoxType, setNotificationBoxType] = useState({
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
  });

  const [state, setState] = useState({
    scanNumber: "",
  });

  const [successNotificationData, setSuccessNotificationData] = useState({});
  const [errorNotificationData, setErrorNotificationData] = useState({});

  const [showDisposalModal, setShowDisposalModal] = useState(false);
  const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(false);

  const [disposalConfirm, setDisposalConfirm] = useState(false);
  const [copyInProgress, setCopyInProgress] = useState(false);

  useEffect(() => {
    createSession();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      setScannerInformation({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/old/login" />;
  }

  const scanInputClass = showScanInput ? "" : "d-none";

  const noDataBoxClass = notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
  const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
  const validDataBoxClass = notificationBoxType.showValidDataBox ? "d-flex flex-column" : "d-none";

  const disposalMessageClass = successNotificationData.message ? "" : "d-none";
  const disposalNumberClass = successNotificationData.tracking_no ? "" : "d-none";
  const disposalConfirmClass = successNotificationData.diposal_confirm ? "" : "d-none";

  const scannedNumberContentClass = showScannedNumbers ? "" : "d-none";

  const scanInputContainerClass = !disposalConfirm ? "" : "d-none";

  return (
    <div className="disposal-bg-container d-flex flex-column align-items-left">
      <div className="d-flex align-items-center">
        <h1 className="font-weight-bolder pl-0 float-left mb-0">Disposal</h1>
        <div className="ml-auto">
          <Dropdown overlay={getMenu()} placement="bottomLeft" trigger={["click"]}>
            <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
              Menu
            </button>
          </Dropdown>
          <button type="button" className="btn btn-outline-danger" onClick={clearSession}>
            End Session
          </button>
        </div>
      </div>
      <div className="d-flex diposal-breadcrumb">
        <nav aria-label="breadcrumb mr-auto">
          <ol className="breadcrumb p-0 m-0">
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan">
                <a className="standard-font-size">Home</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan/hubmanage/parcel-scan">
                <a className="standard-font-size">HMS Scanner</a>
              </LinkContainer>
            </li>
            <li
              className="breadcrumb-item active breadcrumb-divider standard-font-size standard-font-color"
              aria-current="page"
            >
              Disposal
            </li>
          </ol>
        </nav>
      </div>
      <div className="disposal-scanner-information-container">
        <div className="scanner-information-content">
          <div className="d-flex flex-column">
            <span className="hub-id-heading">Hub ID</span>
            <span className="hub-id">{scannerInformation.hubId}</span>

            <span className="user-id-heading">User ID</span>
            <span className="user-id">{scannerInformation.userName}</span>
          </div>
        </div>
      </div>

      <div className={`scan-input-container ${scanInputContainerClass}`}>
        <div className="d-flex flex-nowrap align-items-center">
          <span className="toggle-heading mr-auto">Scan Tracking No.</span>
          {!showScanInput && (
            <FontAwesomeIcon icon={faAngleDown} size="lg" className="toggle-icon" onClick={toggleScanInput} />
            // <i className="fa fa-angle-down fa-lg toggle-icon" aria-hidden="true" onClick={toggleScanInput}></i>
          )}
          {showScanInput && (
            <FontAwesomeIcon icon={faAngleUp} size="lg" className="toggle-icon" onClick={toggleScanInput} />
            // <i className="fa fa-angle-up fa-lg toggle-icon" aria-hidden="true" onClick={toggleScanInput}></i>
          )}
        </div>
        <div className={"scan-input-content " + scanInputClass}>
          <div className="d-flex flex-nowrap">
            <input
              ref={scanNumberInput}
              className="form-control scan-input"
              placeholder="Scan tracking no."
              value={state.scanNumber}
              onChange={(e) => onChangeScanNumberChange(e)}
              onKeyUp={(e) => onChangeScanNumberChange(e)}
            />
            <button className="scan-button" onClick={onScan}>
              Scan
            </button>
          </div>
        </div>
      </div>
      <div className={`empty-notification-container justify-content-center align-items-center ${noDataBoxClass}`}>
        <span className="empty-notification-text mb-0">
          Once you scan the barcode, you will see the scan result here.
        </span>
      </div>

      <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
        <span className="error-notification-text mb-0 text-center text-capitalize">Error!</span>
        <span className="error-notification-scan-number mb-0 text-center text-capitalize">{latestScannedNumber}</span>
        <span className="error-notification-error-message mb-0 text-center text-capitalize">
          {errorNotificationData.errorMessage}
        </span>
      </div>

      <div
        className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
      >
        <span className={`notification-scan-text text-uppercase ${disposalMessageClass}`}>
          {successNotificationData.message}
        </span>
        <span className={`notification-scan-number ${disposalNumberClass}`}>{successNotificationData.tracking_no}</span>
        <span className={`notification-scan-number text-uppercase ${disposalConfirmClass}`}>
          {successNotificationData.diposal_confirm}
        </span>
      </div>

      <div className="scanned-numbers-container">
        <div className="d-flex flex-nowrap align-items-center">
          <span className="toggle-heading mr-auto">
            {scannedNumbersList.length} {scannedNumbersList.length > 1 ? "Parcels" : "Parcel"} Scanned
          </span>
          {!disposalConfirm && (
            <button className="confirm-button" onClick={updateTracker}>
              Confirm
            </button>
          )}
          {disposalConfirm && (
            <CopyToClipboard text={scannedNumbersList} onCopy={onCopyScannedNumbersList}>
              <button className="copy-to-clip-board-button" disabled={scannedNumbersList.length === 0}>
                {copyInProgress ? "Copied!" : "Copy to Clipboard"}
              </button>
            </CopyToClipboard>
          )}

          {!showScannedNumbers && (
            <FontAwesomeIcon icon={faAngleDown} size="lg" className="toggle-icon" onClick={toggleScannedNumbers} />
            // <i className="fa fa-angle-down fa-lg toggle-icon" aria-hidden="true" onClick={toggleScannedNumbers}></i>
          )}
          {showScannedNumbers && (
            <FontAwesomeIcon icon={faAngleUp} size="lg" className="toggle-icon" onClick={toggleScannedNumbers} />
            // <i className="fa fa-angle-up fa-lg toggle-icon" aria-hidden="true" onClick={toggleScannedNumbers}></i>
          )}
        </div>
        <div className={"scanned-numbers-content " + scannedNumberContentClass}>
          <div className="d-flex flex-column">
            {scannedNumbersList &&
              scannedNumbersList.map((number) => {
                return (
                  <div className="d-flex">
                    {!disposalConfirm && (
                      <i
                        class="fa fa-times fa-lg scanned-numbers-cross"
                        aria-hidden="true"
                        onClick={(e) => deleteConfirmationFromDisposal(e, number)}
                      ></i>
                    )}
                    <span key={number} className="scanned-number-text">
                      {number}
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <Modal className="disposal-confirmation-modal" visible={showDisposalModal} footer={null}>
        <p className="disposal-confirmation-text">Confirm Remove {selectedTrackingNumber} from Disposal?</p>
        <div className="d-flex flex-nowrap justify-content-center mt-2">
          <button className="disposal-confirmation-ok-button mr-2" onClick={onRemoveTrackingNumberFromDisposal}>
            OK
          </button>
          <button className="disposal-confirmation-cancel-button" onClick={onCloseDisposalModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );

  function onCopyScannedNumbersList() {
    setCopyInProgress(true);
    setTimeout(() => setCopyInProgress(false), 2000);
  }

  function clearSession() {
    setIsLoggedIn(false);
  }

  function getMenu() {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  }

  function deleteConfirmationFromDisposal(e, trackingNumber) {
    e.preventDefault();
    setShowDisposalModal(true);
    setSelectedTrackingNumber(trackingNumber);
  }

  function onRemoveTrackingNumberFromDisposal() {
    const trackingNumbersList = _.filter(scannedNumbersList, (x) => x !== selectedTrackingNumber);
    setScannedNumbersList(trackingNumbersList);
    setShowDisposalModal(false);
  }

  function onCloseDisposalModal() {
    setShowDisposalModal(false);
  }

  function onChangeScanNumberChange(e) {
    const { value } = e.target;
    const { keyCode, which } = e;
    if (keyCode === 13 || which === 13) {
      onScan();
      return;
    }
    setState({
      ...state,
      scanNumber: value,
    });
  }

  function toggleScanInput() {
    setShowScanInput(!showScanInput);
  }

  function toggleScannedNumbers() {
    setShowScannedNumbers(!showScannedNumbers);
  }

  function getParams(trackingNumber) {
    let params = {
      hub_id: scannerInformation.hubId,
      tracking_no: trackingNumber,
    };
    return params;
  }

  function setStateToErrorState(errorMessage) {
    setErrorNotificationData({
      errorMessage: errorMessage,
      showTrackingNumber: state.scanNumber,
      showDuplicateTrackingNumberError: false,
    });
    setNotificationBoxType({
      showNoDataBox: false,
      showValidDataBox: false,
      showErrorDataBox: true,
      showSortDataBox: false,
    });
  }

  function playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  async function fetchOrderInfo(trackingNumber) {
    return axios
      .get(`${ROOT_URL}/hms/get_order_for_disposal/`, {
        params: getParams(trackingNumber),
      })
      .then((res) => {
        setLatestScannedNumber(res.data.tracking_no);

        setSuccessNotificationData({
          ...successNotificationData,
          tracking_no: res.data.tracking_no,
          message: "Taken over for disposal",
        });

        setNotificationBoxType({
          showNoDataBox: false,
          showValidDataBox: true,
          showErrorDataBox: false,
        });
        playSound("success");
        return true;
      })
      .catch((e) => {
        scanNumberInput && scanNumberInput.current && scanNumberInput.current.focus();
        setState({
          ...state,
          scanNumber: "",
        });
        setLatestScannedNumber(trackingNumber);
        setStateToErrorState(e.data.error);
        playSound("error");
        return false;
      });
  }

  async function processOrder(orders) {
    // old
    return axios.post(`${ROOT_URL}/hms/confirm_orders_disposal/`, {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      scan_type: "sweep",
      session_id: localStorage.getItem("sessionId"),
      orders: orders,
    });
  }

  async function updateTracker(e) {
    e.preventDefault();
    try {
      let orderList = getOrders();
      await processOrder(orderList);
      setSuccessNotificationData({
        diposal_confirm: "Disposal Confirmed",
      });
      setDisposalConfirm(true);
      playSound("success");
      return true;
    } catch (e) {
      playSound("error");
      if (e.data && e.data.error) {
        setStateToErrorState(e.data.error);
      }
      return false;
    }
  }

  function getOrders() {
    let orderList = [];
    scannedNumbersList &&
      scannedNumbersList.length > 0 &&
      scannedNumbersList.forEach((trackingNumber) => {
        orderList.push({ tracking_no: trackingNumber });
      });
    return orderList;
  }

  function duplicateNumberScan(trackingNumber) {
    if (scannedNumbersList.includes(trackingNumber)) {
      setErrorNotificationData({
        errorMessage: "Duplicate Scan",
        showTrackingNumber: trackingNumber,
      });
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
        showSortDataBox: false,
      });
      return true;
    }
    return false;
  }

  async function onScan() {
    if (state.scanNumber === "") return;
    if (duplicateNumberScan(state.scanNumber)) return;
    if (await fetchOrderInfo(state.scanNumber)) {
      let trackingNumberList = [...scannedNumbersList];
      trackingNumberList.unshift(state.scanNumber);
      setScannedNumbersList(trackingNumberList);
      setState({
        ...state,
        scanNumber: "",
      });
    }
  }

  async function createSession() {
    try {
      // old
      const sessionId = await getSessionID();
      localStorage.setItem("sessionId", sessionId.data.session_id);
    } catch (e) {
      console.log("error creating session id");
    }
  }

  function getSessionID() {
    return axios.post(`${ROOT_URL}/hms/session/`, {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      scan_type: "sweep",
    });
  }
}
