import React, { useEffect, useState } from "react";
import { ParcelScannerContext } from "./ParcelScanner";

const undeliverableOptions = {
  1: '1 - Dispose',
  2: '2 - Return'
}

export default function PackageDetails() {
  return (
    <ParcelScannerContext.Consumer>
      {(value) => {
        const { orderData, latestScannedNumber, hasRtsActionRequest } = value;
        return (
          <div className="remedy-exception-container">
            <div className="d-flex flex-nowrap align-items-center">
              <span className="toggle-heading mr-auto">Package Details</span>
            </div>
            {orderData && (
              <div className="remedy-exception-content">
                <div className="d-flex flex-column">
                  <div className="row">
                    <div className="col-6">
                      <label className="remedy-exception-label--text-label">Tracking Number</label>
                      <p className="mb-0 remedy-exception-text">{latestScannedNumber}</p>
                    </div>
                    <div className="col-6">
                      <label className="remedy-exception-label--text-label">Request</label>
                      <p className="mb-0 remedy-exception-text">{hasRtsActionRequest ? "Return to Sender" : "-"}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Location</label>
                      <p className="mb-0 remedy-exception-text">{orderData.location}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Cargo ID</label>
                      <p className="mb-0 remedy-exception-text">{orderData.cargo_id || "-"}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">AE Sorting</label>
                      <p className="mb-0 remedy-exception-text">
                        {orderData.undeliverable_option ? undeliverableOptions[orderData.undeliverable_option] || "-" : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      }}
    </ParcelScannerContext.Consumer>
  );
}
