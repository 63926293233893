import axios from 'axios';
const parseQubeVuAutoCaptureResponse = (xmlDoc) => {
  console.log('parseQubeVuAutoCaptureResponse');
  // let xmlDoc = null;
  // if (window.DOMParser) {
  //   const parser = new DOMParser();
  //   xmlDoc = parser.parseFromString(qubevuXML, 'text/xml');
  // }
  if (xmlDoc) {
    console.log(xmlDoc);
    const scaleData = xmlDoc
      .getElementsByTagName('CapturedData')[0]
      .getElementsByTagName('ScaleData')[0];
    const weight = parseFloat(
      scaleData.getElementsByTagName('Weight')[0].innerHTML
    );
    const dimensionsData = xmlDoc
      .getElementsByTagName('CapturedData')[0]
      .getElementsByTagName('Dimensions')[0];
    const length = parseFloat(
      dimensionsData.getElementsByTagName('Length')[0].innerHTML
    );
    const width = parseFloat(
      dimensionsData.getElementsByTagName('Width')[0].innerHTML
    );
    const height = parseFloat(
      dimensionsData.getElementsByTagName('Height')[0].innerHTML
    );
    console.log({
      weight: weight / 1000,
      length,
      width,
      height,
    })
    return {
      weight: weight / 1000,
      length,
      width,
      height,
    };
  }
};
const getDimWeightFromQubeVu = async (qubeVuIP) => {
  console.log('getDimWeightFromQubeVu');
  const apiURL = 'WebServices/QubeVuService/Status';
  var URL = `https://${qubeVuIP}/${apiURL}`;

  // Make a GET request
  return fetch(URL)
    .then((response) => response.text())
    .then((str) => new window.DOMParser().parseFromString(str, 'text/xml'))
    .then((data) => parseQubeVuAutoCaptureResponse(data));
};
export default getDimWeightFromQubeVu;
