import React, { useState, useEffect } from "react";
import { Divider, message } from "antd";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { trolleyActions } from "../../../actions/rootActions";
import Input from "../../../common/input/input";
import BreadCrumb from "../commonComponents/BreadCrumb";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function Settings(props) {
  const { orderData, scanSettingsData, addCargoToTrolleySuccess, addCargoToTrolleyError, scannerInformation } = props;

  const scanType = props.location.state.scanType;
  const pageName = props.location.state.pageName;
  const route = props.location.state.route;

  const [scanSettings, setScanSettings] = useState(scanSettingsData);

  const [copyInProgress, setCopyInProgress] = useState(false);

  useEffect(() => {
    if (addCargoToTrolleySuccess) {
      if (orderData && orderData.tracking_nos) {
        message.success(
          `${orderData.tracking_nos.length} Parcel(s) added to Cargo ${scanSettings.linkToCargo} Successfully.`
        );
        props.dispatch(trolleyActions.clearAddCargoToTrolleyData());
        goBackToPreviousPage();
      }
    }
  }, [addCargoToTrolleySuccess]);

  // useEffect(() => {
  //   if (addCargoToTrolleyError) {
  //     props.dispatch(trolleyActions.clearAddCargoToTrolleyData());
  //     goBackToPreviousPage();
  //   }
  // }, [addCargoToTrolleyError]);

  useEffect(() => {
    return () => {
      props.dispatch(trolleyActions.clearAddCargoToTrolleyData());
    };
  }, []);

  const inputClass = addCargoToTrolleyError ? "is-invalid" : "";

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0 w-100">
          <BreadCrumb pageName={pageName} scanType={scanType} route={route} />
          <div className="bg-container-body">
            <div className="d-flex flex-column">
              <h2 className="text-heading-black margin-bottom-20">Scan Settings</h2>
              <Input
                className={inputClass}
                label="Link to Cargo"
                name="linkToCargo"
                placeholder="Click here or paste cargo tracking code to scan"
                value={scanSettings.linkToCargo}
                onChange={(e) => onScanSettingsChange("linkToCargo", e.target.value)}
              />
              {addCargoToTrolleyError && <div className="ant-form-item-explain">{addCargoToTrolleyError}</div>}
              <Divider className="gray-divider-20" />
              <div className="d-flex flex-column">
                <span className="tag-heading">
                  Tag Scanned Parcel <FontAwesomeIcon icon={faQuestionCircle} className="tag-help" />
                  {/* <i className="fa fa-question-circle tag-help" aria-hidden="true"></i> */}
                </span>
                <p className="scan-setting-details mb-0">
                  When scanned, tracking numbers are tagged, allowing you to{" "}
                  <span>download/print a DO or manifest</span> for this batch of shipments.
                </p>
                <div className="d-flex tag-container flex-nowrap d-flex col-md-4 pl-0 pr-0">
                  <input
                    className="form-control scan-input border-right-rad-0"
                    placeholder="Add a tag here"
                    value={scanSettings.groupId}
                    onChange={(e) => onScanSettingsChange("groupId", e.target.value)}
                  />
                  <CopyToClipboard text={scanSettings.groupId} onCopy={onCopyGroupId}>
                    <button className="tag-button border-left-rad-0">{copyInProgress ? "Copied!" : "Copy"}</button>
                  </CopyToClipboard>
                </div>
                <Divider className="gray-divider-20" />
              </div>

              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button className="secondary-button-small" onClick={goBackToPreviousPage}>
                  Back
                </button>
                <button className="primary-button mb-sm-0 mb-3" onClick={addCargoToTrolley}>
                  Save and Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousPage() {
    props.history.goBack();
  }

  function onScanSettingsChange(name, value) {
    setScanSettings({
      ...scanSettings,
      [name]: value,
    });
  }

  function onCopyGroupId() {
    setCopyInProgress(true);
    setTimeout(() => setCopyInProgress(false), 2000);
  }

  async function addCargoToTrolley() {
    const data = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      cargo_tracking_no: scanSettings.linkToCargo.trim(),
      scanned_tracking_nos: orderData.tracking_nos,
    };
    props.dispatch(trolleyActions.addCargoToTrolley(data, orderData));
  }
}

const mapStateToProps = ({ trolley, common }) => ({
  scanSettingsData: trolley.scanSettingsData,
  orderData: trolley.orderData,
  addCargoToTrolleySuccess: trolley.addCargoToTrolleySuccess,
  addCargoToTrolleyError: trolley.addCargoToTrolleyError,
  scannerInformation: common.scannerInformation,
});

export default connect(mapStateToProps, null)(Settings);
