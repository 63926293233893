import * as _ from "lodash";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getSessionID } from "../../../actions/authActions";
import { cargoActions } from "../../../actions/cargoActions";
import { commonActions } from "../../../actions/commonActions";
import { shipmentActions } from "../../../actions/shipmentActions";
import {
  CARGO_SCAN_TYPE_PAGE,
  CARGO_TRACKING_NUMBER,
  PAGE_SCAN_TYPE,
  REQUIRE_ERROR_ACKNOWLEDGEMENT,
  SCAN_CARGO_TRACKING_NUMBER,
} from "../../../constants/mixpanelConstants";
import { useGetCargoDetails } from "../../../customHooks/cargoScannerHooks";
import { createRequestPayload } from "../../../utils/createCargoRequestPayload";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { playSound, TYPE_ERROR } from "../../../utils/playSound";
import { scanAudioAlert } from "../../../utils/voiceTypeUtils";
import { mainContent } from "./cargoComponentRenderers";

export const CargoScannerContext = React.createContext(null);

const HmsCargoScanner = function HmsCargoScanner(props) {
  const scanNumberInputRef = useRef(null);
  const [autoPrintLabel, setAutoPrintLabel] = useState(false);
  const [downloadLabel, setDownloadLabel] = useState(null);

  const [voiceUtilsData, setVoiceUtilsData] = useState({});

  const [state, setState] = useState({
    scanType: props.location.state.scanType,
    groupId: "",
    tag: "",
    autoPrintLabel: false,
    showPrintLabel: false,
    scanNumber: props.location.state.tracking_no ? props.location.state.tracking_no : "",
    sortType: undefined,
  });

  const [processCargo, setProcessCargo] = useState(true);

  const [cargoDetails, downloadCargoLabel, printCargoLabel] = useGetCargoDetails({
    scanType: state.scanType,
    group_id: getGroupId(),
    shipment_no: props.scanSettings.linkToShipment,
    scanNumber: state.scanNumber,
    process_cargo: processCargo,
    autoPrintLabel,
    downloadLabel,
  });

  useEffect(() => {
    if (props.cargoDetailsError) {
      playSound(TYPE_ERROR);
      focusScanNumberInput();
    }
  }, [props.cargoDetailsErr]);

  useEffect(() => {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      const data = {
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
      };
      props.setScannerInformation(data);
      // mixpanel tracking page visit
      const mixPanelData = getMixpanelData();
      mixPanelTrack(CARGO_SCAN_TYPE_PAGE, {
        ...mixPanelData,
        [REQUIRE_ERROR_ACKNOWLEDGEMENT]: localStorage.getItem("requireErrorAcknowledgment") === "true",
        [PAGE_SCAN_TYPE]: props.location.state.scanType,
      });
    }
  }, []);

  useEffect(() => {
    props.clearScannedNumbersList();
    return () => {
      props.clearProcessAndDetailsScanOrder();
      props.clearGetCargoDetailsError();
    };
  }, []);

  useEffect(() => {
    setVoiceType();
    props.createSessionID({
      hub_id: localStorage.getItem("hubId"),
      scan_type: props.location.state.scanType,
      hubscanner_key: localStorage.getItem("userId"),
    });
  }, []);

  useEffect(() => {
    if (props.shipmentNumbersList.shipments && props.shipmentNumbersList.shipments.length === 0) {
      getShipmentNumbersListData();
    }
    if (props.location.state.tracking_no) {
      onScan();
    }

    let autoPrint = localStorage.getItem("autoPrintLabel");
    const labelDownload = localStorage.getItem("downloadLabel");
    autoPrint = autoPrint === "true" ? true : false;
    setDownloadLabel(labelDownload);
    setAutoPrintLabel(autoPrint);
  }, []);

  useEffect(() => {
    if (props.processedScannedCargo) {
      const data = {
        ...cargoDetails,
        consigneeCountry: cargoDetails.dest_country,
        consigneePostalPreFix: cargoDetails.dest_poscode_prefix,
        cargoPostalCode: "",
        nextRecipient: cargoDetails.next_recipient,
        noOfParcelsScanned: props.scannedNumbersList.length,
      };
      playAudioAlert(data);
      setState({ ...state, scanNumber: "" });
      const mixPanelData = getMixpanelData();
      mixPanelTrack(SCAN_CARGO_TRACKING_NUMBER, {
        ...mixPanelData,
        [REQUIRE_ERROR_ACKNOWLEDGEMENT]: localStorage.getItem("requireErrorAcknowledgment") === "true",
        [PAGE_SCAN_TYPE]: props.location.state.scanType,
        [CARGO_TRACKING_NUMBER]: state.scanNumber,
      });
    }
  }, [props.processedScannedCargo]);

  useEffect(() => {
    if (props.processedScannedCargoError) {
      playSound(TYPE_ERROR);
      focusScanNumberInput();
    }
  }, [props.processedScannedCargoError]);

  // useEffect(() => {
  //   if (props.getTrackingNumbersInsideCargoError {
  //     // TODO: change error message and try to set it to state while integrating api
  //     message.error(props.getTrackingNumbersInsideCargoError.message);
  //   }
  // }, [props.getTrackingNumbersInsideCargoError]);

  function focusScanNumberInput() {
    scanNumberInputRef && scanNumberInputRef.current && scanNumberInputRef.current.focus();
  }

  const locationScanType = props.location.state.scanType;

  function onChangeScanNumberCameraChange(scanResult) {
    const scannedNumber = scanResult.text;
    setState({
      ...state,
      scanNumber: scannedNumber,
    });
    onScan(scannedNumber);
  }

  function onChangeScanNumberChange(e) {
    const { value } = e.target;
    const { keyCode, which } = e;
    if (keyCode === 13 || which === 13) {
      onScan();
      return;
    }
    setState({ ...state, scanNumber: value });
  }

  function getGroupId() {
    if (!isEmpty(props.scanSettings.groupId) || props.scanSettings.recipientId === undefined) {
      return props.scanSettings.groupId;
    } else {
      return props.scanSettings.recipientId;
    }
  }

  function playAudioAlert(fetchOrderData) {
    let alert = null;
    if (voiceUtilsData.defaultAudioType === "Postal Prefix" && fetchOrderData.consigneePostalPreFix !== "") {
      alert = fetchOrderData.consigneePostalPreFix.toLowerCase();
    } else if (voiceUtilsData.defaultAudioType === "No. of Parcels Scanned") {
      alert = fetchOrderData.noOfParcelsScanned;
    } else if (fetchOrderData.consigneeCountry) {
      alert = fetchOrderData.consigneeCountry.toLowerCase();
    }
    alert && scanAudioAlert(alert, voiceUtilsData.voice);
  }

  async function onScan(scanNumber) {
    const scannedNumber = scanNumber ? scanNumber : state.scanNumber;
    if (!scannedNumber) return;
    const params = createRequestPayload({ tracking_no: scannedNumber.trim(), scan_type: state.scanType }, []);
    if (!isEmpty(props.warningNotificationData) && state.scanType === "cargo break") {
      verifySecurityTag(scannedNumber);
    } else {
      setProcessCargo(true);
      props.getCargoDetails({ params }, props.scannedNumbersList);
    }
  }

  function setVoiceType() {
    const voices = window.speechSynthesis.getVoices();
    const defaultAudioType = localStorage.getItem("defaultAudioType");
    defaultAudioType &&
      setVoiceUtilsData({
        voice: voices[0],
        defaultAudioType: defaultAudioType,
      });
  }

  function verifySecurityTag(scannedNumber) {
    focusScanNumberInput();
    if (scannedNumber === cargoDetails.security_tag_info.security_tag_no) {
      const warningNotificationData = {};
      const errorNotificationData = {};
      const successNotificationData = {
        cargoMessage: "CARGO BREAK SUCCESSFUL",
        cargoDescription: "INBOUND PARCELS",
        security_tag_info: cargoDetails.security_tag_info,
      };

      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false,
      };

      const payload = {
        warningNotificationData: warningNotificationData,
        successNotificationData: successNotificationData,
        errorNotificationData: errorNotificationData,
        notificationBoxType: notificationBoxType,
      };
      props.addScannerData(payload);
      setState({ ...state, scanNumber: "" });
      return;
    } else {
      setStateToErrorState("SCAN SECURITY TAG AGAIN", "SECURITY TAG DOES NOT MATCH CARGO");
      setState({ ...state, scanNumber: "" });
    }
  }

  function setStateToErrorState(errorMessage, errorMessageHeading) {
    playSound(TYPE_ERROR);
    const warningNotificationData = {};
    const successNotificationData = props.successNotificationData;

    const errorNotificationData = {
      errorMessage: errorMessage,
      showTrackingNumber: state.scanNumber,
    };
    if (errorMessageHeading) {
      errorNotificationData.errorMessageHeading = errorMessageHeading;
    }
    if (!isEmpty(cargoDetails.security_tag_info)) {
      errorNotificationData.showTrackingNumber = props.latestScannedNumber;
    }

    const notificationBoxType = {
      showNoDataBox: false,
      showValidDataBox: false,
      showErrorDataBox: true,
    };

    const payload = {
      warningNotificationData: warningNotificationData,
      successNotificationData: successNotificationData,
      errorNotificationData: errorNotificationData,
      notificationBoxType: notificationBoxType,
    };
    props.addScannerData(payload);
  }

  function resetScanState() {
    console.log("resetScanState");
    setState({
      scanType: props.location.state.scanType,
      groupId: "",
      tag: "",
      autoPrintLabel: false,
      showPrintLabel: false,
      scanNumber: "",
      sortType: undefined,
      shipDetails: null,
    });
    props.resetScanState();
  }

  function reFetchCargoDetails() {
    setProcessCargo(false);
    const params = createRequestPayload({ tracking_no: state.scanNumber.trim(), scan_type: state.scanType }, []);
    props.getCargoDetails({ params });
  }

  const shipment = getShipment();

  return (
    <CargoScannerContext.Provider
      value={{
        errorNotificationData: props.errorNotificationData,
        successNotificationData: props.successNotificationData,
        latestScannedNumber: props.latestScannedNumber,
        scanNumberInputRef,
        scanNumber: state.scanNumber,
        onChangeScanNumberChange,
        onChangeScanNumberCameraChange,
        scanType: state.scanType,
        notificationBoxType: props.notificationBoxType,
        locationScanType,
        scanSettings: props.scanSettings,
        scannedNumbersList: props.scannedNumbersList,
        cargoDetails,
        warningNotificationData: props.warningNotificationData,
        resetScanState,
        shipment,
        printCargoLabel,
        downloadCargoLabel,
        redirectToShipmentDetailsPage,
        cargoPackageExceptions: props.cargoPackageExceptions,
        redirectToCargoHomePage,
      }}
    >
      <div className="scanner-bg-container">
        {mainContent(
          props.latestScannedNumber,
          state.scanType,
          props.notificationBoxType,
          locationScanType,
          props.scanSettings,
          props.scannedNumbersList,
          cargoDetails,
          shipment,
          redirectToShipmentDetailsPage,
          redirectToCargoHomePage
        )}
      </div>
    </CargoScannerContext.Provider>
  );

  function redirectToCargoHomePage() {
    if (props.cargoPackageExceptions && props.cargoPackageExceptions.length > 0) {
      if (window.confirm("CSV exceptions list will be deleted once you move from this page")) {
        props.history.push("/scan/hubmanage/cargo-scan");
      }
    } else {
      props.history.push("/scan/hubmanage/cargo-scan");
    }
  }

  // get shipments list
  function getShipmentNumbersListData() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: 1,
    };
    // props.dispatch(shipmentActions.getShipmentNumbersList(data));
    props.getShipmentNumbersList(data);
  }

  function getShipment() {
    const dummyObj = { tracking_no: "" };
    const selectedShipment = cargoDetails
      ? _.find(props.shipmentNumbersList.shipments, (x) => x.tracking_no === cargoDetails.shipment_no)
      : null;
    return selectedShipment ? selectedShipment : dummyObj;
  }

  function redirectToShipmentDetailsPage() {
    console.log("redirect", props.history);
    props.history.push({
      pathname: `/scan/hubmanage/shipments/${shipment.tracking_no}`,
      state: {
        shipment: shipment,
      },
    });
  }
};

const mapStateToProps = ({ cargo, shipment, common, auth }) => ({
  processedScannedCargo: cargo.processedScannedCargo,
  processedScannedCargoError: cargo.processedScannedCargoError,
  photosData: cargo.photosData,
  photosDataError: cargo.photosDataError,
  getTrackingNumbersInsideCargoError: cargo.getTrackingNumbersInsideCargoError,
  countriesList: cargo.countriesList,
  shipmentNumbersList: shipment.shipmentNumbersList,
  scanSettings: cargo.scanSettingsData,
  scannedNumbersList: common.scannedNumbersList,
  latestScannedNumber: cargo.latestScannedNumber,
  notificationBoxType: cargo.notificationBoxType,
  successNotificationData: cargo.successNotificationData,
  errorNotificationData: cargo.errorNotificationData,
  warningNotificationData: cargo.warningNotificationData,
  cargoDetailsError: cargo.cargoDetailsError,
  sessionId: auth.sessionId,
  printer: common.printer,
  cargoPackageExceptions: cargo.exceptions,
});

const mapDispatchToProps = (dispatch) => ({
  getCargoDetails: (payload, scannedNumbersList) => dispatch(cargoActions.getCargoDetails(payload, scannedNumbersList)),
  createSessionID: (payload) => dispatch(getSessionID(payload)),
  clearCargoInfo: () => dispatch(cargoActions.clearCargoInfo()),
  getShipmentNumbersList: (payload) => dispatch(shipmentActions.getShipmentNumbersList(payload)),
  addScannerData: (payload) => dispatch(cargoActions.addScannerData(payload)),
  setScannerInformation: (payload) => dispatch(commonActions.setScannerInformation(payload)),
  clearProcessAndDetailsScanOrder: () => dispatch(cargoActions.clearProcessAndDetailsScanOrder()),
  clearGetCargoDetailsError: () => dispatch(cargoActions.clearGetCargoDetailsError()),
  clearCargoExceptions: () => dispatch(cargoActions.clearCargoExceptions()),
  resetScanState: () => dispatch(cargoActions.resetScanState()),
  clearScannedNumbersList: () => dispatch(commonActions.clearScannedNumbersList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HmsCargoScanner);
