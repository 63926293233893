import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getLabelLink,
  scanManifestCheck,
  scanReset,
  deleteManifest,
  deleteAllManifest
} from '../../actions/scanActions';
import _ from 'lodash';

import compose from 'recompose/compose';
import { withNamespaces, Trans } from 'react-i18next';
import i18n from '../i18n';

import Scanner from '../../deprecated/scanner/Scanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';

class RedirectPrintLabel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: localStorage.getItem('staffId'),
      emails: localStorage.getItem('emails'),
      scanning: false,
      results: [],
      cta: '',
      loading: false,
      manualTrackingNo: '',
      comments: '',
      manifestChecks: {}
    };
  }

  componentDidMount() {
    this.props.scanReset();

    const url = window.location.href;
    let pageType = url.substring(url.lastIndexOf("/") + 1);
    const ctas = {
      printLabel: 'Print Label'
    }

    if (!this.state.cta) {
      if (pageType === 'print-label' && pageType.includes('-')) {
        pageType = pageType.replace('-', '');
        pageType = pageType.substring(0, 5) + pageType.charAt(5).toUpperCase() + pageType.slice(6);
      }
      this.setState({
        cta: ctas[pageType]
      });
    }
  }

  componentDidUpdate() {
    if (typeof (this.props.error) === "boolean" && this.state.loading) {
      this.setState({
        loading: false
      });
    }

    if (this.props.manifestChecks && !_.isEqual(this.props.manifestChecks, this.state.manifestChecks)) {
      this.setState({
        manifestChecks: this.props.manifestChecks
      });
    }
  }

  onSubmit() {
    this.setState({
      scanning: false,
      loading: true
    });

    const {
      cta,
      staffId,
      emails,
      results,
    } = this.state;

    console.log(cta);
    switch (cta) {
      case "Print Label":
        this.props.getLabelLink(staffId, results.join(','), emails);
        break;
      default:
        break;
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === 'staffId') {
      localStorage.setItem('staffId', value);
    } else if (name === 'emails') {
      localStorage.setItem('emails', value);
    }

    this.setState({ [name]: value });
  }

  deleteResult = (result) => {
    let { results } = this.state;

    const removedResults = _.remove(results, (n) => {
      return n !== result;
    });

    this.setState({
      results: removedResults
    });
    this.props.deleteManifest(result)
  }

  renderResults() {
    const { results, manifestChecks } = this.state;

    return _.reverse(_.map(results, (result) => {
      let manifestFound = 'checking';
      if (manifestChecks) {
        if (manifestChecks[result]) {
          manifestFound = 'found';
        } else if (manifestChecks[result] === false) {
          manifestFound = 'not found';
        }
      }
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center" key={result}>
          {
            manifestFound === 'checking' ?
              <FontAwesomeIcon icon={faSpinner} />
              // <i className="fas fa-spinner" />
              :
              manifestFound === 'found' ?
                <FontAwesomeIcon icon={faCheck} />
                // <i className="fas fa-check" />
                :
                <FontAwesomeIcon icon={faTimes} />
            // <i className="fas fa-times" />
          }
          <div>
            {result}
          </div>
          <button
            className="btn btn-danger text-white"
            onClick={() => this.deleteResult(result)}>
            X
          </button>
        </li>
      )
    }));
  }

  renderManualAdd() {
    return (
      <div className="list-group-item d-block justify-content-between py-3 px-3.5 bg-white align-items-center">
        <label><Trans>Manually Add</Trans></label>
        <div className='input-group'>
          <input
            name="manualTrackingNo"
            type="text"
            className="form-control"
            placeholder={i18n.language === 'en' ? 'Tracking Number(s)' : i18n.language === 'id' ? 'Nomor Pelacakan' : '先点击这里'}
            value={this.state.manualTrackingNo}
            onChange={(e) => this.handleOnChange(e)}
            onKeyUp={(e) => {
              if (e.keyCode === 13 || e.which === 13) {
                this.addManualTrackingNo(this.state.manualTrackingNo);
              }
            }}
          />
          <div class='input-group-append'>
            <button
              className="ml-1 btn btn-janio text-white"
              onClick={() => {
                this.addManualTrackingNo(this.state.manualTrackingNo);
              }}>
              <Trans>Add</Trans>
            </button>
          </div>
        </div>
      </div>
    );
  }

  scan() {
    this.setState({
      scanning: !this.state.scanning
    });
  }

  addResult = (trackingNo) => {
    this.setState({
      results: this.state.results.concat([trackingNo]),
      manualTrackingNo: ''
    });
    this.props.scanManifestCheck(trackingNo);
  }

  addManualTrackingNo = (trackingNo) => {
    if (!_.isEmpty(trackingNo) && !_.includes(this.state.results, trackingNo)) {
      this.setState({
        results: this.state.results.concat([trackingNo]),
        manualTrackingNo: ''
      });
      this.addResult(trackingNo);
    }
  }

  onDetected(result) {
    const barcode = result.codeResult.code;
    if (!_.isEmpty(barcode) && !barcode.match(/[!@#$%^&*()'_=,.?/]+/g) && !_.includes(this.state.results, barcode)) {
      navigator.vibrate(500);
      this.setState({
        results: this.state.results.concat([barcode])
      });
      this.addResult(barcode);
    }
  }

  deleteAllResult = () => {
    this.setState({
      results: []
    })
    this.props.deleteAllManifest()
  }

  render() {
    return (
      <div className="main-container d-flex flex-column align-items-center">
        <div>
          <h1 className='font-weight-bolder pl-0'><Trans>{this.state.cta}</Trans></h1>
          <nav aria-label="breadcrumb" >
            <ol className="breadcrumb">
              <li class="breadcrumb-item"><a href="/scan"><Trans>Home</Trans></a></li>
              <li class="breadcrumb-item active" aria-current="page"><Trans>{this.state.cta}</Trans></li>
            </ol>
          </nav>
          <div className="content-container card mt-1 mb-1 p-2">
            <form>
              <div class="form-group">
                <label for="staffIdInput"><Trans>Staff ID</Trans></label>
                <input
                  name="staffId"
                  type="text"
                  className="form-control text-center font-weight-bold"
                  placeholder={i18n.language === 'en' ? 'Click here first' : i18n.language === 'id' ? 'Pertama klik disini' : '先点击这里'}
                  onChange={(e) => this.handleOnChange(e)}
                  value={this.state.staffId !== null ? this.state.staffId : ''}
                />
              </div>
            </form>
            <form>
              <div class="form-group">
                <label for="emailInput">Emails</label>
                <input
                  name="emails"
                  type="text"
                  className="form-control text-center font-weight-bold"
                  placeholder="e.g. test@email.com,test2@email.com"
                  onChange={(e) => this.handleOnChange(e)}
                  value={this.state.emails !== null ? this.state.emails : ''}
                />
              </div>
            </form>
          </div>

          {
            this.state.staffId ?
              <div>
                <div className="content-container card mt-1 mb-1 p-2 py-1">
                  {this.state.scanning ? <Scanner onDetected={this.onDetected.bind(this)} /> : null}
                  <button
                    className={`btn btn-janio text-white ${this.state.scanning ? "" : "btn-janio-redirect"}`}
                    onClick={this.scan.bind(this)}>
                    {this.state.scanning ? <Trans>STOP SCANNING</Trans> : <Trans>CLICK HERE TO START SCANNING</Trans>}
                  </button>
                </div>

                <div className="content-container card mt-1 mb-1">
                  <div className="card-header text-center font-weight-bold d-flex align-items-center justify-content-center">
                    <span className='mr-auto ml-auto'>{`Scanned Tracking Nos (${this.state.results.length})`}</span>
                    <button className='btn btn-xs btn-danger float-right'
                      onClick={() => this.deleteAllResult()}><Trans>Delete All</Trans></button>
                  </div>
                  {
                    this.state.results && this.state.results.length > 0 ?
                      <ul className="list-group list-group-flush">
                        {this.renderManualAdd()}
                        {this.renderResults()}
                      </ul>
                      :
                      <ul className="list-group list-group-flush">
                        {this.renderManualAdd()}
                        <li className="list-group-item"><Trans>Nothing scanned.</Trans></li>
                      </ul>
                  }
                </div>

                <div className="content-container card mt-1 mb-1 p-2">
                  <form>
                    <div class="form-group">
                      <label for="comments"><Trans>Comments</Trans></label>
                      <input
                        name="comments"
                        type="text"
                        className="form-control"
                        onChange={(e) => this.handleOnChange(e)}
                        value={this.state.comments}
                      />
                    </div>
                  </form>
                </div>

                {
                  this.props.status === 200 ?
                    <div className="alert alert-success mb-1" role="alert">
                      Sent, please check your email.
                    </div>
                    :
                    null
                }

                {
                  this.props.error ?
                    <div className="alert alert-danger mb-1" role="alert">
                      Error, please check with admin.
                    </div>
                    :
                    null
                }

                <button
                  type="button"
                  className={`text-white btn btn-lg mb-3 w-100 py-3 ${this.state.results.length === 0 || this.state.loading ? "btn-secondary" : "btn-success"}`}
                  disabled={this.state.results.length === 0}
                  onClick={this.onSubmit.bind(this)}>
                  {`Send Links to Email (${this.state.results.length})`}
                </button>
              </div>
              :
              null
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps({ scan }) {
  return {
    error: scan.error,
    response: scan.response,
    status: scan.status,
    manifestChecks: scan.manifestChecks,
  };
}

export default compose(
  connect(mapStateToProps, {
    getLabelLink,
    scanManifestCheck,
    scanReset,
    deleteManifest,
    deleteAllManifest
  }),
  withNamespaces()
)(RedirectPrintLabel);
