import { Table } from "antd";
import React, { useState } from "react";
import { Resizable } from "react-resizable";
import "./table.scss";

const ResizableColumn = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

export const CustomTable = (props) => {
  const [headers, setHeaders] = useState(props.headers);

  const components = {
    header: {
      cell: ResizableColumn,
    },
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextHeaders = [...headers];
      nextHeaders[index] = { ...nextHeaders[index], width: size.width };
      setHeaders(nextHeaders);
    };

  const columnsData = headers.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index),
    }),
  }));

  console.log("custom table", headers, columnsData, props.tableData);

  return (
    <Table
      className="table-striped"
      components={components}
      columns={columnsData}
      rowKey={props.rowKey}
      dataSource={props.tableData}
      scroll={{ y: 340 }}
      pagination={{
        pageSize: props.defaultPageSize,
        onChange: props.onPageChange,
        total: props.total,
        current: props.pageNumber,
        responsive: true,
      }}
    />
  );
};
