import { useEffect } from "react";
import { useSelector } from "react-redux";

export const useBeforeUnloadHook = (params) => {
  const { disposalScannedNumbersList } = useSelector((state) => ({
    disposalScannedNumbersList: state.parcel.disposalScannedNumbersList,
  }));
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [disposalScannedNumbersList]);

  function handleBeforeUnload(event) {
    if (params.scanType === "disposal" && disposalScannedNumbersList.length > 0) {
      event.preventDefault();
      event.returnValue = `Changes you made may not be saved.`;
    }
  }
};
