/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import {
  scanPickup,
  scanDeliver,
  scanReceive,
  scanTransfer,
  scanReset,
  scanManifestCheck,
  deleteManifest,
  deleteAllManifest
} from '../actions/scanActions';
import _ from 'lodash';

import compose from 'recompose/compose';
import { withNamespaces, Trans } from 'react-i18next';
import i18n from './i18n';

import { resources } from './i18n/index'
import store from 'store'

import Scanner from '../deprecated/scanner/Scanner';
import Success from './Success';
import { faCheck, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ScannerPage extends Component {
  constructor(props) {
    super(props);
    const manifestStore = store.get('manifestChecks', {})
    const resultsStore = Object.entries(manifestStore).map(o => o[0])

    this.state = {
      staffId: localStorage.getItem('staffId'),
      scanning: false,
      results: resultsStore,
      cta: '',
      menutcta: '',
      menuType: '',
      loading: false,
      manualTrackingNo: '',
      comments: '',
      manifestChecks: manifestStore
    };
  }

  componentDidMount() {
    this.props.scanReset();

    const url = window.location.href;
    let pageType = url.substring(url.lastIndexOf("/") + 1);
    let menuType = url.substring(url.lastIndexOf("/") - 9, url.lastIndexOf("/"));
    const ctas = {
      pickup: 'Pick Up',
      deliver: 'Deliver',
      receive: 'Receive',
      transfer: 'Transfer'
    }
    const menuctas = {
      firstmile: 'Firstmile',
      warehouse: 'Warehouse'
    }

    if (!this.state.cta && !this.state.menucta) {
      this.setState({
        cta: ctas[pageType],
        menucta: menuctas[menuType],
        menuType: menuType
      });
    }
  }

  componentDidUpdate() {
    if (typeof (this.props.error) === "boolean" && this.state.loading) {
      this.setState({
        loading: false
      });
    }

    if (this.props.manifestChecks && !_.isEqual(this.props.manifestChecks, this.state.manifestChecks)) {
      //Check every element in this.state.results for any duplicate (lowercase & uppercase version of the same tracking no) and determine which version is valid
      this.state.results.forEach((result) => { this.whichVersionIsValid(result) })
      this.setState({
        manifestChecks: this.props.manifestChecks
      });
    }
  }

  onSubmit() {
    this.setState({
      scanning: false,
      loading: true
    });

    const {
      cta,
      staffId,
      results,
      comments
    } = this.state;

    switch (cta) {
      case "Pick Up":
        if (navigator !== null) {
          navigator.geolocation.getCurrentPosition((location) => {
            const latitude = parseFloat(location.coords.latitude.toFixed(5), 10);
            const longitude = parseFloat(location.coords.longitude.toFixed(5), 10);

            this.props.scanPickup(staffId, results, latitude, longitude, comments);
          });
        }
        break;
      case "Deliver":
        if (navigator !== null) {
          navigator.geolocation.getCurrentPosition((location) => {
            const latitude = parseFloat(location.coords.latitude.toFixed(5), 10);
            const longitude = parseFloat(location.coords.longitude.toFixed(5), 10);

            this.props.scanDeliver(staffId, results, latitude, longitude, comments);
          });
        }
        break;
      case "Receive":
        this.props.scanReceive(staffId, results);
        break;
      case "Transfer":
        this.props.scanTransfer(staffId, results);
        break;
      default:
        break;
    }
  }

  whichVersionIsValid = (result) => {
    // Check if the tracking number is lower case because we do not need to care about the upper case version
    if (result !== result.toUpperCase()) {
      // Execute if the lowercase tracking number is invalid
      if (!this.props.manifestChecks[result]) {
        //Firstly, check if the uppercase version of tracking number exists. If it doesn't exist, do nothing
        if (this.state.results.includes(result.toUpperCase())) {
          if (this.props.manifestChecks[result.toUpperCase()] === true) {
            //Delete lowercase tracking number if its uppercase version is valid
            console.log("false & true")
            this.deleteResult(result)
          }
          if (this.props.manifestChecks[result.toUpperCase()] === false) {
            //Delete uppercase tracking number if its uppercase version is invalid
            console.log("false & false")
            this.deleteResult(result.toUpperCase())
          }
        }
      }
      // Execute if the lowercase tracking number is valid
      else {
        // Firstly, check if uppercase version of tracking number exists. If it doesn't exist, do nothing
        if (this.state.results.includes(result.toUpperCase())) {
          if (this.props.manifestChecks[result.toUpperCase()] === true) {
            //Delete uppercase tracking number if its uppercase version is true
            console.log("true & true")
            this.deleteResult(result.toUpperCase())
          }
          if (this.props.manifestChecks[result.toUpperCase()] === false) {
            //Delete uppercase tracking number if its uppercase version is true
            this.deleteResult(result.toUpperCase())
          }
        }
      }
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === 'staffId') {
      localStorage.setItem('staffId', value);
    }

    this.setState({ [name]: value });
  }

  deleteResult = (result) => {
    console.log(result)
    let { results } = this.state;

    const removedResults = _.remove(results, (n) => {
      return n !== result;
    });

    this.setState({
      results: removedResults
    });
    this.props.deleteManifest(result)
  }

  deleteAllResult = () => {
    this.setState({
      results: []
    })
    this.props.deleteAllManifest()
  }

  renderResults() {
    const { results, manifestChecks } = this.state;

    return _.reverse(_.map(results, (result) => {
      let manifestFound = 'checking';
      if (manifestChecks) {
        if (manifestChecks[result]) {
          manifestFound = 'found';
        } else if (manifestChecks[result] === false) {
          manifestFound = 'not found';
        }
      }
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center" key={result}>
          {
            manifestFound === 'checking' ?
              <FontAwesomeIcon icon={faSpinner} />
              // <i className="fas fa-spinner" />
              :
              manifestFound === 'found' ?
                <FontAwesomeIcon icon={faCheck} />
                // <i className="fas fa-check" />
                :
                <FontAwesomeIcon icon={faTimes} />
            // <i className="fas fa-times" />
          }
          <div>
            {result}
          </div>
          <button
            className="btn btn-danger text-white"
            onClick={() => this.deleteResult(result)}>
            X
          </button>
        </li>
      )
    }));
  }

  submitValue() {
    const trackingNos = this.state.manualTrackingNo
    // const cleanedValue = trackingNos.match(/[A-Za-z0-9-]+/g)
    const cleanedValue = [...new Set(trackingNos.split(/[ ,]+/))]
    const cleanedValueUnique = [].concat(...cleanedValue.filter(v => !!v))
    const newValues = cleanedValueUnique.filter(v => !this.state.results.includes(v))
    newValues.forEach((trackingNo) => {
      if (trackingNo !== trackingNo.toUpperCase() && !(this.state.results.includes(trackingNo.toUpperCase()))) {
        newValues.push(trackingNo.toUpperCase());
      }
    })
    this.addResult(newValues)
  }

  renderManualAdd() {
    return (
      <div className="list-group-item d-block justify-content-between py-3 px-3.5 bg-white align-items-center">
        <label><Trans>Manually Add</Trans></label>
        <div className='input-group'>
          <input
            name="manualTrackingNo"
            type="text"
            className="form-control"
            placeholder={resources[i18n.language]['translations']['Tracking No(s)']}
            value={this.state.manualTrackingNo}
            onChange={(e) => this.handleOnChange(e)}
            onKeyUp={(e) => {
              if (e.keyCode === 13 || e.which === 13) {
                this.submitValue()
              }
            }}
          />
          <div className='input-group-append'>
            <button
              className="ml-1 btn btn-janio text-white"
              onClick={() => {
                this.submitValue()
              }}>
              <Trans>Add</Trans>
            </button>
          </div>
        </div>
      </div>
    );
  }

  scan() {
    this.setState({
      scanning: !this.state.scanning
    });
  }

  addResult = (trackingNos) => {
    const updatedResults = [...this.state.results, ...trackingNos]
    this.setState({
      results: updatedResults,
      manualTrackingNo: ''
    });
    trackingNos.forEach(v => {
      this.props.scanManifestCheck(v);
    })
  }

  addManualTrackingNo = (trackingNo) => {
    if (!_.isEmpty(trackingNo) && !_.includes(this.state.results, trackingNo)) {
      this.addResult(trackingNo);
    }
  }

  onDetected(result) {
    const barcode = result.codeResult.code;
    const barcodeCapital = barcode.toUpperCase();
    if (!_.isEmpty(barcode) && !barcode.match(/[!@#$%^&*()'_=,.?/]+/g) && !_.includes(this.state.results, barcode)) {
      navigator.vibrate(500);
      if (barcode === barcodeCapital) {
        this.addResult([barcode,]);
      } else {
        this.addResult([barcode, barcodeCapital]);
      }
    }
  }

  render() {
    return (
      <div className="main-container d-flex flex-column align-items-center">
        {
          this.props.error === true || this.props.error === null ?
            <div>
              <h1 className='font-weight-bolder pl-0'><Trans>{this.state.cta}</Trans></h1>
              <nav aria-label="breadcrumb" >
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><LinkContainer to="/scan"><a><Trans>Home</Trans></a></LinkContainer></li>
                  <li className="breadcrumb-item"><LinkContainer to={`/scan/${this.state.menuType}/main`}><a><Trans>{this.state.menucta}</Trans></a></LinkContainer></li>
                  <li className="breadcrumb-item active" aria-current="page"><Trans>{this.state.cta}</Trans></li>
                </ol>
              </nav>
              <div className="content-container card mt-1 mb-1 p-2">
                <form>
                  <div className="form-group">
                    <label><Trans>Staff ID</Trans></label>
                    <input
                      name="staffId"
                      type="text"
                      className="form-control text-center font-weight-bold"
                      placeholder={resources[i18n.language]['translations']['Please input your ID']}
                      onChange={(e) => this.handleOnChange(e)}
                      value={this.state.staffId !== null ? this.state.staffId : ''}
                    />
                  </div>
                </form>
              </div>
              {
                this.state.staffId ?
                  <div>
                    <div className="content-container card mt-1 mb-1 p-2 py-1">
                      {this.state.scanning ? <Scanner onDetected={this.onDetected.bind(this)} /> : null}
                      <button
                        className={`btn btn-janio text-white ${this.state.scanning ? "" : "btn-janio-redirect"}`}
                        onClick={this.scan.bind(this)}>
                        {this.state.scanning ? <Trans>STOP SCANNING</Trans> : <Trans>CLICK HERE TO START SCANNING</Trans>}
                      </button>
                    </div>

                    <div className="content-container card mt-1 mb-1">
                      <div className="card-header text-center font-weight-bold d-flex align-items-center justify-content-center">
                        <span className='mr-auto ml-auto'><Trans>Scanned Tracking Nos</Trans>{` (${this.state.results.length})`}</span>
                        <button className='btn btn-xs btn-danger float-right'
                          onClick={() => this.deleteAllResult()}><Trans>Delete All</Trans></button>
                      </div>
                      {
                        this.state.results && this.state.results.length > 0 ?
                          <ul className="list-group list-group-flush">
                            {this.renderManualAdd()}
                            {this.renderResults()}
                          </ul>
                          :
                          <ul className="list-group list-group-flush">
                            {this.renderManualAdd()}
                            <li className="list-group-item"><Trans>Nothing scanned.</Trans></li>
                          </ul>
                      }
                    </div>

                    <div className="content-container card mt-1 mb-1 p-2">
                      <form>
                        <div className="form-group">
                          <label><Trans>Comments</Trans></label>
                          <input
                            name="comments"
                            type="text"
                            className="form-control"
                            onChange={(e) => this.handleOnChange(e)}
                            value={this.state.comments}
                          />
                        </div>
                      </form>
                    </div>

                    {
                      this.props.error ?
                        <div className="alert alert-danger mb-1" role="alert">
                          <Trans>There is an error, please try again.</Trans>
                        </div>
                        :
                        null
                    }

                    <button
                      type="button"
                      className={`text-white btn btn-lg mb-3 w-100 py-3 ${this.state.results.length === 0 || this.state.loading ? "btn-secondary" : "btn-success"}`}
                      disabled={this.state.results.length === 0}
                      onClick={this.onSubmit.bind(this)}
                    >
                      {<Trans>{this.state.cta}</Trans>}{` (${this.state.results.length})`}
                    </button>
                  </div>
                  :
                  null
              }
            </div>
            :
            <Success response={this.props.response} cta={<Trans>{this.state.cta}</Trans>} />
        }
      </div>
    );
  }
}

function mapStateToProps({ scan }) {
  return {
    error: scan.error,
    response: scan.response,
    manifestChecks: scan.manifestChecks,
  };
}

export default compose(
  connect(mapStateToProps, {
    scanPickup,
    scanDeliver,
    scanReceive,
    scanTransfer,
    scanReset,
    scanManifestCheck,
    deleteManifest,
    deleteAllManifest
  }),
  withNamespaces()
)(ScannerPage);
