/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import ShipmentData from "./ShipmentData";
import CreateShipment from "./CreateShipment";
import ShipmentToPartner from "./ShipmentToPartner";
import CreateCargo from "./CreateCargo";
import AddCargoToShipment from "./AddCargoToShipment";
import AddCargoToCargo from "./AddCargoToCargo";
import CargoChildrenPage from "./CargoChildrenPage";
import RemoveCargoFromCargo from "./RemoveCargoFromCargo";
import * as _ from "lodash";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Dropdown, Menu, notification, Modal } from "antd";
import { cargoActions, shipmentActions } from "../../../actions/rootActions";
import { ROOT_URL } from "../../../actions";
import pdf2base64 from "pdf-to-base64";
import * as qz from "qz-tray";
import axios from "axios";
import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";

class HMSShipments extends React.Component {
  createShipmentsRef = React.createRef();
  createCargoRef = React.createRef();
  createCargoToShipmentRef = React.createRef();
  addCargoToShipmentRef = React.createRef();
  removeCargoFromParentRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      cta: "",
      menucta: "",
      menuType: "",
      hubId: "",
      userId: "",
      userName: "",
      qubevuStatus: "disabled",
      qubevuIP: "",
      isLoggedIn: true,
      selectedShipmentFilter: "pending",
      showCreateShipment: false,
      showShipmentToPartner: false,
      showCreateCargo: false,
      showCreateCargoText: false,
      showDestinationCountry: false,
      showDestinationPostalCode: false,
      showCreateCargoToShipment: false,
      showAddCargoToShipment: false,
      showRemoveCargoPage: false,
      selectedRecipientId: "",
      selectedRecipientName: "",
      cargoType: "",
      destCountryCode: "",
      destPostCodePrefix: "",
      isDeminimis: false,
      lastMileRecipientId: undefined,
      createCargoToShipmentCargoNumber: "",
      createCargoToShipmentCargoNumberSnap: "",
      createCargoToShipmentCargoNumbersList: [],
      createCargoToShipmentCargoNumbersData: { total_records: 0 },
      addCargoToShipmentCargoNumber: "",
      addCargoToShipmentCargoNumberSnap: "",
      addCargoToShipmentCargoNumbersList: [],
      addCargoToShipmentCargoNumbersData: null,
      submitted: false,
      cargoNumberSubmitted: false,
      createCargoSubmitted: false,
      defaultPrinter: null,
      showQzTrayNotActiveError: false,
      showConfirmationModal: false,
      showRemoveCargoFromShipment: false,
      removeCargoFromShipmentCargoNumber: "",
      removeCargoFromShipmentCargoNumberSnap: "",
      removeCargoFromShipmentCargoNumbersList: [],
      selectedCargoNumber: null,
      manifestDownloadError: false,
      showUpdateShipmentWeightPopup: false,
      shipmentWeight: "",
      shipmentWeightNumber: null,
      shipmentUpdateWeightError: null,
      showLinkToMawbPopup: false,
      mawbNumber: "",
      shipmentMawbLinkedNumbers: [],
      shipmentMawbLinkError: null,
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-157972758-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      this.setState({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }

    let defaultPrinter = localStorage.getItem("defaultPrinter");
    this.setState({ defaultPrinter: defaultPrinter });

    this.props.dispatch(shipmentActions.fetchRecipients());
    this.props.dispatch(cargoActions.getCountriesList());
    this.getShipmentNumbersList();
    this.checkIfCameFromRTV();
  }

  // get shipments list
  getShipmentNumbersList = () => {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: 1,
    };
    this.props.dispatch(shipmentActions.getShipmentNumbersList(data));
  };

  // RTV shipment number
  checkIfCameFromRTV = () => {
    const isFromRTV = localStorage.getItem("RTV");
    if ((isFromRTV !== undefined || isFromRTV !== null) && isFromRTV === "true") {
      const recipientCompanyFromRTV = localStorage.getItem("recipientCompanyFromRTV");
      this.setState({
        showShipmentToPartner: true,
        showCreateShipment: false,
        selectedRecipientName: recipientCompanyFromRTV,
      });
    }
    localStorage.removeItem("RTV");
    localStorage.removeItem("recipientCompanyFromRTV");
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.newShipmentNo && prevProps.newShipmentNo !== this.props.newShipmentNo) {
      this.setState({ showShipmentToPartner: true, showCreateShipment: false });
      toast.dismiss();
      if (this.state.showCreateShipment) {
        setTimeout(() => {
          this.notify("Shipment Created");
        }, [1000]);
      }
    } else if (
      this.props.newCargoTrackingData &&
      prevProps.newCargoTrackingData !== this.props.newCargoTrackingData &&
      this.props.showSuccessMessage
    ) {
      let addCargoToShipmentCargoNumbersList = [...this.state.addCargoToShipmentCargoNumbersList];
      let newCargoTrackingData = this.props.newCargoTrackingData;
      newCargoTrackingData.scanned_tracking_no = newCargoTrackingData.cargo_tracking_no;
      addCargoToShipmentCargoNumbersList.unshift(this.props.newCargoTrackingData);
      this.setState({
        showCreateCargoToShipment: true,
        showCreateCargo: false,
        showShipmentToPartner: false,
        addCargoToShipmentCargoNumbersList: addCargoToShipmentCargoNumbersList,
        cargoType: "",
        destCountryCode: "",
        destPostCodePrefix: "",
        isDeminimis: false,
        lastMileRecipientId: undefined,
      });
      this.notify("Cargo Created!");
    } else if (
      this.props.addCargoTrackingNumber &&
      prevProps.addCargoTrackingNumber !== this.props.addCargoTrackingNumber
    ) {
      let createCargoToShipmentCargoNumbersList = [...this.state.createCargoToShipmentCargoNumbersList];
      createCargoToShipmentCargoNumbersList.unshift(this.props.addCargoTrackingNumber);
      let createCargoToShipmentCargoNumbersData = { ...this.state.createCargoToShipmentCargoNumbersData };
      createCargoToShipmentCargoNumbersData.total_records = createCargoToShipmentCargoNumbersData.total_records + 1;
      this.setState({
        createCargoToShipmentCargoNumbersList: createCargoToShipmentCargoNumbersList,
        createCargoToShipmentCargoNumbersData: createCargoToShipmentCargoNumbersData,
        createCargoToShipmentCargoNumber: "",
      });
      this.createCargoToShipmentRef.current && this.createCargoToShipmentRef.current.resetFields();
      this.playSound("success");
    } else if (this.props.addCargoError && prevProps.addCargoError !== this.props.addCargoError) {
      this.setState({
        createCargoToShipmentCargoNumber: "",
        createCargoToShipmentCargoNumberSnap: "",
      });

      this.createCargoToShipmentRef.current && this.createCargoToShipmentRef.current.resetFields();
      this.playSound("error");
    } else if (
      this.props.addCargoToShipmentData &&
      prevProps.addCargoToShipmentData !== this.props.addCargoToShipmentData
    ) {
      let addCargoToShipmentCargoNumbersList = [...this.state.addCargoToShipmentCargoNumbersList];
      addCargoToShipmentCargoNumbersList.unshift(this.props.addCargoToShipmentData);

      this.setState({
        addCargoToShipmentCargoNumbersList: addCargoToShipmentCargoNumbersList,
        addCargoToShipmentCargoNumber: "",
      });
      this.addCargoToShipmentRef.current && this.addCargoToShipmentRef.current.resetFields();
      this.playSound("success");
    } else if (
      this.props.addCargoToShipmentDataError &&
      prevProps.addCargoToShipmentDataError !== this.props.addCargoToShipmentDataError
    ) {
      this.setState({
        addCargoToShipmentCargoNumber: "",
        addCargoToShipmentCargoNumberSnap: "",
      });
      this.addCargoToShipmentRef.current && this.addCargoToShipmentRef.current.resetFields();
      this.playSound("error");
    } else if (
      this.props.shipmentCancelSuccessData &&
      prevProps.shipmentCancelSuccessData !== this.props.shipmentCancelSuccessData
    ) {
      this.cancelShipmentNotify("Shipment Cancelled");
      this.resetState();
      this.getShipmentNumbersList();
    } else if (
      this.props.shipmentCancelErrorData &&
      prevProps.shipmentCancelErrorData !== this.props.shipmentCancelErrorData
    ) {
      this.cancelShipmentNotify(this.props.shipmentCancelErrorData.shipment_no);
      this.resetState();
    } else if (
      this.props.removeCargoFromParentSuccessData &&
      prevProps.removeCargoFromParentSuccessData !== this.props.removeCargoFromParentSuccessData
    ) {
      let createCargoToShipmentCargoNumbersList = [...this.state.createCargoToShipmentCargoNumbersList];
      createCargoToShipmentCargoNumbersList = _.filter(
        createCargoToShipmentCargoNumbersList,
        (x) => x.scanned_tracking_no !== this.props.removeCargoFromParentSuccessData.scanned_tracking_no
      );
      let removeCargoFromShipmentCargoNumbersList = [...this.state.removeCargoFromShipmentCargoNumbersList];
      removeCargoFromShipmentCargoNumbersList.unshift(this.props.removeCargoFromParentSuccessData.scanned_tracking_no);

      let createCargoToShipmentCargoNumbersData = { ...this.state.createCargoToShipmentCargoNumbersData };
      createCargoToShipmentCargoNumbersData.total_records = createCargoToShipmentCargoNumbersData.total_records - 1;
      this.setState({
        createCargoToShipmentCargoNumbersList: createCargoToShipmentCargoNumbersList,
        createCargoToShipmentCargoNumbersData: createCargoToShipmentCargoNumbersData,
        removeCargoFromShipmentCargoNumbersList: removeCargoFromShipmentCargoNumbersList,
      });
      this.removeCargoFromParentRef.current && this.removeCargoFromParentRef.current.resetFields();
      this.playSound("success");
      this.fetchShipmentTrackingNumber(this.state.selectedRecipientName, this.props.newShipmentNo, 1);
    } else if (
      this.props.removeCargoFromParentErrorData &&
      prevProps.removeCargoFromParentErrorData !== this.props.removeCargoFromParentErrorData
    ) {
      this.setState({
        removeCargoFromShipmentCargoNumber: "",
        removeCargoFromShipmentCargoNumberSnap: "",
      });
      this.removeCargoFromParentRef.current && this.removeCargoFromParentRef.current.resetFields();
      this.playSound("error");
    }
  }

  // success/error alert
  playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  // scandit settings
  // getScanSettings = () => {
  //   return new ScanSettings({
  //     enabledSymbologies: ["qr", "ean8", "ean13", "upca", "upce", "code128", "code39", "code93", "itf"],
  //     codeDuplicateFilter: 1000
  //   })
  // }

  render() {
    //const { createShipmentProgress } = this.props;
    if (!this.state.isLoggedIn) {
      return <Redirect push to="/scan/hubmanage/old/login" />;
    }
    //const qubevuStatusClass = this.state.qubevuStatus && this.state.qubevuStatus === 'enabled'
    //? 'badge badge-success text-capitalize'
    //: 'badge badge-danger text-capitalize';

    const updateWeightClass = this.state.shipmentUpdateWeightError !== null ? "is-invalid" : "";
    const linkMawbClass = this.state.shipmentMawbLinkError !== null ? "is-invalid" : "";

    return (
      <div className="main-container d-flex flex-column align-items-left">
        <div>
          <h1 className="font-weight-bolder pl-0 float-left">Shipments</h1>
          <div className="float-right mt-2">
            <Dropdown overlay={this.getMenu()} placement="bottomLeft" trigger={["click"]}>
              <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
                Menu
              </button>
            </Dropdown>
            <button type="button" className="btn btn-outline-danger" onClick={this.clearSession}>
              End Session
            </button>
          </div>
        </div>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <LinkContainer to="/scan">
                <a>Home</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item">
              <LinkContainer to={`/scan/hubmanage`}>
                <a>HMS Scanner</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Shipments
            </li>
          </ol>
        </nav>
        <div className="content-container card mb-1 p-2">
          <div className="form-group d-block justify-content-between mb-0">
            <div id="hub-id" style={{ marginBottom: 0 + "rem" }}>
              <label>Hub ID</label>
              <div>
                <label>{this.state.hubId}</label>
              </div>
            </div>
            <div id="user-id">
              <label>User ID</label>
              <div>
                <label>{this.state.userName}</label>
              </div>
            </div>
          </div>
        </div>
        {this.getShipmentContent()}
        <ToastContainer />
        <Modal
          className="confirmation-modal"
          visible={this.state.showConfirmationModal}
          okText="Yes"
          okType="success"
          okButtonProps={{ className: "okButton" }}
          cancelText="No, I don’t want to cancel"
          cancelButtonProps={{ className: "cancelButton" }}
          onOk={this.cancelShipment}
          onCancel={this.closeCancelShipmentModal}
        >
          <p>Are you sure you want to cancel this shipment?</p>
        </Modal>

        {/* Mawb link popup */}
        <Modal className="confirmation-modal" visible={this.state.showLinkToMawbPopup} footer={null}>
          <div className="d-flex flex-column mb-1">
            <span className="text-dark">Link {this.props.newShipmentNo}</span>
            <input
              className={`form-control mt-2 ${linkMawbClass}`}
              value={this.state.mawbNumber}
              onChange={this.handleChangeMawbNumber}
            />
          </div>
          {this.state.shipmentMawbLinkError !== null && (
            <span className="text-danger">{this.state.shipmentMawbLinkError}</span>
          )}
          <div className="d-flex flex-nowrap justify-content-center mt-2">
            <button className="btn btn-outline-primary mr-2" onClick={this.linkShipmentNumberToMawb}>
              Link
            </button>
            <button className="btn btn-outline-danger" onClick={this.handleCancelLinkToMawbPopup}>
              Cancel
            </button>
          </div>
        </Modal>

        {/* shipment weight popup */}
        <Modal className="confirmation-modal" visible={this.state.showUpdateShipmentWeightPopup} footer={null}>
          <div className="mb-3">
            <span className="text-dark">Update Wt. {this.props.newShipmentNo}</span>
            <div className="d-flex flex-nowrap align-items-center">
              <input
                className={`form-control mt-2 text-right ${updateWeightClass}`}
                value={this.state.shipmentWeight}
                onChange={this.handleChangeShipmentWeight}
              />
              &nbsp; <span>KG</span>
            </div>
            {this.state.shipmentUpdateWeightError !== null && (
              <span className="text-danger">{this.state.shipmentUpdateWeightError}</span>
            )}
          </div>
          <div className="d-flex flex-nowrap justify-content-center">
            <button className="btn btn-outline-primary mr-2" onClick={this.updateShipmentWeight}>
              Update
            </button>
            <button className="btn btn-outline-danger" onClick={this.handleCancelUpdateWeightPopup}>
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }

  handleChangeMawbNumber = (e) => {
    this.setState({ mawbNumber: e.target.value, shipmentMawbLinkError: null });
  };

  // link shipment mawb
  linkShipmentNumberToMawb = async (e) => {
    e.preventDefault();
    if (this.validateShipmentToLinkMawb()) {
      await axios
        .put(`${ROOT_URL}/hms/update_shipment/`, {
          hub_id: localStorage.getItem("hubId"),
          hubscanner_key: localStorage.getItem("userId"),
          shipment_no: this.props.newShipmentNo,
          mawb_no: this.state.mawbNumber,
        })
        .then((res) => this.setShipmentLinkToMawb(res.data))
        .catch((err) => console.log("Error", err));
    }
  };

  setShipmentLinkToMawb = (res) => {
    let shipmentMawbLinkNumber = { mawb_no: res.mawb_no, updated_on: new Date() };
    let shipmentMawbLinkedNumbers = [...this.state.shipmentMawbLinkedNumbers];
    shipmentMawbLinkedNumbers.unshift(shipmentMawbLinkNumber);
    this.setState({
      shipmentMawbLinkedNumbers: shipmentMawbLinkedNumbers,
      showLinkToMawbPopup: false,
      mawbNumber: "",
    });
  };

  // validate shipment no field
  validateShipmentToLinkMawb = () => {
    if (_.isEmpty(this.state.mawbNumber)) {
      this.setState({ shipmentMawbLinkError: "This field is required" });
      return false;
    }
    return true;
  };

  handleCancelLinkToMawbPopup = (e) => {
    e.preventDefault();
    this.setState({
      showLinkToMawbPopup: false,
      mawbNumber: "",
      shipmentMawbLinkError: null,
    });
  };

  handleChangeShipmentWeight = (e) => {
    this.setState({
      shipmentWeight: e.target.value,
      shipmentUpdateWeightError: null,
    });
  };

  // update shipment weight
  updateShipmentWeight = async (e) => {
    e.preventDefault();
    if (this.validateUpdateShipmentWeight()) {
      await axios
        .put(`${ROOT_URL}/hms/update_shipment/`, {
          hub_id: localStorage.getItem("hubId"),
          hubscanner_key: localStorage.getItem("userId"),
          shipment_no: this.props.newShipmentNo,
          weight: Number(this.state.shipmentWeight),
        })
        .then((res) =>
          this.setState({
            shipmentWeightNumber: res.data.weight,
            showUpdateShipmentWeightPopup: false,
            shipmentWeight: "",
          })
        )
        .catch((err) => console.log("Error", err));
    }
  };

  // validate shipment weight
  validateUpdateShipmentWeight = () => {
    if (_.isEmpty(this.state.shipmentWeight)) {
      this.setState({ shipmentUpdateWeightError: "This field is required" });
      return false;
    } else if (isNaN(Number(this.state.shipmentWeight))) {
      this.setState({ shipmentUpdateWeightError: "Only numbers are allowed" });
      return false;
    } else if (this.getDecimals(this.state.shipmentWeight) > 2) {
      this.setState({ shipmentUpdateWeightError: "Only 2 decimals are allowed" });
      return false;
    } else if (Number(this.state.shipmentWeight) < 0) {
      this.setState({ shipmentUpdateWeightError: "Number should be greater than zero" });
      return false;
    }
    return true;
  };

  // return decimal count
  getDecimals(input) {
    if (Math.floor(input) === Number(input)) return 0;
    return input.toString().split(".")[1].length || 0;
  }

  handleCancelUpdateWeightPopup = (e) => {
    e.preventDefault();
    this.setState({
      showUpdateShipmentWeightPopup: false,
      shipmentWeight: "",
      shipmentUpdateWeightError: null,
    });
  };

  notify = (message) => {
    toast.success(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      closeButton: false,
      className: "text-center toast-margin",
    });
  };

  cancelShipmentNotify = (message) => {
    toast.warn(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      closeButton: false,
      className: "text-center toast-margin",
    });
  };

  getMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments" onClick={this.clearStateForShipment}>
            Shipments
          </Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  };

  clearStateForShipment = () => {
    this.resetState();
    this.resetReduxState();
    this.getShipmentNumbersList();
  };

  resetState = () => {
    this.setState({
      cta: "",
      menucta: "",
      menuType: "",
      isLoggedIn: true,
      selectedShipmentFilter: "pending",
      showCreateShipment: false,
      showShipmentToPartner: false,
      showCreateCargo: false,
      showCreateCargoText: false,
      showDestinationCountry: false,
      showDestinationPostalCode: false,
      showCreateCargoToShipment: false,
      showAddCargoToShipment: false,
      showRemoveCargoPage: false,
      selectedRecipientId: "",
      selectedRecipientName: "",
      cargoType: "",
      destCountryCode: "",
      destPostCodePrefix: "",
      isDeminimis: false,
      lastMileRecipientId: undefined,
      createCargoToShipmentCargoNumber: "",
      createCargoToShipmentCargoNumberSnap: "",
      createCargoToShipmentCargoNumbersList: [],
      createCargoToShipmentCargoNumbersData: { total_records: 0 },
      addCargoToShipmentCargoNumber: "",
      addCargoToShipmentCargoNumberSnap: "",
      addCargoToShipmentCargoNumbersList: [],
      addCargoToShipmentCargoNumbersData: null,
      submitted: false,
      cargoNumberSubmitted: false,
      createCargoSubmitted: false,
      showQzTrayNotActiveError: false,
      showConfirmationModal: false,
      showRemoveCargoFromShipment: false,
      removeCargoFromShipmentCargoNumber: "",
      removeCargoFromShipmentCargoNumberSnap: "",
      removeCargoFromShipmentCargoNumbersList: [],
      selectedCargoNumber: null,
      manifestDownloadError: false,
      showUpdateShipmentWeightPopup: false,
      shipmentWeight: "",
      shipmentWeightNumber: null,
      shipmentUpdateWeightError: null,
      showLinkToMawbPopup: false,
      mawbNumber: "",
      shipmentMawbLinkedNumbers: [],
      shipmentMawbLinkError: null,
    });
  };

  resetReduxState = () => {
    this.props.dispatch(cargoActions.clearCargoResponse());
    this.props.dispatch(cargoActions.clearCreateCargoError());
    this.props.dispatch(shipmentActions.clearShipmentError());
    this.props.dispatch(shipmentActions.clearShipmentResponse());
    this.props.dispatch(cargoActions.clearCargoState());
    this.props.dispatch(cargoActions.clearCreateCargoResponse());
    this.props.dispatch(cargoActions.clearAddCargoToShipmentResponse());
  };

  getShipmentContent = () => {
    if (this.state.showCreateShipment) {
      return this.createShipments();
    } else if (this.state.showShipmentToPartner) {
      return this.showShipmentToPartner();
    } else if (this.state.showCreateCargo) {
      return this.getCreateCargo();
    } else if (this.state.showCreateCargoToShipment) {
      return this.getCreateCargoToShipment();
    } else if (this.state.showRemoveCargoPage) {
      return this.getRemoveCargoPage();
    } else if (this.state.showRemoveCargoFromShipment) {
      return this.getRemoveCargoFromShipmentPage();
    } else if (this.state.showAddCargoToShipment) {
      return this.getAddCargoToShipment();
    } else {
      return this.getShipmentsData();
    }
  };

  // get shipment data
  getShipmentsData = () => {
    return (
      <ShipmentData
        getFilterBg={this.getFilterBg}
        setSelectedShipmentFilter={this.setSelectedShipmentFilter}
        selectedShipmentFilter={this.state.selectedShipmentFilter}
        showCreateShipment={this.showCreateShipment}
        shipmentNumbersList={this.getFilteredShipmentNumbersList()}
        shipmentNumbersData={this.props.shipmentNumbersList}
        getShipmentTrackingNumber={this.getShipmentTrackingNumber}
      />
    );
  };

  // filter shipment statuses
  getFilteredShipmentNumbersList = () => {
    const { shipmentNumbersList } = this.props;
    return _.filter(shipmentNumbersList.shipments, (x) => x.status === this.state.selectedShipmentFilter.toUpperCase());
  };

  // create shipment
  createShipments = () => {
    const { partners, createShipmentErr } = this.props;
    return (
      <CreateShipment
        partners={partners}
        createCargoErr={createShipmentErr}
        createShipmentsRef={this.createShipmentsRef}
        handlePartnerToSendChange={this.handlePartnerToSendChange}
        showShipmentCreationProgressMessage={this.showShipmentCreationProgressMessage}
        showShipmentPage={this.clearStateForShipment}
      />
    );
  };

  // show shipment
  showShipmentToPartner = () => {
    const { newShipmentNo } = this.props;
    return (
      <ShipmentToPartner
        newShipmentNo={newShipmentNo}
        selectedRecipientName={this.state.selectedRecipientName}
        showCreateCargo={this.showCreateCargo}
        showAddCargoToShipment={this.showAddCargoToShipment}
        cargoNumbersList={this.state.addCargoToShipmentCargoNumbersList}
        cargoNumbersData={this.state.addCargoToShipmentCargoNumbersData}
        cancelShipment={this.cancelShipmentConfirmation}
        showCargoChildrenPage={this.showCargoChildrenPage}
        manifestDownloadError={this.state.manifestDownloadError}
        downloadManifest={this.downloadManifest}
        shipmentWeightNumber={this.state.shipmentWeightNumber}
        handleUpdateWeightClick={this.handleUpdateWeightClick}
        shipmentMawbLinkedNumbers={this.state.shipmentMawbLinkedNumbers}
        handleLinkToMawbClick={this.handleLinkToMawbClick}
        getShipmentTrackingNumber={this.getShipmentTrackingNumber}
        showShipmentPage={this.clearStateForShipment}
      />
    );
  };

  // create cargo
  getCreateCargo = () => {
    let { countriesList, newShipmentNo, createCargoErr, partnersForHub } = this.props;
    return (
      <CreateCargo
        countriesList={countriesList}
        newShipmentNo={newShipmentNo}
        createCargoErr={createCargoErr}
        partnersForHub={partnersForHub}
        selectedRecipientName={this.state.selectedRecipientName}
        createCargoRef={this.createCargoRef}
        handleCargoTypeChange={this.handleCargoTypeChange}
        cargoType={this.state.cargoType}
        showDestinationCountry={this.state.showDestinationCountry}
        handleDestinationChange={this.handleDestinationChange}
        destCountryCode={this.state.destCountryCode}
        handleLastMileRecipientChange={this.handleLastMileRecipientChange}
        lastMileRecipientId={this.state.lastMileRecipientId}
        showDestinationPostalCode={this.state.showDestinationPostalCode}
        handlePostalCodeChange={this.handlePostalCodeChange}
        destPostCodePrefix={this.state.destPostCodePrefix}
        isDeminimis={this.state.isDeminimis}
        handleIsDeminimisChange={this.handleIsDeminimisChange}
        createCargoFn={this.createCargoFn}
        closeCreateCargoError={this.closeCreateCargoError}
      />
    );
  };

  // add cargo to cargo
  getCreateCargoToShipment = () => {
    let { newCargoTrackingData, addCargoError, newShipmentNo, addCargoTrackingNumber } = this.props;
    return (
      <AddCargoToCargo
        newCargoTrackingData={newCargoTrackingData ? newCargoTrackingData : this.state.selectedCargoNumber}
        showCreateCargoText={this.state.showCreateCargoText}
        addCargoError={addCargoError}
        newShipmentNo={newShipmentNo}
        selectedRecipientName={this.state.selectedRecipientName}
        cargoNumbersList={this.state.createCargoToShipmentCargoNumbersList}
        cargoNumbersData={this.state.createCargoToShipmentCargoNumbersData}
        cargoNumberSnap={this.state.createCargoToShipmentCargoNumberSnap}
        createCargoToShipmentRef={this.createCargoToShipmentRef}
        cargoNumber={this.state.createCargoToShipmentCargoNumber}
        addCargoTrackingNumber={addCargoTrackingNumber}
        handleChangeCargoNumber={this.handleChangeCargoNumber}
        setAddCargoNumber={this.setAddCargoNumber}
        createCargoToShipment={this.createCargoToShipment}
        showRemoveCargoPage={this.showRemoveCargoPage}
        // getScanSettings={this.getScanSettings}
        showCargoChildrenPage={this.showCargoChildrenPage}
      />
    );
  };

  getRemoveCargoPage = () => {
    const { newShipmentNo, newCargoTrackingData } = this.props;
    return (
      <CargoChildrenPage
        newShipmentNo={newShipmentNo}
        newCargoTrackingData={newCargoTrackingData ? newCargoTrackingData : this.state.selectedCargoNumber}
        selectedRecipientName={this.state.selectedRecipientName}
        showCreateCargo={this.showCreateCargo}
        showCreateCargoToShipment={this.showCreateCargoToShipment}
        cargoNumbersList={this.state.createCargoToShipmentCargoNumbersList}
        cargoNumbersData={this.state.createCargoToShipmentCargoNumbersData}
        printLabelShipmentToCargo={this.printLabelShipmentToCargo}
        showQzTrayNotActiveError={this.state.showQzTrayNotActiveError}
        showRemoveCargoFromShipmentPage={this.showRemoveCargoFromShipmentPage}
        showShipmentToPartner={this.showShipmentToPartnerFromCargoChildrenPage}
        showCargoChildrenPage={this.showCargoChildrenPage}
      />
    );
  };

  // remove cargo from parent cargo
  getRemoveCargoFromShipmentPage = () => {
    const { newCargoTrackingData, removeCargoFromParentErrorData, removeCargoFromParentSuccessData } = this.props;
    return (
      <RemoveCargoFromCargo
        newCargoTrackingData={newCargoTrackingData ? newCargoTrackingData : this.state.selectedCargoNumber}
        cargoNumber={this.state.removeCargoFromShipmentCargoNumber}
        cargoNumberSnap={this.state.removeCargoFromShipmentCargoNumberSnap}
        cargoNumbersList={this.state.removeCargoFromShipmentCargoNumbersList}
        removeCargoFromParentRef={this.removeCargoFromParentRef}
        removeCargoSuccess={removeCargoFromParentSuccessData}
        removeCargoError={removeCargoFromParentErrorData}
        showRemoveCargoPage={this.showRemoveCargoPageAfterRemoveCargo}
        handleChangeCargoNumber={this.handleRemoveCargoNumberChange}
        removeCargoFromParent={this.removeCargoFromParent}
        setRemoveCargoNumber={this.setRemoveCargoNumber}
        // getScanSettings={this.getScanSettings}
      />
    );
  };

  // add cargo
  getAddCargoToShipment = () => {
    const { addCargoToShipmentDataError, newShipmentNo, addCargoToShipmentData } = this.props;
    return (
      <AddCargoToShipment
        addCargoError={addCargoToShipmentDataError}
        newShipmentNo={newShipmentNo}
        selectedRecipientName={this.state.selectedRecipientName}
        cargoNumber={this.state.addCargoToShipmentCargoNumber}
        cargoNumberSnap={this.state.addCargoToShipmentCargoNumberSnap}
        cargoNumbersList={this.state.addCargoToShipmentCargoNumbersList}
        cargoNumbersData={this.state.addCargoToShipmentCargoNumbersData}
        addCargoRef={this.addCargoToShipmentRef}
        handleChangeCargoNumber={this.handleChangeAddCargoToShipmentNumber}
        setAddCargoToShipmentNumber={this.setAddCargoToShipmentNumber}
        addCargo={this.addCargoToShipment}
        handleShipmentNumberClick={this.handleShipmentNumberClick}
        addCargoToShipmentData={addCargoToShipmentData}
        // getScanSettings={this.getScanSettings}
        getShipmentTrackingNumber={this.getShipmentTrackingNumber}
      />
    );
  };

  // show list of cargos in shipment
  getShipmentTrackingNumber = (selectedRecipientName, shipmentNumber, pageNumber) => {
    this.fetchShipmentTrackingNumber(selectedRecipientName, shipmentNumber, pageNumber);
    this.setState({
      showShipmentToPartner: true,
    });
  };

  fetchShipmentTrackingNumber = async (selectedRecipientName, shipmentNumber, pageNumber) => {
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipmentNumber,
      page: pageNumber,
    };
    let apiResponse = await axios.get(`${ROOT_URL}/hms/cargos/`, { params: data });
    let trackingNumbersList = [];

    apiResponse.data.cargos.forEach((cargoNumber) => {
      trackingNumbersList.push({
        scanned_tracking_no: cargoNumber.tracking_no,
        cargo_type: cargoNumber.cargo_type,
        dest_country: cargoNumber.dest_country_code,
        cargo_tracking_no: cargoNumber.tracking_no,
      });
    });

    let shipmentMawbLinkedNumbers = _.orderBy(apiResponse.data.shipment_mawb_logs, "updated_on", "asc");

    let shipmentWeight = apiResponse.data.shipment_weight;
    this.setState({
      addCargoToShipmentCargoNumbersList: trackingNumbersList,
      addCargoToShipmentCargoNumbersData: apiResponse.data,
      selectedRecipientName: selectedRecipientName,
      shipmentMawbLinkedNumbers: shipmentMawbLinkedNumbers,
      mawbNumber: apiResponse.data.shipment_mawb_no,
      shipmentWeight: shipmentWeight,
      shipmentWeightNumber: shipmentWeight,
    });
    this.props.dispatch(shipmentActions.setShipmentNo(shipmentNumber));
  };

  // Show child cargos in cargo
  showCargoChildrenPage = async (e, cargoNumber, pageNumber) => {
    if (e) e.preventDefault();
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargoNumber.scanned_tracking_no,
      page: pageNumber,
    };
    let apiResponse = await axios.get(`${ROOT_URL}/hms/cargos/`, { params: data });
    let trackingNumbersList = [];

    apiResponse.data.cargos.forEach((cargoNumber) => {
      trackingNumbersList.push({ scanned_tracking_no: cargoNumber.tracking_no });
    });
    this.setState({
      showRemoveCargoPage: true,
      showShipmentToPartner: false,
      createCargoToShipmentCargoNumbersList: trackingNumbersList,
      createCargoToShipmentCargoNumbersData: apiResponse.data,
      selectedCargoNumber: cargoNumber,
      removeCargoFromShipmentCargoNumbersList: [],
    });
    this.props.dispatch(cargoActions.clearCargoResponse());
    this.props.dispatch(cargoActions.clearCreateCargoResponse());
  };

  handleShipmentNumberClick = () => {
    this.setState({ showShipmentToPartner: true, showAddCargoToShipment: false });
    this.props.dispatch(cargoActions.clearAddCargoToShipmentResponse());
  };

  showShipmentToPartnerFromCargoChildrenPage = () => {
    this.setState({ showShipmentToPartner: true, showRemoveCargoPage: false });
  };

  showCreateCargoToShipment = () => {
    this.setState({ showCreateCargoToShipment: true, showCreateCargo: false, showShipmentToPartner: false });
  };

  showRemoveCargoPage = () => {
    this.setState({ showRemoveCargoPage: true, showCreateCargoToShipment: false });
    this.props.dispatch(cargoActions.clearCargoResponse());
  };

  showAddCargoToShipment = () => {
    this.setState({ showAddCargoToShipment: true, showShipmentToPartner: false });
  };

  closeShipmentError = () => {
    this.props.dispatch(shipmentActions.clearShipmentError());
  };

  closeCreateCargoError = () => {
    this.props.dispatch(cargoActions.clearCreateCargoError());
  };

  showRemoveCargoFromShipmentPage = () => {
    this.setState({ showRemoveCargoFromShipment: true, showRemoveCargoPage: false });
  };

  showRemoveCargoPageAfterRemoveCargo = () => {
    this.setState({ showRemoveCargoPage: true, showRemoveCargoFromShipment: false });
    this.props.dispatch(cargoActions.removeCargoResetState());
  };

  getFilterBg = (selectedShipmentFilter) => {
    return this.state.selectedShipmentFilter === selectedShipmentFilter
      ? "pointer selected-shipment-filter-bg"
      : "pointer shipment-filter-bg";
  };

  setSelectedShipmentFilter = (e, selectedShipmentFilter) => {
    e.preventDefault();
    this.setState({ selectedShipmentFilter: selectedShipmentFilter });
  };

  showCreateShipment = () => {
    this.setState({ showCreateShipment: true });
    this.props.dispatch(shipmentActions.shipmentResetState());
    this.props.dispatch(cargoActions.cargoResetState());
  };

  handlePartnerToSendChange = (value, option) => {
    this.setState({
      selectedRecipientId: value,
      selectedRecipientName: option.props.children,
    });
    this.createShipmentsRef.current.setFieldsValue({
      selectedRecipientId: value,
    });
  };

  showShipmentCreationProgressMessage = (values) => {
    this.setState({ submitted: true });
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      next_recipient_id: this.state.selectedRecipientId,
    };
    toast("Shipment Creation in Progress...", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      closeButton: false,
      className: "text-center toast-margin",
    });
    this.props.dispatch(shipmentActions.createShipment(data));
  };

  showShipmentProgressMessage = () => {
    notification.success({
      message: "Shipment Created!",
    });
  };

  // handle mawb popup
  handleLinkToMawbClick = () => {
    this.setState({ showLinkToMawbPopup: true });
  };

  // update shipment weight
  handleUpdateWeightClick = () => {
    this.setState({ showUpdateShipmentWeightPopup: true });
  };

  showCreateCargo = (e) => {
    e.preventDefault();
    let createCargoToShipmentCargoNumbersData = { total_records: 0 };
    this.setState({
      showCreateCargo: true,
      showShipmentToPartner: false,
      showCreateCargoText: true,
      createCargoToShipmentCargoNumbersList: [],
      createCargoToShipmentCargoNumbersData: createCargoToShipmentCargoNumbersData,
      removeCargoFromShipmentCargoNumbersList: [],
    });
  };

  handleCargoTypeChange = (value) => {
    this.setState({ cargoType: value, showDestinationCountry: true });
    this.closeCreateCargoError();
  };

  handleDestinationChange = (value) => {
    this.setState({ destCountryCode: value, showDestinationPostalCode: true });
    this.closeCreateCargoError();
    this.props.dispatch(shipmentActions.fetchRecipientsForHub(value));
  };

  handleLastMileRecipientChange = (value) => {
    this.setState({ lastMileRecipientId: value });
  };

  handlePostalCodeChange = (e) => {
    this.setState({ destPostCodePrefix: e.target.value });
    this.closeCreateCargoError();
  };

  handleIsDeminimisChange = () => {
    this.setState({ isDeminimis: !this.state.isDeminimis });
  };

  createCargoFn = (e) => {
    const { newShipmentNo } = this.props;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: newShipmentNo,
      cargo_type: this.state.cargoType,
      dest_country_code: this.state.destCountryCode,
      dest_poscode_prefix: this.state.destPostCodePrefix,
      is_deminimis: this.state.isDeminimis,
      lm_recipient_id: this.state.lastMileRecipientId,
    };
    this.props.dispatch(cargoActions.createCargo(data));
  };

  handleChangeCargoNumber = (e) => {
    this.setState({
      createCargoToShipmentCargoNumber: e.target.value,
      createCargoToShipmentCargoNumberSnap: e.target.value,
    });
    this.createCargoToShipmentRef.current.setFieldsValue({
      createCargoToShipmentCargoNumber: e.target.value,
    });
  };

  setAddCargoNumber = (cargoNumber) => {
    this.setState({
      createCargoToShipmentCargoNumber: cargoNumber,
      createCargoToShipmentCargoNumberSnap: cargoNumber,
    });
    this.createCargoToShipment();
  };

  cancelShipmentConfirmation = () => {
    this.setState({ showConfirmationModal: true });
  };

  closeCancelShipmentModal = () => {
    this.setState({ showConfirmationModal: false });
  };

  cancelShipment = (e) => {
    e.preventDefault();
    const { newShipmentNo } = this.props;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: newShipmentNo,
    };
    this.props.dispatch(shipmentActions.cancelShipment(data));
  };

  createCargoToShipment = () => {
    const { newCargoTrackingData } = this.props;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: newCargoTrackingData
        ? newCargoTrackingData.cargo_tracking_no
        : this.state.selectedCargoNumber.cargo_tracking_no,
      scanned_tracking_no: this.state.createCargoToShipmentCargoNumber,
    };
    this.props.dispatch(cargoActions.createCargoToShipment(data));
  };

  handleChangeAddCargoToShipmentNumber = (e) => {
    this.setState({
      addCargoToShipmentCargoNumber: e.target.value,
      addCargoToShipmentCargoNumberSnap: e.target.value,
    });
    this.addCargoToShipmentRef.current.setFieldsValue({
      addCargoToShipmentCargoNumber: e.target.value,
    });
  };

  setAddCargoToShipmentNumber = (cargoNumber) => {
    this.setState({
      addCargoToShipmentCargoNumber: cargoNumber,
      addCargoToShipmentCargoNumberSnap: cargoNumber,
    });
    this.addCargoToShipment();
  };

  addCargoToShipment = () => {
    const { newShipmentNo } = this.props;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: newShipmentNo.trim(),
      scanned_tracking_no: this.state.addCargoToShipmentCargoNumber.trim(),
    };
    this.props.dispatch(cargoActions.addCargoToShipment(data));
  };

  handleRemoveCargoNumberChange = (e) => {
    this.setState({
      removeCargoFromShipmentCargoNumber: e.target.value,
      removeCargoFromShipmentCargoNumberSnap: e.target.value,
    });
    this.removeCargoFromParentRef.current.setFieldsValue({
      removeCargoFromShipmentCargoNumber: e.target.value,
    });
  };

  setRemoveCargoNumber = (cargoNumber) => {
    this.setState({
      removeCargoFromShipmentCargoNumber: cargoNumber,
      removeCargoFromShipmentCargoNumberSnap: cargoNumber,
    });
    this.removeCargoFromParent();
  };

  removeCargoFromParent = () => {
    const { newCargoTrackingData } = this.props;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: newCargoTrackingData
        ? newCargoTrackingData.cargo_tracking_no
        : this.state.selectedCargoNumber.cargo_tracking_no,
      scanned_tracking_no: this.state.removeCargoFromShipmentCargoNumber,
    };
    this.props.dispatch(cargoActions.removeCargoFromParent(data));
  };

  disconnectQzTray = () => {
    qz.websocket
      .disconnect()
      .then(() => {
        console.log("Disconnected");
      })
      .catch((e) => console.log("Error", e));
  };

  clearSession = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("hubId");
    localStorage.removeItem("scanTypes");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("printersList");
    localStorage.removeItem("isQzConnected");
    this.setState({
      isLoggedIn: false,
    });
  };

  // download manifest
  downloadManifest = async () => {
    const { newShipmentNo } = this.props;
    let params = {
      hub_id: this.state.hubId,
      hubscanner_key: this.state.userId,
      shipment_no: newShipmentNo,
    };

    const url = `${ROOT_URL}/hms/download-shipment-manifest/`;

    axios
      .get(url, {
        params: params,
        responseType: "blob",
        headers: {
          Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "manifest.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((e) => {
        this.setState({
          manifestDownloadError: true,
        });

        setTimeout(() => {
          this.setState({
            manifestDownloadError: false,
          });
        }, 5000);
      });
  };

  // print cargo label
  printLabelShipmentToCargo = async () => {
    const { newCargoTrackingData } = this.props;
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: newCargoTrackingData
        ? newCargoTrackingData.cargo_tracking_no
        : this.state.selectedCargoNumber.cargo_tracking_no,
    };
    let response = await axios.get(`${ROOT_URL}/hms/print_cargo_label/`, {
      params: data,
    });

    let pdf = `${ROOT_URL}/hms/print_cargo_label/?hub_id=${this.state.hubId}&hubscanner_key=${
      this.state.userId
    }&cargo_tracking_no=${
      newCargoTrackingData ? newCargoTrackingData.cargo_tracking_no : this.state.selectedCargoNumber.cargo_tracking_no
    }`;
    if (qz.websocket.isActive() && response) {
      pdf2base64(pdf)
        .then((response) => {
          var config = qz.configs.create(this.state.defaultPrinter);
          var qzTrayData = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          qz.print(config, qzTrayData).then(function () {});
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ showQzTrayNotActiveError: true });
      setTimeout(() => {
        this.setState({ showQzTrayNotActiveError: false });
      }, 5000);
    }
  };
}

const mapStateToProps = (state) => ({
  shipmentNumbersList: state.shipment.shipmentNumbersList,
  partners: state.shipment.partners,
  partnersForHub: state.shipment.partnersForHub,
  createShipmentProgress: state.shipment.createShipmentProgress,
  newShipmentNo: state.shipment.shipment_no,
  shipmentResetState: state.shipment.resetState,
  createShipmentErr: state.shipment.createShipmentErr,
  shipmentCancelSuccessData: state.shipment.shipmentCancelSuccessData,
  shipmentCancelErrorData: state.shipment.shipmentCancelErrorData,
  createCargoErr: state.cargo.createCargoErr,
  newCargoTrackingData: state.cargo.newCargoTrackingData,
  showSuccessMessage: state.cargo.showSuccessMessage,
  addCargoTrackingNumber: state.cargo.addCargoTrackingNumber,
  addCargoError: state.cargo.addCargoError,
  countriesList: state.cargo.countriesList,
  cargoResetState: state.cargo.resetState,
  addCargoToShipmentData: state.cargo.addCargoToShipmentData,
  addCargoToShipmentDataError: state.cargo.addCargoToShipmentDataError,
  removeCargoFromParentSuccessData: state.cargo.removeCargoFromParentSuccessData,
  removeCargoFromParentErrorData: state.cargo.removeCargoFromParentErrorData,
});

export default connect(mapStateToProps)(HMSShipments);
