export const networkPartners = [
  //{ label: "ysl", value: "ysl" },
  //{ label: "wmg", value: "wmg" },
  //{ label: "tunas", value: "tunas" },
  //{ label: "towrai", value: "towrai" },
  //{ label: "system", value: "system" },
  //{ label: "speedlink", value: "speedlink" },
  //{ label: "speedbox", value: "speedbox" },
  //{ label: "shipperid", value: "shipperid" },
  //{ label: "sfexpress", value: "sfexpress" },
  //{ label: "seko", value: "seko" },
  //{ label: "scg", value: "scg" },
  //{ label: "satsaco", value: "satsaco" },
  //{ label: "sap", value: "sap" },
  //{ label: "pnp", value: "pnp" },
  //{ label: "kerrylogistics", value: "kerrylogistics" },
  //{ label: "kerryhk", value: "kerryhk" },
  //{ label: "janioscanner", value: "janioscanner" },
  //{ label: "janioid", value: "janioid" },
  //{ label: "janiodetrack", value: "janiodetrack" },
  //{ label: "everbest", value: "everbest" },
  //{ label: "entrego", value: "entrego" },
  //{ label: "dyil", value: "dyil" },
  //{ label: "dhl", value: "dhl" },
  //{ label: "cta", value: "cta" },
  { label: "cjsg", value: "cjsg" },
  //{ label: "cjmy", value: "cjmy" },
  //{ label: "borderlessecommerce", value: "borderlessecommerce" },
  //{ label: "aramex", value: "aramex" },
  //{ label: "admin", value: "admin" },
  //{ label: "2goexpress", value: "2goexpress" },
  //{ label: "jitchina", value: "jitchina" },
  { label: "pickupp", value: "pickupp" },
  //{ label: "pgeon", value: "pgeon" },
  //{ label: "Lion Parcel", value: "Lion Parcel" },
  //{ label: "cjcentury", value: "cjcentury" },
  //{ label: "dgpackaging", value: "dgpackaging" },
  //{ label: "loginext", value: "loginext" },
  //{ label: "DTDC", value: "DTDC" },
  //{ label: "loginextsg", value: "loginextsg" },
  //{ label: "sicepat", value: "sicepat" },
  //{ label: "eliexpress", value: "eliexpress" },
  //{ label: "acilogistics", value: "acilogistics" },
  //{ label: "ams", value: "ams" },
  //{ label: "sf logistics indonesia", value: "sf logistics indonesia" },
  //{ label: "airspeed", value: "airspeed" },
  //{ label: "boxc", value: "boxc" },
  //{ label: "shipa", value: "shipa" },
  //{ label: "abxexpress", value: "abxexpress" },
  //{ label: "DTDC UK", value: "DTDC UK" },
  //{ label: "janiodetrackindo", value: "janiodetrackindo" },
  //{ label: "janiohms", value: "janiohms" },
  //{ label: "rnzexpress", value: "rnzexpress" },
  //{ label: "jne", value: "jne" },
  //{ label: "smoothexpress", value: "smoothexpress" },
  //{ label: "keppel", value: "keppel" },
  //{ label: "FMG", value: "FMG" },
  //{ label: "teleport", value: "teleport" },
  //{ label: "uaf", value: "uaf" },
  //{ label: "delivos", value: "delivos" },
  //{ label: "greenlane", value: "greenlane" },
  //{ label: "blackarrow", value: "blackarrow" },
  //{ label: "lsalogistics", value: "lsalogistics" },
  //{ label: "vtk", value: "vtk" },
  //{ label: "kerryindo", value: "kerryindo" },
  //{ label: "kerryph", value: "kerryph" },
  //{ label: "posmalaysia", value: "posmalaysia" },
  //{ label: "b3express", value: "b3express" },
  { label: "pickuppmy", value: "pickuppmy" },
  //{ label: "lbc", value: "lbc" },
  //{ label: "poslaju", value: "poslaju" },
  //{ label: "janiomy", value: "janiomy" },
  { label: "jtms_sg", value: "jtms_sg" },
  //{ label: "janiosg", value: "janiosg" },
  //{ label: "zto", value: "zto" },
  //{ label: "citylinkexpress", value: "citylinkexpress" },
  //{ label: "gdexpress", value: "gdexpress" },
  //{ label: "ninjavansg", value: "ninjavansg" },
  //{ label: "ninjavanmy", value: "ninjavanmy" },
  //{ label: "airpak", value: "airpak" },
  //{ label: "janiohmsftzsg", value: "janiohmsftzsg" },
  //{ label: "janiohmsspacesg", value: "janiohmsspacesg" },
  //{ label: "janiohmskgid", value: "janiohmskgid" },
  //{
  //label: "monarchdiversifiedlogistics",
  //value: "monarchdiversifiedlogistics",
  //},
  //{ label: "apg", value: "apg" },
  //{ label: "rayspeed", value: "rayspeed" },
  //{ label: "ninjavan", value: "ninjavan" },
  //{ label: "zto_cn", value: "zto_cn" },
  //{ label: "lfexpress", value: "lfexpress" },
  //{ label: "cjsg_detrack", value: "cjsg_detrack" },
  //{ label: "isend", value: "isend" },
  //{ label: "kawaspeed", value: "kawaspeed" },
  //{ label: "postco", value: "postco" },
  //{ label: "transportglobal", value: "transportglobal" },
  //{ label: "pcp", value: "pcp" },
  //{ label: "pcphmsdepokid", value: "pcphmsdepokid" },
  //{ label: "pcphmsbogorid", value: "pcphmsbogorid" },
  //{ label: "pcphmstangerangid", value: "pcphmstangerangid" },
  //{ label: "pcphmsbekasiid", value: "pcphmsbekasiid" },
  //{ label: "pcphmsbandungid", value: "pcphmsbandungid" },
  //{ label: "pcphmssurabayaid", value: "pcphmssurabayaid" },
  //{ label: "pcphmsmalangid", value: "pcphmsmalangid" },
  //{ label: "pcphmsjogjaid", value: "pcphmsjogjaid" },
  //{ label: "pcphmssurakartaid", value: "pcphmssurakartaid" },
  //{ label: "pcphmssemarangid", value: "pcphmssemarangid" },
  //{ label: "pcphmsdenpasarid", value: "pcphmsdenpasarid" },
  //{ label: "pcphmsbandarlampungid", value: "pcphmsbandarlampungid" },
  //{ label: "pcphmsjakartaid", value: "pcphmsjakartaid" },
  { label: "jtms_sg_lastmile", value: "jtms_sg_lastmile" },
  //{ label: "baoguohmsmelbau", value: "baoguohmsmelbau" },
  //{ label: "fsl", value: "fsl" },
  //{ label: "wal_hms_shahalam_my", value: "wal_hms_shahalam_my" },
  //{ label: "dakota", value: "dakota" },
  //{ label: "aeroexpress", value: "aeroexpress" },
  //{ label: "fma_hms_bkk_th", value: "fma_hms_bkk_th" },
  //{ label: "dyil_hms_tpe_tw", value: "dyil_hms_tpe_tw" },
  //{ label: "satsaco_hms_hk", value: "satsaco_hms_hk" },
  //{ label: "shroadnetwork", value: "shroadnetwork" },
  //{ label: "dropit", value: "dropit" },
  //{ label: "bbbexpress", value: "bbbexpress" },
  //{ label: "qs", value: "qs" },
  //{ label: "zenlogistic", value: "zenlogistic" },
  //{ label: "srefreight", value: "srefreight" },
  //{ label: "orangeds", value: "orangeds" },
  //{ label: "janiomy_fleet", value: "janiomy_fleet" },
  { label: "jtms_sg_lm_b3express", value: "jtms_sg_lm_b3express" },
  { label: "jtms_sg_lm_bonglobal", value: "jtms_sg_lm_bonglobal" },
  { label: "jtms_sg_lm_speeders", value: "jtms_sg_lm_speeders" },
  { label: "jtms_sg_lm_entregamos", value: "jtms_sg_lm_entregamos" },
  { label: "jtms_sg_lm_lsa", value: "jtms_sg_lm_lsa" },
  { label: "jtms_sg_lm_holla", value: "jtms_sg_lm_holla" },
  { label: "jtms_sg_lm_preeminence", value: "jtms_sg_lm_preeminence" },
  { label: "jtms_sg_lm_vnt", value: "jtms_sg_lm_vnt" },
  { label: "jtms_sg_lm_chalkboard", value: "jtms_sg_lm_chalkboard" },
  { label: "jtms_sg_lm_ameteche", value: "jtms_sg_lm_ameteche" },
  { label: "jtms_sg_lm_hydroguard", value: "jtms_sg_lm_hydroguard" },
  { label: "jtms_sg_lm_abz", value: "jtms_sg_lm_abz" },
  { label: "jtms_sg_lm_b2cexpress", value: "jtms_sg_lm_b2cexpress" },
  { label: "jtms_sg_lm_janio", value: "jtms_sg_lm_janio" },
  { label: "jtms_id_lm_janio", value: "jtms_id_lm_janio" },
  { label: "jtms_sg_fm_janio", value: "jtms_sg_fm_janio" },
  //{ label: "indotrans", value: "indotrans" },
  { label: "jtms_my_fm_janio", value: "jtms_my_fm_janio" },
  //{ label: "zto_sg", value: "zto_sg" },
  { label: "jtms_sg_lm_triforge", value: "jtms_sg_lm_triforge" },
  //{ label: "speedmark", value: "speedmark" },
  //{ label: "jade_freight", value: "jade_freight" },
  //{ label: "jtms_id_fm_janio", value: "jtms_id_fm_janio" },
  { label: "jtms_sg_lm_sarahtransport", value: "jtms_sg_lm_sarahtransport" },
  { label: "jtms_sg_lm_sehservices", value: "jtms_sg_lm_sehservices" },
  //{ label: "tri_mg_id", value: "tri_mg_id" },
  { label: "jtms_sg_lm_avialogexpress", value: "jtms_sg_lm_avialogexpress" },
  //{ label: "kerrymy", value: "kerrymy" },
  {
    label: "jtms_sg_lm_dlslogisticsservices",
    value: "jtms_sg_lm_dlslogisticsservices",
  },
  {
    label: "jtms_sg_lm_highesteemlogistics",
    value: "jtms_sg_lm_highesteemlogistics",
  },
  {
    label: "jtms_sg_lm_fdtserviceproviders",
    value: "jtms_sg_lm_fdtserviceproviders",
  },
  //{ label: "eaefreight", value: "eaefreight" },
  {
    label: "jtms_sg_lm_ptpcourierservice",
    value: "jtms_sg_lm_ptpcourierservice",
  },
  //{ label: "uni_air_id", value: "uni_air_id" },
  //{ label: "baoguo", value: "baoguo" },
  { label: "jtms_sg_lm_rzyexpress", value: "jtms_sg_lm_rzyexpress" },
  { label: "jtms_sg_lm_ajeliteservices", value: "jtms_sg_lm_ajeliteservices" },
  { label: "jtms_sg_lm_skylark", value: "jtms_sg_lm_skylark" },
  { label: "jtms_sg_lm_oceandrive", value: "jtms_sg_lm_oceandrive" },
  //{ label: "osf", value: "osf" },
  //{ label: "sh_road", value: "sh_road" },
  //{ label: "dpexi_hazmat_pte_ltd", value: "dpexi_hazmat_pte_ltd" },
  {
    label: "jtms_sg_lm_emissaryserviceproviders",
    value: "jtms_sg_lm_emissaryserviceproviders",
  },
  //{ label: "flash", value: "flash" },
  //{ label: "cevalogistics", value: "cevalogistics" },
  { label: "jtms_sg_lm_astarlogistics", value: "jtms_sg_lm_astarlogistics" },
  { label: "jtms_sg_lm_t2lservices", value: "jtms_sg_lm_t2lservices" },
  { label: "jtms_my_lm_janio", value: "jtms_my_lm_janio" },
  { label: "jtms_sg_lm_srmexpress", value: "jtms_sg_lm_srmexpress" },
  //{ label: "cevalogistics_my", value: "cevalogistics_my" },
  //{ label: "aee", value: "aee" },
  { label: "jtms_sg_lm_happilogistics", value: "jtms_sg_lm_happilogistics" },
  { label: "jtms_sg_lm_godspeed", value: "jtms_sg_lm_godspeed" },
  {
    label: "jtms_sg_lm_ajpreeminencefri",
    value: "jtms_sg_lm_ajpreeminencefri",
  },
  //{ label: "atlanticcourier", value: "atlanticcourier" },
  { label: "jtms_sg_lm_mmnscourier", value: "jtms_sg_lm_mmnscourier" },
  {
    label: "jtms_sg_lm_firstventureexpress",
    value: "jtms_sg_lm_firstventureexpress",
  },
  {
    label: "jtms_sg_lm_budgetcarslogistics",
    value: "jtms_sg_lm_budgetcarslogistics",
  },
  {
    label: "jtms_sg_lm_rindle",
    value: "jtms_sg_lm_rindle",
  },
  {
    label: "jtms_sg_lm_xfexpress",
    value: "jtms_sg_lm_xfexpress",
  },
  {
    label: "jtms_sg_lm_dycexpress",
    value: "jtms_sg_lm_dycexpress",
  },
  {
    label: "jtms_sg_lm_eonelogistics",
    value: "jtms_sg_lm_eonelogistics",
  },
  { label: "jtms_sg_lm_iwglobalholdings", value: "jtms_sg_lm_iwglobalholdings" },
  { label: "jtms_sg_lm_hash", value: "jtms_sg_lm_hash" },
  { label: "jtms_sg_lm_leonsynergy", value: "jtms_sg_lm_leonsynergy" },
  { label: "jtms_sg_lm_xfexpress", value: "jtms_sg_lm_xfexpress" },
  { label: "jtms_sg_lm_dycexpress", value: "jtms_sg_lm_dycexpress" },
  { label: "jtms_sg_lm_jqservices", value: "jtms_sg_lm_jqservices" },
  { label: "jtms_sg_lm_gxexpress", value: "jtms_sg_lm_gxexpress" },
  { label: "jtms_sg_lm_dyma_logistic", value: "jtms_sg_lm_dyma_logistic" },
  { label: "jtms_sg_lm_danibroenterprise", value: "jtms_sg_lm_danibroenterprise" },
  { label: "jtms_sg_lm_gustologistics", value: "jtms_sg_lm_gustologistics" },
  { label: "jtms_sg_lm_jingroup", value: "jtms_sg_lm_jingroup" },
  { label: "jtms_sg_lm_evolvelogistical", value: "jtms_sg_lm_evolvelogistical" },
  { label: "jtms_sg_lm_gallantlogistics", value: "jtms_sg_lm_gallantlogistics" },
  { label: "jtms_sg_lm_awesomepetexpress", value: "jtms_sg_lm_awesomepetexpress" },
  { label: "jtms_sg_lm_tsgurbanlogistics", value: "jtms_sg_lm_tsgurbanlogistics" },
];
