import React from "react";

export const CargoErrorNotificationArea = ({ errorDataBoxClass, errorNotificationData, scanType }) => {
  let component = (
    <>
      <div id="err-image" className="err-img err-no-data">
        &nbsp;
      </div>
      <span className="error-notification-text mb-0 text-center text-capitalize">
        {errorNotificationData.errorMessage}
      </span>
      {errorNotificationData.jsonBody && (
        <span className="json-notification-text">{errorNotificationData.jsonBody}</span>
      )}
    </>
  );
  if (scanType === "cargo arrival" || scanType === "cargo break") {
    component = (
      <>
        <span className="success-notification-25-bold text-center">{errorNotificationData.errorMessageHeading}</span>
        <span className="success-notification-50-bold text-center">{errorNotificationData.errorMessage}</span>
        <span className="success-notification-25 text-center">CARGO: {errorNotificationData.showTrackingNumber}</span>
        {errorNotificationData.jsonBody && (
          <span className="json-notification-text">{errorNotificationData.jsonBody}</span>
        )}
      </>
    );
  }
  return (
    <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
      {component}
    </div>
  );
};
