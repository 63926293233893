/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { cargoActions } from "../../../actions/rootActions";
import { cargoApi } from "../../../api";
import {
  CARGO_DETAILS_PAGE,
  SHIPMENT_TRACKING_NUMBER,
  CARGO_TRACKING_NUMBER,
} from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import ActionItemsList from "./actionItemsList";
import CargoDetails from "./cargoDetails";
import * as _ from "lodash";
import axios from "axios";
import "./style.scss";

function CargoDetailsPage(props) {
  const [toggleContent, setToggleContent] = useState({
    showMainContent: true,
  });

  const [redirectToShipmentDetailsPage, setRedirectToShipmentDetailsPage] = useState(false);
  const [cargoTrackingNumbers, setCargoTrackingNumbers] = useState([]);
  const [trackingNumberData, setTrackingNumberData] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [cargos, setCargos] = useState([]);
  const locationPageNumber = props.location.state.pageNumber;
  const cargo = props.cargoDetails ? props.cargoDetails : {};
  const shipment = props.location.state.shipment;

  useEffect(() => {
    onGetCargoDetails();
    getTrackingNumberInsideCargo(props.location.state.cargo.tracking_no, pageNumber);
    // mixpanel tracking page visit
    const mixPanelData = getMixpanelData();
    mixPanelTrack(CARGO_DETAILS_PAGE, {
      ...mixPanelData,
      [SHIPMENT_TRACKING_NUMBER]: props.location.state.cargo.shipment_no,
      [CARGO_TRACKING_NUMBER]: props.location.state.cargo.tracking_no,
    });
  }, []);

  useEffect(() => {
    props.dispatch(cargoActions.getCountriesList());
  }, []);

  if (redirectToShipmentDetailsPage) {
    return (
      <Redirect
        to={{
          pathname: `/scan/hubmanage/shipments/${shipment.tracking_no}`,
          state: {
            shipment: shipment,
          },
        }}
      />
    );
  }

  return <div className="scanner-bg-container">{mainContent()}</div>;

  function mainContent() {
    return (
      <React.Fragment>
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="title mb-0">Cargo</p>
          <p className="bread-crumb mt-n1 mb-0">{">"}</p>
          <p className="title text-capitalize mb-0">{cargo.tracking_no}</p>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">{cargoDetailsContent()}</div>
          <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">{actionItemsList()}</div>
        </div>
      </React.Fragment>
    );
  }

  function cargoDetailsContent() {
    return (
      <CargoDetails
        cargo={cargo}
        shipment={shipment}
        onClickRedirectToShipmentDetails={onClickRedirectToShipmentDetails}
      />
    );
  }

  function actionItemsList() {
    return <ActionItemsList onClickActionItem={onClickActionItem} cargo={cargo} shipment={shipment} />;
  }

  function onClickActionItem(name) {
    setToggleContent({
      ...toggleContent,
      showMainContent: false,
      [name]: true,
    });
  }

  function onClickCancelButton() {
    setToggleContent({
      ...toggleContent,
      showMainContent: true,
    });
  }

  // gets what inside the cargo
  async function getTrackingNumberInsideCargo(cargoNumber, pageNumber) {
    let dataToSend = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargoNumber.trim(),
      page: pageNumber,
    };

    let apiResponse = await axios.get(cargoApi.CARGOS, {
      params: dataToSend,
    });

    setCargoTrackingNumbers(apiResponse.data.cargos);
    let data = apiResponse.data;
    delete data.cargos;
    setTrackingNumberData(data);
  }

  function onGetCargoDetails() {
    const {
      match: { params },
    } = props;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      tracking_no: params.cargoNumber.trim(),
      scan_type: "sweep",
    };
    props.dispatch(cargoActions.getCargoDetails({ params: data }));
  }

  async function fetchShipmentTrackingNumber(shipmentNumber, page) {
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipmentNumber,
      page: page,
    };
    let apiResponse = await axios.get(cargoApi.CARGOS, {
      params: data,
    });
    let cargoNumbersList = [];
    apiResponse.data.cargos.forEach((cargoNumber) => {
      cargoNumbersList.push({
        tracking_no: cargoNumber.tracking_no,
        cargo_type: cargoNumber.cargo_type,
        dest_country_code: cargoNumber.dest_country_code,
        shipment_no: cargoNumber.shipment_no,
        shipment_weight: cargoNumber.shipment_weight,
        dest_poscode_prefix: cargoNumber.dest_poscode_prefix,
        type: getType(cargoNumber.tracking_no),
      });
    });
    setCargos(cargoNumbersList);
  }

  function getType(tracking_no) {
    return tracking_no.substr(0, 3) == "JNC" ? "Cargo" : "Parcel";
  }

  function getCargo() {
    const dummyObj = { tracking_no: "" };
    const selectedShipment = _.find(cargos, (x) => x.tracking_no === props.location.state.cargo.tracking_no);
    return selectedShipment ? selectedShipment : dummyObj;
  }

  function onClickRedirectToShipmentDetails() {
    setRedirectToShipmentDetailsPage(true);
  }
}

const mapStateToProps = (state) => ({
  shipmentNumbersList: state.shipment.shipmentNumbersList,
  partners: state.shipment.partners,
  cargoDetails: state.cargo.cargoDetails,
});

export default connect(mapStateToProps)(CargoDetailsPage);
