import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { cargoActions } from "../../../actions/rootActions";
import { Divider, Typography } from "antd";
import * as _ from "lodash";
import { CustomTable } from "../../../common/Table/Table";
import { formatCargoData } from "../../../utils/formatCargoData";

function InsideThisCargo(props) {
  const cargo = props.location.state.cargo;
  const [pageNumber, setPageNumber] = useState(1);
  const [cargoTracking, setCargoTracking] = useState(null);
  const [trackingNumberData, setTrackingNumberData] = useState(null);

  useEffect(() => {
    getTrackingNumberInsideCargo(cargo.tracking_no, pageNumber);
  }, []);

  useEffect(() => {
    if (props.cargoTrackingNumbers) {
      setCargoTracking(props.cargoTrackingNumbers);
    }
    if (props.trackingNumberData) {
      setTrackingNumberData(props.trackingNumberData);
    }
  }, [props.cargoTrackingNumbers, props.trackingNumberData]);

  const headers = [
    {
      title: (
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          Tracking Number
        </Typography.Text>
      ),
      dataIndex: "trackingNo",
      key: "trackingNo",
      width: 200,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
      render: (text) => {
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>;
      },
    },
    {
      title: (
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          Type
        </Typography.Text>
      ),
      dataIndex: "cargoType",
      key: "cargoType",
      width: 200,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
      render: (text) => {
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>;
      },
    },
    {
      title: (
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          Unresolved Exception(s)
        </Typography.Text>
      ),
      dataIndex: "isExceptionResolved",
      key: "isExceptionResolved",
      width: 200,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
      render: (text) => {
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>;
      },
    },
    {
      title: (
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          Exception Type
        </Typography.Text>
      ),
      dataIndex: "exceptionType",
      key: "exceptionType",
      width: 200,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
      render: (text) => {
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>;
      },
    },
    {
      title: (
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          Description
        </Typography.Text>
      ),
      dataIndex: "description",
      key: "description",
      width: 200,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
      render: (text) => {
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>;
      },
    },
    {
      title: (
        <Typography.Text ellipsis={true} style={{ width: "100%" }}>
          Number of Exceptions
        </Typography.Text>
      ),
      dataIndex: "numberOfExceptions",
      key: "numberOfExceptions",
      width: 200,
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
      render: (text) => {
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>{text}</div>;
      },
    },
  ];

  let tableData = null;
  if (cargoTracking && cargoTracking.length) {
    tableData = formatCargoData(cargoTracking);
    console.log("cargo tracking numbers", headers, tableData);
  }

  console.log(tableData, trackingNumberData);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0 w-100">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <p className="link-title mb-0" onClick={goBackToPreviousRoute}>
              Cargo
            </p>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">{cargo.tracking_no}</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black table-heading-margin">Inside This Cargo</h2>
            {tableData && tableData.length > 0 && (
              <CustomTable
                headers={headers}
                defaultPageSize={50}
                onPageChange={onPageChange}
                total={cargoTracking.total_records}
                current={pageNumber}
                tableData={tableData}
                rowKey="trackingNo"
              />
            )}
            <Divider className="gray-divider" />
            <button className="secondary-button-small full-button-width" onClick={goBackToPreviousRoute}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  function onPageChange(page) {
    setPageNumber(page);
    getTrackingNumberInsideCargo(cargo.tracking_no, page);
  }

  // gets what inside the cargo
  async function getTrackingNumberInsideCargo(cargoNumber, pageNumber) {
    let dataToSend = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargoNumber.trim(),
      page: pageNumber,
    };
    props.dispatch(cargoActions.getTrackingNumberInsideCargo(dataToSend));
  }
}

const mapStateToProps = (state) => ({
  trackingNumberData: state.cargo.trackingNumbersInsideCargoData,
  cargoTrackingNumbers: state.cargo.trackingNumbersInsideCargo,
});

export default connect(mapStateToProps)(InsideThisCargo);
