import React from 'react';
import { Alert, Form, Select } from 'antd';

const { Option } = Select;

export default function CreateCargo(props) {
  let {
    countriesList,
    newShipmentNo, 
    createCargoErr, 
    createCargoRef,
    cargoType,
    selectedRecipientName, 
    showDestinationCountry,
    destCountryCode,
    showDestinationPostalCode, 
    destPostCodePrefix, 
    isDeminimis,
    partnersForHub,
    lastMileRecipientId
  } = props;

  return (
    <div className="content-container card mt-2 mb-1 p-2 cnt-2">
      <div className="d-flex flex-column mb-2">
        <div className="d-flex flex-column align-items-center mt-2">
          <h4 className="create-cargo-label">Create Cargo</h4>
          <h5 className="create-cargo-label">Shipment to {selectedRecipientName}</h5>
          <h5 className="create-cargo-label">{newShipmentNo}</h5>
        </div>
        <div className="d-flex flex-column mt-4 py-2 px-4">
          <Form ref={createCargoRef} name="control-ref" onFinish={props.createCargoFn}>
            <div className="d-flex flex-column">
              <Form.Item
                name="cargoType"
                label=""
              >
                <label className="create-cargo-dropdown-label">Cargo Type</label>
                <Select onChange={props.handleCargoTypeChange} value={cargoType}>
                  <Option value="bag">Bag</Option>
                  <Option value="carton">Carton</Option>
                  <Option value="pallet">Pallet</Option>
                  <Option value="other">Other</Option>
                </Select>
                {createCargoErr && createCargoErr['cargo_type'] && (
                  <div className="help-block">{createCargoErr.cargo_type[0]}</div>
                )}
              </Form.Item>
            </div>
            {showDestinationCountry && (
              <div className="d-flex flex-column mt-4">
                <Form.Item
                  name="cargoType"
                  label=""
                >
                  <label className="create-cargo-dropdown-label">
                    Destination Country
                  </label>
                  <Select 
                    showSearch
                    value={destCountryCode}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } 
                    onChange={props.handleDestinationChange}>
                    {
                      countriesList.map((country, index) => {
                        return (
                          <Option key={index} value={country.code}>
                            {country.name}
                          </Option>
                        )
                      })
                    }
                  </Select>
                  {createCargoErr && createCargoErr['dest_country_code'] && (
                    <div className="help-block">{createCargoErr.dest_country_code[0]}</div>
                  )}
                </Form.Item>
              </div>
            )}
            {showDestinationPostalCode && (
              <div className="d-flex flex-column">
                <div
                  className={
                   "form-group mt-4 p-0" +
                    (createCargoErr  && 
                      createCargoErr['dest_poscode_prefix']
                      ? " has-error"
                      : "")
                  }
                >
                <Form.Item name="destination-postal-code">
                  <label
                    htmlFor="destination-postal-code"
                    className="create-cargo-dropdown-label"
                  >
                    Destination Postal Code (Prefix)
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="destination-postal-code"
                    onChange={props.handlePostalCodeChange}
                    value={destPostCodePrefix}
                  />
                  {createCargoErr && createCargoErr['dest_poscode_prefix'] && (
                    <div className="help-block">{createCargoErr.dest_poscode_prefix[0]}</div>
                  )}
                </Form.Item>
              </div>
              <div className="d-flex flex-column align-items-start mt-2">
                <Form.Item name="deminimis-parcel">
                  <label
                    className="create-cargo-dropdown-label"
                    htmlFor="deminimis-parcel"
                  >
                    Deminimis Parcels
                  </label><br />
                  <input 
                    type="checkbox" 
                    name="is_deminimis" 
                    id="deminimis-parcel"  
                    onChange={props.handleIsDeminimisChange} 
                    checked={isDeminimis}
                  />
                </Form.Item>
              </div>
              {partnersForHub && partnersForHub.length > 0 && 
                <div className="form-group mt-2 p-0 mb-4">
                  <Form.Item name="destination-postal-code">
                    <label
                      htmlFor="destination-postal-code"
                      className="create-cargo-dropdown-label"
                    >
                      Destination Hub LM Network Partner
                    </label>
                    <Select
                      showSearch
                      value={lastMileRecipientId}
                      filterOption={(input, option) => 
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={props.handleLastMileRecipientChange}>
                        {
                          partnersForHub.map((partner, index) => {
                            return (
                              <Option key={index} value={partner.id}>
                                {partner.name}
                              </Option>
                            )
                          })
                        }
                    </Select>
                    {createCargoErr && createCargoErr['lm_recipient_id'] && (
                      <div className="help-block">{createCargoErr.lm_recipient_id[0]}</div>
                    )}
                  </Form.Item>
                </div>
              }
            </div>
            )}
            {showDestinationPostalCode && (
              <div className="d-flex justify-content-end">
                <button
                  className="btn create-shipment-button"
                 type="submit"
                >
                  Confirm
                </button>
              </div>
            )}
          </Form>
          {createCargoErr && createCargoErr.shipment_tracking_no &&
            <Alert message={createCargoErr.shipment_tracking_no[0]} type="error" closable onClose={props.closeCreateCargoError} className="mt-3 ml-2 mr-2" />
          }
        </div>
      </div>
    </div>
  );
};