import { authTypes } from "../types";

export default function (
  state = {
    sessionIdError: null,
    error: null,
    authenticated: null,
    user: null,
    sessionId: null,
  },
  action
) {
  switch (action.type) {
    case authTypes.AUTH_USER:
      return { ...state, error: null, authenticated: true };
    case authTypes.UNAUTH_USER:
      return { authenticated: false };
    case authTypes.AUTH_ERROR:
      return { ...state, error: true };
    case authTypes.FETCH_SIGNED_IN_USER:
      return { ...state, user: action.payload };
    case authTypes.CLEAR_AUTH_ERRORS:
      return { ...state, error: null };
    case authTypes.GET_SESSION_ID_SUCCESS:
      return { ...state, sessionId: action.payload.session_id, sessionIdError: null };
    case authTypes.GET_SESSION_ID_ERROR:
      return { ...state, sessionIdError: "Error Creating Session" };
    default:
      return state;
  }
}
