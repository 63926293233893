import React, { useState, useEffect } from "react";
import { Timeline, Divider } from "antd";
import { connect } from "react-redux";
import BreadCrumb from "../commonComponents/BreadCrumb";

function TimelineComponent(props) {
  const { orderData, latestScannedNumber } = props;

  const [privateStatuses, setPrivateStatuses] = useState(getSortedPrivateStatuses(orderData));

  useEffect(() => {
    if (latestScannedNumber) {
      setPrivateStatuses(getSortedPrivateStatuses(orderData));
    }
  }, [latestScannedNumber]);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="d-flex justify-content-center">
      <Timeline className="hms-scanner-timeline">
        {privateStatuses &&
          privateStatuses.map((privateStatus, index) => {
            const date = new Date(privateStatus.updated_on);
            return (
              <Timeline.Item className="hms-scanner-timeline-item" color={index === 0 ? "blue" : "gray"}>
                {privateStatus.status}
                <br />
                {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()} at ${getFormatTime(date)}`}
                <p>{privateStatus.main_text}</p>
              </Timeline.Item>
            );
          })}
      </Timeline>
    </div>
  );

  function getFormatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  function getSortedPrivateStatuses(orderData) {
    if (!orderData.privateStatuses) {
      return null;
    } else {
      let privateStatuses = [];
      for (let x of orderData.privateStatuses) {
        for (let y of x.updates) {
          privateStatuses.push(y);
        }
      }
      privateStatuses.sort(function (a, b) {
        return new Date(b.updated_on) - new Date(a.updated_on);
      });
      return privateStatuses;
    }
  }
}

const mapStateToProps = ({ parcel }) => ({
  orderData: parcel.parcelOrderData,
  latestScannedNumber: parcel.latestScannedNumber,
});

export default connect(mapStateToProps, null)(TimelineComponent);
