import React, { useState } from "react";
import Sidebar from "../sidebar/sidebar";
import logo from "../../images/old-icons/janio_logo.png";
import hamburger from "../../images/hamburger.png";
import "./style.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Nav = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <React.Fragment>
      <div className="top-nav d-flex align-items-center fixed-top">
        <FontAwesomeIcon className="text-white pointer ml-n4" size="lg" icon={faBars} onClick={toggleSidebar} />
        {/*<i className="fa fa-bars text-white fa-lg pointer ml-n4" onClick={toggleSidebar}></i>*/}
        <div className="logo_container w-100 justify-content-sm-start justify-content-center">
          <img src={logo} alt="janio logo" />
        </div>
        <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />
      </div>
      {props.children}
    </React.Fragment>
  );
};

export default Nav;

