export function errorFormatter(err) {
  const errors = [];
  function error(data, dataKey) {
    if (typeof data === "string" || data instanceof String) {
      errors.push(`${dataKey} : ${data}`);
      return;
    }
    if (Array.isArray(data)) {
      data.forEach((d) => {
        error(d, dataKey);
      });
    } else {
      for (let key in data) {
        error(data[key], key);
      }
    }
  }
  error(err);
  return errors;
}
