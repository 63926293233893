import * as React from 'react'
import { Route } from 'react-router-dom';
import { MixpanelConsumer} from "react-mixpanel";

export default function MixPanelRoute(props) {
  const { component: Component, ...rest } = props;
  return (
    <Route {...rest}>
      <MixpanelConsumer>
        {mixpanel => <Component {...props} mixpanel={mixpanel} />}
      </MixpanelConsumer>
    </Route>
  )
}