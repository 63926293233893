import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../Header';
import PickupDeliverAddresses from './PickupDeliverAddresses';

export default function HMSlite() {
  return (
    <Header>
      <Switch>
        <Route path="/firstmile/pickup-deliver-addresses" component={PickupDeliverAddresses} />
      </Switch>
    </Header>
  )
}