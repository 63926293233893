import React, { Component } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";

export default class ZebraScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      decodeResult: "",
      decodeError: "",
    };
  }

  componentDidMount() {
    //let selectedDeviceId;
    this.codeReader = new BrowserMultiFormatReader();
    this.codeReader
      .listVideoInputDevices()
      .then((videoInputDevices) => {
        console.log("videoInputDevices", videoInputDevices);
        //selectedDeviceId = videoInputDevices[0].deviceId;
        this.codeReader.decodeFromVideoDevice(undefined, "video", (result, err) => {
          if (result) {
            this.throttle(this.props.onScanComplete(result), 5000);
          }
          if (err) {
            this.setState({ decodeError: err });
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  componentWillUnmount() {
    if (this.codeReader) {
      this.codeReader.reset();
    }
  }

  render() {
    return <video className="zebra-video" id="video"></video>;
  }
}
