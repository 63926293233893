import React, { useState, useEffect } from "react";
import { Divider, Checkbox } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { parcelActions, commonActions } from "../../../actions/rootActions";
import { Prompt } from "react-router";
import Select from "../../../common/select/select";
import BreadCrumb from "../commonComponents/BreadCrumb";
import * as _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

function Settings(props) {
  const { recipientsList, scanSettingsData } = props;

  const scanType = props.location.state.scanType;
  const pageName = props.location.state.pageName;
  const route = props.location.state.route;

  const [copyInProgress, setCopyInProgress] = useState(false);

  const [scanSettings, setScanSettings] = useState(scanSettingsData);
  const [showPromptDialog, setShowPromptDialog] = useState(false);

  useEffect(() => {
    if (_.isEmpty(recipientsList)) {
      props.dispatch(commonActions.getRecipientsList());
    }
  }, []);

  return (
    <React.Fragment>
      <Prompt when={showPrompt()} message="Changes you made may not be saved." />
      <div className="parcel-bg-container">
        <div className="d-flex flex-column align-items-center">
          <div className="bg-container mt-0 w-100">
            <BreadCrumb pageName={pageName} scanType={scanType} route={route} />
            <div className="bg-container-body">
              <div className="d-flex flex-column">
                <h2 className="text-heading-black margin-bottom-20">Scan Settings</h2>
                {(isWarehouseOutbound() || isWarehouseInbound()) && (
                  <React.Fragment>
                    <span className="text-span-black-bold mb-3">
                      {isWarehouseOutbound() && "Inbound + Outbound"}
                      {isWarehouseInbound() && "Outbound + Inbound"}
                    </span>
                    <Checkbox
                      className="mb-4"
                      checked={scanSettings.automaticallyInbound}
                      onChange={(e) => onScanSettingsChange("automaticallyInbound", e.target.checked)}
                    >
                      <span className="standard-font-lite">
                        {isWarehouseOutbound() && "Automatically inbound and outbound if package is not yet inbounded"}
                        {isWarehouseInbound() && "Automatically outbound and inbound if package is not yet outbounded"}
                      </span>
                    </Checkbox>
                    <span className="text-span-black-bold mb-3">
                      {isWarehouseOutbound() && "Handover + Outbound"}
                      {isWarehouseInbound() && "Handover + Inbound"}
                    </span>
                    <Checkbox
                      checked={scanSettings.autoHandover}
                      onChange={(e) => onScanSettingsChange("autoHandover", e.target.checked)}
                    >
                      <span className="standard-font-lite">
                        {isWarehouseOutbound() &&
                          "Automatically scan handover outbound and warehouse processed if package is not yet outbounded"}
                        {isWarehouseInbound() &&
                          "Automatically scan handover inbound and warehouse processing if package is not yet inbounded"}
                      </span>
                    </Checkbox>
                    <Divider className="gray-divider-20" />
                  </React.Fragment>
                )}
                {isWarehouseOutbound() && (
                  <React.Fragment>
                    <span className="text-span-black-bold margin-bottom-20">Send Scan Data to Network Partner</span>
                    <Select
                      label="Tracking codes that are outbound scanned will be sent to a network partner you select here."
                      placeholder="Select Network Partner Code"
                      options={recipientsList}
                      value={scanSettings.recipientId}
                      name="recipientId"
                      onChange={(value) => onScanSettingsChange("recipientId", value)}
                      disabled={scanSettings.recipientId == null && !_.isEmpty(scanSettings.groupId)}
                      addEmptyOptionLabel="Please select Network Partner"
                      addEmptyOption
                    />
                    <Divider className="gray-divider-20" />
                  </React.Fragment>
                )}
                {showTagContent() && (
                  <div className="d-flex flex-column">
                    <span className="tag-heading">
                      Tag Scanned Parcel <FontAwesomeIcon icon={faQuestionCircle} className="tag-help" />
                      {/* <i className="fa fa-question-circle tag-help" aria-hidden="true"></i> */}
                    </span>
                    <p className="scan-setting-details mb-0">
                      When scanned, tracking numbers are tagged, allowing you to{" "}
                      <span>download/print a DO or manifest</span> for this batch of shipments.
                    </p>
                    <div className="d-flex tag-container flex-nowrap d-flex col-md-4 pl-0 pr-0">
                      <input
                        className="form-control scan-input border-right-rad-0"
                        placeholder="Add a tag here"
                        value={scanSettings.groupId}
                        onChange={(e) => onScanSettingsChange("groupId", e.target.value)}
                        disabled={scanSettings.recipientId !== null && _.isEmpty(scanSettings.groupId)}
                      />
                      <CopyToClipboard text={scanSettings.groupId} onCopy={onCopyGroupId}>
                        <button className="tag-button border-left-rad-0">{copyInProgress ? "Copied!" : "Copy"}</button>
                      </CopyToClipboard>
                    </div>
                    <Divider className="gray-divider-20" />
                  </div>
                )}
                <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                  <button className="secondary-button-small" onClick={() => props.history.goBack()}>
                    Back
                  </button>
                  <button className="primary-button mb-sm-0 mb-3" onClick={saveScanSettings}>
                    Save and Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  function showPrompt() {
    if (showPromptDialog) {
      return true;
    }
    return false;
  }

  function isWarehouseOutbound() {
    if (scanType === "warehouse outbound") {
      return true;
    }
    return false;
  }

  function isWarehouseInbound() {
    if (scanType === "warehouse inbound") {
      return true;
    }
    return false;
  }

  function onScanSettingsChange(name, value) {
    setScanSettings({
      ...scanSettings,
      [name]: value,
    });
    setShowPromptDialog(true);
  }

  function showTagContent() {
    return true;
    // if (
    //   scanType === "sweep" ||
    //   scanType === "freight forwarded inbound" ||
    //   scanType === "returns inbound" ||
    //   scanType === "warehouse inbound" ||
    //   scanType === "warehouse outbound" ||
    //   scanType === "check"
    // ) {
    //   return true;
    // }
    // return false;
  }

  function saveScanSettings() {
    setShowPromptDialog(false);
    props.dispatch(parcelActions.onSaveScanSettings(scanSettings));
    props.history.goBack();
  }

  function onCopyGroupId() {
    setCopyInProgress(true);
    setTimeout(() => setCopyInProgress(false), 2000);
  }
}

const mapStateToProps = ({ parcel, common }) => ({
  scanSettingsData: parcel.scanSettingsData,
  recipientsList: common.recipientsList,
});

export default connect(mapStateToProps, null)(Settings);
