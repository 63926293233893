// @ts-nocheck
import React, { useState } from "react";
import { Divider, Checkbox, Alert } from "antd";
import { sha256 } from "js-sha256";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";
import { LoadingOutlined } from "@ant-design/icons";
import { QZ_TRAY_CERTIFICATE, QZ_TRAY_PRIVATE_KEY } from "../../QzTrayCredentials";
import RadioGroup from "../../../common/radioGroup/radioGroup";
import Select from "../../../common/select/select";
import * as qz from "qz-tray";
import {
  SETTINGS_CHANGED_SUCCESS,
  DEFAULT_SCANNER,
  DEFAULT_AUDIO_TYPE,
  AUTO_PRINT_LABEL,
  SHOW_PRINT_LABEL,
  SETTINGS,
  SUCCESS,
  REQUIRE_ERROR_ACKNOWLEDGEMENT,
  DEFAULT_PRINTER,
  QZ_TRAY_CONNECTION,
  ERROR,
} from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { Prompt } from "react-router";

const RSVP = require("rsvp");

export default function Settings() {
  const [state, setState] = useState({
    scanUsing: getStateValue("defaultScanner", "Laser"),
    audioAlert: getStateValue("defaultAudioType", "Country"),
    errorAcknowledgement: getStateValue("requireErrorAcknowledgment", "Enabled"),
    autoPrintLabel: getCheckBoxValue("autoPrintLabel", false),
    showPrintLabel: getCheckBoxValue("showPrintLabel", false),
    downloadLabel: getStateValue("downloadLabel", "Manual"),
    highValueChecked: getStateValue("showHighValueHighlight", null),
    dduChecked: getStateValue("showDDUHighlight", null),
  });

  const [defaultPrinter, setDefaultPrinter] = useState(getStateValue("defaultPrinter", ""));
  const [printersList, setPrintersList] = useState(getPrintersListValue("printersList", []));

  const [qzTrayConnectSuccess, setQzTrayConnectSuccess] = useState(false);
  const [qzTrayConnectError, setQzTrayConnectError] = useState(false);
  const [settingsSaveSuccess, setSettingsSaveSuccess] = useState(false);
  const [showPromptDialog, setShowPromptDialog] = useState(true);
  const [connectionInProgress, setConnectionInProgress] = useState(false);

  const scanUsingOptions = [
    { value: "Laser", label: "Laser" },
    { value: "Camera", label: "Camera" },
  ];
  const audioAlertOptions = [
    { value: "Country", label: "Country" },
    { value: "Postal Prefix", label: "Postal Prefix" },
    { value: "Partner Name", label: "Partner Name" },
    { value: "No. of Parcels Scanned", label: "No. of Parcels Scanned" },
  ];
  const errorAcknowledgementOptions = [
    { value: "Enabled", label: "Enabled" },
    { value: "Disabled", label: "Disabled" },
  ];
  const downloadLabelOptions = [
    { value: "Manual", label: "Manual" },
    {
      value: "Automatic",
      label: "Automatic (only works on some browsers)",
    },
  ];

  const printerClass = qz.websocket.isActive() ? "active-printer" : "";
  const qzTrayConnectedContainerClass = qz.websocket.isActive() ? "" : "d-none";
  const qzTrayConnectButtonClass = qz.websocket.isActive() ? "d-none" : "";

  return (
    <React.Fragment>
      <Prompt when={showPrompt()} message="Changes you made may not be saved." />
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <p className="title mb-0">Settings</p>
          </div>
          <div className="bg-container-body">
            <div className="d-flex flex-column">
              <span className="text-span-black-bold margin-bottom-20 mb-2">Alerts(s) to Highlight in Parcel Scan</span>
              <Checkbox checked={state.highValueChecked} onChange={onHighValueChange}>
                High Value
              </Checkbox>
              <Checkbox checked={state.dduChecked} onChange={onDDUChange} style={{ marginLeft: "unset" }}>
                DDU
              </Checkbox>
              <Divider className="gray-divider-20" />
            </div>
            <div className="d-flex flex-column">
              <span className="text-span-black-bold">Scan Using</span>
              <div className="margin-top-20">
                <RadioGroup
                  className="blackRadioGroup"
                  value={state.scanUsing}
                  options={scanUsingOptions}
                  onChange={(e) => onStateChange("scanUsing", e.target.value)}
                />
              </div>
              <Divider className="gray-divider-20" />
              <span className="text-span-black-bold">Audio Alert</span>
              <div className="margin-top-20">
                <RadioGroup
                  className="blackRadioGroup"
                  value={state.audioAlert}
                  options={audioAlertOptions}
                  onChange={(e) => onStateChange("audioAlert", e.target.value)}
                />
              </div>
              <Divider className="gray-divider-20" />
              <span className="text-span-black-bold">Require Error Acknowledgement?</span>
              <div className="margin-top-20">
                <RadioGroup
                  className="blackRadioGroup"
                  value={state.errorAcknowledgement}
                  options={errorAcknowledgementOptions}
                  onChange={(e) => onStateChange("errorAcknowledgement", e.target.value)}
                />
              </div>
              <Divider className="gray-divider-20" />
              <span className="text-span-black-bold">Download Label</span>
              <div className="margin-top-20">
                <RadioGroup
                  className="blackRadioGroup"
                  value={state.downloadLabel}
                  options={downloadLabelOptions}
                  onChange={(e) => onStateChange("downloadLabel", e.target.value)}
                />
              </div>
              <Divider className="gray-divider-20" />
              <span className="text-span-black-bold">Print</span>
              <div className={"gray-bg padding-20 margin-top-20 " + qzTrayConnectedContainerClass}>
                <span className="text-span-black-bold">Current Printer: {defaultPrinter}</span>
                <div className="margin-top-20">
                  <Select
                    label="Available Printers"
                    placeholder="Please select printer"
                    options={printersList}
                    value={defaultPrinter}
                    name="defaultPrinter"
                    onChange={onChangeDefaultPrinter}
                  />
                </div>
                <button
                  className="disconnect-qz-tray-button margin-top-20 full-button-width"
                  onClick={disconnectQzTray}
                >
                  Disconnect
                </button>
              </div>
              <div className={qzTrayConnectButtonClass}>
                <button className="secondary-button margin-top-20 full-button-width" onClick={connectQzTray}>
                  {connectionInProgress ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-blue" />
                  ) : qz.websocket.isActive() ? (
                    "Connected"
                  ) : (
                    "Connect to QZ Tray"
                  )}
                </button>
              </div>
              {qzTrayConnectSuccess && (
                <div className="mt-3">
                  <Alert message="QZ Tray Connected" type="success" />
                </div>
              )}
              {qzTrayConnectError && (
                <div className="mt-3">
                  <Alert message={getQzErrorMessage()} type="error" />
                </div>
              )}
              <Checkbox
                className="margin-top-20 blackCheckBox"
                checked={state.autoPrintLabel}
                disabled={!qz.websocket.isActive()}
                onChange={(checked) => onStateChange("autoPrintLabel", checked.target.checked)}
              >
                <span className="fnt-16">Automatically Print Label</span>
                <span className={`${printerClass} fnt-16`}>
                  {qz.websocket.isActive() ? " (Printer Active)" : " (Printer Inactive)"}
                </span>
              </Checkbox>
              <Checkbox
                className="ml-0 blackCheckBox"
                checked={state.showPrintLabel}
                disabled={!qz.websocket.isActive()}
                onChange={(checked) => onStateChange("showPrintLabel", checked.target.checked)}
              >
                <span className="fnt-16">Show Print Label button for scanned tracking number</span>
              </Checkbox>
              <Divider className="gray-divider-20" />
            </div>
            <button className="primary-button full-button-width" onClick={saveSettings}>
              Save and Continue
            </button>
            {settingsSaveSuccess && (
              <div className="mt-3">
                <Alert message="Settings saved successfully" type="success" />
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  function showPrompt() {
    if (showPromptDialog) {
      return true;
    }
    return false;
  }

  function onChangeDefaultPrinter(value) {
    setDefaultPrinter(value);
    localStorage.setItem("defaultPrinter", value);
  }

  function getStateValue(localStorageName, defaultValue) {
    const localStorageValue = localStorage.getItem([localStorageName]);
    if (localStorageValue === null) {
      return defaultValue;
    }
    return localStorageValue;
  }

  function getPrintersListValue(localStorageName, defaultValue) {
    let localStorageValue = localStorage.getItem([localStorageName]);
    if (localStorageValue === null) {
      return defaultValue;
    }
    localStorageValue = JSON.parse(localStorageValue);
    return localStorageValue;
  }

  function getCheckBoxValue(localStorageName, defaultValue) {
    let localStorageValue = localStorage.getItem([localStorageName]);
    if (localStorageValue === null || !qz.websocket.isActive()) {
      return defaultValue;
    }
    if (localStorageValue === "false") {
      return false;
    }
    return true;
  }

  function onStateChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
  }

  function saveSettings(e) {
    e.preventDefault();
    localStorage.removeItem("defaultScanner");
    localStorage.removeItem("defaultAudioType");
    localStorage.removeItem("requireErrorAcknowledgment");
    localStorage.removeItem("autoPrintLabel");
    localStorage.removeItem("showPrintLabel");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("showHighValueHighlight");
    localStorage.removeItem("showDDUHighlight");
    localStorage.setItem("defaultScanner", state.scanUsing);
    localStorage.setItem("defaultAudioType", state.audioAlert);
    localStorage.setItem("requireErrorAcknowledgment", state.errorAcknowledgement);
    localStorage.setItem("autoPrintLabel", state.autoPrintLabel);
    localStorage.setItem("downloadLabel", state.downloadLabel);
    localStorage.setItem("showPrintLabel", state.showPrintLabel);
    localStorage.setItem("defaultPrinter", defaultPrinter);
    if (state.highValueChecked) {
      localStorage.setItem("showHighValueHighlight", state.highValueChecked);
    }
    if (state.dduChecked) {
      localStorage.setItem("showDDUHighlight", state.dduChecked);
    }
    setSettingsSaveSuccess(true);
    setShowPromptDialog(false);

    const mixPanelData = getMixpanelData();
    mixPanelTrack(SETTINGS_CHANGED_SUCCESS, {
      [SUCCESS]: true,
      ...mixPanelData,
      [SETTINGS]: {
        [DEFAULT_SCANNER]: state.scanUsing,
        [DEFAULT_AUDIO_TYPE]: state.audioAlert,
        [REQUIRE_ERROR_ACKNOWLEDGEMENT]: state.errorAcknowledgement,
        [AUTO_PRINT_LABEL]: state.autoPrintLabel,
        [SHOW_PRINT_LABEL]: state.showPrintLabel,
        [DEFAULT_PRINTER]: defaultPrinter,
      },
    });
    setTimeout(() => {
      setSettingsSaveSuccess(false);
      mixPanelTrack(SETTINGS_CHANGED_SUCCESS, {
        [SUCCESS]: false,
        ...mixPanelData,
        [SETTINGS]: {
          [DEFAULT_SCANNER]: state.scanUsing,
          [DEFAULT_AUDIO_TYPE]: state.audioAlert,
          [REQUIRE_ERROR_ACKNOWLEDGEMENT]: state.errorAcknowledgement,
          [AUTO_PRINT_LABEL]: state.autoPrintLabel,
          [SHOW_PRINT_LABEL]: state.showPrintLabel,
          [DEFAULT_PRINTER]: defaultPrinter,
        },
      });
    }, 2500);
  }

  function getQzErrorMessage() {
    if (qzTrayConnectError === "Unable to establish connection with QZ") {
      return (
        <React.Fragment>
          <span>Unable to establish connection with QZ Tray - Please download and install it </span>
          <a
            href="https://drive.google.com/file/d/1y4okKFUJ0k1gqhGOZcGrNhUoGBNMaPJ0/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
        </React.Fragment>
      );
    }
    return qzTrayConnectError;
  }

  function connectQzTray(e) {
    e.preventDefault();
    setConnectionInProgress(true);
    qz.security.setCertificatePromise(function (resolve, reject) {
      resolve(QZ_TRAY_CERTIFICATE);
    });

    var privateKey = QZ_TRAY_PRIVATE_KEY;

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        try {
          var pk = KEYUTIL.getKey(privateKey);
          var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          var hex = sig.sign();
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });

    qz.api.setSha256Type((data) => sha256(data));
    qz.api.setPromiseType((resolver) => new Promise(resolver));
    qz.websocket
      .connect()
      .then(() => {
        setQzTrayConnectSuccess(true);
        localStorage.setItem("isQzConnected", true);
        setTimeout(() => {
          setQzTrayConnectSuccess(false);
        }, 5000);
        qz.printers
          .getDefault()
          .then((defaultPrinter) => {
            setDefaultPrinter(defaultPrinter);
            localStorage.setItem("defaultPrinter", defaultPrinter);

            qz.printers
              .find()
              .then((printersList) => {
                let customizedPrintersList = getPrintersList(printersList);
                setPrintersList(customizedPrintersList);
                let stringifyPrintersList = JSON.stringify(printersList);
                localStorage.setItem("printersList", stringifyPrintersList);
                const mixpanelData = getMixpanelData();
                mixPanelTrack(QZ_TRAY_CONNECTION, {
                  [SUCCESS]: true,
                  [DEFAULT_PRINTER]: defaultPrinter,
                  ...mixpanelData,
                });
              })
              .catch((e) => console.log("Error", e));
          })
          .catch((e) => console.log("Error", e));
      })
      .catch((e) => {
        setQzTrayConnectError(e.message);
        setTimeout(() => {
          setQzTrayConnectError(false);
        }, 10000);
      });
    setTimeout(() => {
      setConnectionInProgress(false);
    }, 3000);
  }

  function getPrintersList(printersList) {
    let customizedPrintersList = printersList.map((printer) => {
      let customObj = {};
      customObj.label = printer;
      customObj.value = printer;
      return customObj;
    });
    return customizedPrintersList;
  }

  // disconnect qz tray
  function disconnectQzTray(e) {
    e.preventDefault();
    qz.api.setPromiseType(function promise(resolver) {
      return new RSVP.Promise(resolver);
    });
    qz.websocket
      .disconnect()
      .then(() => {
        localStorage.removeItem("defaultPrinter");
        localStorage.removeItem("printersList");
        localStorage.removeItem("isQzConnected");
        localStorage.removeItem("showPrintLabel");
        localStorage.removeItem("autoPrintLabel");
        setPrintersList([]);
        setDefaultPrinter("");
        setState({
          ...state,
          autoPrintLabel: false,
          showPrintLabel: false,
        });
      })
      .catch((e) => console.log("Error", e));
  }

  function onHighValueChange() {
    if (state.highValueChecked) {
      setState({
        ...state,
        highValueChecked: null,
      });
    } else {
      setState({
        ...state,
        highValueChecked: true,
      });
    }
  }

  function onDDUChange() {
    if (state.dduChecked) {
      setState({
        ...state,
        dduChecked: null,
      });
    } else {
      setState({
        ...state,
        dduChecked: true,
      });
    }
  }
}
