import { picklistTypes } from "../types";

export default function (
  state = {
    picklistData: null,
    picklistDataError: null,
    picklistDataDownloadError: null,
    picklistActionRequestCount: null,
    picklistActionRequestCountError: null,
  },
  action
) {
  switch (action.type) {
    case picklistTypes.GET_PICK_LIST_DATA_SUCCESS:
      return { ...state, picklistData: action.payload };
    case picklistTypes.GET_PICK_LIST_DATA_ERROR:
      return { ...state, picklistData: null, picklistDataError: "An error occured" };
    case picklistTypes.ADD_DOWNLOAD_PICK_LIST_DATA_ERROR:
      return { ...state, picklistDataDownloadError: true };
    case picklistTypes.REMOVE_DOWNLOAD_PICK_LIST_DATA_ERROR:
      return { ...state, picklistDataDownloadError: false };
    case picklistTypes.GET_ACTION_REQUEST_COUNT_SUCCESS:
      return { ...state, picklistActionRequestCount: action.payload };
    case picklistTypes.GET_ACTION_REQUEST_COUNT_ERROR:
      return { ...state, picklistActionRequestCountError: action.payload };
    default:
      return state;
  }
}
