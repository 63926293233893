import RemedyAllExceptions from "./RemedyAllExceptions";
import React from "react";
import ActionItemsList from "./ActionItemsList";
import PackageDetails from "./PackageDetails";
import RemedyExceptions from "./RemedyExceptions";
import NotificationArea from "./NotificationArea";
import { LinkContainer } from "react-router-bootstrap";
import TimelineSweep from "./TimelineSweep";

export function mainContent(
  locationScanType,
  networkPartner,
  scanType,
  latestScannedNumber,
  orderData,
  scannerInformation,
  downloadExceptionImage,
  fetchOrderInfo
) {
  const showPackageDetails = scanType === "sweep" && latestScannedNumber !== null;

  return (
    <React.Fragment>
      <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
        <LinkContainer to="/scan/hubmanage/parcel-scan">
          <p className="link-title mb-0">Parcel Scan</p>
        </LinkContainer>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="title text-capitalize mb-0">
          {locationScanType === "warehouse inbound"
            ? "wh processing"
            : locationScanType === "warehouse outbound"
            ? "wh processed"
            : locationScanType}
          {networkPartner ? `(${networkPartner})` : null}
        </p>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <NotificationArea />
          {showPackageDetails && <TimelineSweep />}
          {showPackageDetails && <PackageDetails />}
          <RemedyAllExceptions fetchOrderInfo={fetchOrderInfo} scanType={scanType} />
          <RemedyExceptions
            scannerInformation={scannerInformation}
            latestScannedNumber={latestScannedNumber}
            scanType={scanType}
            orderData={orderData}
            downloadExceptionImage={downloadExceptionImage}
            fetchOrderInfo={fetchOrderInfo}
          />
        </div>
        <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">
          <ActionItemsList />
        </div>
      </div>
    </React.Fragment>
  );
}
