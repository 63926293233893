import axios from "axios";
import { parcelServices } from "../services";
import { parcelTypes, commonTypes } from "../types";
import * as _ from "lodash";
import { getMixpanelData, mixPanelTrack } from "../utils/mixpanelUtils";
import { PAYLOAD, SUCCESS, ADD_LOCATION, ERROR, ADD_RESOURCE } from "../constants/mixpanelConstants";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const defaultToken = localStorage.getItem("token");

if (defaultToken) {
  axios.defaults.headers.common["Authorization"] = "Token " + defaultToken;
}

const checkHighValueError = (binsData) => {
  let bins = computeBins(binsData);
  // let highValueBins = _.find(bins, (x) => x.name && x.name.includes("HIGH VALUE"));
  let highValueBins = bins.some((x) => x.type && x.type === "HIGHVALUE");
  if (highValueBins) {
    return true;
  }
  return false;
};

const getPartnerAssigned = (partnerAssigned) => {
  let partner = partnerAssigned[0].replace(/'/g, '"');
  partner = JSON.parse(partner);
  return partner[0];
};

const computeBins = (binsData) => {
  let bins = binsData.replace(/'/g, '"');
  bins = JSON.parse(bins);

  let binsToReturn = [];
  binsToReturn.push({
    // name: successNotificationData.totalOrderValue,
    background_colour: "#E5E5E5",
    text_colour: "#000000",
  });

  bins.forEach((bin) => {
    let binObj = {};
    binObj.name = bin.name;
    binObj.type = bin.type;
    binObj.background_colour = bin.background_colour;
    binObj.text_colour = bin.text_colour;

    if (bin.name === "DEMINIMIS") {
      // binObj.name = successNotificationData.totalOrderValue;
      binsToReturn[0] = binObj;
    } else {
      binsToReturn.push(binObj);
    }
  });
  return binsToReturn;
};

const getSortedExceptions = (exceptions) => {
  const sortedExceptions = _.orderBy(exceptions, ["created_on"], ["desc"]);
  return sortedExceptions;
};

// get order details
const onFetchParcelInfo = (params, exceptionTypes, autoAdd, scannedNumbersList) => {
  const loading = () => ({ type: parcelTypes.FETCH_PARCEL_INFO_LOADING });
  const success = (payload) => ({
    type: parcelTypes.FETCH_PARCEL_INFO_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.FETCH_PARCEL_INFO_FAILURE,
    payload,
  });
  const saveParcelScanExceptions = (payload) => ({
    type: parcelTypes.SAVE_PARCEL_SCAN_EXCEPTION,
    payload,
  });
  const clearParcelScanExceptions = (payload) => ({
    type: parcelTypes.CLEAR_PARCEL_SCAN_EXCEPTION,
    payload,
  });

  const saveParcelData = (payload) => ({
    type: parcelTypes.SAVE_PARCEL_DATA,
    payload,
  });

  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const result = await parcelServices.fetchParcelInfo(params);

      const lastUpdatedOn = new Date(result.updated_on);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let exceptions = [];

      if (result.exceptions.length > 0) {
        const sortedExceptions = getSortedExceptions(result.exceptions);
        sortedExceptions.forEach((exception, index) => {
          const exceptionType = _.find(exceptionTypes, (x) => x.name === exception.name);
          const createdOn = new Date(exception.created_on);
          const createdOnDate =
            createdOn.getDate() + " " + monthNames[createdOn.getMonth()] + " " + createdOn.getFullYear();
          const obj = {
            id: exception.id,
            name: exception.name,
            type: exceptionType ? exceptionType.description.toUpperCase() : exception.name,
            note: exception.note,
            created_by: exception.created_by,
            created_on: createdOnDate,
            photo_url: exception.photo_url,
            module_name: result.location ? result.location.name : "-",
            remedy_reason: "",
            exception_number: sortedExceptions.length - index,
          };
          exceptions.push(obj);
        });
      }

      if (params.scan_type === "warehouse outbound" && exceptions.length > 0) {
        dispatch(saveParcelScanExceptions(exceptions));
      } else {
        dispatch(clearParcelScanExceptions());
      }

      if (params.scan_type === "sweep") {
        if (!scannedNumbersList.includes(params.tracking_no)) {
          const numbersList = [...scannedNumbersList, params.tracking_no];
          dispatch(setScannedNumbersList(numbersList));
        }
      }

      const orderData = {
        orderInfo_length: result.order_length,
        orderInfo_width: result.order_width,
        orderInfo_height: result.order_height,
        orderInfo_weight: result.order_weight,
        dimUpdateSuccess: false,
        labelPath: result.order_label_url,
        exceptions: exceptions,
        showCancelledByCustomerBox: false,
        showHeldUpAtCustomsBox: false,
        privateStatuses: result.private_statuses,
        bins: computeBins(result.bins),
        trackingNumberLocation: result.location ? result.location.name : null,
        locationChangeName: result.location ? result.location.updated_by : null,
        locationChangeTime: result.location ? result.location.updated_on : null,
        location: result.location ? result.location.name : "-",
        resourceUsage: result.resource_usage ? result.resource_usage[result.tracking_no] : null,
        is_cancelled: result.is_cancelled ? result.is_cancelled : false,
        is_held_up_at_customs: result.is_held_up_at_customs,
        has_rts: result.has_rts,
        private_tracker_status_code: result.private_tracker_status_code ? result.private_tracker_status_code : null,
        cargo_id: result.cargo_id,
        undeliverable_option: result.undeliverable_option
      };

      const latestScannedNumber = result.tracking_no;

      const successNotificationData = {
        merchantName: result.agent_application_name,
        consigneeCountry: result.consignee_country,
        consigneePostalPreFix: result.consignee_postal.slice(0, 2),
        consigneeStateCity: result.consignee_city + ", " + result.consignee_state,
        consigneeState: result.consignee_state,
        consigneeCity: result.consignee_city,
        consigneePostal: result.consignee_postal,
        paymentType: result.payment_type,
        incoterm: result.incoterm === undefined || result.incoterm === null ? "DDP" : result.incoterm,
        totalOrderValue: result.order_currency + " " + result.order_total_value,
        lastUpdateMessage: result.private_tracker_status_code,
        lastUpdateBy:
          result.updated_by === ""
            ? lastUpdatedOn.getDate() + " " + monthNames[lastUpdatedOn.getMonth()] + " " + lastUpdatedOn.getFullYear()
            : lastUpdatedOn.getDate() +
              " " +
              monthNames[lastUpdatedOn.getMonth()] +
              " " +
              lastUpdatedOn.getFullYear() +
              " by " +
              result.updated_by,
        bins: result.bins,
        showHighValueError: checkHighValueError(result.bins),
        partner_assigned: getPartnerAssigned(result.partner_assigned),
        is_held_up_at_customs: result.is_held_up_at_customs,
        is_cancelled: result.is_cancelled,
        has_rts: result.has_rts,
        ua_returns: result.ua_returns,
        source: result.source,
      };

      const notificationBoxType = {
        showValidDataBox: true,
        showNoDataBox: false,
        showErrorDataBox: false,
        showRedeliverDataBox: false,
      };

      const payload = {
        latestScannedNumber: latestScannedNumber,
        orderData: orderData,
        successNotificationData: successNotificationData,
        notificationBoxType: notificationBoxType,
      };

      if (autoAdd) {
        dispatch(saveParcelData(payload));
      } else if (orderData.is_cancelled && params.scan_type !== "sweep") {
        const errorNotificationData = {
          errorMessage: `Cannot process Order ${params.tracking_no}. Order already Cancelled.`,
          showTrackingNumber: params.tracking_no,
          showDuplicateTrackingNumberError: false,
        };
        const notificationBoxType = {
          showErrorDataBox: true,
          showNoDataBox: false,
          showValidDataBox: false,
          showRedeliverDataBox: false,
        };
        const payload = {
          notificationBoxType: notificationBoxType,
          errorNotificationData: errorNotificationData,
        };
        dispatch(failure(payload));
      } else if (orderData.private_tracker_status_code === "ORDER_DELIVERED" && params.scan_type !== "sweep") {
        const errorNotificationData = {
          errorMessage: `Cannot Process Order with Tracking Number ${params.tracking_no}. Order already Delivered.`,
          showTrackingNumber: params.tracking_no,
          showDuplicateTrackingNumberError: false,
        };

        const notificationBoxType = {
          showErrorDataBox: true,
          showNoDataBox: false,
          showValidDataBox: false,
          showRedeliverDataBox: false,
        };
        const payload = {
          notificationBoxType: notificationBoxType,
          errorNotificationData: errorNotificationData,
        };
        dispatch(failure(payload));
      } else {
        dispatch(success(payload));
      }
    } catch (err) {
      console.log(err);
      const errorMessage = err.data;
      const errorForStore = errorMessage.tracking_no
        ? errorMessage.tracking_no.join(", ")
        : "Unknown Error, please screenshot and send to Tech Support.";
      let errorNotificationData = {
        errorMessage: errorForStore,
        showTrackingNumber: params.tracking_no,
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(params);
      }

      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      dispatch(failure(payload));
    }
  };
};

// process order
const onProcessOrder = (params, scannedNumbersList) => {
  const loading = () => ({ type: parcelTypes.UPDATE_TRACKER_LOADING });
  const success = (payload) => ({
    type: parcelTypes.UPDATE_TRACKER_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.UPDATE_TRACKER_FAILURE,
    payload,
  });
  const setNetworkPartner = (payload) => ({
    type: parcelTypes.SET_NETWORK_PARTNER,
    payload,
  });
  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      if (scannedNumbersList.includes(params.tracking_nos[0])) {
        dispatch({
          type: parcelTypes.CLEAR_FETCH_PARCEL_DATA,
        });
        const err = new Error();
        err.data = {
          tracking_nos: [`${params.tracking_nos[0]} Duplicate Scanned and/or needs to be inbounded`],
        };
        throw err;
      }

      const result = await parcelServices.processOrder(params);
      if (result && params.scan_type === "warehouse outbound") {
        setNetworkPartner(result.group_id);
      }

      dispatch(success(params.tracking_nos[0]));

      if (!scannedNumbersList.includes(params.tracking_nos[0])) {
        const numbersList = [...scannedNumbersList, params.tracking_nos[0]];
        dispatch(setScannedNumbersList(numbersList));
      }
    } catch (err) {
      let errorMessage = err.data;
      if (errorMessage && errorMessage.tracking_nos && errorMessage.tracking_nos[0]) {
        errorMessage = errorMessage.tracking_nos.join(", ");
      } else if (errorMessage && errorMessage.RETURN_TO_SELLER && errorMessage.RETURN_TO_SELLER[0]) {
        errorMessage = errorMessage.RETURN_TO_SELLER[0];
      } else if (errorMessage && errorMessage.hub_id && errorMessage.hub_id[0]) {
        errorMessage = errorMessage.hub_id[0];
      } else {
        errorMessage = "Unknown Error, please screenshot and send to Tech Support.";
      }

      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      let errorNotificationData = {
        errorMessage: errorMessage,
        showTrackingNumber: params.tracking_nos[0],
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(params);
      }

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      setTimeout(() => {
        dispatch(failure(payload));
      }, 10);
    }
  };
};

// redeliver
const onRedeliverScan = (params, scannedNumbersList) => {
  const loading = () => ({ type: parcelTypes.REDELIVER_SCAN_LOADING });
  const success = (payload) => ({
    type: parcelTypes.REDELIVER_SCAN_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.REDELIVER_SCAN_FAILURE,
    payload,
  });
  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      if (scannedNumbersList.includes(params.tracking_no)) {
        return;
      }
      const res = await parcelServices.redeliverScan(params);

      const orderData = {
        labelPath: res.redelivery_label,
      };

      const latestScannedNumber = res.tracking_no;

      const successNotificationData = {
        tracking_no: res.tracking_no,
        tracking_no_message: "Taken over for redelivery",
        new_tracking_no: res.new_tracking_no,
        new_tracking_no_message: "New Tracking Number",
        label_printer_message: "Check Label Printer for New Label",
        action_request: res.action_request,
      };

      const notificationBoxType = {
        showRedeliverDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: false,
      };

      const payload = {
        latestScannedNumber: latestScannedNumber,
        orderData: orderData,
        successNotificationData: successNotificationData,
        notificationBoxType: notificationBoxType,
      };

      const numbersList = [...scannedNumbersList, params.tracking_no];
      dispatch(setScannedNumbersList(numbersList));
      dispatch(success(payload));
    } catch (err) {
      const errorMessage = err.data.error
        ? err.data.error
        : "Unknown Error, please screenshot and send to Tech Support.";

      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      let errorNotificationData = {
        errorMessage: errorMessage,
        showTrackingNumber: params.tracking_no,
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(params);
      }

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      dispatch(failure(payload));
    }
  };
};

// disposal
const onGetDisposalOrderDetails = (params, disposalScannedNumbersList) => {
  const loading = () => ({ type: parcelTypes.FETCH_DISPOSAL_ORDER_LOADING });
  const success = (payload) => ({
    type: parcelTypes.FETCH_DISPOSAL_ORDER_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.FETCH_DISPOSAL_ORDER_FAILURE,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      if (disposalScannedNumbersList.includes(params.tracking_no)) {
        throw new Error(`${params.tracking_no} - Duplicate Scan`);
      }

      const res = await parcelServices.getDisposalOrderDetails(params);

      const latestScannedNumber = res.tracking_no;

      const successNotificationData = {
        disposalMessage: "Dispose Package",
      };

      const notificationBoxType = {
        showValidDataBox: true,
        showRedeliverDataBox: false,
        showNoDataBox: false,
        showErrorDataBox: false,
      };

      const numbersList = [...disposalScannedNumbersList, params.tracking_no];

      const payload = {
        latestScannedNumber: latestScannedNumber,
        successNotificationData: successNotificationData,
        notificationBoxType: notificationBoxType,
        numbersList: numbersList,
      };
      dispatch(success(payload));
    } catch (err) {
      let errorMessage = err.data ? err.data.error : "Invalid Scan";

      if (err.message) {
        errorMessage = err.message;
      }

      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      let errorNotificationData = {
        errorMessage: errorMessage,
        showTrackingNumber: params.tracking_no,
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(params);
      }

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      dispatch(failure(payload));
    }
  };
};

// disposal confirmation
const onDisposeParcel = (params, scannedNumbersList, disposalScannedNumbersList) => {
  const loading = () => ({ type: parcelTypes.DISPOSAL_SCAN_LOADING });
  const success = (payload) => ({
    type: parcelTypes.DISPOSAL_SCAN_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.DISPOSAL_SCAN_FAILURE,
    payload,
  });
  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const trackingNumbersList = scannedNumbersList.concat([...disposalScannedNumbersList]);

      const res = await parcelServices.disposalScan(params);

      dispatch(setScannedNumbersList(trackingNumbersList));

      const successNotificationData = {
        disposalMessage: "Dispose Package",
      };

      const notificationBoxType = {
        showValidDataBox: true,
        showRedeliverDataBox: false,
        showNoDataBox: false,
        showErrorDataBox: false,
      };

      const payload = {
        successNotificationData: successNotificationData,
        notificationBoxType: notificationBoxType,
      };
      dispatch(success(payload));
    } catch (err) {
      let errorMessage = "Unknown Error, please screenshot and send to Tech Support.";
      if (err.data.error) {
        errorMessage = err.data.error.replaceAll("'", '"');
        errorMessage = JSON.parse(errorMessage).tracking_nos[0];
      }

      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      let errorNotificationData = {
        errorMessage: errorMessage,
        showTrackingNumber: params.tracking_no,
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(params);
      }

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      dispatch(failure(payload));
      dispatch(setScannedNumbersList([]));
    }
  };
};

// update dimweigh
const onUpdateWeight = (params) => {
  const loading = () => ({ type: parcelTypes.UPDATE_WEIGHT_LOADING });
  const success = () => ({ type: parcelTypes.UPDATE_WEIGHT_SUCCESS });
  const failure = (payload) => ({
    type: parcelTypes.UPDATE_WEIGHT_FAILURE,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const res = await parcelServices.updateWeight(params);
      dispatch(success());
    } catch (err) {
      const errorMessage = err.data;
      dispatch(failure(errorMessage));
    }
  };
};

// add exception
const onAddException = (params, exceptionNumber) => {
  const loading = () => ({ type: parcelTypes.ADD_EXCEPTION_LOADING });
  const success = (payload) => ({
    type: parcelTypes.ADD_EXCEPTION_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.ADD_EXCEPTION_FAILURE,
    payload,
  });
  const saveExceptionData = (payload) => ({
    type: parcelTypes.SAVE_EXCEPTION_DATA,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      await parcelServices.addException(params);
      dispatch(success(exceptionNumber + 1));
      if (params.autoAddException) {
        dispatch(saveExceptionData(params));
      }
    } catch (err) {
      const errorMessage = err.data;
      dispatch(failure(errorMessage));
    }
  };
};

// add location
const onAddLocation = (params) => {
  const loading = () => ({ type: parcelTypes.ADD_LOCATION_LOADING });
  const success = (payload) => ({
    type: parcelTypes.ADD_LOCATION_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.ADD_LOCATION_FAILURE,
    payload,
  });
  const saveLocationData = (payload) => ({
    type: parcelTypes.SAVE_LOCATION_DATA,
    payload,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const res = await parcelServices.addLocation(params);
      dispatch(success());
      if (params.autoAddLocation) {
        dispatch(saveLocationData(params));
      }
      const mixpanelData = getMixpanelData();
      mixPanelTrack(ADD_LOCATION, {
        [SUCCESS]: true,
        [PAYLOAD]: params,
        ...mixpanelData,
      });
    } catch (err) {
      const errorMessage = err.data && err.data.location ? err.data.location[0] : "An Error Occur! Please Try Again";
      dispatch(failure(errorMessage));
      const mixpanelData = getMixpanelData();
      mixPanelTrack(ADD_LOCATION, {
        [SUCCESS]: false,
        [ERROR]: err,
        [PAYLOAD]: params,
        ...mixpanelData,
      });
    }
  };
};

const onSetNetworkPartner = (data) => {
  const setNetworkPartner = (payload) => ({
    type: parcelTypes.SET_NETWORK_PARTNER,
    payload,
  });
  return async function (dispatch) {
    dispatch(setNetworkPartner(data));
  };
};

const onSaveScanSettings = (data) => {
  const saveScanSettings = (payload) => ({
    type: parcelTypes.SAVE_SCAN_SETTINGS,
    payload,
  });
  return async function (dispatch) {
    dispatch(saveScanSettings(data));
  };
};

const clearAddExceptionData = () => {
  const clearAddException = () => ({
    type: parcelTypes.CLEAR_ADD_EXCEPTION_DATA,
  });
  return async function (dispatch) {
    dispatch(clearAddException());
  };
};

const clearAddLocationData = () => {
  const clearAddLocation = () => ({
    type: parcelTypes.CLEAR_ADD_LOCATION_DATA,
  });
  return async function (dispatch) {
    dispatch(clearAddLocation());
  };
};

const clearFetchParcelData = () => {
  const clearFetchParcel = () => ({
    type: parcelTypes.CLEAR_FETCH_PARCEL_DATA,
  });
  return async function (dispatch) {
    dispatch(clearFetchParcel());
  };
};

const clearUpdateTrackerData = () => {
  const clearUpdateTracker = () => ({
    type: parcelTypes.CLEAR_UPDATE_TRACKER_DATA,
  });
  return async function (dispatch) {
    dispatch(clearUpdateTracker());
  };
};

const clearUpdateWeightData = () => {
  const clearUpdateWeight = () => ({
    type: parcelTypes.CLEAR_UPDATE_WEIGHT_DATA,
  });
  return async function (dispatch) {
    dispatch(clearUpdateWeight());
  };
};

const clearDisposalOrderData = () => {
  const clearDisposalData = () => ({
    type: parcelTypes.CLEAR_DISPOSAL_ORDER_DATA,
  });
  return async function (dispatch) {
    dispatch(clearDisposalData());
  };
};

const clearDisposalOrderError = () => {
  const clearDisposalError = () => ({
    type: parcelTypes.CLEAR_DISPOSAL_ORDER_ERROR,
  });
  return async function (dispatch) {
    dispatch(clearDisposalError());
  };
};

const clearDisposalScanData = () => {
  const clearDisposalScan = () => ({
    type: parcelTypes.CLEAR_DISPOSAL_SCAN_DATA,
  });
  return async function (dispatch) {
    dispatch(clearDisposalScan());
  };
};

const clearParcelScanData = () => {
  const clearParcelScan = () => ({ type: parcelTypes.CLEAR_PARCEL_SCAN_DATA });
  return async function (dispatch) {
    dispatch(clearParcelScan());
  };
};

const clearRedeliverScanData = () => {
  const clearRedeliverData = () => ({
    type: parcelTypes.CLEAR_REDELIVER_SCAN_DATA,
  });
  return async function (dispatch) {
    dispatch(clearRedeliverData());
  };
};

const processChangeNetworkPartner = (reqPayload, scannedNumbersList) => {
  const success = (payload) => ({
    type: parcelTypes.UPDATE_TRACKER_SUCCESS,
    payload,
  });
  const failure = (payload) => ({
    type: parcelTypes.UPDATE_TRACKER_FAILURE,
    payload,
  });

  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });
  return async function (dispatch) {
    const request = {
      tracking_no: reqPayload.tracking_no,
      partner_name: reqPayload.partner_name,
      transport_type: "lastmile",
    };
    try {
      const res = await parcelServices.reassignPartner(request);
      const numbersList = [...scannedNumbersList, reqPayload.tracking_no];
      dispatch(setScannedNumbersList(numbersList));
      dispatch(success(res));
    } catch (err) {
      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      let errorNotificationData = {
        errorMessage: "COULD NOT REASSIGN NETWORK PARTNER",
        showTrackingNumber: reqPayload.tracking_no,
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(reqPayload);
      }

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      dispatch(failure(payload));
    }
  };
};

const resolveException = (data, saveData) => {
  const loading = () => ({ type: parcelTypes.RESOLVE_EXCEPTIONS_LOADING });
  const success = () => ({ type: parcelTypes.RESOLVE_EXCEPTIONS_SUCCESS });

  const failure = (payload) => ({
    type: parcelTypes.RESOLVE_EXCEPTIONS_ERROR,
    payload,
  });

  const saveRemedyReasonData = (payload) => ({
    type: parcelTypes.SAVE_REMEDY_REASON_DATA,
    payload,
  });

  const automaticallyResolveExceptions = () => ({
    type: parcelTypes.AUTOMATICALLY_RESOLVE_EXCEPTIONS,
  });

  return async function (dispatch) {
    dispatch(loading());
    try {
      await parcelServices.resolveException(data);
      dispatch(success());
      dispatch(saveRemedyReasonData(saveData));

      if (saveData.automaticallyRemedy) {
        dispatch(automaticallyResolveExceptions());
      }
    } catch (err) {
      const errorMessage = err.data;
      dispatch(failure(errorMessage));
    }
  };
};

const clearResolveExceptionData = () => {
  return async function (dispatch) {
    dispatch({
      type: parcelTypes.RESET_RESOLVE_EXCEPTIONS_DATA,
    });
  };
};

const clearResolveExceptionSuccessData = () => {
  return async function (dispatch) {
    dispatch({
      type: parcelTypes.RESET_RESOLVE_EXCEPTIONS_SUCCESS_DATA,
    });
  };
};

const getResourceDetails = (params) => {
  return async function (dispatch) {
    try {
      const res = await parcelServices.getResourceDetailsService(params);

      dispatch({
        type: parcelTypes.GET_RESOURCE_DETAILS_SUCCESS,
        payload: res.resource_details,
      });
    } catch (err) {
      dispatch({
        type: parcelTypes.GET_RESOURCE_DETAILS_ERROR,
      });
    }
  };
};

const addResourceToTN = (data) => {
  return async function (dispatch) {
    dispatch({
      type: parcelTypes.ADD_RESOURCE_LOADING,
      payload: true,
    });
    try {
      if (data.autoAddResource) {
        dispatch({
          type: parcelTypes.SAVE_RESOURCE_DATA,
          payload: data,
        });
      }
      await parcelServices.recordeResourceUsage(data);
      dispatch({
        type: parcelTypes.ADD_RESOURCE_SUCCESS,
      });
      const mixpanelData = getMixpanelData();
      mixPanelTrack(ADD_RESOURCE, {
        [SUCCESS]: true,
        [PAYLOAD]: data,
        ...mixpanelData,
      });
    } catch (err) {
      dispatch({
        type: parcelTypes.ADD_RESOURCE_ERROR,
      });
      const mixpanelData = getMixpanelData();
      mixPanelTrack(ADD_RESOURCE, {
        [SUCCESS]: false,
        [ERROR]: err,
        [PAYLOAD]: data,
        ...mixpanelData,
      });
    }
  };
};

const scanLabellessPackage = (params, scannedNumbersList) => {
  return async function (dispatch) {
    try {
      if (scannedNumbersList.includes(params.print_tracking_no)) {
        return;
      }

      const result = await parcelServices.getLabelForLabellessPackage(params);

      const orderData = {
        orderInfo_length: null,
        orderInfo_width: null,
        orderInfo_height: null,
        orderInfo_weight: null,
        dimUpdateSuccess: false,
        labelPath: result.order_label_url,
        exceptions: [],
        showCancelledByCustomerBox: false,
        showHeldUpAtCustomsBox: false,
        privateStatuses: [],
        bins: null,
        trackingNumberLocation: null,
        locationChangeName: null,
        locationChangeTime: null,
        location: null,
        resourceUsage: null,
      };

      const latestScannedNumber = result.tracking_no;

      const successNotificationData = {
        merchantName: null,
        consigneeCountry: null,
        consigneePostalPreFix: null,
        consigneeStateCity: null,
        consigneeState: null,
        consigneeCity: null,
        consigneePostal: null,
        paymentType: null,
        incoterm: null,
        totalOrderValue: null,
        lastUpdateMessage: null,
        lastUpdateBy: null,
        bins: null,
        showHighValueError: null,
        partner_assigned: null,
        is_held_up_at_customs: null,
        is_cancelled: null,
        has_rts: null,
        ua_returns: null,
      };

      const notificationBoxType = {
        showValidDataBox: true,
        showNoDataBox: false,
        showErrorDataBox: false,
        showRedeliverDataBox: false,
      };

      const numbersList = [...scannedNumbersList, params.print_tracking_no];
      dispatch({
        type: commonTypes.SET_SCANNED_NUMBERS_LIST,
        payload: numbersList,
      });

      const payload = {
        latestScannedNumber: latestScannedNumber,
        orderData: orderData,
        successNotificationData: successNotificationData,
        notificationBoxType: notificationBoxType,
      };

      dispatch({
        type: parcelTypes.SCAN_LABELLESS_PACKAGE_SUCCESS,
        payload,
      });
    } catch (err) {
      // const errorMessage = err.data.message;
      let errorNotificationData = {
        errorMessage: "Label is not available for this tracking number",
        showTrackingNumber: params.tracking_no,
        showDuplicateTrackingNumberError: false,
      };

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(params);
      }

      const notificationBoxType = {
        showErrorDataBox: true,
        showNoDataBox: false,
        showValidDataBox: false,
        showRedeliverDataBox: false,
      };

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
      };
      dispatch({
        type: parcelTypes.SCAN_LABELLESS_PACKAGE_ERROR,
        payload,
      });
    }
  };
};

export const parcelActions = {
  onFetchParcelInfo,
  onProcessOrder,
  onRedeliverScan,
  onGetDisposalOrderDetails,
  clearFetchParcelData,
  clearUpdateTrackerData,
  onUpdateWeight,
  clearUpdateWeightData,
  onAddException,
  onSaveScanSettings,
  onSetNetworkPartner,
  clearAddExceptionData,
  onAddLocation,
  clearAddLocationData,
  clearDisposalOrderData,
  clearDisposalOrderError,
  onDisposeParcel,
  clearDisposalScanData,
  clearParcelScanData,
  clearRedeliverScanData,
  processChangeNetworkPartner,
  resolveException,
  clearResolveExceptionData,
  clearResolveExceptionSuccessData,
  getResourceDetails,
  addResourceToTN,
  scanLabellessPackage,
};
