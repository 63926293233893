import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { commonActions } from "../actions/rootActions";
import { getSessionID } from "../actions/authActions";
import { getMixpanelData, mixPanelTrack } from "../utils/mixpanelUtils";
import { PARCEL_SCAN_TYPE_PAGE, PAGE_SCAN_TYPE, REQUIRE_ERROR_ACKNOWLEDGEMENT } from "../constants/mixpanelConstants";
import axios from "axios";
import { commonApi } from "../api";

export const useInitialize = (params) => {
  const [autoPrintLabel, setAutoPrintLabel] = useState(false);
  const [downloadLabel, setDownloadLabel] = useState(null);
  const dispatch = useDispatch();

  const { exceptionTypes, recipientsList, shipments, voiceUtilsData, printer } = useSelector((state) => ({
    exceptionTypes: state.common.exceptionTypes,
    recipientsList: state.common.recipientsList,
    shipments: state.common.shipments,
    voiceUtilsData: state.common.voiceUtilsData,
    printer: state.common.printer,
    sessionId: state.auth.sessionId,
  }));

  useEffect(() => {
    console.log("initiallize");
    if (_.isEmpty(exceptionTypes)) {
      dispatch(commonActions.getExceptionsTypes());
    }
    if (_.isEmpty(recipientsList)) {
      dispatch(commonActions.getRecipientsList());
    }
    if (_.isEmpty(shipments)) {
      dispatch(commonActions.getShipmentNumbersList());
    }
    if (_.isEmpty(voiceUtilsData)) {
      dispatch(commonActions.setVoiceType());
    }
    if (!printer) {
      dispatch(commonActions.setPrinter());
    }
    createSession(params.scanType);

    let autoPrint = localStorage.getItem("autoPrintLabel");
    const labelDownload = localStorage.getItem("downloadLabel");
    autoPrint = autoPrint === "true" ? true : false;
    setDownloadLabel(labelDownload);
    setAutoPrintLabel(autoPrint);

    const data = {
      hubId: localStorage.getItem("hubId"),
      userId: localStorage.getItem("userId"),
      userName: localStorage.getItem("userName"),
      requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
    };
    dispatch(commonActions.setScannerInformation(data));
    // mixpanel visit tracking
    const mixpanelData = getMixpanelData();
    mixPanelTrack(PARCEL_SCAN_TYPE_PAGE, {
      ...mixpanelData,
      [REQUIRE_ERROR_ACKNOWLEDGEMENT]: localStorage.getItem("requireErrorAcknowledgment") === "true",
      [PAGE_SCAN_TYPE]: params.scanType,
    });
  }, []);

  function createSession(scanType) {
    console.log("create session parcel scan hook");
    const scanTypeToSend = scanType === "disposal" || scanType === "redeliver" ? "sweep" : scanType;
    dispatch(
      getSessionID({
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
        scan_type: scanTypeToSend,
      })
    );
  }

  return [autoPrintLabel, downloadLabel];
};
