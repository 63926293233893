import React from "react";
import ZebraScanner from "../../../common/zebraScanner/ZebraScanner";
import { isEmpty } from "lodash";
import { CSVLink } from "react-csv";
import { generateExceptionsCSVData } from "../../../utils/generateCargoPackageExceptionCSVData";
import { CargoErrorNotificationArea } from "./CargoErrorNotificationArea";
import { CargoWarningNotificationArea } from "./CargoWarningNotificationArea";
import { CargoSuccessNotificationArea } from "./CargoSuccessNotificationArea";
import { DownloadPrintText } from "../commonComponents/downloadPrintText";
import { CargoScannerContext } from "./CargoScanner";

const csvHeaders = [
  {
    label: "Parcel Tracking Number",
    key: "parcelTrackingNo",
  },
  {
    label: "Cargo Tracking Number",
    key: "cargoTrackingNo",
  },
  {
    label: "Exception Type",
    key: "name",
  },
  {
    label: "Description",
    key: "description",
  },
];

export default function CargoNotificationArea() {
  const defaultScanner = localStorage.getItem("defaultScanner");

  function showScanInputField() {
    return (
      (defaultScanner === null || defaultScanner === undefined || defaultScanner === "Laser") &&
      defaultScanner !== "Camera"
    );
  }

  return (
    <CargoScannerContext.Consumer>
      {(value) => {
        let csvData = [];
        if (value.cargoPackageExceptions.length && value.cargoDetails) {
          csvData = generateExceptionsCSVData(value.cargoPackageExceptions, value.cargoDetails.tracking_no);
        }
        const noDataBoxClass =
          value.scanType !== "sweep" && value.notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
        const errorDataBoxClass = value.notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
        const validDataBoxClass =
          value.notificationBoxType.showValidDataBox && value.scanType !== "sweep" ? "d-flex" : "d-none";
        return (
          <React.Fragment>
            <div className={`empty-notification-container justify-content-center align-items-center ${noDataBoxClass}`}>
              <span className="empty-notification-text mb-0">You will see the scan results here.</span>
            </div>
            <CargoErrorNotificationArea
              errorDataBoxClass={errorDataBoxClass}
              errorNotificationData={value.errorNotificationData}
              scanType={value.scanType}
            />
            {!isEmpty(value.warningNotificationData) && (
              <CargoWarningNotificationArea
                scanType={value.scanType}
                warningNotificationData={value.warningNotificationData}
                latestScannedNumber={value.latestScannedNumber}
                validDataBoxClass={validDataBoxClass}
              />
            )}
            {isEmpty(value.warningNotificationData) && (
              <CargoSuccessNotificationArea
                validDataBoxClass={validDataBoxClass}
                scanType={value.scanType}
                successNotificationData={value.successNotificationData}
                latestScannedNumber={value.latestScannedNumber}
              />
            )}
            {defaultScanner === "Camera" && (
              <div>
                <ZebraScanner onScanComplete={(scanResult) => value.onChangeScanNumberCameraChange(scanResult)} />
              </div>
            )}
            {showScanInputField() && (
              <>
                <div
                  className={`d-flex align-items-center scanInputContainer ${
                    value.scanType === "cargo break" && !isEmpty(value.warningNotificationData) ? "flex-column" : ""
                  }`}
                  style={{ height: `${isEmpty(value.warningNotificationData) ? "65px" : "unset"}` }}
                >
                  {!isEmpty(value.warningNotificationData) && value.scanType === "cargo break" && (
                    <div className="w-100 mb-1">
                      <button onClick={value.resetScanState} className="primary-button w-100">
                        Skip Security Tag Scan
                      </button>
                      <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                    </div>
                  )}
                  <div className="w-100 d-flex align-items-center bg-white">
                    <h2 className="text-heading-black mr-3">Scan</h2>
                    <input
                      ref={value.scanNumberInputRef}
                      className="form-control scan-input"
                      placeholder="Click here or paste tracking code to scan"
                      value={value.scanNumber}
                      onChange={value.onChangeScanNumberChange}
                      onKeyUp={value.onChangeScanNumberChange}
                      autoFocus={true}
                    />
                  </div>
                </div>
                {value.cargoPackageExceptions && value.cargoPackageExceptions.length ? (
                  <div className="d-flex align-items-center justify-content-between scanInputContainer">
                    <div className="fnt-16 flex-2">
                      {value.cargoPackageExceptions.length} package(s) with exceptions are added to CSV in this scan
                      session
                    </div>
                    <div className="flex-1">
                      <CSVLink
                        data={csvData}
                        headers={csvHeaders}
                        filename={`exceptions-csv-${Date.now()}.csv`}
                        className="secondary-button d-block text-center"
                        style={{ lineHeight: "36px", textDecoration: "none", color: "#050593" }}
                      >
                        Download CSV
                      </CSVLink>
                    </div>
                  </div>
                ) : null}
                <div className="d-flex align-items-center flex-column scanInputContainer">
                  <DownloadPrintText
                    onDownloadHandler={value.downloadCargoLabel}
                    onClickPrintLabelButton={value.printCargoLabel}
                  />
                </div>
              </>
            )}
          </React.Fragment>
        );
      }}
    </CargoScannerContext.Consumer>
  );
}
