import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createRequestPayload } from "../utils/createCargoRequestPayload";
import { cargoActions } from "../actions/cargoActions";
import { isEmpty } from "lodash";
import { cargoApi } from "../api";
import {
  ERROR,
  ERROR_TYPE,
  PRINT_CARGO_LABEL,
  SHIPMENT_TRACKING_NUMBER,
  SUCCESS,
  CARGO_TRACKING_NUMBER,
} from "../constants/mixpanelConstants";
import { downloadPdf } from "../utils/downloadPdf";
import pdf2base64 from "pdf-to-base64";
import * as qz from "qz-tray";
import axios from "axios";
import { getMixpanelData, mixPanelTrack } from "../utils/mixpanelUtils";

export const useGetCargoDetails = (params) => {
  const { cargoDetails, trackingNumbersInsideCargoData, cargoDetailsSuccess, sessionId, printer, scanSettings } =
    useSelector((state) => ({
      cargoDetails: state.cargo.cargoDetails,
      trackingNumbersInsideCargoData: state.cargo.trackingNumbersInsideCargoData,
      cargoDetailsSuccess: state.cargo.cargoDetailsSuccess,
      sessionId: state.auth.sessionId,
      printer: state.common.printer,
      scanSettings: state.cargo.scanSettingsData,
    }));

  const { scannedNumbersList } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const [_, setError] = useState({});

  useEffect(() => {
    if (cargoDetails && cargoDetailsSuccess) {
      if (cargoDetails.is_cancelled) {
        setError("CANCELLED");
      }
      if (params.scanType === "cargo arrival" || params.scanType === "cargo break") {
        const requestPayload = createRequestPayload({
          tracking_no: cargoDetails.tracking_no.trim(),
          group_id: params.group_id,
          scan_type: params.scanType,
          session_id: sessionId,
        });
        dispatch(cargoActions.processScannedArrivalBreakOrders(requestPayload, cargoDetails, scannedNumbersList));
      } else {
        console.log(cargoDetails.tracking_no, params.shipment_no);
        const requestPayload = createRequestPayload({
          tracking_nos: [cargoDetails.tracking_no.trim()],
          shipment_no: params.shipment_no && params.shipment_no.trim(),
          group_id: params.group_id,
          scan_type: params.scanType,
          session_id: sessionId,
          cargo_auto_handover_scan: scanSettings.autoHandover,
        });
        if (params.scanType !== "sweep") {
          console.log(requestPayload);
          dispatch(cargoActions.processScannedOrders(requestPayload, cargoDetails, scannedNumbersList));
        }
      }
      if (params.scanType === "sweep" && isEmpty(trackingNumbersInsideCargoData)) {
        const requestPayload = createRequestPayload(
          { cargo_tracking_no: cargoDetails.tracking_no.trim(), page: 1 },
          []
        );
        dispatch(cargoActions.getTrackingNumberInsideCargo(requestPayload));
        if (params.autoPrintLabel && params.downloadLabel === "Automatic") {
          downloadCargoLabel();
          printCargoLabel();
        } else if (!params.autoPrintLabel && params.downloadLabel === "Automatic") {
          downloadCargoLabel();
        } else if (params.autoPrintLabel && (!params.downloadLabel || params.downloadLabel === "Manual")) {
          printCargoLabel();
        }
      }
    }
  }, [cargoDetails, params.process_cargo]);

  // useEffect(() => {
  //   const DEFAULT_ERROR = "Unknown Error, please screenshot and send to Tech Support.";
  //   if (cargoDetailsError) {
  //     const error = cargoDetailsError;
  //     const errorMessage = error.data && error.data.tracking_no ? error.data.tracking_no.join(", ") : DEFAULT_ERROR;
  //     // if (error.status !== 204) {
  //     //   setLatestScannedNumber(params.scanNumber);
  //     // }
  //     setError({ errorMessage, status: error.status });
  //   }
  // }, [cargoDetailsError]);
  //
  async function downloadCargoLabel() {
    if (!cargoDetails) {
      return;
    }
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargoDetails.tracking_no,
    };
    let response = await axios.get(cargoApi.PRINT_CARGO_LABEL, {
      params: data,
    });

    let pdf = `${cargoApi.PRINT_CARGO_LABEL}?hub_id=${encodeURIComponent(
      data.hub_id
    )}&hubscanner_key=${encodeURIComponent(data.hubscanner_key)}&cargo_tracking_no=${encodeURIComponent(
      data.cargo_tracking_no
    )}`;
    return downloadPdf(pdf);
  }

  async function printCargoLabel() {
    if (!cargoDetails) {
      return;
    }
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargoDetails.tracking_no,
    };
    let response = await axios.get(cargoApi.PRINT_CARGO_LABEL, {
      params: data,
    });

    const mixPanelData = getMixpanelData();

    let pdf = `${cargoApi.PRINT_CARGO_LABEL}?hub_id=${encodeURIComponent(
      data.hub_id
    )}&hubscanner_key=${encodeURIComponent(data.hubscanner_key)}&cargo_tracking_no=${encodeURIComponent(
      data.cargo_tracking_no
    )}`;
    if (qz.websocket.isActive() && response) {
      pdf2base64(pdf)
        .then((response) => {
          // mixpanel tracking print cargo label
          mixPanelTrack(PRINT_CARGO_LABEL, {
            [SUCCESS]: true,
            [CARGO_TRACKING_NUMBER]: cargoDetails.tracking_no,
            [SHIPMENT_TRACKING_NUMBER]: cargoDetails.shipment_no,
            ...mixPanelData,
          });
          var config = qz.configs.create(printer);
          var qzTrayData = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          qz.print(config, qzTrayData).then(function () {});
        })
        .catch((error) => {
          mixPanelTrack(PRINT_CARGO_LABEL, {
            [ERROR_TYPE]: PRINT_CARGO_LABEL,
            [SUCCESS]: false,
            [ERROR]: error,
            [CARGO_TRACKING_NUMBER]: cargoDetails.tracking_no,
            [SHIPMENT_TRACKING_NUMBER]: cargoDetails.shipment_no,
            ...mixPanelData,
          });
          console.log(error);
        });
    }
  }

  return [cargoDetails, downloadCargoLabel, printCargoLabel];
};
