// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { SUCCESS, VISIT_PARCEL_SCAN_PAGE } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { parcelActions, commonActions } from "../../../actions/rootActions";
import { connect } from "react-redux";
import Select from "../../../common/select/select";
import * as _ from "lodash";
import "./style.scss";
import { networkPartners } from "../../../utils/networPartnerList";

function HmsParcelScannerPage(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [state, setState] = useState({
    scanType: undefined,
    redirectToScanner: false,
    networkPartner: undefined,
  });

  const scanTypeName = state.scanType ? state.scanType.replace(/\s+/g, "-").toLowerCase() : "";

  useEffect(() => {
    if (localStorage.getItem("hubId") === null && localStorage.getItem("userId") === null) {
      setIsLoggedIn(false);
    } else {
      // mixpanel tracking page visit
      const mixpanelData = getMixpanelData();
      mixPanelTrack(VISIT_PARCEL_SCAN_PAGE, {
        [SUCCESS]: true,
        ...mixpanelData,
      });
    }
  }, []);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/login" />;
  }

  if (state.redirectToScanner) {
    return (
      <Redirect
        to={{
          pathname: `/scan/hubmanage/parcel-scan/${scanTypeName}`,
          state: {
            scanType: state.scanType,
            networkPartner: state.networkPartner,
          },
        }}
      />
    );
  }

  const scanTypes = getScanTypes();

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="bg-container">
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="title mb-0">Parcel Scan</p>
        </div>
        <div className="bg-container-body">
          <h2 className="text-heading-black">Scan Type</h2>
          <div className="margin-top-30">
            <Select
              label="Choose a Scan Type"
              placeholder="Select scan type"
              options={scanTypes}
              value={state.scanType}
              onChange={onScanTypeChange}
            />
          </div>
          {state.scanType === "reassign network partner" && (
            <div className="margin-top-30">
              <Select
                label="Select Network Partner to Reassign"
                placeholder="Select..."
                options={networkPartners}
                value={state.networkPartner}
                onChange={onNetworkPartnerChange}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function onNetworkPartnerChange(value) {
    setState({
      ...state,
      networkPartner: value,
    });
    if (state.scanType === undefined) {
      return;
    }
    redirectToScannerPage({ scanType: state.scanType, partner: value });
  }

  function getScanTypes() {
    let scanTypes = localStorage.getItem("scanTypes");
    scanTypes = JSON.parse(scanTypes);
    scanTypes = scanTypes.map((scannedType) => {
      let label = scannedType.name.toUpperCase();
      if (scannedType.name === "warehouse inbound") {
        label = "WH PROCESSING";
      } else if (scannedType.name === "warehouse outbound") {
        label = "WH PROCESSED";
      }
      let type = {
        value: scannedType.name,
        label,
      };
      return type;
    });
    scanTypes = _.filter(scanTypes, (x) => x.value !== "sort by");
    // TODO: change this from the BE later
    scanTypes = _.filter(scanTypes, (x) => !x.value.includes("cargo"));
    scanTypes.push({
      value: "disposal",
      label: "DISPOSAL",
    });
    scanTypes.push({
      value: "redeliver",
      label: "REDELIVER",
    });
    scanTypes.push({
      value: "reassign network partner",
      label: "REASSIGN NETWORK PARTNER",
    });
    scanTypes.push({
      value: "labelless package",
      label: "LABELLESS PACKAGE",
    });
    return scanTypes.filter(t => !t.value.includes("handover"));
  }

  function onScanTypeChange(value) {
    // TODO: refactor it in the future
    setState({
      ...state,
      scanType: value,
    });
    if (value === "reassign network partner" && !state.networkPartner) {
      return;
    }
    console.log(state);
    redirectToScannerPage({ scanType: value, partner: state.networkPartner });
  }

  function redirectToScannerPage({ scanType, partner }) {
    props.dispatch(parcelActions.clearParcelScanData());
    props.dispatch(parcelActions.clearResolveExceptionData());
    props.dispatch(commonActions.clearScannedNumbersList());
    props.history.push({
      pathname: `/scan/hubmanage/parcel-scan/${scanType}`,
      state: { scanType, networkPartner: partner },
    });
  }
}

export default connect(null, null)(HmsParcelScannerPage);
