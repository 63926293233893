import React, { useState, useRef } from "react";
import Measure from "react-measure";
import { useUserMedia } from "./hooks/use-user-media";
import { useCardRatio } from "./hooks/use-card-ratio";
import { useOffsets } from "./hooks/use-offsets";
import './styles.scss';
import BreadCrumb from '../../components/hms/commonComponents/BreadCrumb';

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: "environment" }
};

export function Camera({ onCapture, onClear, pageName, scanType, route }) {
  const canvasRef = useRef();
  const videoRef = useRef();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [imagePreview, setImagePreiview] = useState();

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height,
  );

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio)
    });
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  function handleCapture() {
    const context = canvasRef.current.getContext("2d");

    context.drawImage(
      videoRef.current,
      0,
      0,
      container.width,
      container.height
    );
    canvasRef.current.toBlob(blob => {
      setImagePreiview(blob)
    }
      , "image/jpeg", 1);
  }

  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setImagePreiview(null);
  }

  if (!mediaStream) {
    return null;
  }

  const handleCaptureCallback = () => {
    onCapture(imagePreview);
    handleClear();
  }

  return (
    <div className="bg-container w-100 mt-0">
      <BreadCrumb pageName={pageName} scanType={scanType} route={route} />
      <div className="bg-container-body p-0">
        <Measure bounds onResize={handleResize}>
          {({ measureRef }) => (
            <div className="d-flex align-items-center flex-column w-100 bg-black">
              <div
                className="position-relative w-100 container"
                ref={measureRef}
                style={{
                  height: `${container.height}px`,
                }}
              >
                <video
                  className="video"
                  ref={videoRef}
                  hidden={!isVideoPlaying}
                  onCanPlay={handleCanPlay}
                  autoPlay
                  playsInline
                  muted
                  style={{
                    top: `-${offsets.y}px`,
                    left: `-${offsets.x}px`,
                    display: imagePreview ? 'none' : 'unset'
                  }}
                />
                <canvas
                  ref={canvasRef}
                  width={container.width}
                  height={container.height}
                  style={{
                    display: imagePreview ? 'block' : 'none'
                  }}
                />
              </div>
              <div className="btn-container">
                <button onClick={imagePreview ? handleClear : onClear} className="secondary-button w-sm-100 mb-sm-1">
                  Back
                </button>
                <button onClick={imagePreview ? handleCaptureCallback : handleCapture} className="primary-button w-sm-100">
                  {imagePreview ? 'Upload' : 'Take'} Photo
              </button>
              </div>
            </div>
          )}
        </Measure>
      </div>
    </div >
  );
}

export default Camera;