import React from "react";
import { Redirect } from "react-router";

export default function HMSlite() {
  if (localStorage.getItem("hubId") && localStorage.getItem("userId")) {
    return <Redirect replace to="/scan/hubmanage/parcel-scan" />;
  }
  return <Redirect push to="/scan/hubmanage/login" />;
}

