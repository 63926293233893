import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withNamespaces, Trans } from 'react-i18next';

class RedirectWarehouse extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div className="main-container d-flex flex-column align-items-center">
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><LinkContainer to="/scan"><a><Trans>Home</Trans></a></LinkContainer></li>
              <li className="breadcrumb-item active" aria-current="page"><Trans>Warehouse</Trans></li>
            </ol>
          </nav>
          <div className="content-container card mt-1 mb-5 p-2">
            <LinkContainer to="/scan/warehouse/receive">
              <button
                type="button"
                className="text-white btn btn-lg btn-janio btn-janio-redirect mb-2">
                <Trans>Receive</Trans>
                </button>
            </LinkContainer>

            <LinkContainer to="/scan/warehouse/transfer">
              <button
                type="button"
                className="text-white btn btn-lg btn-janio btn-janio-redirect mb-2">
                <Trans>Transfer</Trans>
                </button>
            </LinkContainer>

            {/*<LinkContainer to="/scan/warehouse/change-weight">
                <button type="button"
                className="text-white btn btn-lg btn-janio btn-janio-redirect mb-2"
                >
                Change Weight
                </button>
              </LinkContainer>*/}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default compose(
  connect(mapStateToProps, {}),
  withNamespaces()
)(RedirectWarehouse);
