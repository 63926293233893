import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { LinkContainer } from "react-router-bootstrap";

// return downloadPdf(value.orderData.labelPath);

// onClick={() => onClickPrintLabelButton(value.orderData.labelPath)}

export const DownloadPrintText = ({ onDownloadHandler, onClickPrintLabelButton }) => {
  const customGreen = "#528E16";
  const customGrey = "#707070";

  const isQzConnected = JSON.parse(localStorage.getItem("isQzConnected"));
  const downloadLabel = localStorage.getItem("downloadLabel");
  const autoPrintLabel = JSON.parse(localStorage.getItem("autoPrintLabel"));

  let text = null;
  let color = customGreen;

  const buttonComp = (
    <div className="d-flex align-items-center flex-row justify-content-start w-100">
      <div className="link-title fnt-16 mr-5" onClick={onDownloadHandler}>
        View Label
      </div>
      {isQzConnected && (
        <div className="link-title fnt-16 mr-5" onClick={onClickPrintLabelButton}>
          Print Label
        </div>
      )}
      <LinkContainer to="/scan/hubmanage/settings">
        <div className="link-title fnt-16">Change Settings</div>
      </LinkContainer>
    </div>
  );

  if (!isQzConnected) {
    // qz-tray not connected and manual label download - grey
    if (!downloadLabel || downloadLabel === "Manual") {
      text = <span>QZ Tray is not connected</span>;
      color = customGrey;
    } else {
      // qz-tray not connected and label auto download - green
      text = <span>Label is automatically downloaded. QZ Tray is not connected</span>;
    }
  } else {
    // qz-tray connected and manual label download and manual print - green
    if ((!downloadLabel || downloadLabel === "Manual") && !autoPrintLabel) {
      text = <span>QZ Tray is connected</span>;
    }
    // qz-tray connected and manual label download and auto print - green
    if ((!downloadLabel || downloadLabel === "Manual") && autoPrintLabel) {
      text = <span>QZ Tray is connected and will print label automatically</span>;
    }
    // qz-tray connected and auto label download and manual print - green
    if (downloadLabel === "Automatic" && !autoPrintLabel) {
      text = <span>Label is automatically downloaded. QZ Tray is connected</span>;
    }
    // qz-tray connected and auto label download and auto print -green
    if (downloadLabel === "Automatic" && autoPrintLabel) {
      text = <span>Label is automatically downloaded. QZ Tray is connected and will print label automatically</span>;
    }
  }

  return (
    <>
      <div className="mb-3 w-100 fnt-16 align-items-center d-flex" style={{ color: color }}>
        <CheckCircleFilled className="mr-1" />
        {text}
      </div>
      {buttonComp}
    </>
  );
};
