import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMixpanelData, mixPanelTrack } from "../utils/mixpanelUtils";
import { PARCEL_SCAN, PARCEL_TRACKING_NUMBER } from "../constants/mixpanelConstants";
import * as _ from "lodash";
import { parcelActions } from "../actions/rootActions";
import { scanAudioAlert } from "../utils/voiceTypeUtils";

export const useUpdateTracker = (params) => {
  const dispatch = useDispatch();
  const {
    voiceUtilsData,
    successNotificationData,
    updateTrackerSuccess,
    scannedNumbersList,
    latestScannedNumber,
    exceptionData,
    locationData,
    resourceData,
  } = useSelector((state) => ({
    successNotificationData: state.parcel.successNotificationData,
    updateTrackerSuccess: state.parcel.updateTrackerSuccess,
    latestScannedNumber: state.parcel.latestScannedNumber,
    scannedNumbersList: state.common.scannedNumbersList,
    voiceUtilsData: state.common.voiceUtilsData,
    exceptionData: state.parcel.exceptionData,
    locationData: state.parcel.locationData,
    resourceData: state.parcel.resourceData,
  }));

  console.log("asjdfkhalsfjh", locationData);

  useEffect(() => {
    if (updateTrackerSuccess) {
      let fetchOrderData = {
        consigneeCountry: successNotificationData.consigneeCountry,
        consigneePostalPreFix: successNotificationData.consigneePostal.slice(0, 2),
        bins: successNotificationData.bins,
      };
      let trackingNumbersList = [...scannedNumbersList];
      if (!trackingNumbersList.includes(latestScannedNumber)) {
        trackingNumbersList.unshift(latestScannedNumber);
      }
      fetchOrderData.noOfParcelsScanned = trackingNumbersList.length;
      // TODO: refactor
      if (params.state.scanType !== "reassign network partner") {
        playAudioAlert(fetchOrderData);
      }
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      const mixPanelData = getMixpanelData();
      mixPanelTrack(PARCEL_SCAN, {
        [PARCEL_TRACKING_NUMBER]: latestScannedNumber,
        ...mixPanelData,
      });
      dispatch(parcelActions.clearUpdateTrackerData());
      addExceptionIfAutoEnabled(latestScannedNumber);
      addLocationIfAutoEnabled(latestScannedNumber);
      addResourceIfAutoEnabled(latestScannedNumber);
    }
  }, [updateTrackerSuccess]);

  function playAudioAlert(fetchOrderData) {
    let networkPartnerBin = undefined;
    let bins = fetchOrderData.bins;
    bins = bins.replace(/'/g, '"');
    bins = JSON.parse(bins);
    networkPartnerBin = _.find(bins, (x) => x.type === "NP");
    const highValueBin = _.find(bins, (x) => x.type === "HIGHVALUE");
    let stringToVoice = "";
    if (voiceUtilsData.defaultAudioType === "Partner Name" && networkPartnerBin) {
      stringToVoice = networkPartnerBin["name"].toLowerCase();
    } else if (voiceUtilsData.defaultAudioType === "Postal Prefix") {
      stringToVoice = fetchOrderData.consigneePostalPreFix.toLowerCase();
    } else if (voiceUtilsData.defaultAudioType === "No. of Parcels Scanned") {
      stringToVoice = fetchOrderData.noOfParcelsScanned;
    } else {
      stringToVoice = fetchOrderData.consigneeCountry.toLowerCase();
    }
    if (highValueBin) {
      stringToVoice = "high value, " + stringToVoice;
    }
    scanAudioAlert(stringToVoice, voiceUtilsData.voice);
  }

  function addExceptionIfAutoEnabled(scannedNumber) {
    if (!_.isEmpty(exceptionData)) {
      let exceptionDataToSend = { ...exceptionData };
      exceptionDataToSend.tracking_no = scannedNumber.trim();
      dispatch(parcelActions.onAddException(exceptionDataToSend));
    }
  }

  function addLocationIfAutoEnabled(scannedNumber) {
    if (!_.isEmpty(locationData)) {
      const newLocationData = { ...locationData };
      newLocationData.tracking_no = scannedNumber.trim();
      dispatch(parcelActions.onAddLocation(newLocationData));
    }
  }

  function addResourceIfAutoEnabled(scannedNumber) {
    if (!_.isEmpty(resourceData)) {
      const newResourceData = { ...resourceData };
      newResourceData.tracking_nos = [scannedNumber.trim()];
      dispatch(parcelActions.addResourceToTN(newResourceData));
    }
  }
};
