import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { playSound, TYPE_ERROR } from "../utils/playSound";
import axios from "axios";
import { parcelScanApi } from "../api";
import { parcelActions } from "../actions/rootActions";
import * as qz from "qz-tray";
import pdf2base64 from "pdf-to-base64";
import * as _ from "lodash";
import { downloadPdf } from "../utils/downloadPdf";

export const useFetchParcel = (params) => {
  const [qzTrayDownloadError, setQzTrayDownloadError] = useState(false);
  const [qzTraySuccess, setQzTraySuccess] = useState(false);

  const dispatch = useDispatch();
  const {
    fetchParcelSuccess,
    orderData,
    latestScannedNumber,
    printer,
    scannerInformation,
    scanSettings,
    scannedNumbersList,
    successNotificationData,
    fetchParcelError,
    sessionId,
    scanSettingsData,
    resources,
  } = useSelector((state) => ({
    fetchParcelSuccess: state.parcel.fetchParcelSuccess,
    orderData: state.parcel.parcelOrderData,
    latestScannedNumber: state.parcel.latestScannedNumber,
    printer: state.common.printer,
    scannerInformation: state.common.scannerInformation,
    scanSettings: state.parcel.scanSettingsData,
    scannedNumbersList: state.common.scannedNumbersList,
    successNotificationData: state.parcel.successNotificationData,
    fetchParcelError: state.parcel.fetchParcelError,
    sessionId: state.auth.sessionId,
    scanSettingsData: state.parcel.scanSettingsData,
    resources: state.parcel.resources,
  }));

  useEffect(() => {
    console.log("resources api");
    dispatch(
      parcelActions.getResourceDetails({
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
      })
    );
  }, []);

  useEffect(() => {
    if (fetchParcelSuccess) {
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      if (
        params.scanType !== "sweep" &&
        (orderData.is_cancelled || orderData.is_held_up_at_customs || orderData.has_rts)
      ) {
        playSound("error");
      }
      if (params.autoPrintLabel && params.downloadLabel === "Automatic") {
        downloadPdf(orderData.labelPath);
        fulfillActionRequest();
        printLabel(orderData.labelPath);
      } else if (!params.autoPrintLabel && params.downloadLabel === "Automatic") {
        downloadPdf(orderData.labelPath);
      } else if (params.autoPrintLabel && (!params.downloadLabel || params.downloadLabel === "Manual")) {
        fulfillActionRequest();
        printLabel(orderData.labelPath);
      }

      // dispatch(parcelActions.clearFetchParcelData());

      const parameters = {
        hub_id: scannerInformation.hubId,
        hubscanner_key: scannerInformation.userId,
        group_id: getGroupId(),
        scan_type: params.scanType,
        session_id: sessionId,
        tracking_nos: [latestScannedNumber.trim()],
        auto_inbound_outbound: scanSettingsData.automaticallyInbound,
        auto_handover_scan: scanSettings.autoHandover,
      };
      // TODO: fix this patch
      if (params.scanType === "reassign network partner") {
        dispatch(
          parcelActions.processChangeNetworkPartner(
            {
              tracking_no: latestScannedNumber.trim(),
              partner_name: params.networkPartner,
            },
            scannedNumbersList
          )
        );
      } else if (params.scanType !== "labelless package" && params.scanType !== "sweep") {
        dispatch(parcelActions.onProcessOrder(parameters, scannedNumbersList));
      }
      if (successNotificationData.ua_returns) {
        playSound(TYPE_ERROR);
      }
      dispatch(parcelActions.clearFetchParcelData());
    }
  }, [fetchParcelSuccess]);

  useEffect(() => {
    if (fetchParcelError) {
      playSound("error");
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      dispatch(parcelActions.clearFetchParcelData());
    }
  }, [fetchParcelError]);

  function fulfillActionRequest() {
    axios.post(parcelScanApi.FULFILL_ACTION_REQUEST, {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      scan_type: params.scanType,
      session_id: sessionId,
      orders: [{ tracking_no: latestScannedNumber.trim() }],
      action_type: "RELABEL",
    });
  }

  function printLabel(labelPath) {
    if (!labelPath) {
      return;
    } else {
      pdf2base64(labelPath)
        .then((response) => {
          var config = qz.configs.create(printer);
          var data = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          if (!qz.websocket.isActive()) {
            setQzTrayError(true);
          } else {
            setQzTraySuccess(true);
            qz.print(config, data)
              .then(() => {
                setQzTrayError(false);
                if (localStorage.getItem("hubId") === "janiotspace") {
                  dispatch(
                    parcelActions.addResourceToTN({
                      hub_id: localStorage.getItem("hubId"),
                      hubscanner_key: localStorage.getItem("userId"),
                      tracking_nos: [latestScannedNumber.trim()],
                      resource_id: resources.filter((val) => val.resource_name === "Label")[0]?.id,
                      times_used: 1,
                      url: window.location.href,
                    })
                  );
                }
              })
              .catch((err) => setQzTrayError(err));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function setQzTrayError(err) {
    setQzTrayDownloadError(err);
    setTimeout(() => {
      setQzTrayDownloadError(err);
    }, 3000);
  }

  function getGroupId() {
    if (!_.isEmpty(scanSettings.groupId) || scanSettings.recipientId === undefined) {
      return scanSettings.groupId;
    } else {
      return scanSettings.recipientId;
    }
  }

  return [printLabel, qzTrayDownloadError, qzTraySuccess];
};
