import axios from "axios";
import { parcelScanApi, trolleyScanApi, cargoApi } from "../api";

const getTrolleyPackageDetails = (params) => {
  return axios({
    method: 'GET',
    url: trolleyScanApi.UPDATE_TROLLEY_PACKAGES,
    params: params,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
  }).then(res => res.data)
};

const addCargoToTrolley = (data) => {
  return axios({
    method: 'PUT',
    url: cargoApi.ADD_TO_CARGO,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
};

const addLocation = (data) => {
  return axios({
    method: 'PUT',
    url: parcelScanApi.ADD_PARCEL_LOCATION,
    data: data,
    headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` }
  }).then(res => res.data)
}

export const trolleyServices = {
  getTrolleyPackageDetails,
  addCargoToTrolley,
  addLocation
}