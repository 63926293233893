import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Dropdown, Menu, Input, Form } from "antd";
import { Link } from "react-router-dom";
import { ROOT_URL } from "../../../actions/index";
import { shipmentActions } from "../../../actions/rootActions";
import { connect } from "react-redux";
import Select from "../../../common/select/select";
import axios from "axios";
import * as _ from "lodash";
import "./style.scss";

const { TextArea } = Input;

function RTV(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [scannerInformation, setScannerInformation] = useState({});
  const [countriesList, setCountriesList] = useState([]);
  const [states, setStates] = useState([]);
  const [state, setState] = useState({
    recipient_name: "",
    recipient_company: "",
    recipient_country: "",
    recipient_postal: "",
    recipient_state: "",
    recipient_address: "",
  });

  const [createReturnShipmentSuccess, setCreateReturnShipmentSuccess] = useState(false);
  const [createReturnShipmentError, setCreateReturnShipmentError] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (localStorage.getItem("hubId") == null && localStorage.getItem("userId") == null) {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    getCountriesList();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      setScannerInformation({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/old/login" />;
  }

  if (createReturnShipmentSuccess) {
    return <Redirect push to="/scan/hubmanage/shipments" />;
  }

  return (
    <div className="rtv-bg-container d-flex flex-column align-items-left">
      <div className="d-flex align-items-center">
        <h1 className="font-weight-bolder pl-0 float-left mb-0">RTV</h1>
        <div className="ml-auto">
          <Dropdown overlay={getMenu()} placement="bottomLeft" trigger={["click"]}>
            <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
              Menu
            </button>
          </Dropdown>
          <button type="button" className="btn btn-outline-danger" onClick={clearSession}>
            End Session
          </button>
        </div>
      </div>
      <div className="d-flex rtv-breadcrumb">
        <nav aria-label="breadcrumb mr-auto">
          <ol className="breadcrumb p-0 m-0">
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan">
                <a className="standard-font-size">Home</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan/hubmanage/parcel-scan">
                <a className="standard-font-size">HMS Scanner</a>
              </LinkContainer>
            </li>
            <li
              className="breadcrumb-item active breadcrumb-divider standard-font-size standard-font-color"
              aria-current="page"
            >
              RTV
            </li>
          </ol>
        </nav>
      </div>
      <div className="rtv-scanner-information-container">
        <div className="rtv-scanner-information-content">
          <div className="d-flex flex-column">
            <span className="hub-id-heading">Hub ID</span>
            <span className="hub-id">{scannerInformation.hubId}</span>
            <span className="user-id-heading">User ID</span>
            <span className="user-id">{scannerInformation.userName}</span>
          </div>
        </div>
      </div>
      <div className="rtv-container">
        <div className="d-flex justify-content-center mb-20">
          <span className="heading-text">Create Shipment</span>
        </div>

        <Form form={form} onFinish={createReturnShipment} initialValues={state}>
          <div className="d-flex flex-column">
            <div className="mb-20 d-flex flex-column">
              <label className="heading-text">Recipient Name</label>
              <Form.Item
                name="recipient_name"
                rules={[{ type: "string", required: true, message: "Please type a recipient name first" }]}
              >
                <Input name="recipient_name" onChange={onInputChange} />
              </Form.Item>
            </div>
            <div className="mb-20 d-flex flex-column">
              <label className="heading-text">Recipient Company</label>
              <Form.Item
                name="recipient_company"
                rules={[{ type: "string", required: true, message: "Please type a recipient company first" }]}
              >
                <Input name="recipient_company" onChange={onInputChange} />
              </Form.Item>
            </div>
            <div className="mb-20 d-flex flex-column">
              <label className="heading-text">Recipient Country</label>
              <Form.Item
                name="recipient_country"
                rules={[{ type: "string", required: true, message: "Please select country first" }]}
              >
                <Select
                  options={countriesList}
                  value={state.recipient_country}
                  name="recipient_country"
                  onChange={(value) => onSelectionChange("recipient_country", value)}
                />
              </Form.Item>
            </div>
            <div className="mb-20 d-flex flex-column">
              <label className="heading-text">Recipient Postal Code</label>
              <Form.Item
                name="recipient_postal"
                rules={[{ type: "string", required: true, message: "Please type a recipient postal code first" }]}
              >
                <Input name="recipient_postal" onChange={onInputChange} />
              </Form.Item>
            </div>
            <div className="mb-20 d-flex flex-column">
              <label className="heading-text">Recipient State</label>
              <Form.Item
                name="recipient_state"
                rules={[{ type: "string", required: true, message: "Please select a state first" }]}
              >
                <Select
                  options={states}
                  value={state.recipient_state}
                  name="recipient_state"
                  onChange={(value) => onSelectionChange("recipient_state", value)}
                />
              </Form.Item>
            </div>
            <div className="mb-20 d-flex flex-column">
              <label className="heading-text">Recipient Address</label>
              <Form.Item
                name="recipient_address"
                rules={[{ type: "string", required: true, message: "Please type an address first" }]}
              >
                <TextArea rows={3} name="recipient_address" onChange={onInputChange} />
              </Form.Item>
            </div>
            <div className="ml-auto mb-2">
              <button className="confirm-button" type="submit">
                Confirm
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );

  function onInputChange(e) {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  }

  function onSelectionChange(name, value) {
    setState({
      ...state,
      [name]: value,
    });
    if (name === "recipient_country") {
      const data = { country: value };
      getStates(data);
    }
  }

  async function createReturnShipment() {
    axios
      .post(
        `${ROOT_URL}/hms/create_return_shipment/?hub_id=${scannerInformation.hubId}&hubscanner_key=${scannerInformation.userId}`,
        {
          recipient_name: state.recipient_name,
          recipient_company: state.recipient_company,
          recipient_country: state.recipient_country,
          recipient_postal: state.recipient_postal,
          recipient_state: state.recipient_state,
          recipient_address: state.recipient_address,
          hub_id: scannerInformation.hubId,
          hubscanner_key: scannerInformation.userId,
        }
      )
      .then((res) => {
        localStorage.setItem("RTV", true);
        localStorage.setItem("recipientCompanyFromRTV", state.recipient_company);
        props.dispatch(shipmentActions.setShipmentNo(res.data.shipment_no));
        setCreateReturnShipmentSuccess(true);
      })
      .catch((e) => {
        localStorage.removeItem("RTV");
        localStorage.removeItem("recipientCompanyFromRTV");
        setCreateReturnShipmentError(e.data);
        setCreateReturnShipmentSuccess(false);
      });
  }

  function getError(name) {
    if (createReturnShipmentError && createReturnShipmentError[name] && createReturnShipmentError[name].length > 0) {
      return createReturnShipmentError[name][0];
    }
  }

  function getMenu() {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  }

  async function getCountriesList() {
    let countriesList = await axios.get(`https://hms.janio.asia/api/location/countries/`);
    countriesList = countriesList.data;
    countriesList = _.orderBy(countriesList, "country_name", "asc");
    countriesList = countriesList.map((country) => {
      country.value = country.country_name;
      country.label = country.country_name;
      return country;
    });
    setCountriesList(countriesList);
  }

  async function getStates(data) {
    let states = await axios({
      method: "GET",
      url: `https://hms.janio.asia/api/location/states/?countries=${data.country}`,
    });
    states = states.data;
    states = _.orderBy(states, "state_name", "asc");
    states = states.map((state) => {
      state.value = state.state_name;
      state.label = state.state_name;
      return state;
    });
    setStates(states);
  }

  function clearSession() {
    setIsLoggedIn(false);
  }
}

export default connect(null, null)(RTV);

