import React from "react";
import { Link } from "react-router-dom";

const ActionItem = ({ onClickHandler, imageSrc, itemText, classNames, children, to }) => {
  return (
    <Link to={to}>
      <div className={`d-flex align-items-center action-item ${classNames ? classNames : ""}`} onClick={onClickHandler}>
        <img className="action-item-image" src={imageSrc} alt="" />
        <h2 className="text-heading-black text-heading-black-font-sm flex-grow-1 mt-2">{itemText}</h2>
        {children}
        <span className="action-item-arrow">{">"}</span>
      </div>
    </Link>
  );
};

export default ActionItem;
