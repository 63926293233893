/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Dropdown, Menu } from "antd";
import { Link } from "react-router-dom";
import { ROOT_URL } from "../../../actions/index";
import axios from "axios";
import "./style.scss";

export default function PickList() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [downloadErr, setDownloadErr] = useState(false);
  const [pickList, setPickList] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("hubId") == null && localStorage.getItem("userId") == null) {
      setIsLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    getPickList();
  }, []);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/old/login" />;
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="pick-list-bg-container d-flex flex-column align-items-left">
      <div className="d-flex align-items-center">
        <h1 className="font-weight-bolder pl-0 float-left mb-0">Pick List</h1>
        <div className="ml-auto">
          <Dropdown overlay={getMenu()} placement="bottomLeft" trigger={["click"]}>
            <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
              Menu
            </button>
          </Dropdown>
          <button type="button" className="btn btn-outline-danger" onClick={clearSession}>
            End Session
          </button>
        </div>
      </div>
      <div className="d-flex parcel-scan-breadcrumb">
        <nav aria-label="breadcrumb mr-auto">
          <ol className="breadcrumb p-0 m-0">
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan">
                <a className="standard-font-size">Home</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item breadcrumb-divider">
              <LinkContainer to="/scan/hubmanage/parcel-scan">
                <a className="standard-font-size">HMS Scanner</a>
              </LinkContainer>
            </li>
            <li
              className="breadcrumb-item active breadcrumb-divider standard-font-size standard-font-color"
              aria-current="page"
            >
              Pick List
            </li>
          </ol>
        </nav>
      </div>
      <div className="d-flex">
        <button className="download-pick-list-button" onClick={exportPickList}>
          Download Pick List
        </button>
      </div>
      {downloadErr && <div className="alert alert-danger">An error occurred. Please try again.</div>}
      <div className="pick-list-container">
        <div className="row">
          <div className="col-5 mb-20">
            <span className="table-heading">Tracking No</span>
          </div>

          <div className="col-2 mb-20 text-center">
            <span className="table-heading">Location</span>
          </div>

          <div className="col-2 mb-20 text-center">
            <span className="table-heading">Request</span>
          </div>

          <div className="col-3 mb-20 text-center">
            <span className="table-heading">Created On</span>
          </div>
          {pickList.map((listItem, index) => {
            const date = new Date(listItem.created_on);
            return (
              <React.Fragment key={index}>
                <div className="col-5 mb-20">
                  <span className="table-text">{listItem.tracking_no}</span>
                </div>
                <div className="col-2 mb-20 text-center">
                  <span className="table-text">{listItem.location}</span>
                </div>
                <div className="col-2 mb-20 text-center">
                  <span className="table-text">{listItem.action_request}</span>
                </div>
                <div className="col-3 mb-20 text-center">
                  <span className="table-text">{`${date.getDate()} ${getMonthName(
                    monthNames[date.getMonth()]
                  )} ${date.getFullYear()}`}</span>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );

  function getMonthName(monthName) {
    return monthName.substr(0, 3);
  }

  function getMenu() {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  }

  function clearSession() {
    setIsLoggedIn(false);
  }

  async function getPickList() {
    let pickListData = await axios.post(`${ROOT_URL}/api/hms/pick_list/`, {
      hub_id: localStorage.getItem("hubId"),
      is_fulfilled: false,
    });
    pickListData = pickListData.data.results;
    setPickList(pickListData);
  }

  async function exportPickList(e) {
    e.preventDefault();
    axios
      .post(
        `${ROOT_URL}/api/hms/export_pick_list/`,
        { hub_id: localStorage.getItem("hubId") },
        {
          responseType: "blob",
          headers: {
            Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "pick-list.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((e) => {
        setDownloadErr(true);
        setTimeout(() => {
          setDownloadErr(false);
        }, 5000);
      });
  }
}
