import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import pdf2base64 from "pdf-to-base64";
import * as qz from "qz-tray";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Trans } from "react-i18next";
import { Redirect } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { ROOT_URL } from "../../actions/index";
import i18n from "../../deprecated/i18n";
import { resources } from "../../deprecated/i18n/index";
import * as CountryCues from "../../deprecated/audio";
import getDimWeightFromQubeVu from "./QubevuParser";
import { scanAudioAlert } from "../../utils/voiceTypeUtils";
import ZebraScanner from "../../common/zebraScanner/ZebraScanner";
import { Dropdown, Menu, Switch } from "antd";
import "antd/dist/antd.css";
import * as _ from "lodash";

export default class HMSCargoScanner extends Component {
  constructor(props) {
    super(props);
    this.bins = [];
    this.binsToShow = [];
    this.state = {
      cta: "",
      menutcta: "",
      menuType: "",
      hubId: "",
      userId: "",
      userName: "",
      requireErrorAcknowledgment: false,
      errorAcknowledgementRequired: false,
      qubevuStatus: "disabled",
      qubevuIP: "",
      hasSelectedScanType: false,
      err: false,
      currScanType: "",
      selectedShipment: "",
      currSortType: "country",
      metaScanType: "",
      isScanning: false,
      isScanComplete: true,
      isLatestScanSuccess: false,
      isLoggedIn: true,
      manualTrackingNo: "",
      labelPath: true,
      groupId: "",
      scannedItems: [],
      waitingForApiResponse: false,
      showAllScannedItems: false,
      copiedToClipboard: false,
      scannerBeepedOnce: [],
      autoRelabel: false,
      doFrom: this.getFromDateFormat(),
      doTo: this.getToDateFormat(),
      doGroupID: "",
      doHubID: "",
      doErr: false,
      dimUpdateSuccess: false,
      orderInfo_length: "",
      orderInfo_width: "",
      orderInfo_height: "",
      orderInfo_weight: "",
      showExceptionsBox: false,
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      showLabelNotFoundBox: false,
      selectedException: "",
      exceptionDescription: "",
      exceptions: [],
      saveExceptionSuccess: false,
      saveExceptionError: false,
      savingException: false,
      removeExceptionSuccess: false,
      reportType: "",
      bins: [],
      privateStatuses: null,
      defaultPrinter: null,
      statusCode: 0,
      voice: null,
      showCameraToScan: false,
      recipientsList: [],
      selectedRecipient: "",
      shipments: [],
      dataBoxConfig: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
        showSortDataBox: false,
        errorMessage: "",
        showDuplicateTrackingNumberError: false,
        merchantName: "",
        consigneeCountry: "",
        consigneePostalPreFix: "",
        consigneeStateCity: "",
        consigneeState: "",
        consigneeCity: "",
        consigneePostal: "",
        paymentType: "",
        incoterm: "DDP",
        showTrackingNumber: "",
        totalOrderValue: "",
        lastUpdateMessage: "",
        lastUpdateBy: "",
        cargoPostalCode: "",
        cargoNumber: "",
        nextRecipient: "",
        outboundNetworkPartner: "",
      },
    };

    this.trackingNumberInput = React.createRef();
  }

  getFromDateFormat() {
    let fromDate = moment();
    fromDate.set({ hour: "00", minute: "00", second: "00" });
    return fromDate;
  }

  getToDateFormat() {
    let toDate = moment();
    toDate.set({ hour: "23", minute: "59", second: "00" });
    return toDate;
  }

  playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  playCountryName(countryName) {
    if (CountryCues[countryName] !== undefined && this.isSortTypeCountry()) {
      let sound = new Audio("data:audio/wav;base64," + CountryCues[countryName].audioContent);
      sound.play();
    }
  }

  isInboundOrOutboundScantype() {
    if (this.state.currScanType) {
      return this.state.currScanType.includes("inbound") || this.state.currScanType.includes("outbound");
    } else {
      return false;
    }
  }

  componentDidMount() {
    const url = window.location.href;
    let pageType = url.substring(url.lastIndexOf("/") + 1);
    let menuType = url.substring(url.lastIndexOf("/") - 9, url.lastIndexOf("/"));
    pageType = pageType.includes("?") ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("?")) : pageType;

    const ctas = {
      pickup: "Pick Up",
      deliver: "Deliver",
      receive: "Receive",
      transfer: "Transfer",
      login: "Login",
      scan: "Scan",
    };
    const menuctas = {
      firstmile: "Firstmile",
      warehouse: "Warehouse",
      hubmanage: "HMS Scanner",
    };

    if (!this.state.cta && !this.state.menucta) {
      this.setState({
        cta: ctas[pageType],
        menucta: menuctas[menuType],
        menuType: menuType,
      });
    }

    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      this.setState({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
    let voices = window.speechSynthesis.getVoices();
    let defaultPrinter = localStorage.getItem("defaultPrinter");
    this.setState({ defaultPrinter: defaultPrinter, voice: voices[0] });
    this.getRecipientsList();
    this.fetchShipmentNumbersList();
  }

  fetchShipmentNumbersList = async () => {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
    };
    const pendingShipmentsData = { ...data, status: "pending" };
    const inProgressShipmentsData = { ...data, status: "ob_in_progress" };
    const pendingShipmentsResult = await axios.get(`${ROOT_URL}/hms/shipments/`, { params: pendingShipmentsData });
    const obInProgressShipmentsResult = await axios.get(`${ROOT_URL}/hms/shipments/`, {
      params: inProgressShipmentsData,
    });
    const pendingShipments = pendingShipmentsResult.data.shipments;
    const obInProgressShipments = obInProgressShipmentsResult.data.shipments;

    let shipments = [...pendingShipments, ...obInProgressShipments];
    shipments = shipments.slice(0, 15);
    this.setState({ shipments });
  };

  // recipients
  getRecipientsList = async () => {
    let recipientsResult = await axios.get(`${ROOT_URL}/hms/get_recipients/`);
    let recipientsList = recipientsResult.data;
    this.setState({ recipientsList: recipientsList });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate");
    console.log(this.state);
    if (prevState.latestScannedNumber === null && this.state.latestScannedNumber !== null) {
      this.submitValue();
    }
    if (
      prevState.latestScannedNumber === null &&
      this.state.latestScannedNumber !== null &&
      this.state.qubevuStatus === "enabled"
    ) {
      this.updateAutoDimWeight();
    }
    if (
      prevState.currScanType !== this.state.currScanType &&
      this.state.currScanType === "warehouse outbound" &&
      this.state.recipientsList.length == 0
    ) {
      let errorMessage = "Your hub has not configured network partners to send to. Call support immediately.";
      this.setState({
        dataBoxConfig: {
          showNoDataBox: false,
          showValidDataBox: false,
          showErrorDataBox: true,
          errorMessage,
          showTrackingNumber: "",
          showDuplicateTrackingNumberError: false,
        },
      });
    }
  }

  handleShipmentChange = (event) => {
    this.setState({
      selectedShipment: event.target.value,
    });
  };

  setRecipientsState = (event) => {
    this.setState({
      selectedRecipient: event.target.value,
    });
  };

  setScanningState(event) {
    let splitStr = event.target.value.split(" ");
    const lastPart = splitStr[splitStr.length - 1];
    let metaScanType =
      lastPart === "inbound" || lastPart === "outbound" || lastPart === "id" || lastPart === "sweep" ? lastPart : null;
    metaScanType = metaScanType === "id" ? "update group id" : metaScanType;

    if (this.state.currScanType !== "") {
      if (window.confirm("If you change your scan type, this will restart your scanning session.")) {
        this.resetScannedItems();
        // this.createSession();
        this.setState(
          {
            currScanType: event.target.value,
            metaScanType: metaScanType,
            dimUpdateSuccess: false,
            orderInfo_height: "",
            orderInfo_weight: "",
            orderInfo_length: "",
            orderInfo_width: "",
          },
          this.createSession
        );
      }
    } else {
      this.setState(
        {
          currScanType: event.target.value,
          metaScanType: metaScanType,
        },
        this.createSession
      );
    }
  }

  clearSession() {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("hubId");
    localStorage.removeItem("scanTypes");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("printersList");
    localStorage.removeItem("isQzConnected");
    this.setState({
      isLoggedIn: false,
    });
  }

  disconnectQzTray = () => {
    qz.websocket
      .disconnect()
      .then(() => {})
      .catch((e) => console.log("Error", e));
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  setStateToErrorState(errorMessage, statusCode) {
    this.playSound("error");
    console.log("setStateToErrorState");
    this.setState({
      dataBoxConfig: {
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
        errorMessage,
        showTrackingNumber: this.state.latestScannedNumber,
        showDuplicateTrackingNumberError: false,
      },
      errorAcknowledgementRequired: this.state.requireErrorAcknowledgment,
      dimUpdateSuccess: false,
      isScanComplete: true,
      isLatestScanSuccess: false,
      labelPath: false,
      showExceptionsBox: false,
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      saveExceptionError: false,
      saveExceptionSuccess: false,
      selectedException: "",
      exceptionDescription: "",
      privateStatuses: null,
      statusCode,
      orderInfo_length: "",
      orderInfo_width: "",
      orderInfo_height: "",
      orderInfo_weight: "",
    });
  }

  getSessionID(scanType) {
    return axios.post(`${ROOT_URL}/hms/session/`, {
      hub_id: this.state.hubId,
      hubscanner_key: this.state.userId,
      scan_type: scanType,
    });
  }

  async updateDimensions() {
    console.log("updateDimensions");
    const res = await this.updateDimWeight();
    if (res !== undefined && this.state.dataBoxConfig.consigneeCountry !== undefined) {
      this.playCountryName(this.state.dataBoxConfig.consigneeCountry.toLowerCase());
    }
    this.setState({
      dimUpdateSuccess: true,
    });
  }

  async updateAutoDimWeight() {
    console.log("updateAutoDimWeight");
    try {
      const dimWeight = await getDimWeightFromQubeVu(this.state.qubevuIP);
      this.setState({
        orderInfo_length: dimWeight.length,
        orderInfo_width: dimWeight.width,
        orderInfo_height: dimWeight.height,
        orderInfo_weight: dimWeight.weight,
      });
      await this.updateDimensions();
      return true;
    } catch (e) {
      console.log(e);
      console.log("AutoDimWeight update failed");
      return false;
    }
  }

  updateDimWeight() {
    console.log("updateDimWeight");
    if (this.state.scannedItems.length > 0) {
      return axios.put(`${ROOT_URL}/hms/update_order_dimweight/`, {
        hub_id: this.state.hubId,
        hubscanner_key: this.state.userId,
        orders: [
          {
            tracking_no: this.state.latestScannedNumber,
            length: this.state.orderInfo_length,
            width: this.state.orderInfo_width,
            height: this.state.orderInfo_height,
            weight: this.state.orderInfo_weight,
          },
        ],
      });
    }
  }

  async createSession() {
    try {
      // old
      const sessionId = await this.getSessionID(this.state.currScanType);
      localStorage.setItem("sessionId", sessionId.data.session_id);
    } catch (e) {
      console.log("error creating session id");
    }
  }

  acknowledgeError() {
    this.setState(
      {
        errorAcknowledgementRequired: false,
        showCancelledByCustomerBox: false,
        showHeldUpAtCustomsBox: false,
        dataBoxConfig: {
          showNoDataBox: true,
          showValidDataBox: false,
          showErrorDataBox: false,
        },
      },
      this.trackingNumberInput.current.focus()
    );
  }

  updateLatestScannedNumber() {
    if (this.state.errorAcknowledgementRequired) {
      this.playSound("error");
      this.setState({ manualTrackingNo: "" });
    } else {
      this.setState({ latestScannedNumber: null }, () => {
        this.setState({
          latestScannedNumber: this.state.manualTrackingNo,
          manualTrackingNo: "",
        });
      });
    }
  }

  async submitValue() {
    const { mixpanel } = this.props;
    const t0 = performance.now();
    this.setState({ waitingForApiResponse: true });
    this.setState({
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      dataBoxConfig: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
      },
    });
    let scannedItems = this.state.scannedItems;
    if (this.state.latestScannedNumber === "") return;
    if (this.state.latestScannedNumber != "") {
      if (await this.getCargoDetails(this.state.latestScannedNumber)) {
        if (await this.processScanOrder([this.state.latestScannedNumber])) {
          if (this.state.dataBoxConfig.consigneeCountry) {
            scanAudioAlert(this.state.dataBoxConfig.consigneeCountry.toLowerCase(), this.state.voice);
          }
          scannedItems.unshift(this.state.latestScannedNumber);
          this.setState({
            scannedItems,
            groupId: this.state.groupId,
            selectedRecipient: this.state.selectedRecipient,
            isLatestScanSuccess: true,
          });
        }

        const t1 = performance.now();
        // old
        mixpanel.track("Scan", {
          Success: true,
          "Duration in ms": t1 - t0,
          "Scanned tracking number": this.state.latestScannedNumber,
          "Number of tracking numbers": scannedItems.length,
          "Hub Name": this.state.hubId,
          "Hubscanner Name": this.state.userId,
          "Scan type": this.state.currScanType,
          "Session id": localStorage.getItem("sessionId"),
        });
      }
      return;
    } else {
      console.log("fetchOrderInfo returns False");
    }
  }

  getCargoDetails = (cargoNumber) => {
    let data = {
      hub_id: this.state.hubId,
      hubscanner_key: this.state.userId,
      tracking_no: cargoNumber,
    };
    return axios
      .get(`${ROOT_URL}/hms/get_cargo_details/`, {
        params: data,
      })
      .then((res) => {
        return this.setCargoDetailsBasedOnStatusCode(res);
      })
      .catch((e) => {
        this.setStateToErrorState("Cargo Number not found", e.status);
        return false;
      });
  };

  setCargoDetailsBasedOnStatusCode = (res) => {
    if (res.status == 204) {
      this.setStateToErrorState("Cargo Number Not Found", res.status);
      return false;
    } else {
      this.setState({
        dataBoxConfig: {
          showNoDataBox: false,
          showValidDataBox: true,
          showErrorDataBox: false,
          showSortDataBox: false,
          errorMessage: "",
          showDuplicateTrackingNumberError: false,
          merchantName: "",
          consigneeCountry: res.data.dest_country,
          consigneePostalPreFix: res.data.dest_poscode_prefix,
          consigneeStateCity: "",
          consigneeState: "",
          consigneeCity: "",
          consigneePostal: "",
          paymentType: "",
          incoterm: "",
          showTrackingNumber: "",
          totalOrderValue: "",
          lastUpdateMessage: "",
          lastUpdateBy: "",
          cargoPostalCode: "",
          cargoNumber: res.data.tracking_no,
          nextRecipient: res.data.next_recipient,
        },
      });
      return true;
    }
  };

  getGroupId = () => {
    if (!_.isEmpty(this.state.groupId)) {
      return this.state.groupId;
    } else {
      return this.state.selectedRecipient;
    }
  };

  processScanOrder = (trackingNumbers) => {
    // old
    return axios
      .post(`${ROOT_URL}/hms/process_scanned_orders/`, {
        hub_id: this.state.hubId,
        hubscanner_key: this.state.userId,
        group_id: this.getGroupId(),
        scan_type: this.state.currScanType,
        session_id: localStorage.getItem("sessionId"),
        tracking_nos: trackingNumbers,
        shipment_no: this.state.selectedShipment,
        synchronous: true,
      })
      .then((res) => {
        if (res && res.data && this.state.currScanType == "warehouse outbound") {
          this.setNetworkPartner(res.data);
        }
        return true;
      })
      .catch((e) => {
        let errorMessage = this.getErrorMessage(e.data);
        this.setState({
          dataBoxConfig: {
            showErrorDataBox: true,
            showTrackingNumber: "",
            errorMessage: errorMessage,
          },
          statusCode: e.status,
        });
        return false;
      });
  };

  getErrorMessage = (data) => {
    let message = data.tracking_nos && data.tracking_nos[0] ? data.tracking_nos[0] : data.hub_id[0];
    return message;
  };

  setNetworkPartner = (data) => {
    let { dataBoxConfig } = this.state;
    dataBoxConfig.outboundNetworkPartner = data.group_id;
    this.setState({ dataBoxConfig: dataBoxConfig });
  };

  resetScannedItems() {
    this.setState({
      scannedItems: [],
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      showCameraToScan: false,
      selectedRecipient: "",
      groupId: "",
      dataBoxConfig: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
        outboundNetworkPartner: "",
      },
      manualTrackingNo: "",
    });
  }

  printLabel() {
    if (!this.state.labelPath) {
      return;
    } else {
      pdf2base64(this.state.labelPath)
        .then((response) => {
          var config = qz.configs.create(this.state.defaultPrinter);
          var data = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          qz.print(config, data).then(function () {});
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  validateNumber(val) {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(val)) {
      return true;
    } else {
      return false;
    }
  }

  getMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  };

  onChangeSwitch = (checked) => {
    this.setState({ showCameraToScan: checked });
  };

  // getScanSettings = () => {
  //   return new ScanSettings({
  //     enabledSymbologies: ["qr", "ean8", "ean13", "upca", "upce", "code128", "code39", "code93", "itf"],
  //     codeDuplicateFilter: 1000
  //   });
  // }

  onScanComplete = (scanResult) => {
    let trackingNumber = scanResult.text;
    this.setState({ manualTrackingNo: trackingNumber });
    this.updateLatestScannedNumber();
  };

  onScanError = (error) => {
    console.error(error.message);
  };

  isInboundScanType() {
    let scanTypes = localStorage.getItem("scanTypes");
    scanTypes = JSON.parse(scanTypes);
    let selectedScanType = _.find(scanTypes, (x) => x.name === this.state.currScanType);
    if (selectedScanType && selectedScanType.type === "inbound") {
      console.log("selectedScanType", selectedScanType, true);
      return true;
    }
    return false;
  }

  getScanTypesDropDown = () => {
    let scanTypes = localStorage.getItem("scanTypes");
    scanTypes = JSON.parse(scanTypes);
    const dropdownScanTypes = scanTypes.map((scannedType) => {
      return <option value={scannedType.name}>{scannedType.name.toUpperCase()}</option>;
    });
    return dropdownScanTypes;
  };

  render() {
    if (!this.state.isLoggedIn) {
      return <Redirect push to="/scan/hubmanage/old/login" />;
    }

    let numberOfScannedItemsToShow = this.state.scannedItems.length;
    if (!this.state.showAllScannedItems) {
      numberOfScannedItemsToShow = 25;
    }

    const renderScannedItems = this.state.scannedItems.slice(0, numberOfScannedItemsToShow).map((scannedItem) => {
      return (
        <div className="hms-inbound">
          <label className="tracking-number">{scannedItem}</label>
        </div>
      );
    });

    const dropdownRecipients = this.state.recipientsList.map((recipient) => {
      return <option value={recipient.name}>{recipient.name}</option>;
    });

    const dropdownShipments =
      this.state.shipments &&
      this.state.shipments.length > 0 &&
      this.state.shipments.map((shipment) => {
        return (
          <option value={shipment.tracking_no}>
            {shipment.recipient_name}-{shipment.tracking_no}
          </option>
        );
      });

    const hasZeroRecipients = this.state.currScanType === "warehouse outbound" && this.state.recipientsList.length == 0;

    const recipientsListError = hasZeroRecipients ? "pl-5 pr-5" : "";
    const postalPrefixEmpty = this.state.dataBoxConfig.consigneePostalPreFix == "" ? "mt-5 pt-2" : "";

    const shipmentContainerClass = this.isInboundScanType() ? "d-block" : "d-none";

    const shipmentsErrorClass = this.state.shipments && this.state.shipments.length === 0 ? "" : "d-none";

    const exceptionButtonClass = this.state.showExceptionsBox ? "oi oi-chevron-top" : "oi oi-chevron-bottom";
    const dimInputClass = this.state.dimUpdateSuccess ? "form-control success-green" : "form-control";
    const noDataBoxClass = this.state.dataBoxConfig.showNoDataBox
      ? "content-container card mb-1"
      : "content-container card mb-1 d-none";
    const validSortDataBoxClass = this.state.dataBoxConfig.showSortDataBox
      ? "content-container card mb-1 valid-sort-container"
      : "content-container card mb-1 d-none";
    const errorDataBoxClass = this.state.dataBoxConfig.showErrorDataBox
      ? "content-container card mb-1 error-data-container"
      : "content-container card mb-1 error-data-container d-none";
    const validDataBoxClass = this.state.dataBoxConfig.showValidDataBox
      ? "content-container card mb-1 valid-data-container"
      : "content-container card mb-1 valid-data-container d-none";
    const errorImageClass = this.state.dataBoxConfig.showDuplicateTrackingNumberError
      ? "err-img err-duplicate-tn"
      : "err-img err-no-data";
    const printLabelClass =
      this.state.isLatestScanSuccess && this.state.labelPath
        ? "btn btn-lg btn-janio text-white btn-block rounded-btn"
        : "btn btn-lg btn-grey text-white btn-block rounded-btn";
    const qubevuStatusClass =
      this.state.qubevuStatus === "enabled"
        ? "badge badge-success text-capitalize"
        : "badge badge-danger text-capitalize";
    let sortString = "NO DATA";
    let line2 = "";
    let line3 = "";
    let line4 = "";
    if (this.state.currSortType === "state") {
      sortString = this.state.dataBoxConfig.consigneeState !== null ? this.state.dataBoxConfig.consigneeState : "NA";
      line2 = this.state.dataBoxConfig.consigneeCountry !== null ? this.state.dataBoxConfig.consigneeCountry : "";
    } else if (this.state.currSortType === "city") {
      sortString = this.state.dataBoxConfig.consigneeCity !== null ? this.state.dataBoxConfig.consigneeCity : "NA";
      line2 = this.state.dataBoxConfig.consigneeState !== null ? this.state.dataBoxConfig.consigneeState : "";
      line3 = this.state.dataBoxConfig.consigneeCountry !== null ? this.state.dataBoxConfig.consigneeCountry : "";
    } else if (this.state.currSortType === "postal") {
      sortString = this.state.dataBoxConfig.consigneePostal !== null ? this.state.dataBoxConfig.consigneePostal : "NA";
      line2 = this.state.dataBoxConfig.consigneeCity !== null ? this.state.dataBoxConfig.consigneeCity : "";
      line3 = this.state.dataBoxConfig.consigneeState !== null ? this.state.dataBoxConfig.consigneeState : "";
      line4 = this.state.dataBoxConfig.consigneeCountry !== null ? this.state.dataBoxConfig.consigneeCountry : "";
    } else if (this.state.statusCode == 500) {
      sortString = "Server Error 500";
    }

    let countryNameClass = this.state.dataBoxConfig.consigneePostalPreFix == "" ? "mt-4" : "mt-n2";

    return (
      <div className="main-container d-flex flex-column align-items-left">
        <div>
          <h1 className="font-weight-bolder pl-0 float-left">Cargo Scan</h1>
          <div className="float-right mt-2">
            <Dropdown overlay={this.getMenu()} placement="bottomLeft" trigger={["click"]}>
              <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
                Menu
              </button>
            </Dropdown>
            <button type="button" className="btn btn-outline-danger" onClick={this.clearSession.bind(this)}>
              End Session
            </button>
          </div>
        </div>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <LinkContainer to="/scan">
                <a>
                  <Trans>Home</Trans>
                </a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item">
              <LinkContainer to={`/scan/${this.state.menuType}`}>
                <a>
                  <Trans>{this.state.menucta}</Trans>
                </a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Cargo Scan
            </li>
          </ol>
        </nav>
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <div id="hub-id" style={{ marginBottom: 1 + "rem" }}>
              <label>
                <Trans>Hub ID</Trans>
              </label>
              <div>
                <label>{this.state.hubId}</label>
              </div>
            </div>
            <div id="user-id" style={{ marginBottom: 1 + "rem" }}>
              <label>
                <Trans>User ID</Trans>
              </label>
              <div>
                <label>{this.state.userName}</label>
              </div>
            </div>
            <div id="qubevu-status">
              <label>
                <Trans>QubeVu Status</Trans>
              </label>
              <div>
                <label className={qubevuStatusClass}>{this.state.qubevuStatus}</label>
              </div>
            </div>
          </div>
        </div>

        <div className="content-container card mb-1 p-2 cnt-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <label>
              <Trans>Scan Type</Trans>
            </label>
            <div>
              <select
                className="form-control"
                value={this.state.currScanType}
                onChange={this.setScanningState.bind(this)}
              >
                <option disabled selected value="">
                  Please select type
                </option>
                {this.getScanTypesDropDown()}
              </select>
            </div>
          </div>

          <div className={`form-group justify-content-between px-3.5 ${shipmentContainerClass}`}>
            <label>Link to Shipment</label>
            <div>
              <select
                className="form-control"
                value={this.state.selectedShipment}
                onChange={this.handleShipmentChange}
                disabled={this.state.shipments.length === 0}
              >
                <option disabled selected value="">
                  Please select a shipment
                </option>
                {dropdownShipments}
              </select>
            </div>
            <div className={"shipment-numbers-error " + shipmentsErrorClass}>
              <p className="mb-0">No shipment pending. Create shipment first for dropdown to populate.</p>
            </div>
          </div>

          {this.state.currScanType === "warehouse outbound" && (
            <div className="form-group d-block justify-content-between px-3.5">
              <label>Send to Network Partner</label>
              <div>
                <select
                  className="form-control"
                  value={this.state.selectedRecipient}
                  disabled={_.isEmpty(this.state.selectedRecipient) && !_.isEmpty(this.state.groupId)}
                  onChange={this.setRecipientsState}
                >
                  <option selected value="">
                    Please select Network Partner
                  </option>
                  {dropdownRecipients}
                </select>
              </div>
            </div>
          )}

          {this.state.currScanType === "" || this.state.metaScanType === null ? null : (
            <div className="form-group d-block justify-content-between px-3.5">
              <label>
                <Trans>Group ID (Optional)</Trans>
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.groupId}
                  disabled={!_.isEmpty(this.state.selectedRecipient) && _.isEmpty(this.state.groupId)}
                  onChange={(e) => {
                    const inputVal = e.target.value.toUpperCase();
                    this.setState({
                      groupId: inputVal,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>

        {this.state.currScanType === "sort by" && this.state.currSortType !== "country" ? (
          <div className={validSortDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen">
              {this.state.currSortType == "np" && this.state.bins.length > 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {this.binsToShow.map((bin) => {
                    if (bin.type && bin.type !== "NP") {
                      return <div className="line1 mt-3">No NP bin type configured</div>;
                    } else if (bin.type) {
                      return <div className="line1 mt-3">{bin.name}</div>;
                    } else {
                      return <div className="line1 mt-3">No NP bin type configured</div>;
                    }
                  })}
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <label className="line1">{sortString}</label>
                  <label className="otherlines">{line2}</label>
                  <label className="otherlines">{line3}</label>
                  <label className="otherlines">{line4}</label>
                </div>
              )}
            </div>
          </div>
        ) : null}

        {this.state.currScanType === "" ||
        this.state.currScanType === "print do" ||
        this.state.currSortType !== "country" ? null : (
          <div id="valid-data-box" className={validDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-valid-info">
              <label className="country-name">{this.state.dataBoxConfig.consigneeCountry}</label>
              {this.state.dataBoxConfig.consigneePostalPreFix !== "" && (
                <div className="d-flex justify-content-center mb-2">
                  <label className="meta-info green-border-right green-border-left postcode-postfix post-code-bg">
                    {this.state.dataBoxConfig.consigneePostalPreFix}
                  </label>
                </div>
              )}
              {this.state.dataBoxConfig.consigneeStateCity && (
                <div>
                  <label className="destination-hub">{this.state.dataBoxConfig.consigneeStateCity}</label>
                </div>
              )}
              {_.isEmpty(this.state.dataBoxConfig.outboundNetworkPartner) && this.state.dataBoxConfig.cargoNumber && (
                <div>
                  <label className={"destination-hub " + countryNameClass}>Cargo Number</label>
                  <label className="destination-hub mt-0">{this.state.dataBoxConfig.cargoNumber}</label>
                </div>
              )}
              {this.state.dataBoxConfig.outboundNetworkPartner && (
                <div className={postalPrefixEmpty}>
                  <label className="merchant-name">
                    {this.state.dataBoxConfig.cargoNumber} outbounded to{" "}
                    {this.state.dataBoxConfig.outboundNetworkPartner}
                  </label>
                </div>
              )}
              {this.state.dataBoxConfig.nextRecipient && (
                <div style={{ float: "left", marginLeft: "10px" }}>
                  <h5>
                    <span
                      class="badge"
                      style={{
                        backgroundColor: "rgb(245, 203, 66)",
                        color: "rgb(0, 0, 255)",
                        marginRight: "4px",
                      }}
                    >
                      {this.state.dataBoxConfig.nextRecipient}
                    </span>
                  </h5>
                </div>
              )}
              {this.state.dataBoxConfig.cargoPostalCode && (
                <div style={{ float: "right", marginRight: "10px" }}>
                  Destination Postal Code &nbsp;
                  <label className="destination-hub mt-0">{this.state.dataBoxConfig.cargoPostalCode}</label>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.currScanType === "" ||
        this.state.currScanType === "print do" ||
        this.state.currSortType !== "country" ? null : !this.state.showCancelledByCustomerBox ? null : (
          <div class="alert alert-warning mt-2" role="alert">
            <Trans>CANCELLED BY CUSTOMER, STOP DELIVERY.</Trans>
          </div>
        )}

        {this.state.currScanType === "" ||
        this.state.currScanType === "print do" ||
        this.state.currSortType !== "country" ? null : !(
            this.state.showHeldUpAtCustomsBox && this.isInboundOrOutboundScantype()
          ) ? null : (
          <div class="alert alert-warning mt-2" role="alert">
            <Trans>FURTHER CHECKS BY CUSTOMS REQUIRED.</Trans>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div id="error-data-box" className={errorDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-error-info">
              <div id="err-image" className={errorImageClass}>
                &nbsp;
              </div>
              <h4 className={`err-title text-white ${recipientsListError}`}>{this.state.dataBoxConfig.errorMessage}</h4>
              {this.state.dataBoxConfig.showTrackingNumber === "" ? null : (
                <div>
                  <h4 className="err-tracking-number text-white">
                    Cargo Number:{" "}
                    <h4 className="text-white" style={{ textTransform: "uppercase" }}>
                      {this.state.dataBoxConfig.showTrackingNumber}
                    </h4>
                  </h4>
                </div>
              )}
              {!this.state.errorAcknowledgementRequired ? null : (
                <div>
                  <h3 class="font-weight-bold text-white">ACKNOWLEDGEMENT REQUIRED</h3>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : !this.state
            .errorAcknowledgementRequired ? null : (
          <div className={"content-container card mb-1 " + errorDataBoxClass}>
            <div className="d-block justify-content-between px-3.5">
              <button
                className="btn btn-lg btn-danger btn-block rounded-btn"
                style={{ "background-color": "#e31920", "border-color": "#e31920" }}
                onClick={this.acknowledgeError.bind(this)}
              >
                <Trans>Acknowledge Error</Trans>
              </button>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div id="no-data-box" className={noDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-no-info">
              <label style={{ marginTop: "95px" }}>
                <Trans>No data shown here</Trans>
              </label>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div className="content-container card mb-1">
            <div className="d-block justify-content-between px-3.5">
              <button className={printLabelClass} onClick={this.printLabel.bind(this)}>
                <Trans>Print label</Trans>
              </button>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div className="d-block justify-content-between px-3.5" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                onClick={(e) => {
                  this.setState({ autoRelabel: !this.state.autoRelabel });
                }}
              />
              <label class="form-check-label" for="defaultCheck1">
                Auto Print Label
              </label>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div className="content-container card mb-1">
            <div className="form-group d-block justify-content-between px-3.5 m-2">
              {this.state.showCameraToScan ? (
                <ZebraScanner onScanComplete={(scanResult) => this.onScanComplete(scanResult)} />
              ) : (
                <div className="input-group">
                  <input
                    name="manualTrackingNo"
                    type="text"
                    className="form-control"
                    placeholder={resources[i18n.language]["translations"]["Tracking No(s)"]}
                    value={this.state.manualTrackingNo}
                    onChange={(e) => this.handleOnChange(e)}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 || e.which === 13) {
                        this.updateLatestScannedNumber();
                      }
                    }}
                    ref={this.trackingNumberInput}
                    disabled={hasZeroRecipients}
                  />
                  <div>
                    <button
                      className="ml-1 btn btn-janio text-white"
                      disabled={hasZeroRecipients}
                      onClick={() => {
                        this.updateLatestScannedNumber();
                      }}
                    >
                      <Trans>Add</Trans>
                    </button>
                  </div>
                </div>
              )}
            </div>

            <div className="form-group d-block justify-content-between px-3.5 m-2">
              <b>Laser</b> <Switch className="scanner-switch" onChange={this.onChangeSwitch} /> <b>Camera</b>
            </div>

            <div className="form-group d-block justify-content-between px-3.5 mt-2 mb-2 pt-0 pb-0">
              <label style={{ float: "left" }} className="pb-0 mb-0">
                For tracking number:{" "}
                {this.state.isLatestScanSuccess && this.state.latestScannedNumber
                  ? this.state.latestScannedNumber
                  : "N/A"}{" "}
                (last success scan)
              </label>
            </div>

            {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : this.state.scannedItems
                .length <= 25 ? null : (
              <div>
                <div className="content-container card my-1">
                  <div className="d-block justify-content-between px-3.5">
                    <button
                      className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}
                      onClick={() => {
                        this.setState({
                          showAllScannedItems: !this.state.showAllScannedItems,
                        });
                      }}
                    >
                      {this.state.showAllScannedItems ? (
                        <Trans>Show only 25 scanned parcel</Trans>
                      ) : (
                        <Trans>Show all scanned parcels</Trans>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {this.state.currScanType == "sweep" ? null : (
              <div className="scan-status">
                <div style={{ marginTop: "30px" }}>
                  <label>
                    <span className="parcel-count-dark">{this.state.scannedItems.length}</span> parcel scanned
                  </label>
                </div>
              </div>
            )}

            {this.state.currScanType != "sweep" && this.state.scannedItems.length > 0 ? (
              <div className="hms-inbound">
                {this.state.metaScanType !== "" && (
                  <label className="inbound-label">
                    <Trans>{this.state.metaScanType}</Trans>
                  </label>
                )}
                {this.state.currScanType == "sort by" && <div className="text-center sweep-exception-text">sort</div>}
              </div>
            ) : null}
            {renderScannedItems}

            {this.state.currScanType === "" || this.state.scannedItems.length === 0 ? null : (
              <div>
                <div className="content-container card mt-1">
                  <div className="d-block justify-content-between px-3.5">
                    <CopyToClipboard
                      text={this.state.scannedItems.join("\r\n")}
                      onCopy={() => {
                        this.setState({ copiedToClipboard: true });
                        setTimeout(() => {
                          this.setState({ copiedToClipboard: false });
                        }, 2000);
                      }}
                    >
                      <button className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}>
                        {this.state.copiedToClipboard ? <Trans>Copied!</Trans> : <Trans>Copy to clipboard</Trans>}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
