import React from "react";

export const CargoSuccessNotificationArea = ({
  validDataBoxClass,
  scanType,
  successNotificationData,
  latestScannedNumber,
}) => {
  let component = (
    <>
      <div className="success-notification-50-bold text-center">{successNotificationData.cargoDescription}</div>
      {successNotificationData.security_tag_info?.history && (
        <div className="success-notification-25">
          {successNotificationData.security_tag_info.history.length > 0 && "NEW"} SECURITY TAG (
          {successNotificationData.security_tag_info.security_tag_no})
        </div>
      )}
      <span className="success-notification-25">CARGO: {latestScannedNumber}</span>
    </>
  );
  if (scanType === "cargo arrival") {
    component = (
      <>
        <div className="success-notification-25-bold text-center">{successNotificationData.cargoMessage}</div>
        <div className="success-notification-50-bold text-center">{successNotificationData.cargoDescription}</div>
        <div className="success-notification-25 text-center margin-top-20">{latestScannedNumber}</div>
      </>
    );
  }
  if (scanType === "cargo break") {
    component = (
      <>
        <div className="success-notification-25-bold text-center">{successNotificationData.cargoMessage}</div>
        <div className="success-notification-50-bold text-center margin-bottom-20">
          {successNotificationData.cargoDescription}
        </div>
        <div className="success-notification-25 text-center bg-yellow text-black">
          {successNotificationData?.security_tag_info &&
          Object.keys(successNotificationData.security_tag_info).length > 0
            ? `${successNotificationData.security_tag_info?.history?.length > 0 ? "NEW" : ""} SECURITY TAG`
            : "NO SECURITY TAG"}
        </div>
        {successNotificationData.security_tag_info &&
          Object.keys(successNotificationData.security_tag_info).length > 0 && (
            <div className="success-notification-25 text-center margin-bottom-20">
              TAG: {successNotificationData.security_tag_info.security_tag_no}
            </div>
          )}
        <div className="success-notification-25 text-center">CARGO: {latestScannedNumber}</div>
      </>
    );
  }

  return (
    <div
      className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
    >
      {component}
    </div>
  );
};
