import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { parcelScanApi } from "../../../api";
import { Divider, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import * as _ from "lodash";

export default function RemedyExceptions(props) {
  const { scannerInformation, scanType, latestScannedNumber, downloadExceptionImage, fetchOrderInfo } = props;

  const { sessionId } = useSelector((state) => ({
    sessionId: state.auth.sessionId,
  }));

  let scannedNumber = latestScannedNumber;

  if (typeof latestScannedNumber === "object" && latestScannedNumber !== null) {
    scannedNumber = latestScannedNumber.tracking_no;
  }

  const [showNoExceptionRemedy] = useState(true);

  const [orderData, setOrderData] = useState(props.orderData);

  useEffect(() => {
    if (!_.isEqual(orderData, props.orderData)) {
      setOrderData(props.orderData);
    }
  }, [props.orderData]);

  const [removeException, setRemoveException] = useState({
    success: false,
    error: false,
    exceptionId: null,
  });

  const [showRemedyModal, setShowRemedyModal] = useState(false);
  const [selectedException, setSelectedException] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const exceptions = orderData.exceptions;

  const remedyNoExceptionContainerClass =
    scannedNumber && scanType === "sweep" && orderData.exceptions && orderData.exceptions.length === 0 ? "" : "d-none";
  const remedyNoExceptionClass = showNoExceptionRemedy ? "" : "d-none";
  const emptyRemedyExceptionsDataClass =
    (orderData.exceptions && orderData.exceptions.length === 0) || !orderData.exceptions ? "" : "d-none";
  const remedyExceptionsDataClass = orderData.exceptions && orderData.exceptions.length > 0 ? "" : "d-none";
  const showExceptions =
    scannedNumber && scanType === "sweep" && orderData.exceptions && orderData.exceptions.length > 0;

  return (
    <React.Fragment>
      <div className={"remedy-exception-container " + remedyNoExceptionContainerClass}>
        <div className="d-flex flex-nowrap align-items-center">
          <span className="toggle-heading mr-auto">Sweep Exception for {scannedNumber}</span>
        </div>
        <div className={"remedy-exception-content " + remedyNoExceptionClass}>
          <span className={"empty-remedy-exception-message " + emptyRemedyExceptionsDataClass}>None</span>
        </div>
      </div>
      {showExceptions &&
        exceptions.map((exception, index) => {
          return (
            <div key={index} className="remedy-exception-container">
              <div className="d-flex flex-nowrap align-items-center">
                {!exception.exceptionRemedied && (
                  <span className="toggle-heading mr-auto">Sweep Exception {exception["exception_number"]}</span>
                )}
                {exception.exceptionRemedied && (
                  <span className="toggle-heading-success mr-auto">
                    Exception {exception["exception_number"]} Remedied
                  </span>
                )}
              </div>
              <div className="remedy-exception-content">
                <div className="d-flex flex-column">
                  <div className={"row " + remedyExceptionsDataClass}>
                    <div className="col-6">
                      <label className="remedy-exception-label--text-label">Tracking Number</label>
                      <p className="mb-0 remedy-exception-text">{scannedNumber}</p>
                    </div>
                    <div className="col-6">
                      <label className="remedy-exception-label--text-label">Reporter</label>
                      <p className="mb-0 remedy-exception-text">{exception["created_by"]}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Exception Creation Date</label>
                      <p className="mb-0 remedy-exception-text">{exception["created_on"]}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Location</label>
                      <p className="mb-0 remedy-exception-text text-uppercase">{exception["module_name"]}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Type</label>
                      <p className="mb-0 remedy-exception-text">{exception["type"]}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Description</label>
                      <p className="mb-0 remedy-exception-text">{exception["note"]}</p>
                    </div>
                    <div className="col-6 remedy-content-spacing">
                      <label className="remedy-exception-label--text-label">Photo</label>
                      <p
                        className="mb-0 remedy-exception-text remedy-link-color"
                        onClick={() => downloadExceptionImage(exception["photo_url"])}
                      >
                        {imageFileName(exception["photo_url"])}
                      </p>
                    </div>
                    {exception.exceptionRemedied && (
                      <div className="col-6 remedy-content-spacing">
                        <label className="remedy-exception-label">Reason to Remedy Exception</label>
                        <p className="mb-0 remedy-exception-text">{exception.remedy_reason}</p>
                      </div>
                    )}
                    {!exception.exceptionRemedied && (
                      <React.Fragment>
                        <div className="col-12">
                          <Divider className="gray-divider" />
                        </div>
                        <div className={`w-100 ${getRemedyInputClass(exception.id)}`}>
                          <div className="col-12">
                            <label className="remedy-exception-label">Reason to Remedy Exception (Optional)</label>
                            <input
                              className="form-control scan-input"
                              placeholder="Type here"
                              value={exception.remedy_reason}
                              onChange={(e) =>
                                onChangeRemedyExceptionReason("remedy_reason", e.target.value, exception.id)
                              }
                            />
                          </div>
                          <div className="col-12">
                            <button
                              className="remedy-exception-button"
                              onClick={() => handleRemedyExceptionClick(exception.id)}
                            >
                              Remedy Exception {exception["exception_number"]}
                            </button>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <Modal
        className="remedy-modal"
        visible={showRemedyModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "remedy-modal-primary-button" }}
        cancelButtonProps={{ className: "remedy-modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="remedy-heading mb-0">Please confirm remedy exception.</h2>
          <p className="remedy-paragraph mb-0">After you have remedied this exception, it cannot be undone.</p>
          <Divider className="gray-divider" />
        </div>
        <div className="text-center">
          <button className="remedy-modal-secondary-button" onClick={handleRemedyExceptionClick}>
            Cancel
          </button>
          <button
            className="remedy-modal-primary-button"
            disabled={requestInProgress}
            onClick={() => resolveException(selectedException)}
          >
            {requestInProgress ? <LoadingOutlined className="loading-spinner loading-spinner-white" /> : "Confirm"}
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );

  function imageFileName(photoUrl) {
    if (photoUrl) {
      photoUrl = photoUrl.split("/");
      return photoUrl[photoUrl.length - 1];
    }
    return "-";
  }

  function getRemedyInputClass(id) {
    if (removeException.success && removeException.exceptionId === id) {
      return "d-none";
    }
    return "";
  }

  function onChangeRemedyExceptionReason(name, value, id) {
    let exceptionsData = [...orderData.exceptions];
    const selectedIndex = _.findIndex(exceptionsData, (x) => x.id === id);
    exceptionsData[selectedIndex] = {
      ...exceptionsData[selectedIndex],
      [name]: value,
    };
    setOrderData({
      ...orderData,
      exceptions: exceptionsData,
    });
  }

  function handleRemedyExceptionClick(exceptionId) {
    setShowRemedyModal(!showRemedyModal);
    setSelectedException(exceptionId);
  }

  async function resolveException(exceptionId) {
    if (exceptionId != null) {
      setRequestInProgress(true);
      let selectedException = _.find(orderData.exceptions, (x) => x.id === exceptionId);
      let reason = selectedException.remedy_reason ? selectedException.remedy_reason : "-";
      axios
        .post(parcelScanApi.REMEDY_EXCEPTION, {
          hub_id: scannerInformation.hubId,
          hubscanner_key: scannerInformation.userId,
          scan_type: scanType,
          session_id: sessionId,
          orders: [
            {
              tracking_no: scannedNumber.trim(),
              resolutions: [
                {
                  exception_id: exceptionId,
                  action: reason,
                },
              ],
            },
          ],
        })
        .then((res) => {
          setShowRemedyModal(false);
          setRemoveException({
            success: true,
            error: false,
            exceptionId: exceptionId,
          });
          setTimeout(() => {
            setRemoveException({
              success: false,
              error: false,
              exceptionId: null,
            });
            setRequestInProgress(false);
          }, 2500);
          fetchOrderInfo(scannedNumber, true);
          onChangeRemedyExceptionReason("exceptionRemedied", true, exceptionId);
          message.success(`Exception ${selectedException.exception_number} Remedied`);
        })
        .catch((err) => {
          setShowRemedyModal(false);
          setRemoveException({
            success: false,
            error: true,
            exceptionId: exceptionId,
          });
          setTimeout(() => {
            setRemoveException({
              success: false,
              error: false,
              exceptionId: null,
            });
            setRequestInProgress(false);
          }, 2500);
        });
    }
  }
}
