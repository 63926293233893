import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Divider } from "antd";
import { cargoActions } from "../../../actions/rootActions";
import { LinkContainer } from "react-router-bootstrap";

function SeePhotos(props) {
  const { photosData, photosDataError } = props;

  const { cargo } = props.location.state;

  // move it to cargo actions file and pass images as props from cargo scanner
  useEffect(() => {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      session_id: props.sessionId,
      tracking_no: cargo.tracking_no.trim(),
    };
    props.dispatch(cargoActions.getCargoPhotos(data));
  }, []);

  function renderPhotosOrError() {
    if (photosData && photosData.length > 0) {
      const photosElements = photosData.map((photo, idx) => {
        return (
          <div className="link-title mb-2" key={`${idx}-${photo.image_url}`}>
            <a href={photo.image_url}>{photo.image_name}</a>
          </div>
        );
      });
      return photosElements;
    } else {
      return <div className="success-notification-20 text-secondary">Photos not available</div>;
    }
  }

  return (
    <div className="scanner-bg-container">
      <div className="bg-container w-100 mt-0">
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <LinkContainer to="/scan/hubmanage/cargo-scan">
            <p className="link-title mb-0">Cargo Scan</p>
          </LinkContainer>
          <p className="bread-crumb mt-n1 mb-0">{">"}</p>
          <p className="title text-capitalize mb-0">See Photos</p>
        </div>
        <div className="bg-container-body">
          <h2 className="text-heading-black margin-bottom-20">Photo(s) for {cargo.tracking_no}</h2>
          <div className="d-flex flex-column mb-2">{renderPhotosOrError()}</div>
          <Divider className="gray-divider" />
          <button onClick={goBackToPreviousScreen} className="secondary-button w-sm-100 mb-sm-1">
            Back
          </button>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousScreen() {
    props.history.goBack();
  }
}

const mapStateToProps = (state) => ({
  photosData: state.cargo.photosData,
  photosDataError: state.cargo.photosDataError,
  sessionId: state.auth.sessionId,
});

export default connect(mapStateToProps)(SeePhotos);
