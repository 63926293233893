export let QZ_TRAY_CERTIFICATE = "-----BEGIN CERTIFICATE-----\n" +
    "MIIDxDCCAqwCCQDAr5lzRXvYizANBgkqhkiG9w0BAQsFADCBojELMAkGA1UEBhMC\n" +
    "U0cxEjAQBgNVBAgMCVNpbmdhcG9yZTESMBAGA1UEBwwJU2luZ2Fwb3JlMRswGQYD\n" +
    "VQQKDBJKYW5pbyBUZWNobm9sb2dpZXMxFDASBgNVBAsMC0hNUyBTY2FubmVyMRYw\n" +
    "FAYDVQQDDA1KYW5pbyBTY2FubmVyMSAwHgYJKoZIhvcNAQkBFhFycnVwaWFoQGdt\n" +
    "YWlsLmNvbTAgFw0yMDA2MTMwOTMwNDJaGA8yMDUxMTIwNzA5MzA0MlowgaIxCzAJ\n" +
    "BgNVBAYTAlNHMRIwEAYDVQQIDAlTaW5nYXBvcmUxEjAQBgNVBAcMCVNpbmdhcG9y\n" +
    "ZTEbMBkGA1UECgwSSmFuaW8gVGVjaG5vbG9naWVzMRQwEgYDVQQLDAtITVMgU2Nh\n" +
    "bm5lcjEWMBQGA1UEAwwNSmFuaW8gU2Nhbm5lcjEgMB4GCSqGSIb3DQEJARYRcnJ1\n" +
    "cGlhaEBnbWFpbC5jb20wggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQDP\n" +
    "+9iPfkSpn3WHeERPEntkYThjK2z7Twm+w8GmojrXFr0qRnmNdv65Fa4322sF6vu+\n" +
    "CKHXUthbwYyqAJumIFCJukIxbT/JGdU9cES1vHx781vAqNK9D9QiXJn5Z0EkHJGF\n" +
    "aa7cxfC3Dr17u80mM0Vnbe5Ami8utElMEhV0kUH7ElilMmTiO5fjxmZydwv3TLUl\n" +
    "Bgc54Tw1LzzAYTachiffd7kohW+WAVdl3vZluiqyqgQ2cJq/8g+XH22hqOf8F0Re\n" +
    "mtso+8hPBV50Ne3aitq2Mutl9O+yevUM/DY0IZEq0xN1hEo3eBCu8j4oZ9OWWW+t\n" +
    "+icOPgHsqPICImf5EU/zAgMBAAEwDQYJKoZIhvcNAQELBQADggEBAKGuWVV/GoKK\n" +
    "PcMlGiCDpBL2Tuv14ERUJeERIXD09ZTuuwvx3h9gVsxdou6qoVOCsX7PScjs7xVz\n" +
    "2ZxKUJV2ySXgYrdPPneaazJ6ftmNlMNyMBfrVO0asw9mdFSCA3Qio/HWnnB2DJb9\n" +
    "Gkk7upvmdWG5j/X9BHICIepgOFPIImBoUW8fq25+bAm8avbHANC/UDpni/CmeM5L\n" +
    "dhtIfZLIASJuF3avucY89TJJpkrNHsK4sc8O1B/x0qT2GPPg/4Srg1xvU6QLSeE7\n" +
    "OkbnNERJmJ1BVdKjiwDRq4DFbHo16ISD0UnZvID/buxezxCRTCw3kPn9bc43W55f\n" +
    "GeLp4tyFXMw=\n" +
    "-----END CERTIFICATE-----";

export let QZ_TRAY_PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\n" +
  "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQDP+9iPfkSpn3WH\n" +
  "eERPEntkYThjK2z7Twm+w8GmojrXFr0qRnmNdv65Fa4322sF6vu+CKHXUthbwYyq\n" +
  "AJumIFCJukIxbT/JGdU9cES1vHx781vAqNK9D9QiXJn5Z0EkHJGFaa7cxfC3Dr17\n" +
  "u80mM0Vnbe5Ami8utElMEhV0kUH7ElilMmTiO5fjxmZydwv3TLUlBgc54Tw1LzzA\n" +
  "YTachiffd7kohW+WAVdl3vZluiqyqgQ2cJq/8g+XH22hqOf8F0Remtso+8hPBV50\n" +
  "Ne3aitq2Mutl9O+yevUM/DY0IZEq0xN1hEo3eBCu8j4oZ9OWWW+t+icOPgHsqPIC\n" +
  "Imf5EU/zAgMBAAECggEAaKnALVXJj+Ki7m1jUrdmSPlzutQbn8wCh3FTfludaKgU\n" +
  "wbV9bec4AEQczBcrIGtEDDmj2AvkvSgFdXeg3FTesrB8pwrnUnghiy14JkyYCHhK\n" +
  "8AUFKAdTJYecJJoFefq2cIDick9Z94T/bcjwUAz1HDuB7hipLcOkLxfanDKNFZ8Z\n" +
  "ncl9qC5WKyIHp+x7NoMMw+Z4NeeE/yMqlMyteVn3xldhbJDykK2m4ikfzMR/Mk/p\n" +
  "n1+8rUfkwZc0L44i0L/aJv8mgB7x5zAgLA37f27X5Rd+Ra5T70SceZGcBP4bvkZx\n" +
  "y7qCpE2wxnaXscOYSoIAWqxgfSPlWOj8g4axjuU2QQKBgQDq/uU3Nnb9HDCDM9cC\n" +
  "3p7ibyuBgzrAEZV4jYQwzavsylgk3p0DcBdV3smXaldslSJ9W/vNQBT8MTCcRQX3\n" +
  "sz0ZXmeyfGoKbsuJJJC8rJDMwUyyHjkuFVCcVqTPL3RBkook5p3FXndPXNxCl8Pl\n" +
  "Ize+vUtVUvNWq5gKg2JxuMqwSQKBgQDikt8lBr3xHabxaz3dawjlUSLdrDhM1KQ3\n" +
  "dAqN2XnAn1RdVQk/hJTHj3d4lTNknCeV4vv1i4CoZDHG6G7B98sWLP5dHykPNUDy\n" +
  "zm7kAvSgSqlnSTAlnFqZTv5nPxhM6/H+aE3U/qHEfIjw2axkU7EtkOv2I/SM6bTs\n" +
  "/KEPQ6t2WwKBgGHg81YQ8HSyZh5v3DGMyj7kMQnLwA+6AzjoMNQnBmIlUZthwaJp\n" +
  "8L5Kgkr39xv57hiq1HGELHlyf53HPhoS5PMJGEYGfiCET3B683IyqkkuhiR2ZDim\n" +
  "liHYEONfD5w0TifaioJnu2gp9HAkSGrLRW/D5nnOiB5kcHqIiNt3La1JAoGADw12\n" +
  "6bzS7avspWlciNNNmf6T9Go2q1QNPOnmsZnbLuLm9fRqUt/6CnALneU7rtl4/8o+\n" +
  "twd3mrhdXjue8CAITAbdN06Uqb/9+qOgVmGj4upS6tu/6R/EHR9UY4Obl7CtORuw\n" +
  "4A+yq50ic+3iOCAfS0mvg4DMBHiaXoxCHwN95rUCgYBpnz5U8I9uXAXpg4xgCMOf\n" +
  "UK4TLcBsHYC0pdb17H3E9ikR7Bd8jSxOtBR42ktu00597Qgn0If1aq5TilIocv0I\n" +
  "MESAuLOFex+khjiAqs57ZoWCNBPubLLyXEu9WU4c+Q7FOLS3pO+6eMVPPl/d/JYP\n" +
  "OOQKgi7w6eBr1GqTbjGgAg==\n" +
  "-----END PRIVATE KEY-----";