import React, { useState } from "react";
import { Timeline, Divider } from "antd";
import { connect } from "react-redux";
import BreadCrumb from "../commonComponents/BreadCrumb";
import TimelineComponent from "./TimelineComponent";

function TimelinePage(props) {
  const { latestScannedNumber } = props;

  const scanType = props.location.state.scanType;
  const locationPageName = props.location.state.pageName;
  const locationRoute = props.location.state.route;

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className="parcel-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container timeline-container w-100 mt-0">
          <BreadCrumb pageName={locationPageName} scanType={scanType} route={locationRoute} />
          <div className="bg-container-body">
            <div>
              <h2 className="text-heading-black margin-bottom-20">{latestScannedNumber} Timeline</h2>
              <TimelineComponent />
              <Divider className="gray-divider" />
              <div className="d-flex justify-content-between">
                <button className="secondary-button-lg-xs" onClick={goBackToPreviousRoute}>
                  Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousRoute() {
    props.history.goBack();
  }
}

const mapStateToProps = ({ parcel }) => ({
  orderData: parcel.parcelOrderData,
  latestScannedNumber: parcel.latestScannedNumber,
});

export default connect(mapStateToProps, null)(TimelinePage);
