export const generateExceptionsCSVData = (data, cargoTrackingNo) => {
  const formattedData = [];
  data.forEach((parcel) => {
    const packageTrackingNo = parcel.tracking_no;
    parcel.exceptions.forEach((exception) => {
      formattedData.push({
        parcelTrackingNo: packageTrackingNo,
        cargoTrackingNo: cargoTrackingNo,
        name: exception.name,
        description: exception.description
      });
    });
  });
  console.log(formattedData)
  return formattedData;
}

// const struct = {
//   trackingno,
//   exceptions: []
// }

// const headers = {
//   "Parcel Tracking Number",
//   "Cargo Tracking Number",
//   "Exception Type",
//   "Description"
// }