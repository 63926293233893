import React from "react";
import ZebraScanner from "../../../common/zebraScanner/ZebraScanner";

export default function NotificationArea(props) {
  const {
    trackingNoInputRef,
    trackingNo,
    setTrackingNo,
    noDataBoxClass,
    errorDataBoxClass,
    validDataBoxClass,
    successNotificationData,
    errorNotificationData,
    onPressEnter,
    onChangeScanNumberCameraChange,
  } = props;

  const defaultScanner = localStorage.getItem("defaultScanner");
  const showPrintLabel = localStorage.getItem("showPrintLabel");

  return (
    <React.Fragment>
      <div className={`empty-notification-container justify-content-center align-items-center ${noDataBoxClass}`}>
        <span className="empty-notification-text mb-0">You will see the scan results here.</span>
      </div>
      {errorNotificationData.stop_shipment ? (
        <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
          <div className="cargo-notification-high-value text-uppercase text-bold mb-4">Exception: prohibited item</div>
          <h2 className="error-notification-text mb-0 text-center text-capitalize">RESOLVE EXCEPTION FIRST</h2>
          <span className="notification-scan-number">{trackingNo}</span>
        </div>
      ) : (
        <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
          <div id="err-image" className="err-img err-no-data">
            &nbsp;
          </div>
          <span className="error-notification-text mb-0 text-center text-capitalize">
            {errorNotificationData.errorMessage}
          </span>
          {errorNotificationData.jsonBody && (
            <span className="json-notification-text m-2">{errorNotificationData.jsonBody}</span>
          )}
        </div>
      )}

      <div
        className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
      >
        <h2 className="success-notification-50-bold">{successNotificationData.tracking_no}</h2>
        <span className="success-notification-standard-uppercase">{successNotificationData.successMessage}</span>
      </div>

      {defaultScanner === "Camera" && (
        <div>
          <ZebraScanner onScanComplete={(scanResult) => onChangeScanNumberCameraChange(scanResult)} />
        </div>
      )}

      {showScanInputField() && (
        <div className="d-flex align-items-center scanInputContainer">
          <h2 className="text-heading-black mr-3">Scan</h2>
          <input
            ref={trackingNoInputRef}
            className="form-control scan-input"
            placeholder="Click here or paste tracking code to scan"
            value={trackingNo}
            onChange={onChangeScanNumberChange}
            onKeyUp={onChangeScanNumberChange}
            autoFocus={true}
          />
        </div>
      )}

      {(showPrintLabel !== null || showPrintLabel !== undefined) &&
        showPrintLabel === "true" &&
        defaultScanner === "Laser" && (
          <React.Fragment>
            <div className="d-flex align-items-center scanInputContainer">
              <h2 className="text-heading-black mr-3">Print</h2>
            </div>
          </React.Fragment>
        )}
    </React.Fragment>
  );

  function onChangeScanNumberChange(e) {
    const { value } = e.target;
    const { keyCode, which } = e;
    if (keyCode === 13 || which === 13) {
      onPressEnter();
      return;
    }
    setTrackingNo(value);
  }

  function showScanInputField() {
    return (
      (defaultScanner === null || defaultScanner === undefined || defaultScanner === "Laser") &&
      defaultScanner !== "Camera"
    );
  }
}
