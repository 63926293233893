// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Quagga from 'quagga';

class Scanner extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  componentDidMount() {
    Quagga.init({
      inputStream: {
        type: "LiveStream",
        constraints: {
          facingMode: "environment"
        }
      },
      locator: {
        patchSize: "medium",
        halfSample: true
      },
      numOfWorkers: 2,
      decoder: {
        readers: ["code_128_reader", "code_39_reader"]
      },
      locate: true
    }, (err) => {
      if (err) {
        console.log(err);
      }
      Quagga.start();
    });
    Quagga.onDetected(this.onDetected.bind(this));
  }

  componentWillUnmount() {
    Quagga.stop();
    Quagga.offDetected(this.onDetected.bind(this));
  }

  onDetected(result) {
    this.props.onDetected(result);
  }

  render() {
    return (
      <div id="interactive" className="viewport" />
    );
  }
}

function mapStateToProps() {
  return {

  };
}

export default connect(mapStateToProps, {})(Scanner);
