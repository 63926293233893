import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parcelActions } from "../actions/rootActions";
import { playSound } from "../utils/playSound";

export const useRedeliverScan = (params) => {
  const dispatch = useDispatch();
  const { redeliverScanSuccess, redeliverScanError } = useSelector((state) => ({
    redeliverScanSuccess: state.parcel.redeliverScanSuccess,
    redeliverScanError: state.parcel.redeliverScanError,
  }));

  useEffect(() => {
    if (redeliverScanSuccess) {
      playSound("success");
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      dispatch(parcelActions.clearRedeliverScanData());
    }
  }, [redeliverScanSuccess]);

  useEffect(() => {
    if (redeliverScanError) {
      playSound("error");
      params.setState({
        ...params.state,
        scanNumber: "",
      });
      dispatch(parcelActions.clearRedeliverScanData());
    }
  }, [redeliverScanError]);
};
