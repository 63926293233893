import React, { Component, createElement } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import Scanner from "../../deprecated/scanner/Scanner";
import { resources } from "../../deprecated/i18n/index";
import i18n from "../../deprecated/i18n";
import { Redirect } from "react-router";
import axios from "axios";
import * as _ from "lodash";
import { ROOT_URL } from "../../actions/index";
import * as qz from "qz-tray";
import * as CountryCues from "../../deprecated/audio";
import moment from "moment";
import getDimWeightFromQubeVu from "./QubevuParser";
import { Alert, Timeline, Menu, Dropdown, Spin, Switch } from "antd";
import "open-iconic/font/css/open-iconic-bootstrap.css";
import "antd/dist/antd.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import pdf2base64 from "pdf-to-base64";
import { scanAudioAlert } from "../../utils/voiceTypeUtils";
import Compress from "browser-image-compression";
import heic2any from "heic2any";
import ZebraScanner from "../../common/zebraScanner/ZebraScanner";

class HMSScanner extends Component {
  constructor(props) {
    super(props);
    this.bins = [];
    this.binsToShow = [];
    this.state = {
      cta: "",
      menutcta: "",
      menuType: "",
      hubId: "",
      userId: "",
      userName: "",
      requireErrorAcknowledgment: false,
      errorAcknowledgementRequired: false,
      qubevuStatus: "disabled",
      qubevuIP: "",
      hasSelectedScanType: false,
      err: false,
      currScanType: "",
      currSortType: "country",
      metaScanType: "",
      isScanning: false,
      scannedTrackingNos: [],
      isScanComplete: true,
      isLatestScanSuccess: false,
      isLoggedIn: true,
      manualTrackingNo: "",
      labelPath: true,
      groupId: "",
      scannedItems: [],
      waitingForApiResponse: false,
      showAllScannedItems: false,
      copiedToClipboard: false,
      scannerBeepedOnce: [],
      autoRelabel: false,
      doFrom: this.getFromDateFormat(),
      doTo: this.getToDateFormat(),
      doGroupID: "",
      doHubID: "",
      doErr: false,
      dimUpdateSuccess: false,
      orderInfo_length: "",
      orderInfo_width: "",
      orderInfo_height: "",
      orderInfo_weight: "",
      showExceptionsBox: false,
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      showLabelNotFoundBox: false,
      selectedException: "",
      exceptionDescription: "ok",
      exceptions: [],
      saveExceptionSuccess: false,
      saveExceptionError: false,
      savingException: false,
      removeExceptionSuccess: false,
      autoSaveException: false,
      reportType: "",
      bins: [],
      privateStatuses: null,
      defaultPrinter: null,
      statusCode: 0,
      defaultAudioType: "consigneeCountry",
      voice: null,
      showAddLocationBox: false,
      autoSaveLocation: false,
      location: "",
      trackingNumberLocation: null,
      locationChangeName: null,
      locationChangeTime: null,
      exceptionTypes: [],
      addLocationError: null,
      qzTrayDownloadError: null,
      exceptionImage: null,
      exceptionImageName: null,
      exceptionImageTypeError: null,
      imageInProcessing: false,
      imageType: null,
      showCameraToScan: false,
      showZebraScanner: false,
      recipientsList: [],
      selectedRecipient: "",
      showNewUi: false,
      dataBoxConfig: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
        showSortDataBox: false,
        errorMessage: "",
        showDuplicateTrackingNumberError: false,
        merchantName: "",
        consigneeCountry: "",
        consigneePostalPreFix: "",
        consigneeStateCity: "",
        consigneeState: "",
        consigneeCity: "",
        consigneePostal: "",
        paymentType: "",
        incoterm: "DDP",
        showTrackingNumber: "",
        totalOrderValue: "",
        lastUpdateMessage: "",
        lastUpdateBy: "",
        outboundNetworkPartner: null,
      },
    };

    this.trackingNumberInput = React.createRef();
  }

  getFromDateFormat() {
    let fromDate = moment();
    fromDate.set({ hour: "00", minute: "00", second: "00" });
    return fromDate;
  }

  getToDateFormat() {
    let toDate = moment();
    toDate.set({ hour: "23", minute: "59", second: "00" });
    return toDate;
  }

  playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  playCountryName(countryName) {
    if (CountryCues[countryName] !== undefined && this.isSortTypeCountry()) {
      let sound = new Audio("data:audio/wav;base64," + CountryCues[countryName].audioContent);
      sound.play();
    }
  }

  isSortTypeCountry() {
    return (
      this.state.currSortType != "state" &&
      this.state.currSortType != "city" &&
      (this.state.currSortType != "postal") & (this.state.currSortType != "np")
    );
  }

  isInboundOrOutboundScantype() {
    if (this.state.currScanType) {
      return this.state.currScanType.includes("inbound") || this.state.currScanType.includes("outbound");
    } else {
      return false;
    }
  }

  getSortedPrivateStatuses() {
    if (!this.state.privateStatuses) {
      return null;
    } else {
      let privateStatuses = [];
      for (let x of this.state.privateStatuses) {
        for (let y of x.updates) {
          privateStatuses.push(y);
        }
      }
      privateStatuses.sort(function (a, b) {
        return new Date(b.updated_on) - new Date(a.updated_on);
      });
      return privateStatuses;
    }
  }

  getPrivateStatusTimeline() {
    if (!this.state.privateStatuses) {
      return null;
    } else {
      return (
        <Timeline className="hms-scanner-timeline">
          {this.getSortedPrivateStatuses().map((privateStatus, index) => {
            let monthNames = [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ];
            let date = new Date(privateStatus.updated_on);
            return (
              <Timeline.Item className="hms-scanner-timeline-item" color={index == 0 ? "blue" : "gray"}>
                {privateStatus.status}
                <br />
                {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()} at ${this.getFormatTime(
                  date
                )}`}
              </Timeline.Item>
            );
          })}
        </Timeline>
      );
    }
  }

  componentDidMount() {
    const url = window.location.href;
    let pageType = url.substring(url.lastIndexOf("/") + 1);
    let menuType = url.substring(url.lastIndexOf("/") - 9, url.lastIndexOf("/"));
    pageType = pageType.includes("?") ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("?")) : pageType;

    const ctas = {
      pickup: "Pick Up",
      deliver: "Deliver",
      receive: "Receive",
      transfer: "Transfer",
      login: "Login",
      scan: "Scan",
    };
    const menuctas = {
      firstmile: "Firstmile",
      warehouse: "Warehouse",
      hubmanage: "HMS Scanner",
    };

    if (!this.state.cta && !this.state.menucta) {
      this.setState({
        cta: ctas[pageType],
        menucta: menuctas[menuType],
        menuType: menuType,
      });
    }

    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      this.setState({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
    let voices = window.speechSynthesis.getVoices();
    let defaultPrinter = localStorage.getItem("defaultPrinter");
    this.setState({ defaultPrinter: defaultPrinter, voice: voices[0] });
    this.setVoiceType();
    this.getExceptionsTypes();
    this.getRecipientsList();
  }

  // recipients
  getRecipientsList = async () => {
    let recipientsResult = await axios.get(`${ROOT_URL}/hms/get_recipients/`);
    let recipientsList = recipientsResult.data;
    this.setState({ recipientsList: recipientsList });
  };

  getExceptionsTypes = async () => {
    let exceptionsResult = await axios.get(`${ROOT_URL}/hms/exception_types/`, {
      params: {
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
        scan_type: "mark exceptions",
      },
    });
    let exceptionTypes = _.orderBy(exceptionsResult.data.exceptions, "name", "asc");
    this.setState({ exceptionTypes: exceptionTypes });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate");
    console.log(this.state);
    if (prevState.latestScannedNumber === null && this.state.latestScannedNumber !== null) {
      this.submitValue();

      if (this.state.autoSaveException) {
        this.saveExceptions();
      }

      if (this.state.autoSaveLocation) {
        this.addLocation();
      }
    }

    if (
      prevState.latestScannedNumber === null &&
      this.state.latestScannedNumber !== null &&
      this.state.qubevuStatus === "enabled"
    ) {
      this.updateAutoDimWeight();
    }

    if (
      prevState.currScanType !== this.state.currScanType &&
      this.state.currScanType === "warehouse outbound" &&
      this.state.recipientsList.length == 0
    ) {
      let errorMessage = "Your hub has not configured network partners to send to. Call support immediately.";
      this.setState({
        dataBoxConfig: {
          showNoDataBox: false,
          showValidDataBox: false,
          showErrorDataBox: true,
          errorMessage,
          showTrackingNumber: "",
          showDuplicateTrackingNumberError: false,
        },
      });
    }
  }

  // set state of scanner component
  setVoiceType = () => {
    let defaultAudioType = localStorage.getItem("defaultAudioType");
    if (defaultAudioType !== null) {
      this.setState({ defaultAudioType: defaultAudioType });
    }
  };

  setSortType(event) {
    this.setState({
      currSortType: event.target.value,
      dataBoxConfig: {
        showNoDataBox: true,
        showSortDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: false,
      },
    });
  }

  setScanningState(event) {
    let splitStr = event.target.value.split(" ");
    const lastPart = splitStr[splitStr.length - 1];
    let metaScanType =
      lastPart === "inbound" || lastPart === "outbound" || lastPart === "id" || lastPart === "sweep" ? lastPart : null;
    metaScanType = metaScanType === "id" ? "update group id" : metaScanType;

    if (this.state.currScanType !== "") {
      if (window.confirm("If you change your scan type, this will restart your scanning session.")) {
        this.resetScannedItems();
        // this.createSession();
        this.setState(
          {
            currScanType: event.target.value,
            metaScanType: metaScanType,
            dimUpdateSuccess: false,
            orderInfo_height: "",
            orderInfo_weight: "",
            orderInfo_length: "",
            orderInfo_width: "",
          },
          this.createSession
        );
      }
    } else {
      this.setState(
        {
          currScanType: event.target.value,
          metaScanType: metaScanType,
        },
        this.createSession
      );
    }
  }

  setRecipientsState = (event) => {
    this.setState({
      selectedRecipient: event.target.value,
    });
  };

  setReportState(event) {
    this.setState({
      reportType: event.target.value,
    });
  }

  clearSession() {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("hubId");
    localStorage.removeItem("scanTypes");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("printersList");
    localStorage.removeItem("isQzConnected");
    this.setState({
      isLoggedIn: false,
    });
  }

  disconnectQzTray = () => {
    qz.websocket
      .disconnect()
      .then(() => {})
      .catch((e) => console.log("Error", e));
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleAddLocationChange = (e) => {
    let { name, value } = e.target;
    value = value.replace(/\s/g, "");
    this.setState({ [name]: value.toUpperCase(), addLocationError: null });
  };

  getParams(trackingNumber) {
    let params = {
      hub_id: this.state.hubId,
      hubscanner_key: this.state.userId,
      tracking_no: trackingNumber,
      scan_type: this.state.currScanType,
    };
    if (this.state.currScanType == "sort by") {
      params.sort_by = this.state.currSortType == "np" ? "network_partner" : this.state.currSortType;
    }
    return params;
  }

  computeBins(bins) {
    bins = bins.replace(/'/g, '"');
    bins = JSON.parse(bins);
    this.binsToShow = bins;
    this.bins.push({
      name: this.state.dataBoxConfig.totalOrderValue,
      background_colour: "#E5E5E5",
      text_colour: "#000000",
    });

    bins.forEach((bin) => {
      let binObj = {};
      binObj.name = bin.name;
      binObj.type = bin.type;
      binObj.background_colour = bin.background_colour;
      binObj.text_colour = bin.text_colour;

      if (bin.name == "DEMINIMIS") {
        binObj.name = this.state.dataBoxConfig.totalOrderValue;
        this.bins[0] = binObj;
      } else {
        this.bins.push(binObj);
      }
    });
  }

  async fetchOrderInfo(trackingNumber, refreshDataOnly) {
    if (this.isInboundOrOutboundScantype() && this.state.scannedItems.includes(trackingNumber && !refreshDataOnly)) {
      this.setStateToErrorState("Duplicate Scanned", null);
      return false;
    }

    return axios
      .get(`${ROOT_URL}/hms/get_order_details/`, {
        params: this.getParams(trackingNumber),
      })
      .then((res) => {
        console.log("then in fetchOrderInfo");
        console.log(res);
        this.setState({ waitingForApiResponse: false });
        const lastUpdatedOn = new Date(res.data.updated_on);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let exceptions = [];
        if (res.data.exceptions.length > 0) {
          res.data.exceptions.forEach((exception) => {
            const obj = {
              id: exception.id,
              name: exception.name,
              note: exception.note,
              created_by: exception.created_by,
              created_on: exception.created_on,
              photo_url: exception.photo_url,
            };
            exceptions.push(obj);
          });
        }

        console.log("setState in fetchOrderInfo");
        this.setState({
          orderInfo_length: res.data.order_length,
          orderInfo_width: res.data.order_width,
          orderInfo_height: res.data.order_height,
          orderInfo_weight: res.data.order_weight,
          dataBoxConfig: {
            merchantName: res.data.agent_application_name,
            consigneeCountry: res.data.consignee_country,
            consigneePostalPreFix: res.data.consignee_postal.slice(0, 2),
            consigneeStateCity: res.data.consignee_city + ", " + res.data.consignee_state,
            consigneeState: res.data.consignee_state,
            consigneeCity: res.data.consignee_city,
            consigneePostal: res.data.consignee_postal,
            paymentType: res.data.payment_type,
            incoterm: res.data.incoterm === undefined || res.data.incoterm === null ? "DDP" : res.data.incoterm,
            showNoDataBox: false,
            showValidDataBox: !(this.state.currScanType === "sort by" && this.state.currSortType !== "country"),
            showSortDataBox: this.state.currScanType === "sort by" && this.state.currSortType !== "country",
            showErrorDataBox: false,
            totalOrderValue: res.data.order_currency + " " + res.data.order_total_value,
            lastUpdateMessage: res.data.private_tracker_status_code,
            lastUpdateBy:
              res.data.updated_by == ""
                ? lastUpdatedOn.getDate() +
                  " " +
                  monthNames[lastUpdatedOn.getMonth()] +
                  " " +
                  lastUpdatedOn.getFullYear()
                : lastUpdatedOn.getDate() +
                  " " +
                  monthNames[lastUpdatedOn.getMonth()] +
                  " " +
                  lastUpdatedOn.getFullYear() +
                  " by " +
                  res.data.updated_by,
          },
          dimUpdateSuccess: false,
          labelPath: res.data.order_label_url,
          exceptions,
          showCancelledByCustomerBox: false,
          showHeldUpAtCustomsBox: false,
          privateStatuses: res.data.private_statuses,
          bins: res.data.bins,
          statusCode: res.status,
          trackingNumberLocation: res.data.location ? res.data.location.name : null,
          locationChangeName: res.data.location ? res.data.location.updated_by : null,
          locationChangeTime: res.data.location ? res.data.location.updated_on : null,
        });
        if (this.state.autoRelabel) {
          this.printLabel();
        }

        if (res.data.is_cancelled) {
          this.playSound("error");
        }
        this.setState({
          showCancelledByCustomerBox: res.data.is_cancelled,
        });

        if (res.data.is_held_up_at_customs && this.isInboundOrOutboundScantype()) {
          this.playSound("error");
        }
        this.setState({
          showHeldUpAtCustomsBox: res.data.is_held_up_at_customs,
        });

        this.bins = [];
        this.binsToShow = [];
        this.computeBins(res.data.bins);

        console.log("end in fetchOrderInfo");
        return true;
      })
      .catch((e) => {
        console.log("error in fetchOrderInfo");
        console.log(e);
        // this.updateTracker(trackingNumber);
        this.setStateToErrorState(
          e.data.tracking_no
            ? e.data.tracking_no.join(", ")
            : "Unknown Error, please screenshot and send to Tech Support.",
          e.status
        );

        const { mixpanel } = this.props;
        // old
        mixpanel.track("Error - fetchOrderInfo", {
          Success: false,
          "Scanned tracking number": this.state.latestScannedNumber,
          "Number of tracking numbers": this.state.scannedItems.length,
          "Hub Name": this.state.hubId,
          "Hubscanner Name": this.state.userId,
          "Scan type": this.state.currScanType,
          "Session id": localStorage.getItem("sessionId"),
          Error: e.data,
        });
        return false;
      });
  }

  setStateToErrorState(errorMessage, statusCode) {
    this.playSound("error");
    console.log("setStateToErrorState");
    this.setState({
      dataBoxConfig: {
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
        errorMessage,
        showTrackingNumber: this.state.latestScannedNumber,
        showDuplicateTrackingNumberError: false,
      },
      errorAcknowledgementRequired: this.state.requireErrorAcknowledgment,
      dimUpdateSuccess: false,
      isScanComplete: true,
      isLatestScanSuccess: false,
      labelPath: false,
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      privateStatuses: null,
      statusCode,
      orderInfo_length: "",
      orderInfo_width: "",
      orderInfo_height: "",
      orderInfo_weight: "",
    });
  }

  getSessionID(scanType) {
    return axios.post(`${ROOT_URL}/hms/session/`, {
      hub_id: this.state.hubId,
      hubscanner_key: this.state.userId,
      scan_type: scanType,
    });
  }

  async updateDimensions() {
    console.log("updateDimensions");
    const res = await this.updateDimWeight();
    if (res !== undefined && this.state.dataBoxConfig.consigneeCountry !== undefined) {
      this.playAudioAlert();
    }
    this.setState({
      dimUpdateSuccess: true,
    });
  }

  async updateAutoDimWeight() {
    console.log("updateAutoDimWeight");
    try {
      const dimWeight = await getDimWeightFromQubeVu(this.state.qubevuIP);
      this.setState({
        orderInfo_length: dimWeight.length,
        orderInfo_width: dimWeight.width,
        orderInfo_height: dimWeight.height,
        orderInfo_weight: dimWeight.weight,
      });
      await this.updateDimensions();
      return true;
    } catch (e) {
      console.log(e);
      console.log("AutoDimWeight update failed");
      return false;
    }
  }

  updateDimWeight() {
    let latestScannedNumber = this.state.latestScannedNumber;
    console.log("updateDimWeight");
    if (this.state.scannedItems.length > 0) {
      return axios.put(`${ROOT_URL}/hms/update_order_dimweight/`, {
        hub_id: this.state.hubId,
        hubscanner_key: this.state.userId,
        orders: [
          {
            tracking_no: latestScannedNumber,
            length: this.state.orderInfo_length,
            width: this.state.orderInfo_width,
            height: this.state.orderInfo_height,
            weight: this.state.orderInfo_weight,
          },
        ],
      });
    }
  }

  scanItem(result) {
    const scannedBarcode = result.codeResult.code;
    if (!this.state.scannedTrackingNos.includes(scannedBarcode)) {
      this.setState(
        {
          scannedTrackingNos: this.state.scannedTrackingNos.concat(scannedBarcode),
          manualTrackingNo: scannedBarcode,
        },
        () => {
          this.updateLatestScannedNumber();
        }
      );
    }
  }

  async createSession() {
    try {
      // old
      const sessionId = await this.getSessionID(this.state.currScanType);
      localStorage.setItem("sessionId", sessionId.data.session_id);
    } catch (e) {
      console.log("error creating session id");
    }
  }

  acknowledgeError() {
    this.setState(
      {
        errorAcknowledgementRequired: false,
        showCancelledByCustomerBox: false,
        showHeldUpAtCustomsBox: false,
        dataBoxConfig: {
          showNoDataBox: true,
          showValidDataBox: false,
          showErrorDataBox: false,
        },
      },
      this.trackingNumberInput.current.focus()
    );
  }

  getGroupId = () => {
    if (!_.isEmpty(this.state.groupId)) {
      return this.state.groupId;
    } else {
      return this.state.selectedRecipient;
    }
  };

  processOrder(trackingNumbers) {
    // old
    return axios.post(`${ROOT_URL}/hms/process_scanned_orders/`, {
      hub_id: this.state.hubId,
      hubscanner_key: this.state.userId,
      group_id: this.getGroupId(),
      scan_type: this.state.currScanType,
      session_id: localStorage.getItem("sessionId"),
      tracking_nos: trackingNumbers,
    });
  }

  async updateTracker(trackingNumber) {
    console.log("updateTracker");
    try {
      let orderList = [];
      orderList.push(trackingNumber);
      let result = await this.processOrder(orderList);
      if (result && result.data && this.state.currScanType == "warehouse outbound") {
        await this.setNetworkPartner(result.data);
      }
      return true;
    } catch (e) {
      console.log("tracker update failed");
      console.log(e);
      if (e.data && e.data.tracking_nos && e.data.tracking_nos[0]) {
        this.setStateToErrorState(
          e.data.tracking_nos
            ? e.data.tracking_nos.join(", ")
            : "Unknown Error, please screenshot and send to Tech Support.",
          e.status
        );
      }

      const { mixpanel } = this.props;
      // old
      mixpanel.track("Error - updateTracker", {
        Success: false,
        "Scanned tracking number": this.state.latestScannedNumber,
        "Number of tracking numbers": this.state.scannedItems.length,
        "Hub Name": this.state.hubId,
        "Hubscanner Name": this.state.userId,
        "Scan type": this.state.currScanType,
        "Session id": localStorage.getItem("sessionId"),
        Error: e.data,
      });
      return false;
    }
  }

  setNetworkPartner = (data) => {
    let { dataBoxConfig } = this.state;
    dataBoxConfig.outboundNetworkPartner = data.group_id;
    this.setState({ dataBoxConfig: dataBoxConfig });
  };

  updateLatestScannedNumber() {
    if (this.state.errorAcknowledgementRequired) {
      this.playSound("error");
      this.setState({ manualTrackingNo: "" });
    } else {
      this.setState({ latestScannedNumber: null }, () => {
        this.setState({
          latestScannedNumber: this.state.manualTrackingNo,
          manualTrackingNo: "",
        });
      });
    }
  }

  // play audio alert based on choosen value (country by default)
  playAudioAlert = () => {
    let { voice } = this.state;
    let bins = this.state.bins;
    bins = bins.replace(/'/g, '"');
    bins = JSON.parse(bins);
    let networkPartnerBin = _.find(bins, (x) => x.type == "NP");
    let highValueBin = _.find(bins, (x) => x.type == "HIGHVALUE");
    let stringToVoice = "";
    if (this.state.defaultAudioType == "networkPartner" && networkPartnerBin) {
      stringToVoice = networkPartnerBin["name"].toLowerCase();
    } else if (this.state.defaultAudioType == "consigneePostalPreFix") {
      stringToVoice = this.state.dataBoxConfig.consigneePostalPreFix.toLowerCase();
    } else if (this.state.defaultAudioType == "noOfParcelsScanned") {
      stringToVoice = this.state.scannedItems.length + 1;
    } else {
      stringToVoice = this.state.dataBoxConfig.consigneeCountry.toLowerCase();
    }
    if (highValueBin) {
      stringToVoice = "high value, " + stringToVoice;
    }
    scanAudioAlert(stringToVoice, voice);
  };

  async submitValue() {
    let latestScannedNumber = this.state.latestScannedNumber;
    const { mixpanel } = this.props;
    const t0 = performance.now();
    this.setState({ waitingForApiResponse: true });
    this.setState({
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      dataBoxConfig: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
      },
    });
    let scannedItems = this.state.scannedItems;
    if (latestScannedNumber === "") return;
    if (await this.fetchOrderInfo(latestScannedNumber)) {
      console.log("fetchOrderInfo returns True");
      if (await this.updateTracker(latestScannedNumber)) {
        if (this.state.dataBoxConfig.consigneeCountry !== undefined) {
          this.playAudioAlert();
        }
        scannedItems.unshift(latestScannedNumber);
        this.setState({
          scannedItems,
          groupId: this.state.groupId,
          selectedRecipient: this.state.selectedRecipient,
          isLatestScanSuccess: true,
        });

        const { mixpanel } = this.props;
        const t1 = performance.now();
        // old
        mixpanel.track("Scan", {
          Success: true,
          "Duration in ms": t1 - t0,
          "Scanned tracking number": latestScannedNumber,
          "Number of tracking numbers": scannedItems.length,
          "Hub Name": this.state.hubId,
          "Hubscanner Name": this.state.userId,
          "Scan type": this.state.currScanType,
          "Session id": localStorage.getItem("sessionId"),
        });
      }
    } else {
      console.log("fetchOrderInfo returns False");
    }
  }

  resetScannedItems() {
    this.setState({
      scannedItems: [],
      showCancelledByCustomerBox: false,
      showHeldUpAtCustomsBox: false,
      exceptionImage: null,
      exceptionImageName: null,
      imageInProcessing: false,
      imageType: null,
      showCameraToScan: false,
      groupId: "",
      selectedRecipient: "",
      dataBoxConfig: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
        outboundNetworkPartner: null,
      },
      manualTrackingNo: "",
      selectedException: "",
      exceptionDescription: "",
      autoSaveException: false,
      location: "",
      autoSaveLocation: false,
      scannedTrackingNos: [],
    });
  }

  toggleScanning() {
    this.setState(
      {
        isScanning: !this.state.isScanning,
      },
      () => {
        this.setState({ scannedTrackingNos: [] });
      }
    );
  }

  printLabel() {
    if (!this.state.labelPath) {
      return;
    } else {
      pdf2base64(this.state.labelPath)
        .then((response) => {
          var config = qz.configs.create(this.state.defaultPrinter);
          var data = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          if (!qz.websocket.isActive()) {
            this.setQzTrayError(true);
          } else {
            qz.print(config, data)
              .then(function () {})
              .catch((err) => this.setQzTrayError(err));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  // set qz tray error
  setQzTrayError = (err) => {
    this.setState({ qzTrayDownloadError: err });
    setTimeout(() => {
      this.setState({ qzTrayDownloadError: null });
    }, 3000);
  };

  validateNumber(val) {
    var validNumber = new RegExp(/^\d*\.?\d*$/);
    if (validNumber.test(val)) {
      return true;
    } else {
      return false;
    }
  }

  saveExceptions = (isAutoSaveOff) => {
    this.trackingNumberInput.current.focus();
    if (this.state.selectedException == "" || this.state.exceptionDescription == "") {
      this.setState({
        savingException: true,
        saveExceptionError: true,
        saveExceptionSuccess: false,
      });
      setTimeout(() => {
        this.setState({
          savingException: false,
          saveExceptionError: false,
          saveExceptionSuccess: false,
        });
      }, 2000);
    } else {
      this.setState({ savingException: true });
      // old
      axios
        .post(`${ROOT_URL}/hms/process_scanned_orders/`, {
          hub_id: this.state.hubId,
          hubscanner_key: this.state.userId,
          group_id: this.getGroupId(),
          scan_type: "cs mark exceptions",
          session_id: localStorage.getItem("sessionId"),
          tracking_no: this.state.latestScannedNumber,
          exception_type: this.state.selectedException,
          exception_note: this.state.exceptionDescription,
          image_name: this.state.exceptionImageName,
          image_data: this.state.exceptionImage,
          content_type: this.state.imageType,
        })
        .then((res) => {
          this.setState({
            saveExceptionError: false,
            saveExceptionSuccess: true,
            savingException: false,
          });
          if (!this.state.autoSaveException) {
            this.setState({
              selectedException: "",
              exceptionDescription: "ok",
              exceptionImage: null,
              exceptionImageName: null,
            });
          }
          setTimeout(() => {
            this.setState({
              saveExceptionSuccess: false,
            });
            this.fetchOrderInfo(this.state.latestScannedNumber, true);
          }, 2500);
        })
        .catch((err) => {
          this.setState({
            saveExceptionError: true,
            saveExceptionSuccess: false,
            savingException: false,
          });
          setTimeout(() => {
            this.setState({
              saveExceptionError: false,
              saveExceptionSuccess: false,
            });
          });
        });
    }
  };

  // api call
  addLocation() {
    // old
    let latestScannedNumber = this.state.latestScannedNumber;
    this.trackingNumberInput.current.focus();
    axios
      .put(`${ROOT_URL}/hms/parcel_location/`, {
        hub_id: this.state.hubId,
        hubscanner_key: this.state.userId,
        session_id: localStorage.getItem("sessionId"),
        tracking_no: latestScannedNumber,
        location: this.state.location,
      })
      .then((res) => {
        setTimeout(() => {
          this.setState({
            trackingNumberLocation: res.data.location.name,
            locationChangeName: res.data.location.updated_by,
            locationChangeTime: res.data.location.updated_on,
            addLocationError: null,
          });
        }, 2500);
      })
      .catch((err) => this.setState({ addLocationError: err.data.location[0] }));
  }

  // exception image upload
  handleExceptionImageChange = async (e) => {
    const acceptedTypes = ["image/jpeg", "image/png"];
    let file = e.target.files[0];
    let imageName = file.name;
    let imageType = file.type;
    let isHeicFile = false;
    if (!file) {
      this.setState({
        exceptionImage: null,
        exceptionImageName: null,
        imageType: null,
      });
      return;
    }
    this.setState({ imageInProcessing: true });
    isHeicFile = file.name.substr(file.name.length - 4) == "heic" || file.name.substr(file.name.length - 4) == "heif";

    if (!acceptedTypes.includes(file.type) && !isHeicFile) {
      let errorMessage = "Only PNG, JPEG and HEIF images are allowed";
      this.setExceptionImageError(errorMessage);
      return;
    } else if (isHeicFile) {
      let fileToCompress = await this.convertHeicToJpeg(file);
      fileToCompress = this.blobToFile(fileToCompress);
      imageName = imageName.substr(0, imageName.length - 4) + "jpeg";
      this.compressedImage(fileToCompress, imageName, fileToCompress.type);
    } else {
      this.compressedImage(file, imageName, imageType);
    }
  };

  blobToFile = (blob, fileName) => {
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  };

  convertHeicToJpeg = async (file) => {
    let result = await heic2any({ blob: file, toType: "image/jpeg" });
    return result;
  };

  setExceptionImageError = (errorMessage) => {
    this.setState({
      exceptionImageTypeError: errorMessage,
      exceptionImage: null,
      imageType: null,
      exceptionImageName: null,
      imageInProcessing: false,
    });
    setTimeout(() => {
      this.setState({ exceptionImageTypeError: null });
    }, 2500);
  };

  compressedImage = (file, fileName, imageType) => {
    let fileToSave = null;
    let imageName = fileName;
    const fileSize = file.size / Math.pow(1024, 2);
    if (fileSize > 2) {
      const options = {
        maxSizeMB: 2,
        useWebWorker: true,
      };
      Compress(file, options)
        .then((compressedBlob) => {
          let reader = new FileReader();
          reader.readAsDataURL(compressedBlob);
          reader.onload = () => {
            let result = reader.result.replace(/^data:image.+;base64,/, "");
            this.setState({
              exceptionImage: result,
              exceptionImageName: imageName,
              imageType: imageType,
              imageInProcessing: false,
            });
          };
          reader.onerror = (error) => {
            console.log("Error: ", error);
          };
        })
        .catch((e) => {
          let errorMessage = "Something went wrong. Please upload again";
          this.setExceptionImageError(errorMessage);
        });
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let result = reader.result.replace(/^data:image.+;base64,/, "");
        this.setState({
          exceptionImage: result,
          exceptionImageName: imageName,
          imageType: imageType,
          imageInProcessing: false,
        });
        console.log("Reader result ", result);
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
  };

  async resolveException(exceptionId) {
    let latestScannedNumber = this.state.latestScannedNumber;
    if (exceptionId != null) {
      // old
      axios
        .post(`${ROOT_URL}/hms/resolve_problems/`, {
          hub_id: this.state.hubId,
          hubscanner_key: this.state.userId,
          scan_type: this.state.currScanType,
          session_id: localStorage.getItem("sessionId"),
          orders: [
            {
              tracking_no: latestScannedNumber,
              resolutions: [
                {
                  exception_id: exceptionId,
                  action: "-",
                },
              ],
            },
          ],
        })
        .then((res) => {
          this.fetchOrderInfo(latestScannedNumber, true);
          this.setState({ removeExceptionSuccess: true });
          setTimeout(() => {
            this.setState({ removeExceptionSuccess: false });
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          console.log("error!");
        });
    }
  }

  getFormatTime = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";

    hours = hours % 12;
    hours = hours ? hours : 12; // hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  getMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  };

  // scandit
  onChangeSwitch = (checked) => {
    this.setState({ showCameraToScan: checked });
  };

  // zebra scanner
  onChangeZebraSwitch = (checked) => {
    this.setState({ showZebraScanner: checked });
  };

  // getScanSettings = () => {
  //   return new ScanSettings({
  //     enabledSymbologies: ["qr", "ean8", "ean13", "upca", "upce", "code128", "code39", "code93", "itf"],
  //     codeDuplicateFilter: 1000
  //   });
  // }

  onScanComplete = (scanResult) => {
    let trackingNumber = scanResult.barcodes[0].data;
    this.setState({ manualTrackingNo: trackingNumber });
    this.updateLatestScannedNumber();
  };

  onScanError = (error) => {
    console.error(error.message);
  };

  onZebraScanComplete = (scanResult) => {
    console.log("Scan Result", scanResult);
    this.setState({ manualTrackingNo: scanResult.text });
    this.updateLatestScannedNumber();
  };

  downloadExceptionImage = (photoUrl) => {
    let fileName = "image." + photoUrl.substr(photoUrl.length - 4);
    fetch(photoUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  };

  onChangeVersion = () => {
    if (window.confirm("If you change this version, this will restart your scanning session.")) {
      this.setState({ showNewUi: true });
    }
  };

  getScanTypesDropDown = () => {
    let scanTypes = localStorage.getItem("scanTypes");
    scanTypes = JSON.parse(scanTypes);
    const dropdownScanTypes = scanTypes.map((scannedType) => {
      return <option value={scannedType.name}>{scannedType.name.toUpperCase()}</option>;
    });
    return dropdownScanTypes;
  };

  getExceptionName = () => {
    if (this.state.exceptions && this.state.exceptions.length > 0) {
      let selectedException = _.find(this.state.exceptionTypes, (x) => x.name === this.state.exceptions[0].name);
      return selectedException ? selectedException.description : "";
    }
    return "";
  };

  getExceptionDescription = () => {
    if (this.state.exceptions && this.state.exceptions.length > 0) {
      let selectedException = _.find(this.state.exceptionTypes, (x) => x.name === this.state.exceptions[0].name);
      return selectedException ? this.mapExceptionToAction(selectedException.description) : "";
    }
    return "";
  };

  mapExceptionToAction = (fieldName) => {
    const actionToTrigger = {
      "Failed Delivery (Maximum Attempts)": "Escalate to Merchant",
      "Delivery Not Attempted (Out of Coverage)": "Escalate to Merchant",
      Damaged: "Escalate to Merchant",
      "Failed Delivery (Consignee Rejected)": "Escalate to Merchant",
      "Failed Delivery (Consignee Wrong Address)": "Contact Consignee",
      "Failed Delivery (Consignee Unavailable)": "Contact Consignee",
      "Consignee Request Reschedule": "Contact Consignee",
      "Pending Tax Documents": "Bin to Exceptions Area",
      "Delivery Not Attempted (Missorting)": "Bin to Exceptions Area",
      "Returns to Client": "Bin to Exceptions Area",
      Duplicate: "Bin to Exceptions Area",
      "No Data": "Bin to Exceptions Area",
      "No Label": "Bin to Exceptions Area",
      "Disposal of Parcel": "Bin to Exceptions Area",
    };
    return actionToTrigger[fieldName];
  };

  render() {
    if (!this.state.isLoggedIn) {
      return <Redirect push to="/scan/hubmanage/old/login" />;
    }

    if (this.state.showNewUi) {
      return <Redirect push to="/scan/hubmanage/new/parcel-scan" />;
    }

    if (this.state.showNewUi) {
      return <Redirect push to="/scan/hubmanage/new/parcel-scan" />;
    }

    const binLabels = this.bins.map((bin) => {
      return (
        <span
          class="badge"
          style={{
            backgroundColor: bin.background_colour,
            color: bin.text_colour,
            marginRight: "4px",
          }}
        >
          {bin.name}
        </span>
      );
    });

    let numberOfScannedItemsToShow = this.state.scannedItems.length;
    if (!this.state.showAllScannedItems) {
      numberOfScannedItemsToShow = 25;
    }
    const renderScannedItems = this.state.scannedItems.slice(0, numberOfScannedItemsToShow).map((scannedItem) => {
      return (
        <div className="hms-inbound">
          <label className="tracking-number">{scannedItem}</label>
        </div>
      );
    });

    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    let date = new Date(this.state.locationChangeTime);
    const locationInputClass = this.state.addLocationError ? "is-invalid" : "";

    const hasZeroRecipients = this.state.currScanType === "warehouse outbound" && this.state.recipientsList.length == 0;
    const recipientListError = hasZeroRecipients ? "pl-5 pr-5" : "";

    const exceptionButtonClass = this.state.showExceptionsBox ? "oi oi-chevron-top" : "oi oi-chevron-bottom";
    const addLocationButtonClass = this.state.showAddLocationBox ? "oi oi-chevron-top" : "oi oi-chevron-bottom";
    const dimInputClass = this.state.dimUpdateSuccess ? "form-control success-green" : "form-control";
    const noDataBoxClass = this.state.dataBoxConfig.showNoDataBox
      ? "content-container card mb-1"
      : "content-container card mb-1 d-none";
    const validSortDataBoxClass = this.state.dataBoxConfig.showSortDataBox
      ? "content-container card mb-1 valid-sort-container"
      : "content-container card mb-1 d-none";
    const errorDataBoxClass = this.state.dataBoxConfig.showErrorDataBox
      ? "content-container card mb-1 error-data-container"
      : "content-container card mb-1 error-data-container d-none";
    const validDataBoxClass = this.state.dataBoxConfig.showValidDataBox
      ? "content-container card mb-1 valid-data-container"
      : "content-container card mb-1 valid-data-container d-none";
    const validReturnInboundDataBoxClass = this.state.dataBoxConfig.showValidDataBox
      ? "content-container card mb-1 valid-data-container"
      : "content-container card mb-1 valid-data-container d-none";
    const errorImageClass = this.state.dataBoxConfig.showDuplicateTrackingNumberError
      ? "err-img err-duplicate-tn"
      : "err-img err-no-data";
    const printLabelClass =
      this.state.isLatestScanSuccess && this.state.labelPath
        ? "btn btn-lg btn-janio text-white btn-block rounded-btn"
        : "btn btn-lg btn-grey text-white btn-block rounded-btn";
    const qubevuStatusClass =
      this.state.qubevuStatus === "enabled"
        ? "badge badge-success text-capitalize"
        : "badge badge-danger text-capitalize";
    let sortString = "NO DATA";
    let line2 = "";
    let line3 = "";
    let line4 = "";
    if (this.state.currSortType === "state") {
      sortString = this.state.dataBoxConfig.consigneeState !== null ? this.state.dataBoxConfig.consigneeState : "NA";
      line2 = this.state.dataBoxConfig.consigneeCountry !== null ? this.state.dataBoxConfig.consigneeCountry : "";
    } else if (this.state.currSortType === "city") {
      sortString = this.state.dataBoxConfig.consigneeCity !== null ? this.state.dataBoxConfig.consigneeCity : "NA";
      line2 = this.state.dataBoxConfig.consigneeState !== null ? this.state.dataBoxConfig.consigneeState : "";
      line3 = this.state.dataBoxConfig.consigneeCountry !== null ? this.state.dataBoxConfig.consigneeCountry : "";
    } else if (this.state.currSortType === "postal") {
      sortString = this.state.dataBoxConfig.consigneePostal !== null ? this.state.dataBoxConfig.consigneePostal : "NA";
      line2 = this.state.dataBoxConfig.consigneeCity !== null ? this.state.dataBoxConfig.consigneeCity : "";
      line3 = this.state.dataBoxConfig.consigneeState !== null ? this.state.dataBoxConfig.consigneeState : "";
      line4 = this.state.dataBoxConfig.consigneeCountry !== null ? this.state.dataBoxConfig.consigneeCountry : "";
    } else if (this.state.statusCode == 500) {
      sortString = "Server Error 500";
    }

    const dropdownRecipients = this.state.recipientsList.map((recipient) => {
      return <option value={recipient.name}>{recipient.name}</option>;
    });

    const dropdownScanTypes = localStorage
      .getItem("scanTypes")
      .split(",")
      .map((scannedType) => {
        return <option value={scannedType}>{scannedType.toUpperCase()}</option>;
      });

    const exceptionsList = this.state.exceptions.map((exception) => {
      const exceptionDate = new Date(exception.created_on);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      return (
        <div style={{ width: "100%" }} class="d-flex mb-2">
          <div className="sweep-exception-remedy-btn">
            <button
              type="button"
              // style={{ height: '65px' }}
              style={{ height: "65px", whiteSpace: "pre-wrap", minWidth: "110px" }}
              class="btn btn-primary btn-block"
              onClick={() => {
                this.resolveException(exception.id);
              }}
            >
              Remedy Exception
            </button>
          </div>
          <div className="d-flex flex-column ml-4">
            <div>
              {`${exceptionDate.getDate()} ${monthNames[exceptionDate.getMonth()]}
                ${exceptionDate.getFullYear()} at ${this.getFormatTime(exceptionDate)}`}
            </div>
            <div class="text-uppercase text-break">{exception.name}</div>
            <div class="text-break">{exception.description}</div>
            <div class="text-break">{exception.note}</div>
            {exception.photo_url && (
              <div
                className="text-break pointer download-image-text"
                onClick={() => this.downloadExceptionImage(exception.photo_url)}
              >
                Download Image
              </div>
            )}
          </div>
        </div>
      );
    });

    const remedyExceptions = () => {
      return (
        <div className="pt-3 pb-3">
          <div className="text-center sweep-exception-text mt-4">exceptions</div>
          {!this.state.removeExceptionSuccess ? (
            ""
          ) : (
            <div style={{ textAlign: "center", color: "#1ABC9C" }}>Successfully removed exception!</div>
          )}
          <div className="mt-4 ml-3 mr-3 mb-4">
            {exceptionsList.length > 0 ? (
              exceptionsList
            ) : (
              <div className="text-center" style={{ width: "100%", color: "#4d4d4d", fontSize: "14px" }}>
                No Exceptions
              </div>
            )}
            <div className="text-center sweep-exception-text mt-5">timeline</div>
            <div className="mt-4 ml-5">
              {this.getPrivateStatusTimeline()}

              {/* {
                this.state.privateStatuses && this.state.privateStatuses.map((privateStatus) => {
                  return <Timeline className="hms-scanner-timeline">
                    {
                      privateStatus.updates.map((status, index) => {
                        let monthNames =["January", "February", "March", "April", "May", "June",
                          "July", "August", "September", "October", "November", "December"
                      ];

                      let date = new Date(status.updated_on);
                      return (
                        <Timeline.Item className="hms-scanner-timeline-item" color={index == 0 ? 'blue' : 'gray' }>
                          {status.status}<br />
                          {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()} at ${this.getFormatTime(date)}`}
                        </Timeline.Item>
                      )
                      })
                    }
                  </Timeline>
                })
              } */}
            </div>
          </div>
        </div>
      );
    };

    const exceptionTypes = () => {
      return (
        this.state.exceptionTypes &&
        this.state.exceptionTypes.length > 0 &&
        this.state.exceptionTypes.map((exceptionType, index) => {
          console.log("ExceptionType", exceptionType);
          return (
            <option key={index} value={exceptionType.name}>
              {exceptionType.name} - {exceptionType.description.toUpperCase()}
            </option>
          );
        })
      );
    };
    return (
      <div className="main-container d-flex flex-column align-items-left">
        <div>
          <h1 className="font-weight-bolder pl-0 float-left">Parcel Scan</h1>
          <div className="float-right mt-2">
            <Dropdown overlay={this.getMenu()} placement="bottomLeft" trigger={["click"]}>
              <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
                Menu
              </button>
            </Dropdown>
            <button type="button" className="btn btn-outline-danger" onClick={this.clearSession.bind(this)}>
              End Session
            </button>
          </div>
        </div>

        <div className="d-flex">
          <nav aria-label="breadcrumb text-left">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <LinkContainer to="/scan">
                  <a>
                    <Trans>Home</Trans>
                  </a>
                </LinkContainer>
              </li>
              <li className="breadcrumb-item">
                <LinkContainer to={`/scan/${this.state.menuType}`}>
                  <a>
                    <Trans>{this.state.menucta}</Trans>
                  </a>
                </LinkContainer>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Parcel Scan
              </li>
            </ol>
          </nav>

          {/* <div className="toggle-container">
            <span className="toggle-text">Old</span>
            <span className="toggle-text">|</span>
            <span className="breadcrumb-link" onClick={this.onChangeVersion}>New</span>
          </div> */}
        </div>

        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <div id="hub-id" style={{ marginBottom: 1 + "rem" }}>
              <label>
                <Trans>Hub ID</Trans>
              </label>
              <div>
                <label>{this.state.hubId}</label>
              </div>
            </div>
            <div id="user-id" style={{ marginBottom: 1 + "rem" }}>
              <label>
                <Trans>User ID</Trans>
              </label>
              <div>
                <label>{this.state.userName}</label>
              </div>
            </div>
            <div id="qubevu-status">
              <label>
                <Trans>QubeVu Status</Trans>
              </label>
              <div>
                <label className={qubevuStatusClass}>{this.state.qubevuStatus}</label>
              </div>
            </div>
          </div>
        </div>

        <div className="content-container card mb-1 p-2 cnt-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <label>
              <Trans>Scan Type</Trans>
            </label>
            <div>
              <select
                className="form-control"
                value={this.state.currScanType}
                onChange={this.setScanningState.bind(this)}
              >
                <option disabled selected value="">
                  Please select type
                </option>
                {this.getScanTypesDropDown()}
              </select>
            </div>
          </div>

          {this.state.currScanType === "warehouse outbound" && (
            <div className="form-group d-block justify-content-between px-3.5">
              <label>Send to Network Partner</label>
              <div>
                <select
                  className="form-control"
                  value={this.state.selectedRecipient}
                  disabled={_.isEmpty(this.state.selectedRecipient) && !_.isEmpty(this.state.groupId)}
                  onChange={this.setRecipientsState}
                >
                  <option selected value="">
                    Please select Network Partner
                  </option>
                  {dropdownRecipients}
                </select>
              </div>
            </div>
          )}

          {this.state.currScanType === "" || this.state.metaScanType === null ? null : (
            <div className="form-group d-block justify-content-between px-3.5">
              <label>
                <Trans>Group ID (Optional)</Trans>
              </label>
              <div>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.groupId}
                  disabled={!_.isEmpty(this.state.selectedRecipient) && _.isEmpty(this.state.groupId)}
                  onChange={(e) => {
                    const inputVal = e.target.value.toUpperCase();
                    this.setState({
                      groupId: inputVal,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>

        {this.state.currScanType !== "sort by" ? null : (
          <div className="content-container card mb-1 p-2">
            <div className="form-group d-block justify-content-between px-3.5">
              <label>
                <Trans>Sort Type</Trans>
              </label>
              <div>
                <select className="form-control" value={this.state.currSortType} onChange={this.setSortType.bind(this)}>
                  <option selected value="country">
                    COUNTRY
                  </option>
                  <option value="state">STATE / PROVINCE</option>
                  <option value="city">CITY</option>
                  <option value="postal">POSTAL CODE</option>
                  <option value="np">NETWORK PARTNER</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {this.state.currScanType === "sort by" && this.state.currSortType !== "country" ? (
          <div className={validSortDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen">
              {this.state.currSortType == "np" && this.state.bins.length > 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center">
                  {this.binsToShow.map((bin) => {
                    if (bin.type && bin.type !== "NP") {
                      return <div className="line1 mt-3">No NP bin type configured</div>;
                    } else if (bin.type) {
                      return <div className="line1 mt-3">{bin.name}</div>;
                    } else {
                      return <div className="line1 mt-3">No NP bin type configured</div>;
                    }
                  })}
                </div>
              ) : (
                <div className="d-flex flex-column justify-content-center">
                  <label className="line1">{sortString}</label>
                  <label className="otherlines">{line2}</label>
                  <label className="otherlines">{line3}</label>
                  <label className="otherlines">{line4}</label>
                </div>
              )}
            </div>
          </div>
        ) : null}

        {this.state.currScanType === "" ||
        this.state.currScanType === "print do" ||
        this.state.currScanType === "returns inbound" ||
        this.state.currSortType !== "country" ? null : (
          <div id="valid-data-box" className={validDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-valid-info">
              <label className="country-name">{this.state.dataBoxConfig.consigneeCountry}</label>
              <div>
                <label className="meta-info green-border-right customs-info">
                  <Trans>{this.state.dataBoxConfig.incoterm}</Trans>
                </label>
                <label className="meta-info green-border-right postcode-postfix">
                  {this.state.dataBoxConfig.consigneePostalPreFix}
                </label>
                <label className="meta-info payment-type">{this.state.dataBoxConfig.paymentType}</label>
              </div>
              {this.state.dataBoxConfig.consigneeStateCity && (
                <div>
                  <label className="destination-hub">{this.state.dataBoxConfig.consigneeStateCity}</label>
                </div>
              )}
              <div>
                <label className="merchant-name">{this.state.dataBoxConfig.merchantName}</label>
              </div>
              {this.state.dataBoxConfig.outboundNetworkPartner && (
                <div>
                  <label className="merchant-name">
                    {this.state.latestScannedNumber} outbounded to {this.state.dataBoxConfig.outboundNetworkPartner}
                  </label>
                </div>
              )}
              <div style={{ float: "left", marginLeft: "10px" }}>
                <h5>{binLabels}</h5>
              </div>
              {this.state.metaScanType != "sweep" ? null : (
                <div style={{ float: "left", width: "100%", textAlign: "center" }}>
                  {this.state.dataBoxConfig.lastUpdateMessage}
                </div>
              )}
              {this.state.metaScanType != "sweep" ? null : (
                <div style={{ float: "left", width: "100%", textAlign: "center" }}>
                  {this.state.dataBoxConfig.lastUpdateBy}
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.currScanType === "returns inbound" && (
          <div id="valid-data-box" className={validReturnInboundDataBoxClass}>
            <div className="d-flex flex-column justify-content-center text-center px-3.5 valid-return-inbound-container">
              <h4 className="return-inbound-exception">
                {this.state.exceptions && this.state.exceptions.length === 0 && "please add exception"}
                {this.state.exceptions && this.state.exceptions.length > 0 && `Exception: ${this.getExceptionName()}`}
              </h4>
              <h1 className="return-inbound-to-do-next">{this.getExceptionDescription()}</h1>
              <span className="return-inbound-scan-number">{this.state.latestScannedNumber}</span>
            </div>
          </div>
        )}

        {this.state.currScanType === "" ||
        this.state.currScanType === "print do" ||
        this.state.currSortType !== "country" ? null : !this.state.showCancelledByCustomerBox ? null : (
          <div class="alert alert-warning mt-2" role="alert">
            <Trans>CANCELLED BY CUSTOMER, STOP DELIVERY.</Trans>
          </div>
        )}

        {this.state.currScanType === "" ||
        this.state.currScanType === "print do" ||
        this.state.currSortType !== "country" ? null : !(
            this.state.showHeldUpAtCustomsBox && this.isInboundOrOutboundScantype()
          ) ? null : (
          <div class="alert alert-warning mt-2" role="alert">
            <Trans>FURTHER CHECKS BY CUSTOMS REQUIRED.</Trans>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div id="error-data-box" className={errorDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-error-info">
              <div id="err-image" className={errorImageClass}>
                &nbsp;
              </div>
              <h4 className={`err-title text-white ${recipientListError}`}>{this.state.dataBoxConfig.errorMessage}</h4>
              {this.state.dataBoxConfig.showTrackingNumber === "" ? null : (
                <div>
                  <h4 className="err-tracking-number text-white">
                    Tracking Number:{" "}
                    <h4 className="text-white" style={{ textTransform: "uppercase" }}>
                      {this.state.dataBoxConfig.showTrackingNumber}
                    </h4>
                  </h4>
                </div>
              )}
              {!this.state.errorAcknowledgementRequired ? null : (
                <div>
                  <h3 class="font-weight-bold text-white">ACKNOWLEDGEMENT REQUIRED</h3>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : !this.state
            .errorAcknowledgementRequired ? null : (
          <div className={"content-container card mb-1 " + errorDataBoxClass}>
            <div className="d-block justify-content-between px-3.5">
              <button
                className="btn btn-lg btn-danger btn-block rounded-btn"
                style={{ "background-color": "#e31920", "border-color": "#e31920" }}
                onClick={this.acknowledgeError.bind(this)}
              >
                <Trans>Acknowledge Error</Trans>
              </button>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div id="no-data-box" className={noDataBoxClass}>
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-no-info">
              <label style={{ marginTop: "95px" }}>
                <Trans>No data shown here</Trans>
              </label>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div className="content-container card mb-1">
            <div className="d-block justify-content-between px-3.5">
              <button className={printLabelClass} onClick={this.printLabel.bind(this)}>
                <Trans>Print Label</Trans>
              </button>
            </div>
            {this.state.qzTrayDownloadError && (
              <Alert className="mt-3" type="error" message="QZ Tray is not connected." />
            )}
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div>
            {this.state.isScanning ? <Scanner onDetected={this.scanItem.bind(this)} /> : null}
            <div className="content-container card mb-1">
              <div className="d-block justify-content-between px-3.5">
                <button
                  className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}
                  onClick={this.toggleScanning.bind(this)}
                >
                  {this.state.isScanning ? <Trans>Stop Camera</Trans> : <Trans>Use Camera</Trans>}
                </button>
              </div>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div className="d-block justify-content-between px-3.5" style={{ marginTop: "10px", marginBottom: "10px" }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="defaultCheck1"
                onClick={(e) => {
                  this.setState({ autoRelabel: !this.state.autoRelabel });
                }}
              />
              <label class="form-check-label" for="defaultCheck1">
                Auto Print Label
              </label>
            </div>
          </div>
        )}

        {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : (
          <div className="content-container card mb-1">
            <div className="form-group d-block justify-content-between px-3.5 m-2">
              {this.state.showZebraScanner && <ZebraScanner onScanComplete={this.onZebraScanComplete} />}
              {!this.state.showZebraScanner && (
                <div className="input-group">
                  <input
                    name="manualTrackingNo"
                    type="text"
                    className="form-control"
                    placeholder={resources[i18n.language]["translations"]["Tracking No(s)"]}
                    value={this.state.manualTrackingNo}
                    onChange={(e) => this.handleOnChange(e)}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 || e.which === 13) {
                        this.updateLatestScannedNumber();
                      }
                    }}
                    ref={this.trackingNumberInput}
                    disabled={hasZeroRecipients}
                  />
                  <div>
                    <button
                      className="ml-1 btn btn-janio text-white"
                      disabled={hasZeroRecipients}
                      onClick={() => {
                        this.updateLatestScannedNumber();
                      }}
                    >
                      <Trans>Add</Trans>
                    </button>
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-6">
                  <b>Laser</b>&nbsp;
                  <Switch
                    className="scanner-switch"
                    checked={this.state.showZebraScanner}
                    disabled={hasZeroRecipients}
                    onChange={this.onChangeZebraSwitch}
                  />
                  &nbsp;<b>Camera</b>
                </div>
              </div>
            </div>
            <div className="form-group d-block justify-content-between px-3.5 mt-2 mb-0 pt-0 pb-0">
              <label style={{ float: "left" }} className="pb-0 mb-0">
                For tracking number:{" "}
                {this.state.isLatestScanSuccess && this.state.latestScannedNumber
                  ? this.state.latestScannedNumber
                  : "N/A"}{" "}
                (last success scan)
              </label>
            </div>

            <div className="form-group d-block justify-content-between px-3.5 m-2 mt-0 pt-0">
              <div style={{ width: "100%" }}>
                <div style={{ float: "left" }}>
                  <label style={{ float: "left" }}>L (cms)</label>
                  <label style={{ float: "left", marginLeft: "28px" }}>W (cms)</label>
                  <label style={{ float: "left", marginLeft: "25px" }}>H (cms)</label>
                  <div className="dimweight-input-group">
                    <input
                      type="text"
                      className={dimInputClass}
                      name="length"
                      placeholder="Length"
                      value={this.state.orderInfo_length}
                      onChange={(e) => {
                        if (this.validateNumber(e.target.value)) {
                          this.setState({
                            orderInfo_length: e.target.value,
                          });
                        }
                      }}
                    />
                    <input
                      type="text"
                      className={dimInputClass}
                      name="width"
                      placeholder="Width"
                      value={this.state.orderInfo_width}
                      onChange={(e) => {
                        if (this.validateNumber(e.target.value)) {
                          this.setState({
                            orderInfo_width: e.target.value,
                          });
                        }
                      }}
                    />
                    <input
                      type="text"
                      className={dimInputClass}
                      name="height"
                      placeholder="Height"
                      value={this.state.orderInfo_height}
                      onChange={(e) => {
                        if (this.validateNumber(e.target.value)) {
                          this.setState({
                            orderInfo_height: e.target.value,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
                <div style={{ float: "right" }}>
                  <label>Wt (kgs)</label>
                  <div className="dimweight-input-group">
                    <input
                      type="text"
                      className={dimInputClass}
                      name="weight"
                      placeholder="Weight"
                      value={this.state.orderInfo_weight}
                      onChange={(e) => {
                        if (this.validateNumber(e.target.value)) {
                          this.setState({
                            orderInfo_weight: e.target.value,
                          });
                        }
                      }}
                    />
                    <div style={{ clear: "both" }}>
                      <button
                        type="button"
                        style={{ width: "100%" }}
                        class="btn btn-primary float-right mt-2"
                        onClick={this.updateDimensions.bind(this)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-lg btn-janio text-white btn-block rounded-btn mt-2"
                onClick={(e) => {
                  this.setState({ showExceptionsBox: !this.state.showExceptionsBox });
                }}
              >
                Mark Exception
                <span style={{ float: "right", width: "25px", top: "7px" }} className={exceptionButtonClass}></span>
              </button>
            </div>

            {!this.state.showExceptionsBox ? null : (
              <div className="form-group d-block justify-content-between px-3.5 mt-2">
                <div className="d-block mt-2 mb-1 pt-0 pb-0">
                  <label className="pb-0 mb-0">
                    For tracking number:{" "}
                    {this.state.isLatestScanSuccess && this.state.latestScannedNumber
                      ? this.state.latestScannedNumber
                      : "N/A"}{" "}
                    (last success scan)
                  </label>
                </div>

                <label>
                  <Trans>Exception Type</Trans>
                </label>
                <div>
                  <select
                    className="form-control"
                    value={this.state.selectedException}
                    onChange={(e) => {
                      this.setState({
                        selectedException: e.target.value,
                      });
                    }}
                  >
                    <option disabled selected value="">
                      Please select exception
                    </option>
                    {exceptionTypes()}
                  </select>
                </div>

                <label className="mt-2">
                  <Trans>Exception Description</Trans>
                </label>
                <div>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={this.state.exceptionDescription}
                    onChange={(e) => {
                      this.setState({
                        exceptionDescription: e.target.value,
                      });
                    }}
                  />
                </div>

                <div className="mt-2 mb-3">
                  <label>(Optional) Photo Proof</label>
                  <br />
                  <label htmlFor="exception-image" className="btn choose-file-button p-1">
                    Choose File
                  </label>
                  <input
                    type="file"
                    className="d-none"
                    id="exception-image"
                    onChange={this.handleExceptionImageChange}
                  />
                  {!this.state.exceptionImageName && !this.state.imageInProcessing && (
                    <span className="ml-2">No file chosen</span>
                  )}
                  {this.state.exceptionImageName && <span className="ml-2">{this.state.exceptionImageName}</span>}
                  {this.state.imageInProcessing && <Spin className="ml-2" tip="Processing..." />}
                </div>

                {this.state.exceptionImageTypeError && (
                  <Alert className="mt-3 mb-3" type="error" message={this.state.exceptionImageTypeError} />
                )}
                {!this.state.saveExceptionSuccess ? (
                  ""
                ) : (
                  <div style={{ textAlign: "center", color: "#1ABC9C" }}>Successfully marked exception!</div>
                )}
                {!this.state.saveExceptionError ? (
                  ""
                ) : (
                  <div style={{ textAlign: "center", color: "#bc1a1a" }}>Error occured, please try again!</div>
                )}
                <div class="container d-flex justify-content-between mt-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="autoSaveException"
                      checked={this.state.autoSaveException}
                      onClick={(e) => {
                        this.setState({ autoSaveException: !this.state.autoSaveException });
                      }}
                    />
                    <label class="form-check-label" for="autoSaveException">
                      After scanning, automatically save exception
                    </label>
                  </div>
                  <div style={{ clear: "both" }}>
                    <button
                      type="button"
                      disabled={this.state.savingException || this.state.imageInProcessing}
                      class="btn btn-primary"
                      onClick={this.saveExceptions}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* location tab */}
            {this.state.currScanType == "sweep" && (
              <div>
                <button
                  className="btn btn-lg btn-janio text-white btn-block rounded-btn mt-2"
                  onClick={(e) => {
                    this.setState({
                      showAddLocationBox: !this.state.showAddLocationBox,
                    });
                  }}
                >
                  Add Location
                  <span style={{ float: "right", width: "25px", top: "7px" }} className={addLocationButtonClass}></span>
                </button>
              </div>
            )}

            {this.state.showAddLocationBox && (
              <div className="form-group d-block justify-content-between px-3.5 mt-2">
                <label className="mt-2">Location</label>
                <div>
                  <input
                    name="location"
                    type="text"
                    value={this.state.location}
                    className={`form-control ${locationInputClass}`}
                    onChange={(e) => this.handleAddLocationChange(e)}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 || e.which === 13) {
                        this.addLocation();
                      }
                    }}
                  />
                  {this.state.addLocationError && <span className="text-danger">{this.state.addLocationError}</span>}
                </div>
                <div class="container d-flex justify-content-between mt-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="autoSaveLocation"
                      checked={this.state.autoSaveLocation}
                      onClick={(e) => {
                        this.setState({ autoSaveLocation: !this.state.autoSaveLocation });
                      }}
                    />
                    <label class="form-check-label" for="autoSaveLocation">
                      After scanning, automatically save location
                    </label>
                  </div>
                  <div style={{ clear: "both" }}>
                    <button
                      type="button"
                      class="btn btn-primary"
                      disabled={this.state.addLocationError !== null}
                      onClick={this.addLocation.bind(this)}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            )}

            {this.state.currScanType === "" || this.state.currScanType === "print do" ? null : this.state.scannedItems
                .length <= 25 ? null : (
              <div>
                <div className="content-container card my-1">
                  <div className="d-block justify-content-between px-3.5">
                    <button
                      className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}
                      onClick={() => {
                        this.setState({
                          showAllScannedItems: !this.state.showAllScannedItems,
                        });
                      }}
                    >
                      {this.state.showAllScannedItems ? (
                        <Trans>Show only 25 scanned parcel</Trans>
                      ) : (
                        <Trans>Show all scanned parcels</Trans>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}

            {this.state.currScanType == "sweep" ? null : (
              <div className="scan-status">
                <div style={{ marginTop: "30px" }}>
                  <label>
                    <span className="parcel-count-dark">{this.state.scannedItems.length}</span> parcel scanned
                  </label>
                </div>
              </div>
            )}

            {this.state.currScanType != "sweep" && this.state.scannedItems.length > 0 ? (
              <div className="hms-inbound">
                {this.state.metaScanType !== "" && (
                  <label className="inbound-label">
                    <Trans>{this.state.metaScanType}</Trans>
                  </label>
                )}
                {this.state.currScanType == "sort by" && <div className="text-center sweep-exception-text">sort</div>}
              </div>
            ) : null}
            {this.state.currScanType != "sweep" && this.state.scannedItems.length > 0 ? renderScannedItems : null}

            {this.state.currScanType == "sweep" && this.state.scannedItems.length > 0 && (
              <React.Fragment>
                <div className="text-center sweep-tracking-number mt-4">{this.state.latestScannedNumber}</div>
                <div className="text-center sweep-exception-text mt-4">Location</div>
                <div className="text-center" style={{ width: "100%", color: "#4d4d4d", fontSize: "14px" }}>
                  {this.state.trackingNumberLocation ? this.state.trackingNumberLocation : "No Location"}
                </div>

                {this.state.trackingNumberLocation && (
                  <div className="text-center" style={{ width: "100%", color: "#4d4d4d", fontSize: "14px" }}>
                    {`${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()} at ${this.getFormatTime(
                      date
                    )} `}
                    {this.state.locationChangeName && `by ${this.state.locationChangeName}`}
                  </div>
                )}
              </React.Fragment>
            )}

            {(this.state.currScanType == "sweep" || this.state.currScanType == "returns inbound") &&
            this.state.scannedItems.length > 0
              ? remedyExceptions()
              : null}

            {this.state.currScanType === "" ||
            this.state.currScanType === "print do" ||
            this.state.scannedItems.length === 0 ? null : (
              <div>
                <div className="content-container card mt-1">
                  <div className="d-block justify-content-between px-3.5">
                    <CopyToClipboard
                      text={this.state.scannedItems.join("\r\n")}
                      onCopy={() => {
                        this.setState({ copiedToClipboard: true });
                        setTimeout(() => {
                          this.setState({ copiedToClipboard: false });
                        }, 2000);
                      }}
                    >
                      <button className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}>
                        {this.state.copiedToClipboard ? <Trans>Copied!</Trans> : <Trans>Copy to clipboard</Trans>}
                      </button>
                    </CopyToClipboard>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HMSScanner);

// getOrderInfo(trackingNumber) {
//   let requestParams = this.getParams(trackingNumber);
//   return axios.get(`${ROOT_URL}/hms/get_order_details/`, {
//     params: requestParams,
//   });
// }

// try {
//   const res = await this.getOrderInfo(trackingNumber);
//   this.setState({ waitingForApiResponse: false })
//   const lastUpdatedOn = new Date(res.data.updated_on);
//   const monthNames = [
//     'January', 'February', 'March', 'April', 'May', 'June', 'July',
//     'August', 'September', 'October', 'November', 'December',
//   ];
//   this.exceptions = [];
//   if (res.data.exceptions.length > 0) {
//     res.data.exceptions.forEach((exception) => {
//       const obj = {
//         id: exception.id,
//         name: exception.name,
//         note: exception.note,
//         created_by: exception.created_by,
//         created_on: exception.created_on,
//       };
//       this.exceptions.push(obj);
//     });
//   }

//   this.setState({
//     orderInfo_length: res.data.order_length,
//     orderInfo_width: res.data.order_width,
//     orderInfo_height: res.data.order_height,
//     orderInfo_weight: res.data.order_weight,
//     dataBoxConfig: {
//       merchantName: res.data.agent_application_name,
//       consigneeCountry: res.data.consignee_country,
//       consigneePostalPreFix: res.data.consignee_postal.slice(0, 2),
//       consigneeStateCity:
//         res.data.consignee_city + ', ' + res.data.consignee_state,
//       consigneeState: res.data.consignee_state,
//       consigneeCity: res.data.consignee_city,
//       consigneePostal: res.data.consignee_postal,
//       paymentType: res.data.payment_type,
//       incoterm:
//         res.data.incoterm === undefined || res.data.incoterm === null
//           ? 'DDP'
//           : res.data.incoterm,
//       showNoDataBox: false,
//       showValidDataBox: !(
//         this.state.currScanType === 'sort by' &&
//         this.state.currSortType !== 'country'
//       ),
//       showSortDataBox:
//         this.state.currScanType === 'sort by' &&
//         this.state.currSortType !== 'country',
//       showErrorDataBox: false,
//       totalOrderValue:
//         res.data.order_currency + ' ' + res.data.order_total_value,
//       lastUpdateMessage: res.data.private_tracker_status_code,
//       lastUpdateBy:
//         res.data.updated_by == ''
//           ? lastUpdatedOn.getDate() +
//             ' ' +
//             monthNames[lastUpdatedOn.getMonth()] +
//             ' ' +
//             lastUpdatedOn.getFullYear()
//           : lastUpdatedOn.getDate() +
//             ' ' +
//             monthNames[lastUpdatedOn.getMonth()] +
//             ' ' +
//             lastUpdatedOn.getFullYear() +
//             ' by ' +
//             res.data.updated_by,
//     },
//     dimUpdateSuccess: false,
//     isLabelAvailable: res.data.order_label_url === null ? false : true,
//     labelPath: res.data.order_label_url,
//     showExceptionsBox: false,
//     showCancelledByCustomerBox: false,
//     saveExceptionError: false,
//     saveExceptionSuccess: false,
//     selectedException: '',
//     exceptionDescription: '',
//     privateStatuses: res.data.private_statuses,
//     bins: res.data.bins,
//     statusCode: res.status
//   });
//   if (this.state.autoRelabel) {
//     await this.printLabel();
//   }

//   if (res.data.tracker_status_code === 'CANCELLED_BY_CUSTOMER'){
//     this.playSound('error');
//   }
//   this.setState({
//     showCancelledByCustomerBox: res.data.is_cancelled
//   })

//   this.bins = [];
//   this.binsToShow = [];
//   this.computeBins(res.data.bins);

//   return true;
// } catch (e) {
//   console.log('error in fetchOrderInfo')
//   console.log(e);
//   this.setStateToErrorState(e.data.tracking_nos[1], e.status);
//   return false;
// }
// }

// async printLabel() {
//   if (!this.state.isLabelAvailable) return;
//   let parts = this.state.labelPath.split('/');
//   let url = '/scan/hubmanage/print/' + parts[parts.length - 1];
//   const w = window.open(url, '_blank');
//   w.onload = (e) => {
//     w.onfocus = () => {
//       w.close();
//     };
//     w.print();
//   };
// }
