import React from "react";
import { Radio } from "antd";
import "./style.scss";

function Select(props) {
  const { label, value, onChange, options, className } = props;

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  return (
    <React.Fragment>
      {label && <h6 className="form-label">{label}</h6>}
      <Radio.Group onChange={onChange} value={value} className={className}>
        {options.map((option, index) => {
          return (
            <Radio key={index} style={radioStyle} value={option.value} className="form-label--radio-text">
              {option.label}
            </Radio>
          );
        })}
      </Radio.Group>
    </React.Fragment>
  );
}

export default Select;

