import React, { useState } from "react";
import { ROOT_URL } from "../../../actions";
import { Alert, Pagination, Modal } from "antd";
import { toast } from "react-toastify";
import axios from "axios";

export default function RemoveCargoPage(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDeletePackagesError, setShowDeletePackagesError] = useState(false);

  const { newShipmentNo, newCargoTrackingData, cargoNumbersList, cargoNumbersData, showQzTrayNotActiveError } = props;

  const deletePackageContainerClass = cargoNumbersData && cargoNumbersData.total_records > 0 ? "" : "d-none";

  return (
    <React.Fragment>
      <div className="content-container card mt-2 mb-1 p-2 cnt-2 d-flex flex-column">
        <div className="d-flex flex-column align-items-center mt-2">
          <h5 className="add-cargo-label">
            Cargo ({newCargoTrackingData ? newCargoTrackingData.cargo_type : ""}) to{" "}
            {newCargoTrackingData ? newCargoTrackingData.dest_country : ""}
          </h5>
          <h6 className="add-cargo-label">{newCargoTrackingData && newCargoTrackingData.cargo_tracking_no}</h6>
          <h6 className="font-weight-bold">
            In Shipment
            <span className="font-weight-bold shipment-link-color pointer" onClick={props.showShipmentToPartner}>
              {" "}
              {newShipmentNo}
            </span>
          </h6>
        </div>
        <div className="d-flex justify-content-between p-2 mt-3">
          <button className="btn cargo-button" onClick={props.showRemoveCargoFromShipmentPage}>
            Remove Cargo
          </button>
          <button className="btn cargo-button" onClick={props.printLabelShipmentToCargo}>
            Print Label
          </button>
          <button className="btn cargo-button" onClick={props.showCreateCargoToShipment}>
            Add Cargo
          </button>
        </div>
        <div className={"pl-2 " + deletePackageContainerClass}>
          <button className="btn cargo-button" onClick={toggleModal}>
            Remove Packages in Cargo
          </button>
        </div>
        {showDeletePackagesError && (
          <div className="mt-3">
            <Alert message="There is an error, please try again." type="error" />
          </div>
        )}
        {showQzTrayNotActiveError && (
          <div className="mt-3">
            <Alert message="QZ Tray is not connected" type="error" />
          </div>
        )}
        {cargoNumbersList && cargoNumbersList.length > 0 && (
          <React.Fragment>
            <div className="w-100 cargo-bg text-dark text-center pt-2 mt-5">
              <h5 className="added-to-cargo-text">{getRecordsLength()} Added To This Cargo</h5>
            </div>
            <div className="mb-1 p-2">
              <div className="d-flex flex-column align-items-center mb-2 p-3">
                {cargoNumbersList.map((number, index) => {
                  return (
                    <h6 key={index} className="cargo-tracking-number mb-3">
                      {number.scanned_tracking_no}
                    </h6>
                  );
                })}
              </div>
            </div>
          </React.Fragment>
        )}
        {cargoNumbersData && cargoNumbersList && cargoNumbersList.length > 0 && (
          <div className="ml-5 mb-3 pt-0">
            <Pagination
              current={currentPage}
              defaultPageSize={50}
              total={cargoNumbersData.total_records}
              onChange={onPageChange}
              className="shipment-pagination"
              responsive
            />
          </div>
        )}
      </div>
      <Modal
        className="confirmation-modal"
        visible={showConfirmationModal}
        okText="Yes"
        okType="success"
        okButtonProps={{ className: "okButton" }}
        cancelText="No, I don’t want to delete"
        cancelButtonProps={{ className: "cancelButton" }}
        onOk={onDeletePackages}
        onCancel={toggleModal}
      >
        <p className="confimation-modal-text">
          Are you sure you want to remove {getRecordsLength()} {getPackagesNumber()} in cargo{" "}
          {newCargoTrackingData && newCargoTrackingData.cargo_tracking_no}?
        </p>
      </Modal>
    </React.Fragment>
  );

  function getPackagesNumber() {
    if (cargoNumbersData && cargoNumbersData.total_records === 1) {
      return "package";
    }
    return "packages";
  }

  function toggleModal(e) {
    e.preventDefault();
    setShowConfirmationModal(!showConfirmationModal);
  }

  function onPageChange(page) {
    setCurrentPage(page);
    props.showCargoChildrenPage(null, newCargoTrackingData, page);
  }

  function getRecordsLength() {
    if (cargoNumbersData && cargoNumbersData.total_records > 0) {
      return cargoNumbersData.total_records;
    } else {
      return "";
    }
  }

  function onDeletePackages(e) {
    e.preventDefault();
    axios
      .put(`${ROOT_URL}/hms/remove_all_tracking_no_from_cargo/`, {
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
        cargo_tracking_no: newCargoTrackingData.cargo_tracking_no,
      })
      .then((res) => {
        setShowConfirmationModal(false);
        deleteParcelNotify();
        setTimeout(() => {
          props.showShipmentToPartner();
        }, 2500);
      })
      .catch((err) => {
        setShowConfirmationModal(false);
        setShowDeletePackagesError(true);
        setTimeout(() => {
          setShowDeletePackagesError(false);
        }, 2500);
      });
  }

  function deleteParcelNotify() {
    toast.warn(`${getRecordsLength()} Packages are removed from ${newCargoTrackingData.cargo_tracking_no}`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      closeButton: false,
      className: "text-center toast-margin",
    });
  }
}

