import React, { useEffect } from "react";
import { Divider, message } from "antd";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import { shipmentActions } from "../../../actions/rootActions";
import { LoadingOutlined } from "@ant-design/icons";

function CancelShipment(props) {
  const { shipmentCancelSuccessData, shipmentCancelLoading } = props;

  const shipment = props.location.state.shipment;

  useEffect(() => {
    if (shipmentCancelSuccessData) {
      goBackToPreviousScreen();
      message.success("Shipment has been cancelled");
      props.dispatch(shipmentActions.clearCancelShipmentState());
    }
  }, [shipmentCancelSuccessData]);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <LinkContainer to="/scan/hubmanage/shipments">
              <p className="link-title mb-0">Shipments</p>
            </LinkContainer>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">{shipment.tracking_no}</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black">Please confirm shipment cancellation</h2>
            <p className="text-span-black margin-top-20 mb-0">
              This shipment will be inactive but still remain in the system.
            </p>
            <Divider className="gray-divider" />
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button-small" onClick={goBackToPreviousScreen}>
                Back
              </button>
              <button
                type="submit"
                className="primary-button-small mb-sm-0 mb-3"
                disabled={shipmentCancelLoading}
                onClick={handleCancelShipmentClick}
              >
                {shipmentCancelLoading ? (
                  <LoadingOutlined className="loading-spinner loading-spinner-white" />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function handleCancelShipmentClick() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipment.tracking_no.trim(),
    };
    props.dispatch(shipmentActions.cancelShipment(data));
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }
}

const mapStateToProps = (state) => ({
  shipmentCancelLoading: state.shipment.shipmentCancelLoading,
  shipmentCancelSuccessData: state.shipment.shipmentCancelSuccessData,
});

export default connect(mapStateToProps)(CancelShipment);
