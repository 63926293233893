export const trolleyTypes = {
  FETCH_TROLLEY_PACKAGES_LOADING: "FETCH_TROLLEY_PACKAGES_LOADING",
  FETCH_TROLLEY_PACKAGES_SUCCESS: "FETCH_TROLLEY_PACKAGES_SUCCESS",
  FETCH_TROLLEY_PACKAGES_FAILURE: "FETCH_TROLLEY_PACKAGES_FAILURE",
  CLEAR_FETCH_TROLLEY_PACKAGES_DATA: "CLEAR_FETCH_TROLLEY_PACKAGES_DATA",

  ADD_CARGO_TO_TROLLEY_LOADING: "ADD_CARGO_TO_TROLLEY_LOADING",
  ADD_CARGO_TO_TROLLEY_SUCCESS: "ADD_CARGO_TO_TROLLEY_SUCCESS",
  ADD_CARGO_TO_TROLLEY_FAILURE: "ADD_CARGO_TO_TROLLEY_FAILURE",
  CLEAR_ADD_CARGO_TO_TROLLEY_DATA: "CLEAR_ADD_CARGO_TO_TROLLEY_DATA",

  ADD_LOCATION_LOADING: "ADD_LOCATION_LOADING",
  ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",
  ADD_LOCATION_FAILURE: "ADD_LOCATION_FAILURE",
  SAVE_LOCATION_DATA: "SAVE_LOCATION_DATA",
  CLEAR_ADD_LOCATION_DATA: "CLEAR_ADD_LOCATION_DATA",

  CLEAR_TROLLEY_SCAN_DATA: "CLEAR_TROLLEY_SCAN_DATA"
}