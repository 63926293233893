import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { reactI18nextModule } from "react-i18next";

export const resources = {
  en: {
    translations: {
      "Language": "Language",
      "Home": "Home",
      "Firstmile": "Firstmile",
      "Warehouse": "Warehouse",
      "Print Labels": "Print Labels",
      "Pick Up": "Pick Up",
      "Deliver": "Deliver",
      "See Pick Up and Deliver Addresses": "See Pick Up and Deliver Addresses",
      "Receive": "Receive",
      "Transfer": "Transfer",
      "Staff ID": "Staff ID",
      "Please input your ID": "Please input your ID",
      "Emails": "Emails",
      "CLICK HERE TO START SCANNING": "CLICK HERE TO START SCANNING",
      "STOP SCANNING": "STOP SCANNING",
      "Tracking No(s)": "Tracking No(s)",
      "Scanned Tracking Nos": "Scanned Tracking Nos",
      "Manually Add": "Manually Add",
      "Nothing scanned.": "Nothing scanned.",
      "Comments": "Comments",
      "Delete All": "Delete All",
      "Add": "Add",
      "Send Links to Email": "Send Links to Email",
      "There is an error, please try again.": "There is an error, please try again.",
      "Results": "Results",
      "Updated": "Updated",
      "Already Picked Up": "Already Picked Up",
      "Already Delivered": "Already Delivered",
      "Already Received": "Already Received",
      "Already Transferred": "Already Transferred",
      "Not Found": "Not Found",
      "RETURN": "RETURN"
    }
  },
  id: {
    translations: {
      "Language": "Bahasa",
      "Home": "Menu Utama",
      "Firstmile": "Mil Pertama",
      "Warehouse": "Gudang",
      "Print Labels": "Cetak Label",
      "Pick Up": "Jemput",
      "Deliver": "Kirim",
      "See Pick Up and Deliver Addresses": "Lihat Alamat Penjemputan dan Pengiriman",
      "Receive": "Menerima",
      "Transfer": "Transfer",
      "Staff ID": "Identitas Pegawai",
      "Please input your ID": "Mohon Ketik ID Anda",
      "Emails": "Surat Elektronik",
      "CLICK HERE TO START SCANNING": "KLIK DISINI UNTUK MEMINDAI",
      "STOP SCANNING": "BERHENTI MEMINDAI",
      "Tracking No(s)": "Nomor Pelacakan",
      "Scanned Tracking Nos": "Nomor Pelacakan yang Terpindai",
      "Manually Add": "Tambah Secara Manual",
      "Nothing scanned.": "Tidak ada yang terpindai.",
      "Comments": "Komentar",
      "Delete All": "Hapus Semua",
      "Add": "Tambah",
      "Send Links to Email": "Kirim Tautan ke Surat Elektronik",
      "There is an error, please try again.": "Error, mohon dicoba kembali.",
      "Results": "Hasil",
      "Updated": "Terbaharui",
      "Already Picked Up": "Sudah Dijemput",
      "Already Delivered": "Sudah Dikirim",
      "Already Received": "Sudah Diterima",
      "Already Transferred": "Sudah Ditransfer",
      "Not Found": "Tidak Ditemukan",
      "RETURN": "KEMBALI"
    }
  },
  zh: {
    translations: {
      "Language": '语言',
      "Home": "主页",
      "Firstmile": "入仓",
      "Warehouse": "仓储",
      "Print Labels": "打印面单",
      "Pick Up": "上⻔取货",
      "Deliver": "⾃⾏送货",
      "See Pick Up and Deliver Addresses": "查看取货和配送地址",
      "Receive": "收货",
      "Transfer": "转送",
      "Staff ID": "员工号码",
      "Please input your ID": "请输入你的员工号码",
      "Emails": "邮箱",
      "CLICK HERE TO START SCANNING": "点击这里进行扫描",
      "STOP SCANNING": "停止扫描",
      "Tracking No(s)": "追踪单号",
      "Scanned Tracking Nos": "已扫描单号",
      "Manually Add": "手动添加",
      "Nothing scanned.": "无扫描纪录",
      "Comments": "评语及意见",
      "Delete All": "删除全部",
      "Add": "添加",
      "Send Links to Email": "发送链接至邮箱",
      "There is an error, please try again.": "有错误，请再试一次",
      "Results": "结果",
      "Updated": "已更新",
      "Already Picked Up": "已拣货",
      "Already Delivered": "已配送",
      "Already Received": "已收货",
      "Already Transferred": "已转运",
      "Not Found": "不存在",
      "RETURN": "退货"
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    // we init with resources
    resources: resources,
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
