import React from 'react';
import { Select } from 'antd';
import './style.scss';

const { Option } = Select;

function multiSelect(props) {
  const {
    label,
    placeholder,
    value,
    onChange,
    options
  } = props;

  return (
    <React.Fragment>
      {label && <h6 className="form-label">{label}</h6>}
      <Select
        onChange={onChange}
        showSearch
        value={value}
        placeholder={placeholder}
        mode="multiple"
        className="multi-select"
        optionFilterProp="children"
      >
        {options.map((option, index) => {
          return (
            <Option key={index} value={option.value}>{option.label}</Option>
          );
        })}
      </Select>
    </React.Fragment>
  )
}

export default multiSelect;