import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shipmentActions } from "../actions/shipmentActions";
import { createRequestPayload } from "../utils/createCargoRequestPayload";

export const useGetShipmentsData = () => {
  const [shipments, setShipments] = useState([]);
  const { pendingShipmentsData, inProgressShipmentsData } = useSelector((state) => ({
    pendingShipmentsData: state.shipment.pendingShipmentsData,
    inProgressShipmentsData: state.shipment.inProgressShipmentsData,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    const pendingRequestPayload = createRequestPayload({ status: "pending" }, []);
    const inProgressRequestPayload = createRequestPayload({ status: "ob_in_progress" }, []);
    dispatch(
      shipmentActions.getInProgressShipmentData({
        params: inProgressRequestPayload,
      })
    );
    dispatch(shipmentActions.getPendingShipmentData({ params: pendingRequestPayload }));
  }, []);

  useEffect(() => {
    let shipments = [];
    if (pendingShipmentsData) {
      shipments = [...shipments, ...pendingShipmentsData.shipments];
    }
    if (inProgressShipmentsData) {
      shipments = [...shipments, ...inProgressShipmentsData.shipments];
    }
    if (shipments.length > 15) {
      shipments = shipments.slice(0, 15);
      shipments = shipments.map((shipment) => {
        shipment.value = shipment.tracking_no;
        shipment.label = shipment.recipient_name + "-" + shipment.tracking_no;
        return shipment;
      });
      setShipments(shipments);
    }
  }, [pendingShipmentsData, inProgressShipmentsData]);

  return shipments;
};
