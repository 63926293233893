import React, { useEffect } from "react";
import { connect } from "react-redux";
import { message } from "antd";
import { cargoActions } from "../../../actions/rootActions";
import { generateImageName } from "../../../utils/generateImageName";
import { convertBlobToBase64 } from "../../../utils/convertBlobToBase64";
import { createRequestPayload } from "../../../utils/createCargoRequestPayload";
import { isEmpty } from "lodash";
import Camera from "../../../common/camera/camera";

function TakePhotos(props) {
  const { uploadCargoPhotoSuccess, uploadCargoPhotosError } = props;

  const { cargo, scanType } = props.location.state;

  useEffect(() => {
    if (uploadCargoPhotoSuccess) {
      message.success(uploadCargoPhotoSuccess.message);
    }
  }, [uploadCargoPhotoSuccess]);

  useEffect(() => {
    if (uploadCargoPhotosError) {
      message.error(uploadCargoPhotosError.message);
    }
  }, [uploadCargoPhotosError]);

  return (
    <Camera
      onClear={goBackToPreviousScreen}
      onCapture={handleImageUpload}
      pageName="Cargo Scan"
      scanType="Take Photo"
      route="/scan/hubmanage/cargo-scan"
    />
  );

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  async function handleImageUpload(blob) {
    const imageData = await convertBlobToBase64(blob);
    const imageName = generateImageName(cargo.tracking_no, blob.type.split("/")[1]);
    const requestPayload = createRequestPayload({
      tracking_no: cargo.tracking_no.trim(),
      image_name: imageName,
      content_type: blob.type,
      image_data: imageData,
      group_id: getGroupId(),
      scan_type: scanType,
    });
    props.dispatch(cargoActions.uploadCargoPhotos(requestPayload));
  }

  function getGroupId() {
    if (!isEmpty(props.scanSettings.groupId) || props.scanSettings.recipientId === undefined) {
      return props.scanSettings.groupId;
    } else {
      return props.scanSettings.recipientId;
    }
  }
}

const mapStateToProps = ({ cargo }) => ({
  scanSettings: cargo.scanSettingsData,
  uploadCargoPhotoSuccess: cargo.uploadCargoPhotoSuccess,
  uploadCargoPhotosError: cargo.uploadCargoPhotosError,
});

export default connect(mapStateToProps)(TakePhotos);
