import { combineReducers } from 'redux';
import authReducer from './authReducer';
import scanReducer from './scanReducer';
import shipmentReducer from './shipmentReducer';
import cargoReducer from './cargoReducer';
import picklistReducer from './picklistReducer';
import { parcelReducer } from './parcelReducer';
import { commonReducer } from './commonReducer';
import { trolleyReducer } from './trolleyReducer';

const appReducer = combineReducers({
  auth: authReducer,
  scan: scanReducer,
  shipment: shipmentReducer,
  cargo: cargoReducer,
  picklist: picklistReducer,
  parcel: parcelReducer,
  common: commonReducer,
  trolley: trolleyReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
}

export default rootReducer;
