import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPickupDeliverAddresses } from '../../actions/scanActions';
import _ from 'lodash';

class PickupDeliverAddresses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: localStorage.getItem('staffId')
    };
  }

  componentDidMount() {
    if (this.state.staffId && this.state.staffId.length >= 4 && !this.props.pickupDeliverAddresses) {
      this.props.fetchPickupDeliverAddresses(this.state.staffId);
    }
  }

  componentDidUpdate() {
    if (this.state.staffId && this.state.staffId.length >= 4 && !this.props.pickupDeliverAddresses) {
      this.props.fetchPickupDeliverAddresses(this.state.staffId);
    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;

    if (value === 'staffId') {
      localStorage.setItem('staffId', value);
    }

    this.setState({ [name]: value });
  }

  handleShowMorePickupAddresses = (e) => {
    const showMorePickupAddressesButtonId = e.target.getAttribute('id');
    const trackingNumbersDivId = e.target.nextSibling.getAttribute('id');

    if (showMorePickupAddressesButtonId === trackingNumbersDivId) {
      e.target.style.display = 'none';
      e.target.nextSibling.removeAttribute('style');
    }
  }

  handleShowMoreDeliverAddresses = (e) => {
    const showMoreDeliverAddressesButtonId = e.target.getAttribute('id');
    const trackingNumbersDivId = e.target.nextSibling.getAttribute('id');

    if (showMoreDeliverAddressesButtonId === trackingNumbersDivId) {
      e.target.style.display = 'none';
      e.target.nextSibling.removeAttribute('style');
    }
  }

  renderPickupAddresses = (addresses) => {
    const googleLink = 'https://www.google.com/maps?q='
    if (addresses && addresses.length > 0) {
      return _.map(addresses, (address, i) => {
        return (
          <div key={i} className="list-group-item d-flex justify-content-center flex-column">
            <div className="h4 text-center mb-1">{`${address.pickup_address} (${address.pickup_address__count})`}</div>
            <button id={i} type="button" className="btn btn-secondary mb-1" onClick={(e) => window.open(googleLink + address.pickup_address)}>Open on Google Map</button>
            <button id={i} type="button" className="btn btn-success mb-2" onClick={(e) => this.handleShowMorePickupAddresses(e)}>Show More</button>
            <div id={i} style={{ display: 'none' }}>
              {this.renderTrackingNumbers(address.tracking_nos)}
            </div>
          </div>
        )
      });
    } else {
      return (
        <div className="text-center">No orders to pickup.</div>
      )
    }
  }

  renderDeliverAddresses = (addresses) => {
    const googleLink = 'https://www.google.com/maps?q='
    if (addresses && addresses.length > 0) {
      return _.map(addresses, (address, i) => {
        return (
          <div key={i} className="list-group-item d-flex justify-content-center flex-column">
            <div className="h4 text-center mb-2">{`${address.warehouse_address} (${address.warehouse_address__count})`}</div>
            <button id={i} type="button" className="btn btn-secondary mb-1" onClick={(e) => window.open(googleLink + address.warehouse_address)}>Open on Google Map</button>
            <button id={i} type="button" className="btn btn-success mb-2" onClick={(e) => this.handleShowMoreDeliverAddresses(e)}>Show More</button>
            <div id={i} style={{ display: 'none' }}>
              {this.renderTrackingNumbers(address.tracking_nos)}
            </div>
          </div>
        )
      });
    } else {
      return (
        <div className="text-center">No orders to deliver.</div>
      )
    }
  }

  renderTrackingNumbers = (trackingNos) => {
    return _.map(trackingNos, (trackingNo, i) => {
      return (
        <div key={i} className="text-center">
          {trackingNo.tracking_no}
        </div>
      )
    });
  }

  render() {
    return (
      <div className="main-container d-flex flex-column align-items-center">
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="input-group mb-0">
            <div className="input-group-prepend">
              <span className="input-group-text">Staff ID</span>
            </div>
            <input
              name="staffId"
              type="text"
              className="form-control text-center font-weight-bold"
              placeholder="Click Here First"
              onChange={(e) => this.handleOnChange(e)}
              value={this.state.staffId !== null ? this.state.staffId : ''}
            />
          </div>
        </div>

        {
          this.props.pickupDeliverAddresses ?
            <div>
              <div className="content-container card mt-1 mb-1 p-2">
                <div className="text-center font-weight-bold h3">Pick Ups</div>
                {this.renderPickupAddresses(this.props.pickupDeliverAddresses.orders_to_pickup)}
              </div>
              <div className="content-container card mt-1 mb-1 p-2">
                <div className="text-center font-weight-bold h3">Deliveries</div>
                {this.renderDeliverAddresses(this.props.pickupDeliverAddresses.orders_to_deliver)}
              </div>
            </div>
            :
            null
        }

      </div>
    );
  }
}

function mapStateToProps({ scan }) {
  return {
    pickupDeliverAddresses: scan.pickupDeliverAddresses
  };
}

export default connect(mapStateToProps, { fetchPickupDeliverAddresses })(PickupDeliverAddresses);
