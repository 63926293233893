import React, { useState, useEffect } from "react";
import { Divider, message } from "antd";
import { trolleyActions } from "../../../actions/rootActions";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import BreadCrumb from "../commonComponents/BreadCrumb";
import Input from "../../../common/input/input";

function AddLocation(props) {
  const { latestScannedNumber, orderData, addLocationLoading, addLocationSuccess, addLocationError, sessionId } = props;

  const scanType = props.location.state.scanType;
  const pageName = props.location.state.pageName;

  const [locationData, setLocationData] = useState({
    location: "",
    trackingNumberLocation: undefined,
    locationChangeName: undefined,
    locationChangeTime: undefined,
    addLocationError: undefined,
  });

  useEffect(() => {
    if (addLocationSuccess) {
      message.success(`Location added for ${latestScannedNumber}`);
      props.dispatch(trolleyActions.clearAddLocationData());
      goBackToPreviousRoute();
    }
  }, [addLocationSuccess]);

  useEffect(() => {
    if (addLocationError) {
      setLocationData({
        ...locationData,
        addLocationError: addLocationError,
      });
    }
  }, [addLocationError]);

  useEffect(() => {
    return () => {
      props.dispatch(trolleyActions.clearAddLocationData());
    };
  }, []);

  const inputClass = locationData.addLocationError ? "is-invalid" : "";

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container location-container mt-0">
          <BreadCrumb pageName={pageName} scanType={scanType} />
          <div className="bg-container-body">
            <div className="d-flex flex-column">
              <h2 className="text-heading-black margin-bottom-20">Add Location for {latestScannedNumber}</h2>
              <Input
                className={inputClass}
                label="Location"
                name="location"
                placeholder="Type here"
                value={locationData.location}
                onChange={(e) => onChangeLocationData(e)}
                autoFocus
                onKeyUp={(e) => onChangeLocationData(e)}
              />
              {locationData.addLocationError && (
                <span className="text-danger mt-2">{locationData.addLocationError}</span>
              )}
              <Divider className="gray-divider" />
              <div className="d-flex justify-content-between">
                <button className="secondary-button-small" onClick={goBackToPreviousRoute}>
                  Back
                </button>
                <button className="primary-button" onClick={addLocation} disabled={addLocationLoading}>
                  {addLocationLoading ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-white" />
                  ) : (
                    "Add Location"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  // Add Location input change
  function onChangeLocationData(e) {
    const locationValue = e.target.value.replace(/\s/g, "");
    const { keyCode, which } = e;
    if ((keyCode && keyCode === 13) || (which && which === 13)) {
      addLocation();
      return;
    }
    setLocationData({
      ...locationData,
      location: locationValue.toUpperCase(),
    });
  }

  function addLocation() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      session_id: sessionId,
      tracking_no: latestScannedNumber.trim(),
      tracking_numbers: orderData.tracking_nos,
      location: locationData.location,
    };
    props.dispatch(trolleyActions.onAddLocation(data));
  }
}

const mapStateToProps = ({ trolley, common, auth }) => ({
  latestScannedNumber: trolley.latestScannedNumber,
  orderData: trolley.orderData,
  addLocationLoading: trolley.addLocationLoading,
  addLocationSuccess: trolley.addLocationSuccess,
  addLocationError: trolley.addLocationError,
  sessionId: auth.sessionId,
});

export default connect(mapStateToProps, null)(AddLocation);
