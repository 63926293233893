import { commonServices } from "../services";
import { commonTypes } from "../types";
import * as _ from "lodash";

const setScannedNumbersList = (trackingNo, scannedNumbersList) => {
  const setScannedNumbersList = (payload) => ({ type: commonTypes.SET_SCANNED_NUMBERS_LIST, payload });

  return function (dispatch) {
    const numbersList = [...scannedNumbersList, trackingNo];
    dispatch(setScannedNumbersList(numbersList));
  };
};

const setScannerInformation = (data) => {
  const scannerInformation = (payload) => ({ type: commonTypes.SET_SCANNER_INFORMATION, payload });

  return function (dispatch) {
    dispatch(scannerInformation(data));
  };
};

const setPrinter = () => {
  const setDefaultPrinter = (payload) => ({ type: commonTypes.SET_PRINTER, payload });

  return function (dispatch) {
    const defaultPrinter = localStorage.getItem("defaultPrinter");
    dispatch(setDefaultPrinter(defaultPrinter));
  };
};

const setVoiceType = () => {
  const setVoice = (payload) => ({ type: commonTypes.SET_VOICE_TYPE, payload });

  return function (dispatch) {
    let voices = window.speechSynthesis.getVoices();
    let defaultAudioType = localStorage.getItem("defaultAudioType");
    if (defaultAudioType !== null) {
      const voiceUtilsData = {
        voice: voices[0],
        defaultAudioType: defaultAudioType,
      };
      dispatch(setVoice(voiceUtilsData));
    }
  };
};

const getExceptionsTypes = () => {
  const loading = () => ({ type: commonTypes.GET_EXCEPTIONS_TYPES_LOADING });
  const success = (payload) => ({ type: commonTypes.GET_EXCEPTIONS_TYPES_SUCCESS, payload });
  const failure = (payload) => ({ type: commonTypes.GET_EXCEPTIONS_TYPES_FAILURE, payload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const params = {
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
        scan_type: "mark exceptions",
      };
      const exceptionsTypes = await commonServices.getExceptionsTypes(params);
      let exceptionsTypesdata = _.orderBy(exceptionsTypes.exceptions, "description", "asc");
      exceptionsTypesdata = exceptionsTypesdata.filter(e => e.name !== "OTH").map((exception) => { // exclude "OTH - Others"
        exception.value = exception.name;
        exception.label = exception.name + " - " + exception.description.toUpperCase();
        return exception;
      });

      dispatch(success(exceptionsTypesdata));
    } catch (err) {
      const errorMessage = err.data;
      dispatch(failure(errorMessage));
    }
  };
};

const getRecipientsList = () => {
  const loading = () => ({ type: commonTypes.GET_RECIPIENTS_LIST_LOADING });
  const success = (payload) => ({ type: commonTypes.GET_RECIPIENTS_LIST_SUCCESS, payload });
  const failure = (payload) => ({ type: commonTypes.GET_RECIPIENTS_LIST_FAILURE, payload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const recipientsResult = await commonServices.getRecipientsList();

      let list = recipientsResult;
      list = list.map((recipient) => {
        recipient.label = recipient.name;
        recipient.value = recipient.name;
        return recipient;
      });

      dispatch(success(list));
    } catch (err) {
      const errorMessage = err.data;
      dispatch(failure(errorMessage));
    }
  };
};

const getShipmentNumbersList = () => {
  const loading = () => ({ type: commonTypes.GET_SHIPMENT_NUMBERS_LIST_LOADING });
  const success = (payload) => ({ type: commonTypes.GET_SHIPMENT_NUMBERS_LIST_SUCCESS, payload });
  const failure = (payload) => ({ type: commonTypes.GET_SHIPMENT_NUMBERS_LIST_FAILURE, payload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const data = {
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
      };
      const pendingShipmentsData = { ...data, status: "pending" };
      const inProgressShipmentsData = { ...data, status: "ob_in_progress" };

      const pendingShipmentsResult = await commonServices.getShipmentNumbersList(pendingShipmentsData);
      const obInProgressShipmentsResult = await commonServices.getShipmentNumbersList(inProgressShipmentsData);

      const pendingShipments = pendingShipmentsResult.shipments;
      const obInProgressShipments = obInProgressShipmentsResult.shipments;
      let shipments = [...pendingShipments, ...obInProgressShipments];
      shipments = shipments.slice(0, 15);
      shipments = shipments.map((shipment) => {
        shipment.value = shipment.tracking_no.trim();
        shipment.label = shipment.recipient_name + "-" + shipment.tracking_no.trim();
        return shipment;
      });

      dispatch(success(shipments));
    } catch (err) {
      const errorMessage = err.data;
      dispatch(failure(errorMessage));
    }
  };
};

const clearScannedNumbersList = () => {
  const clearScannedNumbers = () => ({ type: commonTypes.CLEAR_SCANNED_NUMBERS_LIST_DATA });

  return async function (dispatch) {
    dispatch(clearScannedNumbers());
  };
};

export const commonActions = {
  setScannedNumbersList,
  setScannerInformation,
  getExceptionsTypes,
  getRecipientsList,
  getShipmentNumbersList,
  setVoiceType,
  setPrinter,
  clearScannedNumbersList,
};
