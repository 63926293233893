/* eslint-disable react-hooks/rules-of-hooks,react-hooks/exhaustive-deps */
import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Form, message } from "antd";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { cargoActions, shipmentActions } from "../../../actions/rootActions";
import Input from "../../../common/input/input";
import Select from "../../../common/select/select";
import {
  CARGO_TYPE,
  CREATE_CARGO,
  DEST_COUNTRY_CODE,
  DEST_POSTCODE_PREFIX,
  IS_DEMINIMIS,
  LM_RECIPIENT_ID,
  SHIPMENT_TRACKING_NUMBER,
} from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";

function CreateCargo(props) {
  const { countriesList, partnersForHub, createCargoLoading, newCargoTrackingData, createCargoErr } = props;

  const shipment = props.location.state.shipment;

  const [createCargoData, setCreateCargoData] = useState({
    cargo_type: undefined,
    dest_country_code: undefined,
    dest_poscode_prefix: "",
    is_deminimis: false,
    lm_recipient_id: undefined,
    security_tag_no: undefined,
  });

  const [countriesListOptions, setCountriesListOptions] = useState([]);
  const [lmRecipientsOptions, setLmRecipientsOptions] = useState([]);

  const cargoTypes = [
    { label: "Bag", value: "bag" },
    { label: "Carton", value: "carton" },
    { label: "Pallet", value: "pallet" },
    { label: "Other", value: "other" },
  ];

  const [form] = Form.useForm();

  useEffect(() => {
    if (!_.isEqual(countriesListOptions, countriesList)) {
      const optionsList = countriesList.map((country) => {
        country.label = country.name;
        country.value = country.code;
        return country;
      });
      setCountriesListOptions(optionsList);
    }
  }, [countriesList]);

  useEffect(() => {
    if (!_.isEqual(lmRecipientsOptions, partnersForHub)) {
      const optionsList = partnersForHub.map((recipient) => {
        recipient.label = recipient.name;
        recipient.value = recipient.id;
        return recipient;
      });
      setLmRecipientsOptions(optionsList);
    }
  }, [partnersForHub]);

  useEffect(() => {
    props.dispatch(cargoActions.getCountriesList());
  }, []);

  useEffect(() => {
    if (newCargoTrackingData) {
      message.success("Cargo Created");
      props.dispatch(cargoActions.clearCreateCargoResponse());
      goBackToPreviousScreen();
    }
  }, [newCargoTrackingData]);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container w-100 mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <LinkContainer to="/scan/hubmanage/shipments">
              <p className="link-title mb-0">Shipments</p>
            </LinkContainer>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="link-title text-capitalize mb-0" onClick={goBackToPreviousScreen}>
              {shipment.tracking_no}
            </p>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">Create Cargo</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black">Create Cargo</h2>
            <p className="text-span-black margin-top-20 mb-0">
              This cargo will be linked to shipment {shipment.tracking_no} once created.
            </p>
            <Form form={form} onFinish={onSubmit} initialValues={createCargoData}>
              <div className="row">
                <div className="col-sm-4 col-12">
                  <Form.Item
                    name="cargo_type"
                    className="margin-top-30 mb-0"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please select a type first",
                      },
                    ]}
                    help={getError("cargo_type")}
                  >
                    <Select
                      label="Cargo Type"
                      placeholder="Select cargo type"
                      value={createCargoData.cargo_type}
                      options={cargoTypes}
                      onChange={(value) => onChangeCargoData("cargo_type", value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-4 col-12">
                  <Form.Item
                    name="dest_country_code"
                    className="margin-top-30 mb-0"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please select a country first",
                      },
                    ]}
                    help={getError("dest_country_code")}
                  >
                    <Select
                      label="Destination Country"
                      placeholder="Select destination country"
                      value={createCargoData.dest_country_code}
                      options={countriesListOptions}
                      onChange={(value) => onChangeCargoData("dest_country_code", value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-4 col-12">
                  <Form.Item
                    name="dest_poscode_prefix"
                    className="margin-top-30 mb-0"
                    help={getError("dest_poscode_prefix")}
                  >
                    <Input
                      className={getErrorClass("dest_poscode_prefix")}
                      label="Destination Postal Code (Prefix)"
                      placeholder="Type here"
                      value={createCargoData.dest_poscode_prefix}
                      onChange={(e) => onChangeCargoData("dest_poscode_prefix", e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-4 col-12">
                  <Form.Item name="security-tag" className="margin-top-30 mb-0" help={getError("security_tag")}>
                    <Input
                      label="Security Tag (Optional)"
                      placeholder="Type here"
                      className={getErrorClass("security_tag")}
                      id="security-tag"
                      onChange={(e) => onChangeCargoData("security_tag_no", e.target.value)}
                      value={createCargoData.security_tag}
                    />
                  </Form.Item>
                </div>
                {partnersForHub && partnersForHub.length > 0 && (
                  <div className="col-sm-4 col-12">
                    <Form.Item name="lm_recipient_id" className="margin-top-30 mb-0" help={getError("lm_recipient_id")}>
                      <Select
                        label="Destination Hub LM Network Partner"
                        placeholder="Select LM Network Partner"
                        value={createCargoData.lm_recipient_id}
                        options={lmRecipientsOptions}
                        onChange={(value) => onChangeCargoData("lm_recipient_id", value)}
                      />
                    </Form.Item>
                  </div>
                )}
                <div className="col-12">
                  <Form.Item name="is_deminimis" className="margin-top-20 mb-0">
                    <Checkbox
                      checked={createCargoData.is_deminimis}
                      onChange={(e) => onChangeCargoData("is_deminimis", e.target.checked)}
                    >
                      <span className="text-span-black">Deminimis (High Value) Parcels</span>
                    </Checkbox>
                  </Form.Item>
                </div>
                {createCargoErr && (
                  <div className="col-12">
                    {createCargoErr.map((err) => (
                      <div className="text-danger">{err}</div>
                    ))}
                  </div>
                )}
                <div className="col-12">
                  <Divider className="gray-divider" />
                </div>
              </div>
              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button className="secondary-button-lg-xs" onClick={goBackToPreviousScreen}>
                  Cancel
                </button>
                <button type="submit" className="primary-button mb-sm-0 mb-3" disabled={createCargoLoading}>
                  {createCargoLoading ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-white" />
                  ) : (
                    "Create Cargo"
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  function onChangeCargoData(name, value) {
    setCreateCargoData({
      ...createCargoData,
      [name]: value,
    });
    if (name === "dest_country_code") {
      props.dispatch(shipmentActions.fetchRecipientsForHub(value));
    }
  }

  function getError(name) {
    return getAddError(name);
  }

  function getAddError(name) {
    if (createCargoErr && createCargoErr[name] && createCargoErr[name].length > 0) {
      return createCargoErr[name][0];
    }
  }

  function getErrorClass(name) {
    if (createCargoErr && createCargoErr[name] && createCargoErr[name].length > 0) {
      return "input-error";
    }
    return "";
  }

  async function onSubmit() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipment.tracking_no,
      cargo_type: createCargoData.cargo_type,
      dest_country_code: createCargoData.dest_country_code,
      dest_poscode_prefix: createCargoData.dest_poscode_prefix,
      is_deminimis: createCargoData.is_deminimis,
      lm_recipient_id: createCargoData.lm_recipient_id,
      security_tag_no: createCargoData.security_tag_no,
    };
    onCreateCargo(data);
  }

  function onCreateCargo(data) {
    // mixpanel tracking cargo create event
    const mixPanelData = getMixpanelData();
    mixPanelTrack(CREATE_CARGO, {
      ...mixPanelData,
      [SHIPMENT_TRACKING_NUMBER]: data.shipment_no.trim(),
      [CARGO_TYPE]: data.cargo_type,
      [DEST_COUNTRY_CODE]: data.dest_country_code,
      [DEST_POSTCODE_PREFIX]: data.dest_poscode_prefix,
      [IS_DEMINIMIS]: data.is_deminimis,
      [LM_RECIPIENT_ID]: data.lm_recipient_id,
    });
    props.dispatch(cargoActions.createCargo(data));
  }
}

const mapStateToProps = (state) => ({
  countriesList: state.cargo.countriesList,
  partnersForHub: state.shipment.partnersForHub,
  createCargoErr: state.cargo.createCargoErr,
  newCargoTrackingData: state.cargo.newCargoTrackingData,
  createCargoLoading: state.cargo.createCargoLoading,
});

export default connect(mapStateToProps)(CreateCargo);
