import { isEmpty } from "lodash";
import React from "react";
import CargoCheckActionItemsList from "../cargos/actionItemsList";
import CargoDetails from "../cargos/cargoDetails";
import CargoActionItemsList from "./CargoActionItemsList";
import CargoNotificationArea from "./CargoNotificationArea";

export function mainContent(
  latestScannedNumber,
  scanType,
  notificationBoxType,
  locationScanType,
  scanSettings,
  scannedNumbersList,
  cargoDetails,
  shipment,
  redirectToShipmentDetailsPage,
  redirectToCargoHomePage
) {
  const showActionItems = latestScannedNumber !== null;
  let showCargoArrivalBreakActionItems = false;
  if (scanType === "cargo break" || scanType === "cargo arrival") {
    showCargoArrivalBreakActionItems = true;
  }

  const settingsClass = latestScannedNumber === null || notificationBoxType.showErrorDataBox ? "" : "margin-top-20";

  console.log(locationScanType);

  return (
    <React.Fragment>
      <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
        <p className="link-title mb-0" onClick={redirectToCargoHomePage}>
          Cargo Scan
        </p>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="title text-capitalize mb-0">
          {locationScanType === "warehouse inbound"
            ? "wh processing"
            : locationScanType === "warehouse outbound"
            ? "wh processed"
            : locationScanType}
        </p>
      </div>
      <div className="row">
        <div className="flex-column col-md-6 col-sm-12">
          <CargoNotificationArea />
          {scanType === "sweep" && !isEmpty(cargoDetails) && (
            <div className="margin-top-20">
              <CargoDetails
                cargo={cargoDetails}
                onClickRedirectToShipmentDetails={() => redirectToShipmentDetailsPage()}
              />
            </div>
          )}
        </div>
        <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">
          {scanType === "sweep" && !isEmpty(cargoDetails) && (
            <CargoCheckActionItemsList scanType={scanType} cargo={cargoDetails} shipment={shipment} />
          )}
          {scanType !== "sweep" && (
            <CargoActionItemsList
              showActionItems={showActionItems}
              settingsClass={settingsClass}
              scannedNumbersList={scannedNumbersList}
              scanSettings={scanSettings}
              errorOnScanning={notificationBoxType.showErrorDataBox}
              scanType={locationScanType}
              showCargoArrivalBreakActionItems={showCargoArrivalBreakActionItems}
              cargoDetails={cargoDetails}
              pageName="Cargo Scan"
              route="/scan/hubmanage/cargo-scan"
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
