import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Divider, message } from "antd";
import { cargoActions } from "../../../actions/rootActions";
import { LinkContainer } from "react-router-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";

function BulkRemoveFromCargo(props) {
  const { trackingNumberData, bulkRemoveFromCargoLoading, bulkRemoveFromCargoSuccess, bulkRemoveFromCargoError } =
    props;

  const cargo = props.location.state.cargo;

  useEffect(() => {
    if (bulkRemoveFromCargoSuccess) {
      message.success("Package(s) removed successfully");
      goBackToPreviousScreen();
    }
  }, [bulkRemoveFromCargoSuccess]);

  useEffect(() => {
    if (bulkRemoveFromCargoError) {
      message.error("Package(s) remove failed");
      goBackToPreviousScreen();
    }
  }, [bulkRemoveFromCargoError]);

  useEffect(() => {
    return () => {
      props.dispatch(cargoActions.clearBulkRemoveFromCargoData());
    };
  }, []);

  const numberOfPackages = trackingNumberData ? trackingNumberData.total_records : 0;

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <LinkContainer to="/scan/hubmanage/cargo-scan">
              <p className="link-title mb-0">Cargo Scan</p>
            </LinkContainer>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">Bulk Remove Packages From Cargo</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black">Please confirm bulk removal from cargo</h2>
            <p className="text-span-black margin-top-20 mb-0">
              Are you sure you want to remove {numberOfPackages ? numberOfPackages : 0} package(s) in cargo{" "}
              {cargo.tracking_no}?
            </p>
            <Divider className="gray-divider" />
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button-small" onClick={goBackToPreviousScreen}>
                Back
              </button>
              <button
                type="submit"
                className="primary-button-small mb-sm-0 mb-3"
                disabled={bulkRemoveFromCargoLoading}
                onClick={handleBulkRemovalFromCargo}
              >
                {bulkRemoveFromCargoLoading ? (
                  <LoadingOutlined className="loading-spinner loading-spinner-white" />
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  function handleBulkRemovalFromCargo() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargo.tracking_no.trim(),
    };
    props.dispatch(cargoActions.bulkRemoveFromCargo(data));
  }
}

const mapStateToProps = (state) => ({
  trackingNumberData: state.cargo.trackingNumbersInSideCargoData,
  bulkRemoveFromCargoLoading: state.cargo.bulkRemoveFromCargoLoading,
  bulkRemoveFromCargoSuccess: state.cargo.bulkRemoveFromCargoSuccess,
  bulkRemoveFromCargoError: state.cargo.bulkRemoveFromCargoError,
});

export default connect(mapStateToProps)(BulkRemoveFromCargo);
