// @ts-nocheck
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { cargoActions } from "../../../actions/cargoActions";
import Select from "../../../common/select/select";
import { SUCCESS, VISIT_CARGO_SCAN_PAGE } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { commonActions } from "../../../actions/commonActions";

function HmsCargoScannerPage(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [state, setState] = useState({
    scanType: undefined,
    redirectToScanner: false,
  });

  useEffect(() => {
    if (localStorage.getItem("hubId") === null && localStorage.getItem("userId") === null) {
      setIsLoggedIn(false);
    } else {
      // mixpanel tracking page visit
      const mixpanelData = getMixpanelData();
      mixPanelTrack(VISIT_CARGO_SCAN_PAGE, {
        [SUCCESS]: true,
        ...mixpanelData,
      });
    }
  }, []);

  if (!isLoggedIn) {
    return <Redirect push to="/scan/hubmanage/login" />;
  }

  const scanTypes = getScanTypes();

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="bg-container">
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="title mb-0">Cargo Scan</p>
        </div>
        <div className="bg-container-body">
          <h2 className="text-heading-black">Scan Type</h2>
          <div className="margin-top-30">
            <Select
              label="Choose a Scan Type"
              placeholder="Please select scan type"
              options={scanTypes}
              value={state.scanType}
              onChange={(value) => onScanTypeChange("scanType", value)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  function getScanTypes() {
    let scanTypes = localStorage.getItem("scanTypes");
    scanTypes = JSON.parse(scanTypes);
    scanTypes = scanTypes.map((scannedType) => {
      let label = scannedType.name.toUpperCase();
      if (scannedType.name === "warehouse inbound") {
        label = "WH PROCESSING";
      } else if (scannedType.name === "warehouse outbound") {
        label = "WH PROCESSED";
      }
      let type = {
        value: scannedType.name,
        label,
      };
      return type;
    });
    scanTypes = _.filter(scanTypes, (x) => x.value !== "sort by");
    return scanTypes.filter(t => !t.value.includes("handover"));
  }

  function onScanTypeChange(name, value) {
    clearCargoInfo();
    //localStorage.removeItem("sessionId");
    setState({
      [name]: value,
    });
    props.history.push({
      pathname: `/scan/hubmanage/cargo-scan/${value}`,
      state: { scanType: value },
    });
  }

  function clearCargoInfo() {
    props.clearCargoInfo();
    props.clearScannedNumbersList();
    props.resetTrackingNumberInsideCargoData();
    props.clearCargoExceptions();
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearCargoInfo: () => dispatch(cargoActions.clearCargoInfo()),
  clearScannedNumbersList: () => dispatch(commonActions.clearScannedNumbersList()),
  resetTrackingNumberInsideCargoData: () => dispatch(cargoActions.resetTrackingNumberInsideCargoData()),
  clearCargoExceptions: () => dispatch(cargoActions.clearCargoExceptions()),
});

export default connect(null, mapDispatchToProps)(HmsCargoScannerPage);
