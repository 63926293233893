import { useSelector } from "react-redux";

export function createRequestPayload(valuesToAdd = {}, keysToDelete = []) {
  const requestPayload = {
    hub_id: localStorage.getItem("hubId"),
    hubscanner_key: localStorage.getItem("userId"),
    ...valuesToAdd,
  };
  keysToDelete.forEach((key) => {
    delete requestPayload[key];
  });
  return requestPayload;
}
