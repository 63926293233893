import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parcelActions } from "../../../actions/rootActions";
import { Divider, Modal, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import * as _ from "lodash";
import { ParcelScannerContext } from "./ParcelScanner";

export default function RemedyExceptions({ fetchOrderInfo, scanType }) {
  const {
    parcel: {
      remedyData: { automaticallyRemedy, remedyReason },
      resolveExceptionLoading,
      resolveExceptionSuccess,
      resolveExceptionError,
      automaticallyResolveExceptions,
      fetchParcelSuccess,
      parcelOrderData,
      latestScannedNumber,
    },
    auth: { sessionId },
    common: { scannerInformation },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  let scannedNumber = latestScannedNumber;
  if (typeof latestScannedNumber === "object" && latestScannedNumber !== null) {
    scannedNumber = latestScannedNumber.tracking_no;
  }

  const [showRemedyModal, setShowRemedyModal] = useState(false);
  const [showRemedyAllModal, setShowRemedyAllModal] = useState(false);
  const [remedyReasonValue, setRemedyReason] = useState(remedyReason);
  const [automaticRemedy, setAutomaticRemedy] = useState(automaticallyRemedy);

  useEffect(() => {
    if (resolveExceptionSuccess) {
      setShowRemedyModal(false);
      setShowRemedyAllModal(false);
      dispatch(parcelActions.clearResolveExceptionSuccessData());
      fetchOrderInfo(scannedNumber, true);
      message.success(`Exception(s) Remedied`);
    }
  }, [resolveExceptionSuccess]);

  useEffect(() => {
    if (resolveExceptionError) {
      setShowRemedyModal(false);
      setShowRemedyAllModal(false);
    }
  }, [resolveExceptionError]);

  useEffect(() => {
    if (automaticallyResolveExceptions && fetchParcelSuccess) {
      resolveException(scanType);
    }
  }, [fetchParcelSuccess, latestScannedNumber]);

  return (
    <ParcelScannerContext.Consumer>
      {(value) => {
        const remedyNoExceptionContainerClass =
          scannedNumber && value.scanType === "sweep" && parcelOrderData?.exceptions?.length === 0 ? "" : "d-none";
        const remedyExceptionsDataClass = parcelOrderData?.exceptions?.length > 0 ? "" : "d-none";
        const showExceptions = scannedNumber && value.scanType === "sweep" && parcelOrderData?.exceptions?.length > 0;
        const showExceptionContainerClass = showExceptions ? "" : "d-none";
        const remedyReasonClass = resolveExceptionError ? "is-invalid" : "";

        return (
          <>
            <div className={showExceptionContainerClass}>
              <div className={"remedy-exception-container " + remedyNoExceptionContainerClass}>
                <div className="d-flex flex-nowrap align-items-center">
                  <span className="toggle-heading mr-auto">Sweep Exception for {scannedNumber}</span>
                </div>
              </div>
              {showExceptions && (
                <div className="remedy-exception-container">
                  <div className="d-flex flex-nowrap align-items-center">
                    {!resolveExceptionSuccess && (
                      <span className="toggle-heading mr-auto">Remedy All Exception(s)</span>
                    )}
                    {resolveExceptionSuccess && (
                      <span className="toggle-heading-success mr-auto">Exception(s) Remedied</span>
                    )}
                  </div>
                  <div className="remedy-exception-content">
                    <div className="d-flex flex-column">
                      <div className={"row " + remedyExceptionsDataClass}>
                        {resolveExceptionSuccess && (
                          <div className="col-12">
                            <label className="remedy-exception-label">Reason to Remedy Exception</label>
                            <p className="mb-0 remedy-exception-text">{remedyReasonValue}</p>
                          </div>
                        )}
                        {!resolveExceptionSuccess && (
                          <React.Fragment>
                            <div className="w-100">
                              <div className="col-12">
                                <label className="remedy-exception-label">Reason to Remedy Exception</label>
                                <input
                                  className={"form-control scan-input " + remedyReasonClass}
                                  placeholder="Type here"
                                  value={remedyReasonValue}
                                  onChange={onChangeRemedyExceptionReason}
                                />
                                {resolveExceptionError?.orders?.resolutions?.actions && (
                                  <small class="form-text error-help-text">
                                    {resolveExceptionError.orders.resolutions.action[0]}
                                  </small>
                                )}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                        <div className="w-100">
                          {resolveExceptionSuccess && (
                            <div className="col-12">
                              <Divider className="gray-divider" />
                            </div>
                          )}
                          <div className="col-12 margin-top-30">
                            <div class="form-check remedy-all-exception-checkbox-container">
                              <input
                                type="checkbox"
                                id="automatically-checkbox"
                                class="form-check-input remedy-all-exception-checkbox"
                                checked={automaticRemedy}
                                onChange={onAutomaticallyRemedyChange}
                              />
                              <label class="form-check-label standard-font-lite" for="automatically-checkbox">
                                Remedy exceptions (with the same reason) automatically to newly scanned packages
                              </label>
                            </div>
                          </div>
                        </div>
                        {!resolveExceptionSuccess && (
                          <React.Fragment>
                            <div className="w-100">
                              <div className="col-12">
                                <Divider className="gray-divider" />
                              </div>
                              <div className="col-12">
                                <button className="remedy-exception-button mt-0" onClick={handleRemedyExceptionClick}>
                                  Remedy Exceptions
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <Modal
              className="remedy-modal"
              visible={showRemedyModal || showRemedyAllModal}
              footer={null}
              okText="Confirm"
              okButtonProps={{ className: "remedy-modal-primary-button" }}
              cancelButtonProps={{ className: "remedy-modal-secondary-button" }}
              cancelText="Cancel"
            >
              <div className="text-left">
                <h2 className="remedy-heading mb-0">
                  {showRemedyModal && "Please confirm remedy exception(s)"}
                  {showRemedyAllModal && "Please confirm to automatically remedy exception(s)"}
                </h2>
                <p className="remedy-paragraph mb-0">
                  {showRemedyModal && "After you have remedied this exception(s), it cannot be undone."}
                  {showRemedyAllModal && "After you have remedied an exception, it cannot be undone."}
                </p>
                <Divider className="gray-divider" />
              </div>
              <div className="text-center">
                <button className="remedy-modal-secondary-button" onClick={handleRemedyExceptionClick}>
                  Cancel
                </button>
                <button
                  className="remedy-modal-primary-button"
                  disabled={resolveExceptionLoading}
                  onClick={() => resolveException(value.scanType)}
                >
                  {resolveExceptionLoading ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-white" />
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </Modal>
          </>
        );
      }}
    </ParcelScannerContext.Consumer>
  );

  function onChangeRemedyExceptionReason(e) {
    setRemedyReason(e.target.value);
  }

  function onAutomaticallyRemedyChange(e) {
    setAutomaticRemedy(!automaticRemedy);
    if (!e.target.checked) {
      dispatch(parcelActions.clearResolveExceptionData());
    }
  }

  function handleRemedyExceptionClick() {
    if (automaticRemedy) {
      setShowRemedyAllModal(!showRemedyAllModal);
    } else {
      setShowRemedyModal(!showRemedyModal);
    }
  }

  function resolveException(scanType) {
    console.log("resolved", scanType);
    if (parcelOrderData?.exceptions?.length > 0) {
      const data = {
        hub_id: scannerInformation.hubId,
        hubscanner_key: scannerInformation.userId,
        scan_type: scanType,
        session_id: sessionId,
        orders: [
          {
            tracking_no: scannedNumber.trim(),
            resolutions: getRemedyResolution(),
          },
        ],
      };

      const saveData = {
        remedyReason: remedyReasonValue,
        automaticallyRemedy: automaticRemedy,
      };

      dispatch(parcelActions.resolveException(data, saveData));
    }
  }

  function getRemedyResolution() {
    const resolutions =
      parcelOrderData?.exceptions?.length > 0 &&
      parcelOrderData.exceptions.map((exception) => {
        let resObj = {
          exception_id: exception.id,
          action: remedyReasonValue,
        };
        return resObj;
      });
    return resolutions;
  }
}
