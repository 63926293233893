import React from "react";
import { Link } from "react-router-dom";
import InsideThisShipment from "../../../images/shipment-icons/inside-this-shipment.png";
import CreateCargo from "../../../images/shipment-icons/create-cargo-in-this-shipment.png";
import EditShipment from "../../../images/shipment-icons/edit-shipment.png";
import AddCargo from "../../../images/shipment-icons/add-parcel-cargo-to-shipment.png";
import RemoveCargo from "../../../images/shipment-icons/remove-parcel-cargo-from-shipment.png";
import CancelShipment from "../../../images/shipment-icons/cancel-shipment.png";
import TakePhoto from "../../../images/cargo-icons/take-photo.png";
import SeePhoto from "../../../images/cargo-icons/see-photo.png";
import BulkRemoveCargo from "../../../images/cargo-icons/bulk-remove-cargo.png";
import ActionItem from "../commonComponents/actionItem";

export default function ActionItemsList(props) {
  const { scanType, cargo, shipment } = props;

  return (
    <div className="d-flex flex-column">
      <div className="action-item-box-shadow">
        <ActionItem to={getInsideThisCargoHref()} imageSrc={InsideThisShipment} itemText="Inside This Cargo" />
        <ActionItem
          to={getCargoIsPartOfHref()}
          imageSrc={CreateCargo}
          itemText="Cargo is Part of"
          classNames="action-item-top-border"
        />
      </div>
      <div className="action-item-box-shadow">
        <ActionItem
          to={editCargoHref()}
          imageSrc={EditShipment}
          itemText="Edit Cargo"
          classNames="action-item-margin"
        />
        {scanType && scanType === "sweep" && (
          <ActionItem
            to={getCancelCargoHref()}
            imageSrc={CancelShipment}
            itemText="Cancel Cargo"
            classNames="action-item-top-border"
          />
        )}
      </div>
      {scanType && scanType === "sweep" && (
        <>
          <div className="action-item-box-shadow">
            <ActionItem
              to={getTakePhotosHref()}
              imageSrc={TakePhoto}
              itemText="Take Photo"
              classNames="action-item-margin"
            />
            <ActionItem
              to={getSeePhotosHref()}
              imageSrc={SeePhoto}
              itemText="See Photo(s)"
              classNames="action-item-top-border"
            />
          </div>
          <div className="action-item-box-shadow">
            <ActionItem
              to={getRemoveBulkFromCargoHref()}
              imageSrc={BulkRemoveCargo}
              itemText="Bulk Remove Packages From Cargo"
              classNames="action-item-margin"
            />
          </div>
        </>
      )}
      <div className="action-item-box-shadow">
        <ActionItem
          to={getAddCargoToCargoHref()}
          imageSrc={AddCargo}
          itemText="Add Parcel/Cargo To This Cargo"
          classNames="action-item-margin"
        />
        <ActionItem
          to={getRemoveCargoFromCargoHref()}
          imageSrc={RemoveCargo}
          itemText="Remove Parcel/Cargo From Cargo"
          classNames="action-item-top-border"
        />
      </div>
    </div>
  );

  function editCargoHref() {
    return {
      pathname: "/scan/hubmanage/edit-cargo",
      state: { cargo: cargo, scanType: scanType },
    };
  }

  function getInsideThisCargoHref() {
    return {
      pathname: "/scan/hubmanage/inside-cargo",
      state: { cargo: cargo },
    };
  }

  function getCargoIsPartOfHref() {
    return {
      pathname: "/scan/hubmanage/cargo-part-of",
      state: { cargo: cargo, shipment: shipment },
    };
  }

  function getAddCargoToCargoHref() {
    return {
      pathname: "/scan/hubmanage/add-cargo-to-cargo",
      state: { cargo: cargo },
    };
  }

  function getRemoveCargoFromCargoHref() {
    return {
      pathname: "/scan/hubmanage/remove-cargo-from-cargo",
      state: { cargo: cargo },
    };
  }

  function getCancelCargoHref() {
    return {
      pathname: "/scan/hubmanage/cancel-cargo",
      state: { cargo: cargo },
    };
  }

  function getRemoveBulkFromCargoHref() {
    return {
      pathname: "/scan/hubmanage/bulk-remove-from-cargo",
      state: { cargo: cargo },
    };
  }

  function getSeePhotosHref() {
    return {
      pathname: "/scan/hubmanage/cargo-photos",
      state: { cargo: cargo },
    };
  }

  function getTakePhotosHref() {
    return {
      pathname: "/scan/hubmanage/take-cargo-photos",
      state: { cargo: cargo, scanType: scanType },
    };
  }
}
