// @ts-nocheck
import React, { useState, useEffect } from "react";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { LOCATION_DATA, PARCEL_TRACKING_NUMBER, TAG_LOCATION_PARCEL } from "../../../constants/mixpanelConstants";
import { Divider, Checkbox, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { parcelActions } from "../../../actions/rootActions";
import { connect } from "react-redux";
import BreadCrumb from "../commonComponents/BreadCrumb";
import Input from "../../../common/input/input";
import * as _ from "lodash";

function AddLocation(props) {
  const { addLocationLoading, addLocationSuccess, addLocationError, latestScannedNumber, sessionId } = props;

  const addedLocation = getAddedLocation();

  const [locationData, setLocationData] = useState({
    location: addedLocation,
    trackingNumberLocation: undefined,
    locationChangeName: undefined,
    locationChangeTime: undefined,
    addLocationError: undefined,
    autoAddLocation: false,
  });

  useEffect(() => {
    if (addLocationSuccess) {
      message.success(`Location added for ${latestScannedNumber}`);
      const mixpanelData = getMixpanelData();
      mixPanelTrack(TAG_LOCATION_PARCEL, {
        ...mixpanelData,
        [PARCEL_TRACKING_NUMBER]: latestScannedNumber,
        [LOCATION_DATA]: locationData,
      });
      goBackToPreviousRoute();
    }
  }, [addLocationSuccess]);

  useEffect(() => {
    if (addLocationError) {
      setLocationData({
        ...locationData,
        addLocationError: addLocationError,
      });
    }
  }, [addLocationError]);

  useEffect(() => {
    return () => {
      props.dispatch(parcelActions.clearAddLocationData());
    };
  }, []);

  const scanType = props.location.state.scanType;
  const pageName = props.location.state.pageName;
  const route = props.location.state.route;

  const inputClass = locationData.addLocationError ? "is-invalid" : "";

  return (
    <div className="parcel-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container location-container mt-0 w-100">
          <BreadCrumb pageName={pageName} scanType={scanType} route={route} />
          <div className="bg-container-body">
            <div className="d-flex flex-column">
              <h2 className="text-heading-black margin-bottom-20">Add Location for {latestScannedNumber}</h2>
              <div className="d-flex location-input-checkbox-group">
                <div className="inputbox-w-40 inputbox-mr-4 w-100">
                  <Input
                    className={inputClass}
                    label="Location"
                    name="location"
                    placeholder="Type here"
                    autoFocus
                    value={locationData.location}
                    onChange={(e) => {
                      return onChangeLocationData(e);
                    }}
                    onKeyUp={(e) => {
                      return onChangeLocationData(e);
                    }}
                  />
                  {locationData.addLocationError && (
                    <span className="text-danger">{locationData.addLocationError}</span>
                  )}
                </div>
                <div className="checkbox-w-60 w-100">
                  <Checkbox
                    className="mt-3"
                    checked={locationData.autoAddLocation}
                    onChange={(e) => onClickAutoAddLocationCheck("autoAddLocation", e.target.checked)}
                  >
                    <span className="text-span-black">Add this location automatically to newly scanned packages</span>
                  </Checkbox>
                </div>
              </div>
              <Divider className="gray-divider" />
              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button className="secondary-button-small" onClick={goBackToPreviousRoute}>
                  Back
                </button>
                <button className="primary-button mb-sm-0 mb-3" disabled={addLocationLoading} onClick={addLocation}>
                  {addLocationLoading ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-white" />
                  ) : (
                    "Add Location"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function getAddedLocation() {
    if (!_.isEmpty(props.locationData)) {
      return props.locationData.location;
    }
    return "";
  }

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  // Add Location input change
  function onChangeLocationData(e) {
    const locationValue = e.target.value.replace(/\s/g, "");
    const { keyCode, which } = e;
    if ((keyCode && keyCode === 13) || (which && which === 13)) {
      addLocation();
      return;
    }
    setLocationData({
      ...locationData,
      location: locationValue.toUpperCase(),
    });
  }

  function onClickAutoAddLocationCheck(name, value) {
    setLocationData({
      ...locationData,
      [name]: value,
    });
  }

  function addLocation() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      session_id: sessionId,
      tracking_no: latestScannedNumber.trim(),
      tracking_numbers: [latestScannedNumber.trim()],
      location: locationData.location,
      autoAddLocation: locationData.autoAddLocation,
    };
    props.dispatch(parcelActions.onAddLocation(data));
  }
}

const mapStateToProps = ({ parcel, auth }) => ({
  addLocationLoading: parcel.addLocationLoading,
  addLocationError: parcel.addLocationError,
  addLocationSuccess: parcel.addLocationSuccess,
  latestScannedNumber: parcel.latestScannedNumber,
  locationData: parcel.locationData,
  sessionId: auth.sessionId,
});

export default connect(mapStateToProps, null)(AddLocation);
