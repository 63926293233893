import React from "react";

export default function SecurityTagDetails({ securityTagInfo }) {
  const pastTags = [];
  if (securityTagInfo?.history?.length > 0) {
    securityTagInfo.history.forEach((tag) => {
      pastTags.push(<div>{tag.security_tag_no}</div>);
    });
  }
  return (
    <div className="row margin-top-20">
      <div className="col-12 security-tag-padding-0">
        <div className="security-tag-box details-box">
          <div className="text-heading-black">Security Tag Details</div>
          <div className="row">
            <div className="col-6 margin-top-30">
              <div className="text-span-label">Tag ID</div>
              <div className="text-span-black margin-top-10">
                {securityTagInfo.security_tag_no ? securityTagInfo.security_tag_no : "-"}
              </div>
            </div>
            <div className="col-6 margin-top-30">
              <div className="text-span-label">Past Tag ID(s)</div>
              <div className="text-span-black margin-top-10">{pastTags.length ? pastTags : "-"}</div>
            </div>
            <div className="col-6 margin-top-30">
              <div className="text-span-label">Comments</div>
              <div className="text-span-black margin-top-10">
                {securityTagInfo.comments ? securityTagInfo.comments : "-"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SecurityTagDetails.defaultProps = {
  securityTagInfo: {
    security_tag_no: "",
    history: [],
    comments: "",
  },
};
