import axios from "axios";
import { saveAs } from "file-saver";

export const downloadPdf = async (url) => {
  console.log("download", url);
  if (url) {
    const res = await fetch(url);
    console.log(res);
    const blob = await res.blob();
    saveAs(blob, "label.pdf");
  }
  return;
};
