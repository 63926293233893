/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import { cargoActions } from "../../../actions/rootActions";
import NotificationArea from "./notificationArea";

const addCargoToShipmentPage = (props) => {
  const shipment = props.location.state.shipment;

  const trackingNoInputRef = useRef(null);
  const [trackingNo, setTrackingNo] = useState("");

  const [notificationBoxType, setNotificationBoxType] = useState({
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
  });

  const [successNotificationData, setSuccessNotificationData] = useState({});
  const [errorNotificationData, setErrorNotificationData] = useState({
    errorMessage: "",
  });

  const [toggleContent, setToggleContent] = useState({
    showMainContent: true,
    showSettingsContent: false,
  });

  useEffect(() => {
    if (props.addCargoToShipmentData) {
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false,
      });
      setSuccessNotificationData({
        tracking_no: props.addCargoToShipmentData.scanned_tracking_no,
        successMessage: "Successfully Added!",
      });
      setTrackingNo("");
      playSound("success");
    }
  }, [props.addCargoToShipmentData]);

  useEffect(() => {
    if (props.addCargoToShipmentDataError) {
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
      });
      setErrorNotificationData({
        errorMessage: props.addCargoToShipmentDataError.errorData.scanned_tracking_no[0],
        jsonBody: props.addCargoToShipmentDataError.jsonBody,
      });
      setTrackingNo("");
      playSound("error");
    }
  }, [props.addCargoToShipmentDataError]);

  useEffect(() => {
    return () => {
      props.dispatch(cargoActions.clearAddCargoToShipmentResponse());
    };
  }, []);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
        <LinkContainer to="/scan/hubmanage/shipments">
          <p className="link-title mb-0">Shipments</p>
        </LinkContainer>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="link-title text-capitalize mb-0" onClick={onCargoClick}>
          {shipment.tracking_no}
        </p>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="title text-capitalize mb-0">Add Parcel/Cargo</p>
      </div>
      {toggleContent.showMainContent && mainContent()}
    </div>
  );

  function onCargoClick() {
    goBackToPreviousScreen();
  }

  function mainContent() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">{notificationAndScanInputField()}</div>
      </div>
    );
  }

  function notificationAndScanInputField() {
    const noDataBoxClass = notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
    const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
    const validDataBoxClass = notificationBoxType.showValidDataBox ? "d-flex flex-column" : "d-none";
    return (
      <NotificationArea
        shipment={shipment}
        trackingNoInputRef={trackingNoInputRef}
        trackingNo={trackingNo}
        setTrackingNo={setTrackingNo}
        notificationBoxType={notificationBoxType}
        noDataBoxClass={noDataBoxClass}
        errorDataBoxClass={errorDataBoxClass}
        validDataBoxClass={validDataBoxClass}
        successNotificationData={successNotificationData}
        errorNotificationData={errorNotificationData}
        onPressEnter={onAddCargoToShipment}
        onChangeScanNumberCameraChange={onChangeScanNumberCameraChange}
      />
    );
  }

  function onAddCargoToShipment(scannedTrackingNo) {
    const trackingCodeToSend = scannedTrackingNo ? scannedTrackingNo : trackingNo;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipment.tracking_no.trim(),
      scanned_tracking_no: trackingCodeToSend.trim(),
    };
    props.dispatch(cargoActions.addCargoToShipment(data));
  }

  function onChangeScanNumberCameraChange(scanResult) {
    const scannedNumber = scanResult.text;
    setTrackingNo(scannedNumber);
    onAddCargoToShipment(scannedNumber);
  }

  function playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }
};

const mapStateToProps = (state) => ({
  addCargoToShipmentData: state.cargo.addCargoToShipmentData,
  addCargoToShipmentDataError: state.cargo.addCargoToShipmentDataError,
});

export default connect(mapStateToProps)(addCargoToShipmentPage);
