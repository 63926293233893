import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSignedInUser } from '../../actions/authActions';
import { scanChangeWeight, scanReset } from '../../actions/scanActions';
import _ from 'lodash';

import Scanner from '../../deprecated/scanner/Scanner';
import Success from '../Success';

class ChangeWeightPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      staffId: localStorage.getItem('staffId'),
      weight: 0,
      scanning: false,
      results: [],
      cta: '',
      loading: false,
      manualTrackingNo: ''
    };
  }

  componentDidMount() {
    this.props.scanReset();

    const url = window.location.href;
    const pageType = url.substring(url.lastIndexOf("/") + 1)
    const ctas = {
      'change-weight': 'Change Weight'
    }

    if (!this.state.cta) {
      this.setState({ cta: ctas[pageType] });
    }

    if (!this.props.user) {
      this.props.fetchSignedInUser();
    }
  }

  componentDidUpdate() {
    if (!this.props.user) {
      this.props.fetchSignedInUser();
    }

    if (typeof (this.props.error) === "boolean" && this.state.loading) {
      this.setState({ loading: false });
    }

    // if(this.props.error === false && this.state.results.length !== 0) {
    //   this.setState({
    //     results: [],
    //     loading: false,
    //     manualTrackingNo: ''
    //   });
    // }
  }

  onSubmit() {
    this.setState({
      scanning: false,
      loading: true
    });

    const { results, staffId, weight, cta } = this.state;

    switch (cta) {
      case "Change Weight":
        this.props.scanChangeWeight(results, staffId, weight);
        break;
      default:

    }
  }

  handleOnChange = (e) => {
    const { name, value } = e.target;

    if (value === 'staffId') {
      localStorage.setItem('staffId', value);
    }

    this.setState({ [name]: value });
  }

  deleteResult = (result) => {
    let { results } = this.state;

    const removedResults = _.remove(results, (n) => {
      return n !== result;
    });

    this.setState({
      results: removedResults
    });
  }

  renderResults() {
    const { results } = this.state;

    return _.reverse(_.map(results, (result) => {
      return (
        <li className="list-group-item d-flex justify-content-between align-items-center" key={result}>
          <div>
            {result}
          </div>
          <button
            className="btn btn-danger text-white"
            onClick={() => this.deleteResult(result)}>
            X
          </button>
        </li>
      )
    }));
  }

  renderManualAdd() {
    return (
      <li className="list-group-item d-flex justify-content-between py-3 px-4 bg-light align-items-center">
        <input
          name="manualTrackingNo"
          type="text"
          className="form-control"
          placeholder="...or manually add"
          value={this.state.manualTrackingNo}
          onChange={(e) => this.handleOnChange(e)}
          onKeyUp={(e) => {
            if (e.keyCode === 13 || e.which === 13) {
              this.addManualTrackingNo(this.state.manualTrackingNo);
            }
          }}
        />
        <button
          className="ml-1 btn btn-janio text-white"
          onClick={() => {
            this.addManualTrackingNo(this.state.manualTrackingNo);
          }}>
          ADD
        </button>
      </li>
    );
  }

  addManualTrackingNo(trackingNo) {
    const { results } = this.state;
    if (trackingNo && trackingNo.length > 0 && !results.includes(trackingNo)) {
      this.setState({
        results: this.state.results.concat([trackingNo]),
        manualTrackingNo: ''
      });
    }
  }

  scan() {
    this.setState({ scanning: !this.state.scanning });
  }

  onDetected(result) {
    const { results } = this.state;
    const barcode = result.codeResult.code;
    if (!_.isEmpty(barcode) && !barcode.match(/[!@#$%^&*()'_=,.?/]+/g) && !results.includes(barcode)) {
      navigator.vibrate(500);
      this.setState({
        results: this.state.results.concat([barcode])
      });
    }
  }

  render() {
    const { user } = this.props;

    return (
      <div className="d-flex flex-column align-items-center">
        <div className="top-container d-flex flex-column align-items-center bg-white w-100">
          <div className="h3 pt-3 mb-0 text-white">
            {this.state.cta}
          </div>
        </div>
        <div className="main-container d-flex flex-column align-items-center">
          <div className="divider divider-scanner mb-3" />
          {
            this.props.error === true || this.props.error === null ?
              user && user.is_first_mile ?
                <div>
                  <div className="content-container card mt-1 mb-1 p-2">
                    <div className="input-group mb-0">
                      <div className="input-group-prepend">
                        <span className="input-group-text">Staff ID</span>
                      </div>
                      <input
                        name="staffId"
                        type="text"
                        className="form-control text-center font-weight-bold"
                        placeholder="Click Here First"
                        onChange={(e) => this.handleOnChange(e)}
                        value={this.state.staffId !== null ? this.state.staffId : ''}
                      />
                    </div>

                  </div>

                  {
                    this.state.staffId && this.state.staffId.length >= 4 ?
                      <div>
                        <div className="content-container card mt-1 mb-1 p-2 py-1">
                          {this.state.scanning ? <Scanner onDetected={this.onDetected.bind(this)} /> : null}
                          <button
                            className={`btn btn-janio text-white ${this.state.scanning ? "" : "btn-janio-redirect"}`}
                            onClick={this.scan.bind(this)}>
                            {this.state.scanning ? 'STOP SCANNING' : 'CLICK HERE TO START SCANNING'}
                          </button>
                        </div>

                        <div className="content-container card mt-1 mb-1">
                          <div className="card-header text-center font-weight-bold">
                            {`Scanned Tracking Nos (${this.state.results.length})`}
                          </div>
                          {
                            this.state.results && this.state.results.length > 0 ?
                              <ul className="list-group list-group-flush">
                                {this.renderManualAdd()}
                                {this.renderResults()}
                              </ul>
                              :
                              <ul className="list-group list-group-flush">
                                {this.renderManualAdd()}
                                <li className="list-group-item">Nothing scanned.</li>
                              </ul>
                          }
                        </div>

                        <div className="input-group mb-1">
                          <div className="input-group-prepend">
                            <span className="input-group-text">New Weight (KG)</span>
                          </div>
                          <input
                            name="weight"
                            type="text"
                            className="form-control text-center font-weight-bold"
                            placeholder="Weight in KG"
                            onChange={(e) => this.handleOnChange(e)}
                            value={this.state.weight}
                          />
                        </div>

                        {
                          this.props.error ?
                            <div className="alert alert-danger mb-1" role="alert">
                              There is an error, please try again.
                            </div>
                            :
                            null
                        }

                        <button
                          type="button"
                          className={`text-white btn btn-lg mb-3 w-100 py-3 ${this.state.results.length === 0 || this.state.loading ? "btn-secondary" : "btn-success"}`}
                          disabled={this.state.results.length === 0}
                          onClick={this.onSubmit.bind(this)}>
                          {`${this.state.cta} to ${this.state.weight}KG (${this.state.results.length})`}
                        </button>
                      </div>
                      :
                      null
                  }

                </div>
                :
                null
              :
              <Success response={this.props.response} />
          }
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth, scan }) {
  return {
    user: auth.user,
    error: scan.error,
    response: scan.response
  };
}

export default connect(mapStateToProps, {
  fetchSignedInUser,
  scanChangeWeight,
  scanReset
})(ChangeWeightPage);
