import { ROOT_URL } from "../actions";

export const cargoApi = {
  ADD_TO_CARGO: `${ROOT_URL}/hms/add_to_cargo/`,
  ADD_CARGO_TO_SHIPMENT: `${ROOT_URL}/hms/add_to_shipment/`,
  CREATE_CARGO: `${ROOT_URL}/hms/create_cargo/`,
  REMOVE_FROM_CARGO: `${ROOT_URL}/hms/remove_from_cargo/`,
  GET_COUNTRIES_LIST: `${ROOT_URL}/hms/get_countries/`,
  PRINT_CARGO_LABEL: `${ROOT_URL}/hms/print_cargo_label/`,
  UPDATE_CARGO_DETAILS: `${ROOT_URL}/hms/update_cargo_details/`,
  CARGOS: `${ROOT_URL}/hms/cargos/`,
  REMOVE_ALL_FROM_CARGO: `${ROOT_URL}/hms/remove_all_tracking_no_from_cargo/`,
};

