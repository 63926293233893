import React from "react";
import { Link } from "react-router-dom";
import DigitalInfrastructure from "../../../images/parcel-icons/digital-infrastructure.png";
import Sorting from "../../../images/parcel-icons/sorting.png";
import TakePhoto from "../../../images/cargo-icons/take-photo.png";
import SeePhoto from "../../../images/cargo-icons/see-photo.png";
import { isEmpty } from "lodash";
import ActionItem from "../commonComponents/actionItem";

export default function CargoActionItemsList(props) {
  const {
    showActionItems,
    settingsClass,
    scannedNumbersList,
    scanSettings,
    errorOnScanning,
    scanType,
    showCargoArrivalBreakActionItems,
    cargoDetails,
    pageName,
    route,
  } = props;

  const numberOfScanItems = scannedNumbersList && scannedNumbersList.length > 0 ? scannedNumbersList.length : "0";
  const showActionItem = !errorOnScanning && showActionItems;
  const shipmentActionItemClass =
    scanSettings.recipientId === null && scanSettings.groupId === "" ? "action-settings-buttons-color-margin-left" : "";
  let showPhotosActionItems = false;
  if (
    (scanType === "cargo arrival" || scanType === "cargo break") &&
    showActionItems &&
    showCargoArrivalBreakActionItems
  ) {
    showPhotosActionItems = true;
  } else if (
    showCargoArrivalBreakActionItems &&
    scanType === "cargo break" &&
    errorOnScanning &&
    !isEmpty(cargoDetails?.security_tag_info)
  ) {
    showPhotosActionItems = true;
  }

  return (
    <div className="d-flex flex-column">
      {(showActionItems || showPhotosActionItems) && (
        <div className="action-item-box-shadow margin-bottom-20">
          <ActionItem
            to={getScannedNumberListHref()}
            imageSrc={Sorting}
            itemText={`${numberOfScanItems} Scanned in This Session`}
          />
        </div>
      )}
      {showPhotosActionItems && (
        <div className="action-item-box-shadow margin-bottom-20">
          <ActionItem to={getTakePhotosHref()} imageSrc={TakePhoto} itemText="Take Photo" />
          <ActionItem to={getSeePhotosHref()} imageSrc={SeePhoto} itemText="See Photo(s)" />
        </div>
      )}
      <div className="action-item-box-shadow">
        <ActionItem to={getSettingsHref()} imageSrc={DigitalInfrastructure} itemText="Settings">
          {scanSettings.linkToShipment === undefined &&
            scanSettings.recipientId === null &&
            scanSettings.groupId === "" && <button className="action-settings-buttons">None</button>}
          {scanSettings.linkToShipment !== undefined && (
            <button className={"action-settings-buttons-color " + shipmentActionItemClass}>Shipment</button>
          )}
          {(scanSettings.recipientId !== null || scanSettings.groupId !== "") && (
            <button className="action-settings-buttons-color action-settings-buttons-color-margin-left">Tagged</button>
          )}
        </ActionItem>
      </div>
    </div>
  );

  function getSettingsHref() {
    return {
      pathname: "/scan/hubmanage/cargo-scanner/settings",
      state: { scanType: scanType, pageName: pageName, route: route },
    };
  }

  function getScannedNumberListHref() {
    return {
      pathname: "/scan/hubmanage/scanned-numbers-list",
      state: { scanType: scanType, pageName: pageName, route: route },
    };
  }

  function getSeePhotosHref() {
    return {
      pathname: "/scan/hubmanage/cargo-photos",
      state: { cargo: cargoDetails },
    };
  }

  function getTakePhotosHref() {
    return {
      pathname: "/scan/hubmanage/take-cargo-photos",
      state: { cargo: cargoDetails, scanType: scanType },
    };
  }
}
