export const parcelTypes = {
  FETCH_PARCEL_INFO_LOADING: "FETCH_PARCEL_INFO_LOADING",
  FETCH_PARCEL_INFO_SUCCESS: "FETCH_PARCEL_INFO_SUCCESS",
  FETCH_PARCEL_INFO_FAILURE: "FETCH_PARCEL_INFO_FAILURE",
  CLEAR_FETCH_PARCEL_DATA: "CLEAR_FETCH_PARCEL_DATA",
  SAVE_PARCEL_SCAN_EXCEPTION: "SAVE_PARCEL_SCAN_EXCEPTION",
  CLEAR_PARCEL_SCAN_EXCEPTION: "CLEAR_PARCEL_SCAN_EXCEPTION",
  SAVE_PARCEL_DATA: "SAVE_PARCEL_DATA",

  UPDATE_TRACKER_LOADING: "UPDATE_TRACKER_LOADING",
  UPDATE_TRACKER_SUCCESS: "UPDATE_TRACKER_SUCCESS",
  UPDATE_TRACKER_FAILURE: "UPDATE_TRACKER_FAILURE",
  CLEAR_UPDATE_TRACKER_DATA: "CLEAR_UPDATE_TRACKER_DATA",
  SET_NETWORK_PARTNER: "SET_NETWORK_PARTNER",

  REDELIVER_SCAN_LOADING: "REDELIVER_SCAN_LOADING",
  REDELIVER_SCAN_SUCCESS: "REDELIVER_SCAN_SUCCESS",
  REDELIVER_SCAN_FAILURE: "REDELIVER_SCAN_FAILURE",
  CLEAR_REDELIVER_SCAN_DATA: "CLEAR_REDELIVER_SCAN_DATA",

  FETCH_DISPOSAL_ORDER_LOADING: "FETCH_DISPOSAL_ORDER_LOADING",
  FETCH_DISPOSAL_ORDER_SUCCESS: "FETCH_DISPOSAL_ORDER_SUCCESS",
  FETCH_DISPOSAL_ORDER_FAILURE: "FETCH_DISPOSAL_ORDER_FAILURE",
  SET_DISPOSAL_SCANNED_NUMBERS_LIST: "SET_DISPOSAL_SCANNED_NUMBERS_LIST",
  CLEAR_DISPOSAL_ORDER_DATA: "CLEAR_DISPOSAL_ORDER_DATA",
  CLEAR_DISPOSAL_ORDER_ERROR: "CLEAR_DISPOSAL_ORDER_ERROR",

  DISPOSAL_SCAN_LOADING: "DISPOSAL_SCAN_LOADING",
  DISPOSAL_SCAN_SUCCESS: "DISPOSAL_SCAN_SUCCESS",
  DISPOSAL_SCAN_FAILURE: "DISPOSAL_SCAN_FAILURE",
  CLEAR_DISPOSAL_SCAN_DATA: "CLEAR_DISPOSAL_SCAN_DATA",

  UPDATE_WEIGHT_LOADING: "UPDATE_WEIGHT_LOADING",
  UPDATE_WEIGHT_SUCCESS: "UPDATE_WEIGHT_SUCCESS",
  UPDATE_WEIGHT_FAILURE: "UPDATE_WEIGHT_FAILURE",
  CLEAR_UPDATE_WEIGHT_DATA: "CLEAR_UPDATE_WEIGHT_DATA",

  ADD_EXCEPTION_LOADING: "ADD_EXCEPTION_LOADING",
  ADD_EXCEPTION_SUCCESS: "ADD_EXCEPTION_SUCCESS",
  ADD_EXCEPTION_FAILURE: "ADD_EXCEPTION_FAILURE",
  SET_EXCEPTION_NUMBER: "SET_EXCEPTION_NUMBER",
  SAVE_EXCEPTION_DATA: "SAVE_EXCEPTION_DATA",
  CLEAR_ADD_EXCEPTION_DATA: "CLEAR_ADD_EXCEPTION_DATA",

  ADD_LOCATION_LOADING: "ADD_LOCATION_LOADING",
  ADD_LOCATION_SUCCESS: "ADD_LOCATION_SUCCESS",
  ADD_LOCATION_FAILURE: "ADD_LOCATION_FAILURE",
  SAVE_LOCATION_DATA: "SAVE_LOCATION_DATA",
  CLEAR_ADD_LOCATION_DATA: "CLEAR_ADD_LOCATION_DATA",

  SAVE_SCAN_SETTINGS: "SAVE_SCAN_SETTINGS",
  CLEAR_PARCEL_SCAN_DATA: "CLEAR_PARCEL_SCAN_DATA",

  RESOLVE_EXCEPTIONS_LOADING: "RESOLVE_EXCEPTIONS_LOADING",
  RESOLVE_EXCEPTIONS_SUCCESS: "RESOLVE_EXCEPTIONS_SUCCESS",
  RESOLVE_EXCEPTIONS_ERROR: "RESOLVE_EXCEPTIONS_ERROR",
  RESET_RESOLVE_EXCEPTIONS_DATA: "RESET_RESOLVE_EXCEPTIONS_DATA",

  SAVE_REMEDY_REASON_DATA: "SAVE_REMEDY_REASON_DATA",
  AUTOMATICALLY_RESOLVE_EXCEPTIONS: "AUTOMATICALLY_RESOLVE_EXCEPTIONS",

  GET_RESOURCE_DETAILS_SUCCESS: "GET_RESOURCE_DETAILS_SUCCESS",
  GET_RESOURCE_DETAILS_ERROR: "GET_RESOURCE_DETAILS_ERROR",

  ADD_RESOURCE_LOADING: "ADD_RESOURCE_LOADING",
  ADD_RESOURCE_SUCCESS: "ADD_RESOURCE_SUCCESS",
  ADD_RESOURCE_ERROR: "ADD_RESOURCE_ERROR",

  SAVE_RESOURCE_DATA: "SAVE_RESOURCE_DATA",
  RESET_RESOLVE_EXCEPTIONS_SUCCESS_DATA: "RESET_RESOLVE_EXCEPTIONS_SUCCESS_DATA",
  PLAY_ERROR_SOUND: "PLAY_ERROR_SOUND",

  SCAN_LABELLESS_PACKAGE_SUCCESS: "SCAN_LABELLESS_PACKAGE_SUCCESS",
  SCAN_LABELLESS_PACKAGE_ERROR: "SCAN_LABELLESS_PACKAGE_ERROR",
};
