/* eslint-disable  jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import compose from 'recompose/compose';
import { withNamespaces, Trans } from 'react-i18next';
import i18n from './i18n';
import janioLogo from '../images/old-icons/janio_logo.png';
import tick from '../images/old-icons/tick.png'
import '../../node_modules/normalize.css/normalize.css';
import '../style/style.css';

const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
}

class Header extends Component {
  render(){
    if (i18n.language.includes('en') && i18n.language !== 'en'){
      i18n.changeLanguage('en')
    } else if (i18n.language.includes('zh') && i18n.language !== 'zh'){
      i18n.changeLanguage('zh')
    } else if (i18n.language === 'en' || i18n.language === 'zh' || i18n.language === 'id') {
    } else {
      i18n.changeLanguage('en')
    }
    return (
      <div className="d-flex flex-column align-items-center">
        <div className="top-container d-flex flex-row justify-content-center align-items-center w-100 mb-4">
          <div className='main-header-container d-flex flex-row justify-content-between align-items-center pt-3 pb-3'>
            <div className='invisible-box-left'/>
            <LinkContainer to='/scan'>
              <a>
                <img
                  src={janioLogo}
                  className="logo-container img-fluid"
                  alt=""
                />
              </a>
            </LinkContainer>
            <div className='d-flex invisible-box justify-content-end'>
              <div className="dropdown pr-2">
                <button className="btn btn-language dropdown-toggle text-white"
                        type="button" id="languageSelector" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" style={{backgroundColor:'transparent'}}>
                  <Trans>Language</Trans>
                </button>
                <div className="dropdown-menu" aria-labelledby="languageSelector">
                  <div className='d-flex flex-row'>
                    {i18n.language==='en' ? <img src={tick} className='tick-container img-fluid' alt=''/> : <div className='tick-container'></div>}
                    <button className="btn dropdown-item" type='button' onClick={()=> changeLanguage('en')}>English</button>
                  </div>
                  <div className='d-flex flex-row'>
                    {i18n.language==='id' ? <img src={tick} className='tick-container img-fluid' alt=''/> : <div className='tick-container'></div>}
                    <button className="btn dropdown-item" type='button' onClick={()=> changeLanguage('id')}>Bahasa Indonesia</button>
                  </div>
                  <div className='d-flex flex-row'>
                    {i18n.language==='zh' ? <img src={tick} className='tick-container img-fluid' alt=''/> : <div className='tick-container'></div>}
                    <button className="btn dropdown-item" type='button' onClick={()=> changeLanguage('zh')}>中文</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
};

export default compose(
  withNamespaces()
)(Header);
