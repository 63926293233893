import axios from 'axios';
import { authApi, commonApi } from '../api';
import { authTypes } from '../types';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const defaultToken = localStorage.getItem('token');
if (defaultToken) {
  axios.defaults.headers.common['Authorization'] = 'Token ' + defaultToken;
}

export function signinUser(username, password, history) {
  return function (dispatch) {
    axios.post(authApi.LOGIN,
      {
        "username": username,
        "password": password
      }
    )
      .then((response) => {
        const token = response.data.token;
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = `Token ${token}`;
        dispatch(fetchSignedInUser())
        dispatch({
          type: authTypes.AUTH_USER,
        });
        history.push('/');
      })
      .catch((error) => {
        dispatch({
          type: authTypes.AUTH_ERROR
        });
      });
  }
}

export function signoutUser(history) {
  localStorage.removeItem('token');

  return function (dispatch) {
    dispatch({ type: authTypes.UNAUTH_USER });
    history.push('/signin');
  }
}

export function fetchSignedInUser() {
  const token = localStorage.getItem('token');

  return function (dispatch) {
    axios.get(authApi.PROFILE,
      { headers: { 'Authorization': 'Token ' + token } }
    )
      .then((response) => {
        dispatch({
          type: authTypes.FETCH_SIGNED_IN_USER,
          payload: response.data[0]
        });
      });
  }
}

export function clearAuthErrors() {
  return {
    type: authTypes.CLEAR_AUTH_ERRORS
  }
}

export function getSessionID(requestPayload) {
  return async function (dispatch) {
    try {
      const res = await axios.post(commonApi.GET_SESSION_ID, requestPayload);
      dispatch({
        type: authTypes.GET_SESSION_ID_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: authTypes.GET_SESSION_ID_ERROR,
        payload: err
      })
    }
  }
}