import React, { useState, useEffect } from "react";
import { Form, Divider, message } from "antd";
import { DIMENSION_DATA, SCANNED_NUMBER, UPDATE_DIMWEIGHT } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { parcelActions } from "../../../actions/rootActions";
import { scanAudioAlert } from "../../../utils/voiceTypeUtils";
import BreadCrumb from "../commonComponents/BreadCrumb";
import Input from "../../../common/input/input";
import * as _ from "lodash";

function UpdateWeight(props) {
  const {
    orderData,
    latestScannedNumber,
    successNotificationData,
    updateWeightLoading,
    updateWeightSuccess,
    scannedNumbersList,
    voiceUtilsData,
  } = props;

  const scanType = props.location.state.scanType;
  const locationPageName = props.location.state.pageName;
  const locationRoute = props.location.state.route;

  const [orderDuplicateData, setOrderDuplicateData] = useState(orderData);

  const [submitForm, setSubmitForm] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (updateWeightSuccess && successNotificationData.consigneeCountry !== undefined) {
      goBackToPreviousRoute();
      message.success(`Dimensions Updated for ${latestScannedNumber}`);
      let data = {
        consigneeCountry: successNotificationData.consigneeCountry,
        consigneePostalPreFix: successNotificationData.consigneePostalPreFix,
        bins: successNotificationData.bins,
        noOfParcelsScanned: scannedNumbersList.length,
      };
      playAudioAlert(data);
      const mixPanelData = getMixpanelData();
      mixPanelTrack(UPDATE_DIMWEIGHT, {
        ...mixPanelData,
        [SCANNED_NUMBER]: latestScannedNumber,
        [DIMENSION_DATA]: orderDuplicateData,
      });
      props.dispatch(parcelActions.clearUpdateWeightData());
    }
  }, [updateWeightSuccess]);

  return (
    <div className="parcel-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container update-weight-container mt-0 w-100">
          <BreadCrumb pageName={locationPageName} scanType={scanType} route={locationRoute} />
          <div className="bg-container-body">
            <div className="d-flex flex-column">
              <h2 className="text-heading-black margin-bottom-20">Update Dimweigh for {latestScannedNumber}</h2>
              <Form form={form} initialValues={orderDuplicateData}>
                <div className="row">
                  <div className="col-sm-4 col-12 update-dimen-input">
                    <Form.Item
                      name="orderInfo_length"
                      className="mb-0"
                      validateStatus={dimensionsValidation("orderInfo_length") ? "error" : "validating"}
                      help={dimensionsValidation("orderInfo_length")}
                    >
                      <Input
                        label="L (cm)"
                        placeholder="Type here"
                        value={orderDuplicateData.orderInfo_length}
                        onChange={(e) => onOrderDataChange("orderInfo_length", e.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-sm-4 col-12 update-dimen-input">
                    <Form.Item
                      name="orderInfo_width"
                      className="margin-top-xs-30 mb-0"
                      validateStatus={dimensionsValidation("orderInfo_width") ? "error" : "validating"}
                      help={dimensionsValidation("orderInfo_width")}
                    >
                      <Input
                        label="W (cm)"
                        placeholder="Type here"
                        value={orderDuplicateData.orderInfo_width}
                        onChange={(e) => onOrderDataChange("orderInfo_width", e.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-sm-4 col-12 update-dimen-input update-dimen-input--pr-0">
                    <Form.Item
                      name="orderInfo_height"
                      className="margin-top-xs-30 mb-0"
                      validateStatus={dimensionsValidation("orderInfo_height") ? "error" : "validating"}
                      help={dimensionsValidation("orderInfo_height")}
                    >
                      <Input
                        label="H (cm)"
                        placeholder="Type here"
                        value={orderDuplicateData.orderInfo_height}
                        onChange={(e) => onOrderDataChange("orderInfo_height", e.target.value)}
                      />
                    </Form.Item>
                  </div>
                  <div className="col-sm-12 col-md-4 update-dimen-input update-dimen-input--pr-0">
                    <Form.Item
                      name="orderInfo_weight"
                      className="margin-top-30 mb-0"
                      validateStatus={weightValidation() ? "error" : "validating"}
                      help={weightValidation()}
                    >
                      <Input
                        label="Wt (kg)"
                        placeholder="Type here"
                        value={orderDuplicateData.orderInfo_weight}
                        onChange={(e) => onOrderDataChange("orderInfo_weight", e.target.value)}
                      />
                    </Form.Item>
                  </div>
                </div>
                <Divider className="gray-divider" />
                <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                  <button className="secondary-button-small" onClick={goBackToPreviousRoute}>
                    Back
                  </button>
                  <button
                    type="submit"
                    className="primary-button mb-sm-0 mb-3"
                    disabled={updateWeightLoading}
                    onClick={onClickUpdateButton}
                  >
                    {updateWeightLoading ? (
                      <LoadingOutlined className="loading-spinner loading-spinner-white" />
                    ) : (
                      "Update Dimweigh"
                    )}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function weightValidation(submitted) {
    const isFormSubmit = submitted ? submitted : submitForm;
    if (
      isFormSubmit &&
      (orderDuplicateData.orderInfo_weight === undefined || _.isEmpty(orderDuplicateData.orderInfo_weight))
    ) {
      return "This field is required";
    } else if (isFormSubmit && isNaN(orderDuplicateData.orderInfo_weight)) {
      return "A valid number is required";
    } else if (
      isFormSubmit &&
      (orderDuplicateData.orderInfo_weight === undefined || _.isEmpty(orderDuplicateData.orderInfo_weight)) &&
      orderDuplicateData.height !== undefined &&
      !_.isEmpty(orderDuplicateData.height) &&
      orderDuplicateData.length !== undefined &&
      !_.isEmpty(orderDuplicateData.length) &&
      orderDuplicateData.width !== undefined &&
      !_.isEmpty(orderDuplicateData.width)
    ) {
      return "This field is required";
    } else if (isFormSubmit && notHasTwoDecimals(orderDuplicateData.orderInfo_weight)) {
      return "Only two decimals are allowed";
    }
    return null;
  }

  function dimensionsValidation(name, submitted) {
    const isFormSubmit = submitted ? submitted : submitForm;
    if (isFormSubmit && (orderDuplicateData[name] === undefined || _.isEmpty(orderDuplicateData[name]))) {
      return `This field is required`;
    } else if (isFormSubmit && isNaN(orderDuplicateData[name])) {
      return "A valid number is required";
    } else if (isFormSubmit && notHasTwoDecimals(orderDuplicateData[name])) {
      return "Only two decimals are allowed";
    }
    return null;
  }

  function notHasTwoDecimals(unit) {
    if (isNaN(unit)) {
      return true;
    } else if (unit.includes(".") && unit.split(".")[1].length > 2) {
      return true;
    }
    return false;
  }

  function onOrderDataChange(name, value) {
    setOrderDuplicateData({
      ...orderDuplicateData,
      [name]: value,
    });
  }

  function onClickUpdateButton() {
    setSubmitForm(true);
    const formSubmitted = true;
    const weightValidationFail = weightValidation(formSubmitted);
    const heightValidationFail = dimensionsValidation("orderInfo_height", formSubmitted);
    const lengthValidationFail = dimensionsValidation("orderInfo_length", formSubmitted);
    const widthValidationFail = dimensionsValidation("orderInfo_width", formSubmitted);
    if (!weightValidationFail && !heightValidationFail && !lengthValidationFail && !widthValidationFail) {
      updateDimensions();
    }
  }

  function updateDimensions() {
    const params = getParams();
    props.dispatch(parcelActions.onUpdateWeight(params));
  }

  function getParams() {
    const data = {
      tracking_no: latestScannedNumber.trim(),
      length: orderDuplicateData.orderInfo_length,
      width: orderDuplicateData.orderInfo_width,
      height: orderDuplicateData.orderInfo_height,
      weight: orderDuplicateData.orderInfo_weight,
    };
    const params = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      orders: [data],
    };
    return params;
  }

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  function playAudioAlert(fetchOrderData) {
    let networkPartnerBin = undefined;
    let bins = fetchOrderData.bins;
    bins = bins.replace(/'/g, '"');
    bins = JSON.parse(bins);
    networkPartnerBin = _.find(bins, (x) => x.type === "NP");
    const highValueBin = _.find(bins, (x) => x.type === "HIGHVALUE");
    let stringToVoice = "";
    if (voiceUtilsData.defaultAudioType === "Partner Name" && networkPartnerBin) {
      stringToVoice = networkPartnerBin["name"].toLowerCase();
    } else if (voiceUtilsData.defaultAudioType === "Postal Prefix") {
      stringToVoice = fetchOrderData.consigneePostalPreFix.toLowerCase();
    } else if (voiceUtilsData.defaultAudioType === "No. of Parcels Scanned") {
      stringToVoice = fetchOrderData.noOfParcelsScanned;
    } else {
      stringToVoice = fetchOrderData.consigneeCountry.toLowerCase();
    }
    if (highValueBin) {
      stringToVoice = "high value, " + stringToVoice;
    }
    scanAudioAlert(stringToVoice, voiceUtilsData.voice);
  }
}

const mapStateToProps = ({ parcel, common }) => ({
  updateWeightLoading: parcel.updateWeightLoading,
  updateWeightError: parcel.updateWeightError,
  updateWeightSuccess: parcel.updateWeightSuccess,
  successNotificationData: parcel.successNotificationData,
  latestScannedNumber: parcel.latestScannedNumber,
  scannedNumbersList: common.scannedNumbersList,
  orderData: parcel.parcelOrderData,
  voiceUtilsData: common.voiceUtilsData,
});

export default connect(mapStateToProps, null)(UpdateWeight);
