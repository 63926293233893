/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useCallback } from "react";
import { connect } from "react-redux";
import { Form, Divider, Alert, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { cargoActions } from "../../../actions/rootActions";
import { cargoApi } from "../../../api";
import { createRequestPayload } from "../../../utils/createCargoRequestPayload";
import Input from "../../../common/input/input";
import Select from "../../../common/select/select";
import axios from "axios";
import { cloneDeep, debounce, isEmpty } from "lodash";

function EditCargo(props) {
  const cargo = props.location.state.cargo;
  const scanType = props.location.state.scanType;

  const deminisParcelValues = [
    {
      value: true,
      label: "yes",
    },
    {
      value: false,
      label: "no",
    },
  ];

  const [cargoData, setCargoData] = useState({
    cargo_type: cargo.cargo_type,
    dest_country_code: cargo.dest_country_code ? cargo.dest_country_code : cargo.dest_country,
    dest_poscode_prefix: cargo.dest_poscode_prefix,
    weight: cargo.weight,
    height: cargo.height,
    length: cargo.length,
    width: cargo.width,
    security_tag_no: cargo.security_tag_info?.security_tag_no,
    security_tag_comments: cargo.security_tag_info?.comments,
    additional_barcode: cargo.additional_barcode
      ? cargo.additional_barcode
      : localStorage.getItem("additional_barcode"),
    //deminis_parcels: cargo.deminis_parcels ? cargo.deminis_parcels : false,
  });

  const [requestInProgress, setRequestInProgress] = useState(false);

  const [editCargoError, setEditCargoError] = useState(false);
  const [submitForm, setSubmitForm] = useState(false);

  const [form] = Form.useForm();

  const cargoTypes = [
    { label: "Bag", value: "bag" },
    { label: "Carton", value: "carton" },
    { label: "Pallet", value: "pallet" },
    { label: "Other", value: "other" },
  ];

  const onChangeSecurityTag = useCallback(
    debounce((key, value) => {
      const newCargoData = cloneDeep(cargoData);
      newCargoData[key] = value;
      setCargoData(newCargoData);
    }, 100),
    [cargoData.security_tag_comments, cargoData.security_tag_no]
  );

  console.log(cargoData);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0 edit-cargo-container">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <p className="link-title mb-0" onClick={goBackToPreviousScreen}>
              Cargo
            </p>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">{cargo.tracking_no}</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black">Edit Cargo Details</h2>
            <Form form={form} initialValues={cargoData}>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="cargo_type"
                    className="margin-top-30 mb-0"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please select type first",
                      },
                    ]}
                  >
                    <Select
                      label="Cargo Type"
                      placeholder="Select Type"
                      value={cargoData.cargo_type}
                      options={cargoTypes}
                      onChange={(value) => onChangeCargoData("cargo_type", value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="dest_country_code"
                    className="margin-top-30 mb-0"
                    rules={[
                      {
                        type: "string",
                        required: true,
                        message: "Please select country first",
                      },
                    ]}
                  >
                    <Select
                      label="Destination Country"
                      placeholder="Select Country"
                      value={cargoData.dest_country_code}
                      options={getCountriesListOptions()}
                      onChange={(value) => onChangeCargoData("dest_country_code", value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-4">
                  <Form.Item name="dest_poscode_prefix" className="margin-top-30 mb-0">
                    <Input
                      label="Destination Postal Code (Prefix)"
                      placeholder="Type here"
                      value={cargoData.dest_poscode_prefix}
                      onChange={(e) => onChangeCargoData("dest_poscode_prefix", e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
              <Divider className="gray-divider" />
              <h2 className="text-heading-black">Cargo Security</h2>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <Form.Item name="security_tag_no" className="margin-top-30 mb-0">
                    <Input
                      label="Security Tag (Optional)"
                      placeholder="Type here"
                      value={cargoData.security_tag_no ? cargoData.security_tag_no : ""}
                      onChange={(e) => onChangeCargoData("security_tag_no", e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-8">
                  <Form.Item name="security_tag_comments" className="margin-top-30 mb-0">
                    <Input
                      label="Comments (Optional)"
                      placeholder="Type here"
                      type="textarea"
                      value={cargoData.security_tag_comments ? cargoData.security_tag_comments : ""}
                      rows={1}
                      textAreaClass="input-textarea"
                      onChange={(e) => onChangeCargoData("security_tag_comments", e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
              <Divider className="gray-divider" />
              <h2 className="text-heading-black">Cargo Dimweigh</h2>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="weight"
                    className="margin-top-30 mb-0"
                    //validateStatus={weightValidation() ? "error" : "validating"}
                    //help={weightValidation()}
                  >
                    <Input
                      label="Actual Cargo Weight (KG)"
                      placeholder="Type here"
                      value={cargoData.weight}
                      onChange={(e) => onChangeCargoData("weight", e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="length"
                    className="margin-top-30 mb-0"
                    //validateStatus={
                    //dimensionsValidation("length") ? "error" : "validating"
                    //}
                    //help={dimensionsValidation("length")}
                  >
                    <Input
                      label="Cargo Length (cm)"
                      placeholder="Type here"
                      value={cargoData.length}
                      onChange={(e) => onChangeCargoData("length", e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="width"
                    className="margin-top-30 mb-0"
                    //validateStatus={
                    //dimensionsValidation("width") ? "error" : "validating"
                    //}
                    //help={dimensionsValidation("width")}
                  >
                    <Input
                      label="Cargo Width (cm)"
                      placeholder="Type here"
                      value={cargoData.width}
                      onChange={(e) => onChangeCargoData("width", e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="height"
                    className="margin-top-30 mb-0"
                    //validateStatus={
                    //dimensionsValidation("height") ? "error" : "validating"
                    //}
                    //help={dimensionsValidation("height")}
                  >
                    <Input
                      label="Cargo Height (cm)"
                      placeholder="Type here"
                      value={cargoData.height}
                      onChange={(e) => onChangeCargoData("height", e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
              {/* TODO: uncomment if required */}
              {/*
              <Form.Item name="deminis_parcels" className="margin-top-30 mb-0">
                <Select
                  label="Deminis (High Value) Parcels"
                  placeholder="Select Value"
                  value={cargoData.deminis_parcels}
                  options={deminisParcelValues}
                  onChange={(value) =>
                    onChangeCargoData("deminis_parcels", value)
                  }
                />
              </Form.Item>
              */}
              <Divider className="gray-divider" />
              <h2 className="text-heading-black">Cargo Label</h2>
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <Form.Item
                    name="additional_barcode"
                    className="margin-top-30 mb-0"
                    //validateStatus={
                    //dimensionsValidation("height") ? "error" : "validating"
                    //}
                    //help={dimensionsValidation("height")}
                  >
                    <Input
                      label="Additional Barcode"
                      placeholder="Type here"
                      value={cargoData.additional_barcode}
                      onChange={(e) => onChangeCargoData("additional_barcode", e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>

              <Divider className="gray-divider" />
              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button className="secondary-button-small" onClick={goBackToPreviousScreen}>
                  Back
                </button>
                <button type="submit" className="primary-button-small mb-sm-0 mb-3" onClick={onClickEditButton}>
                  {requestInProgress ? <LoadingOutlined className="loading-spinner loading-spinner-white" /> : "Edit"}
                </button>
              </div>
            </Form>
            {editCargoError && (
              <div className="margin-top-20">
                <Alert type="error" message="Error, please try again!" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function getCountriesListOptions() {
    const { countriesList } = props;
    return countriesList.map((country) => {
      country.label = country.name;
      country.value = country.code;
      return country;
    });
  }

  function weightValidation() {
    if (submitForm && (cargoData.weight === undefined || isEmpty(cargoData.weight))) {
      return "Please type weight first";
    } else if (submitForm && isNaN(cargoData.weight)) {
      return "A valid number is required";
    } else if (
      submitForm &&
      (cargoData.weight === undefined || isEmpty(cargoData.weight)) &&
      cargoData.height !== undefined &&
      !isEmpty(cargoData.height) &&
      cargoData.length !== undefined &&
      !isEmpty(cargoData.length) &&
      cargoData.width !== undefined &&
      !isEmpty(cargoData.width)
    ) {
      return "Please type weight first";
    } else if (submitForm && notHasTwoDecimals(cargoData.weight)) {
      return "Only two decimals are allowed";
    }
    return null;
  }

  function dimensionsValidation(name) {
    if (submitForm && (cargoData[name] === undefined || isEmpty(cargoData[name]))) {
      return `Please type ${name} first`;
    } else if (submitForm && isNaN(cargoData[name])) {
      return "A valid number is required";
    } else if (submitForm && notHasTwoDecimals(cargoData[name])) {
      return "Only two decimals are allowed";
    }
    return null;
  }

  function notHasTwoDecimals(unit) {
    if (isNaN(unit)) {
      return true;
    } else if (unit.includes(".") && unit.split(".")[1].length > 2) {
      return true;
    }
    return false;
  }

  async function onClickEditButton() {
    try {
      localStorage.setItem("additional_barcode", cargoData.additional_barcode);
      setSubmitForm(true);
      await form.validateFields();
      handleEditCargoClick();
    } catch (errorInfo) {}
  }

  function onChangeCargoData(name, value) {
    setCargoData({
      ...cargoData,
      [name]: value,
    });
  }

  function getCargoEditedData() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      tracking_no: cargo.tracking_no,
      weight: cargoData.weight,
      height: cargoData.height,
      length: cargoData.length,
      width: cargoData.width,
      cargo_type: cargoData.cargo_type,
      dest_country_code: cargoData.dest_country_code,
      dest_poscode_prefix: cargoData.dest_poscode_prefix,
      security_tag_no: cargoData.security_tag_no ? cargoData.security_tag_no : null,
      security_tag_comments: cargoData.security_tag_comments ? cargoData.security_tag_comments : null,
      additional_barcode: cargoData.additional_barcode ? cargoData.additional_barcode : null,

      //deminis_parcels: cargoData.deminis_parcels,
    };
    return data;
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  function reFetchCargoDetails() {
    if (scanType) {
      const params = createRequestPayload(
        {
          tracking_no: cargo.tracking_no.trim(),
          scan_type: scanType,
        },
        []
      );
      props.dispatch(cargoActions.getCargoDetails({ params }));
    }
  }

  function handleEditCargoClick() {
    setRequestInProgress(true);
    const data = getCargoEditedData();
    axios
      .post(cargoApi.UPDATE_CARGO_DETAILS, data)
      .then((res) => {
        message.success("Cargo has been updated");
        setSubmitForm(false);
        setRequestInProgress(false);
        reFetchCargoDetails();
        goBackToPreviousScreen();
      })
      .catch((err) => {
        console.log("error edit cargo", err);
        setEditCargoError(true);
        setTimeout(() => {
          setEditCargoError(false);
        }, 3000);
        setRequestInProgress(false);
      });
  }
}

const mapStateToProps = (state) => ({
  countriesList: state.cargo.countriesList,
});

export default connect(mapStateToProps)(EditCargo);
