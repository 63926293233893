import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ChangeWeightPage from './ChangeWeightPage';
import Header from '../Header';
import RedirectWarehouse from './RedirectWarehouse';
import ScannerPage from '../ScannerPage';

export default function HMSlite() {
  return (
    <Header>
      <Switch>
        <Route path="/scan/warehouse/main" component={RedirectWarehouse} />
        <Route path="/scan/warehouse/transfer" component={ScannerPage} />
        <Route path="/scan/warehouse/receive" component={ScannerPage} />
        <Route path="/scan/warehouse/change-weight" component={ChangeWeightPage} />
      </Switch>
    </Header>
  )
}