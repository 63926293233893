/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { cargoActions, commonActions } from "../../../actions/rootActions";
import NotificationArea from "./notificationArea";
import { ADD_CARGO_TO_CARGO, CARGO_TRACKING_NUMBER, SCANNED_NUMBER } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import ActionItem from "../commonComponents/actionItem";
import Sorting from "../../../images/parcel-icons/sorting.png";

const addCargoToCargoPage = (props) => {
  const cargo = props.location.state.cargo;
  const { scannedNumbersList } = props;
  const numberOfScanItems = scannedNumbersList && scannedNumbersList.length > 0 ? scannedNumbersList.length : "0";

  const trackingNoInputRef = useRef(null);
  const [trackingNo, setTrackingNo] = useState("");

  const [notificationBoxType, setNotificationBoxType] = useState({
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
  });

  const [successNotificationData, setSuccessNotificationData] = useState({});
  const [errorNotificationData, setErrorNotificationData] = useState({
    errorMessage: "",
  });

  useEffect(() => {
    if (props.addCargoTrackingNumber) {
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false,
      });
      setSuccessNotificationData({
        tracking_no: props.addCargoTrackingNumber.scanned_tracking_no,
        successMessage: "Successfully Added!",
      });
      setTrackingNo("");
      playSound("success");
    }
  }, [props.addCargoTrackingNumber]);

  useEffect(() => {
    if (props.addCargoError) {
      setNotificationBoxType({
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
      });
      setErrorNotificationData({
        errorMessage: props.addCargoError.errorData.scanned_tracking_no
          ? props.addCargoError.errorData.scanned_tracking_no[0]
          : "Unknown Error, please screenshot and send to Tech Support.",
        jsonBody: props.addCargoError.jsonBody,
      });
      setTrackingNo("");
      playSound("error");
    }
  }, [props.addCargoError]);

  useEffect(() => {
    return () => {
      props.dispatch(cargoActions.clearCargoResponse());
    };
  }, []);

  return (
    <div className="scanner-bg-container">
      <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
        <p className="link-title mb-0" onClick={onCargoClick}>
          Cargo
        </p>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="link-title text-capitalize mb-0" onClick={onCargoClick}>
          {cargo.tracking_no}
        </p>
        <p className="bread-crumb mt-n1 mb-0">{">"}</p>
        <p className="title text-capitalize mb-0">Add Parcel/Cargo</p>
      </div>
      {mainContent()}
    </div>
  );

  function onCargoClick() {
    goBackToPreviousScreen();
  }

  function mainContent() {
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">{notificationAndScanInputField()}</div>
        <div className="col-md-6 col-sm-12  mt-md-0 mt-sm-3 mt-3">
          <ActionItem
            to={getScannedNumberListHref()}
            imageSrc={Sorting}
            itemText={`${numberOfScanItems} Scanned in This Session`}
          />
        </div>
      </div>
    );
  }

  function notificationAndScanInputField() {
    const noDataBoxClass = notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
    const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
    const validDataBoxClass = notificationBoxType.showValidDataBox ? "d-flex flex-column" : "d-none";
    return (
      <NotificationArea
        trackingNoInputRef={trackingNoInputRef}
        trackingNo={trackingNo}
        setTrackingNo={setTrackingNo}
        notificationBoxType={notificationBoxType}
        noDataBoxClass={noDataBoxClass}
        errorDataBoxClass={errorDataBoxClass}
        validDataBoxClass={validDataBoxClass}
        successNotificationData={successNotificationData}
        errorNotificationData={errorNotificationData}
        onPressEnter={onAddCargoToCargo}
        onChangeScanNumberCameraChange={onChangeScanNumberCameraChange}
      />
    );
  }

  function getScannedNumberListHref() {
    return {
      pathname: "/scan/hubmanage/scanned-numbers-list",
      state: { scanType: "sweep", pageName: "Cargo Scan", route: "/scan/hubmanage/cargo-scan" },
    };
  }

  function onAddCargoToCargo(scannedTrackingNo) {
    const trackingCodeToSend = scannedTrackingNo ? scannedTrackingNo : trackingNo;
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargo.tracking_no.trim(),
      scanned_tracking_no: trackingCodeToSend.trim(),
    };
    props.dispatch(cargoActions.createCargoToShipment(data, scannedNumbersList));
    const mixPanelData = getMixpanelData();
    mixPanelTrack(ADD_CARGO_TO_CARGO, {
      ...mixPanelData,
      [CARGO_TRACKING_NUMBER]: cargo.tracking_no.trim(),
      [SCANNED_NUMBER]: trackingCodeToSend,
    });
  }

  function onChangeScanNumberCameraChange(scanResult) {
    const scannedNumber = scanResult.text;
    setTrackingNo(scannedNumber);
    onAddCargoToCargo(scannedNumber);
  }

  function playSound(type) {
    const sounds = {
      success: new Audio("/sounds/success.mp3"),
      error: new Audio("/sounds/error.mp3"),
    };
    sounds[type].play();
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }
};

const mapStateToProps = (state) => ({
  addCargoTrackingNumber: state.cargo.addCargoTrackingNumber,
  addCargoError: state.cargo.addCargoError,
  scannedNumbersList: state.common.scannedNumbersList,
});

export default connect(mapStateToProps)(addCargoToCargoPage);
