/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Alert } from "antd";
import * as _ from "lodash";
import { useDispatch } from "react-redux";
import { clearParcelScanExceptions } from "../../../actions/scanActions";
import ZebraScanner from "../../../common/zebraScanner/ZebraScanner";
import { downloadPdf } from "../../../utils/downloadPdf";
import { ErrorNotificationArea } from "./ErrorNotificationArea";
import { SuccessNotificationArea } from "./SuccessNotificationArea";
import { WarningNotificationArea } from "./WarningNotificationArea";
import { ParcelScannerContext } from "./ParcelScanner";
import { DownloadPrintText } from "../commonComponents/downloadPrintText";

function NotificationArea(props) {
  const dispatch = useDispatch();

  const defaultScanner = localStorage.getItem("defaultScanner");
  const showPrintLabel = localStorage.getItem("showPrintLabel");
  const downloadLabel = localStorage.getItem("downloadLabel");

  useEffect(() => {
    return () => {
      dispatch(clearParcelScanExceptions());
    };
  }, []);

  function showScanInputField() {
    return (
      (defaultScanner === null || defaultScanner === undefined || defaultScanner === "Laser") &&
      defaultScanner !== "Camera"
    );
  }

  function showPrintLabelButton() {
    if (
      showPrintLabel !== null ||
      showPrintLabel !== undefined ||
      downloadLabel !== null ||
      downloadLabel !== undefined
      // removed it for labelless scan uses camera
      // defaultScanner === "Laser"
    ) {
      return true;
    }
    return false;
  }

  function mapExceptionToAction(fieldName) {
    const actionToTrigger = {
      "Failed Delivery (Maximum Attempts)": "Escalate to Merchant",
      "Delivery Not Attempted (Out of Coverage)": "Escalate to Merchant",
      Damaged: "Escalate to Merchant",
      "Failed Delivery (Consignee Rejected)": "Escalate to Merchant",
      "Failed Delivery (Consignee Wrong Address)": "Contact Consignee",
      "Failed Delivery (Consignee Unavailable)": "Contact Consignee",
      "Consignee Request Reschedule": "Contact Consignee",
      "Pending Tax Documents": "Bin to Exceptions Area",
      "Delivery Not Attempted (Missorting)": "Bin to Exceptions Area",
      "Returns to Client": "Bin to Exceptions Area",
      Duplicate: "Bin to Exceptions Area",
      "No Data": "Bin to Exceptions Area",
      "No Label": "Bin to Exceptions Area",
      "Disposal of Parcel": "Bin to Exceptions Area",
    };
    return actionToTrigger[fieldName];
  }

  return (
    <ParcelScannerContext.Consumer>
      {(value) => {
        let scannedNumber = value.latestScannedNumber;

        const noDataBoxClass =
          value.scanType !== "sweep" && value.notificationBoxType.showNoDataBox ? "d-flex" : "d-none";
        const warningDataBoxClass = getWarningDataBoxClass();
        const validDataBoxClass = getValidDataBoxClass(warningDataBoxClass);
        const validReturnInboundDataBoxClass = getReturnsInboundBoxClass(validDataBoxClass);

        function getValidDataBoxClass(warningDataBoxClass) {
          if (value.notificationBoxType.showValidDataBox) {
            if (value.scanType === "sweep") {
              return "d-none";
            } else if (value.scanType !== "returns inbound" && warningDataBoxClass === "d-none") {
              return "d-flex";
            } else {
              return "d-none";
            }
          }
          return "d-none";
        }

        function getWarningDataBoxClass() {
          if (value.notificationBoxType.showValidDataBox) {
            if (value.scanType === "sweep") {
              return "d-none";
            } else if (
              value.scanType !== "sweep" &&
              value.scanType !== "returns inbound" &&
              value.scanType !== "labelless package" &&
              (value.successNotificationData.incoterm === "DDU" ||
                value.successNotificationData.paymentType === "cod" ||
                value.successNotificationData.showHighValueError ||
                value.successNotificationData.is_held_up_at_customs === true ||
                value.successNotificationData.is_cancelled === true ||
                value.successNotificationData.ua_returns === true)
            ) {
              return "d-flex";
            } else {
              return "d-none";
            }
          }
          return "d-none";
        }

        function getReturnsInboundBoxClass(validDataBoxClass) {
          if (
            value.notificationBoxType.showValidDataBox &&
            value.scanType === "returns inbound" &&
            validDataBoxClass === "d-none"
          ) {
            return "d-flex";
          }
          return "d-none";
        }

        let renderType = "PARCEL";
        if (value.scanType === "reassign network partner") {
          renderType = "REASSIGN";
        } else if (
          (value.scanType === "warehouse inbound" || value.scanType === "warehouse outbound") &&
          value.successNotificationData.stop_shipment
        ) {
          renderType = "PROHIBIT";
        } else {
          renderType = "PARCEL";
        }

        if (typeof value.latestScannedNumber === "object" && value.latestScannedNumber !== null) {
          scannedNumber = value.latestScannedNumber.tracking_no;
        }

        function showDisposalConfirmationButton() {
          return value.scanType === "disposal" && value.totalScanNumber > 0;
        }

        function getExceptionName() {
          if (value.exceptions && value.exceptions.length > 0) {
            const selectedException = _.find(value.exceptionTypes, (x) => x.name === value.exceptions[0].name);
            return selectedException ? selectedException.description : "";
          }
          return "";
        }

        function getExceptionDescription() {
          if (value.exceptions && value.exceptions.length > 0) {
            const selectedException = _.find(value.exceptionTypes, (x) => x.name === value.exceptions[0].name);
            return selectedException ? mapExceptionToAction(selectedException.description) : "";
          }
          return "";
        }

        return (
          <>
            <div className={`empty-notification-container justify-content-center align-items-center ${noDataBoxClass}`}>
              <span className="empty-notification-text mb-0">You will see the scan results here.</span>
            </div>
            <ErrorNotificationArea
              notificationBoxType={value.notificationBoxType}
              errorNotificationData={value.errorNotificationData}
              parcelScanExceptions={value.parcelScanExceptions}
              scanType={value.scanType}
              scannedNumber={scannedNumber}
              renderType={renderType}
            />
            <WarningNotificationArea
              warningDataBoxClass={warningDataBoxClass}
              successNotificationData={value.successNotificationData}
              scanType={value.scanType}
              renderType={renderType}
              scannedNumber={scannedNumber}
            />
            <SuccessNotificationArea
              renderType={renderType}
              notificationBoxType={value.notificationBoxType}
              successNotificationData={value.successNotificationData}
              validDataBoxClass={validDataBoxClass}
              warningDataBoxClass={warningDataBoxClass}
              networkPartner={value.networkPartner}
              isPackageDisposed={value.isPackageDisposed}
              scannedNumber={scannedNumber}
              latestScannedNumber={value.latestScannedNumber}
            />
            <div
              className={`valid-return-inbound-container flex-column justify-content-center align-items-center ${validReturnInboundDataBoxClass}`}
            >
              <span className="return-inbound-exception">
                Exception:{" "}
                {value.exceptions && value.exceptions.length > 0 ? `${getExceptionName()}` : "no exception detected"}
              </span>
              <h2 className="return-inbound-to-do-next">
                {value.exceptions && value.exceptions.length > 0 ? getExceptionDescription() : "please add exception"}
              </h2>
              <span className="return-inbound-scan-number">{scannedNumber}</span>
            </div>
            {defaultScanner === "Camera" && (
              <div>
                <ZebraScanner onScanComplete={(scanResult) => value.onChangeScanNumberCameraChange(scanResult)} />
              </div>
            )}

            {showScanInputField() && (
              <div className="d-flex align-items-center scanInputContainer">
                <h2 className="text-heading-black mr-3">Scan</h2>
                <input
                  ref={value.scanNumberInputRef}
                  className="form-control scan-input"
                  placeholder="Click here or paste tracking code to scan"
                  value={value.scannedNumber}
                  onChange={value.onChangeScanNumberChange}
                  onKeyUp={value.onChangeScanNumberChange}
                  autoFocus={true}
                />
              </div>
            )}

            {showDisposalConfirmationButton() && (
              <>
                <div className="d-flex align-items-center scanInputContainer">
                  <h2 className="text-heading-black mr-3">Confirm</h2>
                  <button className="print-label-button" onClick={value.showDisposalConfirmationModal}>
                    Dispose {value.totalScanNumber} Package(s)
                  </button>
                </div>
              </>
            )}

            {showPrintLabelButton() && (
              <React.Fragment>
                <div className="d-flex align-items-center flex-column scanInputContainer">
                  <DownloadPrintText
                    onDownloadHandler={() => {
                      return downloadPdf(value.orderData.labelPath);
                    }}
                    onClickPrintLabelButton={() => value.printLabel(value.orderData.labelPath)}
                  />
                </div>
                {value.qzTraySuccess && (
                  <div className="qz-tray-container pt-0">
                    <Alert
                      className="qz-tray-err-message success-text"
                      message="Printer is connected"
                      type="success"
                      showIcon
                    />
                  </div>
                )}

                {value.qzTrayDownloadError && (
                  <div className="qz-tray-container pt-0 pl-5">
                    <Alert
                      className="qz-tray-err-message warning-text"
                      message="Please connect the printer in Settings to print labels"
                      type="warning"
                      showIcon
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </>
        );
      }}
    </ParcelScannerContext.Consumer>
  );
}

export default NotificationArea;
