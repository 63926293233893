
export const convertBlobToBase64 = async (blob) => {
  return new Promise((resolve, _) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      const result = fileReader.result.replace(/^data:image.+;base64,/, "");
      resolve(result);
    }
    fileReader.readAsDataURL(blob);
  });
}