import React from "react";

export const WarningNotificationArea = ({
  warningDataBoxClass,
  successNotificationData,
  scanType,
  renderType,
  scannedNumber,
}) => {
  const highValueHighlight = localStorage.getItem("showHighValueHighlight");
  const dduHighlight = localStorage.getItem("showDDUHighlight");
  const incotermClass = successNotificationData?.incoterm === "DDU" && scanType !== "sweep" ? "" : "d-none";
  const highValueErrorClass = successNotificationData?.showHighValueError && scanType !== "sweep" ? "" : "d-none";
  const uaReturnsClass = successNotificationData?.ua_returns && scanType !== "sweep" ? "" : "d-none";
  const isHeldAtCustomOrCancelledClass =
    successNotificationData?.is_held_up_at_customs ||
    successNotificationData?.is_cancelled ||
    successNotificationData?.has_rts
      ? "d-none"
      : "";
  const notificationCustomClass = successNotificationData?.is_held_up_at_customs ? "" : "d-none";
  const notificationCancelledClass = successNotificationData?.is_cancelled ? "" : "d-none";
  const notificationReturnToSenderClass = successNotificationData?.has_rts ? "" : "d-none";
  const paymentTypeClass = successNotificationData?.paymentType === "cod" && scanType !== "sweep" ? "" : "d-none";
  const highlightBackgroundClass = highValueHighlight || dduHighlight ? "highlight-background" : "";
  const highValueHighlightText =
    highValueHighlight && successNotificationData?.showHighValueError && scanType !== "sweep"
      ? "text-blink highlight-text"
      : "";
  const dduHighlightText =
    dduHighlight && successNotificationData?.incoterm === "DDU" && scanType !== "sweep"
      ? "text-blink highlight-text"
      : "";

  console.log("warning scanned number", scannedNumber, warningDataBoxClass);

  if (renderType === "PARCEL") {
    return (
      <div
        className={`warning-notification-container flex-column justify-content-center align-items-center ${warningDataBoxClass} ${highlightBackgroundClass}`}
      >
        <div className="d-flex justify-content-around">
          <div className="text-center">
            <span className={`notification-incoterm ${incotermClass} ${dduHighlightText}`}>
              {successNotificationData.incoterm}
            </span>
            <span className={`notification-incoterm ${incotermClass}`}>&nbsp;|&nbsp;</span>
            <span className={`notification-high-value ${highValueErrorClass} ${highValueHighlightText}`}>
              HIGH VALUE{" "}
            </span>
            <span className={`notification-high-value ${highValueErrorClass}`}>&nbsp;|&nbsp;</span>
            <span className={"notification-payment-type text-uppercase " + paymentTypeClass}>
              {successNotificationData.paymentType}&nbsp;|&nbsp;
            </span>
            <span className="notification-country">{successNotificationData.consigneeCountry}</span>
            <span className={"notification-payment-type text-uppercase " + uaReturnsClass}>
              &nbsp;|&nbsp;UA-RETURNS
            </span>
          </div>
        </div>
        <span className={"notification-postal-code " + isHeldAtCustomOrCancelledClass}>
          {successNotificationData.consigneePostalPreFix}
          {successNotificationData.source &&
            successNotificationData.source === "cainiao_lazada" &&
            successNotificationData.consigneePostalPreFix === "50" &&
            "(CJ)"}
          {successNotificationData.source &&
            successNotificationData.source === "cainiao_lazada" &&
            successNotificationData.consigneePostalPreFix === "60" &&
            "(NV)"}
        </span>
        <span className={"notification-custom-check " + notificationCustomClass}>Requires Custom Check</span>
        <span className={"notification-custom-check " + notificationCancelledClass}>Cancelled By Customer</span>
        <span className={"notification-custom-check " + notificationReturnToSenderClass}>Return To Sender</span>
        <span className={"notification-state-city " + isHeldAtCustomOrCancelledClass}>
          {successNotificationData.consigneeCity}
          {successNotificationData.consigneeCity && successNotificationData.consigneeCity !== "" && ", "}
          {successNotificationData.consigneeState}
        </span>
        <span className={"notification-shipper-name " + isHeldAtCustomOrCancelledClass}>
          {successNotificationData.partner_assigned}
        </span>
        <span className="notification-scan-number">{scannedNumber}</span>
      </div>
    );
  }
  if (renderType === "PROHIBIT") {
    return (
      <div className={`warning-notification-container flex-column justify-content-center align-items-center d-flex`}>
        <div className="d-flex justify-content-around">
          <div className="text-center">
            <div className="parcel-success-notification-50-bold text-uppercase text-white mb-3">
              remove prohibited parcel
            </div>
          </div>
        </div>
        <span className="notification-scan-number">{scannedNumber}</span>
      </div>
    );
  }
  return null;
};
