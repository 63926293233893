import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { shipmentActions } from "../actions/shipmentActions";

export const useGetRecipientsData = () => {
  const { partners } = useSelector((state) => ({
    partners: state.shipment.partners,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(shipmentActions.fetchRecipients());
  }, []);

  useEffect(() => {
    if (partners) {
      partners.forEach((recipient) => {
        recipient.label = recipient.name;
        recipient.value = recipient.name;
      });
    }
  }, [partners]);

  return partners;
};
