import React from 'react';
import { LinkContainer } from "react-router-bootstrap";

export default function BreadCrumb(props) {
  const {
    pageName,
    scanType,
    route
  } = props;

  return (
    <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
      {getLinkContainer()}
      <p className="bread-crumb mt-n1 mb-0">{'>'}</p>
      <p className="title text-capitalize mb-0">{scanType}</p>
    </div>
  )

  function getLinkContainer() {
    if (route) {
      return (
        <LinkContainer to={route}>
          <p className="link-title mb-0">{pageName}</p>
        </LinkContainer>
      )
    }
    return (
      <p className="title mb-0">{pageName}</p>
    )
  }
}
