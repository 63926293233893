import React, { useState } from 'react';
import { Form, Input, Switch } from 'antd';
import ZebraScanner from '../../../common/zebraScanner/ZebraScanner';

export default function RemoveCargoFromCargo(props) {
  let {
    newCargoTrackingData,
    removeCargoError,
    cargoNumbersList,
    removeCargoFromParentRef,
    cargoNumber,
    removeCargoSuccess
  } = props;

  const [showCameraToScan, setShowCameraToScan] = useState(false);

  return (
    <div className="mb-2">
      <div className="content-container card mt-2 p-2">
        <div className="d-flex flex-column">
          <div className="d-flex flex-column align-items-center mt-2">
            <h4 className="add-cargo-label">Remove Cargo</h4>
            <h5 className="add-cargo-label">
              Cargo ({newCargoTrackingData.cargo_type}) to {newCargoTrackingData.dest_country}
            </h5>
            <h5 className="add-cargo-label shipment-link-color pointer" onClick={props.showRemoveCargoPage}>
              {newCargoTrackingData && newCargoTrackingData.cargo_tracking_no}
            </h5>
          </div>
        </div>
      </div>
      {
        removeCargoError ? 
        <div id="error-data-box" className="content-container card mb-1 error-data-container">
          <div className="d-block justify-content-between px-3.5 scan-info-screen scan-error-info">
            <div id="err-image" className="err-img err-no-data">
              &nbsp;
            </div>
            <label className="err-title pl-5 pr-5">
              {removeCargoError.scanned_tracking_no && removeCargoError.scanned_tracking_no[0]}
              {removeCargoError.cargo_tracking_no && removeCargoError.cargo_tracking_no[0]}
            </label>
          </div>
        </div> 
      : removeCargoSuccess ? 
        (
          <div id="valid-data-box" className="content-container card mb-1 valid-sort-container w-100">
            <div className="d-flex flex-column justify-content-center align-items-center px-3.5 scan-info-screen">
            <h4 className="font-weight-bold text-white">
              {removeCargoSuccess.scanned_tracking_no}
            </h4>
            <h4 className="font-weight-bold text-white">
              Successfully Removed!
            </h4>
            </div>
          </div>
        ) : (
          <div id="no-data-box" className="content-container card mb-1">
            <div className="d-block justify-content-between px-3.5 scan-info-screen scan-no-info">
              <label style={{ marginTop: "95px" }}>No data shown here</label>
            </div>
          </div>
        )
      }
      <div className="content-container card mb-1 p-2">
        {showCameraToScan ?
          <ZebraScanner onScanComplete={onScanComplete} /> 
        :
          <Form ref={removeCargoFromParentRef} name="control-ref" onFinish={props.removeCargoFromParent}>
            <div className="d-flex flex-nowrap px-3.5 m-2 remove-cargo-container">
              <Form.Item
                name="cargoNumber"
                rules={[{ required: true, message: 'This field may not be blank' }]}
              >
                <Input
                  name="cargoNumber"
                  placeholder="Tracking No(s)"
                  onChange={props.handleChangeCargoNumber}
                  value={cargoNumber}
                  autoFocus
                />
              </Form.Item>
              <div>
                <button
                  type="submit"
                  className="ml-1 btn btn-janio text-white"
                >
                  Remove Cargo
                </button>
              </div>
            </div>
          </Form>
        }

        <div className="form-group d-block justify-content-between px-3.5 m-2">
          <b>Laser</b> <Switch className="scanner-switch" onChange={onChangeSwitch} /> <b>Camera</b>
        </div>  

      </div>
      {
        cargoNumbersList && cargoNumbersList.length > 0 && (
          <React.Fragment>
            <div className="content-container card w-100 cargo-bg text-dark text-center pt-2">
              <h5 className="added-to-cargo-text">Removed From This Cargo</h5>
            </div>
            <div className="content-container card mb-1 p-2">
              <div className="d-flex flex-column align-items-center mb-2 p-3">
                {cargoNumbersList.map((number, index) => {
                  return (
                    <h6 key={index} className="cargo-tracking-number mb-3">
                      {number}
                    </h6>
                  )
                })}
              </div>
            </div>
          </React.Fragment>
        )
      }
    </div>
  );

  function onChangeSwitch(checked) {
    setShowCameraToScan(checked)
  }

  function onScanComplete(scanResult) {
    let trackingNumber = scanResult.text;
    props.setRemoveCargoNumber(trackingNumber);
  }
}