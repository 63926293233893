import React from "react";
import { Input } from "antd";
import "./style.scss";

export default function input({
  label,
  className,
  textAreaClass,
  ...otherProps
}) {
  let input = <Input className="input-field" {...otherProps} />;
  if (otherProps.type === "textarea") {
    input = (
      <Input.TextArea
        className={`input-field ${textAreaClass}`}
        {...otherProps}
      />
    );
  }
  return (
    <div className={`custom-input ${className}`}>
      {label && <h6 className="form-label">{label}</h6>}
      {input}
    </div>
  );
}
