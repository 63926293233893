export const SCAN_PICKUP = "scan_pickup";
export const SCAN_DELIVER = "scan_deliver";
export const SCAN_RECEIVE = "scan_receive";
export const SCAN_TRANSFER = "scan_transfer";
export const SCAN_SUCCESS = "scan_success";
export const SCAN_FAIL = "scan_fail";
export const SCAN_RESET = "scan_reset";

export const FETCH_PICKUP_DELIVER_ADDRESSES = "fecth_pickup_deliver_addresses";

export const GET_LABEL_LINK = "get_label_link";

export const MANIFEST_CHECK_SUCCESS = "manifest_check_success";
export const MANIFEST_CHECK_FAIL = "manifest_check_fail";
export const MANIFEST_DELETE = "MANIFEST_DELETE";
export const MANIFEST_DELETE_ALL = "MANIFEST_DELETE_ALL";
export const HMS_LOGIN_SUCCESS = "HMS_LOGIN_SUCCESS";
export const HMS_LOGIN_FAIL = "HMS_LOGIN_FAIL";

export const SAVE_PARCEL_SCAN_EXCEPTION = "SAVE_PARCEL_SCAN_EXCEPTION";
export const CLEAR_PARCEL_SCAN_EXCEPTION = "CLEAR_PARCEL_SCAN_EXCEPTION"; 