import { LoadingOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Select } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Input from "../../../common/input/input";
import BreadCrumb from "../commonComponents/BreadCrumb";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { parcelActions } from "../../../actions/rootActions";
import { isEmpty } from "lodash";

const { Option } = Select;

function AddResource(props) {
  const [resourceData, setResourceData] = useState({
    resourceId: null,
    resourceQuantity: null,
    autoAddResource: false,
    addResourceError: undefined,
  });

  const [resources, setResources] = useState([]);
  const [usedResources, setUsedResources] = useState([]);

  useEffect(() => {
    // dispatch action to fetch resource names
    props.dispatch(
      parcelActions.getResourceDetails({
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
      })
    );
  }, []);

  useEffect(() => {
    if (props.resources && props.resources.length > 0) {
      console.log(props.resources);
      const newResources = props.resources.map((resource) => ({
        label: resource.resource_name,
        value: resource.id,
      }));
      setResources(newResources);
    }
  }, [props.resources]);

  const inputClass = resourceData.addResourceError ? "is-invalid" : "";

  const scanType = props.location.state.scanType;
  const pageName = props.location.state.pageName;
  const route = props.location.state.route;

  useEffect(() => {
    if (!props.addResourceLoading && props.addResourceSuccess) {
      let params = {
        hub_id: props.scannerInformation.hubId,
        hubscanner_key: props.scannerInformation.userId,
        tracking_no: props.latestScannedNumber.trim(),
        scan_type: scanType,
      };
      props.dispatch(parcelActions.onFetchParcelInfo(params, props.exceptionTypes, true, props.scannedNumbersList));
    }
  }, [props.addResourceLoading, props.addResourceSuccess]);

  useEffect(() => {
    if (!isEmpty(props.parcelOrderData) && !isEmpty(props.parcelOrderData.resourceUsage)) {
      setUsedResources(props.parcelOrderData.resourceUsage);
    }
  }, [props.parcelOrderData]);

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  function addOrDeleteResource(operation, resourceName, quantity) {
    let resourceId = resourceData.resourceId;
    if (resourceName) {
      console.log(resources.filter((r) => r.label === resourceName));
      resourceId = resources.filter((r) => r.label === resourceName)[0].value;
    }

    props.dispatch(
      parcelActions.addResourceToTN({
        hub_id: localStorage.getItem("hubId"),
        hubscanner_key: localStorage.getItem("userId"),
        tracking_nos: [props.latestScannedNumber.trim()],
        resource_id: resourceId,
        times_used: operation === "delete" ? Number(quantity) * -1 : Number(resourceData.resourceQuantity),
        autoAddResource: resourceData.autoAddResource,
      })
    );
    setResourceData({
      ...resourceData,
      resourceId: null,
      resourceQuantity: null,
      autoAddResource: false,
      addResourceError: undefined,
    });
  }

  function onClickAutoAddResourceCheck(name, value) {
    setResourceData({
      ...resourceData,
      [name]: value,
    });
  }

  function onChangeResourceQty(e) {
    setResourceData({
      ...resourceData,
      resourceQuantity: e.target.value,
    });
  }

  function onChangeResourceName(value) {
    setResourceData({
      ...resourceData,
      resourceId: value,
    });
  }

  return (
    <div className="parcel-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container location-container mt-0 w-100">
          <BreadCrumb pageName={pageName} scanType={scanType} route={route} />
          <div className="bg-container-body">
            <div className="d-flex flex-column">
              <h2 className="text-heading-black margin-bottom-20">Add Resource for {props.latestScannedNumber}</h2>
              <div className="d-flex location-input-checkbox-group">
                <div className="inputbox-mr-4 w-100">
                  <h6 className="form-label">Name</h6>
                  <Select
                    className="w-100"
                    onChange={(value) => onChangeResourceName(value)}
                    value={resourceData.resourceId}
                  >
                    {resources.length > 0 &&
                      resources.map((resource) => {
                        return <Option value={resource.value}>{resource.label}</Option>;
                      })}
                  </Select>
                </div>
                <div className="inputbox-mr-4 w-100">
                  <Input
                    className={inputClass}
                    label="Quantity"
                    name="quantity"
                    placeholder="Type here"
                    autoFocus
                    value={resourceData.resourceQuantity}
                    onKeyUP={() => {}}
                    onChange={(e) => onChangeResourceQty(e)}
                    type="number"
                  />
                  {resourceData.addResourceError && (
                    <span className="text-danger">{resourceData.addResourceError}</span>
                  )}
                </div>
              </div>
              <div className="checkbox-w-60 w-100">
                <Checkbox
                  className="mt-3"
                  checked={resourceData.autoAddResource}
                  onChange={(e) => onClickAutoAddResourceCheck("autoAddResource", e.target.checked)}
                >
                  <span className="text-span-black">Add this resource automatically to newly scanned packages</span>
                </Checkbox>
              </div>
              <Divider className="gray-divider" />
              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button className="secondary-button-small" onClick={goBackToPreviousRoute}>
                  Cancel
                </button>
                <button
                  className="primary-button mb-sm-0 mb-3"
                  disabled={props.addResourceLoading}
                  onClick={addOrDeleteResource}
                >
                  {props.addResourceLoading ? (
                    <LoadingOutlined className="loading-spinner loading-spinner-white" />
                  ) : (
                    "Add Resource"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="bg-container-body mt-4">
            <h2 className="text-heading-black margin-bottom-20">Current Resource(s)</h2>
            <div className="table-responsive">
              <table className="table table-striped table-borderless">
                <thead className="table-header">
                  <tr>
                    <td className="cursor-pointer">Name</td>
                    <td className="cursor-pointer">Quantity</td>
                  </tr>
                </thead>
                <tbody>
                  {usedResources.length > 0 &&
                    usedResources.map((resource) => {
                      if (resource.times_used) {
                        return (
                          <tr>
                            <td className="text-span-black">{resource.resource_name}</td>
                            <td className="text-span-black">
                              {resource.times_used}
                              {/* TODO: change once api is ready. For now sending negative value to delete the resource */}
                              <FontAwesomeIcon
                                className="pointer ml-2 float-right"
                                size="md"
                                icon={faTrashAlt}
                                onClick={() =>
                                  addOrDeleteResource("delete", resource.resource_name, resource.times_used)
                                }
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = ({ common, parcel, auth }) => ({
  scannerInformation: common.scannerInformation,
  addResourceLoading: parcel.addResourceLoading,
  latestScannedNumber: parcel.latestScannedNumber,
  sessionId: auth.sessionId,
  resources: parcel.resources,
  addResourceSuccess: parcel.addResourceSuccess,
  exceptionTypes: common.exceptionTypes,
  parcelOrderData: parcel.parcelOrderData,
  scannedNumbersList: common.scannedNumbersList,
});

export default connect(mapStateToProps)(AddResource);
