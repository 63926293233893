import { commonTypes } from "../types";

const initialState = {
  scannedNumbersList: [],
  scannerInformation: {},
  voiceUtilsData: {},
  printer: null,

  exceptionTypesLoading: false,
  exceptionTypes: [],
  exceptionTypesError: null,

  recipientsListLoading: false,
  recipientsList: [],
  recipientsListError: null,

  shipmentsLoading: false,
  shipments: [],
  shipmentsError: null
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case commonTypes.SET_SCANNED_NUMBERS_LIST:
      return { ...state, scannedNumbersList: action.payload };
    
    case commonTypes.CLEAR_SCANNED_NUMBERS_LIST_DATA:
      return { ...state, scannedNumbersList: [] };

    case commonTypes.SET_SCANNER_INFORMATION:
      return { ...state, scannerInformation: action.payload };

    case commonTypes.SET_PRINTER:
      return { ...state, printer: action.payload };

    case commonTypes.SET_VOICE_TYPE:
      return { ...state, voiceUtilsData: action.payload };

    case commonTypes.GET_EXCEPTIONS_TYPES_LOADING:
      return { ...state, exceptionTypesLoading: true, exceptionTypes: [] };
    case commonTypes.GET_EXCEPTIONS_TYPES_SUCCESS:
      return { ...state, exceptionTypesLoading: false, exceptionTypes: action.payload, exceptionTypesError: null };
    case commonTypes.GET_EXCEPTIONS_TYPES_FAILURE:
      return { ...state, exceptionTypesLoading: false, exceptionTypes: [], exceptionTypesError: action.payload };

    case commonTypes.GET_RECIPIENTS_LIST_LOADING:
      return { ...state, recipientsListLoading: true, recipientsList: [] };
    case commonTypes.GET_RECIPIENTS_LIST_SUCCESS:
      return { ...state, recipientsListLoading: false, recipientsList: action.payload, recipientsListError: null };
    case commonTypes.GET_RECIPIENTS_LIST_FAILURE:
      return { ...state, recipientsListLoading: false, recipientsList: [], recipientsListError: action.payload };

    case commonTypes.GET_SHIPMENT_NUMBERS_LIST_LOADING:
      return { ...state, shipmentsLoading: true, shipments: [] };
    case commonTypes.GET_SHIPMENT_NUMBERS_LIST_SUCCESS:
      return { ...state, shipmentsLoading: false, shipments: action.payload, shipmentsError: null };
    case commonTypes.GET_SHIPMENT_NUMBERS_LIST_FAILURE:
      return { ...state, shipmentsLoading: false, shipments: [], shipmentsError: action.payload };

    default:
      return state;
  }
}

export { commonReducer }