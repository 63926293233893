// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import * as _ from "lodash";
import "./style.scss";
import { parcelActions } from "../../../actions/rootActions";
import { connect } from "react-redux";
import { Divider, Modal } from "antd";
import { Prompt } from "react-router";
import { mainContent } from "./ComponentRenderer";
import { useInitialize } from "../../../customHooks/parcelScannerHooks";
import { useFetchParcel } from "../../../customHooks/useFetchParcel";
import { useUpdateTracker } from "../../../customHooks/useParcelUpdateTracker";
import { useRedeliverScan } from "../../../customHooks/useParcelRedeliverScanHooks";
import { useDisposalOrder, useDisposalScan } from "../../../customHooks/useParcelDisposalScanHooks";
import { useBeforeUnloadHook } from "../../../customHooks/useBeforeUnloadHook";
import { commonTypes } from "../../../types";

export const ParcelScannerContext = React.createContext(null);

function HmsParcelScanner(props) {
  const { scannerInformation, exceptionTypes, printer, selectedNetworkPartner, scanSettings, sessionId } = props;

  const scanNumberInputRef = useRef(null);

  const [state, setState] = useState({
    scanType: props.location.state.scanType,
    groupId: "",
    tag: "",
    scanNumber: "",
    sortType: undefined,
  });

  const [autoPrintLabel, downloadLabel] = useInitialize({
    scanType: props.location.state.scanType,
  });

  const [printLabel, qzTrayDownloadError, qzTraySuccess] = useFetchParcel({
    downloadLabel,
    autoPrintLabel,
    networkPartner: props.location.state.networkPartner,
    scanType: props.location.state.scanType,
    state,
    setState,
  });

  const [disposalConfirmationModal, setDisposalConfirmationModalDisplay] = useState(false);

  const [isOpen, setIsOpen] = useState(localStorage.getItem("isOpen"));
  const [isPackageDisposed] = useDisposalOrder({ state, setState });

  useRedeliverScan({ state, setState });
  useDisposalScan({ state, setState });
  useUpdateTracker({ state, setState });
  useBeforeUnloadHook({ scanType: state.scanType });

  useEffect(() => {
    if (selectedNetworkPartner) {
      let scanSettingsCopy = { ...scanSettings };
      scanSettingsCopy.recipientId = selectedNetworkPartner;
      props.dispatch(parcelActions.onSetNetworkPartner(scanSettingsCopy));
    }
  }, [selectedNetworkPartner]);

  useEffect(() => {
    if (props.addExceptionSuccess) {
      const autoAdd = true;
      fetchOrderInfo(props.latestScannedNumber, autoAdd);
      props.dispatch(parcelActions.clearAddExceptionData());
    }
  }, [props.addExceptionSuccess]);

  useEffect(() => {
    if (props.addLocationSuccess) {
      const autoAdd = true;
      fetchOrderInfo(props.latestScannedNumber, autoAdd);
      props.dispatch(parcelActions.clearAddLocationData());
    }
  }, [props.addLocationSuccess]);

  const locationScanType = props.location.state.scanType;
  const networkPartner = props.location.state.networkPartner;

  return (
    <React.Fragment>
      <Prompt
        when={state.scanType === "disposal" && props.disposalScannedNumbersList.length > 0}
        message="Changes you made may not be saved."
      />
      <ParcelScannerContext.Provider
        value={{
          locationScanType,
          networkPartner,
          scanType: state.scanType,
          latestScannedNumber: props.latestScannedNumber,
          showDisposalConfirmationModal,
          disposalScannedNumbersList: props.disposalScannedNumbersList,
          errorNotificationData: props.errorNotificationData,
          orderData: props.orderData,
          exceptionTypes,
          successNotificationData: props.successNotificationData,
          scanNumberInputRef,
          scannedNumber: state.scanNumber,
          onChangeScanNumberChange,
          onChangeScanNumberCameraChange,
          printLabel,
          notificationBoxType: props.notificationBoxType,
          qzTraySuccess,
          qzTrayDownloadError,
          isPackageDisposed,
          scannedNumbersList: props.scannedNumbersList,
          scanSettings,
          getExceptionTag,
          getAddedLocation,
          scannerInformation,
          downloadExceptionImage,
          isOpen,
          setIsOpen,
          printer,
          fetchOrderInfo,
          parcelScanExceptions: props.parcelScanExceptions,
        }}
      >
        <div className="parcel-bg-container">
          {mainContent(
            locationScanType,
            networkPartner,
            state.scanType,
            props.latestScannedNumber,
            props.orderData,
            scannerInformation,
            downloadExceptionImage,
            fetchOrderInfo
          )}
        </div>
      </ParcelScannerContext.Provider>

      <Modal
        className="remedy-modal"
        visible={disposalConfirmationModal}
        footer={null}
        okText="Confirm"
        okButtonProps={{ className: "remedy-modal-primary-button" }}
        cancelButtonProps={{ className: "remedy-modal-secondary-button" }}
        cancelText="Cancel"
      >
        <div className="text-left">
          <h2 className="remedy-heading mb-0">Please confirm package disposal</h2>
          <p className="remedy-paragraph mb-0">
            {props.disposalScannedNumbersList.length} Package(s) will be disposed.
          </p>
          <Divider className="gray-divider" />
        </div>
        <div className="text-center">
          <button className="remedy-modal-secondary-button" onClick={hideDisposalConfirmationModal}>
            Cancel
          </button>
          <button className="remedy-modal-primary-button" onClick={disposeParcel}>
            Confirm
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );

  function getExceptionTag() {
    if (!_.isEmpty(props.exceptionData)) {
      return props.exceptionData.exception_type;
    }
    return "";
  }

  function getAddedLocation() {
    if (!_.isEmpty(props.locationData)) {
      return props.locationData.location;
    }
    return "";
  }

  function onChangeScanNumberCameraChange(scanResult) {
    const scannedNumber = scanResult.text;
    setState({
      ...state,
      scanNumber: scannedNumber,
    });
    onScan(scannedNumber);
  }

  function showDisposalConfirmationModal() {
    setDisposalConfirmationModalDisplay(true);
  }

  function hideDisposalConfirmationModal() {
    setDisposalConfirmationModalDisplay(false);
  }

  function onChangeScanNumberChange(e) {
    const { value } = e.target;
    const { keyCode, which } = e;
    if (keyCode === 13 || which === 13) {
      if (locationScanType === "disposal") {
        fetchDisposalOrderInfo(state.scanNumber);
        setState({
          ...state,
          scanNumber: "",
        });
        return;
      }
      onScan();
      return;
    }
    setState({
      ...state,
      scanNumber: value,
    });
  }

  function downloadExceptionImage(photoUrl) {
    let fileName = "image." + photoUrl.substr(photoUrl.length - 4);
    fetch(photoUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      });
    });
  }

  function getParams(trackingNumber) {
    let params = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      tracking_no: trackingNumber.trim(),
      scan_type: state.scanType,
    };
    return params;
  }

  // get order details
  function fetchOrderInfo(trackingNumber, autoAdd) {
    const params = getParams(trackingNumber);
    props.dispatch(parcelActions.onFetchParcelInfo(params, exceptionTypes, autoAdd, props.scannedNumbersList));
  }

  function onScan(scanNumber) {
    let scannedNumber = scanNumber ? scanNumber : state.scanNumber;
    if (scannedNumber === "") return;
    // const duplicateScan = duplicateSweepScan(scannedNumber)
    if (state.scanType === "redeliver") {
      redeliverScan(scannedNumber);
    } else if (state.scanType === "labelless package") {
      let re1 = /!![A-Z]{5}!!/;
      let isValidQR = re1.test(scannedNumber);
      if (isValidQR) {
        props.dispatch(
          parcelActions.scanLabellessPackage(
            {
              print_tracking_no: scannedNumber.trim(),
              hub_id: scannerInformation.hubId,
              hubscanner_key: scannerInformation.userId,
            },
            props.scannedNumbersList
          )
        );
      } else {
        let re2 = /PND-JA-[A-Za-z0-9]/;
        let isPNDTN = re2.test(scannedNumber);
        if (props.scannedNumbersList.includes(scannedNumber)) {
          return;
        }
        if (isPNDTN) {
          // FIXME: patch - adding the TN because process order details api isn't called which is responsible for adding the TN to list.
          const numbersList = [...props.scannedNumbersList, scannedNumber];
          props.dispatch({
            type: commonTypes.SET_SCANNED_NUMBERS_LIST,
            payload: numbersList,
          });
          scannedNumber = scannedNumber.substr(scannedNumber.lastIndexOf("-") + 1);
          parcelScan(scannedNumber);
        }
      }
    } else {
      parcelScan(scannedNumber);
    }
  }

  async function parcelScan(scannedNumber) {
    fetchOrderInfo(scannedNumber);
  }

  function redeliverScan(scannedNumber) {
    let trackingNumbersList = [...props.scannedNumbersList];
    const params = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      session_id: sessionId,
      tracking_no: scannedNumber.trim(),
    };
    props.dispatch(parcelActions.onRedeliverScan(params, trackingNumbersList));
  }

  async function disposeParcel() {
    // call the dispose api
    hideDisposalConfirmationModal();
    const params = {
      hub_id: scannerInformation.hubId,
      hubscanner_key: scannerInformation.userId,
      scan_type: "sweep",
      session_id: sessionId,
      orders: props.disposalScannedNumbersList.map((scanNumber) => ({
        tracking_no: scanNumber.trim(),
      })),
    };
    props.dispatch(parcelActions.onDisposeParcel(params, props.scannedNumbersList, props.disposalScannedNumbersList));
  }

  async function fetchDisposalOrderInfo(trackingNumber) {
    const params = getParams(trackingNumber);
    props.dispatch(parcelActions.onGetDisposalOrderDetails(params, props.disposalScannedNumbersList));
  }
}

const mapStateToProps = ({ parcel, common, auth, scan }) => ({
  latestScannedNumber: parcel.latestScannedNumber,
  scannedNumbersList: common.scannedNumbersList,
  orderData: parcel.parcelOrderData,
  successNotificationData: parcel.successNotificationData,
  errorNotificationData: parcel.errorNotificationData,
  notificationBoxType: parcel.notificationBoxType,
  scannerInformation: common.scannerInformation,
  exceptionTypes: common.exceptionTypes,
  printer: common.printer,
  scanSettings: parcel.scanSettingsData,
  selectedNetworkPartner: parcel.selectedNetworkPartner,
  disposalScannedNumbersList: parcel.disposalScannedNumbersList,
  exceptionData: parcel.exceptionData,
  addExceptionSuccess: parcel.addExceptionSuccess,
  addLocationSuccess: parcel.addLocationSuccess,
  locationData: parcel.locationData,
  resolveExceptionSuccess: parcel.resolveExceptionSuccess,
  sessionId: auth.sessionId,
  parcelScanExceptions: scan.exceptions,
});

export default connect(mapStateToProps, null)(HmsParcelScanner);
