import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from '../Header';
import RedirectFirstmile from './RedirectFirstmile';
import ScannerPage from '../ScannerPage';

export default function HMSlite() {
  return (
    <Header>
      <Switch>
        <Route path="/scan/firstmile/main" component={RedirectFirstmile} />
        <Route path="/scan/firstmile/deliver" component={ScannerPage} />
        <Route path="/scan/firstmile/pickup" component={ScannerPage} />
      </Switch>
    </Header>
  )
}