import React from "react";
import ZebraScanner from "../../../common/zebraScanner/ZebraScanner";

export default function TrolleyNotificationArea(props) {
  const {
    noDataBoxClass,
    errorDataBoxClass,
    errorNotificationData,
    validDataBoxClass,
    latestScannedNumber,
    scanNumberInputRef,
    scanNumber,
    orderData,
  } = props;

  const defaultScanner = localStorage.getItem("defaultScanner");

  return (
    <React.Fragment>
      <div className={`empty-notification-container justify-content-center align-items-center ${noDataBoxClass}`}>
        <span className="empty-notification-text mb-0">You will see the scan results here.</span>
      </div>

      <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
        <div id="err-image" className="err-img err-no-data">
          &nbsp;
        </div>
        <span className="error-notification-text mb-0 text-center text-capitalize">
          {errorNotificationData.errorMessage}
        </span>
        {errorNotificationData.jsonBody && (
          <span className="json-notification-text">{errorNotificationData.jsonBody}</span>
        )}
      </div>

      <div
        className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
      >
        <h2 className="success-notification-25-bold">
          {orderData && orderData.tracking_nos && orderData.tracking_nos.length} PARCELS IN TROLLEY
        </h2>
        <span className="success-notification-40-bold">TROLLEY SCANNED</span>
        <span className="success-notification-20">{latestScannedNumber}</span>
      </div>

      {defaultScanner === "Camera" && (
        <div>
          <ZebraScanner onScanComplete={(scanResult) => props.onChangeScanNumberCameraChange(scanResult)} />
        </div>
      )}

      {showScanInputField() && (
        <div className="d-flex align-items-center scanInputContainer">
          <h2 className="text-heading-black mr-3">Scan</h2>
          <input
            ref={scanNumberInputRef}
            className="form-control scan-input"
            placeholder="Click here or paste tracking code to scan"
            value={scanNumber}
            onChange={props.onChangeScanNumberChange}
            onKeyUp={props.onChangeScanNumberChange}
            autoFocus={true}
          />
        </div>
      )}
    </React.Fragment>
  );

  function showScanInputField() {
    return (
      (defaultScanner === null || defaultScanner === undefined || defaultScanner === "Laser") &&
      defaultScanner !== "Camera"
    );
  }
}
