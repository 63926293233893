// Events
export const EVENT_LOGIN_SUCCESS = "Login Page - Login Success";
export const EVENT_LOGIN_FAILED = "Login Page - Login Failed";
export const VISIT_PARCEL_SCAN_PAGE = "Parcel Scan Page";
export const VISIT_CARGO_SCAN_PAGE = "Cargo Scan Page";
export const VISIT_SHIPMENTS_PAGE = "Shipments Page";
export const VISIT_REPORTS_PAGE = "Reports Page";
export const SETTINGS_CHANGED_SUCCESS = "Settings Page - Settings Change Success";
export const SETTINGS_CHANGED_FAILED = "Settings Page - Settings Change Failed";
export const PARCEL_SCAN_TYPE_PAGE = "Parcel Scan Type Page";
export const CARGO_SCAN_TYPE_PAGE = "Cargo Scan Type Page";
export const SHIPMENT_DETAILS_PAGE = "Shipment Details Page";
export const CARGO_DETAILS_PAGE = "Cargo Details Page";
export const SHIPMENTS_FILTERS_CHANGE = "Shipments Filter Change";
export const CREATE_SHIPMENT = "Create Shipment";
export const CREATE_CARGO = "Create Cargo";
export const REMOVE_CARGO = "Remove Cargo";
export const DOWNLOAD_MANIFEST = "Download Manifest";
export const TAG_LOCATION_PARCEL = "Tag Location Parcel";
export const PRINT_CARGO_LABEL = "Print Cargo Label";
export const DOWNLOAD_REPORT = "Download Report";
export const SCAN_CARGO_TRACKING_NUMBER = "Scan Cargo Tracking Number";
export const QZ_TRAY_CONNECTION = "QZ Tray Connection";
export const PARCEL_SCAN = "Parcel Scan";
export const ADD_EXCEPTION = "Add Exception";
export const ADD_CARGO_TO_CARGO = "Add Cargo To Cargo";
export const UPDATE_DIMWEIGHT = "Update Dimension-Weight";
export const AUTO_ADD_EXCEPTION = "Auto Add Exception";
export const VISIT_TROLLEY_SCAN_PAGE = "Visit trolley scan page"
export const VISIT_PICKLIST_PAGE = "Visit picklist page"
export const DOWNLOAD_PICK_LIST_CSV = "Download pick list csv"
export const PICK_LIST_SHOW_DROPDOWN = "Pick list show dropdown"
export const EDIT_SHIPMENT_DETAILS = "Edit shipment details"
export const CANCEL_SHIPMENT = "Cancel shipment"
export const ADD_LOCATION = "Add location"
export const ADD_RESOURCE = "Add resource"

// Data to store
export const SUCCESS = "Success";
export const ERROR = "Error";
export const PERFORMANCE = "Duration in ms";
export const CREATED_ON = "created_on";
export const HUB_ID = "Hub Name";
export const HUB_SCANNER_NAME = "Hub Scanner Name";
export const HUB_SCANNER_ID = "Hub Scanner ID";
export const PARCEL_TRACKING_NUMBER = "Parcel Tracking Number";
export const CARGO_TRACKING_NUMBER = "Cargo Tracking Number";
export const SHIPMENT_TRACKING_NUMBER = "Shipment Tracking Number";
export const SCAN_TYPES = "Scan Types";
export const USER_ID = "User ID";
export const SETTINGS = "Settings";
export const DEFAULT_SCANNER = "Default Scanner";
export const DEFAULT_AUDIO_TYPE = "Default Audio Type";
export const REQUIRE_ERROR_ACKNOWLEDGEMENT = "Require Error Acknowledgement"
export const AUTO_PRINT_LABEL = "Auto Print Label";
export const SHOW_PRINT_LABEL = "Show Print Label";
export const DEFAULT_PRINTER = "Default Printer";
export const PAGE_SCAN_TYPE = "Page Scan Type";
export const FILTER_NAME = "Filter Name";
export const FILTER_VALUE = "Filter Value";
export const RECIPIENT_ID = "Recipient ID";
export const RECIPIENT_NAME = "Recipient Name";
export const CARGO_TYPE = "Cargo Type";
export const DEST_COUNTRY_CODE = "Destination Country Code";
export const DEST_POSTCODE_PREFIX = "Destination Post Code Prefix";
export const IS_DEMINIMIS = "Is Deminims";
export const LM_RECIPIENT_ID = "LM Recipient Id";
export const LOCATION_DATA = "Location Data";
export const DATE_FROM = "Date From";
export const DATE_TO = "Date To";
export const SCAN_TYPE = "Scan Type";
export const EXCEPTION_DATA = "Exception Data";
export const SCANNED_NUMBER = "Scanned Number";
export const DIMENSION_DATA = "Dimension Data";
export const ERROR_TYPE = "Error Type";
export const SHOW_DROPDOWN_VALUE = "Show dropdown value"
export const PAYLOAD = "Payload"