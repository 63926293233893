import React from "react";
import { Select } from "antd";
import "./style.scss";

const { Option } = Select;

function select(props) {
  const {
    label,
    placeholder,
    value,
    onChange,
    disabled,
    options,
    addEmptyOption,
    addEmptyOptionLabel,
    className,
  } = props;

  function onFilter(input, option) {
    return option.children.toLowerCase().match(`^${input.toLowerCase()}`);
  }

  return (
    <React.Fragment>
      {label && <h6 className="form-label">{label}</h6>}
      <Select
        onChange={onChange}
        showSearch
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        className={"custom-select-style " + className}
        optionFilterProp="children"
        filterOption={onFilter}
      >
        {addEmptyOption && (
          <Option value={undefined}>{addEmptyOptionLabel}</Option>
        )}
        {options.map((option, index) => {
          return (
            <Option key={index} value={option.value}>
              {option.label}
            </Option>
          );
        })}
      </Select>
    </React.Fragment>
  );
}

export default select;

