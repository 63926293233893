import axios from "axios";
import { cargoApi, cargoScanApi } from "../api";
import { cargoTypes, commonTypes } from "../types";
import { isEmpty } from "lodash";
import { errorFormatter } from "../utils/errorFormatter";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const defaultToken = localStorage.getItem("token");

if (defaultToken) {
  axios.defaults.headers.common["Authorization"] = "Token " + defaultToken;
}

// add cargo to cargo
const createCargoToShipment = (data, scannedNumbersList) => {
  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });
  return function (dispatch) {
    dispatch({ type: cargoTypes.ADD_CARGO_LOADING });
    axios
      .put(cargoApi.ADD_TO_CARGO, data)
      .then((response) => {
        if (!scannedNumbersList.includes(data.cargo_tracking_no)) {
          const numbersList = [...scannedNumbersList, data.scanned_tracking_no];
          dispatch(setScannedNumbersList(numbersList));
        }
        dispatch({
          type: cargoTypes.ADD_CARGO,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: cargoTypes.ADD_CARGO_ERROR,
          payload: {
            errorData: err.data,
            jsonBody: err.status === 500 ? JSON.stringify(data) : null,
          },
        });
      });
  };
};

// add cargo to shipmennt
const addCargoToShipment = (data) => {
  return function (dispatch) {
    dispatch({ type: cargoTypes.ADD_CARGO_TO_SHIPMENT_LOADING });
    axios
      .put(cargoApi.ADD_CARGO_TO_SHIPMENT, data)
      .then((response) => {
        dispatch({
          type: cargoTypes.ADD_CARGO_TO_SHIPMENT,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: cargoTypes.ADD_CARGO_TO_SHIPMENT_ERROR,
          payload: {
            errorData: err.data,
            jsonBody: err.status === 500 ? JSON.stringify(data) : null,
          },
        });
      });
  };
};

const createCargo = (data) => {
  return function (dispatch) {
    dispatch({ type: cargoTypes.CREATE_CARGO_LOADING });
    axios
      .post(cargoApi.CREATE_CARGO, data)
      .then((response) => {
        dispatch({
          type: cargoTypes.CREATE_CARGO,
          payload: response.data,
        });
      })
      .catch((err) => {
        const data = errorFormatter(err.data);
        dispatch({
          type: cargoTypes.CREATE_CARGO_ERROR,
          payload: data,
        });
      });
  };
};

const removeCargoFromParent = (data, scannedNumbersList) => {
  const setScannedNumbersList = (payload) => ({
    type: commonTypes.SET_SCANNED_NUMBERS_LIST,
    payload,
  });
  return function (dispatch) {
    axios
      .put(cargoApi.REMOVE_FROM_CARGO, data)
      .then((response) => {
        if (!scannedNumbersList.includes(data.cargo_tracking_no)) {
          console.log(response, scannedNumbersList);
          const numbersList = [...scannedNumbersList, data.scanned_tracking_no];
          dispatch(setScannedNumbersList(numbersList));
        }
        dispatch({
          type: cargoTypes.REMOVE_CARGO_FROM_PARENT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: cargoTypes.REMOVE_CARGO_FROM_PARENT_ERROR,
          payload: {
            errorData: err.data,
            jsonBody: err.status === 500 ? JSON.stringify(data) : null,
          },
        });
      });
  };
};

const getCountriesList = () => {
  return function (dispatch) {
    axios
      .get(cargoApi.GET_COUNTRIES_LIST)
      .then((response) => {
        dispatch({
          type: cargoTypes.GET_COUNTRIES_LIST,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: cargoTypes.GET_COUNTRIES_ERROR,
          payload: err,
        });
      });
  };
};

const setCargoNumber = (cargoNumber) => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.SET_CARGO,
      payload: cargoNumber,
    });
  };
};

const clearCargoResponse = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_CARGO_RESPONSE,
    });
  };
};

const clearCargoState = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_CARGO_STATE,
    });
  };
};

const clearCreateCargoError = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_CREATE_CARGO_ERROR,
    });
  };
};

const clearCreateCargoResponse = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_CREATE_CARGO_RESPONSE,
    });
  };
};

const clearAddCargoToShipmentResponse = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_ADD_CARGO_TO_SHIPMENT_RESPONSE,
    });
  };
};

const cargoResetState = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CARGO_RESET_STATE,
    });
  };
};

const removeCargoResetState = () => {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_REMOVE_CARGO_STATE,
    });
  };
};

// Cargo Scan Actions
function getCargoDetails(reqPayload, scannedNumbersList) {
  return async function (dispatch) {
    try {
      if (scannedNumbersList && scannedNumbersList.includes(reqPayload.tracking_no)) {
        const errorMessage =
          reqPayload.scan_type === "cargo arrival"
            ? "BREAK CARGO"
            : reqPayload.scan_type === "cargo break"
            ? "INBOUND PARCELS"
            : `Invalid / Duplicate Scan ${reqPayload.tracking_no}`;
        throw new Error(errorMessage);
      }

      const res = await axios.get(cargoScanApi.GET_CARGO_DETAILS, reqPayload);
      if (res.status === 204) {
        const errorMessage = "Cargo Number Not Found";
        throw new Error(errorMessage);
      }

      const notificationBoxType = {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
      };

      const payload = {
        latestScannedNumber: res.data.tracking_no,
        cargoDetails: res.data,
        notificationBoxType,
      };

      dispatch({
        type: cargoTypes.GET_CARGO_DETAILS_SUCCESS,
        payload: payload,
      });
    } catch (err) {
      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
      };

      let errorMessage = null;
      if (typeof err.data.error === "string") {
        errorMessage = err.data.error;
      } else {
        errorMessage =
          err.data && err.data.tracking_no
            ? err.data.tracking_no.join(", ")
            : "Unknown Error, please screenshot and send to Tech Support.";
      }

      let errorNotificationData = {
        errorMessage: errorMessage,
        showTrackingNumber: reqPayload.tracking_no,
      };

      if (err.message) {
        errorMessage = err.message;
      }

      if (!err.message) {
        errorNotificationData.errorMessageHeading = "CARGO HAS ALREADY BEEN SCANNED";
      }

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(reqPayload);
      }

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData,
        cargoDetailsError: err,
      };

      dispatch({
        type: cargoTypes.GET_CARGO_DETAILS_ERROR,
        payload: payload,
      });
    }
  };
}

// using same action types as processScannedOrders since essentially it is a cargo.
function processScannedArrivalBreakOrders(reqPayload, cargoDetails, scannedNumbersList) {
  return async function (dispatch) {
    try {
      const res = await axios.post(cargoScanApi.PROCESS_SCANNED_ARRIVAL_BREAK_ORDERS, reqPayload);

      let successNotificationData = {};
      let warningNotificationData = {};

      const data = {
        ...cargoDetails,
        consigneeCountry: cargoDetails.dest_country,
        consigneePostalPreFix: cargoDetails.dest_poscode_prefix,
        cargoPostalCode: "",
        nextRecipient: cargoDetails.next_recipient,
      };

      // if (reqPayload.scan_type === "warehouse outbound") {
      //   setScanSettings({
      //     ...scanSettings,
      //     recipientId: data.group_id,
      //   });
      // }

      if (reqPayload.scan_type === "cargo arrival" || reqPayload.scan_type === "cargo break") {
        if (reqPayload.scan_type === "cargo break" && !isEmpty(cargoDetails.security_tag_info)) {
          warningNotificationData = {
            cargoMessage: "CARGO TRACKING NO. SCANNED",
            cargoDescription: "SCAN SECURITY TAG",
          };
        } else {
          // if security tag is not present is case of cargo break
          successNotificationData = {
            cargoMessage: reqPayload.scan_type === "cargo arrival" ? "CARGO RECEIVED" : "CARGO BREAK SUCCESSFUL",
            cargoDescription: reqPayload.scan_type === "cargo arrival" ? "CHECK CARGO" : "INBOUND PARCELS",
            security_tag_info: reqPayload.scan_type === "cargo break" && cargoDetails.security_tag_info,
          };
        }
      } else {
        successNotificationData = data;
      }

      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false,
      };

      const payload = {
        successNotificationData: successNotificationData,
        warningNotificationData: warningNotificationData,
        notificationBoxType: notificationBoxType,
        processedScannedCargo: res.data,
      };

      dispatch({
        type: cargoTypes.PROCESS_SCANNED_CARGOS_SUCESS,
        payload: payload,
      });

      const numbersList = [...scannedNumbersList, reqPayload.tracking_no];

      dispatch({
        type: commonTypes.SET_SCANNED_NUMBERS_LIST,
        payload: numbersList,
      });
    } catch (err) {
      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
      };

      let errorNotificationData = {};
      const error = err;
      if (
        error.data &&
        ((error.data.tracking_nos && error.data.tracking_nos[0]) ||
          (error.data.tracking_no && error.data.tracking_no[0]))
      ) {
        const errorMessage = error.data.tracking_nos ? error.data.tracking_nos[0] : error.data.tracking_no[0];
        errorNotificationData = {
          errorMessage: errorMessage,
          showTrackingNumber: reqPayload.tracking_no,
        };
      } else {
        errorNotificationData = {
          errorMessage: "Unknown Error, please screenshot and send to Tech Support.",
          showTrackingNumber: reqPayload.tracking_no,
        };
      }

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(reqPayload);
      }

      const payload = {
        errorNotificationData: errorNotificationData,
        notificationBoxType: notificationBoxType,
        processedScannedCargoError: err,
      };

      dispatch({
        type: cargoTypes.PROCESS_SCANNED_CARGOS_ERROR,
        payload: payload,
      });
    }
  };
}

function processScannedOrders(reqPayload, cargoDetails, scannedNumbersList) {
  return async function (dispatch) {
    try {
      const res = await axios.post(cargoScanApi.PROCESS_SCANNED_ORDERS, reqPayload);

      let successNotificationData = {};
      let warningNotificationData = {};

      const data = {
        ...cargoDetails,
        consigneeCountry: cargoDetails.dest_country,
        consigneePostalPreFix: cargoDetails.dest_poscode_prefix,
        cargoPostalCode: "",
        nextRecipient: cargoDetails.next_recipient,
      };

      // if (reqPayload.scan_type === "warehouse outbound") {
      //   setScanSettings({
      //     ...scanSettings,
      //     recipientId: data.group_id,
      //   });
      // }

      if (reqPayload.scan_type === "cargo arrival" || reqPayload.scan_type === "cargo break") {
        if (reqPayload.scan_type === "cargo break" && !isEmpty(cargoDetails.security_tag_info)) {
          warningNotificationData = {
            cargoMessage: "CARGO TRACKING NO. SCANNED",
            cargoDescription: "SCAN SECURITY TAG",
          };
        } else {
          // if security tag is not present is case of cargo break
          successNotificationData = {
            cargoMessage: reqPayload.scan_type === "cargo arrival" ? "CARGO RECEIVED" : "CARGO MARKED AS ARRIVED",
            cargoDescription: reqPayload.scan_type === "cargo arrival" ? "CHECK CARGO" : "INBOUND PARCELS",
            security_tag_info: reqPayload.scan_type === "cargo break" && cargoDetails.security_tag_info,
          };
        }
      } else if (cargoDetails.package_exceptions.length) {
        warningNotificationData = {
          cargoMessage: `${cargoDetails.package_exceptions.length} PACKAGE(s) WITH EXCEPTIONS`,
          cargoDescription:
            reqPayload.scan_type === "warehouse inbound" || reqPayload.scan_type === "handover inbound"
              ? "CARGO INBOUNDED"
              : "CARGO OUTBOUNDED",
        };
        dispatch({
          type: cargoTypes.ADD_CARGO_EXCEPTIONS,
          payload: cargoDetails.package_exceptions,
        });
      } else {
        successNotificationData = {
          cargoDescription:
            reqPayload.scan_type === "warehouse inbound" || reqPayload.scan_type === "handover inbound"
              ? "CARGO INBOUNDED"
              : "CARGO OUTBOUNDED",
        };
        // successNotificationData = data;
      }

      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false,
      };

      const payload = {
        successNotificationData: successNotificationData,
        warningNotificationData: warningNotificationData,
        notificationBoxType: notificationBoxType,
        processedScannedCargo: res.data,
      };

      dispatch({
        type: cargoTypes.PROCESS_SCANNED_CARGOS_SUCESS,
        payload: payload,
      });

      const numbersList = [...scannedNumbersList, reqPayload.tracking_nos[0]];

      dispatch({
        type: commonTypes.SET_SCANNED_NUMBERS_LIST,
        payload: numbersList,
      });
    } catch (err) {
      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true,
      };

      let errorNotificationData = {};
      const error = err;

      if (
        error.data &&
        ((error.data.tracking_nos && error.data.tracking_nos[0]) ||
          (error.data.tracking_no && error.data.tracking_no[0]))
      ) {
        const errorMessage = error.data.tracking_nos ? error.data.tracking_nos[0] : error.data.tracking_no[0];
        errorNotificationData = {
          errorMessage: errorMessage,
          showTrackingNumber: reqPayload.tracking_nos[0],
        };
      } else if (error.data && error.data.hub_id && error.data.hub_id[0]) {
        const errorMessage = error.data.hub_id[0];
        errorNotificationData = {
          errorMessage: errorMessage,
          showTrackingNumber: reqPayload.tracking_nos[0],
        };
      } else {
        errorNotificationData = {
          errorMessage: "Unknown Error, please screenshot and send to Tech Support.",
          showTrackingNumber: reqPayload.tracking_nos[0],
        };
      }

      if (err.status === 500) {
        errorNotificationData.jsonBody = JSON.stringify(reqPayload);
      }

      const payload = {
        errorNotificationData: errorNotificationData,
        notificationBoxType: notificationBoxType,
        processedScannedCargoError: err,
      };
      dispatch({
        type: cargoTypes.PROCESS_SCANNED_CARGOS_ERROR,
        payload: payload,
      });
    }
  };
}

function uploadCargoPhotos(reqPayload) {
  return async function (dispatch) {
    try {
      await axios.post(cargoScanApi.UPLOAD_CARGO_PHOTOS, reqPayload);
      dispatch({
        type: cargoTypes.UPLOAD_CARGO_PHOTOS_SUCCESS,
        payload: {
          message: "Photo Upload Successful",
        },
      });
    } catch (err) {
      dispatch({
        type: cargoTypes.UPLOAD_CARGO_PHOTOS_ERROR,
        payload: {
          message: "Photo Upload Unsuccessful",
        },
      });
    }
  };
}

function getCargoPhotos(reqPayload) {
  return async function (dispatch) {
    try {
      const res = await axios.post(cargoScanApi.SEE_PHOTOS, reqPayload);
      dispatch({
        type: cargoTypes.SEE_PHOTOS_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: cargoTypes.SEE_PHOTOS_ERROR,
        payload: err,
      });
    }
  };
}

function onSaveScanSettings(payload) {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.SAVE_SCAN_SETTINGS_DATA,
      payload: payload,
    });
  };
}

function clearCargoInfo() {
  return function (dispatch) {
    dispatch({
      type: cargoTypes.CLEAR_CARGO_INFO,
    });
  };
}

// function getTrackingNumberInsideCargo(requestPayload) {
//   return async function (dispatch) {
//     try {
//       const res = await axios.get(cargoApi.CARGOS, {
//         params: requestPayload,
//       });
//       dispatch({
//         type: cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_SUCCESS,
//         payload: res.data,
//       });
//     } catch (err) {
//       // TODO: change the error message while integrating api
//       dispatch({
//         type: cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_ERROR,
//         payload: {
//           message: "Could not fetch tracking numbers inside the cargo",
//         },
//       });
//     }
//   };
// }

const getTrackingNumberInsideCargo = (requestPayload) => {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_LOADING });
    try {
      const res = await axios.get(cargoApi.CARGOS, {
        params: requestPayload,
      });
      const cargoTrackingNumbers = res.data.cargos;
      let data = res.data;
      delete data.cargos;
      const cargoTrackingNumbersData = data;

      const payload = {
        cargoTrackingNumbers: cargoTrackingNumbers,
        cargoTrackingNumbersData: cargoTrackingNumbersData,
      };

      dispatch({
        type: cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_SUCCESS,
        payload: payload,
      });
    } catch (err) {
      dispatch({
        type: cargoTypes.GET_TRACKING_NUMBERS_INSIDE_CARGO_ERROR,
        payload: err,
      });
    }
  };
};

const resetTrackingNumberInsideCargoData = () => {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.CLEAR_TRACKING_NUMBERS_INSIDE_CARGO_DATA });
  };
};

function cancelCargo(requestPayload) {
  return async function (dispatch) {
    try {
      // TODO: insert api and request payload
      const res = await axios.post();
      dispatch({
        type: cargoTypes.CANCEL_CARGO_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      // TODO: change the error message while integrating
      dispatch({
        type: cargoTypes.CANCEL_CARGO_ERROR,
        payload: {
          message: "Could not cancel cargo",
        },
      });
    }
  };
}

function bulkRemoveFromCargo(requestPayload) {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.BULK_REMOVE_CARGO_LOADING });
    try {
      const res = await axios.put(cargoApi.REMOVE_ALL_FROM_CARGO, requestPayload);
      dispatch({
        type: cargoTypes.BULK_REMOVE_CARGO_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: cargoTypes.BULK_REMOVE_CARGO_ERROR,
        payload: {
          message: "Could not remove packages from cargo",
        },
      });
    }
  };
}

function clearBulkRemoveFromCargoData() {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.CLEAR_BULK_REMOVE_CARGO_DATA });
  };
}

function addScannerData(payload) {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.ADD_SCANNER_DATA, payload: payload });
  };
}

function clearProcessAndDetailsScanOrder() {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.CLEAR_PROCESS_SCAN_ORDER });
  };
}

function clearGetCargoDetailsError() {
  return async function (dispatch) {
    dispatch({ type: cargoTypes.CLEAR_GET_CARGO_DETAILS_ERROR });
  };
}

function clearCargoExceptions() {
  return function (dispatch) {
    dispatch({ type: cargoTypes.REMOVE_CARGO_EXCEPTIONS });
  };
}

function resetScanState() {
  return function (dispatch) {
    const payload = {
      warningNotificationData: {},
      successNotificationData: {},
      errorNotificationData: {},
      notificationBoxType: {
        showNoDataBox: true,
        showValidDataBox: false,
        showErrorDataBox: false,
      },
    };
    dispatch({ type: cargoTypes.RESET_SCAN_STATE, payload });
  };
}

export const cargoActions = {
  createCargoToShipment,
  addCargoToShipment,
  createCargo,
  removeCargoFromParent,
  getCountriesList,
  setCargoNumber,
  clearCargoResponse,
  clearCargoState,
  clearCreateCargoError,
  clearCreateCargoResponse,
  clearAddCargoToShipmentResponse,
  cargoResetState,
  removeCargoResetState,
  getCargoDetails,
  processScannedArrivalBreakOrders,
  processScannedOrders,
  uploadCargoPhotos,
  clearCargoInfo,
  getCargoPhotos,
  getTrackingNumberInsideCargo,
  cancelCargo,
  bulkRemoveFromCargo,
  clearBulkRemoveFromCargoData,
  onSaveScanSettings,
  addScannerData,
  resetTrackingNumberInsideCargoData,
  clearProcessAndDetailsScanOrder,
  clearGetCargoDetailsError,
  clearCargoExceptions,
  resetScanState,
};
