import React, { useState } from "react";
import { Pagination } from "antd";
import { shipmentActions } from "../../../actions/rootActions";
import { connect } from "react-redux";

function CreateShipment(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const { shipmentNumbersData, shipmentNumbersList } = props;
  const pageNumber = 1;

  return (
    <div className="content-container card mt-2 mb-1 p-2 cnt-2">
      <div className="d-flex flex-column align-items-center">
        <h4 className="mt-3 mb-3 shipments-title">Shipments</h4>
        <button className="btn create-shipment-button" onClick={props.showCreateShipment}>
          Create Shipment
        </button>
      </div>
      <div className="d-flex mt-3 p-3 pt-0" style={{ justifyContent: "space-evenly" }}>
        <div className="">
          <span className={props.getFilterBg("pending")} onClick={(e) => setSelectedShipmentFilter(e, "pending")}>
            Pending
          </span>
        </div>
        <div className="">
          <span
            className={props.getFilterBg("OB_IN_PROGRESS")}
            onClick={(e) => setSelectedShipmentFilter(e, "OB_IN_PROGRESS")}
          >
            O/B In Progress
          </span>
        </div>
        <div className="">
          <span className={props.getFilterBg("outbounded")} onClick={(e) => setSelectedShipmentFilter(e, "outbounded")}>
            Outbounded
          </span>
        </div>
        <div className="">
          <span className={props.getFilterBg("cancelled")} onClick={(e) => setSelectedShipmentFilter(e, "cancelled")}>
            Cancelled
          </span>
        </div>
      </div>
      <div className="row mt-2 ml-3 pt-0">
        <div className="col-12 ml-4">
          {shipmentNumbersList &&
            shipmentNumbersList.length > 0 &&
            shipmentNumbersList.map((shipmentNumber, index) => {
              return (
                <div key={index} className="row pt-2 pb-2">
                  <div className="col-3">
                    <span
                      className="shipments-text pointer"
                      onClick={() =>
                        props.getShipmentTrackingNumber(
                          shipmentNumber.recipient_name,
                          shipmentNumber.tracking_no,
                          pageNumber
                        )
                      }
                    >
                      {shipmentNumber.recipient_name}
                    </span>
                  </div>
                  <div className="col-9 pl-4">
                    <span
                      className="shipments-text pointer"
                      onClick={() =>
                        props.getShipmentTrackingNumber(
                          shipmentNumber.recipient_name,
                          shipmentNumber.tracking_no,
                          pageNumber
                        )
                      }
                    >
                      {shipmentNumber.tracking_no}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {shipmentNumbersList && shipmentNumbersList.length > 0 && (
        <div className="mt-3 ml-3 mb-3 pt-0">
          <Pagination
            current={currentPage}
            defaultPageSize={50}
            total={shipmentNumbersData.total_records}
            onChange={onPageChange}
            className="shipment-pagination"
            responsive
          />
        </div>
      )}
    </div>
  );

  function setSelectedShipmentFilter(e, filterName) {
    e.preventDefault();
    onPageChange(1);
    props.setSelectedShipmentFilter(e, filterName);
  }

  function onPageChange(page) {
    setCurrentPage(page);
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: page,
    };
    props.dispatch(shipmentActions.getShipmentNumbersList(data));
  }
}

export default connect(null, null)(CreateShipment);
