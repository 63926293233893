import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import ReactGA from "react-ga";
import { Trans } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import { ROOT_URL } from "../../actions/index";
import * as qz from "qz-tray";
import { DatePicker, Dropdown, Menu, Alert } from "antd";
import "antd/dist/antd.css";
import * as _ from "lodash";

export default class HMSReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubId: "",
      userId: "",
      userName: "",
      qubevuStatus: "disabled",
      qubevuIP: "",
      isLoggedIn: true,
      doFrom: this.getFromDateFormat(),
      doTo: this.getToDateFormat(),
      doGroupID: "",
      reportType: null,
      doErr: false,
      shipmentNumber: "",
      showShipmentNumberEmptyError: false,
      currScanType: "",
      recipientsList: [],
      selectedRecipient: "",
    };
  }
  componentDidMount() {
    ReactGA.initialize("UA-157972758-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      this.setState({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        qubevuStatus: localStorage.getItem("qubevuStatus") || "disabled",
        qubevuIP: localStorage.getItem("qubevuIP") || "",
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
    this.getRecipientsList();
  }

  setRecipientsState = (event) => {
    this.setState({
      selectedRecipient: event.target.value,
      doGroupID: "",
    });
  };

  // recipients
  getRecipientsList = async () => {
    let recipientsResult = await axios.get(`${ROOT_URL}/hms/get_recipients/`);
    let recipientsList = recipientsResult.data;
    this.setState({ recipientsList: recipientsList });
  };

  render() {
    if (!this.state.isLoggedIn) {
      return <Redirect push to="/scan/hubmanage/old/login" />;
    }
    let errorClass = this.state.showShipmentNumberEmptyError ? "is-invalid" : "";

    const hasZeroRecipients = this.state.reportType === "warehouse outbound" && this.state.recipientsList.length === 0;

    const dropdownRecipients = this.state.recipientsList.map((recipient) => {
      return <option value={recipient.name}>{recipient.name}</option>;
    });

    const dropdownScanTypes = localStorage
      .getItem("scanTypes")
      .split(",")
      .map((scannedType) => {
        return <option value={scannedType}>{scannedType.toUpperCase()}</option>;
      });

    return (
      <div className="main-container d-flex flex-column align-items-left">
        <div>
          <h1 className="font-weight-bolder pl-0 float-left">Reports</h1>
          <div className="float-right mt-2">
            <Dropdown overlay={this.getMenu()} placement="bottomLeft" trigger={["click"]}>
              <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
                Menu
              </button>
            </Dropdown>
            <button type="button" className="btn btn-outline-danger" onClick={this.clearSession}>
              End Session
            </button>
          </div>
        </div>

        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <LinkContainer to="/scan">
                <a>Home</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item">
              <LinkContainer to={`/scan/hubmanage`}>
                <a>HMS Scanner</a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Reports
            </li>
          </ol>
        </nav>
        <div className="content-container card mb-1 p-2">
          <div className="form-group d-block justify-content-between mb-0">
            <div id="hub-id" style={{ marginBottom: 0 + "rem" }}>
              <label>Hub ID</label>
              <div>
                <label>{this.state.hubId}</label>
              </div>
            </div>
            <div id="user-id">
              <label>User ID</label>
              <div>
                <label>{this.state.userName}</label>
              </div>
            </div>
          </div>
        </div>
        <div className="content-container card mb-1 p-2 cnt-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <div className="text-center">
              <h3>Reports</h3>
            </div>
            <div>
              <label>
                <Trans>Report Type</Trans>
              </label>
              <div>
                <select
                  className="form-control"
                  value={this.state.reportType}
                  onChange={this.setReportState.bind(this)}
                >
                  <option disabled selected value="">
                    Select a type
                  </option>
                  <option value="freight forwader inbound">FREIGHT FORWARDER INBOUND</option>
                  <option value="freight forwader outbound">FREIGHT FORWARDER OUTBOUND</option>
                  <option value="ftz inbound">FTZ INBOUND</option>
                  <option value="ftz outbound">FTZ OUTBOUND</option>
                  <option value="warehouse inbound">WAREHOUSE INBOUND</option>
                  <option value="ftz inbound">FTZ INBOUND</option>
                  <option value="ftz outbound">FTZ OUTBOUND</option>
                  <option value="warehouse outbound">WAREHOUSE OUTBOUND</option>
                  <option value="no_data">NO DATA</option>
                  <option value="shipment_manifest">SHIPMENT MANIFEST</option>
                </select>
              </div>
            </div>
            {this.state.reportType && this.state.reportType == "shipment_manifest" && (
              <div className="d-flex flex-column mb-3 mt-3">
                <label>Shipment Number</label>
                <input
                  type="text"
                  className={`form-control ${errorClass}`}
                  value={this.state.shipmentNumber}
                  onChange={this.handleChangeShipmentNumber}
                  placeholder="Please input the Shipment Number"
                />
                {this.state.showShipmentNumberEmptyError && (
                  <div className="alert alert-danger w-100 text-center mt-3">This field should not be empty</div>
                )}
              </div>
            )}
            {this.state.reportType && this.state.reportType !== "shipment_manifest" && (
              <React.Fragment>
                {this.state.reportType === "no_data" && (
                  <div className="mt-3">
                    <label>
                      <Trans>Scan Type</Trans>
                    </label>
                    <div>
                      <select
                        className="form-control"
                        value={this.state.currScanType}
                        onChange={this.handleScanTypeChange}
                      >
                        <option disabled selected value="">
                          Please select type
                        </option>
                        {dropdownScanTypes}
                      </select>
                    </div>
                  </div>
                )}

                {this.state.reportType === "warehouse outbound" && (
                  <div className="mt-3">
                    <label>Send to Network Partner</label>
                    <div>
                      <select
                        className="form-control"
                        value={this.state.selectedRecipient}
                        onChange={this.setRecipientsState}
                        disabled={_.isEmpty(this.state.selectedRecipient) && !_.isEmpty(this.state.doGroupID)}
                      >
                        <option selected value="">
                          Please select Network Partner
                        </option>
                        {dropdownRecipients}
                      </select>
                    </div>
                  </div>
                )}

                {this.state.reportType !== "no_data" && (
                  <div className="mt-3">
                    <label>Group ID</label>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Group ID"
                        value={this.state.doGroupID}
                        disabled={!_.isEmpty(this.state.selectedRecipient) && _.isEmpty(this.state.doGroupID)}
                        onChange={(e) => {
                          const inputVal = e.target.value.toUpperCase();
                          this.setState({ doGroupID: inputVal, selectedRecipient: "" });
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="mt-3">
                  <label>Scan Date From</label>
                  <div>
                    <DatePicker
                      showTime={{
                        format: "DD/MM/YY H:mm:ss",
                        defaultValue: moment().startOf("D"),
                      }}
                      format={"DD/MM/YY H:mm:ss"}
                      style={{ width: "100%" }}
                      value={this.state.doFrom}
                      onChange={(date, dateString) =>
                        this.setState({
                          doFrom: date,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <label>Scan Date To</label>
                  <div>
                    <DatePicker
                      showTime={{
                        format: "DD/MM/YY H:mm:ss",
                        defaultValue: moment().startOf("D"),
                      }}
                      format={"DD/MM/YY H:mm:ss"}
                      style={{ width: "100%" }}
                      value={this.state.doTo}
                      onChange={(date, dateString) =>
                        this.setState({
                          doTo: date,
                        })
                      }
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
            {this.state.reportType && (
              <div className="mt-3">
                {this.state.doErr ? (
                  <div class="alert alert-danger">
                    No data exists for your selection. Please check if the Group ID is correct and scan dates are valid.
                  </div>
                ) : null}
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={this.downloadDO.bind(this)}
                  disabled={hasZeroRecipients}
                >
                  {this.state.reportType === "shipment_manifest"
                    ? "Generate and Download Manifest"
                    : "Generate and Download DO/MAWB"}
                </button>
              </div>
            )}
            {hasZeroRecipients && (
              <Alert
                className="mt-3"
                type="error"
                message="Your hub has not configured network partners to send to. Call support immediately."
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  handleScanTypeChange = (e) => {
    this.setState({ currScanType: e.target.value });
  };

  handleChangeShipmentNumber = (e) => {
    this.setState({ shipmentNumber: e.target.value });
  };

  clearSession = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("hubId");
    localStorage.removeItem("scanTypes");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("printersList");
    localStorage.removeItem("isQzConnected");
    this.setState({
      isLoggedIn: false,
    });
    // this.disconnectQzTray();
  };

  disconnectQzTray = () => {
    qz.websocket
      .disconnect()
      .then(() => {
        console.log("Disconnected");
      })
      .catch((e) => console.log("Error", e));
  };

  getFromDateFormat() {
    let fromDate = moment();
    fromDate.set({ hour: "00", minute: "00", second: "00" });
    return fromDate;
  }

  getToDateFormat() {
    let toDate = moment();
    toDate.set({ hour: "23", minute: "59", second: "00" });
    return toDate;
  }

  getMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  };

  setReportState(event) {
    this.setState({
      reportType: event.target.value,
    });
  }

  getAllHubs() {
    const url = `${ROOT_URL}/hms/hubs/`;
    return axios.get(url, {
      headers: {
        Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
      },
    });
  }

  async getHubIdByName(hubName) {
    let hubs = await this.getAllHubs();
    let hubId = null;
    hubs.data.forEach((hub) => {
      if (hub.hub_id === hubName) {
        hubId = hub.id;
      }
    });

    return hubId;
  }

  getParams = async () => {
    const DATETIME_FORMAT = "DD/MM/YY H:mm:ss";
    let fromDate = this.state.doFrom;
    const hubIDID = await this.getHubIdByName(this.state.hubId);

    if (this.state.reportType === "shipment_manifest") {
      let params = {
        hub_id: this.state.hubId,
        hubscanner_key: this.state.userId,
        shipment_no: this.state.shipmentNumber,
      };
      return params;
    } else if (this.state.reportType === "no_data") {
      let params = {
        hub_id: this.state.hubId,
        hubscanner_key: this.state.userId,
        scan_from: moment(fromDate, DATETIME_FORMAT).format(),
        scan_to: moment(this.state.doTo, DATETIME_FORMAT).format(),
        scan_type: this.state.currScanType,
        // hms_hub_group_id: hubIDID + '#' + this.state.doGroupID,
      };
      return params;
    } else {
      let params = {
        hms_scan_from: moment(fromDate, DATETIME_FORMAT).format(),
        hms_scan_to: moment(this.state.doTo, DATETIME_FORMAT).format(),
        scan_type: this.state.reportType,
      };
      if (!_.isEmpty(this.state.doGroupID)) {
        params.hms_hub_group_id = hubIDID + "#" + this.state.doGroupID;
      } else {
        params.hms_hub_group_id = hubIDID + "#" + this.state.selectedRecipient;
      }
      return params;
    }
  };

  getUrl = () => {
    if (this.state.reportType === "shipment_manifest") {
      return `${ROOT_URL}/hms/download-shipment-manifest/`;
    } else if (this.state.reportType === "no_data") {
      return `${ROOT_URL}/hms/download-nodata-scan/`;
    }
    return `${ROOT_URL}/hms/download-delivery-manifest/`;
  };

  // filename
  getFileName = () => {
    if (this.state.reportType === "shipment_manifest") {
      return "shipment-manifest.xlsx";
    } else if (this.state.reportType === "no_data") {
      return "no-data.xlsx";
    }
    return "manifest.xlsx";
  };

  async downloadDO() {
    if (this.state.reportType === "shipment_manifest" && this.state.shipmentNumber == "") {
      this.setState({ showShipmentNumberEmptyError: true });
      setTimeout(() => {
        this.setState({
          showShipmentNumberEmptyError: false,
        });
      }, 5000);
      return;
    }

    let params = await this.getParams();
    const url = this.getUrl();
    const fileName = this.getFileName();

    axios
      .get(url, {
        params: params,
        responseType: "blob",
        headers: {
          Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((e) => {
        this.setState({
          doErr: true,
        });

        setTimeout(() => {
          this.setState({
            doErr: false,
          });
        }, 5000);
      });
  }
}
