import axios from "axios";
import { parcelServices, trolleyServices } from "../services";
import { trolleyTypes, commonTypes } from "../types";
import * as _ from "lodash";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
const defaultToken = localStorage.getItem("token");

if (defaultToken) {
  axios.defaults.headers.common["Authorization"] = "Token " + defaultToken;
}

// get trolley package details
const getTrolleyPackageDetails = (params, scannedNumbersList) => {
  const loading = () => ({ type: trolleyTypes.FETCH_TROLLEY_PACKAGES_LOADING });
  const success = (payload) => ({ type: trolleyTypes.FETCH_TROLLEY_PACKAGES_SUCCESS, payload });
  const failure = (payload) => ({ type: trolleyTypes.FETCH_TROLLEY_PACKAGES_FAILURE, payload });
  const setScannedNumbersList = (payload) => ({ type: commonTypes.SET_SCANNED_NUMBERS_LIST, payload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      const res = await trolleyServices.getTrolleyPackageDetails(params);

      const orderData = {
        trolley_no: res.orderDataResponse,
        tracking_nos: res.tracking_nos
      };

      const latestScannedNumber = res.temporary_group_id;

      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false
      };

      const payload = {
        latestScannedNumber: latestScannedNumber,
        orderData: orderData,
        notificationBoxType: notificationBoxType
      };

      let numbersList = [...scannedNumbersList];
      numbersList.unshift(params.temporary_group_id);
      dispatch(setScannedNumbersList(numbersList));

      dispatch(success(payload));
    } catch (e) {
      const errorMessage = e.data.error ? e.data.error : "Unknown Error, please screenshot and send to Tech Support.";
      
      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: false,
        showErrorDataBox: true
      };

      let errorNotificationData = {
        errorMessage: errorMessage,
        showTrackingNumber: params.temporary_group_id,
      };

      if (e.status === 500) {
       errorNotificationData.jsonBody = JSON.stringify(params);
      };

      const payload = {
        notificationBoxType: notificationBoxType,
        errorNotificationData: errorNotificationData
      };
      dispatch(failure(payload));
    }
  }
}

// add cargo to trolley
const addCargoToTrolley = (params, orderData) => {
  const loading = () => ({ type: trolleyTypes.ADD_CARGO_TO_TROLLEY_LOADING });
  const success = (payload) => ({ type: trolleyTypes.ADD_CARGO_TO_TROLLEY_SUCCESS, payload });
  const failure = (payload) => ({ type: trolleyTypes.ADD_CARGO_TO_TROLLEY_FAILURE, payload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      if (_.isEmpty(orderData)) {
        throw new Error(`No Tracking Numbers Linked`)
      }

      if (!_.isEmpty(orderData) && orderData.tracking_nos && orderData.tracking_nos.length === 0) {
        throw new Error(`No Tracking Numbers Linked`)
      }

      await trolleyServices.addCargoToTrolley(params);

      const notificationBoxType = {
        showNoDataBox: false,
        showValidDataBox: true,
        showErrorDataBox: false
      };

      const payload = {
        notificationBoxType: notificationBoxType
      };

      dispatch(success(payload));
    } catch (e) {
      let errorMessage = "";
      if (e && e.data) {
        errorMessage = e.data && e.data.scanned_tracking_no
          ? e.data.scanned_tracking_no.join(", ")
          : e.data.cargo_tracking_no ? e.data.cargo_tracking_no.join(", ") : "Unknown Error, please screenshot and send to Tech Support.";
      }

      if (e.message) {
        errorMessage = e.message;
      }

      // const errorNotificationData = {
      //   errorMessage: errorMessage,
      //   showTrackingNumber: params.temporary_group_id,
      // };
      // const notificationBoxType = {
      //   showNoDataBox: false,
      //   showValidDataBox: false,
      //   showErrorDataBox: true
      // };

      // const payload = {
      //   notificationBoxType: notificationBoxType,
      //   errorNotificationData: errorNotificationData
      // };
      dispatch(failure(errorMessage));
    }
  }
}

const setAddCargoToTrolley = (errorMessage) => {
  const failure = (payload) => ({ type: trolleyTypes.ADD_CARGO_TO_TROLLEY_FAILURE, payload });
  return function (dispatch) {
    dispatch(failure(errorMessage));
  }
}

const onAddLocation = (params) => {
  const loading = () => ({ type: trolleyTypes.ADD_LOCATION_LOADING });
  const success = () => ({ type: trolleyTypes.ADD_LOCATION_SUCCESS });
  const failure = (payload) => ({ type: trolleyTypes.ADD_LOCATION_FAILURE, payload });

  return async function (dispatch) {
    dispatch(loading());
    try {
      await trolleyServices.addLocation(params);
      dispatch(success());
    } catch (err) {
      const errorMessage = err.data && err.data.location ? err.data.location[0] : 'Error! Please Try Again';
      dispatch(failure(errorMessage));
    }
  }
}

const clearFetchTrolleyPackagesData = () => {
  const clearTrolleyPackagesData = () => ({ type: trolleyTypes.CLEAR_FETCH_TROLLEY_PACKAGES_DATA });

  return async function (dispatch) {
    dispatch(clearTrolleyPackagesData());
  }
}

const clearAddCargoToTrolleyData = () => {
  const clearAddCargoToTrolley = () => ({ type: trolleyTypes.CLEAR_ADD_CARGO_TO_TROLLEY_DATA });

  return async function (dispatch) {
    dispatch(clearAddCargoToTrolley());
  }
}

const clearAddLocationData = () => {
  const clearAddLocation = () => ({ type: trolleyTypes.CLEAR_ADD_LOCATION_DATA });
  
  return async function (dispatch) {
    dispatch(clearAddLocation());
  }
}

const resetTrolleyScanStateData = () => {
  const clearAddLocation = () => ({ type: trolleyTypes.CLEAR_TROLLEY_SCAN_DATA })

  return async function (dispatch) {
    dispatch(clearAddLocation());
  }
}

export const trolleyActions = {
  getTrolleyPackageDetails,
  clearFetchTrolleyPackagesData,
  addCargoToTrolley,
  setAddCargoToTrolley,
  clearAddCargoToTrolleyData,
  onAddLocation,
  clearAddLocationData,
  resetTrolleyScanStateData
}