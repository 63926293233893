import React, { useState } from "react";
import { Divider } from "antd";
import * as _ from "lodash";

export default function CargoPartOf(props) {
  const shipment = props.location.state.shipment;
  const cargo = props.location.state.cargo;

  const [cargoTracking, setCargoTracking] = useState(getCargoParentData());
  const [cargoTrackingCopy] = useState(getCargoParentData());

  const [menuVisible, setMenuVisible] = useState({
    trackingCodeVisible: false,
    typeVisible: false,
  });

  const [filters, setFilters] = useState({
    trackingCode: "",
    type: "",
  });

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <p className="link-title mb-0" onClick={goBackToPreviousScreen}>
              Cargo
            </p>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">{cargo.tracking_no}</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black table-heading-margin">Cargo is Part Of</h2>
            <div className="table-responsive margin-top-20">
              <table className="table table-striped table-borderless">
                <thead className="table-header--bottom-border">
                  <tr>
                    <td>
                      <span className="cursor-pointer">Tracking Number</span>
                      {/* <Dropdown
                        overlay={trackingNumberFilterMenu}
                        trigger={["click"]}
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() =>
                          handleVisibleChange("trackingCodeVisible")
                        }
                        visible={menuVisible.trackingCodeVisible}
                      >
                        <span className="text-span-black cursor-pointer">
                          Tracking Code
                          <i
                            className="fa fa-filter filter-icon cursor-pointer"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Dropdown> */}
                    </td>
                    <td className="table-header-extended">
                      <span className="cursor-pointer">Type</span>
                      {/* <Dropdown
                        overlay={typeFilterMenu}
                        trigger={["click"]}
                        overlayClassName="hms-dropdown-menu"
                        onVisibleChange={() => handleVisibleChange("typeVisible")}
                        visible={menuVisible.typeVisible}
                      >
                        <span className="text-span-black cursor-pointer">
                          Type
                          <i
                            className="fa fa-filter filter-icon cursor-pointer"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </Dropdown> */}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {cargoTracking &&
                    cargoTracking.length > 0 &&
                    cargoTracking.map((cargo, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-span-link" onClick={cargo.onClick}>
                            {cargo.tracking_no}
                          </td>
                          <td className="text-span-black table-header-extended">{cargo.type}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <Divider className="gray-divider" />
            <button className="secondary-button-small full-button-width" onClick={goBackToPreviousScreen}>
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  //! DISABLED TILL BACKEND FILTER IS DONE
  // function trackingNumberFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Tracking Code"
  //           placeholder="Type here"
  //           name="trackingCode"
  //           value={filters.trackingCode}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("trackingCode")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  // function typeFilterMenu() {
  //   return (
  //     <Menu>
  //       <Menu.Item key="0">
  //         <Input
  //           label="Filter Type"
  //           placeholder="Type here"
  //           name="type"
  //           value={filters.type}
  //           onChange={onInputChange}
  //         />
  //         <div className="d-flex align-items-center justify-content-between">
  //           <span
  //             className="shipment-filter-reset"
  //             onClick={() => onClickReset("type")}
  //           >
  //             Reset
  //           </span>
  //           <button className="shipment-filter-button" onClick={onClickFilter}>
  //             Filter
  //           </button>
  //         </div>
  //       </Menu.Item>
  //     </Menu>
  //   );
  // }

  //function onInputChange(e) {
  //const { name, value } = e.target;
  //setFilters({
  //...filters,
  //[name]: value,
  //});
  //}

  //function onClickReset(name) {
  //let newFilters = { ...filters };
  //newFilters[name] = "";
  //setFilters(newFilters);
  //onClickFilter(null, newFilters);
  //}

  function onClickFilter(e, resetFilters) {
    const listFilters = resetFilters ? resetFilters : filters;
    let list = cargoTrackingCopy;
    if (!_.isEmpty(listFilters.trackingCode)) {
      list = _.filter(list, (x) => x.tracking_no === listFilters.trackingCode);
    } else if (!_.isEmpty(listFilters.type)) {
      list = _.filter(list, (x) => x.type.toLowerCase() === listFilters.type.toLowerCase());
    }
    setCargoTracking(list);
  }

  //function handleVisibleChange(name) {
  //setMenuVisible({
  //...menuVisible,
  //[name]: !menuVisible[name],
  //});
  //}

  function getCargoParentData() {
    return [
      { tracking_no: cargo.shipment_no, type: "Shipment", onClick: () => goToShipmentDetails() },
      //{ tracking_no: cargo.tracking_no, type: "Cargo", onClick: () => goBackToPreviousScreen() },
    ];
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }

  function goToShipmentDetails() {
    props.history.push({
      pathname: `/scan/hubmanage/shipments/${shipment.tracking_no}`,
      state: {
        shipment: shipment,
      },
    });
  }
}
