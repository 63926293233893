import React from "react";
import { isEmpty } from "lodash";

export const CargoWarningNotificationArea = ({
  scanType,
  warningNotificationData,
  latestScannedNumber,
  validDataBoxClass,
}) => {
  if (
    (scanType === "warehouse inbound" || scanType === "warehouse outbound" || scanType === "cargo break") &&
    !isEmpty(warningNotificationData)
  ) {
    return (
      <div
        className={`warning-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
      >
        <div className="success-notification-25-bold text-center">{warningNotificationData.cargoMessage}</div>
        <div className="success-notification-50-bold text-center margin-bottom-20">
          {warningNotificationData.cargoDescription}
        </div>
        <div className="success-notification-25 text-center">CARGO: {latestScannedNumber}</div>
      </div>
    );
  }
  return null;
};
