import { parcelTypes } from "../types";

const initialState = {
  fetchParcelLoading: false,
  fetchParcelSuccess: false,
  fetchParcelError: false,
  latestScannedNumber: null,
  parcelOrderData: {},
  successNotificationData: {},
  errorNotificationData: {},
  notificationBoxType: {
    showNoDataBox: true,
    showValidDataBox: false,
    showErrorDataBox: false,
    showRedeliverDataBox: false,
  },
  updateTrackerLoading: false,
  updateTrackerSuccess: false,
  redeliverScanLoading: false,
  redeliverScanSuccess: false,
  redeliverScanError: false,

  fetchDisposalOrderLoading: false,
  fetchDisposalOrderSuccess: false,
  fetchDisposalOrderError: null,
  disposalScannedNumbersList: [],

  disposalScanLoading: false,
  disposalScanSuccess: false,
  disposalScanError: false,

  updateWeightLoading: false,
  updateWeightError: null,
  updateWeightSuccess: false,

  addExceptionLoading: false,
  addExceptionError: null,
  addExceptionSuccess: false,
  exceptionNumber: 0,
  exceptionData: {},

  addLocationLoading: false,
  addLocationError: null,
  addLocationSuccess: false,
  locationData: {},

  selectedNetworkPartner: null,

  scanSettingsData: {
    groupId: "",
    recipientId: null,
    automaticallyInbound: false,
    autoHandover: false,
  },

  resolveExceptionLoading: false,
  resolveExceptionSuccess: false,
  resolveExceptionError: false,

  remedyData: {
    remedyReason: null,
    automaticallyRemedy: false,
  },

  automaticallyResolveExceptions: false,
  resources: [],
  addResourceLoading: false,
  addResourceSuccess: false,
  resourceData: {},
};

const parcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case parcelTypes.FETCH_PARCEL_INFO_LOADING:
      return {
        ...state,
        fetchParcelLoading: true,
      };
    case parcelTypes.FETCH_PARCEL_INFO_SUCCESS:
      return {
        ...state,
        fetchParcelLoading: false,
        errorNotificationData: {},
        fetchParcelSuccess: true,
        latestScannedNumber: action.payload.latestScannedNumber,
        parcelOrderData: action.payload.orderData,
        successNotificationData: action.payload.successNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
        exceptionNumber: initialState.exceptionNumber,
        fetchParcelError: false,
      };
    case parcelTypes.FETCH_PARCEL_INFO_FAILURE:
      return {
        ...state,
        fetchParcelLoading: false,
        fetchParcelSuccess: false,
        errorNotificationData: action.payload.errorNotificationData,
        parcelOrderData: {},
        successNotificationData: {},
        notificationBoxType: action.payload.notificationBoxType,
        fetchParcelError: true,
      };

    case parcelTypes.SAVE_PARCEL_DATA:
      return {
        ...state,
        parcelOrderData: action.payload.orderData,
        successNotificationData: action.payload.successNotificationData,
        latestScannedNumber: action.payload.latestScannedNumber,
        notificationBoxType: action.payload.notificationBoxType,
      };

    case parcelTypes.CLEAR_FETCH_PARCEL_DATA:
      return {
        ...state,
        fetchParcelLoading: false,
        fetchParcelSuccess: false,
        fetchParcelError: false,
      };

    case parcelTypes.SET_NETWORK_PARTNER:
      return {
        ...state,
        selectedNetworkPartner: action.payload,
      };

    case parcelTypes.UPDATE_TRACKER_LOADING:
      return {
        ...state,
        updateTrackerLoading: true,
        updateTrackerSuccess: false,
      };
    case parcelTypes.UPDATE_TRACKER_SUCCESS:
      return {
        ...state,
        updateTrackerLoading: false,
        errorNotificationData: {},
        updateTrackerSuccess: true,
        latestScannedNumber: action.payload,
      };
    case parcelTypes.UPDATE_TRACKER_FAILURE:
      return {
        ...state,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
        updateTrackerSuccess: false,
        updateTrackerLoading: false,
        fetchParcelError: true,
      };
    case parcelTypes.CLEAR_UPDATE_TRACKER_DATA:
      return {
        ...state,
        updateTrackerSuccess: false,
        updateTrackerLoading: false,
        fetchParcelError: false,
      };

    case parcelTypes.REDELIVER_SCAN_LOADING:
      return {
        ...state,
        redeliverScanLoading: true,
        redeliverScanSuccess: false,
      };
    case parcelTypes.REDELIVER_SCAN_SUCCESS:
      return {
        ...state,
        redeliverScanLoading: false,
        errorNotificationData: {},
        redeliverScanSuccess: true,
        latestScannedNumber: action.payload.latestScannedNumber,
        parcelOrderData: action.payload.orderData,
        successNotificationData: action.payload.successNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
      };
    case parcelTypes.REDELIVER_SCAN_FAILURE:
      return {
        ...state,
        redeliverScanLoading: false,
        redeliverScanError: true,
        errorNotificationData: action.payload.errorNotificationData,
        parcelOrderData: {},
        successNotificationData: {},
        notificationBoxType: action.payload.notificationBoxType,
      };
    case parcelTypes.CLEAR_REDELIVER_SCAN_DATA:
      return {
        ...state,
        redeliverScanLoading: false,
        redeliverScanSuccess: false,
        redeliverScanError: false,
      };

    case parcelTypes.FETCH_DISPOSAL_ORDER_LOADING:
      return {
        ...state,
        fetchDisposalOrderLoading: true,
        fetchDisposalOrderSuccess: false,
      };
    case parcelTypes.FETCH_DISPOSAL_ORDER_SUCCESS:
      return {
        ...state,
        fetchDisposalOrderLoading: false,
        fetchDisposalOrderSuccess: true,
        errorNotificationData: {},
        latestScannedNumber: action.payload.latestScannedNumber,
        successNotificationData: action.payload.successNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
        disposalScannedNumbersList: action.payload.numbersList,
      };
    case parcelTypes.FETCH_DISPOSAL_ORDER_FAILURE:
      return {
        ...state,
        fetchDisposalOrderLoading: false,
        fetchDisposalOrderSuccess: false,
        fetchDisposalOrderError: true,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
      };

    case parcelTypes.CLEAR_DISPOSAL_ORDER_DATA:
      return { ...state, fetchDisposalOrderSuccess: false };
    case parcelTypes.CLEAR_DISPOSAL_ORDER_ERROR:
      return { ...state, fetchDisposalOrderError: false };
    case parcelTypes.DISPOSAL_SCAN_LOADING:
      return { ...state, disposalScanLoading: true, disposalScanSuccess: false };
    case parcelTypes.DISPOSAL_SCAN_SUCCESS:
      return {
        ...state,
        disposalScanLoading: false,
        disposalScanSuccess: true,
        successNotificationData: action.payload.successNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
        disposalScannedNumbersList: [],
        errorNotificationData: {},
      };
    case parcelTypes.DISPOSAL_SCAN_FAILURE:
      return {
        ...state,
        disposalScanLoading: false,
        disposalScanSuccess: false,
        disposalScanError: true,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
      };
    case parcelTypes.CLEAR_DISPOSAL_SCAN_DATA:
      return {
        ...state,
        disposalScanLoading: false,
        disposalScanSuccess: false,
        disposalScanError: false,
      };

    case parcelTypes.UPDATE_WEIGHT_LOADING:
      return { ...state, updateWeightLoading: true, updateWeightSuccess: false };
    case parcelTypes.UPDATE_WEIGHT_SUCCESS:
      return {
        ...state,
        updateWeightLoading: false,
        updateWeightSuccess: true,
        updateWeightError: null,
      };
    case parcelTypes.UPDATE_WEIGHT_FAILURE:
      return {
        ...state,
        updateWeightLoading: false,
        updateWeightSuccess: true,
        updateWeightError: action.payload,
      };
    case parcelTypes.CLEAR_UPDATE_WEIGHT_DATA:
      return {
        ...state,
        updateWeightLoading: false,
        updateWeightSuccess: false,
        updateWeightError: null,
      };

    case parcelTypes.ADD_EXCEPTION_LOADING:
      return { ...state, addExceptionLoading: true, addExceptionSuccess: false };
    case parcelTypes.ADD_EXCEPTION_SUCCESS:
      return {
        ...state,
        addExceptionLoading: false,
        addExceptionSuccess: true,
        exceptionNumber: action.payload,
        addExceptionError: null,
      };
    case parcelTypes.ADD_EXCEPTION_FAILURE:
      return {
        ...state,
        addExceptionLoading: false,
        addExceptionSuccess: true,
        addExceptionError: action.payload,
      };
    case parcelTypes.CLEAR_ADD_EXCEPTION_DATA:
      return {
        ...state,
        addExceptionLoading: false,
        addExceptionSuccess: false,
        addExceptionError: null,
      };
    case parcelTypes.SAVE_EXCEPTION_DATA:
      return { ...state, exceptionData: action.payload };

    case parcelTypes.ADD_LOCATION_LOADING:
      return {
        ...state,
        addLocationLoading: true,
        addLocationSuccess: false,
      };
    case parcelTypes.ADD_LOCATION_SUCCESS:
      return {
        ...state,
        addLocationLoading: false,
        addLocationSuccess: true,
        addLocationError: null,
      };
    case parcelTypes.ADD_LOCATION_FAILURE:
      return {
        ...state,
        addLocationLoading: false,
        addLocationSuccess: false,
        addLocationError: action.payload,
      };
    case parcelTypes.CLEAR_ADD_LOCATION_DATA:
      return {
        ...state,
        addLocationLoading: false,
        addLocationSuccess: false,
        addLocationError: null,
      };
    case parcelTypes.SAVE_LOCATION_DATA:
      return { ...state, locationData: action.payload };

    case parcelTypes.RESOLVE_EXCEPTIONS_LOADING:
      return { ...state, resolveExceptionLoading: true };
    case parcelTypes.RESOLVE_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        resolveExceptionLoading: false,
        resolveExceptionSuccess: true,
        resolveExceptionError: false,
      };
    case parcelTypes.RESOLVE_EXCEPTIONS_ERROR:
      return {
        ...state,
        resolveExceptionLoading: false,
        resolveExceptionSuccess: false,
        resolveExceptionError: action.payload,
      };
    case parcelTypes.SAVE_REMEDY_REASON_DATA:
      return { ...state, remedyData: action.payload };
    case parcelTypes.AUTOMATICALLY_RESOLVE_EXCEPTIONS:
      return { ...state, automaticallyResolveExceptions: true };
    case parcelTypes.RESET_RESOLVE_EXCEPTIONS_DATA:
      return {
        ...state,
        resolveExceptionLoading: false,
        resolveExceptionSuccess: false,
        resolveExceptionError: false,
        automaticallyResolveExceptions: false,
        remedyData: initialState.remedyData,
      };

    case parcelTypes.RESET_RESOLVE_EXCEPTIONS_SUCCESS_DATA:
      return {
        ...state,
        resolveExceptionLoading: false,
        resolveExceptionSuccess: false,
        resolveExceptionError: false,
      };

    case parcelTypes.SAVE_SCAN_SETTINGS:
      return { ...state, scanSettingsData: action.payload };

    case parcelTypes.CLEAR_PARCEL_SCAN_DATA:
      return { ...initialState };

    case parcelTypes.GET_RESOURCE_DETAILS_SUCCESS:
      return { ...state, resources: action.payload };
    case parcelTypes.GET_RESOURCE_DETAILS_ERROR:
      return { ...state, resources: [] };

    case parcelTypes.ADD_RESOURCE_LOADING:
      return { ...state, addResourceLoading: true, addResourceSuccess: false };
    case parcelTypes.ADD_RESOURCE_SUCCESS:
      return {
        ...state,
        addResourceLoading: false,
        addResourceSuccess: true,
      };
    case parcelTypes.ADD_RESOURCE_ERROR: {
      return {
        ...state,
        addResourceLoading: false,
        addResourceSuccess: false,
      };
    }
    case parcelTypes.SAVE_RESOURCE_DATA:
      return { ...state, resourceData: action.payload };
    case parcelTypes.SCAN_LABELLESS_PACKAGE_SUCCESS:
      return {
        ...state,
        latestScannedNumber: action.payload.latestScannedNumber,
        successNotificationData: action.payload.successNotificationData,
        parcelOrderData: action.payload.orderData,
        notificationBoxType: action.payload.notificationBoxType,
      };
    case parcelTypes.SCAN_LABELLESS_PACKAGE_ERROR:
      return {
        ...state,
        fetchDisposalOrderLoading: false,
        fetchDisposalOrderSuccess: false,
        fetchDisposalOrderError: true,
        errorNotificationData: action.payload.errorNotificationData,
        notificationBoxType: action.payload.notificationBoxType,
      };
    default:
      return state;
  }
};

export { parcelReducer };
