import React from "react";
import { ParcelScannerContext } from "./ParcelScanner";
import { Space, Collapse } from "antd";
import TimelineComponent from "./TimelineComponent";

const TimelineSweep = () => {
  return (
    <ParcelScannerContext.Consumer>
      {(value) => {
        const onToggleHandler = () => {
          if (value.isOpen === "1") {
            value.setIsOpen("0");
            localStorage.setItem("isOpen", "0");
          } else {
            value.setIsOpen("1");
            localStorage.setItem("isOpen", "1");
          }
        };

        return (
          <Space direction={"vertical"} className="w-100 margin-top-20">
            <Collapse collapsible="header" activeKey={value.isOpen} onChange={onToggleHandler}>
              <Collapse.Panel
                header={
                  <div className="d-flex align-items-center justify-content-between padding-20 bg-white">
                    <h2 className="text-heading-black">{value.latestScannedNumber} Timeline</h2>
                    <span className="link-title fnt-16">{value.isOpen === "1" ? "Hide" : "Show"}</span>
                  </div>
                }
                showArrow={false}
                key={1}
                className="bg-white"
              >
                <div className="margin-top-20 bg-white height-50vh padding-20 overflow-auto">
                  <TimelineComponent />
                </div>
              </Collapse.Panel>
            </Collapse>
          </Space>
        );
      }}
    </ParcelScannerContext.Consumer>
  );
};

export default TimelineSweep;
