import React from "react";
import DamagedParcel from "../../../images/parcel-icons/damaged-parcel.png";
import DigitalInfrastructure from "../../../images/parcel-icons/digital-infrastructure.png";
import LargeWarehouse from "../../../images/parcel-icons/large-warehouse.png";
import AddResource from "../../../images/parcel-icons/add-resource.png";
import WeighingScale from "../../../images/parcel-icons/weighing-scale.png";
import Sorting from "../../../images/parcel-icons/sorting.png";
import { Link } from "react-router-dom";
import ActionItem from "../commonComponents/actionItem";
import { ParcelScannerContext } from "./ParcelScanner";

export default function ActionItemsList() {
  return (
    <ParcelScannerContext.Consumer>
      {(value) => {
        const {
          scannedNumbersList,
          scanSettings,
          errorOnScanning,
          locationScanType,
          latestScannedNumber,
          notificationBoxType,
          getAddedLocation,
          getExceptionTag,
        } = value;

        const numberOfScanItems = scannedNumbersList && scannedNumbersList.length > 0 ? scannedNumbersList.length : "0";
        const showActionItem = !errorOnScanning && latestScannedNumber !== null;
        const shipmentActionItemClass =
          scanSettings.recipientId === null && scanSettings.groupId === ""
            ? "action-settings-buttons-color-margin-left"
            : "";
        const settingsClass =
          latestScannedNumber === null || notificationBoxType.showErrorDataBox ? "" : "margin-top-20";
        const addedLocation = getAddedLocation();
        const exceptionTag = getExceptionTag();
        return (
          <div className="d-flex flex-column">
            {showActionItem && locationScanType !== "labelless package" && (
              <div className="action-item-box-shadow">
                <ActionItem
                  to={getScannedNumberListHref(locationScanType)}
                  imageSrc={Sorting}
                  itemText={`${numberOfScanItems} Scanned in This Session`}
                />
              </div>
            )}
            {locationScanType !== "labelless package" && (
              <div className="action-item-box-shadow">
                {showActionItem &&
                  locationScanType !== "disposal" &&
                  locationScanType !== "redeliver" &&
                  locationScanType !== "warehouse outbound" && (
                    <ActionItem
                      to={getAddExceptionHref(locationScanType)}
                      imageSrc={DamagedParcel}
                      itemText="Add Exception(s)"
                      classNames="margin-top-20"
                    >
                      {exceptionTag && (
                        <button className="action-settings-buttons-green action-settings-buttons-color-margin-left">
                          {exceptionTag}
                        </button>
                      )}
                    </ActionItem>
                  )}
                {showActionItem && locationScanType !== "disposal" && locationScanType !== "redeliver" && (
                  <ActionItem
                    to={getUpdateWeightHref(locationScanType)}
                    imageSrc={WeighingScale}
                    itemText="Update Dimweigh"
                    classNames="action-item-top-border"
                  />
                )}
                {showActionItem &&
                  locationScanType !== "disposal" &&
                  locationScanType !== "redeliver" &&
                  locationScanType !== "warehouse outbound" && (
                    <ActionItem
                      to={getAddLocationtHref(locationScanType)}
                      imageSrc={LargeWarehouse}
                      itemText="Add Location"
                      classNames="action-item-top-border"
                    >
                      {addedLocation && (
                        <button className="action-settings-buttons-green action-settings-buttons-color-margin-left">
                          {addedLocation}
                        </button>
                      )}
                    </ActionItem>
                  )}
                {showActionItem && locationScanType === "sweep" && (
                  <ActionItem
                    to={getAddResourceHref(locationScanType)}
                    imageSrc={AddResource}
                    itemText="Add Resource"
                    classNames="action-item-top-border"
                  />
                )}
              </div>
            )}
            <div className="action-item-box-shadow">
              <ActionItem
                to={getSettingsHref(locationScanType)}
                imageSrc={DigitalInfrastructure}
                itemText="Settings"
                classNames={settingsClass}
              >
                {scanSettings.linkToShipment === undefined &&
                  scanSettings.recipientId === null &&
                  scanSettings.groupId === "" && <button className="action-settings-buttons">None</button>}
                {scanSettings.linkToShipment !== undefined && (
                  <button className={"action-settings-buttons-color " + shipmentActionItemClass}>Shipment</button>
                )}
                {(scanSettings.recipientId !== null || scanSettings.groupId !== "") && (
                  <button className="action-settings-buttons-color action-settings-buttons-color-margin-left">
                    Tagged
                  </button>
                )}
              </ActionItem>
            </div>
          </div>
        );
      }}
    </ParcelScannerContext.Consumer>
  );

  function getScannedNumberListHref(scanType) {
    return {
      pathname: "/scan/hubmanage/scanned-numbers-list",
      state: { scanType, pageName: "Parcel Scan", route: "/scan/hubmanage/parcel-scan" },
    };
  }

  function getAddExceptionHref(scanType) {
    return {
      pathname: "/scan/hubmanage/add-exception",
      state: { scanType, pageName: "Parcel Scan", route: "/scan/hubmanage/parcel-scan" },
    };
  }

  function getAddLocationtHref(scanType) {
    return {
      pathname: "/scan/hubmanage/add-location",
      state: { scanType, pageName: "Parcel Scan", route: "/scan/hubmanage/parcel-scan" },
    };
  }

  function getUpdateWeightHref(scanType) {
    return {
      pathname: "/scan/hubmanage/update-weight",
      state: { scanType, pageName: "Parcel Scan", route: "/scan/hubmanage/parcel-scan" },
    };
  }

  function getSettingsHref(scanType) {
    return {
      pathname: "/scan/hubmanage/scanner/settings",
      state: { scanType, pageName: "Parcel Scan", route: "/scan/hubmanage/parcel-scan" },
    };
  }

  function getAddResourceHref(scanType) {
    return {
      pathname: "/scan/hubmanage/scanner/add-resource",
      state: { scanType, pageName: "Parcel Scan", route: "/scan/hubmanage/parcel-scan" },
    };
  }
}
