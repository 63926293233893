export const commonTypes = {
  SET_SCANNED_NUMBERS_LIST: "SET_SCANNED_NUMBERS_LIST",
  SET_SCANNER_INFORMATION: "SET_SCANNER_INFORMATION",
  CLEAR_SCANNED_NUMBERS_LIST_DATA: "CLEAR_SCANNED_NUMBERS_LIST_DATA",

  GET_EXCEPTIONS_TYPES_LOADING: "GET_EXCEPTIONS_TYPES_LOADING",
  GET_EXCEPTIONS_TYPES_SUCCESS: "GET_EXCEPTIONS_TYPES_SUCCESS",
  GET_EXCEPTIONS_TYPES_FAILURE: "GET_EXCEPTIONS_TYPES_FAILURE",

  GET_RECIPIENTS_LIST_LOADING: "GET_RECIPIENTS_LIST_LOADING",
  GET_RECIPIENTS_LIST_SUCCESS: "GET_RECIPIENTS_LIST_SUCCESS",
  GET_RECIPIENTS_LIST_FAILURE: "GET_RECIPIENTS_LIST_FAILURE",

  GET_SHIPMENT_NUMBERS_LIST_LOADING: "GET_SHIPMENT_NUMBERS_LIST_LOADING",
  GET_SHIPMENT_NUMBERS_LIST_SUCCESS: "GET_SHIPMENT_NUMBERS_LIST_SUCCESS",
  GET_SHIPMENT_NUMBERS_LIST_FAILURE: "GET_SHIPMENT_NUMBERS_LIST_FAILURE",

  SET_VOICE_TYPE: "SET_VOICE_TYPE",
  SET_PRINTER: "SET_PRINTER"
}