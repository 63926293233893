import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import cameraIcon from "../../images/old-icons/camera.png";
import Scanner from "../../deprecated/scanner/Scanner";
import { Redirect } from "react-router";
import axios from "axios";
import { ROOT_URL } from "../../actions/index";
import ReactGA from "react-ga";

class HMSAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cta: "",
      menutcta: "",
      menuType: "",
      hubId: "",
      userId: "",
      isHubScanning: false,
      isUserScanning: false,
      result: "",
      err: false,
      isValid: false,
    };
  }

  componentDidMount() {
    ReactGA.initialize("UA-157972758-1");
    ReactGA.pageview(window.location.pathname + window.location.search);

    const url = window.location.href;
    let pageType = url.substring(url.lastIndexOf("/") + 1);
    let menuType = url.substring(url.lastIndexOf("/") - 9, url.lastIndexOf("/"));
    pageType = pageType.includes("?") ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("?")) : pageType;

    const ctas = {
      pickup: "Pick Up",
      deliver: "Deliver",
      receive: "Receive",
      transfer: "Transfer",
      login: "Login",
      scan: "Scan",
    };
    const menuctas = {
      firstmile: "Firstmile",
      warehouse: "Warehouse",
      hubmanage: "HMS Scanner",
    };

    if (!this.state.cta && !this.state.menucta) {
      this.setState({
        cta: ctas[pageType],
        menucta: menuctas[menuType],
        menuType: menuType,
      });
    }
  }

  scanHub() {
    this.setState({
      isHubScanning: !this.state.isHubScanning,
    });
  }

  scanUser() {
    this.setState({
      isUserScanning: !this.state.isUserScanning,
    });
  }

  inputHandler(e) {
    //const { name, value } = e.target;
    console.log(e);
  }

  registerHubID(result) {
    const barcode = result.codeResult.code.toUpperCase();
    this.setState({
      hubId: barcode,
      isHubScanning: false,
    });
  }

  registerUserID(result) {
    const barcode = result.codeResult.code.toUpperCase();
    this.setState({
      userId: barcode,
      isUserScanning: false,
    });
  }

  login(hubid, userid) {
    return axios.get(`${ROOT_URL}/hms/get_valid_scan_types/`, {
      params: {
        hub_id: hubid,
        hubscanner_key: userid,
      },
    });
  }

  async validate() {
    try {
      const res = await this.login(this.state.hubId, this.state.userId);
      let scanTypes = JSON.stringify(res.data.valid_scan_types);
      localStorage.setItem("hubId", this.state.hubId);
      localStorage.setItem("userId", this.state.userId);
      localStorage.setItem("userName", res.data.hub_scanner_name);
      localStorage.setItem("scanTypes", scanTypes);
      localStorage.setItem("qubevuIP", "127.0.0.1");
      localStorage.setItem("qubevuStatus", "disabled");

      ReactGA.event({
        category: "HMS Login",
        action: "Login Validation Success",
      });
      this.setState({
        isValid: true,
      });
    } catch (e) {
      ReactGA.event({
        category: "HMS Login",
        action: "Login Validation Failed",
      });
      this.setState({
        err: true,
      });

      setTimeout(() => {
        this.setState({
          err: false,
        });
      }, 5000);
    }
  }

  render() {
    if (this.state.isValid) {
      return <Redirect push to="/scan/hubmanage/old/parcel-scan" />;
    }

    return (
      <div className="main-container d-flex flex-column align-items-left">
        <h1 className="font-weight-bolder pl-0">
          <Trans>{this.state.cta}</Trans>
        </h1>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <LinkContainer to="/scan">
                <a>
                  <Trans>Home</Trans>
                </a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item">
              <LinkContainer to={`/scan/${this.state.menuType}/login`}>
                <a>
                  <Trans>{this.state.menucta}</Trans>
                </a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Trans>{this.state.cta}</Trans>
            </li>
          </ol>
        </nav>
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <div id="hub-id" style={{ marginBottom: 1 + "rem" }}>
              <label>
                <Trans>Hub ID</Trans>
              </label>
              <div className="input-group">
                <input
                  id="hubId"
                  name="hubId"
                  type="text"
                  className="form-control"
                  value={this.state.hubId}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    this.setState({
                      hubId: inputVal,
                      isHubScanning: false,
                    });
                  }}
                />
                <div className="input-group-append align-items-left">
                  <button className="ml-2 btn-janio-light rounded-btn" onClick={this.scanHub.bind(this)} type="button">
                    <img src={cameraIcon} />
                  </button>
                </div>
                {this.state.isHubScanning ? <Scanner onDetected={this.registerHubID.bind(this)} /> : null}
              </div>
            </div>

            <div id="user-id">
              <label>
                <Trans>User Key</Trans>
              </label>
              <div className="input-group">
                <input
                  id="userId"
                  name="userId"
                  type="password"
                  className="form-control"
                  value={this.state.userId}
                  onChange={(e) => {
                    const inputVal = e.target.value;
                    this.setState({
                      userId: inputVal,
                    });
                  }}
                />
                <div className="input-group-append align-items-">
                  <button className="ml-2 btn-janio-light rounded-btn" onClick={this.scanUser.bind(this)} type="button">
                    <img src={cameraIcon} />
                  </button>
                </div>
                {this.state.isUserScanning ? <Scanner onDetected={this.registerUserID.bind(this)} /> : null}
              </div>
            </div>
          </div>
        </div>

        <div className="py-3">
          <div className="input-group">
            <button
              className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}
              onClick={this.validate.bind(this)}
            >
              <Trans>Login</Trans>
            </button>
          </div>
        </div>
        {this.state.err === true ? (
          <div className="alert alert-danger" role="alert">
            Invalid Credentials. Please try again.
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HMSAuth);
