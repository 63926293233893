import React, { Component } from "react";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import * as qz from "qz-tray";
import { sha256 } from "js-sha256";
import ReactGA from "react-ga";
import { Redirect } from "react-router";
import { Trans } from "react-i18next";
import { Menu, Dropdown, Alert, Select, Radio } from "antd";
import "antd/dist/antd.css";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";
import { QZ_TRAY_CERTIFICATE, QZ_TRAY_PRIVATE_KEY } from "../QzTrayCredentials";

const { Option } = Select;

var RSVP = require("rsvp");

class HMSSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubId: "",
      userId: "",
      userName: "",
      isLoggedIn: true,
      qubevuIP: "",
      qubevuStatus: "disabled",
      ipErrorMessage: "",
      settingUpdateSuccess: false,
      requireErrorAcknowledgment: false,
      showQzTrayConnect: false,
      showQzTrayConnectAlert: false,
      qzTrayDisconnect: false,
      showQzTrayDisconnectAlert: false,
      defaultPrinter: "",
      printersList: [],
      connectionError: null,
      defaultAudioType: "consigneeCountry",
    };
  }
  clearSession() {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("hubId");
    localStorage.removeItem("scanTypes");
    localStorage.removeItem("sessionId");
    localStorage.removeItem("qubevuIP");
    localStorage.removeItem("qubevuStatus");
    localStorage.removeItem("defaultPrinter");
    localStorage.removeItem("printersList");
    localStorage.removeItem("isQzConnected");

    this.setState({
      isLoggedIn: false,
    });
  }
  componentDidMount() {
    ReactGA.initialize("UA-157972758-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
    const url = window.location.href;
    let pageType = url.substring(url.lastIndexOf("/") + 1);
    let menuType = url.substring(url.lastIndexOf("/") - 9, url.lastIndexOf("/"));
    pageType = pageType.includes("?") ? url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("?")) : pageType;

    const ctas = {
      settings: "Settings",
    };
    const menuctas = {
      hubmanage: "HMS Scanner",
    };

    if (!this.state.cta && !this.state.menucta) {
      this.setState({
        cta: ctas[pageType],
        menucta: menuctas[menuType],
        menuType: menuType,
      });
    }
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      this.setState({
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        requireErrorAcknowledgment: localStorage.getItem("requireErrorAcknowledgment") === "true",
        qubevuStatus: localStorage.getItem("qubevuStatus"),
        qubevuIP: localStorage.getItem("qubevuIP"),
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
    this.setDefaultPrinter();
    this.setDefaultVoiceType();
  }

  toggleRequireErrorAcknowledgment = () => {
    let requireErrorAcknowledgment = !this.state.requireErrorAcknowledgment;
    this.setState(
      {
        requireErrorAcknowledgment,
      },
      localStorage.setItem("requireErrorAcknowledgment", requireErrorAcknowledgment)
    );
  };

  setDefaultPrinter = () => {
    let defaultPrinter = localStorage.getItem("defaultPrinter");
    let isQzConnected = localStorage.getItem("isQzConnected");
    let printersList = localStorage.getItem("printersList");
    if (defaultPrinter !== null) {
      this.setState({ defaultPrinter: defaultPrinter });
    }
    if (isQzConnected !== null) {
      this.setState({ showQzTrayConnect: true });
    }
    if (printersList !== null) {
      let parsePrintersList = JSON.parse(printersList);
      console.log("parsePrintersList", parsePrintersList);
      this.setState({ printersList: parsePrintersList });
    }
  };

  // set default voice type (scan)
  setDefaultVoiceType = () => {
    let defaultAudioType = localStorage.getItem("defaultAudioType");
    if (defaultAudioType !== null) {
      this.setState({ defaultAudioType: defaultAudioType });
    }
  };

  handleOnChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  updateQubeVuStatus = (event) => {
    const status = event.target.value;
    this.setState({ qubevuStatus: status });
  };
  submitValue = () => {
    let ipAddress = this.state.qubevuIP;
    const qubevuStatus = this.state.qubevuStatus;
    const regexIP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (qubevuStatus === "enabled") {
      if (!ipAddress) {
        this.setState({
          ipErrorMessage: "IP Address is required",
          settingUpdateSuccess: false,
        });
        ipAddress = "";
      } else if (!regexIP.test(ipAddress)) {
        this.setState({
          ipErrorMessage: "Incorrect IP Address Format",
          settingUpdateSuccess: false,
        });
        ipAddress = "";
      } else {
        localStorage.setItem("qubevuIP", ipAddress);
        localStorage.setItem("qubevuStatus", qubevuStatus);
        this.setState({ settingUpdateSuccess: true, ipErrorMessage: "" });
      }
    } else {
      ipAddress = "";
      localStorage.setItem("qubevuIP", ipAddress);
      localStorage.setItem("qubevuStatus", qubevuStatus);
      this.setState({ settingUpdateSuccess: true, ipErrorMessage: "" });
    }
  };

  getMenu = () => {
    return (
      <Menu>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/parcel-scan">Parcel Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/cargo-scan">Cargo Scan</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/shipments">Shipments</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/pick-list">Pick List</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/disposal">Disposal</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/redeliver">Redeliver</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/rtv">RTV</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/reports">Reports</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/scan/hubmanage/old/settings">Settings</Link>
        </Menu.Item>
      </Menu>
    );
  };

  // set value on audio type change
  onAudioTypeChange = async (e) => {
    this.setState({ defaultAudioType: e.target.value });
    localStorage.setItem("defaultAudioType", e.target.value);
  };

  render() {
    const settingInputClass = this.state.settingUpdateSuccess ? "form-control success-green" : "form-control";
    if (!this.state.isLoggedIn) {
      return <Redirect push to="/scan/hubmanage/old/login" />;
    }
    return (
      <div className="main-container d-flex flex-column align-items-left">
        <div>
          <h1 className="font-weight-bolder pl-0 float-left">
            <Trans>{this.state.cta}</Trans>
          </h1>
          <div className="float-right mt-2">
            <Dropdown overlay={this.getMenu()} placement="bottomLeft" trigger={["click"]}>
              <button type="button" className="btn btn-outline-primary px-4" style={{ marginRight: "5px" }}>
                Menu
              </button>
            </Dropdown>
            <button type="button" className="btn btn-outline-danger" onClick={this.clearSession.bind(this)}>
              End Session
            </button>
          </div>
        </div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <LinkContainer to="/scan">
                <a>
                  <Trans>Home</Trans>
                </a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item">
              <LinkContainer to={`/scan/${this.state.menuType}`}>
                <a>
                  <Trans>{this.state.menucta}</Trans>
                </a>
              </LinkContainer>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Trans>{this.state.cta}</Trans>
            </li>
          </ol>
        </nav>
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <div id="hub-id" style={{ marginBottom: 1 + "rem" }}>
              <label>
                <Trans>Hub ID</Trans>
              </label>
              <div>
                <label>{this.state.hubId}</label>
              </div>
            </div>
            <div id="user-id">
              <label>
                <Trans>User ID</Trans>
              </label>
              <div>
                <label>{this.state.userName}</label>
              </div>
            </div>
          </div>
        </div>

        {/* Choose Scan Audio Alert */}
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group px-3.5">
            <div className="d-flex flex-column">
              <h5 className="font-weight-bolder pl-0 float-left mt-3">Choose Audio Alert</h5>
              <Radio.Group
                className="d-sm-block d-flex flex-column"
                onChange={this.onAudioTypeChange}
                value={this.state.defaultAudioType}
              >
                <Radio value="consigneeCountry">Country</Radio>
                <Radio value="consigneePostalPreFix">Postal Prefix</Radio>
                <Radio value="networkPartner">Partner Name</Radio>
                <Radio value="noOfParcelsScanned">No of Parcels Scanned</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>

        {/* Require Error Acknowledgement */}
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group px-3.5">
            <h5 className="font-weight-bolder pl-0 float-left mt-3">Require Error Acknowledgement</h5>
            <div className="float-right mt-2 mr-n1">
              {!this.state.requireErrorAcknowledgment ? (
                <button
                  type="button"
                  className="btn btn-outline-danger"
                  onClick={this.toggleRequireErrorAcknowledgment}
                >
                  Disabled
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-janio text-white"
                  onClick={this.toggleRequireErrorAcknowledgment}
                >
                  Enabled
                </button>
              )}
            </div>
          </div>
        </div>

        {/* QZ Tray */}
        <div className="content-container card mt-1 mb-1 p-2">
          <div className="form-group px-3.5">
            <h5 className="font-weight-bolder pl-0 float-left mt-3">QZ Tray</h5>
            <div className="float-right mt-2 mr-n1">
              {!this.state.showQzTrayConnect ? (
                <button type="button" className="btn btn-janio text-white" onClick={this.connectQzTray}>
                  Connect
                </button>
              ) : (
                <button type="button" className="btn btn-outline-danger" onClick={this.disconnectQzTray}>
                  Disconnect
                </button>
              )}
            </div>
          </div>

          {this.state.showQzTrayConnectAlert && (
            <div className="form-group px-3.5">
              <Alert message="QZ Tray Connected" type="success" />
            </div>
          )}
          {this.state.showQzTrayDisconnectAlert && (
            <div className="form-group px-3.5">
              <Alert message="QZ Tray Disconnected" type="success" />
            </div>
          )}
          {this.state.connectionError && (
            <div className="form-group px-3.5">
              <Alert message={this.getQzErrorMessage()} type="error" />
            </div>
          )}
          {this.state.printersList && this.state.printersList.length > 0 && (
            <div className="form-group px-3.5">
              <div className="d-flex flex-nowrap mb-2">
                <label className="font-weight-bold">Current Printer :</label>&nbsp;&nbsp;
                <span className="font-italic">{this.state.defaultPrinter}</span>
              </div>
              <label>Available Printers</label>
              <Select
                name="defaultPrinter"
                defaultValue={this.state.defaultPrinter}
                value={this.state.defaultPrinter}
                className="w-100"
                onChange={this.changeDefaultPrinter}
              >
                {this.state.printersList.map((printer) => {
                  return (
                    <Option key={printer} value={printer}>
                      {printer}
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        </div>
        <div className="content-container card mb-1 p-2">
          <div className="form-group d-block justify-content-between px-3.5">
            <label>
              <Trans>QubeVu IP Address</Trans>
            </label>
            <div>
              <input
                className={settingInputClass}
                type="text"
                name="qubevuIP"
                placeholder="Eg. 192.168.0.1"
                value={this.state.qubevuIP}
                onChange={(e) => this.handleOnChange(e)}
              />
              {this.state.ipErrorMessage && (
                <label className="err-title text-danger">{this.state.ipErrorMessage}</label>
              )}
            </div>
          </div>
          <div className="form-group d-block justify-content-between px-3.5">
            <label>
              <Trans>QubeVu Enabled/Disabled</Trans>
            </label>
            <div>
              <select
                className={settingInputClass}
                value={this.state.qubevuStatus}
                onChange={this.updateQubeVuStatus.bind(this)}
              >
                <option value="enabled">Enabled</option>
                <option defaultValue value="disabled">
                  Disabled
                </option>
              </select>
            </div>
          </div>
          <div className="d-block justify-content-between px-3.5">
            <button
              className={`btn btn-lg btn-janio text-white btn-block rounded-btn`}
              onClick={this.submitValue.bind(this)}
            >
              <Trans>Save Settings</Trans>
            </button>
          </div>
        </div>
      </div>
    );
  }

  // error message
  getQzErrorMessage = () => {
    if (this.state.connectionError == "Unable to establish connection with QZ") {
      return (
        <React.Fragment>
          <span>Unable to establish connection with QZ Tray - Please download and install it </span>
          <a href="https://drive.google.com/file/d/1y4okKFUJ0k1gqhGOZcGrNhUoGBNMaPJ0/view" target="_blank">
            here
          </a>
        </React.Fragment>
      );
    }
    return this.state.connectionError;
  };

  // connect qz-tray / find & connect printers
  connectQzTray = (e) => {
    e.preventDefault();

    qz.security.setCertificatePromise(function (resolve, reject) {
      resolve(QZ_TRAY_CERTIFICATE);
    });

    var privateKey = QZ_TRAY_PRIVATE_KEY;

    qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        try {
          var pk = KEYUTIL.getKey(privateKey);
          var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
          sig.init(pk);
          sig.updateString(toSign);
          var hex = sig.sign();
          resolve(stob64(hextorstr(hex)));
        } catch (err) {
          console.error(err);
          reject(err);
        }
      };
    });

    qz.api.setSha256Type((data) => sha256(data));
    qz.api.setPromiseType((resolver) => new Promise(resolver));
    qz.websocket
      .connect()
      .then(() => {
        this.setState({ showQzTrayConnect: true, showQzTrayConnectAlert: true });
        localStorage.setItem("isQzConnected", true);
        setTimeout(() => {
          this.setState({ showQzTrayConnectAlert: false });
        }, 5000);
        qz.printers
          .getDefault()
          .then((defaultPrinter) => {
            this.setState({ defaultPrinter: defaultPrinter });
            localStorage.setItem("defaultPrinter", defaultPrinter);
            qz.printers
              .find()
              .then((printersList) => {
                this.setState({ printersList: printersList });
                let stringifyPrintersList = JSON.stringify(printersList);
                localStorage.setItem("printersList", stringifyPrintersList);
              })
              .catch((e) => console.log("Error", e));
          })
          .catch((e) => console.log("Error", e));
      })
      .catch((e) => {
        this.setState({ connectionError: e.message });
        setTimeout(() => {
          this.setState({ connectionError: null });
        }, 10000);
      });
  };

  // disconnect qz tray
  disconnectQzTray = (e) => {
    e.preventDefault();
    qz.api.setPromiseType(function promise(resolver) {
      return new RSVP.Promise(resolver);
    });
    qz.websocket
      .disconnect()
      .then(() => {
        localStorage.removeItem("defaultPrinter");
        localStorage.removeItem("printersList");
        localStorage.removeItem("isQzConnected");
        this.setState({
          showQzTrayConnect: false,
          showQzTrayDisconnectAlert: true,
          printersList: [],
          defaultPrinter: "",
        });
        setTimeout(() => {
          this.setState({ showQzTrayDisconnectAlert: false });
        }, 6000);
      })
      .catch((e) => console.log("Error", e));
  };

  // change default printers
  changeDefaultPrinter = (value) => {
    this.setState({ defaultPrinter: value });
    localStorage.setItem("defaultPrinter", value);
  };
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps, {})(HMSSettings);
