/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { shipmentActions, commonActions } from "../../../actions/rootActions";
import ShipmentData from "./shipmentData";
import { SUCCESS, VISIT_SHIPMENTS_PAGE } from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";

function Shipments(props) {
  const { userData, shipmentNumbersList } = props;

  useEffect(() => {
    getShipmentNumbersListData();
    getUserData();
    props.dispatch(shipmentActions.fetchRecipients());
  }, []);

  useEffect(() => {
    if (userData.isLoggedIn) {
      const mixPanelData = getMixpanelData();
      mixPanelTrack(VISIT_SHIPMENTS_PAGE, {
        [SUCCESS]: true,
        ...mixPanelData,
      });
    }
  }, [userData]);

  if (userData.isLoggedIn === false) {
    return <Redirect push to="/scan/hubmanage/login" />;
  }

  return <ShipmentData shipmentNumbersList={shipmentNumbersList.shipments} shipmentNumbersData={shipmentNumbersList} />;

  // get shipments list
  function getShipmentNumbersListData() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      page: 1,
    };
    props.dispatch(shipmentActions.getShipmentNumbersList(data));
  }

  function getUserData() {
    if (localStorage.getItem("hubId") !== null && localStorage.getItem("userId") !== null) {
      const data = {
        hubId: localStorage.getItem("hubId"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
        isLoggedIn: true,
      };
      props.dispatch(commonActions.setScannerInformation(data));
    } else {
      const data = {
        isLoggedIn: false,
      };
      props.dispatch(commonActions.setScannerInformation(data));
    }
  }
}

const mapStateToProps = (state) => ({
  shipmentNumbersList: state.shipment.shipmentNumbersList,
  partners: state.shipment.partners,
  userData: state.common.scannerInformation,
});

export default connect(mapStateToProps, null)(Shipments);

