// @ts-nocheck
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { shipmentActions, cargoActions } from "../../../actions/rootActions";
import { Alert, Form, Divider, message } from "antd";
import { shipmentApi } from "../../../api";
import { connect } from "react-redux";
import Select from "../../../common/select/select";
import CustomInput from "../../../common/input/input";
import TextArea from "../../../common/textArea";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import * as _ from "lodash";

function CreateShipment(props) {
  const createShipmentsRef = useRef();
  const {
    userData,
    partners,
    createShipmentErr
  } = props;

  const [shipmentType, setShipmentType] = useState(undefined);
  const [regularShipmentData, setRegularShipmentData] = useState({
    selectedRecipientId: undefined,
    selectedRecipientName: undefined,
  });
  const [countriesList, setCountriesList] = useState([]);
  const [states, setStates] = useState([]);
  const [rtvShipmentData, setRtvShipmentData] = useState({
    recipient_name: "abc",
    recipient_company: "RTV",
    recipient_postal_code: "abc123",
    recipient_address: "abc123",
    recipient_country: "Singapore",
    recipient_state: "Singapore",
  });

  const customizedPartners = partners.map((partner) => {
    partner.label = partner.name;
    partner.value = partner.id;
    return partner;
  });

  const shipmentTypesOptions = [
    { label: "Regular", value: "regular" },
    { label: "RTV/RTS", value: "rtv" },
  ];

  useEffect(() => {
    if (props.newShipmentNo) {
      message.success("Shipment has been created");
      goBackToPreviousRoute();
      resetReduxState();
      props.dispatch(shipmentActions.clearShipmentResponse());
    }
  }, [props.newShipmentNo]);

  useEffect(() => {
    getCountriesList();
  }, []);

  return (
    <div className="d-flex flex-column align-items-center w-100">
      <div className="bg-container full-width-container">
        <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
          <p className="link-title mb-0" onClick={goBackToPreviousRoute}>Shipments</p>
          <p className="bread-crumb mt-n1 mb-0">{">"}</p>
          <p className="title text-capitalize mb-0">Create Shipment</p>
        </div>
        <div className="bg-container-body">
          <div className="d-flex flex-column">
            <h2 className="text-heading-black">Create Shipment</h2>
            <Form
              ref={createShipmentsRef}
              name="control-ref"
              onFinish={onSubmit}
            >
              <div className="row">
                <div className="col-sm-4 col-12">
                  <Form.Item
                    name="shipmentType"
                    rules={[
                      {
                        required: true,
                        message: "Please select shipment type first",
                      },
                    ]}
                    className="margin-top-20 mb-0"
                  >
                    <Select
                      name="shipmentType"
                      label="Shipment Type"
                      placeholder="Select"
                      value={shipmentType}
                      options={shipmentTypesOptions}
                      onChange={handleShipmentTypeChange}
                    ></Select>
                  </Form.Item>
                </div>
                {shipmentType !== undefined && shipmentType !== 'rtv' &&
                  <div className="col-12 pt-1 pt-sm-0">
                    <Divider className="gray-divider-30" />
                  </div>
                }

                {shipmentType === "regular" && (
                  <div className="col-sm-4 col-12">
                    <Form.Item
                      name="selectedRecipientId"
                      rules={[
                        {
                          required: true,
                          message: "Please select a partner first",
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        name="selectedRecipientId"
                        label="Recipient"
                        placeholder="Select network partner to send"
                        value={regularShipmentData.selectedRecipientId}
                        options={customizedPartners}
                        onChange={handlePartnerToSendChange}
                      ></Select>
                    </Form.Item>
                  </div>
                )}
              
                {/* {shipmentType === "rtv" && (
                  <React.Fragment>
                    <div className="col-sm-4 col-12">
                      <Form.Item
                        name="recipient_name"
                        rules={[
                          {
                            required: true,
                            message: "Please type recipient name first",
                          },
                        ]}
                      >
                        <CustomInput
                          name="recipient_name"
                          label="Recipient Name"
                          placeholder="Type here"
                          value={rtvShipmentData.recipient_name}
                          onChange={(e) =>
                            onRtvShipmentDataChange(
                              "recipient_name",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-4 col-12">
                      <Form.Item
                        name="recipient_company"
                        rules={[
                          {
                            required: true,
                            message: "Please type recipient company first",
                          },
                        ]}
                      >
                        <CustomInput
                          name="recipient_company"
                          label="Recipient Company"
                          placeholder="Type here"
                          value={rtvShipmentData.recipient_company}
                          onChange={(e) =>
                            onRtvShipmentDataChange(
                              "recipient_company",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-4 col-12">
                      <Form.Item
                        name="recipient_country"
                        rules={[
                          {
                            required: true,
                            message: "Please select a country first",
                          },
                        ]}
                      >
                        <Select
                          name="recipient_country"
                          label="Recipient Country"
                          placeholder="Select"
                          value={rtvShipmentData.recipient_country}
                          options={countriesList}
                          onChange={(value) =>
                            onCountryChange("recipient_country", value)
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-4 col-12">
                      <Form.Item
                        name="recipient_state"
                        rules={[
                          {
                            required: true,
                            message: "Please select a state first",
                          },
                        ]}
                        className="margin-top-20"
                      >
                        <Select
                          name="recipient_state"
                          label="Recipient State"
                          placeholder="Select"
                          value={rtvShipmentData.recipient_state}
                          options={states}
                          onChange={(value) =>
                            onRtvShipmentDataChange("recipient_state", value)
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="col-sm-4 col-12">
                      <Form.Item
                        name="recipient_postal_code"
                        rules={[
                          {
                            required: true,
                            message: "Please type postal code first",
                          },
                        ]}
                        className="margin-top-20"
                      >
                        <CustomInput
                          name="recipient_postal_code"
                          label="Recipient Postal Code"
                          placeholder="Type here"
                          value={rtvShipmentData.recipient_postal_code}
                          onChange={(e) =>
                            onRtvShipmentDataChange(
                              "recipient_postal_code",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="d-none d-sm-block col-sm-4 col-12">
                      <Form.Item
                        name="recipient_address"
                        className="margin-top-20"
                        rules={[
                          {
                            required: true,
                            message: "Please type an address first",
                          },
                        ]}
                      >
                        <CustomInput
                          label="Recipient Address"
                          placeholder="Type here"
                          name="recipient_address"
                          value={rtvShipmentData.recipient_address}
                          onChange={(e) =>
                            onRtvShipmentDataChange(
                              "recipient_address",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                    <div className="d-block d-sm-none col-sm-4 col-12">
                      <Form.Item
                        name="recipient_address"
                        className="margin-top-20 mb-0"
                        rules={[
                          {
                            required: true,
                            message: "Please type an address first",
                          },
                        ]}
                      >
                        <TextArea
                          rows={3}
                          label="Recipient Address"
                          placeholder="Type here"
                          name="recipient_address"
                          value={rtvShipmentData.recipient_address}
                          onChange={(e) =>
                            onRtvShipmentDataChange(
                              "recipient_address",
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    </div>
                  </React.Fragment>
                )} */}

                <div className="col-12 pt-1 pt-sm-0">
                  <Divider className="gray-divider-30" />
                </div>
              </div>
              <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
                <button
                  type="button"
                  className="secondary-button-lg-xs"
                  onClick={goBackToPreviousRoute}
                >
                  Cancel
                </button>
                <button className="primary-button mb-sm-0 mb-3" type="submit" disabled={props.createShipmentProgress}>
                  {props.createShipmentProgress ? <LoadingOutlined className="loading-spinner loading-spinner-white" /> : "Create Shipment"}
                </button>
              </div>
            </Form>
            {createShipmentErr && createShipmentErr.hub_id && (
              <Alert
                message={createShipmentErr.hub_id[0]}
                type="error"
                closable
                onClose={this.closeShipmentError}
                className="mb-3 ml-3 mr-3"
              />
            )}
            {createShipmentErr && createShipmentErr.hubscanner_key && (
              <Alert
                message={createShipmentErr.hubscanner_key[0]}
                type="error"
                closable
                onClose={this.closeShipmentError}
                className="mb-3 ml-3 mr-3"
              />
            )}
            {createShipmentErr && createShipmentErr.next_recipient_id && (
              <Alert
                message={createShipmentErr.next_recipient_id[0]}
                type="error"
                closable
                onClose={this.closeShipmentError}
                className="mb-3 ml-3 mr-3"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );

  function goBackToPreviousRoute() {
    props.history.goBack();
  }

  function handleShipmentTypeChange(value) {
    setShipmentType(value);
  }

  function handlePartnerToSendChange(value, option) {
    setRegularShipmentData({
      ...regularShipmentData,
      selectedRecipientId: value,
      selectedRecipientName: option.children,
    });
    createShipmentsRef.current.setFieldsValue({
      selectedRecipientId: value,
    });
  }

  function onRtvShipmentDataChange(name, value) {
    setRtvShipmentData({
      ...rtvShipmentData,
      [name]: value,
    });
  }

  function onCountryChange(name, value) {
    setRtvShipmentData({
      ...rtvShipmentData,
      [name]: value,
    });
    const data = { country: value };
    getStates(data);
  }

  function onSubmit() {
    if (shipmentType === "rtv") {
      createRtvShipment();
    } else {
      createRegularShipment();
    }
  }

  function createRegularShipment() {
    const data = {
      hub_id: userData.hubId,
      hubscanner_key: userData.userId,
      next_recipient_id: regularShipmentData.selectedRecipientId,
    };
    props.dispatch(shipmentActions.createShipment(data));
  }

  async function createRtvShipment() {
    const data = {
      recipient_name: rtvShipmentData.recipient_name,
      recipient_company: rtvShipmentData.recipient_company,
      recipient_country: rtvShipmentData.recipient_country,
      recipient_postal: rtvShipmentData.recipient_postal_code,
      recipient_state: rtvShipmentData.recipient_state,
      recipient_address: rtvShipmentData.recipient_address,
      hub_id: userData.hubId,
      hubscanner_key: userData.userId,
    }
    props.dispatch(shipmentActions.createRTVShipment(data));
  }

  async function getCountriesList() {
    let countriesList = await axios.get(
      `https://hms.janio.asia/api/location/countries/`
    );
    countriesList = countriesList.data;
    countriesList = _.orderBy(countriesList, "country_name", "asc");
    countriesList = countriesList.map((country) => {
      country.value = country.country_name;
      country.label = country.country_name;
      return country;
    });
    setCountriesList(countriesList);
  }

  async function getStates(data) {
    let states = await axios({
      method: "GET",
      url: `https://hms.janio.asia/api/location/states/?countries=${data.country}`,
    });
    states = states.data;
    states = _.orderBy(states, "state_name", "asc");
    states = states.map((state) => {
      state.value = state.state_name;
      state.label = state.state_name;
      return state;
    });
    setStates(states);
  }

  function resetReduxState() {
    props.dispatch(cargoActions.clearCargoResponse());
    props.dispatch(cargoActions.clearCreateCargoError());
    props.dispatch(shipmentActions.clearShipmentError());
    props.dispatch(shipmentActions.clearShipmentResponse());
    props.dispatch(cargoActions.clearCargoState());
    props.dispatch(cargoActions.clearCreateCargoResponse());
    props.dispatch(cargoActions.clearAddCargoToShipmentResponse());
  }
}

const mapStateToProps = (state) => ({
  newShipmentNo: state.shipment.shipment_no,
  createShipmentProgress: state.shipment.createShipmentProgress,
  createShipmentErr: state.shipment.createShipmentErr,
  partners: state.shipment.partners,
  userData: state.common.scannerInformation
});

export default connect(mapStateToProps, null)(CreateShipment);