import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from '../utils/historyUtils';
import { scanReset } from '../actions/scanActions';
import _ from 'lodash';

import compose from 'recompose/compose';
import { withNamespaces, Trans } from 'react-i18next';

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  renderResults = (results) => {
    return _.reverse(_.map(results, (result) => {
      return (
        <li className="list-group-item d-flex justify-content-center" key={result}>
          {result}
        </li>
      )
    }));
  }

  render() {
    const props = this.props;

    let renderDiv = null;
    if (props !== undefined && props.response) {
      renderDiv = <div className="content-container card mt-1 mb-3 p-4">

        <div className="h2 text-center mb-3">
          <Trans>Results</Trans>
        </div>

        {
          props.response.updated_orders && props.response.updated_orders.length > 0 ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                <Trans>Updated</Trans>
            </div>
              {this.renderResults(props.response.updated_orders)}
            </div>
            :
            null
        }

        {
          props.response.picked_up_orders && props.response.picked_up_orders.length > 0 && props.cta === 'Pick Up' ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                <Trans>Already Picked Up</Trans>
            </div>
              {this.renderResults(props.response.picked_up_orders)}
            </div>
            :
            null
        }

        {
          props.response.delivered_orders && props.response.delivered_orders.length > 0 && props.cta === 'Deliver' ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                <Trans>Already Delivered</Trans>
            </div>
              {this.renderResults(props.response.delivered_orders)}
            </div>
            :
            null
        }

        {
          props.response.received_orders && props.response.received_orders.length > 0 && props.cta === 'Receive' ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                <Trans>Already Received</Trans>
            </div>
              {this.renderResults(props.response.received_orders)}
            </div>
            :
            null
        }

        {
          props.response.transferred_orders && props.response.transferred_orders.length > 0 && props.cta === 'Transfer' ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                <Trans>Already Transferred</Trans>
            </div>
              {this.renderResults(props.response.transferred_orders)}
            </div>
            :
            null
        }

        {
          props.response.changed_weight_orders && props.response.changed_weight_orders.length > 0 ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                Changed Weight
            </div>
              {this.renderResults(props.response.changed_weight_orders)}
            </div>
            :
            null
        }

        {
          props.response.non_changed_weight_orders && props.response.non_changed_weight_orders.length > 0 ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                Error Changing Weight
            </div>
              {this.renderResults(props.response.non_changed_weight_orders)}
            </div>
            :
            null
        }

        {
          props.response.non_existent_tracking_nos && props.response.non_existent_tracking_nos.length > 0 ?
            <div className="mb-3">
              <div className="h5 text-center mb-1 text-muted">
                <Trans>Not Found</Trans>
            </div>
              {this.renderResults(props.response.non_existent_tracking_nos)}
            </div>
            :
            null
        }

        <button
          className="btn btn-janio btn-lg text-white w-100 mt-3"
          onClick={() => {
            scanReset();
            history.goBack()
          }}>
          <Trans>RETURN</Trans>
        </button>

      </div>;
    }

    return (
      <div>
        <h1 className='font-weight-bolder pl-0'><Trans>{this.state.cta}</Trans></h1>
        {renderDiv}
      </div>
    )
  }
}

function mapStateToProps() {
  return ({

  });
}

export default compose(
  connect(mapStateToProps, {}),
  withNamespaces()
)(Success);
