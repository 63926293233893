import React from "react";
import { Divider } from "antd";
import { LinkContainer } from "react-router-bootstrap";
import { LoadingOutlined } from "@ant-design/icons";

export default function CancelCargo(props) {
  const { onCancelCargo, requestInProgress } = props;

  const cargo = props.location.state.cargo;

  return (
    <div className="scanner-bg-container">
      <div className="d-flex flex-column align-items-center">
        <div className="bg-container mt-0">
          <div className="d-flex ml-sm-0 ml-3 bread-crumb-container">
            <LinkContainer to="/scan/hubmanage/cargo-scan">
              <p className="link-title mb-0">Cargo Scan</p>
            </LinkContainer>
            <p className="bread-crumb mt-n1 mb-0">{">"}</p>
            <p className="title text-capitalize mb-0">Cancel Cargo</p>
          </div>
          <div className="bg-container-body">
            <h2 className="text-heading-black">Please confirm cargo cancellation</h2>
            <p className="text-span-black margin-top-20 mb-0">
              This cargo will be inactive but still remain in the system.
            </p>
            <Divider className="gray-divider" />
            <div className="d-flex justify-content-sm-between flex-sm-row flex-column flex-column-reverse mb-0">
              <button className="secondary-button-small" onClick={goBackToPreviousScreen}>
                Back
              </button>
              <button
                type="submit"
                className="primary-button-small mb-sm-0 mb-3"
                disabled={requestInProgress}
                onClick={handleCancelCargoClick}
              >
                {requestInProgress ? <LoadingOutlined className="loading-spinner loading-spinner-white" /> : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function handleCancelCargoClick() {
    const data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      tracking_no: cargo.tracking_no,
    };
    onCancelCargo(data);
  }

  function goBackToPreviousScreen() {
    props.history.goBack();
  }
}
