/* eslint-disable react-hooks/rules-of-hooks */
import axios from "axios";
import pdf2base64 from "pdf-to-base64";

import * as qz from "qz-tray";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";
import React, { useEffect, useState } from "react";
import { cargoApi } from "../../../api";
import {
  CARGO_TRACKING_NUMBER,
  ERROR,
  ERROR_TYPE,
  PRINT_CARGO_LABEL,
  SHIPMENT_TRACKING_NUMBER,
  SUCCESS,
} from "../../../constants/mixpanelConstants";
import SecurityTagDetails from "../cargoScanner/SecurityTagDetails";

export default function CargoDetails(props) {
  const { cargo, onClickRedirectToShipmentDetails } = props;

  const [showQzTrayNotActiveError, setShowQzTrayNotActiveError] = useState(false);
  const [defaultPrinter, setDefaultPrinter] = useState(null);

  useEffect(() => {
    const printer = localStorage.getItem("defaultPrinter");
    setDefaultPrinter(printer);
  }, []);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-sm-12">
          <div className="details-box cargo-details-box">
            <h2 className="text-heading-black">Cargo Details</h2>
            <div className="row">
              <div className="col-6 margin-top-30">
                <div className="text-span-label">Link to Shipment</div>
                <div className="text-span-link margin-top-10 text-break" onClick={onClickRedirectToShipmentDetails}>
                  {cargo.shipment_no}
                </div>
              </div>
              <div className="col-6 margin-top-30">
                <div className="text-span-label">Cargo Type</div>
                <div className="text-span-black margin-top-10">{cargo.cargo_type}</div>
              </div>
              <div className="col-6 margin-top-30">
                <div className="text-span-label">Destination Country</div>
                <div className="text-span-black margin-top-10">{cargo.dest_country_code || cargo.dest_country}</div>
              </div>
              <div className="col-6 margin-top-30">
                <div className="text-span-label">Destination Postal Code (Prefix)</div>
                <div className="text-span-black margin-top-10">
                  {cargo.dest_poscode_prefix !== "" ? cargo.dest_poscode_prefix : "-"}
                </div>
              </div>
              <div className="col-6 margin-top-30">
                <div className="text-span-label">Cargo Weight</div>
                <div className="text-span-black margin-top-10">{cargo.weight}</div>
              </div>
            </div>
            <div className="d-flex margin-top-30">
              <button className="secondary-button full-button-width" onClick={printCargoLabel}>
                Print Label
              </button>
              {showQzTrayNotActiveError && (
                <div className="alert alert-danger download-manifest-alert">QZ Tray is not connected</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SecurityTagDetails securityTagInfo={cargo.security_tag_info} />
    </React.Fragment>
  );

  // print cargo label
  async function printCargoLabel() {
    let data = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      cargo_tracking_no: cargo.tracking_no,
    };
    let response = await axios.get(cargoApi.PRINT_CARGO_LABEL, {
      params: data,
    });

    const mixPanelData = getMixpanelData();

    let pdf = `${cargoApi.PRINT_CARGO_LABEL}?hub_id=${data.hub_id}&hubscanner_key=${data.hubscanner_key}&cargo_tracking_no=${cargo.tracking_no}`;
    if (qz.websocket.isActive() && response) {
      pdf2base64(pdf)
        .then((response) => {
          // mixpanel tracking print cargo label
          mixPanelTrack(PRINT_CARGO_LABEL, {
            [SUCCESS]: true,
            [CARGO_TRACKING_NUMBER]: cargo.tracking_no,
            [SHIPMENT_TRACKING_NUMBER]: cargo.shipment_no,
            ...mixPanelData,
          });
          var config = qz.configs.create(defaultPrinter);
          var qzTrayData = [
            {
              type: "pixel",
              format: "pdf",
              flavor: "base64",
              data: response,
              version: 2,
            },
          ];
          qz.print(config, qzTrayData).then(function () {});
        })
        .catch((error) => {
          mixPanelTrack(PRINT_CARGO_LABEL, {
            [ERROR_TYPE]: PRINT_CARGO_LABEL,
            [SUCCESS]: false,
            [ERROR]: error,
            [CARGO_TRACKING_NUMBER]: cargo.tracking_no,
            [SHIPMENT_TRACKING_NUMBER]: cargo.shipment_no,
            ...mixPanelData,
          });
          console.log(error);
        });
    } else {
      setShowQzTrayNotActiveError(true);
      setTimeout(() => {
        setShowQzTrayNotActiveError(false);
      }, 5000);
    }
  }
}
