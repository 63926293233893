import React from "react";

export const ErrorNotificationArea = ({
  notificationBoxType,
  errorNotificationData,
  parcelScanExceptions,
  scanType,
  scannedNumber,
  renderType,
}) => {
  const errorDataBoxClass = notificationBoxType.showErrorDataBox ? "d-flex flex-column" : "d-none";
  const renderNotificationBody = () => {
    if (scanType === "warehouse outbound") {
      if (renderType === "PARCEL" && parcelScanExceptions && parcelScanExceptions.length > 0) {
        return (
          <div className={`flex-column`}>
            <div className="notification-high-value text-uppercase text-bold mb-4">
              Exception:{" "}
              {parcelScanExceptions.length > 1
                ? `${parcelScanExceptions[0].name} + ${parcelScanExceptions.length - 1} Exceptions`
                : `${parcelScanExceptions[0].name}`}
            </div>
            <h2 className="resolve-exception-text">RESOLVE EXCEPTION FIRST</h2>
            <span className="notification-scan-number">{scannedNumber}</span>
          </div>
        );
      }
      if (renderType === "PROHIBIT") {
        return (
          <div className={`flex-column`}>
            <div className="notification-high-value text-uppercase text-bold mb-4">Exception: prohibited item</div>
            <h2 className="resolve-exception-text">RESOLVE EXCEPTION FIRST</h2>
            <span className="notification-scan-number">{scannedNumber}</span>
          </div>
        );
      }
    }
    return (
      <div className="d-flex flex-column align-items-center">
        <div id="err-image" className="err-img err-no-data">
          &nbsp;
        </div>
        <span className="error-notification-text mb-0 p-1 text-center text-capitalize">
          {errorNotificationData.errorMessage}
        </span>
        {errorNotificationData.jsonBody && (
          <span className="json-notification-text">{errorNotificationData.jsonBody}</span>
        )}
      </div>
    );
  };

  return (
    <div className={`error-notification-container justify-content-center align-items-center ${errorDataBoxClass}`}>
      <div className="error-notification-text mb-0 p-1 text-center text-capitalize">{renderNotificationBody()}</div>
    </div>
  );
};
