/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import { commonApi } from "../../../api";
import axios from "axios";
import {
  DOWNLOAD_MANIFEST,
  ERROR,
  SHIPMENT_TRACKING_NUMBER,
  SUCCESS,
  ERROR_TYPE,
} from "../../../constants/mixpanelConstants";
import { getMixpanelData, mixPanelTrack } from "../../../utils/mixpanelUtils";

export default function ShipmentDetails(props) {
  const { shipment, shipmentWeight } = props;

  const [manifestDownloadError, setManifestDownloadError] = useState(false);

  return (
    <div className="d-flex flex-column details-box shipment-details-box">
      <h2 className="text-heading-black">Shipment Details</h2>
      <div className="row">
        <div className="d-flex flex-column col-6 margin-top-30">
          <span className="text-span-label">Status in Hub</span>
          <span className="text-span-black margin-top-10">{shipment.status}</span>
        </div>
        <div className="d-flex flex-column col-6 margin-top-30">
          <span className="text-span-label">Recipient</span>
          <span className="text-span-black margin-top-10">{shipment.recipient_name}</span>
        </div>
        <div className="d-flex flex-column col-6 margin-top-30">
          <span className="text-span-label">Shipment Total Weight</span>
          <span className="text-span-black margin-top-10">{shipmentWeight ? shipmentWeight : "-"}</span>
        </div>
        <div className="d-flex flex-column col-6 margin-top-30">
          <span className="text-span-label">Link to MAWB</span>
          <span className="text-span-black margin-top-10">{shipment.mawb_no ? shipment.mawb_no : "-"}</span>
        </div>
      </div>
      <div className="d-flex margin-top-30">
        <button className="secondary-button full-button-width" onClick={downloadManifest}>
          Download Manifest
        </button>
        {manifestDownloadError && <div className="alert alert-danger download-manifest-alert">No data exists.</div>}
      </div>
    </div>
  );

  async function downloadManifest() {
    let params = {
      hub_id: localStorage.getItem("hubId"),
      hubscanner_key: localStorage.getItem("userId"),
      shipment_no: shipment.tracking_no,
    };

    const mixPanelData = getMixpanelData();

    axios
      .get(commonApi.DOWNLOAD_SHIPMENT_MANIFEST, {
        params: params,
        responseType: "blob",
        headers: {
          Authorization: "Token ea2b3b0d33dcee3db455881cb10c4e3e5dc246cc",
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "manifest.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
        // mixpanel tracking download manifest
        mixPanelTrack(DOWNLOAD_MANIFEST, {
          [SUCCESS]: true,
          ...mixPanelData,
          [SHIPMENT_TRACKING_NUMBER]: params.shipment_no,
        });
      })
      .catch((e) => {
        setManifestDownloadError(true);
        mixPanelTrack(DOWNLOAD_MANIFEST, {
          [ERROR_TYPE]: DOWNLOAD_MANIFEST,
          [SUCCESS]: false,
          [ERROR]: e,
          ...mixPanelData,
          [SHIPMENT_TRACKING_NUMBER]: params.shipment_no,
        });
        setTimeout(() => {
          setManifestDownloadError(false);
        }, 5000);
      });
  }
}
