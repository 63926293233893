import _ from "lodash";

export const generatePayload = (trackingNos) => {
  const trackingNosFormatted = _.map(trackingNos, (trackingNo) => {
    if (trackingNo && trackingNo.length > 0) {
      return {
        tracking_no: trackingNo.trim(),
      };
    }
  });

  return trackingNosFormatted;
};
