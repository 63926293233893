import React from "react";

export const SuccessNotificationArea = ({
  renderType,
  notificationBoxType,
  successNotificationData,
  validDataBoxClass,
  warningDataBoxClass,
  networkPartner,
  isPackageDisposed,
  scannedNumber,
  latestScannedNumber,
}) => {
  const validRedeliverDataBoxClass = notificationBoxType.showRedeliverDataBox ? "d-flex flex-column" : "d-none";
  console.log(successNotificationData);

  if (renderType === "REASSIGN") {
    return (
      <div
        className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass} ${warningDataBoxClass}`}
      >
        <h2 className="parcel-success-notification-50-bold text-uppercase text-white">
          REASSIGNED TO {networkPartner}
        </h2>
        {isPackageDisposed ? null : <span className="notification-scan-number">{scannedNumber}</span>}
      </div>
    );
  }
  if (renderType === "PARCEL") {
    return (
      <>
        <div
          className={`success-notification-container flex-column justify-content-center align-items-center ${validRedeliverDataBoxClass}`}
        >
          <span className="notification-country text-uppercase text-bold">
            {successNotificationData.tracking_no_message}
          </span>
          <span className="notification-redeliver-heading text-uppercase">Print New Label</span>
          <span className="notification-redeliver-scan-number">Old: {successNotificationData.tracking_no}</span>
          <span className="notification-redeliver-scan-number">New: {successNotificationData.new_tracking_no}</span>
        </div>
        <div
          className={`success-notification-container flex-column justify-content-center align-items-center ${validDataBoxClass}`}
        >
          <h2 className="notification-country">{successNotificationData.consigneeCountry}</h2>
          <span className="notification-postal-code">
            {successNotificationData.consigneePostalPreFix}
            {successNotificationData.source &&
              successNotificationData.source === "cainiao_lazada" &&
              successNotificationData.consigneePostalPreFix === "50" &&
              "(CJ)"}
            {successNotificationData.source &&
              successNotificationData.source === "cainiao_lazada" &&
              successNotificationData.consigneePostalPreFix === "60" &&
              "(NV)"}
          </span>
          {successNotificationData.disposalMessage && (
            <span className="notification-disposal-message">{successNotificationData.disposalMessage}</span>
          )}
          <span className="notification-state-city">
            {successNotificationData.consigneeCity}{" "}
            {successNotificationData.consigneeCity && successNotificationData.consigneeCity !== "" && ", "}
            {successNotificationData.consigneeState}
          </span>
          <span className="notification-shipper-name">{successNotificationData.partner_assigned}</span>
          {isPackageDisposed ? null : <span className="notification-scan-number">{latestScannedNumber}</span>}
        </div>
      </>
    );
  }
};
