import * as Sentry from "@sentry/browser";
import mixpanel from "mixpanel-browser";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import Favicon from "react-favicon";
import { MixpanelProvider } from "react-mixpanel";
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { authTypes } from './types';
import App from './components/app';
import DoRedirect from './deprecated/doRedirect/DoRedirect';
import PickupDeliverAddresses from './deprecated/first-mile-pickup';
import HMSScanner from './components/hms-old';
import HMSScannerNew from './components/hms';
import LoginPage from './components/hms/login';
import RedirectMain from './deprecated/redirect-main';
import RedirectFirstmile from './deprecated/firstmile';
import RedirectPrintLabel from './deprecated/print-label';
import RedirectWarehouse from './deprecated/warehouse';
import './deprecated/i18n';
import img from './images/favicon/janio-favicon.png';
import reducers from './reducers';
import { history } from './utils/historyUtils';

// initialize mixpanel
mixpanel.init("b9699cb50fada242f680130d79bab4f5");

// export mixpanel object
export let Mixpanel = mixpanel;

const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore);
const store = createStoreWithMiddleware(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const token = localStorage.getItem("token");
if (token) {
  store.dispatch({ type: authTypes.AUTH_USER });
}
if (process.env.REACT_APP_IS_STAGING) {
  Sentry.init({
    dsn: "https://067ed6c5a0ca41498c47e1ca23159bfa@sentry.io/1536247",
    environment: "staging",
  });
}
ReactDOM.render(
  <Provider store={store}>
    <DoRedirect>
      <Favicon url={img} />
      <Router history={history}>
        <MixpanelProvider mixpanel={mixpanel}>
          <App>
            <Switch>
              <Route path="/firstmile" component={PickupDeliverAddresses} />
              <Route path="/scan/print-label" component={RedirectPrintLabel} />
              <Route path="/scan/firstmile" component={RedirectFirstmile} />
              <Route path="/scan/warehouse" component={RedirectWarehouse} />
              <Route path="/scan/hubmanage/old" component={HMSScanner} />
              <Route path="/scan/hubmanage/login" component={LoginPage} />
              <Route path="/scan/hubmanage" component={HMSScannerNew} />
              <Route path="/" component={RedirectMain} />
            </Switch>
          </App>
        </MixpanelProvider>
      </Router>
    </DoRedirect>
  </Provider>,
  document.querySelector("#root")
);
// registerServiceWorker();