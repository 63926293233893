import axios from "axios";
import {
  SCAN_FAIL,
  SCAN_RESET,
  SCAN_SUCCESS,
  FETCH_PICKUP_DELIVER_ADDRESSES,
  GET_LABEL_LINK,
  MANIFEST_CHECK_SUCCESS,
  MANIFEST_CHECK_FAIL,
  MANIFEST_DELETE,
  MANIFEST_DELETE_ALL,
  SAVE_PARCEL_SCAN_EXCEPTION,
  CLEAR_PARCEL_SCAN_EXCEPTION,
} from "./types";
import { ROOT_URL } from "./index";
import { generatePayload } from "../deprecated/inputUtils";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const defaultToken = localStorage.getItem("token");
if (defaultToken) {
  axios.defaults.headers.common["Authorization"] = "Token " + defaultToken;
}

export function scanPickup(staffId, trackingNos, latitude, longitude, comments) {
  console.log("picking up orders...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/firstmile/scan/pickup/`,
        {
          secret_key: staffId,
          tracking_nos: generatePayload(trackingNos),
          lat: latitude,
          lon: longitude,
          comments: comments,
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: SCAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SCAN_FAIL,
        });
      });
  };
}

export function scanDeliver(staffId, trackingNos, latitude, longitude, comments) {
  console.log("delivering orders...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/firstmile/scan/deliver/`,
        {
          secret_key: staffId,
          tracking_nos: generatePayload(trackingNos),
          lat: latitude,
          lon: longitude,
          comments: comments,
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: SCAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SCAN_FAIL,
        });
      });
  };
}

export function scanReceive(staffId, trackingNos) {
  console.log("receiving orders...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/firstmile/scan/receive/`,
        {
          secret_key: staffId,
          tracking_nos: generatePayload(trackingNos),
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: SCAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SCAN_FAIL,
        });
      });
  };
}

export function scanTransfer(staffId, trackingNos) {
  console.log("transferring orders...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/firstmile/scan/transfer/`,
        {
          secret_key: staffId,
          tracking_nos: generatePayload(trackingNos),
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: SCAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SCAN_FAIL,
        });
      });
  };
}

export function scanChangeWeight(staffId, trackingNos, weight) {
  console.log("changing weight of orders...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/warehouse/scan/change-weight/`,
        {
          secret_key: staffId,
          tracking_nos: generatePayload(trackingNos),
          weight: weight,
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: SCAN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SCAN_FAIL,
        });
      });
  };
}

export function fetchPickupDeliverAddresses(staffId) {
  console.log("getting pickup deliver addresses...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .get(`${ROOT_URL}/firstmile/staff-orders-addresses/`, {
        params: {
          secret_key: staffId,
        },
        // { headers: { 'Authorization': 'Token ' + token }}
      })
      .then((response) => {
        console.log(response);
        dispatch({
          type: FETCH_PICKUP_DELIVER_ADDRESSES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        // dispatch({
        //   type: SCAN_FAIL
        // });
      });
  };
}

export function getLabelLink(staffId, trackingNo, emails) {
  console.log("getting label link...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/order/get-label-link/`,
        {
          secret_key: staffId,
          tracking_nos: trackingNo.trim(),
          emails: emails,
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: GET_LABEL_LINK,
          payload: response.data,
          status: response.status,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_LABEL_LINK,
          error: error.data,
        });
      });
  };
}

export function scanReset() {
  console.log("resetting scan...");

  return function (dispatch) {
    dispatch({
      type: SCAN_RESET,
    });
  };
}

export function scanManifestCheck(trackingNo) {
  console.log("scanning manifest check...");
  // const token = localStorage.getItem('token');

  return function (dispatch) {
    axios
      .post(
        `${ROOT_URL}/order/check-order-exist/`,
        {
          tracking_no: trackingNo.trim(),
        }
        // { headers: { 'Authorization': 'Token ' + token }}
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: MANIFEST_CHECK_SUCCESS,
          trackingNo: trackingNo.trim(),
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: MANIFEST_CHECK_FAIL,
          trackingNo: trackingNo.trim(),
        });
      });
  };
}

export const deleteManifest = (trackingNo) => {
  return (dispatch) => {
    dispatch({
      type: MANIFEST_DELETE,
      trackingNo: trackingNo,
    });
  };
};

export const deleteAllManifest = () => {
  return (dispatch) => {
    dispatch({
      type: MANIFEST_DELETE_ALL,
    });
  };
};

export const saveParcelScanExceptions = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SAVE_PARCEL_SCAN_EXCEPTION,
      payload,
    });
  };
};

export const clearParcelScanExceptions = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PARCEL_SCAN_EXCEPTION,
    });
  };
};
